import { lazy } from 'react';

// ----------------------------------------------------------------------

const EcosystemPage = lazy(() => import('../../Pages/_explore/ecosystem'));

// ----------------------------------------------------------------------

export const ecosystemRoutes = [
  {
    path: 'ecosystem',
    children: [
      { element: <EcosystemPage />, index: true },
    ],
  },
];
