import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import "./styles.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "./routes/Dashboard/Dashboard";
import Home from "./routes/FrontEnd"
import { createTheme, ThemeProvider } from '@mui/material';
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { ProvideAuth } from "./hooks/useAuth";
import { createUploadLink } from "apollo-upload-client";
import { SnackbarProvider } from "notistack";
import { VERSION, APPLICATION_ID, JAVASCRIPT_KEY, API_URI } from "./env";
import Slide from "@material-ui/core/Slide";
import styled from "@material-ui/core/styles/styled";

const client = new ApolloClient({
  link: createUploadLink({
    uri: `${API_URI}/graphql`,
    headers: {
      "X-Parse-Application-Id": APPLICATION_ID,
      "X-Parse-Javascript-Key": JAVASCRIPT_KEY,
    },
  }),
  cache: new InMemoryCache(),
});

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    common: {
      white: '#000',
      black: '#fff'
    },
    background: {
      paper: "#16161e", // Main
      default: "",
    },
    text: {
      primary: "#fff",
      secondary: "#bebec3",
    },
    primary: {
      main: "#76777f", // Light grey
      light: "#2c2c34", // Light
      dark: "#0e0e14", // Dark
      lighter: "#333344", // Light Hover
      darker: '#0a0a11',
    },
    secondary: {
      main: "#35c669", // Light green
      light: '#2ec4a9', // Darker green
      dark: "#23232b",
      lighter: 'white',
      darker: '#1e1e28', // Popover
    },
    info: {
      main: "#3ea1f2", // Light blue
      light: 'white',
      dark: 'black',
      lighter: 'white',
      darker: 'gray',
    },
    error: {
      main: "#d32f2f", // dark red
      light: 'white',
      dark: 'black',
      lighter: 'white',
      darker: 'gray',
    },
    success: {
      main: "#34eb58", // light green
      light: 'white',
      dark: 'black',
      lighter: 'white',
      darker: 'gray',
    },
  },
  shape: {
    borderRadius: 10,
  },
  typography: {
    fontWeightMedium: 600,
    button: {
      textTransform: "none",
    },
  },
});

const App = () => {
  useEffect(() => {
    console.log(`api: ${API_URI}, version: ${VERSION}`);
  }, []);

  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      TransitionComponent={Slide}
      style={{
        fontFamily: 'sans-serif',
        borderRadius: 10,
      }}
    >
      <BrowserRouter>
        <ThemeProvider theme={darkTheme}>
          <ProvideAuth>
            <ApolloProvider client={client}>
              <Routes>
                <Route path="/*" element={<Home />} />
                <Route path="/dashboard/*" element={<Dashboard />} />
              </Routes>
            </ApolloProvider>
          </ProvideAuth>
        </ThemeProvider>
      </BrowserRouter>
    </SnackbarProvider>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));

// if (process.env.NODE_ENV === "development" && module.hot) {
//   module.hot.accept("./index.js", render);
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
