import React, { useEffect, useState, useRef } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Box,
  MenuItem,
  IconButton,
  Menu,
  Typography,
} from "@mui/material";
import FadeIn from "react-fade-in";
import { useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useQuery, gql, useLazyQuery } from "@apollo/client";
import { Loading } from "../../../../components";
import { useAuth } from "../../../../hooks/useAuth";
import Parse from "parse";
import MenuIcon from "@material-ui/icons/Menu";

const useStyles = makeStyles((theme: any) => ({
  card: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    height: "375px",
    boxShadow: theme.shadows[4],
    borderRadius: 15,
    outlineColor: "#2c2c33",
    outlineStyle: "solid",
    outlineWidth: 1,
  },
  grid: {
    flexGrow: 1,
  },
  popover: {
    backgroundColor: theme.palette.secondary.dark,
    borderRadius: 11,
    outlineColor: "#2c2c33",
    outlineStyle: "solid",
    outlineWidth: 1,
  },
  title: {
    fontSize: 18,
    fontWeight: 500
  },
  titleFont: {
    fontWeight: 500
  },
}));

export default function StudentTotals(props) {
  const theme = useTheme();
  const classes = useStyles(theme);

  const auth = useAuth();
  const [data, setData] = useState<any>(undefined);
  const [loading, setLoading] = useState(true);

  // if (error) console.log(JSON.stringify(error, null, 2));

  useEffect(() => {
    if (auth.collegeId) {
      async function fetchData() {
        setLoading(true);

        await Parse.Cloud.run("dashboardLeadsTotals", { collegeId: auth.collegeId }).then((response) => {
          setLoading(false);
          setData(response);
        });
      }
      fetchData();
    }
  }, [auth.collegeId]);

  return (
    <FadeIn delay={200}>
      <Card style={{
        backgroundColor: theme.palette.primary.dark,
        padding: theme.spacing(2),
        height: 350,
        boxShadow: theme.shadows[4],
        borderRadius: 25,
        outlineColor: "#2c2c33",
        outlineStyle: "solid",
        outlineWidth: 1,
      }}>
        <CardHeader
          title="Lead Totals"
          subheader="High School Students"
          titleTypographyProps={{ fontWeight: 600 }}
          action={<></>}
        />
        <CardContent>
          {loading ? (
            <Loading />
          ) : (
            <>
              <Box height="17px" />
              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <Typography variant="h5" color="secondary" align="left">
                    {isNaN(data?.total) ? 0 : data?.total}
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography className={classes.title} align="right" color="textSecondary">
                    Total Student Leads
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="h5" color="secondary" align="left">
                    {data?.inState ?? 0}
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography className={classes.title} align="right" color="textSecondary">
                    In-state
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="h5" color="secondary" align="left">
                    {data?.outState ?? 0}
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography className={classes.title} align="right" color="textSecondary">
                    Out-of-State
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}
        </CardContent>
      </Card>
    </FadeIn>
  );
}
