import Parse from "parse";
import {
  Card,
  Grid,
  Menu,
  MenuItem,
  IconButton,
  Typography,
  Box,
  Modal,
  Fade,
  Button,
  TableCell,
  Table,
  TableHead,
  TableBody,
  TableRow,
  CircularProgress,
  Tooltip,
  Link,
  ButtonBase,
  Hidden
} from "@mui/material";
import { Buffer } from "buffer";
import FadeIn from "react-fade-in";
import { Link as RouterLink } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import Papa from "papaparse";
import { read, utils } from "xlsx";
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Image from '../../../FrontEnd/Components/image'
import { useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useRef, useEffect, useState, ChangeEvent } from "react";
import Iconify from '../../../../../src/routes/FrontEnd/Components/iconify';
import InfoIcon from "@material-ui/icons/Info";
import UploadIcon from "@material-ui/icons/CloudUploadRounded";
import { Loading } from "../../../../components";
import Divider from '@mui/material/Divider';
import CloseIcon from "@material-ui/icons/Close";
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useAuth } from "../../../../hooks/useAuth";
import { useSnackbar } from "notistack";
import LoadingButton from "@mui/lab/LoadingButton";
import { inviteEmailGenericContent } from "./InvitesContent/lead-invite-generic"
import { inviteEmailPersonalizedContent } from "./InvitesContent/lead-invite-personalized"

const useStyles = makeStyles((theme: any) => ({
  card: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    height: "375px",
    boxShadow: theme.shadows[4],
    borderRadius: 15,
    outlineColor: "#2c2c33",
    outlineStyle: "solid",
    outlineWidth: 1,
  },
  grid: {
    flexGrow: 1,
  },
  popover: {
    backgroundColor: theme.palette.secondary.dark,
    borderRadius: 11,
    outlineColor: "#2c2c33",
    outlineStyle: "solid",
    outlineWidth: 1,
  },
  title: {
    fontSize: 18,
    fontWeight: 500
  },
  titleFont: {
    fontWeight: 500
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: theme.spacing(3),
  },
  tableCell: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    "& .MuiTableCell-root": {
      borderBottomColor: "#3c3c46"
    },
  },
}));

const HistoricalImportRow = (props) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [hisoricalFileData, setHisoricalFileData] = useState<any>(null);
  const [showDataLoading, setShowDataLoading] = useState(false);
  const [openImportDataHistorical, setOpenImportDataHistorical] = useState(false);
  const [hisoricalFileInfo, setHisoricalFileInfo] = useState<any>("");

  const invite = props.invite;

  const auth = useAuth();

  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  const getCurrentLeads = async (importId: string) => {
    await Parse.Cloud.run("getCurrentLeads", {
      collegeId: auth.collegeId,
      importId: importId
    }).then(
      async (res) => {
        if (res) {
          setHisoricalFileData(res);
          setShowDataLoading(false);
        }
      }
    );
  }

  return (
    <>
      <TableRow
        className={classes.tableCell}
        key={invite.id}
        style={{ textDecoration: "none" }}
      >
        <TableCell align="left">{<Typography style={{ fontWeight: 600, fontSize: 14, color: "#fff" }}>{invite.get('fileInfo').split(" - ").slice(0, -1).join(" - ")}</Typography>}</TableCell>
        <TableCell align="left">{<Typography style={{ fontWeight: 400, fontSize: 14, color: theme.palette.text.secondary }}>{invite.get('createdBy').get('userContact').get("fullName")}</Typography>}</TableCell>
        <TableCell align="left">{<Typography style={{ fontWeight: 400, fontSize: 14, color: theme.palette.text.secondary }}>{invite.get('fileInfo').split(" - ").slice(-1)}</Typography>}</TableCell>
        <TableCell align="left">{<Typography style={{ fontWeight: 400, fontSize: 14, color: theme.palette.text.secondary }}>{invite.get('emailType')}</Typography>}</TableCell>
        <TableCell align="left">{<Typography style={{ fontWeight: 400, fontSize: 14, color: invite.get('stage') == "Finished" ? theme.palette.secondary.main : theme.palette.error.main }}>{invite.get('stage')}</Typography>}</TableCell>
        <TableCell align="left">{<Typography style={{ fontWeight: 400, fontSize: 14, color: "#fff" }}>{invite.get('followUpStatus') == "no_follow_ups" ? "0 Follow-Ups Sent" : invite.get('followUpStatus') == "one_follow_up" ? "1 Follow-Up Sent" : "2 Follow-Ups Sent"}</Typography>}</TableCell>
        <TableCell>
          <LoadingButton
            variant="outlined"
            loading={showDataLoading}
            style={{
              background: "linear-gradient(75deg, #35c669, #2ec4a9)",
              border: 0,
              color: "#fff",
              marginLeft: "auto",
              fontSize: 10,
              height: 28,
            }}
            loadingIndicator={
              <CircularProgress thickness={4} size={15} sx={{ color: "#fff" }} />
            }
            onClick={async () => {
              setShowDataLoading(true);
              await getCurrentLeads(invite.id);
              setHisoricalFileInfo(invite.get('fileInfo'));
              setOpenImportDataHistorical(true);
            }}
          >
            {!showDataLoading ? "VIEW" : ""}
          </LoadingButton>
        </TableCell>
        <TableCell align="right">{<Typography style={{ fontWeight: 600, fontSize: 14, color: "#fff" }}>{months[(new Date(invite.createdAt)).getMonth()] + " " + (new Date(invite.createdAt)).getDate() + ", " + (new Date(invite.createdAt)).getFullYear()}</Typography>}</TableCell>
      </TableRow>
      <ShowImportData
        setOpen={setOpenImportDataHistorical}
        open={openImportDataHistorical}
        title="Import Results"
        subtitle={hisoricalFileInfo}
        arr={hisoricalFileData?.sort((a, b) => a[0].localeCompare(b[0]))}
      />
    </>
  )
}

const Info = (props) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Modal
      className={classes.modal}
      open={props.open}
      onClose={() => props.setOpen(false)}
      closeAfterTransition
    >
      <Fade in={props.open}>
        <Card style={{
          maxHeight: 690,
          overflowY: "auto",
          overflowX: "hidden",
          backgroundColor: theme.palette.primary.dark,
          display: "flex",
          flexDirection: "column",
          padding: theme.spacing(4),
          paddingBottom: theme.spacing(2),
          borderRadius: 25,
          outlineColor: "#2c2c33",
          outlineStyle: "solid",
          outlineWidth: 1,
        }}>
          <Box width={500}>
            <Grid container sx={{ alignSelf: "left" }}>
              <Typography style={{ fontWeight: 600, fontSize: 29 }} align="left" color="textPrimary">
                {props.title}
              </Typography>
              <Box flexGrow={1} />
              <IconButton style={{ width: 40, height: 40 }} onClick={() => props.setOpen(false)}>
                <CloseIcon />
              </IconButton>
            </Grid>
            {props.content}
          </Box>
        </Card>
      </Fade>
    </Modal >
  );
};

const Preview = (props) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const ref = useRef(null);

  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const options = [
    {
      label: "Generic Invite",
      value: "generic_invite",
    },
    {
      label: "Personalized Invite",
      value: "personalized_invite",
    },
  ];

  const handleOpen = () => {
    setDropdownOpen(true);
  };

  const handleClose = () => {
    setDropdownOpen(false);
  };

  return (
    <Modal
      className={classes.modal}
      open={props.open}
      onClose={() => props.setOpen(false)}
      closeAfterTransition
    >
      <Fade in={props.open}>
        <Card style={{
          maxHeight: 650,
          overflowY: "auto",
          overflowX: "hidden",
          backgroundColor: theme.palette.primary.dark,
          display: "flex",
          flexDirection: "column",
          padding: theme.spacing(4),
          paddingBottom: theme.spacing(2),
          borderRadius: 25,
          outlineColor: "#2c2c33",
          outlineStyle: "solid",
          outlineWidth: 1,
        }}>
          <Box width={500}>
            <Grid container sx={{ alignSelf: "left" }}>
              <div>
                <Typography style={{ fontWeight: 600, fontSize: 29 }} align="left" color="textPrimary">
                  {props.title}
                </Typography>
                <Box
                  display="flex"
                  alignItems="center"
                  component={ButtonBase}
                  onClick={handleOpen}
                  ref={ref}
                  disabled={props.emailTypeDisabled}
                  style={{
                    borderRadius: 25,
                    backgroundColor: "#0d0d14",
                    height: 23,
                    // padding: 1,
                    opacity: props.emailTypeDisabled ? 0.5 : 1,
                    paddingRight: theme.spacing(8),
                    paddingLeft: theme.spacing(8),
                    marginTop: theme.spacing(1),
                    marginLeft: 1,
                    paddingTop: 4,
                    paddingBottom: 4,
                    outlineWidth: 1,
                    outlineStyle: "solid",
                    outlineColor: "#3b3b42"
                  }}
                >
                  {/* <Avatar alt="User" className={classes.avatar} src={src} sx={{ width: 32, height: 32, backgroundColor: theme.palette.primary.light }} /> */}
                  <Hidden smDown>
                    <Typography style={{ fontSize: 12, fontWeight: 600 }} color="inherit">
                      {props.emailType}
                    </Typography>
                  </Hidden>
                </Box>
                <Menu
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  keepMounted
                  slotProps={{
                    paper: {
                      sx: {
                        minWidth: 270,
                        marginLeft: theme.spacing(0),
                        marginTop: theme.spacing(1),
                        backgroundColor: theme.palette.primary.dark,
                        borderRadius: 1.5,
                        outlineColor: "#3c3c44",
                        outlineStyle: "solid",
                        outlineWidth: 1,
                      }
                    }
                  }}
                  anchorEl={ref.current}
                  open={isDropdownOpen}
                >
                  {options.map((option) => (
                    <MenuItem
                      key={option.value}
                      onClick={() => {
                        setDropdownOpen(false);
                        props.setEmailType(option.label);
                      }}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Menu>
              </div>
              <Box flexGrow={1} />
              <IconButton style={{ width: 40, height: 40 }} onClick={() => props.setOpen(false)}>
                <CloseIcon />
              </IconButton>
            </Grid>
            <PerfectScrollbar
              style={{
                marginTop: theme.spacing(2),
                overflow: "hidden",
                borderRadius: 20,
                maxHeight: 550,
              }}
            >
              <div dangerouslySetInnerHTML={{ __html: props?.content }} />
            </PerfectScrollbar>
          </Box>
        </Card>
      </Fade>
    </Modal >
  );
};

const ImportDataRow = (props) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <TableRow
      className={classes.tableCell}
      key={props.email}
      style={{ textDecoration: "none" }}
    >
      <TableCell
        align="left"
      >
        {props.student != null ? (
          <Link
            color="textPrimary"
            component={RouterLink}
            to={`/dashboard/profiles/${(props.student as any).get('userContact').id}`}
            variant="h6"
          >
            <Typography
              style={{
                fontWeight: 600,
                fontSize: 14,
                color: theme.palette.text.primary,
              }}
              sx={{
                textDecoration: 'underline'
              }}
            >
              {props.firstName}
            </Typography>
          </Link>

        ) : (
          <Typography
            style={{
              fontWeight: 600,
              fontSize: 14,
              color: theme.palette.text.primary,
            }}
          >
            {props.firstName}
          </Typography>
        )}
      </TableCell>
      <TableCell
        align="left"
      >
        {<Typography
          style={{
            fontWeight: 400,
            fontSize: 14,
            color: theme.palette.text.secondary,
            fontStyle: "italic"
          }}
        >
          {props.email}
        </Typography>}
      </TableCell>
      {props.delivered != null ? (
        <TableCell align="center">
          <div style={{ display: 'flex' }}>
            <Tooltip
              title={props.delivered ? "Delivered" : "Not Delivered"}
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: "#46464f",
                    color: "white"
                  }
                }
              }}
            >
              <Box
                display='flex'
                justifyContent='center'
              >
                <Box
                  display='flex'
                  justifyContent='center'
                  alignItems='center'
                  marginLeft={0.5}
                  sx={{
                    backgroundColor: props.delivered ? "#35c669" : theme.palette.error.main,
                    borderRadius: 20,
                    width: 22,
                    height: 22
                  }}
                >
                  <img
                    alt='check'
                    src={props.delivered ? '/assets/icons/ic_envelope-open.svg' : '/assets/icons/ic_cross_small.svg'}
                    style={{
                      width: props.delivered ? 12 : 15.5,
                      height: props.delivered ? 12 : 15.5,
                    }}
                  />
                </Box>
              </Box>
            </Tooltip>
            <Tooltip
              title={props.onboarded ? "Onboarded" : "Not Onboarded Yet"}
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: "#46464f",
                    color: "white"
                  }
                }
              }}
            >
              <Box
                display='flex'
                justifyContent='center'
                marginLeft={0.5}
              >
                <Box
                  display='flex'
                  justifyContent='center'
                  alignItems='center'
                  sx={{
                    background: props.onboarded ? "linear-gradient(75deg, #3396e8, #32c7e5)" : null,
                    backgroundColor: "#373742",
                    borderRadius: 20,
                    width: 22,
                    height: 22
                  }}
                >
                  <img
                    alt='check'
                    src={props.onboarded ? '/assets/icons/ic_user_white.svg' : '/assets/icons/ic_user_gray.svg'}
                    style={{
                      width: 12,
                      height: 12
                    }}
                  />
                </Box>
              </Box>
            </Tooltip>
            <Tooltip
              title={props.interested ? "Is Interested" : "Not Interested"}
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: "#46464f",
                    color: "white"
                  }
                }
              }}
            >
              <Box
                display='flex'
                justifyContent='center'
                marginLeft={0.5}
              >
                <Box
                  display='flex'
                  justifyContent='center'
                  alignItems='center'
                  sx={{
                    background: props.interested ? "linear-gradient(75deg, #fdc830, #f37335)" : null,
                    backgroundColor: "#373742",
                    borderRadius: 20,
                    width: 22,
                    height: 22
                  }}
                >
                  <img
                    alt='check'
                    src={props.interested ? '/assets/icons/ic_flash.svg' : '/assets/icons/ic_flash_gray.svg'}
                    style={{
                      width: 13,
                      height: 13
                    }}
                  />
                </Box>
              </Box>
            </Tooltip>
          </div>
        </TableCell>
      ) : (<TableCell align="center"></TableCell>)}
    </TableRow>
  )
}

const ShowImportData = (props) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [filterDelivered, setFilterDelivered] = useState(false);
  const [filterOnboarded, setFilterOnboarded] = useState(false);
  const [filterInterested, setFilterInterested] = useState(false);
  const [fileData, setFileData] = useState<Array<Array<string>>>([[]]);
  const [chunks, setChunks] = useState<any>([]);
  const [skip, setSkip] = useState(0);

  const chunkSize = 20;

  useEffect(() => {
    const nextChunk = chunks[skip];

    var data = fileData?.map((e) => e);
    data?.push.apply(data, nextChunk);

    setFileData(data);

  }, [skip]);

  useEffect(() => {
    var chunks: any = [];

    for (let i = 0; i < props.arr?.length; i += chunkSize) {
      const chunk = props.arr?.slice(i, i + chunkSize);
      chunks.push(chunk);
    }

    setChunks(chunks);

    setSkip(0);
    setFileData(chunks[0]);

  }, [props.open]);

  return (
    <Modal
      className={classes.modal}
      open={props.open}
      onClose={() => props.setOpen(false)}
      closeAfterTransition
    >
      <Fade in={props.open}>
        <Card style={{
          maxHeight: 650,
          height: props.arr ? Math.min((props.arr.length * 67) + 259, 650) : 650,
          overflowY: "auto",
          overflowX: "hidden",
          backgroundColor: theme.palette.primary.dark,
          display: "flex",
          flexDirection: "column",
          padding: theme.spacing(4),
          paddingBottom: theme.spacing(2),
          borderRadius: 25,
          outlineColor: "#2c2c33",
          outlineStyle: "solid",
          outlineWidth: 1,
        }}>
          <Box width={500}>
            <Grid container sx={{ alignSelf: "left" }}>
              <Box mb={theme.spacing(3)}>
                <Typography style={{ fontWeight: 600, fontSize: 29 }} align="left" color="textPrimary">
                  {props.title}
                </Typography>
                <Typography style={{ fontWeight: 400, fontSize: 14, fontStyle: "italic" }} align="left" color="textSecondary">
                  {props.subtitle}
                </Typography>
                {props.arr ? (
                  props.arr.length > 0 ? (
                    props.arr[0][2] != null ? (
                      <Grid container width={"130%"} marginTop={theme.spacing(2)} spacing={2}>
                        <Grid item xs={6.8}>
                          <Card style={{
                            backgroundColor: filterDelivered ? "#1a1a21" : "#040405",
                            height: 12,
                            borderRadius: 30,
                            padding: theme.spacing(1),
                            outlineWidth: 1,
                            outlineStyle: "solid",
                            outlineColor: "#3b3b42"
                          }}>
                            <ButtonBase
                              disableRipple
                              style={{
                                display: 'block',
                                textAlign: 'center',
                                height: 26,
                                top: -7,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 600,
                                color: "textPrimary",
                              }}
                              onClick={() => {
                                setFilterDelivered(!filterDelivered);
                                setFilterOnboarded(false);
                                setFilterInterested(false);
                              }}
                            >
                              {props.arr.filter((x) => x[2] == true).length + " Delivered"}
                            </ButtonBase>
                          </Card>
                        </Grid>
                        <Grid item xs={5.2}>
                          <Card style={{
                            backgroundColor: filterOnboarded ? "#1a1a21" : "#040405",
                            height: 12,
                            borderRadius: 30,
                            padding: theme.spacing(1),
                            outlineWidth: 1,
                            outlineStyle: "solid",
                            outlineColor: "#3b3b42"
                          }}>
                            <ButtonBase
                              disableRipple
                              style={{
                                display: 'block',
                                textAlign: 'center',
                                height: 26,
                                top: -7,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 600,
                                color: "textPrimary",
                              }}
                              onClick={() => {
                                setFilterOnboarded(!filterOnboarded);
                                setFilterInterested(false);
                                setFilterDelivered(false);
                              }}
                            >
                              {props.arr.filter((x) => x[3] == true).length + " Onboarded"}
                            </ButtonBase>
                          </Card>
                        </Grid>
                        <Grid item xs={5}>
                          <Card style={{
                            backgroundColor: filterInterested ? "#1a1a21" : "#040405",
                            height: 12,
                            borderRadius: 30,
                            padding: theme.spacing(1),
                            outlineWidth: 1,
                            outlineStyle: "solid",
                            outlineColor: "#3b3b42"
                          }}>
                            <ButtonBase
                              disableRipple
                              style={{
                                display: 'block',
                                textAlign: 'center',
                                height: 26,
                                top: -7,
                                width: '100%',
                                fontSize: 12,
                                fontWeight: 600,
                                color: "textPrimary",
                              }}
                              onClick={() => {
                                setFilterOnboarded(false);
                                setFilterInterested(!filterInterested);
                                setFilterDelivered(false);
                              }}
                            >
                              {props.arr.filter((x) => x[4] == true).length + " Interested"}
                            </ButtonBase>
                          </Card>
                        </Grid>
                      </Grid>
                    ) : (
                      <></>
                    )
                  ) : (
                    <></>
                  )
                ) : (
                  <></>
                )}
              </Box>
              <Box flexGrow={1} />
              <IconButton style={{ width: 40, height: 40 }} onClick={() => props.setOpen(false)}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Box>
          <PerfectScrollbar
            onYReachEnd={() => {
              if (filterOnboarded == false && filterInterested == false) {
                setTimeout(() => {
                  if (skip < chunks.length && props.arr?.length >= chunkSize) {
                    setSkip(skip + 1);
                  }
                }, 400);
              }
            }}
          >
            <Table>
              <TableHead>
                <TableRow className={classes.tableCell}>
                  <TableCell align="left">First Name</TableCell>
                  <TableCell align="left">Email</TableCell>
                  <TableCell align="center">{fileData ? fileData[0][2] != null ? "Status" : "" : ""}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filterDelivered ? (
                  <>
                    {props.arr?.filter((x) => x[2]).map((student: Array<string>) => (
                      <ImportDataRow
                        firstName={student[0]}
                        email={student[1]}
                        delivered={student[2]}
                        onboarded={student[3]}
                        interested={student[4]}
                        student={student[5]}
                      />
                    ))}
                  </>
                ) : (
                  <>
                    {filterOnboarded ? (
                      <>
                        {props.arr?.filter((x) => x[3]).map((student: Array<string>) => (
                          <ImportDataRow
                            firstName={student[0]}
                            email={student[1]}
                            delivered={student[2]}
                            onboarded={student[3]}
                            interested={student[4]}
                            student={student[5]}
                          />
                        ))}
                      </>
                    ) : (
                      <>
                        {filterInterested ? (
                          <>
                            {props.arr?.filter((x) => x[4]).map((student: Array<string>) => (
                              <ImportDataRow
                                firstName={student[0]}
                                email={student[1]}
                                delivered={student[2]}
                                onboarded={student[3]}
                                interested={student[4]}
                                student={student[5]}
                              />
                            ))}
                          </>
                        ) : (
                          <>
                            {fileData?.map((student: Array<string>) => (
                              <ImportDataRow
                                firstName={student[0]}
                                email={student[1]}
                                delivered={student[2]}
                                onboarded={student[3]}
                                interested={student[4]}
                                student={student[5]}
                              />
                            ))}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </TableBody>
            </Table>
          </PerfectScrollbar>
        </Card>
      </Fade >
    </Modal >
  );
};

export default function ImportLeads() {
  const theme = useTheme();
  const classes = useStyles(theme);

  const { enqueueSnackbar } = useSnackbar();
  const hiddenFileInput = useRef<any>(null);
  const hiddenFileInputUpload = useRef<any>(null);
  const [openInfo, setOpenInfo] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [openImportData, setOpenImportData] = useState(false);
  const [height, setHeight] = useState(60);
  const [fileInfo, setFileInfo] = useState<any>(null);
  const [fileData, setFileData] = useState<any>(null);
  const [isSendingInvites, setIsSendingInvites] = useState(false);
  const [currentStage, setCurrentStage] = useState("");
  const [historicalInvites, setHistoricalInvites] = useState<Array<any>>([]);
  const [openHistorical, setOpenHistorical] = useState(false);
  const [emailType, setEmailType] = useState("Generic Invite");
  const [isLoadingInitialGetCurrentSendStatus, setIsLoadingInitialGetCurrentSendStatus] = useState(true);
  const [showImportUploadBtn, setShowImportUploadBtn] = useState(false);
  const [isUploadingData, setIsUploadingData] = useState(false);

  var prevProgress = 0;
  const [currentProgress, setCurrentProgress] = useState(0);

  const timer = ms => new Promise(res => setTimeout(res, ms))

  const auth = useAuth();

  function humanFileSize(bytes, si = false, dp = 1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }

    const units = si
      ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10 ** dp;

    do {
      bytes /= thresh;
      ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


    return bytes.toFixed(dp) + ' ' + units[u];
  }

  const clearImport = async () => {
    if (currentStage == "Finished" || currentStage == "Canceled") {
      await Parse.Cloud.run("dontShowRecentImport", {
        dontShow: true,
        userUniversityId: Parse.User.current()!.get("userUniversity").id,
      }).then(
        async (res) => {

        }
      );
    }

    setFileInfo(null);
    setHeight(60);
    setOpenHistorical(false);

    setCurrentStage("");
    setCurrentProgress(0);
  }

  const validateEmail = (email) => {
    if (!email) {
      return false;

    } else {
      return email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    }
  };

  function checkForDuplicates(arr) {
    return arr.some((val, i) => arr.indexOf(val) !== i);
  }

  const validateLeads = (arr: [Array<string>]) => {
    if (arr.some((arr) => arr.includes(''))) {
      enqueueSnackbar("Please make sure all names and emails are entered in every row", {
        variant: "error",
      });

      return false;
    }

    if (arr.some((arr) => !validateEmail(arr[1]))) {
      enqueueSnackbar("Please make sure all emails are in a valid format", {
        variant: "error",
      });

      return false;
    }

    if (checkForDuplicates(arr.map((x) => x[1]))) {
      enqueueSnackbar("There appear to be duplicate emails in your list", {
        variant: "error",
      });

      return false;
    }

    if (auth.tier == "premium") {
      if (arr.length > 3000) {
        enqueueSnackbar("Oops! The max number of students allowed in a single import is 3000", {
          variant: "error",
        });

        return false;
      }

    } else {
      if (arr.length > 50) {
        enqueueSnackbar("Oops! You can't include more than 50 students per import with the free plan. Visit billing to upgrade.", {
          variant: "error",
        });

        return false;
      }
    }

    return true;
  }

  async function onFileChange(e: ChangeEvent<HTMLInputElement>) {
    e.persist();

    if (e.currentTarget.files) {
      const file = e.currentTarget.files[0];

      const reader = new FileReader()
      reader.onload = async (e: any) => {
        const data = new Uint8Array(e.target.result);
        const workbook = read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const csvData = Papa.unparse(utils.sheet_to_json(sheet), {
          header: false,
        });

        const rows = csvData.split('\n');
        const arr = rows.map(row => row.replace(/(\r\n|\n|\r)/gm, "").split(','));

        if (validateLeads(arr)) {
          setHeight(80);
          setOpenHistorical(false);
          setFileInfo(file.name + " - " + arr.length + " rows (" + humanFileSize(file.size, true) + ")");

          setFileData(arr);
          setCurrentProgress(0);
          setCurrentStage("Ready to send");
        }
      };
      reader.readAsArrayBuffer(file);
    }
  }

  async function onFileChangeUpload(e: ChangeEvent<HTMLInputElement>) {
    e.persist();

    if (e.currentTarget.files) {
      const file = e.currentTarget.files[0];

      const reader = new FileReader()
      reader.onload = async (e: any) => {
        setIsUploadingData(true);

        const data = new Uint8Array(e.target.result);
        const workbook = read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const csvData = Papa.unparse(utils.sheet_to_json(sheet), {
          header: true,
        });

        const rows = csvData.split('\n');
        const arr = rows.map(row => row.replace(/(\r\n|\n|\r)/gm, "").split(','));

        await Parse.Cloud.run("saveImportDataUpload", {
          data: arr,
          collegeId: auth.collegeId
        }).then(
          async (res) => {
            setIsUploadingData(false);

            enqueueSnackbar("Successfully uploaded import file", {
              variant: "success",
            });
          });
      };
      reader.readAsArrayBuffer(file);
    }
  }

  async function sendInvites() {
    setIsSendingInvites(true);
    setCurrentProgress(0);

    setTimeout(getCurrentLeadInvitesSendStatus, 1500);

    await Parse.Cloud.run("sendBatchLeadImportEmails", {
      collegeId: auth.collegeId,
      userUniversityId: Parse.User.current()!.get("userUniversity").id,
      fileData: fileData,
      fileInfo: fileInfo,
      emailType: emailType
    }).then(
      async (res) => {
        setIsSendingInvites(false);

        getHistoricalInvites();

        await Parse.Cloud.run("getCurrentLeadInvitesSendStatus", {
          userUniversityId: Parse.User.current()!.get("userUniversity").id,
        }).then(
          async (res) => {
            if (res) {
              if (res.get('stage') != "Canceled") {
                setCurrentStage("Finished");
                setCurrentProgress(100);
              }
            }
          });
      }
    );
  }

  const cancelSending = async () => {
    setIsSendingInvites(false);

    await Parse.Cloud.run("cancelSendingInvites", {
      userUniversityId: Parse.User.current()!.get("userUniversity").id,
    }).then(
      async (res) => {
        enqueueSnackbar(res, {
          variant: "success",
        });
      });
  };

  const getCurrentLeadInvitesSendStatus = async () => {
    console.log('getCurrentLeadInvitesSendStatus');

    await Parse.Cloud.run("getCurrentLeadInvitesSendStatus", {
      userUniversityId: Parse.User.current()!.get("userUniversity").id,
    }).then(
      async (res) => {
        if (res) {
          if (res.get('stage') != "Finished" && res.get('stage') != "Canceled") {
            if (res.get('stage') == "Preparing file") {
              setCurrentProgress(res.get('percComplete'));

            } else if ((res.get('stage') == "Sending invites")) {
              if (res.get('percComplete') == 0) {
                setCurrentProgress(0);
                prevProgress = 0;
              }

              for (let i = prevProgress; i < res.get('percComplete'); i++) {
                setCurrentProgress(i);

                await timer(150);
              };
              prevProgress = res.get('percComplete');
            }

            setIsSendingInvites(true);
            setCurrentStage(res.get('stage'));
            setFileInfo(res.get('fileInfo'));
            setHeight(80);
            setOpenHistorical(false);

            setTimeout(getCurrentLeadInvitesSendStatus, 1500);

          } else {
            if (res.get('dontShow')) {
              setCurrentStage("");
              setFileInfo("");
              setHeight(60);
              setOpenHistorical(false);
              setCurrentProgress(0);
              setIsSendingInvites(false);

            } else {
              setCurrentStage(res.get('stage'));
              setFileInfo(res.get('fileInfo'));
              setEmailType(res.get('emailType'))
              setHeight(80);
              setOpenHistorical(false);
              setCurrentProgress(res.get('percComplete'));
              setIsSendingInvites(false);
            }
          }
        }

        setIsLoadingInitialGetCurrentSendStatus(false);
      }
    );
  }

  const getHistoricalInvites = async () => {
    await Parse.Cloud.run("getHistoricalInvites", {
      collegeId: auth.collegeId,
    }).then(
      async (res) => {
        if (res) {
          setHistoricalInvites(res);
        }
      }
    );
  }

  const getDataUploadBtnStatus = async () => {
    await Parse.Cloud.run("getDataUploadBtnStatus", {
      collegeId: auth.collegeId,
    }).then(
      async (res) => {
        if (res) {
          setShowImportUploadBtn(res);
        }
      }
    );
  }

  const getCurrentLeads = async (importId: string) => {
    await Parse.Cloud.run("getCurrentLeads", {
      collegeId: auth.collegeId,
      importId: importId
    }).then(
      async (res) => {
        if (res) {
          setFileData(res);
        }
      }
    );
  }

  useEffect(() => {
    if (openImportData) {
      document.body.style.overflow = 'hidden';

    } else {
      document.body.style.overflow = 'auto';
    }
  }, [openImportData]);

  useEffect(() => {
    if (auth.collegeId) {
      getHistoricalInvites();
      getDataUploadBtnStatus();
    }
  }, [auth.collegeId]);


  useEffect(() => {
    if (fileData == null) {
      getCurrentLeadInvitesSendStatus();
    }

    return () => {
      var highestTimeoutId = setTimeout(";");
      for (var i = 0; i < highestTimeoutId; i++) {
        clearTimeout(i);
      }
    };
  }, []);

  return (
    <FadeIn>
      <Card style={{
        backgroundColor: theme.palette.primary.dark,
        padding: theme.spacing(1),
        height: isSendingInvites ? height == 80 ? height : height : height,
        transition: 'transform 150ms ease',
        boxShadow: theme.shadows[4],
        borderRadius: 23,
        outlineColor: "#2c2c33",
        outlineStyle: "solid",
        outlineWidth: 1,
      }}>
        <Grid container spacing={3} xs={12} maxHeight={80}>
          <Grid item xs={8}>
            <Box display='flex' alignItems='center' ml={theme.spacing(4)} mt={fileInfo ? 0.3 : 1}>
              <Image
                alt="import"
                src="/assets/icons/ic_file_import.svg"
                style={{ width: 35 }}
              />
              <Box>
                <Typography style={{ fontWeight: 600, fontSize: 24, marginLeft: theme.spacing(2), marginTop: 3.5 }}>Send Invites</Typography>
                <Box
                  display='flex'
                  alignItems='center'
                  visibility={fileInfo ? "visible" : "hidden"}
                  height={fileInfo ? 15 : 0}
                  sx={{ marginLeft: theme.spacing(2) }}
                >
                  <Typography
                    color={theme.palette.text.secondary}
                    style={{
                      fontWeight: 400,
                      fontSize: 11,
                      fontStyle: "italic"
                    }}
                  >
                    {fileInfo}
                  </Typography>
                  <IconButton
                    disabled={isSendingInvites ? true : false}
                    style={{
                      height: 14,
                      width: 14,
                      marginLeft: 7,
                    }}
                    onClick={clearImport}>
                    <CloseIcon
                      style={{
                        height: 13,
                        width: 13,
                        fontSize: "small",
                        color: theme.palette.text.secondary,
                        opacity: isSendingInvites ? 0.6 : 1.0
                      }}
                    />
                  </IconButton>
                  <IconButton
                    disabled={isSendingInvites ? true : false}
                    style={{
                      height: 14,
                      width: 14,
                      marginLeft: 3,
                    }}
                    onClick={async () => {
                      if (currentStage == "Finished" || currentStage == "Canceled") {
                        await getCurrentLeads("");
                      }

                      setOpenImportData(true);
                    }}
                  >
                    <VisibilityIcon
                      style={{
                        height: 13,
                        width: 13,
                        fontSize: "small",
                        color: "#35c669",
                        opacity: isSendingInvites ? 0.6 : 1.0
                      }}
                    />
                  </IconButton>
                  <ShowImportData
                    setOpen={setOpenImportData}
                    open={openImportData}
                    title="Send Invites"
                    subtitle={fileInfo}
                    arr={fileData?.sort((a, b) => a[0].localeCompare(b[0]))}
                  >
                  </ShowImportData>
                </Box>
                <Box
                  display='flex'
                  alignItems='center'
                  visibility={fileInfo ? "visible" : "hidden"}
                  height={fileInfo ? 15 : 0}
                  sx={{ marginLeft: theme.spacing(2), marginTop: 0.5 }}
                >
                  <LinearProgress
                    variant={fileInfo && isSendingInvites == false ? "determinate" : "determinate"}
                    value={currentProgress}
                    sx={{
                      width: 200,
                      borderRadius: 5,
                      height: 2,
                      '.MuiLinearProgress-bar': {
                        backgroundColor: "#35c669"
                      }
                    }}
                  />
                  <Typography
                    style={{
                      marginLeft: theme.spacing(1),
                      fontWeight: 400,
                      fontSize: 11,
                      fontStyle: "italic"
                    }}
                    color="text.secondary">
                    {`${currentStage} - ${Math.trunc(currentProgress)}%`}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box display='flex' alignItems='center' justifyContent='right' mt={fileInfo ? 2.5 : 1.1}>
              <Tooltip
                title={"Select & Send Invites"}
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: "#46464f",
                      color: "white"
                    }
                  }
                }}
              >
                <Button
                  disabled={isLoadingInitialGetCurrentSendStatus == true ? true : currentStage == "Finished" || currentStage == "Canceled" ? false : isSendingInvites ? false : false}
                  style={{
                    background: isSendingInvites ? "" : "linear-gradient(75deg, #35c669, #2ec4a9)",
                    borderRadius: 15,
                    backgroundColor: isSendingInvites ? theme.palette.error.main : "#2c2c33",
                    height: 22,
                    fontSize: 10,
                    color: "#fff",
                    opacity: isLoadingInitialGetCurrentSendStatus == true ? 0.6 : currentStage == "Finished" || currentStage == "Canceled" ? 1.0 : isSendingInvites ? 1.0 : 1.0,
                    marginRight: theme.spacing(0.5),
                    paddingRight: theme.spacing(1.5),
                    paddingLeft: theme.spacing(1.5)
                  }}
                  value={currentStage == "Finished" || currentStage == "Canceled" ? "IMPORT MORE" : isSendingInvites ? "CANCEL" : fileInfo ? "SEND INVITES" : "IMPORT"}
                  onClick={(e) => {
                    if (e.currentTarget.value == "IMPORT") {
                      hiddenFileInput.current.click();

                    } else if (e.currentTarget.value == "SEND INVITES" && fileInfo) {
                      sendInvites();

                    } else if (e.currentTarget.value == "CANCEL") {
                      cancelSending();

                    } else if (e.currentTarget.value == "IMPORT MORE") {
                      hiddenFileInput.current.click();
                    }
                  }}
                >
                  {currentStage == "Finished" || currentStage == "Canceled" ? "IMPORT MORE" : isSendingInvites ? "CANCEL" : fileInfo ? "SEND INVITES" : "IMPORT"}
                  <input
                    type="file"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    ref={hiddenFileInput}
                    onChange={(e) => {
                      onFileChange(e);
                      e.target.value = '';
                    }}
                    style={{ display: "none" }}
                  />
                </Button>
              </Tooltip>
              <Tooltip
                title={"Invite Type"}
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: "#46464f",
                      color: "white"
                    }
                  }
                }}
              >
                <Button
                  disabled={isLoadingInitialGetCurrentSendStatus == true ? true : currentStage == "Finished" || currentStage == "Canceled" ? false : isSendingInvites ? false : false}
                  style={{
                    borderRadius: 15,
                    backgroundColor: "#111117",
                    height: 22,
                    fontSize: 10,
                    color: "#fff",
                    outlineColor: "#45454f",
                    outlineStyle: "solid",
                    outlineWidth: 1,
                    opacity: isLoadingInitialGetCurrentSendStatus == true ? 0.6 : currentStage == "Finished" || currentStage == "Canceled" ? 1.0 : isSendingInvites ? 1.0 : 1.0,
                    marginRight: theme.spacing(0.5),
                    marginLeft: theme.spacing(1),
                    paddingRight: theme.spacing(1.5),
                    paddingLeft: theme.spacing(1.5)
                  }}
                  onClick={() => setOpenPreview(true)}
                >
                  {emailType == "Generic Invite" ? "GENERIC INVITE" : "PERSONALIZED INVITE"}
                </Button>
              </Tooltip>
              <Tooltip
                title={"Import Info"}
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: "#46464f",
                      color: "white"
                    }
                  }
                }}
              >
                <IconButton onClick={() => setOpenInfo(true)}>
                  <InfoIcon style={{ color: "#9696a0" }} />
                </IconButton>
              </Tooltip>
              {showImportUploadBtn ? (
                <Tooltip
                  title={"Upload Import File"}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: "#46464f",
                        color: "white"
                      }
                    }
                  }}
                >
                  <IconButton
                    onClick={() => {
                      hiddenFileInputUpload.current.click();
                    }}
                  >
                    {isUploadingData ? (
                      <Loading smaller></Loading>
                    ) : (
                      <UploadIcon style={{ color: "#9696a0" }} />
                    )}

                    <input
                      type="file"
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      ref={hiddenFileInputUpload}
                      onChange={(e) => {
                        onFileChangeUpload(e);
                        e.target.value = '';
                      }}
                      style={{ display: "none" }}
                    />
                  </IconButton>
                </Tooltip>
              ) : (
                <></>
              )}

              <IconButton
                style={{
                  height: 35,
                  width: 35
                }}
                onClick={() => {
                  setOpenHistorical(!openHistorical);

                  if (openHistorical) {
                    if (currentStage == "") {
                      setHeight(60);
                      setOpenHistorical(false);

                    } else {
                      setHeight(80);
                      setOpenHistorical(false);
                    }

                  } else {
                    setHeight(350);
                    setOpenHistorical(true);
                  }
                }}
              >
                <Iconify
                  width={30}
                  icon={'tabler:chevron-right'}
                  sx={{
                    ml: 0.1,
                    color: theme.palette.text.secondary,
                    transform: openHistorical ? 'rotate(90deg)' : 'rotate(0deg)',
                    transition: 'transform 150ms ease'
                  }}
                />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
        <Divider
          style={{
            marginLeft: theme.spacing(4),
            marginRight: theme.spacing(4),
            marginTop: currentStage != "" ? theme.spacing(4.5) : theme.spacing(2),
            visibility: height > 80 ? "visible" : "hidden"
          }}
        />
        <PerfectScrollbar
          style={{
            marginLeft: theme.spacing(4),
            marginRight: theme.spacing(4),
            visibility: height > 80 ? "visible" : "hidden",
            maxHeight: 250
          }}
        >
          <Table>
            <TableHead>
              <TableRow className={classes.tableCell}>
                <TableCell align="left">File Name</TableCell>
                <TableCell align="left">Created By</TableCell>
                <TableCell align="left">Import Size</TableCell>
                <TableCell align="left">Invite Type</TableCell>
                <TableCell align="left">Status</TableCell>
                <TableCell align="left">Follow-Up Status</TableCell>
                <TableCell align="left">Results</TableCell>
                <TableCell align="right">Date Sent</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {historicalInvites?.map((invite: any) => (
                <HistoricalImportRow invite={invite} />
              ))}
            </TableBody>
          </Table>
        </PerfectScrollbar>
        <Info
          setOpen={setOpenInfo}
          open={openInfo}
          title="How to Import Your Data"
          content={<Typography style={{ fontWeight: 400, fontSize: 16, color: "#aaaab4", marginTop: theme.spacing(3) }} align="left">
            Click the 'Import' button to import a CSV document with your student data. There are <u>only two columns</u> that
            should be included and they should have the exact names of, <u>'First Name'</u> and <u>'Email'</u>, <u>in that order</u>. <b>These
              fields are required.</b>
            <br />
            <br />
            Once imported, the data will be organized into columns, and you can easily view. Once you are satisfied
            with the data, click the 'Send Invites' button and your leads will uploaded to the OptN system and
            each of those students will receive an invite email to join.
            <br />
            <br />
            A follow up email will then be sent after 3 days to the students that have not onboarded, followed by one
            more reminder email one week later.
            <br />
            <br />
            <p style={{ fontSize: 12 }}>*Only .csv and .xlsx files will be accepted. If you are on the free plan, you will be
              limited to 50 rows/import. With the Premium plan, you can have up to 3000 rows/import.</p>
          </Typography>}
        />
        <Preview
          setOpen={setOpenPreview}
          open={openPreview}
          title="Invite Email Preview"
          emailType={emailType}
          setEmailType={setEmailType}
          emailTypeDisabled={currentStage == "Finished" || currentStage == "Canceled" ? true : isSendingInvites ? true : false}
          content={emailType == "Generic Invite" ? inviteEmailGenericContent : inviteEmailPersonalizedContent}
        />
      </Card>
    </FadeIn>
  );
}
