// ----------------------------------------------------------------------

export const paths = {
  platform: {
    webapp: `/university-webapp`,
    mobile: `/student-app`
  },
  explore: {
    ecosystem: `/ecosystem`,
    benefits: `/platform-benefits`,
    blogs: {
      root: `/resources`,
    }
  },
  company: {
    about: `/about`,
    careers: {
      root: `/careers`,
      job: `/careers/job`
    },
    contact: `/contact`
  },
  // Auth
  signin: '/signin',
  register: '/claim-school',
  forgotPassword: '/forgot-password',
  verify: '/verify',
  ubsubscribe: '/ubsubscribe',
  // Common
  support: '/support',
  page404: '/404',
  terms: '/terms',
  privacy: '/privacy',
  // Others
  downloadStudentApp: 'https://apps.apple.com/us/app/optn-your-college-connection/id1582513542',
  scheduleDemo: 'https://calendly.com/d/3mn-sc5-mt3/intro-call-demo',
};
