import React from "react";
import { useTheme, TextField } from "@mui/material";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => ({
  input: {
    "& .MuiInputBase-root": {
      backgroundColor: theme.palette.primary.light,
      borderBottomRightRadius: theme.shape.borderRadius,
      borderBottomLeftRadius: theme.shape.borderRadius,
    },
  },
}));

const Input = (props) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <TextField
      className={classes.input}
      sx={{
        "& .MuiInputBase-root": {
          backgroundColor: theme.palette.primary.light,
          borderBottomRightRadius: props.borderRadius ? props.borderRadius : theme.shape.borderRadius,
          borderBottomLeftRadius: props.borderRadius ? props.borderRadius : theme.shape.borderRadius,
          borderTopRightRadius: props.borderRadius ? props.borderRadius : theme.shape.borderRadius,
          borderTopLeftRadius: props.borderRadius ? props.borderRadius : theme.shape.borderRadius,
          outlineColor: "#35353d",
          outlineStyle: "solid",
          outlineWidth: 1,
        },
        "& .MuiInputBase-root.Mui-disabled": {
          backgroundColor: theme.palette.primary.light
        },
        "&.MuiInputBase-root.Mui-hover": {
          backgroundColor: "#FFF"
        }
      }}
      InputProps={{ disableUnderline: true }}
      variant="filled"
      {...props}
    >
      {props.children}
    </TextField>
  );
};

export default Input;
