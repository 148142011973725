import React, { useState, useEffect } from "react";
import Parse from "parse";

import {
  Button,
  TextField,
  Grid,
  IconButton,
  Box,
  Modal,
  Fade,
  List,
  ListItem,
  ListItemText,
  Card,
  CardContent,
  Input,
  CardHeader,
  Typography,
} from "@mui/material";
import { useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import DeleteIcon from "@material-ui/icons/Delete";
import PerfectScrollbar from "react-perfect-scrollbar";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/client";
import { Loading } from "../../../components";
import DoneIcon from "@material-ui/icons/Done";
import SearchIcon from "@material-ui/icons/Search";
import { useAuth } from "../../../hooks/useAuth";

const useStyles = makeStyles((theme: any) => ({
  root: {
    "& .MuiInputBase-root.Mui-disabled": {
      color: "#fff",
    },
  },
  search: {
    display: "flex",
    alignItems: "center",
    height: 44,
    borderRadius: 10,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    backgroundColor: theme.palette.primary.light,
  },
  searchInput: {
    flexGrow: 1,
    marginLeft: theme.spacing(1),
  },
  button: {
    background: "linear-gradient(75deg, #35c669, #2ec4a9)",
    border: 0,
    color: "#fff",
    padding: "0 30px",
    minHeight: "50px",
    borderRadius: 15,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    width: "900px",
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3),
  },
  cancel_button: {
    backgroundColor: "#d32f2f",
    border: 0,
    borderRadius: 5,
    color: "#fff",
    padding: "0 30px",
    minHeight: "50px",
  },
  list: {
    height: 400,
    overflow: "scroll",
    overflowX: "hidden",
  },
}));

const GET_MAJORS = gql`
  query getCollegeMajors($id: ID!) {
    college(id: $id) {
      id
      objectId
      collegeStats {
        id
        objectId
        majors(first: 300) {
          count
          edges {
            node {
              id
              objectId
              name
              category
            }
          }
        }
      }
    }
  }
`;

const GET_ALL_MAJORS = gql`
  query getAllMajors {
    majors(order: name_ASC first: 300) {
      edges {
        node {
          id
          objectId
          name
          category
        }
      }
    }
  }
`;

const DELETE_MAJOR = gql`
  mutation updateCollegeStats($id: ID!, $majorID: [ID!]) {
    updateCollegeStat(
      input: { id: $id, fields: { majors: { remove: $majorID } } }
    ) {
      collegeStat {
        id
        objectId
        majors {
          count
          edges {
            node {
              id
              objectId
              name
              category
            }
          }
        }
      }
    }
  }
`;

const ADD_MAJOR = gql`
  mutation updateCollegeStats($id: ID!, $majorID: [ID!]) {
    updateCollegeStat(
      input: { id: $id, fields: { majors: { add: $majorID } } }
    ) {
      collegeStat {
        id
        objectId
        majors {
          count
          edges {
            node {
              id
              objectId
              name
              category
            }
          }
        }
      }
    }
  }
`;

function AddMajorButton(props) {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [open, setOpen] = useState(false);
  const { data, loading, error } = useQuery(GET_ALL_MAJORS);
  const [addMajor] = useMutation(ADD_MAJOR);
  const [added, setAdded] = useState<any>([]);
  const [filterValue, setFilterValue] = useState("");
  const [majors, setMajors] = useState([]);

  const onSearchChange = (event) => {
    event.preventDefault();
    setFilterValue(event.target.value);
  };

  const filteredMajors =
    majors?.filter((major: any) => {
      if (major) {
        return (
          (("" + major.name)
            .toString()
            .toLowerCase()
            .includes(filterValue.toLowerCase()) ||
            ("" + major.category)
              .toString()
              .toLowerCase()
              .includes(filterValue.toLowerCase()))
        );
      }
      return false;
    }) ?? [];

  useEffect(() => {
    console.log(data);
    setMajors(data?.majors?.edges.map((e) => e.node));
  }, [data]);

  if (loading) return <Loading />;

  return (
    <div>
      <Button
        style={{
          background: "linear-gradient(75deg, #35c669, #2ec4a9)",
          border: 0,
          borderRadius: 15,
          color: "#fff",
          padding: "0 30px",
          minHeight: "45px",
        }}
        variant="contained"
        size="large"
        onClick={() => {
          setOpen(true);
        }}
      >
        Add Major
      </Button>
      <Modal
        className={classes.modal}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        closeAfterTransition
      >
        <Fade in={open}>
          <Card
            style={{
              backgroundColor: theme.palette.primary.dark,
              width: 900,
              display: "flex",
              flexDirection: "column",
              borderRadius: 20,
              padding: theme.spacing(4),
              paddingBottom: 1,
              outlineColor: "#323238",
              outlineStyle: "solid",
              outlineWidth: 1,
            }}
          >
            <CardHeader>
              <Typography variant="h5">Add A Major</Typography>
            </CardHeader>
            <div className={classes.search}>
              <IconButton>
                <SearchIcon />
              </IconButton>
              <Input
                className={classes.searchInput}
                disableUnderline
                placeholder="Search majors..."
                onChange={onSearchChange}
                value={filterValue}
              />
            </div>
            <CardContent>
              <PerfectScrollbar
                className={classes.list}
                options={{ suppressScrollX: true }}
              >
                <List>
                  {filteredMajors.map((maj: any) => (
                    <ListItem
                      button
                      onClick={() => {
                        setAdded([...added, maj.objectId]);
                        // addMajor({
                        //   variables: {
                        //     id: props.collegeStatsID,
                        //     majorID: m.objectId,
                        //   },
                        // });
                        Parse.Cloud.run("addMajorsToCollege", {
                          majorIDs: [maj.objectId],
                        });
                      }}
                    >
                      <ListItemText
                        primary={maj.name}
                        primaryTypographyProps={{
                          noWrap: true,
                          fontWeight: 600,
                          color: "textPrimary",
                        }}
                        secondary={maj.category}
                        secondaryTypographyProps={{
                          noWrap: true,
                          variant: "body2",
                          color: "textSecondary",
                        }}
                      />
                      {added.includes(maj.objectId) ||
                        props.addedMajors.includes(maj.id) ? (
                        <DoneIcon style={{ color: "#35c669" }} />
                      ) : (
                        <></>
                      )}
                    </ListItem>
                  ))}
                </List>
              </PerfectScrollbar>
            </CardContent>
          </Card>
        </Fade>
      </Modal>
    </div>
  );
}

const Majors = () => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const auth = useAuth();
  const [majors, setMajors] = useState<any>([]);
  const [deletedMajors, setDeletedMajors] = useState<any>([]);
  const [filterValue, setFilterValue] = useState("");
  const { data, loading, error } = useQuery(GET_MAJORS, {
    variables: { id: auth.collegeId },
  });
  const [deleteMajor] = useMutation(DELETE_MAJOR);

  const onSearchChange = (event) => {
    event.preventDefault();
    setFilterValue(event.target.value);
  };

  const filteredMajors =
    majors?.filter((major: any) => {
      if (major) {
        return (
          !deletedMajors.includes(major.objectId) &&
          (("" + major.name)
            .toString()
            .toLowerCase()
            .includes(filterValue.toLowerCase()) ||
            ("" + major.category)
              .toString()
              .toLowerCase()
              .includes(filterValue.toLowerCase()))
        );
      }
      return false;
    }) ?? [];

  const handleRemove = (id) => {
    deleteMajor({
      variables: { id: data?.college?.collegeStats?.objectId, majorID: id },
    });
    setDeletedMajors([...deletedMajors, id]);
  };

  const addMajor = (m) => {
    setMajors([...majors, m]);
  };

  useEffect(() => {
    setMajors(data?.college?.collegeStats?.majors?.edges.map((e) => e.node));
  }, [data]);

  if (loading) return <Loading />;

  if (error) console.log(error);

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={9}>
        <div className={classes.search}>
          <IconButton>
            <SearchIcon />
          </IconButton>
          <Input
            className={classes.searchInput}
            disableUnderline
            placeholder="Search majors..."
            onChange={onSearchChange}
            value={filterValue}
          />
        </div>
      </Grid>
      <Grid item xs={3} sx={{ alignSelf: "right" }}>
        <AddMajorButton
          addedMajors={data?.college?.collegeStats?.majors?.edges.map(
            (e) => e.node.id
          )}
          addMajor={addMajor}
          collegeStatsID={data?.college?.collegeStats?.objectId}
        />
      </Grid>
      {filteredMajors.map((maj) => (
        <Grid item xs={12} key={maj.id}>
          <Box sx={{
            outlineWidth: 1,
            outlineStyle: 'solid',
            outlineColor: '#47474f',
            borderRadius: 1.3,
            height: 50,
            paddingLeft: 2.5,
            alignItems: 'center',
            display: 'flex'
          }}>
            <Typography
              style={{
                width: "95%",
                textAlign: 'left'
              }}
            >
              {maj.name + " (" + maj.category + ")"}
            </Typography>
            <Box display='flex' justifyContent='right' sx={{ mr: 1 }}>
              <IconButton sx={{ marginRight: 'auto' }}>
                <DeleteIcon onClick={() => handleRemove(maj.objectId)} />
              </IconButton>
            </Box>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default Majors;
