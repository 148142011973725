import React from "react";
import clsx from "clsx";
import { Container, useTheme } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    backgroundColor: theme.palette.background.dark,
  },
  noScroll: {
    overflowY: "hidden",
  },
  container: {
    height: "100%",
  },
}));

export default function Page(props) {
  const theme = useTheme();
  const classes = useStyles(theme);
  
  return (
    <div
      className={
        props.noScroll ? clsx(classes.root, classes.noScroll) : classes.root
      }
    >
      <Helmet>
        <title>{"OptN | " + props.title}</title>
      </Helmet>
      <Container maxWidth={props.maxWidth} className={classes.container}>
        {props.children}
      </Container>
    </div>
  );
}
