import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Parse from "parse";
import {
  Avatar,
  Box,
  Drawer,
  List,
  Card,
  Button,
  Typography,
  ListItem
} from "@mui/material";
import {
  BarChart as BarChartIcon,
  Bookmark,
  User as UserIcon,
  Users as UsersIcon,
  MessageCircle,
  Send,
  FileText,
  Radio,
  Zap,
  Tool,
  ThumbsUp,
  CreditCard,
} from "react-feather";
import { useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import NavItem from "./NavItem";
import Copyright from "./Copyright";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import Collapse from '@mui/material/Collapse';
import Iconify from '../../src/routes/FrontEnd/Components/iconify';

const useStyles = makeStyles((theme: any) => ({
  topSection: {
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  desktopDrawer: {
    width: 256,
    height: "calc(70%)",
    top: 65.5,
    backgroundColor: theme.palette.background.paper,
  },
  topCard: {
    height: 80,
    width: 225,
  },
  avatar: {
    backgroundColor: "#33333f",
    width: 55,
    height: 55,
  },
  selectedNavItem: {
    color: theme.palette.secondary.main,
    "& .span": {
      color: theme.palette.secondary.main,
    },
  },
  fileBox: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  accordion: {
    border: "hidden",
    boxShadow: "none",
    // background: theme.palette.background.paper,
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    paddingTop: theme.spacing(1),
    '& .Mui-expanded': {
      minHeight: 0,
    },
  },
  accordianDetails: {
    padding: theme.spacing(0),
    margin: theme.spacing(0),
    // background: theme.palette.background.paper
  },
  MuiAccordionroot: {
    "&.MuiAccordion-root:before": {
      backgroundColor: theme.palette.background.paper
    },
  },
  item: {
    display: "flex",
    paddingBottom: 0,
  },
  button: {
    color: theme.palette.text.primary,
    fontWeight: 600,
    justifyContent: "flex-start",
    letterSpacing: 0,
    padding: "10px 8px",
    marginLeft: theme.spacing(-2),
    left: -15,
    textTransform: "none",
    width: "110%",
  },
  icon: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  title: {
    marginRight: "auto",
    color: theme.palette.text.secondary
  },
  selectedTitle: {
    marginRight: "auto",
    color: theme.palette.secondary.main
  },
  active: {
    color: theme.palette.primary.main,
    "& $title": {
      fontWeight: 600,
    },
    "& $icon": {
      color: theme.palette.primary.main,
    },
  },
}));

const GET_ROLES = gql`
  query getRoles($id: ID!) {
    roles(skip: 1, where: { users: { have: { objectId: { equalTo: $id } } } }) {
      edges {
        node {
          id
          objectId
          name
        }
      }
    }
  }
`;

const NavButton = ({ item, key, location }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [open, setOpen] = useState(false);

  return (
    <>
      <ListItem
        style={{
          display: "flex",
          marginTop: 10,
          height: 45
        }}
        disableGutters>
        <Button
          onClick={() => setOpen(!open)}
          sx={{
            color: theme.palette.text.primary,
            fontWeight: 600,
            justifyContent: "flex-start",
            letterSpacing: 0,
            textTransform: "none",
            width: "93%",
          }}
          key={key}
          style={{
            height: 45,
            fontSize: 13
          }}
        >
          {item.img && <item.img className={classes.icon} size="20" />}
          {item.subItems.some(x => (x.href === location.pathname || x.href + "/" === location.pathname)) ? (
            <span className={classes.selectedTitle}>{item.title}</span>
          ) : (
            <span className={classes.title}>{item.title}</span>
          )}
          <Iconify
            width={18}
            icon={'tabler:chevron-right'}
            sx={{
              ml: 1,
              color: theme.palette.text.secondary,
              transform: open ? 'rotate(90deg)' : 'rotate(0deg)',
              transition: 'transform 150ms ease'
            }}
          />
        </Button>
      </ListItem>
      <Collapse in={open} unmountOnExit>
        {item?.subItems?.map((subItem) => (
          <NavItem
            ml={15}
            width= "86.5%"
            href={subItem.href}
            key={subItem.title}
            title={subItem.title}
            icon={subItem.icon}
            fontWeight={500}
            disabled={subItem.disabled}
            selected={
              subItem.href === location.pathname ||
              subItem.href + "/" === location.pathname
            }
          />
        ))}
        <Box height={20} />
      </Collapse>
    </>
  );
};

const SideBar = ({ open, avatar, name, title, checkRoles, roles, tier }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [itemOpen, setItemOpen] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const [items, setItems] = useState(Array());
  const id = Parse.User.current()?.id;
  const { data, loading, error } = useQuery(GET_ROLES, { variables: { id } });

  useEffect(() => {
    var items = [
      {
        href: "/dashboard",
        icon: BarChartIcon,
        title: "Dashboard",
        disabled: false
      },
      {
        href: "/dashboard/favorites",
        icon: Bookmark,
        title: "Favorited Students",
        disabled: false
      },
      {
        href: "/dashboard/accounts",
        icon: UsersIcon,
        title: "Manage Accounts",
        role: "ADMIN",
        disabled: false
      },
      {
        href: "/dashboard/billing",
        icon: CreditCard,
        title: "Billing",
        role: "BILLING",
        disabled: false
      },
      {
        href: "/dashboard/profile",
        icon: UserIcon,
        title: "College Profile",
        role: "PROFILE",
        disabled: false
      },
      {
        href: "/dashboard/social/posts",
        icon: ThumbsUp,
        title: "Social Posts",
        role: "SOCIAL",
        disabled: (tier === "free") ? true : false
      },
      {
        href: "/dashboard/social/ads",
        icon: Radio,
        title: "Advertisements",
        role: "ADS",
        disabled: (tier === "free") ? true : false
      },
      {
        href: "/dashboard/forums",
        icon: FileText,
        title: "Forums",
        role: "FORUMS",
        disabled: (tier === "free") ? true : false
      },
      {
        href: "/dashboard/campaigns",
        icon: Zap,
        title: "Campaigns",
        role: "CAMPAIGNS",
        disabled: (tier === "free") ? true : false
      },
      {
        href: "/dashboard/messages",
        icon: MessageCircle,
        title: "Messaging",
        role: "MESSAGING",
        disabled: (tier === "free") ? true : false
      },
    ].filter((x) => checkRoles(x.role) || x.role === undefined);

    var arrangedItems = Array();

    if (items.some((x) => x.href === "/dashboard")) {
      var menuItemDashboard = {
        title: "",
        img: null,
        subItems: Array()
      }

      menuItemDashboard.subItems.push(items.filter((x) => x.href === "/dashboard")[0]);

      arrangedItems.push(menuItemDashboard);
    }

    if (items.some((x) => x.href === "/dashboard/favorites")) {
      var menuItemFavorites = {
        title: "",
        img: null,
        subItems: Array()
      }

      menuItemFavorites.subItems.push(items.filter((x) => x.href === "/dashboard/favorites")[0]);

      arrangedItems.push(menuItemFavorites);
    }

    if (items.some((x) => x.href === "/dashboard/profile")) {
      var menuItemProfile = {
        title: "",
        img: null,
        subItems: Array()
      }

      menuItemProfile.subItems.push(items.filter((x) => x.href === "/dashboard/profile")[0])

      arrangedItems.push(menuItemProfile);
    }

    if (items.some((x) => x.href === "/dashboard/accounts") || items.some((x) => x.href === "/dashboard/billing")) {
      var menuItemAdmin = {
        title: "Admin Tools",
        img: Tool,
        subItems: Array()
      }

      if (items.some((x) => x.href === "/dashboard/accounts")) {
        menuItemAdmin.subItems.push(items.filter((x) => x.href === "/dashboard/accounts")[0])
      }

      if (items.some((x) => x.href === "/dashboard/billing")) {
        menuItemAdmin.subItems.push(items.filter((x) => x.href === "/dashboard/billing")[0])
      }

      arrangedItems.push(menuItemAdmin);
    }

    if (items.some((x) => x.href === "/dashboard/forums")) {
      var menuItemForums = {
        title: "",
        img: null,
        subItems: Array()
      }

      menuItemForums.subItems.push(items.filter((x) => x.href === "/dashboard/forums")[0])

      arrangedItems.push(menuItemForums);
    }

    if (items.some((x) => x.href === "/dashboard/social/posts") || items.some((x) => x.href === "/dashboard/social/ads")) {
      var menuItemSocialAds = {
        title: "Social & Ads",
        img: Radio,
        subItems: Array()
      }

      if (items.some((x) => x.href === "/dashboard/social/posts")) {
        menuItemSocialAds.subItems.push(items.filter((x) => x.href === "/dashboard/social/posts")[0])
      }

      if (items.some((x) => x.href === "/dashboard/social/ads")) {
        menuItemSocialAds.subItems.push(items.filter((x) => x.href === "/dashboard/social/ads")[0])
      }

      arrangedItems.push(menuItemSocialAds);
    }

    if (items.some((x) => x.href === "/dashboard/messages") || items.some((x) => x.href === "/dashboard/campaigns")) {
      var menuItemMessaging = {
        title: "Messaging",
        img: Send,
        subItems: Array()
      }

      if (items.some((x) => x.href === "/dashboard/messages")) {
        menuItemMessaging.subItems.push(items.filter((x) => x.href === "/dashboard/messages")[0])
      }

      if (items.some((x) => x.href === "/dashboard/campaigns")) {
        menuItemMessaging.subItems.push(items.filter((x) => x.href === "/dashboard/campaigns")[0])
      }

      arrangedItems.push(menuItemMessaging);
    }

    setItems(arrangedItems);
  }, [data, roles, checkRoles]);

  if (error) {
    return <Typography color="error">{error.toString()}</Typography>;
  }

  return (
    <Drawer
      anchor="left"
      open={open}
      variant="persistent"
      PaperProps={{
        sx: {
          top: 69.5,
          height: "calc(100vh - 70px)",
          width: 258,
          overflow: 'hidden'
        }
      }}
    >
      <Box
        className="cardScroll"
        height="100%"
        overflow='auto'
        display="flex"
        flexDirection="column"
        style={{
          position: "absolute",
          top: 10,
          width: 275
        }}
      >
        <Box sx={{ justifyContent: 'center' }}>
          <Card
            className={classes.topCard}
            onClick={() => {
              navigate("/dashboard/settings");
            }}
            component={Button}
            sx={{
              justifyContent: "left",
              display: 'flex',
              marginLeft: 2,
              marginRight: 2,
              marginTop: 1,
              padding: 2,
              borderRadius: 1.5,
              outlineColor: "#323238",
              outlineStyle: "solid",
              outlineWidth: 1,
            }}
          >
            <Avatar src={avatar} className={classes.avatar} sx={{ backgroundColor: "#33333f", width: 50, height: 50, }} />
            {!loading && (
              <Box sx={{ alignItems: 'left' }} ml={1.3} mt={0.0}>
                <Typography
                  className={classes.title}
                  variant="body1"
                  style={{ fontWeight: 600, color: "#fff" }}
                >
                  {name}
                </Typography>
                <Typography color="textSecondary" align="left" variant="body2" sx={{ marginRight: "auto" }}>
                  {title}
                </Typography>
              </Box>
            )}
          </Card>
        </Box>
        <Box p={2} pt={0} >
          <List>
            {items?.map((item, i) => {
              if (item?.title != "") {
                return (
                  <NavButton
                    item={item}
                    key={i}
                    location={location}
                  />
                )
              } else {
                return (
                  item?.subItems?.map((subItem) => (
                    <NavItem
                      width={"93.5%"}
                      href={subItem.href}
                      key={subItem.title}
                      title={subItem.title}
                      icon={subItem.icon}
                      disabled={subItem.disabled}
                      fontWeight={600}
                      selected={
                        subItem.href === location.pathname ||
                        subItem.href + "/" === location.pathname
                      }
                    />
                  ))
                )
              }
            })}
          </List>
        </Box>
        <Box flexGrow={1} />
        <Box p={3} width="77%">
          <Copyright />
        </Box>
      </Box>
    </Drawer >
  );
};

export default SideBar;
