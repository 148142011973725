import React, { useRef, useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import Box from "@mui/material/Box";
import { useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import MessageItem from "./MessageItem";
import Parse from "parse";

const useStyles = makeStyles((theme: any) => ({
  root: {
    overflow: "scroll",
    overflowX: "hidden",
  },
}));

const MessageList = (props) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [lastId, setLastId] = useState("");

  const scrollRef = useRef<any>(null);

  useEffect(() => {
    const scrollMessagesToBottom = () => {
      if (scrollRef.current) {
        scrollRef.current._container.scrollTop =
          scrollRef.current._container.scrollHeight;
      }
    };

    var lastIdTemp = "";

    for (var i = props.messages.length - 1; i > -1; i--) {
      if (props.messages[i].from?.userStudent == null && lastIdTemp == "") {
        lastIdTemp = props.messages[i].objectId;
        break;
      }
    }

    setLastId(lastIdTemp);

    scrollMessagesToBottom();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.messages]);

  return (
    <PerfectScrollbar
      className={classes.root}
      options={{ suppressScrollX: true }}
      ref={scrollRef}
    >
      {props.messages !== undefined &&
        props.messages.map((message) => (
          <MessageItem key={message.id} message={message} isLast={message.objectId == lastId}/>
        ))}
        <Box height={20}/>
    </PerfectScrollbar>
  );
};

export default MessageList;
