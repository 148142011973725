import React, { useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Avatar,
  Box,
  ButtonBase,
  Hidden,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from "@mui/material";
import { makeStyles } from '@mui/styles';
import parse from "parse";
import { useAuth } from "../hooks/useAuth";

const useStyles = makeStyles((theme: any) => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(0.2),
  },
  popover: {
    width: 195,
    backgroundColor: theme.palette.secondary.dark,
    outlineColor: "#2c2c33",
    outlineStyle: "solid",
    outlineWidth: 1,
  },
  menuItem: {
    justifyContent: "left",
  },
}));

const Account = ({ src }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const auth = useAuth();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        ref={ref}
        style={{
          borderRadius: 25,
          backgroundColor: "#0d0d14",
          padding: 5,
          paddingRight: theme.spacing(1),
          paddingLeft: theme.spacing(0.6),
          marginLeft: 10,
          outlineWidth: 1,
          outlineStyle: "solid",
          outlineColor: "#3b3b42"
        }}
      >
        <Avatar alt="User" className={classes.avatar} src={src} sx={{ width: 32, height: 32, backgroundColor: theme.palette.primary.light }} />
        <Hidden smDown>
          <Typography style={{ fontSize: 14, fontWeight: 600, marginRight: 7 }} color="inherit">
            {parse.User.current()?.get("username")}
          </Typography>
        </Hidden>
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        keepMounted
        slotProps={{
          paper: {
            sx: {
              backgroundColor: theme.palette.primary.dark,
              borderRadius: 1.5,
              outlineColor: "#3c3c44",
              outlineStyle: "solid",
              outlineWidth: 1,
            }
          }
        }}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem className={classes.menuItem} component="a" href="mailto:support@joinoptn.com">
          Contact Support
        </MenuItem>
        <MenuItem
          className={classes.menuItem}
          component={RouterLink}
          to="/dashboard/settings"
        >
          Account Information
        </MenuItem>
        <MenuItem className={classes.menuItem} onClick={() => auth.signout()}>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};

export default Account;
