import React from "react";
import { Page } from "../../../components";
import {
  Card,
  Grid,
  Avatar,
  Box,
  Button,
  Typography,
} from "@mui/material";
import { useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme: any) => ({
  root: {},
  card: {
    backgroundColor: theme.palette.background.paper,
    height: theme.spacing(30),
    padding: theme.spacing(2),
  },
  button: {
    background: "linear-gradient(75deg, #35c669, #2ec4a9)",
    color: "#fff",
    width: 160,
  },
  avatar: {
    width: 150,
    height: 150,
  },
  submit: {
    margin: theme.spacing(3),
    marginBottom: theme.spacing(20),
  },
  settings_card: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
    height: 366,
  },
  stat: {
    backgroundColor: theme.palette.primary.light,
    height: 240,
  },
}));

export default function UniversityProfile({ data }) {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Page title="User Profile" maxWidth="lg">
      <Card className={classes.card} sx={{ alignSelf: "center" }}>
        <Grid container>
          <Grid item xs={5} sx={{ alignSelf: "right" }}>
            <Box height="50px" />
            <Link
              to={`/dashboard/messages/create/${data?.userContact?.objectId}`}
            >
              <Button
                className={classes.button}
                variant="contained"
                size="large"
              >
                Direct Message
              </Button>
            </Link>
          </Grid>
          <Grid item xs={2}>
            <Avatar
              className={classes.avatar}
              alt="U"
              src={data?.userContact?.img?.url}
            />
            <Box height="10px" />
          </Grid>
          <Grid item xs={5} sx={{ alignSelf: "left" }}>
            <Box height="50px" />
            <Link to="/dashboard/accounts">
              <Button
                className={classes.button}
                variant="contained"
                size="large"
              >
                Go to Accounts
              </Button>
            </Link>
          </Grid>
        </Grid>
        <Typography variant="h4">
          {data?.userContact?.firstName + " " + data?.userContact?.lastName}
        </Typography>
        <Typography variant="body1" color="textSecondary">
          {data?.userContact?.userUniversity?.jobTitle +
            " at " +
            data?.userContact?.userUniversity?.linkedCollege?.name}
        </Typography>
      </Card>
    </Page>
  );
}
