import React, { useEffect, useState } from "react";
import {
  MenuList,
  Paper,
  MenuItem,
  Typography,
  Button,
  Box,
  IconButton,
} from "@mui/material";
import { useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Page, Loading } from "../../../../components";
import AddIcon from "@material-ui/icons/Add";
import ForumCard from "./ForumCard";
import gql from "graphql-tag";
import { useLazyQuery } from "@apollo/client";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme: any) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    "& ul": {
      display: "inline-flex",
    },
    margin: theme.spacing(3),
  },
  selected: {
    color: theme.palette.secondary.main,
  },
}));

const FETCH_FORUM = gql`
  query fetchForumPost($id: ID!) {
    forum(id: $id) {
      id
      objectId
      createdAt
      title
      content
      numUpVotes
      numDownVotes
      tags {
        edges {
          node {
            id
            tag
            color
          }
        }
      }
      author {
        id
        objectId
        firstName
        lastName
        userStudent {
          id
          objectId
          verificationStatus
          appliedColleges {
            ... on Element {
              value
            }
          }
          acceptedColleges {
            ... on Element {
              value
            }
          }
          enrolledColleges {
            ... on Element {
              value
            }
          }
        }
        img {
          url
        }
      }
      replies(order: numUpVotes_DESC) {
        count
        edges {
          node {
            id
            objectId
            content
            createdAt
            numUpVotes
            numDownVotes
            author {
              objectId
              firstName
              lastName
              userStudent {
                id
                objectId
                verificationStatus
                appliedColleges {
                  ... on Element {
                    value
                  }
                }
                acceptedColleges {
                  ... on Element {
                    value
                  }
                }
                enrolledColleges {
                  ... on Element {
                    value
                  }
                }
              }
              img {
                url
              }
            }
            college {
              objectId
              name
              img {
                url
              }
            }
          }
        }
      }
    }
  }
`;

export default function Forums() {
  const theme = useTheme();
  const classes = useStyles(theme);

  const { id } = useParams();
  const [fetchForum, { data, loading, error }] = useLazyQuery(FETCH_FORUM);

  function getForum() {
    fetchForum({ variables: { id } });
  }

  useEffect(() => {
    getForum();
  }, []);

  if (loading) return <Loading lower />;

  if (error) console.log(JSON.stringify(error, null, 2));

  return (
    <Page title="Forum Post" maxWidth="md">
      <Box p={3} pt={0} key={data?.forum?.id}>
        <ForumCard data={data?.forum} showReplies refreshReplies={getForum} />
      </Box>
    </Page>
  );
}
