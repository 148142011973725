import React, { useEffect, useState } from "react";
import Parse from "parse";
import clsx from "clsx";
import {
  Box,
  IconButton,
  Tooltip,
  Typography,
  colors,
  Button,
  Card,
  Grid,
  SvgIcon,
  Modal,
  Fade,
  Input as RealInput,
  List,
  ButtonBase,
  Avatar
} from "@mui/material";
import { useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import ShareIcon from "@material-ui/icons/Share";
import CommentIcon from "@material-ui/icons/Comment";
import AssessmentIcon from "@material-ui/icons/Assessment";
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import TrackChangesRoundedIcon from '@material-ui/icons/TrackChangesRounded';
import { useParams, useNavigate } from "react-router-dom";
import { gql, useMutation, useLazyQuery } from "@apollo/client";
import CloseIcon from "@material-ui/icons/Close";
import { Search as SearchIcon } from "react-feather";
import { Loading } from "../../../components/Loading";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link as RounterLink } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  likedButton: {
    color: colors.red[500],
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: theme.spacing(3),
  },
  cardAction: {
    width: "auto",
    height: "auto",
    display: "block",
  },
  avatar: {
    height: 42,
    width: 42,
    top: 2,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    backgroundColor: "#1d1d2b",
  },
  search: {
    display: "flex",
    alignItems: "center",
    height: 44,
    borderRadius: 10,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    backgroundColor: theme.palette.primary.light,
    outlineColor: "#33333a",
    outlineStyle: "solid",
    outlineWidth: 1,
  },
  searchInput: {
    flexGrow: 1,
    marginLeft: theme.spacing(1),
  },
}));

const LIKE_POST = gql`
  mutation likePost($postID: ID!, $ucid: ID!) {
    updateCollegePost(
      input: { id: $postID, fields: { likes: { add: [$ucid] } } }
    ) {
      collegePost {
        id
        objectId
        likes {
          count
        }
      }
    }
    updateUserContact(
      input: { id: $ucid, fields: { likedPosts: { add: [$postID] } } }
    ) {
      userContact {
        id
        objectId
        likedPosts {
          edges {
            node {
              id
              objectId
            }
          }
        }
      }
    }
  }
`;

const UNLIKE_POST = gql`
  mutation unlikePost($postID: ID!, $ucid: ID!) {
    updateCollegePost(
      input: { id: $postID, fields: { likes: { remove: [$ucid] } } }
    ) {
      collegePost {
        id
        objectId
        likes {
          count
        }
      }
    }
    updateUserContact(
      input: { id: $ucid, fields: { likedPosts: { remove: [$postID] } } }
    ) {
      userContact {
        id
        objectId
        likedPosts {
          edges {
            node {
              id
              objectId
            }
          }
        }
      }
    }
  }
`;

const FETCH_POST_IMPRESSIONS = gql`
  query getPost($id: ID!, $after: String) {
    collegePost(id: $id) {
      id
      objectId
      impressions(first: 50, after: $after) {
        count
        edges {
          cursor
          node {
            objectId
            fullName
            userStudent {
              id
              objectId
              verificationStatus
              appliedColleges {
                ... on Element {
                  value
                }
              }
              acceptedColleges {
                ... on Element {
                  value
                }
              }
              enrolledColleges {
                ... on Element {
                  value
                }
              }
            }
            img {
              url
            }
            user {
              username
            }
          }
        }
      }
    }
  }
  `;

const FETCH_POST_ANALYTICS = gql`
  query getPost($id: ID!) {
    collegePost(id: $id) {
      id
      objectId
      likes(first: 5000, where: { userStudent: { exists: true } }) {
        edges {
          cursor
          node {
            objectId
            fullName
            userStudent {
              id
              objectId
              verificationStatus
              appliedColleges {
                ... on Element {
                  value
                }
              }
              acceptedColleges {
                ... on Element {
                  value
                }
              }
              enrolledColleges {
                ... on Element {
                  value
                }
              }
            }
            img {
              url
            }
            user {
              username
            }
          }
        }
      }
      taps(first: 5000, where: { userStudent: { exists: true } }) {
        edges {
          cursor
          node {
            objectId
            fullName
            userStudent {
              id
              objectId
              verificationStatus
              appliedColleges {
                ... on Element {
                  value
                }
              }
              acceptedColleges {
                ... on Element {
                  value
                }
              }
              enrolledColleges {
                ... on Element {
                  value
                }
              }
            }
            img {
              url
            }
            user {
              username
            }
          }
        }
      }
    }
  }
  `;

const SEARCH_POST_IMPRESSIONS = gql`
  query getPost($id: ID!, $query: String!) {
    collegePost(id: $id) {
      id
      objectId
      impressions(first: 20, where: { fullName: { matchesRegex: $query, options: "i" } }) {
        count
        edges {
          cursor
          node {
            id
            objectId
            fullName
            userStudent {
              id
              objectId
              verificationStatus
              appliedColleges {
                ... on Element {
                  value
                }
              }
              acceptedColleges {
                ... on Element {
                  value
                }
              }
              enrolledColleges {
                ... on Element {
                  value
                }
              }
            }
            img {
              url
            }
            user {
              username
            }
          }
        }
      }
    }
  }
`;

const Person = ({ person, hasLiked, hasTapped }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const auth = useAuth();

  const capitalize_each_word = (str) => {
    if (!str) {
      return ""
    }

    const arr = str.split(" ");

    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }

    const str2 = arr.join(" ");

    return str2
  }

  return (
    <Grid item xs={12}>
      <RounterLink
        className={classes.cardAction}
        to={`/dashboard/profiles/${person.objectId}`}
        style={{ textDecoration: "none" }}
      >
        <Card
          sx={{
            "&:hover": {
              backgroundColor: theme.palette.primary.light,
              cursor: "pointer"
            },
          }}
          style={{
            backgroundColor: "#202028",
            padding: theme.spacing(1),
            height: 45,
            display: "flex",
          }}
        >
          <Avatar
            alt={person.fullName}
            src={person.img?.url}
            className={classes.avatar}
          />
          <Box sx={{ alignSelf: "left" }} ml={1}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: 'transparent',
              }}
            >
              <Typography variant="body1" fontWeight={600}>
                {capitalize_each_word(person.fullName)}
              </Typography>
              {person?.userStudent?.verificationStatus == "verified" ? (
                <img
                  height={17}
                  width={17}
                  src={"/assets/icons/ic_verify.png"}
                  style={{
                    visibility: person?.userStudent?.verificationStatus == "verified" ? "visible" : "hidden",
                    marginLeft: theme.spacing(0.5),
                  }}
                />
              ) : (<></>)}
              {person?.userStudent?.appliedColleges || person?.userStudent?.acceptedColleges || person?.userStudent?.enrolledColleges ? (
                <Tooltip
                  title={person?.userStudent?.appliedColleges.map((x) => x.value).includes(auth.collegeId) ? "Applied" : person?.userStudent?.acceptedColleges.map((x) => x.value).includes(auth.collegeId) ? "Accepted" : person?.userStudent?.enrolledColleges.map((x) => x.value).includes(auth.collegeId) ? "Enrolled" : "Not Applied"}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: "#46464f",
                        color: "white"
                      }
                    }
                  }}
                >
                  <img
                    height={14}
                    width={14}
                    src={person?.userStudent?.appliedColleges.map((x) => x.value).includes(auth.collegeId) ? "/assets/icons/ic_applied.svg" : person?.userStudent?.acceptedColleges.map((x) => x.value).includes(auth.collegeId) ? "/assets/icons/ic_accepted.svg" : person?.userStudent?.enrolledColleges.map((x) => x.value).includes(auth.collegeId) ? "/assets/icons/ic_enrolled.svg" : "/assets/icons/ic_applied.svg"}
                    style={{
                      visibility: person?.userStudent?.appliedColleges.map((x) => x.value).includes(auth.collegeId) || person?.userStudent?.acceptedColleges.map((x) => x.value).includes(auth.collegeId) || person?.userStudent?.enrolledColleges.map((x) => x.value).includes(auth.collegeId) ? "visible" : "hidden",
                      marginLeft: theme.spacing(0.5),
                    }}
                  />
                </Tooltip>
              ) : (<></>)}
            </div>
            <Typography variant="body2" color="textSecondary">
              {person.user?.username}
            </Typography>
          </Box>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: 70,
              justifyContent: "center",
              marginLeft: 'auto'
            }}
          >
            <Tooltip
              title={hasLiked ? "Has liked" : "Has not liked"}
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: "#46464f",
                    color: "white"
                  }
                }
              }}
            >
              <Box
                display='flex'
                justifyContent='center'
              >
                <Box
                  display='flex'
                  justifyContent='center'
                  alignItems='center'
                  marginLeft={0.5}
                  sx={{
                    background: hasLiked ? "linear-gradient(75deg, #ef4371, #db0f12)" : null,
                    backgroundColor: "#46464d",
                    borderRadius: 20,
                    width: 24,
                    height: 24
                  }}
                >
                  <img
                    alt='check'
                    src={hasLiked ? '/assets/icons/ic_heart-white.svg' : '/assets/icons/ic_heart-gray.svg'}
                    style={{
                      width: 14,
                      height: 14,
                    }}
                  />
                </Box>
              </Box>
            </Tooltip>
            <Tooltip
              title={hasTapped ? "Has tapped" : "Has not tapped"}
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: "#46464f",
                    color: "white"
                  }
                }
              }}
            >
              <Box
                display='flex'
                justifyContent='center'
              >
                <Box
                  display='flex'
                  justifyContent='center'
                  alignItems='center'
                  marginLeft={1}
                  marginRight={0.5}
                  sx={{
                    backgroundColor: hasTapped ? "#35c669" : "#46464d",
                    borderRadius: 20,
                    width: 24,
                    height: 24
                  }}
                >
                  <img
                    alt='check'
                    src={hasTapped ? '/assets/icons/ic_tap-white.svg' : '/assets/icons/ic_tap-gray.svg'}
                    style={{
                      width: 14,
                      height: 14,
                    }}
                  />
                </Box>
              </Box>
            </Tooltip>
          </div>
        </Card>
        <Box height="10px" />
      </RounterLink>
    </Grid>
  );
};

const PostEngagement = (props) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [filterLikes, setFilterLikes] = useState(false);
  const [filterTaps, setFilterTaps] = useState(false);
  const [query, setQuery] = useState("");

  const [getPostImpressions, { data, loading, error, fetchMore }] =
    useLazyQuery(FETCH_POST_IMPRESSIONS);
  const [searchImpressions, { data: searchData, loading: searchLoading }] =
    useLazyQuery(SEARCH_POST_IMPRESSIONS);
  const [getPostAnalytics, { data: analyticsData, loading: analyticsLoading, error: analyticsErrorFetching }] =
    useLazyQuery(FETCH_POST_ANALYTICS);

  const loadMore = async () => {
    try {
      if (fetchMore) {
        await fetchMore({
          variables: {
            after:
              data?.collegePost?.impressions?.edges[data?.collegePost?.impressions?.edges?.length - 1]
                ?.cursor,
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;
            if (fetchMoreResult.collegePost.impressions.edges.length === 0) return prev;
            return {
              ...prev,
              collegePost: {
                ...prev.collegePost,
                impressions: {
                  ...prev.collegePost.impressions,
                  edges: [
                    ...prev.collegePost.impressions.edges,
                    ...fetchMoreResult.collegePost.impressions.edges,
                  ],
                },
              },
            };
          },
        });
      }
    } catch (error) { return }
  };

  useEffect(() => {
    if (filterLikes == false && filterTaps == false) {
      searchImpressions({
        variables: {
          id: props.post.id,
          query: query
        },
      });
    }
  }, [query]);

  useEffect(() => {
    getPostImpressions({
      variables: {
        id: props.post.id,
      },
    })

    getPostAnalytics({
      variables: {
        id: props.post.id,
      },
    })
  }, [props]);

  return (
    <Modal
      className={classes.modal}
      open={props.open}
      onClose={() => props.setOpen(false)}
      closeAfterTransition
    >
      <Fade in={props.open}>
        <Card style={{
          height: Math.min((data?.collegePost?.impressions?.count * 67) + 210, 526),
          overflowX: "hidden",
          backgroundColor: theme.palette.primary.dark,
          display: "flex",
          flexDirection: "column",
          padding: theme.spacing(4),
          borderRadius: 25,
          outlineColor: "#25252b",
          outlineStyle: "solid",
          outlineWidth: 1,
        }}>
          <Box width="500px">
            <Grid container sx={{ alignSelf: "left" }}>
              <Typography style={{ fontWeight: 600, fontSize: 29 }} align="left" color="textPrimary">
                Post Engagement
              </Typography>
              <Box flexGrow={1} />
              <IconButton style={{ width: 40, height: 40 }} onClick={() => props.setOpen(false)}>
                <CloseIcon />
              </IconButton>
            </Grid>
            <Box width="20px" />
            {searchLoading || analyticsLoading || loading ? (
              <div style={{ marginTop: theme.spacing(1) }}>
                <Loading smaller />
              </div>
            ) : (
              <Grid container width={"80%"} marginTop={theme.spacing(0.4)} spacing={2}>
                <Grid item xs={6.7}>
                  <Card style={{
                    backgroundColor: "#040405",
                    height: 12,
                    borderRadius: 30,
                    padding: theme.spacing(1),
                    outlineWidth: 1,
                    outlineStyle: "solid",
                    outlineColor: "#3b3b42"
                  }}>
                    <ButtonBase
                      disableRipple
                      style={{
                        display: 'block',
                        textAlign: 'center',
                        height: 26,
                        top: -7,
                        width: '100%',
                        fontSize: 12,
                        fontWeight: 600,
                        color: "textPrimary",
                      }}
                      onClick={() => {
                        setFilterLikes(false);
                        setFilterTaps(false);
                      }}
                    >
                      {data?.collegePost?.impressions?.count?.toLocaleString() + " Impressions"}
                    </ButtonBase>
                  </Card>
                </Grid>
                <Grid item xs={5.3}>
                  <Card style={{
                    backgroundColor: filterLikes ? "#1a1a21" : "#040405",
                    height: 12,
                    borderRadius: 30,
                    padding: theme.spacing(1),
                    outlineWidth: 1,
                    outlineStyle: "solid",
                    outlineColor: "#3b3b42"
                  }}>
                    <ButtonBase
                      disableRipple
                      style={{
                        display: 'block',
                        textAlign: 'center',
                        height: 26,
                        top: -7,
                        width: '100%',
                        fontSize: 12,
                        fontWeight: 600,
                        color: "textPrimary",
                      }}
                      onClick={() => {
                        setFilterLikes(!filterLikes);
                        setFilterTaps(false);
                      }}
                    >
                      {analyticsData?.collegePost?.likes?.edges?.length + " Likes"}
                    </ButtonBase>
                  </Card>
                </Grid>
                <Grid item xs={5.3}>
                  <Card style={{
                    backgroundColor: filterTaps ? "#1a1a21" : "#040405",
                    height: 12,
                    borderRadius: 30,
                    padding: theme.spacing(1),
                    outlineWidth: 1,
                    outlineStyle: "solid",
                    outlineColor: "#3b3b42"
                  }}>
                    <ButtonBase
                      disableRipple
                      style={{
                        display: 'block',
                        textAlign: 'center',
                        height: 26,
                        top: -7,
                        width: '100%',
                        fontSize: 12,
                        fontWeight: 600,
                        color: "textPrimary",
                      }}
                      onClick={() => {
                        setFilterLikes(false);
                        setFilterTaps(!filterTaps);
                      }}
                    >
                      {analyticsData?.collegePost?.taps?.edges?.length + " Taps"}
                    </ButtonBase>
                  </Card>
                </Grid>
              </Grid>
            )}
            <Box height="20px" />
            <div className={classes.search}>
              <SvgIcon fontSize="small" color="action">
                <SearchIcon />
              </SvgIcon>
              <RealInput
                className={classes.searchInput}
                disableUnderline
                placeholder="Search by name"
                value={query}
                onChange={(e) => {
                  setQuery(e.target.value);
                }}
              />
            </div>
            <Box height="10px" />
            <Grid item xs={12}>
              {/* {recipientLoading ? <Loading /> : <></>} */}
              {data?.collegePost?.impressions?.edges && data?.collegePost?.impressions?.edges?.length > 0 ?
                <div style={{ height: Math.min((data?.collegePost?.impressions?.count * 67) + 145, 350) }}>
                  {filterLikes ? (
                    <>
                      {query != "" ? (
                        <>
                          <PerfectScrollbar>
                            <List>
                              {analyticsData?.collegePost?.likes?.edges?.filter((x) => x.node.fullName.toLowerCase().includes(query.toLowerCase())).map((p) => (
                                <Person
                                  key={p.node.id}
                                  person={p.node}
                                  hasLiked={analyticsData?.collegePost?.likes?.edges?.filter((x) => x.node.objectId == p.node.objectId).length > 0}
                                  hasTapped={analyticsData?.collegePost?.taps?.edges?.filter((x) => x.node.objectId == p.node.objectId).length > 0}
                                />
                              ))}
                            </List>
                            {analyticsData?.collegePost?.likes?.edges?.filter((x) => x.node.fullName.toLowerCase().includes(query.toLowerCase())).length === 0 && (
                              <Box display='flex' justifyContent='center'>
                                <Typography>No results.</Typography>
                              </Box>
                            )}
                          </PerfectScrollbar>
                        </>
                      ) : (
                        <>
                          <PerfectScrollbar>
                            <List>
                              {analyticsData?.collegePost?.likes?.edges?.map((p) => (
                                <Person
                                  key={p.node.id}
                                  person={p.node}
                                  hasLiked={analyticsData?.collegePost?.likes?.edges?.filter((x) => x.node.objectId == p.node.objectId).length > 0}
                                  hasTapped={analyticsData?.collegePost?.taps?.edges?.filter((x) => x.node.objectId == p.node.objectId).length > 0}
                                />
                              ))}
                            </List>
                            {analyticsData?.collegePost?.likes?.edges?.length === 0 && (
                              <Box display='flex' justifyContent='center'>
                                <Typography>No results.</Typography>
                              </Box>
                            )}
                          </PerfectScrollbar>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {filterTaps ? (
                        <>
                          {query != "" ? (
                            <>
                              <PerfectScrollbar>
                                <List>
                                  {analyticsData?.collegePost?.taps?.edges?.filter((x) => x.node.fullName.toLowerCase().includes(query.toLowerCase())).map((p) => (
                                    <Person
                                      key={p.node.id}
                                      person={p.node}
                                      hasLiked={analyticsData?.collegePost?.likes?.edges?.filter((x) => x.node.objectId == p.node.objectId).length > 0}
                                      hasTapped={analyticsData?.collegePost?.taps?.edges?.filter((x) => x.node.objectId == p.node.objectId).length > 0}
                                    />
                                  ))}
                                </List>
                                {analyticsData?.collegePost?.taps?.edges?.filter((x) => x.node.fullName.toLowerCase().includes(query.toLowerCase())).length === 0 && (
                                  <Box display='flex' justifyContent='center'>
                                    <Typography>No results.</Typography>
                                  </Box>
                                )}
                              </PerfectScrollbar>
                            </>
                          ) : (
                            <>
                              <PerfectScrollbar>
                                <List>
                                  {analyticsData?.collegePost?.taps?.edges?.map((p) => (
                                    <Person
                                      key={p.node.id}
                                      person={p.node}
                                      hasLiked={analyticsData?.collegePost?.likes?.edges?.filter((x) => x.node.objectId == p.node.objectId).length > 0}
                                      hasTapped={analyticsData?.collegePost?.taps?.edges?.filter((x) => x.node.objectId == p.node.objectId).length > 0}
                                    />
                                  ))}
                                </List>
                                {analyticsData?.collegePost?.taps?.edges?.length === 0 && (
                                  <Box display='flex' justifyContent='center'>
                                    <Typography>No results.</Typography>
                                  </Box>
                                )}
                              </PerfectScrollbar>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          {query != "" ? (
                            <>
                              <PerfectScrollbar>
                                <List>
                                  {searchData?.collegePost?.impressions?.edges?.map((p) => (
                                    <Person
                                      key={p.node.id}
                                      person={p.node}
                                      hasLiked={analyticsData?.collegePost?.likes?.edges?.filter((x) => x.node.objectId == p.node.objectId).length > 0}
                                      hasTapped={analyticsData?.collegePost?.taps?.edges?.filter((x) => x.node.objectId == p.node.objectId).length > 0}
                                    />
                                  ))}
                                </List>
                                {searchData?.collegePost?.impressions?.edges.length === 0 && (
                                  <Box display='flex' justifyContent='center'>
                                    <Typography>No results.</Typography>
                                  </Box>
                                )}
                              </PerfectScrollbar>
                            </>
                          ) : (
                            <>
                              <PerfectScrollbar onYReachEnd={loadMore}>
                                <List>
                                  {data?.collegePost?.impressions?.edges?.map((p) => (
                                    <Person
                                      key={p.node.id}
                                      person={p.node}
                                      hasLiked={analyticsData?.collegePost?.likes?.edges?.filter((x) => x.node.objectId == p.node.objectId).length > 0}
                                      hasTapped={analyticsData?.collegePost?.taps?.edges?.filter((x) => x.node.objectId == p.node.objectId).length > 0}
                                    />
                                  ))}
                                </List>
                                {data?.collegePost?.impressions?.edges.length === 0 && (
                                  <Box display='flex' justifyContent='center'>
                                    <Typography>No results.</Typography>
                                  </Box>
                                )}
                              </PerfectScrollbar>
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
                : <></>}
            </Grid>
          </Box>
        </Card>
      </Fade>
    </Modal >
  )
}

const CostMetrics = (props) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Modal
      className={classes.modal}
      open={props.open}
      onClose={() => props.setOpen(false)}
      closeAfterTransition
    >
      <Fade in={props.open}>
        <Card style={{
          height: 160,
          overflowX: "hidden",
          backgroundColor: theme.palette.primary.dark,
          display: "flex",
          flexDirection: "column",
          padding: theme.spacing(4),
          borderRadius: 25,
          outlineColor: "#25252b",
          outlineStyle: "solid",
          outlineWidth: 1,
        }}>
          <Box width="500px">
            <Grid container sx={{ alignSelf: "left" }}>
              <Typography style={{ fontWeight: 600, fontSize: 29 }} align="left" color="textPrimary">
                Cost Metrics
              </Typography>
              <Box flexGrow={1} />
              <IconButton style={{ width: 40, height: 40 }} onClick={() => props.setOpen(false)}>
                <CloseIcon />
              </IconButton>
            </Grid>
            <Box width="20px" />
            <Grid container width={"80%"} marginTop={theme.spacing(0.4)} spacing={2}>
              <Grid item xs={6.7}>
                <Tooltip
                  title={"(Total ad cost)"}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: "#46464f",
                        color: "white"
                      }
                    }
                  }}
                >
                  <Card style={{
                    backgroundColor: "#040405",
                    height: 12,
                    borderRadius: 30,
                    padding: theme.spacing(1),
                    outlineWidth: 1,
                    outlineStyle: "solid",
                    outlineColor: "#3b3b42"
                  }}>
                    <ButtonBase
                      disableRipple
                      disabled
                      style={{
                        display: 'block',
                        textAlign: 'center',
                        height: 26,
                        top: -7,
                        width: '100%',
                        fontSize: 12,
                        fontWeight: 600,
                        color: "textPrimary",
                      }}
                    >
                      {"$" + props.totalCost.toFixed(2) + " Total cost"}
                    </ButtonBase>
                  </Card>
                </Tooltip>
              </Grid>
              <Grid item xs={5.3}>
                <Tooltip
                  title={"(Cost per impression)"}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: "#46464f",
                        color: "white"
                      }
                    }
                  }}
                >
                  <Card style={{
                    backgroundColor: "#040405",
                    height: 12,
                    borderRadius: 30,
                    padding: theme.spacing(1),
                    outlineWidth: 1,
                    outlineStyle: "solid",
                    outlineColor: "#3b3b42"
                  }}>
                    <ButtonBase
                      disableRipple
                      disabled
                      style={{
                        display: 'block',
                        textAlign: 'center',
                        height: 26,
                        top: -7,
                        width: '100%',
                        fontSize: 12,
                        fontWeight: 600,
                        color: "textPrimary",
                      }}
                    >
                      {props.totalImpressions > 0 ? "$" + (props.totalCost / props.totalImpressions).toFixed(2) + " CPI" : "N/A CPI"}
                    </ButtonBase>
                  </Card>
                </Tooltip>
              </Grid>
              <Grid item xs={5.3}>
                <Tooltip
                  title={"(Cost per tap)"}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: "#46464f",
                        color: "white"
                      }
                    }
                  }}
                >
                  <Card style={{
                    backgroundColor: "#040405",
                    height: 12,
                    borderRadius: 30,
                    padding: theme.spacing(1),
                    outlineWidth: 1,
                    outlineStyle: "solid",
                    outlineColor: "#3b3b42"
                  }}>
                    <ButtonBase
                      disableRipple
                      disabled
                      style={{
                        display: 'block',
                        textAlign: 'center',
                        height: 26,
                        top: -7,
                        width: '100%',
                        fontSize: 12,
                        fontWeight: 600,
                        color: "textPrimary",
                      }}
                    >
                      {props.totalTaps > 0 ? "$" + (props.totalCost / props.totalTaps).toFixed(2) + " CPT" : "N/A CPT"}
                    </ButtonBase>
                  </Card>
                </Tooltip>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Fade>
    </Modal >
  )
};

const Reactions = ({ className, post, isAd, finalCost, likedPosts, ...props }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [openAnalytics, setOpenAnalytics] = useState(false);
  const [openCostMetrics, setOpenCostMetrics] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const [isLiked, setLiked] = useState(
    likedPosts?.indexOf(post?.objectId) != -1 ?? false
  );
  const [likePost, { data: likeData, error: likeError }] =
    useMutation(LIKE_POST);
  const [unlikePost, { data: unlikeData, error: unlikeError }] =
    useMutation(UNLIKE_POST);

  const handleLike = () => {
    setLiked(true);
    likePost({
      variables: {
        postID: post.objectId,
        liker: Parse.User.current()?.id,
        ucid: Parse.User.current()?.get("userContact").id,
      },
    });
    const q = new Parse.Query(Parse.Object.extend("collegePost"));
    q.get(post.objectId).then((p) => {
      p.increment("numLikes");
      p.save();
    });
  };

  const handleUnlike = () => {
    setLiked(false);
    unlikePost({
      variables: {
        postID: post.objectId,
        liker: Parse.User.current()?.id,
        ucid: Parse.User.current()?.get("userContact").id,
      },
    });
    const q = new Parse.Query(Parse.Object.extend("collegePost"));
    q.get(post.objectId).then((p) => {
      p.decrement("numLikes");
      p.save();
    });
  };

  const handleComment = () => {
    if (id !== post.id) {
      if (isAd) {
        navigate("/dashboard/social/ads/" + post.id);
      } else {
        navigate("/dashboard/social/posts/" + post.id);
      }
    }
  };

  useEffect(() => {
    if (likedPosts) {
      setLiked(likedPosts.includes(post.objectId));
    }
  }, [post]);

  useEffect(() => {
    if (likeData) {
      setLiked(true);
    }
  }, [likeData]);

  useEffect(() => {
    if (unlikeData) {
      setLiked(false);
    }
  }, [unlikeData]);

  if (likeError) console.log(JSON.stringify(likeError, null, 2));

  if (unlikeError) console.log(JSON.stringify(unlikeError, null, 2));

  return (
    <div className={clsx(classes.root, className)}>
      {isLiked ? (
        <Tooltip
          title="Unlike"
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: "#46464f",
                color: "white"
              }
            }
          }}
        >
          <IconButton
            style={{
              color: colors.red[500],
            }}
            onClick={handleUnlike}>
            <FavoriteIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip
          title="Like"
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: "#46464f",
                color: "white"
              }
            }
          }}
        >
          <IconButton onClick={handleLike}>
            <FavoriteBorderIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
      <Typography color="textSecondary" variant="h6">
        {post?.likes?.count}
      </Typography>
      <Box width="15px" />
      <Tooltip
        title="Comments"
        componentsProps={{
          tooltip: {
            sx: {
              bgcolor: "#46464f",
              color: "white"
            }
          }
        }}
      >
        <IconButton
          onClick={handleComment}
          disabled={props.disableCommentButton}
        >
          <CommentIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Typography color="textSecondary" variant="h6">
        {post.numComments}
      </Typography>
      <Box width="15px" />
      <Button
        style={{
          color: "#fff"
        }}
        onClick={() => {
          setOpenAnalytics(true);
        }}
      >
        <AssessmentIcon />
        <Box width="6px" />
        Engagement
      </Button>
      {isAd && (
        <>
          <Box width="5px" />
          <Button
            disabled
            style={{
              color: "#fff"
            }}
          >
            <TrackChangesRoundedIcon />
            <Box width="6px" />
            Possible Reach: {post?.filterQuery?.audienceSize ? post?.filterQuery?.audienceSize : "30,000"}
          </Button>
          <Box width="5px" />
          <Button
            style={{
              color: "#fff"
            }}
            onClick={() => {
              setOpenCostMetrics(true);
            }}
          >
            <MonetizationOnIcon />
            <Box width="6px" />
            Cost: ${post?.finalCost ? post?.finalCost.toFixed(2) : 0}
          </Button>
          <CostMetrics
            open={openCostMetrics}
            setOpen={setOpenCostMetrics}
            totalCost={post?.finalCost}
            totalImpressions={post?.impressions?.count}
            totalTaps={post?.taps?.count}
          />
        </>
      )}
      <PostEngagement
        open={openAnalytics}
        setOpen={setOpenAnalytics}
        post={post}
      />
      <Box flexGrow={1} />
    </div>
  );
};

export default Reactions;
