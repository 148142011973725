import Stack from '@mui/material/Stack';
import { alpha, useTheme } from '@mui/material/styles';

import { bgGradient } from '../../Theme/css';

import Header from '../common/header-simple';

import FadeIn from "react-fade-in";

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
  type: string;
};

export default function AuthBackgroundLayout({ children, type }: Props) {
  const theme = useTheme();

  return (
    <>
      <Header isDark={true} />

      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          ...bgGradient({
            color: alpha(theme.palette.grey[900], 0.92),
            imgUrl: '/assets/background/overlay_2.jpg',
          }),
          px: 2,
          py: 12,
          minHeight: '100vh',
        }}
      >
        <FadeIn>
          <Stack
            spacing={4}
            sx={{
              p: 4,
              width: type == "claim-school" || type == "claim-account" ? 1 : 430,
              mx: 'auto',
              flexShrink: 0,
              maxWidth: 750,
              borderRadius: 3,
              bgcolor: '#282832',
              boxShadow: "0px 0px 40px rgba(17, 17, 17, 0.7)",
              textAlign: { xs: 'center', md: 'left' },
            }}
            style={{
              outlineColor: "#50505c",
              outlineStyle: "solid",
              outlineWidth: 0.5,
            }}
          >
            {children}
          </Stack>
        </FadeIn>
      </Stack>
    </>
  );
}
