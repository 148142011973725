import React, { useEffect, useRef, useState } from "react";
import Parse from "parse";
import {
  Typography,
  Box,
  Menu,
  MenuItem,
  IconButton,
  Card,
  Tooltip,
  Container,
  List,
  SvgIcon,
  Input,
} from "@mui/material";
import FadeIn from "react-fade-in";
import { useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Loading, Page } from "../../../../components";
import RecentThreads from "./RecentThreads";
import FilterListIcon from "@material-ui/icons/FilterList";
import NewThread from "./NewThread";
import { gql, useQuery, useLazyQuery } from "@apollo/client";
import ThreadItem from "./ThreadItem";
import clsx from "clsx";
import { Search as SearchIcon } from "react-feather";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { useAuth } from "../../../../hooks/useAuth";

const useStyles = makeStyles((theme: any) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
  },
  header: {
    display: "flex",
    alignItems: "center",
    height: 64,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  card: {
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    boxShadow: theme.shadows[4],
    borderRadius: 20,
    outlineColor: "#25252b",
    outlineStyle: "solid",
    outlineWidth: 1,
  },
  paper: {
    marginTop: theme.spacing(20),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  button: {
    background: "linear-gradient(75deg, #35c669, #2ec4a9)",
    border: 0,
    color: "#fff",
  },
  filterButton: {
    marginRight: theme.spacing(2)
  },
  search: {
    display: "flex",
    alignItems: "center",
    height: 44,
    borderRadius: 10,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.primary.light,
    outlineColor: "#2a2a30",
    outlineStyle: "solid",
    outlineWidth: 1,
  },
  searchInput: {
    flexGrow: 1,
    marginLeft: theme.spacing(2),
  },
  popover: {
    backgroundColor: theme.palette.secondary.dark,
    width: 320,
    outlineColor: "#2a2a30",
    outlineStyle: "solid",
    outlineWidth: 1,
  },
  hideThreadList: {
    display: "none",
  },
  threads: {
    height: 535,
  },
}));

const GET_ALL_THREADS = gql`
  query getThreads($id: ID!, $after: String) {
    collegeThreads(
      first: 20
      after: $after
      order: updatedAt_DESC
      where: {
        AND: [
          { college: { have: { objectId: { equalTo: $id } } } },
          { claimedBy: { exists: true } }
        ]
      }
    ) {
      count
      edges {
        cursor
        node {
          id
          objectId
          updatedAt
          messages(first: 50, order: createdAt_DESC) {
            edges {
              node {
                id
                content
              }
            }
          }
          student {
            firstName
            lastName
            fullName
            userStudent {
              id
              objectId
              verificationStatus
              appliedColleges {
                ... on Element {
                  value
                }
              }
              acceptedColleges {
                ... on Element {
                  value
                }
              }
              enrolledColleges {
                ... on Element {
                  value
                }
              }
            }
            img {
              url
            }
          }
          college {
            id
            objectId
            name
          }
        }
      }
    }
  }
`;

const SEARCH_ALL_THREADS = gql`
  query getThreads($id: ID!, $query: String!) {
    collegeThreads(
      first: 20
      order: updatedAt_DESC
      where: {
        AND: [
          { college: { have: { objectId: { equalTo: $id } } } },
          { claimedBy: { exists: true } },
          { student: { have: { fullName: { matchesRegex: $query, options: "i" } } } }
        ]
      }
    ) {
      count
      edges {
        cursor
        node {
          id
          objectId
          updatedAt
          messages(first: 50, order: createdAt_DESC) {
            edges {
              node {
                id
                content
              }
            }
          }
          student {
            firstName
            lastName
            fullName
            userStudent {
              id
              objectId
              verificationStatus
              appliedColleges {
                ... on Element {
                  value
                }
              }
              acceptedColleges {
                ... on Element {
                  value
                }
              }
              enrolledColleges {
                ... on Element {
                  value
                }
              }
            }
            img {
              url
            }
          }
          college {
            id
            objectId
            name
          }
        }
      }
    }
  }
`;

const GET_YOUR_THREADS = gql`
  query getThreads($id: ID!, $uuid: ID!) {
    collegeThreads(
      first: 400
      order: updatedAt_DESC
      where: {
        AND: [
          { college: { have: { objectId: { equalTo: $id } } } },
          { claimedBy: { have: { objectId: { equalTo: $uuid } } } },
          { claimedBy: { exists: true } }
        ]
      }
    ) {
      count
      edges {
        node {
          id
          objectId
          updatedAt
          messages(first: 1, order: createdAt_DESC) {
            edges {
              node {
                id
                content
              }
            }
          }
          student {
            firstName
            lastName
            fullName
            userStudent {
              id
              objectId
              verificationStatus
              appliedColleges {
                ... on Element {
                  value
                }
              }
              acceptedColleges {
                ... on Element {
                  value
                }
              }
              enrolledColleges {
                ... on Element {
                  value
                }
              }
            }
            img {
              url
            }
          }
          college {
            id
            objectId
            name
          }
        }
      }
    }
  }
`;

const SEARCH_YOUR_THREADS = gql`
  query getThreads($id: ID!, $query: String!, $uuid: ID!) {
    collegeThreads(
      first: 20
      order: updatedAt_DESC
      where: {
        AND: [
          { college: { have: { objectId: { equalTo: $id } } } },
          { claimedBy: { have: { objectId: { equalTo: $uuid } } } },
          { claimedBy: { exists: true } },
          { student: { have: { fullName: { matchesRegex: $query, options: "i" } } } }
        ]
      }
    ) {
      count
      edges {
        cursor
        node {
          id
          objectId
          updatedAt
          messages(first: 1, order: createdAt_DESC) {
            edges {
              node {
                id
                content
              }
            }
          }
          student {
            firstName
            lastName
            fullName
            userStudent {
              id
              objectId
              verificationStatus
              appliedColleges {
                ... on Element {
                  value
                }
              }
              acceptedColleges {
                ... on Element {
                  value
                }
              }
              enrolledColleges {
                ... on Element {
                  value
                }
              }
            }
            img {
              url
            }
          }
          college {
            id
            objectId
            name
          }
        }
      }
    }
  }
`;

const GET_CAMPAIGN_RESPONSES = gql`
  query getThreads($id: ID!, $ucid: ID!, $after: String) {
    collegeThreads(
      first: 30
      after: $after
      order: updatedAt_DESC
      where: {
        AND: [
          { college: { have: { objectId: { equalTo: $id } } } },
          { claimedBy: { exists: false } },
        ]
        messages: { have: { from: { have: { objectId: { notEqualTo: $ucid } } } } }
      }
    ) {
      count
      edges {
        cursor
        node {
          id
          objectId
          updatedAt
          messages(first: 1, order: createdAt_DESC) {
            count
            edges {
              node {
                id
                content
              }
            }
          }
          student {
            firstName
            lastName
            fullName
            userStudent {
              id
              objectId
              verificationStatus
              appliedColleges {
                ... on Element {
                  value
                }
              }
              acceptedColleges {
                ... on Element {
                  value
                }
              }
              enrolledColleges {
                ... on Element {
                  value
                }
              }
            }
            img {
              url
            }
          }
          college {
            id
            objectId
            name
          }
        }
      }
    }
  }
`;

const SEARCH_CAMPAIGN_RESPONSES = gql`
  query getThreads($id: ID!, $ucid: ID!, $query: String!) {
    collegeThreads(
      first: 50
      order: updatedAt_DESC
      where: {
        AND: [
          { college: { have: { objectId: { equalTo: $id } } } },
          { claimedBy: { exists: false } },
          { student: { have: { fullName: { matchesRegex: $query, options: "i" } } } }
        ]
        messages: { have: { from: { have: { objectId: { notEqualTo: $ucid } } } } }
      }
    ) {
      count
      edges {
        cursor
        node {
          id
          objectId
          updatedAt
          messages(first: 1, order: createdAt_DESC) {
            count
            edges {
              node {
                id
                content
              }
            }
          }
          student {
            firstName
            lastName
            fullName
            userStudent {
              id
              objectId
              verificationStatus
              appliedColleges {
                ... on Element {
                  value
                }
              }
              acceptedColleges {
                ... on Element {
                  value
                }
              }
              enrolledColleges {
                ... on Element {
                  value
                }
              }
            }
            img {
              url
            }
          }
          college {
            id
            objectId
            name
          }
        }
      }
    }
  }
`;

export default function Messaging() {
  const theme = useTheme();
  const classes = useStyles(theme);

  const auth = useAuth();
  const popoverRef = useRef(null);
  const pageRef = useRef(null);
  const options = ["All Direct Messages", "My Direct Messages", "Campaign Responses"];
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const [anchor, setAnchor] = useState(null);
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState("");
  const uuid = Parse.User.current()?.get("userUniversity").id;
  const ucid = Parse.User.current()?.get("userContact").id;

  const [fetchYourThreads, { data: dataMyThreads, loading: loadingMyThreads }] = useLazyQuery(GET_YOUR_THREADS, {
    fetchPolicy: "network-only",
  });

  const [fetchAllThreads, { data: dataAllThreads, loading: loadingAllThreads, fetchMore }] = useLazyQuery(GET_ALL_THREADS, {
    fetchPolicy: "network-only",
  });

  const [fetchCampaignResponseThreads, { data: dataCampaignResponseThreads, loading: loadingCampaignResponseThreads, error: errorCampaignResponseThreads, fetchMore: fetchCampaignResponseMore }] = useLazyQuery(GET_CAMPAIGN_RESPONSES, {
    fetchPolicy: "network-only",
  });

  const [searchAllThreads, { data: searchAllData, loading: searchAllLoading }] = useLazyQuery(SEARCH_ALL_THREADS);
  const [searchYourThreads, { data: searchYourData, loading: searchYourLoading }] = useLazyQuery(SEARCH_YOUR_THREADS);
  const [searchCampaignResponseThreads, { data: searchCampaignResponseData, loading: searchCampaignResponseLoading }] = useLazyQuery(SEARCH_CAMPAIGN_RESPONSES);

  useEffect(() => {
    document.body.style.backgroundColor = "#0e0e14";
  }, []);

  useEffect(() => {
    if (selectedOption == "All Direct Messages") {
      searchAllThreads({
        variables: {
          id: auth.collegeId,
          query: query
        },
      });

    } else if (selectedOption == "My Direct Messages") {
      searchYourThreads({
        variables: {
          id: auth.collegeId,
          query: query,
          uuid: uuid
        },
      });

    } else if (selectedOption == "Campaign Responses") {
      searchCampaignResponseThreads({
        variables: {
          id: auth.collegeId,
          ucid: ucid,
          query: query
        },
      });
    }
  }, [query]);

  useEffect(() => {
    if (selectedOption == "All Direct Messages") {
      fetchAllThreads({
        variables: {
          id: auth.collegeId
        }
      })

    } else if (selectedOption == "My Direct Messages") {
      fetchYourThreads({
        variables: {
          uuid: uuid,
          id: auth.collegeId
        }
      })

    } else if (selectedOption == "Campaign Responses") {
      fetchCampaignResponseThreads({
        variables: {
          id: auth.collegeId,
          ucid: ucid,
        }
      })
    }

  }, [selectedOption]);

  const loadMore = async () => {
    if (selectedOption == "All Direct Messages" && dataAllThreads?.collegeThreads?.count > 0) {
      try {
        if (fetchMore) {
          await fetchMore({
            variables: {
              after:
                dataAllThreads?.collegeThreads?.edges[dataAllThreads?.collegeThreads?.edges?.length - 1]
                  ?.cursor,
            },
            updateQuery: (prev, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prev;
              if (fetchMoreResult.collegeThreads.edges.length === 0) return prev;
              return {
                ...prev,
                collegeThreads: {
                  ...prev.collegeThreads,
                  edges: [
                    ...prev.collegeThreads.edges,
                    ...fetchMoreResult.collegeThreads.edges,
                  ],
                },
              };
            },
          });
        }
      } catch (error) { return }

    } else if (selectedOption == "Campaign Responses" && dataCampaignResponseThreads?.collegeThreads?.count > 0) {
      try {
        if (fetchCampaignResponseMore) {
          await fetchCampaignResponseMore({
            variables: {
              after:
                dataCampaignResponseThreads?.collegeThreads?.edges[dataCampaignResponseThreads?.collegeThreads?.edges?.length - 1]
                  ?.cursor,
            },
            updateQuery: (prev, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prev;
              if (fetchMoreResult.collegeThreads.edges.length === 0) return prev;
              return {
                ...prev,
                collegeThreads: {
                  ...prev.collegeThreads,
                  edges: [
                    ...prev.collegeThreads.edges,
                    ...fetchMoreResult.collegeThreads.edges,
                  ],
                },
              };
            },
          });
        }
      } catch (error) { return }
    }
  };

  if (errorCampaignResponseThreads) console.log(JSON.stringify(errorCampaignResponseThreads, null, 2));

  if (loadingAllThreads) return <Loading lower />;
  if (loadingMyThreads) return <Loading lower />;
  if (loadingCampaignResponseThreads) return <Loading lower />;

  if (loadingAllThreads == false && loadingMyThreads == false && loadingCampaignResponseThreads == false) {
    return (
      <Page
        title="Messaging"
        ref={pageRef}
        maxWidth="xl"
      >
        <FadeIn>
          <Card style={{
            backgroundColor: theme.palette.primary.dark,
            paddingTop: theme.spacing(3),
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            boxShadow: theme.shadows[4],
            borderRadius: 25,
            outlineColor: "#25252b",
            outlineStyle: "solid",
            outlineWidth: 1,
            height: "calc(100vh - 155px)"
          }}>
            <FadeIn>
              <div className={classes.header}>
                <Typography color="textPrimary" style={{ fontSize: 30, fontWeight: 600 }}>
                  Messaging
                </Typography>
                <Box width="20px" />
                {searchYourLoading || searchAllLoading || searchCampaignResponseLoading ? (
                  <Loading smaller />
                ) : (
                  <>
                    {query != "" ? (
                      <>
                        {selectedOption == "All Direct Messages" ? (
                          <Typography variant="body2" color="textSecondary">{`(${searchAllData?.collegeThreads?.count?.toLocaleString() ?? 0
                            } Results)`}</Typography>
                        ) : (
                          <>
                            {selectedOption == "My Direct Messages" ? (
                              <Typography variant="body2" color="textSecondary">{`(${searchYourData?.collegeThreads?.count?.toLocaleString() ?? 0
                                } Results)`}</Typography>
                            ) : (
                              <>
                                <Typography variant="body2" color="textSecondary">{`(${searchCampaignResponseData?.collegeThreads?.count?.toLocaleString() ?? 0
                                  } Results)`}</Typography>
                              </>
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {selectedOption == "All Direct Messages" ? (
                          <Typography variant="body2" color="textSecondary">{`(${dataAllThreads?.collegeThreads?.count?.toLocaleString() ?? 0
                            } Message Threads)`}</Typography>
                        ) : (
                          <>
                            {selectedOption == "My Direct Messages" ? (
                              <Typography variant="body2" color="textSecondary">{`(${dataMyThreads?.collegeThreads?.count?.toLocaleString() ?? 0
                                } Message Threads)`}</Typography>
                            ) : (
                              <>
                                <Typography variant="body2" color="textSecondary">{`(${dataCampaignResponseThreads?.collegeThreads?.count?.toLocaleString() ?? 0
                                  } Responses)`}</Typography>
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
                <Box flexGrow={1} />
                <Typography variant="body1" color="textSecondary">
                  {selectedOption}
                </Typography>
                <Tooltip
                  title="Filter By"
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: "#46464f",
                        color: "white"
                      }
                    }
                  }}
                >
                  <IconButton
                    size="large"
                    className={classes.filterButton}
                    ref={popoverRef}
                    onClick={(e: any) => {
                      setOpen(true);
                      setAnchor(e.currentTarget);
                    }}
                  >
                    <FilterListIcon />
                  </IconButton>
                </Tooltip>
                <Menu
                  id="post-menu"
                  anchorEl={anchor}
                  open={open}
                  slotProps={{
                    paper: {
                      sx: {
                        backgroundColor: theme.palette.primary.dark,
                        borderRadius: 1.5,
                        outlineColor: "#3c3c44",
                        outlineStyle: "solid",
                        outlineWidth: 1,
                      }
                    }
                  }}
                  onClose={() => setOpen(false)}
                >
                  {options.map((option) => (
                    <MenuItem
                      onClick={() => {
                        setOpen(false);
                        setSelectedOption(option);
                      }}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </Menu>
                <NewThread />
              </div>
            </FadeIn>
            <Box height="20px" />
            <div className={classes.search}>
              <SvgIcon fontSize="small" style={{ color: "#fff" }}>
                <SearchIcon />
              </SvgIcon>
              <Input
                className={classes.searchInput}
                disableUnderline
                placeholder="Search by name"
                value={query}
                onChange={(e) => {
                  setQuery(e.target.value);
                }}
              />
            </div>
            <Container style={{ height: "calc(100vh - 300px)" }} maxWidth={false}>
              {query != "" ? (
                <>
                  {selectedOption == "All Direct Messages" ? (
                    <PerfectScrollbar>
                      <List>
                        {searchAllData?.collegeThreads?.edges?.map((t) => (
                          <ThreadItem key={t.node.id} active={false} thread={t.node} className={null} onSelect={null} />
                        ))}
                      </List>
                    </PerfectScrollbar>
                  ) : (
                    <>
                      {selectedOption == "My Direct Messages" ? (
                        <PerfectScrollbar>
                          <List>
                            {searchYourData?.collegeThreads?.edges?.map((t) => (
                              <ThreadItem key={t.node.id} active={false} thread={t.node} className={null} onSelect={null} />
                            ))}
                          </List>
                        </PerfectScrollbar>
                      ) : (
                        <>
                          <PerfectScrollbar>
                            <List>
                              {searchCampaignResponseData?.collegeThreads?.edges?.map((t) => (
                                <ThreadItem key={t.node.id} active={false} thread={t.node} className={null} onSelect={null} />
                              ))}
                            </List>
                          </PerfectScrollbar>
                        </>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  {selectedOption == "All Direct Messages" ? (
                    <>
                      <PerfectScrollbar onYReachEnd={loadMore}>
                        <List>
                          {dataAllThreads?.collegeThreads?.edges?.map((t) => (
                            <ThreadItem key={t.node.id} active={false} thread={t.node} className={null} onSelect={null} />
                          ))}
                        </List>
                        {dataAllThreads?.collegeThreads?.edges.length === 0 && (
                          <Box display='flex' justifyContent='center'>
                            <Typography>No results.</Typography>
                          </Box>
                        )}
                      </PerfectScrollbar>
                    </>
                  ) : (
                    <>
                      {selectedOption == "My Direct Messages" ? (
                        <PerfectScrollbar>
                          <List>
                            {dataMyThreads?.collegeThreads?.edges?.map((t) => (
                              <ThreadItem key={t.node.id} active={false} thread={t.node} className={null} onSelect={null} />
                            ))}
                          </List>
                          {dataMyThreads?.collegeThreads?.edges.length === 0 && (
                            <Box display='flex' justifyContent='center'>
                              <Typography>No results.</Typography>
                            </Box>
                          )}
                        </PerfectScrollbar>
                      ) : (
                        <>
                          <PerfectScrollbar onYReachEnd={loadMore}>
                            <List>
                              {dataCampaignResponseThreads?.collegeThreads?.edges?.map((t) => (
                                <ThreadItem key={t.node.id} active={false} thread={t.node} className={null} onSelect={null} />
                              ))}
                            </List>
                            {dataCampaignResponseThreads?.collegeThreads?.edges.length === 0 && (
                              <Box display='flex' justifyContent='center'>
                                <Typography>No results.</Typography>
                              </Box>
                            )}
                          </PerfectScrollbar>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </Container>
          </Card>
        </FadeIn>
      </Page>
    );
  }

  return <></>;
}
