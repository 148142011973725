import './global.css';

// ----------------------------------------------------------------------

import ThemeProvider from './Theme';
import Router from './Routes/sections';
import MotionLazy from './Components/animate/motion-lazy';
import { useScrollToTop } from './Hooks/use-scroll-to-top';
import { SettingsDrawer, SettingsProvider } from './Components/settings';

// ----------------------------------------------------------------------

export default function App() {
  useScrollToTop();

  return (
    <SettingsProvider
      defaultSettings={{
        themeMode: 'light', // 'light' | 'dark'
        themeDirection: 'ltr', //  'rtl' | 'ltr'
        themeColorPresets: 'default', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
      }}
    >
      <ThemeProvider>
        <MotionLazy>
          <Router />
        </MotionLazy>
      </ThemeProvider>
    </SettingsProvider>
  );
}
