import React, { useEffect, useState } from "react";

import {
  Box,
  Container,
  IconButton,
  Tooltip,
  Typography,
  TextField,
  Paper,
  Grid,
} from "@mui/material";
import FadeIn from "react-fade-in";
import { useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import MoreIcon from "@material-ui/icons/MoreVert";
import RoomIcon from "@material-ui/icons/Room";
import SchoolIcon from "@material-ui/icons/School";
import EmojiEventsIcon from "@material-ui/icons/EmojiEvents";
// import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
// import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import ProfileImage from "./ProfileImage";
import BannerImage from "./BannerImage";

const useStyles = makeStyles((theme: any) => ({
  root: {
    // marginBottom: -330,
  },
  cover: {
    position: "relative",
    height: 460,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    borderRadius: 15,
    "&:before": {
      position: "absolute",
      content: '" "',
      top: 0,
      left: 0,
      height: "100%",
      width: "100%",
      backgroundImage:
        "linear-gradient(-180deg, rgba(0,0,20,0.00) 58%, rgba(0,0,20,0.32) 100%)",
    },
    "&:hover": {
      "& $changeBannerButton": {
        visibility: "visible",
      },
    },
  },
  changeBannerButton: {
    visibility: "hidden",
    position: "absolute",
    top: theme.spacing(3),
    bottom: "auto",
    right: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
    "&:hover": {
      backgroundColor: theme.palette.background.paperLight,
    },
  },
  action: {
    marginLeft: theme.spacing(1),
  },
  actionButton: {
    background: "linear-gradient(75deg, #35c669, #2ec4a9)",
    color: theme.palette.text.primary,
    fontStyle: "bold",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    height: 285,
    position: "relative",
    top: -45,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderRadius: 15,
    outlineColor: "#323238",
    outlineStyle: "solid",
    outlineWidth: 1,
  },
  container: {
    top: -335,
    position: "relative",
  },
  gridList: {
    marginTop: -25,
    display: "flex",
    overflow: "auto",
    flexWrap: "nowrap",
  },
  scrollCard: {
    width: "12rem",
    height: "110px",
    padding: "1rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    borderRadius: 17,
    backgroundColor: theme.palette.primary.light,
    outlineColor: "#37373d",
    outlineStyle: "solid",
    outlineWidth: 1,
  },
  descriptor: {
    color: theme.palette.text.secondary,
  },
  ataglance: {
    marginLeft: theme.spacing(1),
  },
}));

export default function Header(props) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [scrollCards, setScrollCards] = useState<any>([]);

  useEffect(() => {
    setScrollCards([
      {
        title: "# Students Enrolled",
        value: props.data.college?.collegeStats?.numStudentsEnrolled ? props.data.college?.collegeStats?.numStudentsEnrolled
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "No Data",
      },
      {
        title: "Graduation Rate (8yr)",
        value:
          props.data.college?.collegeStats?.graduationRate8yr ? (props.data.college?.collegeStats?.graduationRate8yr * 100)
            .toString()
            .substring(0, 5) + "%" : "No Data",
      },
      {
        title: "Avg In-State Tuition",
        value: props.data.college?.collegeStats?.avgTuitionInState ? "$" + props.data.college?.collegeStats?.avgTuitionInState
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "No Data",
      },
      {
        title: "Avg Out-of-State Tuition",
        value: props.data.college?.collegeStats?.avgTuitionOutOfState ? "$" + props.data.college?.collegeStats?.avgTuitionOutOfState
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "No Data",
      },
      {
        title: "Avg SAT Score",
        value: props.data.college?.collegeStats?.SATavg ? props.data.college?.collegeStats?.SATavg : "No Data",
      },
      {
        title: "Avg ACT Score",
        value: props.data.college?.collegeStats?.ACTavg ? props.data.college?.collegeStats?.ACTavg : "No Data",
      },
    ]);
  }, [props.data]);

  return (
    <FadeIn>
      <Box>
        <BannerImage url={props.data.college.bannerImg?.url} />
        <Paper style={{
          backgroundColor: theme.palette.background.paper,
          height: 295,
          position: "relative",
          top: -45,
          borderBottomLeftRadius: 25,
          borderBottomRightRadius: 25,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          outlineColor: "#323238",
          outlineStyle: "solid",
          outlineWidth: 1,
        }} elevation={0}
        >
          <Box
            display='flex'
            mt={1}
            sx={{
              height: 100,
              paddingLeft: 2,
              paddingRight: 2
            }}
          >
            <Box sx={{ width: 130, ml: 0.8 }}>
              <ProfileImage url={props.data.college?.img?.url} />
            </Box>
            <Box sx={{ ml: 2 }}>
              <Box>
                {props.data.editing ? (
                  <TextField
                    fullWidth
                    size="small"
                    name="officialName"
                    variant="outlined"
                    value={props.data.officialName}
                    onChange={props.handleChange}
                  />
                ) : (
                  <Typography style={{ fontWeight: 600, fontSize: 33, marginBottom: 2, marginTop: 2 }} color="textPrimary">
                    {props.data.college.name}
                  </Typography>
                )}
              </Box>
              <Box>
                <Grid container alignItems="center" spacing={3}>
                  <Grid item>
                    <Box display="flex" flexDirection="row">
                      <RoomIcon style={{ color: theme.palette.text.secondary }} />
                      <Box width="3px" />
                      <Typography color="textSecondary">
                        {props.data.college.city}, {props.data.college.state}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box display="flex" flexDirection="row">
                      <SchoolIcon style={{ color: theme.palette.text.secondary }} />
                      <Box width="4px" />
                      <Typography color="textSecondary">
                        {props.data.college.isPublic
                          ? "Public Institution"
                          : "Private Institution"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box
                      className={classes.descriptor}
                      style={{ color: theme.palette.text.secondary, }}
                      display="flex"
                      flexDirection="row"
                    >
                      <EmojiEventsIcon />
                      <Box width="3px" />
                      <Typography>
                        #{props.data.college.ranking} in National Universities
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
          <Box height="15px" />
          <Box
            overflow={'hidden'}
          >
            <Typography
              style={{ 
                marginLeft: theme.spacing(4),
                fontSize: 23,
                fontWeight: 600
              }}
              color="textPrimary"
            >
              At a glance
            </Typography>
            <Box height="12px" />
            <Grid
              container
              style={{
                marginTop: -25,
                display: "flex",
                overflow: "auto",
                height: 200,
                flexWrap: "nowrap",
                paddingBottom: 2,
                paddingLeft: 30,
                paddingRight: 30
              }} spacing={3}>
              {scrollCards.map((item) => (
                <Grid item sx={{ alignSelf: "center" }} key={item.title.toString()}>
                  <Paper
                    style={{
                      width: 155,
                      height: 75,
                      marginTop: theme.spacing(-7),
                      padding: "1rem",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      borderRadius: 22,
                      backgroundColor: "#0e0e19",
                      outlineColor: "#37373d",
                      outlineStyle: "solid",
                      outlineWidth: 1,
                    }} elevation={5}>
                    <Typography variant="body1" style={{ textAlign: 'center', marginBottom: 3 }}>{item.title}</Typography>
                    <Typography style={{ textAlign: 'center', fontWeight: 600 }} color="textSecondary" variant="h5">{item.value}</Typography>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Paper>
      </Box>
    </FadeIn>
  );
}
