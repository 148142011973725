import { lazy } from 'react';

// ----------------------------------------------------------------------

const JobPage = lazy(() => import('../../Pages/careers/job'));
const JobsPage = lazy(() => import('../../Pages/careers/jobs'));

// ----------------------------------------------------------------------

export const careerRoutes = [
  {
    path: 'careers',
    children: [
      { element: <JobsPage />, index: true },
      { path: ':id', element: <JobPage /> },
    ],
  },
];
