import { lazy } from 'react';

// ----------------------------------------------------------------------

const BlogPage = lazy(() => import('../../Pages/_explore/blog/posts'));
const PostPage = lazy(() => import('../../Pages/_explore/blog/post'));

// ----------------------------------------------------------------------

export const blogRoutes = [
  {
    path: 'resources',
    children: [
      { element: <BlogPage />, index: true },
      { path: ':id', element: <PostPage /> },
    ],
  },
];
