import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import {
  Avatar,
  Box,
  Link,
  Typography,
  Grid,
  Button,
} from "@mui/material";
import { useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: "flex",
    marginBottom: theme.spacing(1),
  },
  bubble: {
    borderRadius: 12,
    // backgroundColor: theme.palette.primary.light,
  },
}));

const Comment = ({ className, comment, ...rest }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [viewAllComments, setViewAllComments] = useState(false);

  const parseDate = (date) => {
    let d = new Date(date);
    let ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
    let mo = new Intl.DateTimeFormat("en", { month: "short" }).format(d);
    let da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
    return `${mo} ${da}, ${ye}`;
  };

  return (
    <>
      <div style={{ marginTop: theme.spacing(1) }} className={clsx(classes.root, className)} {...rest}>
        <Avatar
          alt="Person"
          component={RouterLink}
          src={comment.author?.img?.url}
          to={`/dashboard/profiles/${comment?.author?.objectId}`}
        />
        <Box flexGrow={1} pl={1} ml={1.3} pr={2} pb={1.5} className={classes.bubble}>
          <Box display="flex" alignItems="center" mb={0}>
            <Link
              color="textSecondary"
              component={RouterLink}
              to={`/dashboard/profiles/${comment?.author?.objectId}`}
              style={{
                cursor: 'pointer',
                fontSize: 15,
                fontFamily: "sans-serif",
                fontWeight: 600,
                marginTop: -3
              }}
            >
              {comment?.author?.firstName + " " + comment?.author?.lastName}
            </Link>
            <Box flexGrow={1} />
            <Typography color="textSecondary" variant="caption">
              {parseDate(comment.createdAt)}
            </Typography>
          </Box>
          <Typography
            style={{
              marginTop: theme.spacing(0.3),
              fontSize: 14,
              fontFamily: "sans-serif",
              fontWeight: 500
            }}
            color="textPrimary">
            {comment.content}
          </Typography>
        </Box>
      </div>
      <Grid container>
        {comment.replies?.edges
          ?.map((e) => e.node)
          .slice(0, viewAllComments ? comment.replies?.edges.length : 2)
          .map((reply) => {
            return (
              <>
                <Grid item xs={1} />
                <Grid item xs={11}>
                  <div className={classes.root}>
                    <Avatar
                      alt="Person"
                      component={RouterLink}
                      src={reply.author?.img?.url}
                      style={{
                        width: 36,
                        height: 36
                      }}
                      to={`/dashboard/profiles/${reply?.author?.objectId}`}
                    />
                    <Box flexGrow={1} pl={1} ml={1.3} pr={2} pb={1.6} className={classes.bubble}>
                      <Box display="flex" alignItems="center" mb={0}>
                        <Link
                          color="textSecondary"
                          component={RouterLink}
                          to={`/dashboard/profiles/${reply?.author?.objectId}`}
                          variant="h6"
                          style={{
                            cursor: 'pointer',
                            fontSize: 14,
                            fontFamily: "sans-serif",
                            fontWeight: 600,
                            marginTop: -7
                          }}
                        >
                          {reply?.author?.firstName +
                            " " +
                            reply?.author?.lastName}
                        </Link>
                        <Box flexGrow={1} />
                        <Typography color="textSecondary" variant="caption">
                          {parseDate(reply.createdAt)}
                        </Typography>
                      </Box>
                      <Typography
                        style={{
                          fontSize: 13,
                          fontFamily: "sans-serif",
                          fontWeight: 500
                        }}
                        color="textPrimary">
                        {reply.content}
                      </Typography>
                    </Box>
                  </div>
                </Grid>
              </>
            );
          })}
        {!viewAllComments && (
          <>
            {comment.replies.count !== 0 && (
              <>
                <Grid item xs={1} />
                <Grid item xs={11} display="flex" sx={{ justifyContent: "left" }} >
                  <Button
                    size="small"
                    style={{
                      paddingRight: "10px",
                      paddingLeft: "10px",
                      backgroundColor: "#373741",
                      color: "#fff",
                      fontSize: 11,
                      marginBottom: theme.spacing(3)
                    }}
                    onClick={
                      () => setViewAllComments(true)
                    }
                  >
                    Load More Replies
                  </Button>
                  <Box height="10px" />
                </Grid>
              </>
            )}
          </>
        )}
      </Grid>
    </>
  );
};

export default Comment;
