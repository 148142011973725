import React, { useState, useEffect } from "react";
import Parse from "parse";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  CardHeader,
  Grid,
  TextField,
  CardMedia,
  Divider,
  Link,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Button as MuiButton
} from "@mui/material";
import { useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Reactions from "../Reactions";
import Comment from "../Comment";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { parseDate } from "../../../../util";
import {
  Button,
  Modal as CustomModal
} from "../../../../components";
import ChatIcon from "@material-ui/icons/Chat";
import { useAuth } from "../../../../hooks/useAuth";

const useStyles = makeStyles((theme: any) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[4],
  },
  date: {
    marginLeft: 6,
  },
  media: {
    height: 500,
    backgroundPosition: "top",
  },
  input: {
    "& .MuiInputBase-root": {
      backgroundColor: theme.palette.primary.light,
      borderBottomRightRadius: theme.shape.borderRadius,
      borderBottomLeftRadius: theme.shape.borderRadius,
    },
  },
  cancelButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.text.primary,
  },
  errorButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.text.primary,
  },
}));

const UPDATE_POST = gql`
  mutation updatePost($id: ID!, $msg: String!) {
    updateCollegePost(input: { id: $id, fields: { message: $msg } }) {
      collegePost {
        id
        objectId
        message
      }
    }
  }
`;

const PostCard = (props) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const navigate = useNavigate();
  const auth = useAuth();

  const [openMenu, setOpenMenu] = useState(false);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [editing, setEditing] = useState(false);
  const [message, setMessage] = useState("");
  const [anchor, setAnchor] = useState(null);
  const [deleted, setDeleted] = useState(false);

  const [updatePost] = useMutation(UPDATE_POST);

  const handleDelete = (event) => {
    event.preventDefault();
    Parse.Cloud.run("deletePost", { id: props.data?.objectId }).then(() =>
      setDeleted(true)
    );
    setOpenDeleteConfirm(false)
    setOpenMenu(false);
    if (props.backOnDelete) {
      navigate("/dashboard/social/posts");
    }
  };

  const handleEdit = (event) => {
    event.preventDefault();
    setMessage(props?.data?.message);
    setEditing(true);
    setOpenMenu(false);
  };

  const handleSaveEdit = (event) => {
    event.preventDefault();
    updatePost({ variables: { id: props.data?.objectId, msg: message } });
    setEditing(false);
  };

  const handleCancelEdit = (event) => {
    event.preventDefault();
    setMessage(props?.data?.message);
    setEditing(false);
  };

  useEffect(() => {
    console.log(props?.data);
  }, []);

  if (deleted) return <></>; // TODO: Add notistack notification for deleted post

  return (
    <Box p={3} pt={0}>
      <Card
        style={{
          backgroundColor: theme.palette.primary.dark,
          borderRadius: 25,
          padding: theme.spacing(1),
          outlineColor: "#25252b",
          outlineStyle: "solid",
          outlineWidth: 1,
          boxShadow: theme.shadows[4],
        }}
      >
        <CardHeader
          avatar={
            <Avatar
              alt="Person"
              component={RouterLink}
              src={props.data?.by?.img?.url}
              to="profile"
              style={{
                top: props.isAd ? -14 : 0,
                height: 45,
                width: 45
              }}
            />
          }
          disableTypography
          subheader={
            <>
              <Box display="flex" alignItems="center">
                <Typography variant="caption" color="textSecondary" fontSize={14}>
                  {parseDate(props.data?.createdAt)}
                </Typography>
              </Box>
              {props.data?.isAd && (
                <Box display='flex' alignItems='center' height={30}>
                  <Box height="4px" />
                  <ChatIcon
                    fontSize="small"
                    style={{
                      color: "#fff"
                    }}
                  />
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    height={20}
                    style={{
                      marginLeft: theme.spacing(1)
                    }}
                  >
                    {` Sponsored`}
                  </Typography>
                </Box>
              )}
            </>
          }
          title={
            <Link
              color="textPrimary"
              component={RouterLink}
              to={
                props.data?.by?.objectId === auth.collegeId
                  ? "/dashboard/profile"
                  : "#"
              }
              variant="h6"
            >
              {props.data?.by?.name}
            </Link>
          }
          action={
            <>
              <IconButton
                aria-controls="post-menu"
                onClick={(e: any) => {
                  setOpenMenu(true);
                  setAnchor(e.currentTarget);
                }}
              >
                <MoreHorizIcon />
              </IconButton>
              <Menu
                id="post-menu"
                anchorEl={anchor}
                open={openMenu}
                slotProps={{
                  paper: {
                    sx: {
                      backgroundColor: theme.palette.primary.dark,
                      borderRadius: 1.5,
                      outlineColor: "#3c3c44",
                      outlineStyle: "solid",
                      outlineWidth: 1,
                      minWidth: 100
                    }
                  }
                }}
                onClose={() => setOpenMenu(false)}
              >
                <MenuItem onClick={handleEdit}>Edit</MenuItem>
                <MenuItem onClick={() => setOpenDeleteConfirm(true)}>Delete</MenuItem>
                <CustomModal
                  open={openDeleteConfirm}
                  setOpen={setOpenDeleteConfirm}
                  sub="Are you sure you want to delete this post?"
                  cardColor="#12121b"
                  noScroll
                >
                  <Box display="flex">
                    <Grid container spacing={2}>
                      <Grid item xs={1} />
                      <Grid item xs={5}>
                        <MuiButton
                          style={{
                            height: 50,
                            backgroundColor: theme.palette.error.main,
                            color: theme.palette.text.primary,
                            borderRadius: 13
                          }}
                          fullWidth
                          variant="contained"
                          onClick={() => setOpenDeleteConfirm(false)}
                        >
                          No
                        </MuiButton>
                      </Grid>
                      <Grid item xs={5}>
                        <Button
                          style={{
                            height: 50, 
                            borderRadius: 13
                          }}
                          variant="contained"
                          fullWidth
                          onClick={handleDelete}
                        >
                          Yes
                        </Button>
                      </Grid>
                      <Grid item xs={1} />
                    </Grid>
                  </Box>
                </CustomModal>
              </Menu>
            </>
          }
        />
        <Box px={3} pb={2}>
          {editing ? (
            <Box display="flex">
              <TextField
                className={classes.input}
                id="firstName"
                fullWidth
                variant="filled"
                label="Message"
                value={message}
                InputProps={{ disableUnderline: true }}
                onChange={(e) => setMessage(e.target.value)}
              />
              <Box p={0.5} flexGrow={1} />
              <Box display='flex' alignItems='center'>
                <Button
                  onClick={handleCancelEdit}
                  className={classes.cancelButton}
                  style={{
                    maxHeight: 40
                  }}
                >
                  Cancel
                </Button>
                <Box p={0.5} />
                <Button
                  onClick={handleSaveEdit}
                  style={{
                    color: "#fff",
                    maxHeight: 40
                  }}
                >
                  Save
                </Button>
              </Box>
            </Box>
          ) : (
            <Typography variant="body1" color="textPrimary" fontSize={18}>
              {props.data?.message}
            </Typography>
          )}
          {props.data?.img?.url && (
            <Box
              mt={2}
              style={{
                borderRadius: 30
              }}
            >
              <CardActionArea
                component="a"
                href={
                  props.data?.isAd ? props.data?.link : props.data?.img?.url
                }
                target="_blank"
              >
                <CardMedia
                  style={{
                    height: 500,
                    borderRadius: 30
                  }}
                  image={props.data?.img?.url}
                />
              </CardActionArea>
            </Box>
          )}
          <Box mt={2}>
            <Reactions
              post={props.data}
              isAd={props.data?.isAd}
              className={null}
              disableCommentButton={props.showComments}
              likedPosts={props.likedPosts}
              finalCost={props.finalCost}
            />
          </Box>
          {props.showComments && (
            <>
              <Box my={2}>
                <Divider />
              </Box>
              {props.data?.comments?.edges?.map((comment) => (
                <Comment
                  comment={comment.node}
                  key={comment.node.id}
                  className={null}
                />
              ))}
              {props.moreComments && props.data?.comments.edges.length !== 0 && (
                <Box display="flex" sx={{ justifyContent: "center", width: "100%" }}>
                  <MuiButton 
                    size="small" 
                    style={{ 
                      paddingRight: "10px", 
                      paddingLeft: "10px",
                      marginTop: "15px", 
                      marginBottom: "10px",
                      background: "linear-gradient(75deg, #35c669, #2ec4a9)",
                      color: "#fff"
                    }} 
                    onClick={
                      () => props.fetchMore()
                    }
                  >
                    Load More Comments
                  </MuiButton>
                </Box>
              )}
              {props.data?.comments.edges.length === 0 && (
                <Typography align="center" color="textSecondary">
                  No comments yet.
                </Typography>
              )}
            </>
          )}
        </Box>
      </Card>
    </Box>
  );
};

export default PostCard;
