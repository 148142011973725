import Collapse from '@mui/material/Collapse';

import { usePathname } from '../../../../Routes/hooks';
import { useBoolean } from '../../../../Hooks/use-boolean';

import { NavItemBaseProps } from '../types';

import NavItem from './nav-item';
import NavSubItem from './nav-sub-item';

import { useState } from "react";
import { paths } from '../../../../Routes/paths';

// ----------------------------------------------------------------------

type NavListProps = {
  item: NavItemBaseProps;
};

export default function NavList({ item }: NavListProps) {
  const pathname = usePathname();

  const externalLink = item.path.includes('http');

  const [open, setOpen] = useState(!!item.children && item.children?.filter((element) => element.path == pathname).length > 0);

  return (
    <>
      <NavItem
        item={item}
        open={open}
        onClick={() => setOpen(!open)}
        active={ pathname=="/" ? false :  pathname === item.path}
        externalLink={externalLink}
      />

      {!!item.children && (
        <Collapse in={ open } unmountOnExit>
          {item.children.map((x) => (
            <NavSubItem
              item={x}
              open={open}
              active={pathname === x.path}
              externalLink={externalLink}
            />
          ))}
        </Collapse>
      )}
    </>
  );
}
