import { lazy } from 'react';

// ----------------------------------------------------------------------

const AboutPage = lazy(() => import('../../Pages/_company/about'));

// ----------------------------------------------------------------------

export const aboutRoutes = [
  {
    path: 'about',
    children: [
      { element: <AboutPage />, index: true },
    ],
  },
];
