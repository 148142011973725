import React from "react";
import clsx from "clsx";
import { useTheme, IconButton, Button as MUIButton } from "@mui/material";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => ({
  background: {
    background: "linear-gradient(75deg, #35c669, #2ec4a9)",
  },
  button: {
    border: 0,
    color: "#fff",
    borderRadius: theme.shape.borderRadius,
  },
}));

const Button = (props) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  if (props.icon) {
    return (
      <IconButton {...props} className={classes.background}>
        {props.children}
      </IconButton>
    );
  }

  return (
    <MUIButton
      style={{
        border: 0,
        color: "#fff",
        borderRadius: theme.shape.borderRadius,
      }}
      className={
        !props.className
          ? clsx(classes.background, classes.button)
          : clsx(classes.button, props.className)
      }
      {...props}
    >
      {props.children}
    </MUIButton>
  );
};

export default Button;
