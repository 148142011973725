import React from "react";
import Parse from "parse";
import clsx from "clsx";
import {
  Avatar,
  Box,
  Chip,
  ListItem,
  ListItemAvatar,
  ListItemText,
  colors,
  Tooltip,
  Typography
} from "@mui/material";
import { useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AvatarGroup } from "@material-ui/lab";
import { Link } from "react-router-dom";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import StarRateIcon from "@material-ui/icons/StarRate";
import { useAuth } from "../../../../hooks/useAuth";

const useStyles = makeStyles((theme: any) => ({
  active: {
    boxShadow: `inset 4px 0px 0px ${theme.palette.secondary.main}`,
  },
  smallAvatar: {
    height: 30,
    width: 30,
    "&:first-child": {
      marginTop: 10,
    },
  },
  avatar: {
    marginRight: theme.spacing(1),
    backgroundColor: "#1d1d2b",
  },
  unreadIndicator: {
    height: 18,
    marginTop: 2,
    minWidth: 18,
    padding: 2,
  },
  threadItem: {
    backgroundColor: 'transparent',
    height: 70,
    display: "flex",
  },
  chevron: {
    color: theme.palette.primary.main,
  },
  cardAction: {
    width: "auto",
    height: "auto",
    display: "block",
  },
  star: {
    color: colors.yellow[500],
  },
}));

const ThreadItem = ({ active, className, thread, onSelect, ...rest }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const auth = useAuth();

  const capitalize_each_word = (str) => {
    if (!str) {
      return ""
    }

    const arr = str.split(" ");

    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }

    const str2 = arr.join(" ");

    return str2
  }

  return (
    <Link
      className={classes.cardAction}
      to={`/dashboard/messages/${thread.objectId}`}
      style={{ textDecoration: "none" }}
    >
      <ListItem
        button
        className={clsx(
          { [classes.active]: active },
          className,
          classes.threadItem
        )}
        style={{
          borderRadius: 12
        }}
        sx={{
          ":hover": {
            backgroundColor: "#303038"
          },
          "&& .MuiTouchRipple-child": {
            backgroundColor: "#303038"
          }
        }}
        onClick={onSelect}
      >
        <ListItemAvatar>
          <Avatar
            alt="Person"
            key={thread.student.id}
            src={thread.student.img?.url}
            className={classes.avatar}
          />
        </ListItemAvatar>
        <ListItemText
          primary={
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: 'transparent',
              }}
            >
              <Typography
                color="textPrimary"
                style={{
                  fontWeight: 600,
                  fontSize: 16
                }}
              >
                {capitalize_each_word(thread.student.firstName + " " + thread.student.lastName)}
              </Typography>
              {thread.student.userStudent.verificationStatus == "verified" ? (
                <img
                  height={17}
                  width={17}
                  src={"/assets/icons/ic_verify.png"}
                  style={{
                    visibility: thread.student.userStudent.verificationStatus == "verified" ? "visible" : "hidden",
                    marginLeft: theme.spacing(0.5),
                  }}
                />
              ) : (<></>)}
              {thread.student.userStudent?.appliedColleges || thread.student.userStudent?.acceptedColleges || thread.student.userStudent?.enrolledColleges ? (
                <Tooltip
                  title={thread.student.userStudent?.appliedColleges.map((x) => x.value).includes(auth.collegeId) ? "Applied" : thread.student.userStudent?.acceptedColleges.map((x) => x.value).includes(auth.collegeId) ? "Accepted" : thread.student.userStudent?.enrolledColleges.map((x) => x.value).includes(auth.collegeId) ? "Enrolled" : "Not Applied"}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: "#46464f",
                        color: "white"
                      }
                    }
                  }}
                >
                  <img
                    height={14}
                    width={14}
                    src={thread.student.userStudent?.appliedColleges.map((x) => x.value).includes(auth.collegeId) ? "/assets/icons/ic_applied.svg" : thread.student.userStudent?.acceptedColleges.map((x) => x.value).includes(auth.collegeId) ? "/assets/icons/ic_accepted.svg" : thread.student.userStudent?.enrolledColleges.map((x) => x.value).includes(auth.collegeId) ? "/assets/icons/ic_enrolled.svg" : "/assets/icons/ic_applied.svg"}
                    style={{
                      visibility: thread.student.userStudent?.appliedColleges.map((x) => x.value).includes(auth.collegeId) || thread.student.userStudent?.acceptedColleges.map((x) => x.value).includes(auth.collegeId) || thread.student.userStudent?.enrolledColleges.map((x) => x.value).includes(auth.collegeId) ? "visible" : "hidden",
                      marginLeft: theme.spacing(0.5),
                    }}
                  />
                </Tooltip>
              ) : (<></>)}
            </div>
          }
          primaryTypographyProps={{
            noWrap: true,
          }}
          secondary={<Typography color="textSecondary" style={{ fontWeight: 400, fontSize: 14, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{thread.messages?.edges[0]?.node?.content}</Typography>}
          secondaryTypographyProps={{
            noWrap: false,
          }}
        />
        {thread.impressive && (
          <Tooltip
            title="Impressive student"
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: "#46464f",
                  color: "white"
                }
              }
            }}
          >
            <StarRateIcon className={classes.star} />
          </Tooltip>
        )}
        <Box ml={2} display="flex" flexDirection="column" alignItems="flex-end">
          {thread.unreadCount > 0 && (
            <Chip
              className={classes.unreadIndicator}
              color="secondary"
              size="small"
              label={thread.unreadCount}
            />
          )}
        </Box>
        <ChevronRightIcon className={classes.chevron} />
      </ListItem>
    </Link>
  );
};

ThreadItem.defaultProps = {
  active: false,
  onSelect: () => { },
};

export default ThreadItem;
