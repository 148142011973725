import React, { useEffect, useState } from "react";
import Parse from "parse";
import clsx from "clsx";
import Header from "./Header";
import GeneralInfo from "./Generallnfo";
import Admissions from "./Admissions";
import SATScores from "./SATScores";
import ACTScores from "./ACTScores";
import CostsScholarships from "./CostsScholarships";
import Majors from "./Majors";
import { Loading } from "../../../components/Loading"
import Page from "../../../components/Page";
import {
  Box,
  Button,
  CardHeader,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import { useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { gql, useQuery } from "@apollo/client";
import { useAuth } from "../../../hooks/useAuth";

const useStyles = makeStyles((theme: any) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  card: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
  button: {
    background: "linear-gradient(75deg, #35c669, #2ec4a9)",
    border: 0,
    borderRadius: 5,
    color: "#fff",
    padding: "0 30px",
    minHeight: "50px",
  },
  accordion: {
    paddingRight: theme.spacing(3),
    background: theme.palette.primary.dark,
    "&:MuiAccordion-root": {
      position: "inherit",
    },
  },
  topAcc: {
    borderTopRightRadius: 25,
    borderTopLeftRadius: 25,
    '&:before': {
      display: 'none',
    }
  },
  bottomAcc: {
    borderBottomRightRadius: 25,
    borderBottomLeftRadius: 25,
  },
  accordianDetails: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  submit: {
    margin: theme.spacing(3),
    marginBottom: theme.spacing(20),
  },
}));

const GET_COLLEGE_PROFILE = gql`
  query getCollegeProfile($id: ID!) {
    college(id: $id) {
      id
      objectId
      name
      state
      city
      isPublic
      ranking
      bio
      testingRequirements
      img {
        url
      }
      bannerImg {
        url
      }
      collegeStats {
        id
        objectId
        numStudentsEnrolled
        admissionRate
        graduationRate8yr
        tuitionExpensesMinusAid
        tuitionExpenses
        avgTuitionInState
        avgTuitionOutOfState
        pellRate
        federalLoanRate
        avgMonthlyPayments
        medianDebtPostgrad
        SATavg
        SAT25thPercentileMath
        SAT25thPercentileReading
        SAT25thPercentileWriting
        SATMidpointMath
        SATMidpointReading
        SATMidpointWriting
        SAT75thPercentileMath
        SAT75thPercentileReading
        SAT75thPercentileWriting
        ACTavg
        ACT25thPercentileMath
        ACT25thPercentileEnglish
        ACT25thPercentileWriting
        ACT25thPercentileCumulative
        ACTMidpointMath
        ACTMidpointEnglish
        ACTMidpointWriting
        ACTMidpointCumulative
        ACT75thPercentileMath
        ACT75thPercentileEnglish
        ACT75thPercentileWriting
        ACT75thPercentileCumulative
        majors {
          edges {
            node {
              id
              name
              category
            }
          }
        }
      }
    }
  }
`;

export default function CollegeProfile(props) {
  const theme = useTheme();
  const classes = useStyles(theme);

  const auth = useAuth();

  const { data, loading, error } = useQuery(GET_COLLEGE_PROFILE, {
    variables: { id: auth.collegeId },
  });

  const handleSave = () => { };

  useEffect(() => {
    document.body.style.backgroundColor = "#0e0e14";
  }, []);

  if (loading) {
    return <Loading lower />;
  }

  if (error) {
    return <Typography color="error">{error.toString()}</Typography>;
  }

  // if (error) {
  //   return console.log(error);
  // }

  return (
    <>
      <Page title="College Profile" maxWidth="xl">
        <Header data={data} />
        {/* <Box height="25px" /> */}
        <Box marginTop={-3}>
          <Accordion className={clsx(classes.topAcc, classes.accordion)}
            style={{
              paddingRight: theme.spacing(3),
              background: theme.palette.background.paper,
              borderTopRightRadius: 25,
              borderTopLeftRadius: 25,
            }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <CardHeader
                title="General Information"
                subheader="General profile information for the University"
                titleTypographyProps={{ variant: "h6" }}
              />
            </AccordionSummary>
            <AccordionDetails style={{
              paddingLeft: theme.spacing(4),
              paddingRight: theme.spacing(4),
              paddingBottom: theme.spacing(4),
            }}>
              <GeneralInfo data={data} save={handleSave} />
            </AccordionDetails>
          </Accordion>
          <Accordion className={classes.accordion}
            style={{
              paddingRight: theme.spacing(3),
              background: theme.palette.background.paper,
            }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <CardHeader
                title="Admissions"
                subheader="General admissions information for the University"
                titleTypographyProps={{ variant: "h6" }}
              />
            </AccordionSummary>
            <AccordionDetails style={{
              paddingLeft: theme.spacing(4),
              paddingRight: theme.spacing(4),
              paddingBottom: theme.spacing(4),
            }}>
              <Admissions data={data} />
            </AccordionDetails>
          </Accordion>
          <Accordion className={classes.accordion}
            style={{
              paddingRight: theme.spacing(3),
              background: theme.palette.background.paper,
            }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <CardHeader
                title="SAT Scores"
                subheader="Average SAT scores for current students at the University"
                titleTypographyProps={{ variant: "h6" }}
              />
            </AccordionSummary>
            <AccordionDetails style={{
              paddingLeft: theme.spacing(4),
              paddingRight: theme.spacing(4),
              paddingBottom: theme.spacing(4),
            }}>
              <SATScores data={data} />
            </AccordionDetails>
          </Accordion>
          <Accordion className={classes.accordion}
            style={{
              paddingRight: theme.spacing(3),
              background: theme.palette.background.paper,
            }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <CardHeader
                title="ACT Scores"
                subheader="Average ACT scores for current students at the University"
                titleTypographyProps={{ variant: "h6" }}
              />
            </AccordionSummary>
            <AccordionDetails style={{
              paddingLeft: theme.spacing(4),
              paddingRight: theme.spacing(4),
              paddingBottom: theme.spacing(4),
            }}>
              <ACTScores data={data} />
            </AccordionDetails>
          </Accordion>
          <Accordion className={classes.accordion}
            style={{
              paddingRight: theme.spacing(3),
              background: theme.palette.background.paper,
            }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <CardHeader
                title="Costs & Scholarships"
                subheader="Information regarding tuition costs and scholarships offered"
                titleTypographyProps={{ variant: "h6" }}
              />
            </AccordionSummary>
            <AccordionDetails style={{
              paddingLeft: theme.spacing(4),
              paddingRight: theme.spacing(4),
              paddingBottom: theme.spacing(4),
            }}>
              <CostsScholarships data={data} />
            </AccordionDetails>
          </Accordion>
          <Accordion className={clsx(classes.bottomAcc, classes.accordion)}
            style={{
              paddingRight: theme.spacing(3),
              background: theme.palette.background.paper,
              borderBottomRightRadius: 25,
              borderBottomLeftRadius: 25,
            }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <CardHeader
                title="Majors"
                subheader="All of the majors that the university provides"
                titleTypographyProps={{ variant: "h6" }}
              />
            </AccordionSummary>
            <AccordionDetails style={{
              paddingLeft: theme.spacing(4),
              paddingRight: theme.spacing(4),
              paddingBottom: theme.spacing(4),
            }}>
              <Majors />
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box height={15} />
      </Page>
    </>
  );
}
