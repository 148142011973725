import React, { useState, useEffect } from "react";
import Parse from "parse";
import { useParams } from "react-router-dom";
import {
  Box,
  Container,
  Button,
  Typography,
} from "@mui/material";
import { useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Loading, Page } from "../../../../components";
import PostCard from "./PostCard";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useQuery, gql } from "@apollo/client";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme: any) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    "& ul": {
      display: "inline-flex",
    },
    marginTop: theme.spacing(0),
    margin: theme.spacing(3),
  },
  selected: {
    color: theme.palette.secondary.main,
  },
  button: {
    background: "linear-gradient(75deg, #35c669, #2ec4a9)",
    border: 0,
    color: "#fff",
    margin: theme.spacing(3),
    paddingRight: theme.spacing(2)
  },
}));

const GET_POST = gql`
  query getPost($id: ID!, $after: String) {
    collegePost(id: $id) {
      id
      objectId
      createdAt
      isAd
      link
      numComments
      filterQuery {
        audienceSize
      }
      by {
        id
        name
        img {
          url
        }
      }
      img {
        url
      }
      message
      finalCost
      likes {
        count
      }
      impressions {
        count
      }
      taps {
        count
      }
      comments(first: 5, after: $after, order: numLikes_DESC) {
        count
        edges {
          cursor
          node {
            author {
              id
              objectId
              img {
                url
              }
              firstName
              lastName
            }
            createdAt
            content
            numLikes
            replies(order: createdAt_ASC) {
              count
              edges {
                node {
                  id
                  objectId
                  author {
                    id
                    objectId
                    img {
                      url
                    }
                    firstName
                    lastName
                  }
                  content
                  numLikes
                  createdAt
                }
              }
            }
          }
        }
      }
    }
  }
`;

const GET_LIKED_POSTS = gql`
  query getLikedPosts($id: ID!) {
    userContact(id: $id) {
      likedPosts {
        edges {
          node {
            id
            objectId
          }
        }
      }
    }
  }
`;

const Posts = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { id } = useParams();
  const [moreComments, setMoreComments] = useState(true);
  const { data, loading, error, fetchMore } = useQuery(GET_POST, {
    variables: { id },
  });
  const { data: likedPosts } = useQuery(GET_LIKED_POSTS, {
    variables: { id: Parse.User.current()?.get("userContact").id },
  });

  const loadMoreComments = async () => {
    try {
      await fetchMore({
        variables: {
          after:
            data?.collegePost?.comments.edges[
              data?.collegePost?.comments.edges.length - 1
            ].cursor,
        },
        updateQuery: (prev: any, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          if (fetchMoreResult.collegePost?.comments?.edges.length === 0) {
            setMoreComments(false);
            return prev;
          }
          return {
            ...prev,
            collegePost: {
              ...prev.collegePost,
              comments: {
                ...prev.collegePost.comments,
                edges: [
                  ...prev.collegePost.comments.edges,
                  ...fetchMoreResult.collegePost.comments.edges,
                ],
              },
            },
          };
        },
      });
    } catch (error) { return }
  };

  if (loading) return <Loading lower />;

  if (error) return <Typography color="error">{error.toString()}</Typography>;

  return (
    <Page className={classes.root} title="Social Feed">
      <Container maxWidth="md">
        <Link to="/dashboard/social/posts">
          <Button
            style={{
              background: "linear-gradient(75deg, #35c669, #2ec4a9)",
              border: 0,
              color: "#fff",
              margin: theme.spacing(3),
              paddingRight: theme.spacing(2)
            }}>
            <ArrowBackIcon />
            Back
          </Button>
        </Link>
        <Box p={3} pt={0}>
          <PostCard
            updatePosts={() => { }}
            data={data?.collegePost}
            backOnDelete
            showComments
            likedPosts={likedPosts?.userContact?.likedPosts?.edges?.map(
              (e) => e.node.objectId
            )}
            fetchMore={loadMoreComments}
            moreComments={moreComments}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default Posts;
