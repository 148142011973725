import React, { useEffect, useState, useMemo } from "react";
import Parse from "parse";
import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import {
  useTheme,
  Card,
  Grid,
  Box,
  Typography,
  Fade,
} from "@mui/material";
import { makeStyles } from '@mui/styles';
import { Input, Modal, Button } from "../../../components";
import "react-perfect-scrollbar/dist/css/styles.css";
import { useAuth } from "../../../hooks/useAuth";
import { useSnackbar } from "notistack";
import { Loading } from "../../../components/Loading";

const useStyles = makeStyles((theme: any) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  card: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  avatar: {
    height: 42,
    width: 42,
    top: 2,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    backgroundColor: "#1d1d2b",
  },
  button: {
    marginTop: theme.spacing(3)
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: theme.spacing(1),
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(4),
    marginBottom: theme.spacing(0)
  },
  deactivate: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.text.primary,
  },
  tableCell: {
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  accordion: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[0],
    outlineColor: theme.palette.background.paper,
    marginTop: theme.spacing(1),
  },
  person: {
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(1),
    height: 62,
    display: "flex",
    "&:hover": {
      backgroundColor: theme.palette.primary.lighter,
      cursor: "pointer"
    },
  },
  cardAction: {
    width: "auto",
    height: "auto",
    display: "block",
  },
  title: {
    fontSize: 30,
    fontWeight: 600,
    color: "#fff",
  },
  search: {
    display: "flex",
    alignItems: "center",
    height: 44,
    borderRadius: 10,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    backgroundColor: theme.palette.primary.light,
  },
  label: {
    fontSize: 18,
    fontWeight: 500,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(0)
  },
  searchInput: {
    flexGrow: 1,
    marginLeft: theme.spacing(1),
  },
  results: {
    overflowY: "auto",
    height: 300,
  },
}));

const ChildComponent = (props) => {
  const theme = useTheme();

  const stripe = useStripe();
  const elements = useElements();
  const auth = useAuth();

  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (event) => {
    if (elements) {
      const paymentElement = elements.getElement(PaymentElement);

      if (stripe && paymentElement) {
        await stripe.confirmSetup({
          elements,
          redirect: 'if_required',
          confirmParams: {
            return_url: "http://localhost:3000/dashboard/billing",
            payment_method_data: {
              billing_details: {
                email: props.email
              }
            }
          }
        }).then(async function (result) {
          if (result.setupIntent) {
            setLoading(true);

            Parse.Cloud.run("changePaymentMethod", {
              collegeId: auth.collegeId,
              methodId: result.setupIntent.payment_method,
            }).then(
              (result) => {
                if (result === true) {
                  enqueueSnackbar("Payment method changed successfully", {
                    variant: "success",
                  });
                }

                props.refresh();

                props.setOpen(false);

                setLoading(false);
              },
              (error) => {
                enqueueSnackbar(error.toString(), {
                  variant: "error",
                });

                props.setOpen(false);

                setLoading(false);
              })

          } else {
            if (result.error.message) {
              enqueueSnackbar(result.error.message.toString(), {
                variant: "error",
              });
            }
          }
        });
      }
    }
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <PaymentElement id="payment-element" />
          <Grid item xs={12} sx={{ alignSelf: "right", marginTop: theme.spacing(3), marginBottom: theme.spacing(-1) }}>
            <Box display='flex' justifyContent='right'>
              <Button onClick={handleSubmit}>Submit</Button>
            </Box>
          </Grid>
        </>
      )}
    </>
  );
};

export default function ChangePaymentMethod(props) {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [open, setOpen] = useState(false);
  const childComponent = useMemo(() => <ChildComponent email={props.email} refresh={props.refresh} setOpen={setOpen} />, []);
  const [stripePromise, setStripePromise] = useState(() => loadStripe('pk_live_51JZHakCgmkCKcX6cUE5ymujI4Gbzv0T11l9bKCXdP5UtUMyodH5kwxjgHIJELRpZdlKabluyJq16AMHiAn7J0w7x003b1wNj5t'))
  const [clientSecret, setClientSecret] = useState("");

  const createPaymentIntent = async () => {
    const res = await Parse.Cloud.run("createPaymentIntent", {});
    setClientSecret(res.client_secret);
  };

  useEffect(() => {
    if (clientSecret == "") {
      createPaymentIntent();
    }
  }, []);

  return (
    <>
      <Box display='flex' justifyContent='right'>
        <Button style={{ color: "#fff", paddingRight: 12, paddingLeft: 12 }} onClick={() => setOpen(true)}>Change</Button>
      </Box>
      <Modal
        open={open}
        setOpen={setOpen}
        title={<Typography style={{
          fontSize: 30,
          fontWeight: 600,
          color: "#fff",
        }}>Change Payment Info</Typography>}
        noScroll
      >
        <Fade in={open}>
          <Card
            className="cardScroll"
            style={{
              backgroundColor: theme.palette.background.paper,
              display: "flex",
              flexDirection: "column",
              padding: theme.spacing(4),
              marginBottom: theme.spacing(0),
              borderRadius: 19,
              maxHeight: 400,
              overflowY: 'scroll'
            }}
          >
            <Box width={500}>
              <Elements
                stripe={stripePromise}
                options={{
                  clientSecret: clientSecret,
                  appearance: {
                    theme: 'stripe',
                    variables: {
                      colorPrimary: '#0570de',
                      colorBackground: '#ffffff',
                      colorText: '#000',
                      colorTextSecondary: "#82828c",
                      colorDanger: '#df1b41',
                      spacingUnit: '5px',
                      borderRadius: '13px',
                    },
                    rules: {
                      ".Label": {
                        color: "#a0a0aa",
                        fontWeight: "500"
                      },
                      ".Tab--selected": {
                        backgroundColor: "#ececf6"
                      }
                    }
                  }
                }}
              >
                {childComponent}
              </Elements>
            </Box>
          </Card>
        </Fade>
      </Modal>
    </>
  );
}
