import { lazy } from 'react';

// ----------------------------------------------------------------------

const TermsPage = lazy(() => import('../../Pages/terms'));

// ----------------------------------------------------------------------

export const termsRoutes = [
  {
    path: 'terms',
    children: [
      { element: <TermsPage />, index: true },
    ],
  },
];
