import { paths } from '../../Routes/paths';

// ----------------------------------------------------------------------

export const platformLinks = [
  {
    order: '1',
    title: 'Student Mobile App',
    subTitle: 'High school/college students',
    icon: '/assets/icons/nav-bar/mobile-notch.svg',
    path: '/student-app'
  },
  {
    order: '2',
    title: 'University Web App',
    subTitle: 'Platform Used by your Account Team',
    icon: '/assets/icons/nav-bar/laptop-code.svg',
    path: '/university-webapp'
  },
];

export const exploreLinks = [
  {
    order: '1',
    title: 'Ecosystem',
    subTitle: 'How everything fits together',
    icon: '/assets/icons/nav-bar/data-transfer.svg',
    path: '/ecosystem'
  },
  {
    order: '2',
    title: 'Resources & Blogs',
    subTitle: "How Tos & Resources",
    icon: '/assets/icons/nav-bar/book.svg',
    path: '/resources'
  },
];

export const companyLinks = [
  {
    order: '1',
    title: 'About OptN',
    subTitle: 'Learn about our company',
    icon: '/assets/icons/nav-bar/info.svg',
    path: '/about'
  },
  {
    order: '2',
    title: 'Careers',
    subTitle: 'Come work for us',
    icon: '/assets/icons/nav-bar/briefcase.svg',
    path: '/careers'
  },
  {
    order: '3',
    title: 'Contact',
    subTitle: 'Get in touch',
    icon: '/assets/icons/nav-bar/headset.svg',
    path: '/contact'
  },
];


export const navConfig = [
  {
    title: 'Platform',
    path: '/',
    children: [platformLinks[0], platformLinks[1]],
  },
  {
    title: 'Explore',
    path: '/',
    children: [exploreLinks[0], exploreLinks[1]],
  },
  {
    title: 'Company',
    path: '/',
    children: [companyLinks[0], companyLinks[1], companyLinks[2]],
  },
  { title: 'Support', path: paths.support },
];
