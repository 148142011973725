import React, { useState, useRef, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  IconButton,
  MenuItem,
  Menu,
  Typography,
  Box
} from "@mui/material";
import FadeIn from "react-fade-in";
import { useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Chart, registerables } from "chart.js";
import MenuIcon from "@material-ui/icons/Menu";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { Loading } from "../../../../components";
import { useAuth } from "../../../../hooks/useAuth";
import { DatePicker } from "@material-ui/pickers";

const useStyles = makeStyles((theme: any) => ({
  card: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    height: "350px",
    boxShadow: theme.shadows[4],
    borderRadius: 15,
    outlineColor: "#2c2c33",
    outlineStyle: "solid",
    outlineWidth: 1,
  },
  grid: {
    flexGrow: 1,
  },
  popover: {
    backgroundColor: theme.palette.secondary.dark,
    borderRadius: 11,
    outlineColor: "#2c2c33",
    outlineStyle: "solid",
    outlineWidth: 1,
  },
  titleFont: {
    fontWeight: 500
  },
}));

const GET_STUDENTS = gql`
  query getInterestedStudents(
    $id: ID!
    $dateFilter: [InterestedStudentsOverTimeWhereInput!]
  ) {
    interestedStudentsOverTimes(
      first: 1000
      where: {
        college: { have: { objectId: { equalTo: $id } } }
        AND: $dateFilter
      }
      order: createdAt_ASC
    ) {
      count
      edges {
        node {
          createdAt
          numInState
          numOutState
        }
      }
    }
  }
`;

function monthToString(n) {
  var month: any = [];
  month[0] = "Jan";
  month[1] = "Feb";
  month[2] = "Mar";
  month[3] = "Apr";
  month[4] = "May";
  month[5] = "Jun";
  month[6] = "Jul";
  month[7] = "Aug";
  month[8] = "Sep";
  month[9] = "Oct";
  month[10] = "Nov";
  month[11] = "Dec";
  return month[n];
}

// maximum datapoints to put on the x-axis
const MAX_X_DATAPOINTS = 30;

export default function InterestedStudentsOverTime(props) {
  const theme = useTheme();
  const classes = useStyles(theme);

  const auth = useAuth();
  const chartRef = useRef<any>();
  const popoverRef = useRef<any>();
  const [sub, setSub] = useState("This Month");
  const [timeframe, setTimeframe] = useState("lastMonth");
  const [anchor, setAnchor] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [date, setDate] = useState<any>(undefined);

  const [getStudents, { data, loading, error }] = useLazyQuery(GET_STUDENTS, {
    fetchPolicy: "network-only",
  });
  const [currChart, setCurrChart] = useState<any>(null);

  const options = [
    {
      label: "Today",
      value: "today",
    },
    {
      label: "This Week",
      value: "lastWeek",
    },
    {
      label: "This Month",
      value: "lastMonth",
    },
    {
      label: "This Year",
      value: "lastYear",
    },
    {
      label: "All Time",
      value: "allTime",
    },
  ];

  const handleDateChange = (option) => {
    setOpenMenu(false);
    setSub(option.label);
    setTimeframe(option.value);
    let d = new Date();
    if (option.value === "today") {
      d.setDate(d.getDate() - 3);
      setDate({
        createdAt: { greaterThanOrEqualTo: d.toISOString() },
      });
    }
    if (option.value === "allTime") {
      setDate({
        createdAt: { lessThanOrEqualTo: d.toISOString() },
      });
      return;
    }
    if (option.value === "lastWeek") {
      d.setDate(d.getDate() - 7);
    }
    if (option.value === "lastMonth") {
      d.setMonth(d.getMonth() - 1);
    }
    if (option.value === "lastYear") {
      d.setFullYear(d.getFullYear() - 1);
    }
    setDate({
      createdAt: { greaterThanOrEqualTo: d.toISOString() },
    });
  };

  const getData = () => {
    if (timeframe === "today") {
      return {
        data: data?.interestedStudentsOverTimes?.edges.map(
          (e, index, arr) => index > 0 ? (e.node?.numInState + e.node?.numOutState) - (arr[index - 1].node?.numInState + arr[index - 1].node?.numOutState) : 0
        ).slice(-2),
        labels: data?.interestedStudentsOverTimes?.edges.map((e) => {
          const c = new Date(e.node.createdAt);
          const today = new Date();
          // var hours = c.getHours();
          // var minutes = c.getMinutes();
          // var suffix = hours >= 12 ? "pm" : "am";
          // hours = hours % 12;
          // hours = hours ? hours : 12;
          // minutes = minutes < 10 ? "0" + minutes : minutes;
          return c.getDay() == today.getDay() ? "Today" : "Yesterday"
        }).slice(-2),
      };
    }

    // max of 30 data points, evenly spaced
    let sliced: any = [];
    var interval =
      data?.interestedStudentsOverTimes?.count < MAX_X_DATAPOINTS
        ? 1
        : Math.floor(
          data?.interestedStudentsOverTimes?.count / MAX_X_DATAPOINTS
        );

    for (
      let i = 0;
      i < data?.interestedStudentsOverTimes?.count;
      i += interval
    ) {
      sliced.push(data?.interestedStudentsOverTimes?.edges[i]?.node);
    }

    return {
      data: sliced.map((e, index, arr) => index > 0 ? (e?.numInState + e?.numOutState) - (arr[index - 1]?.numInState + arr[index - 1]?.numOutState) > 0 ? (e?.numInState + e?.numOutState) - (arr[index - 1]?.numInState + arr[index - 1]?.numOutState) : 0 : 0),
      labels: sliced.map((e) => {
        const c = new Date(e?.createdAt);
        return monthToString(c.getMonth()) + " " + c.getDate();
      }),
    };
  };

  useEffect(() => {
    if (auth.collegeId) {
      getStudents({
        variables: {
          id: auth.collegeId,
          dateFilter: date ? date : undefined,
        },
      });
    }
  }, [auth.collegeId, date]);

  useEffect(() => {
    Chart.register(...registerables);

    let d = new Date();
    d.setMonth(d.getMonth() - 1);
    setDate({
      createdAt: { greaterThanOrEqualTo: d.toISOString() },
    });
  }, []);

  useEffect(() => {
    if (chartRef.current && data) {
      var labels: any = [];
      labels = labels.map((item) => {
        return monthToString(item.getMonth()) + " " + item.getDate();
      });

      const myChartRef = chartRef.current.getContext("2d");
      if (currChart !== null) {
        currChart.destroy();
      }

      var gradientBackground = myChartRef.createLinearGradient(0, 0, 0, 400);
      gradientBackground.addColorStop(0, 'rgba(53, 198, 105, 0.8)');
      gradientBackground.addColorStop(1, 'rgba(46, 196, 169, 0.2)');

      const filteredData = getData();

      const charty = new Chart(myChartRef, {
        type: "line",
        data: {
          datasets: [
            {
              data: filteredData.data,
              backgroundColor: gradientBackground,
              borderColor: 'rgba(53, 198, 105, 1)',
              tension: 0.3,
              cubicInterpolationMode: 'monotone',
              fill: true,
            },
          ],
          labels: filteredData.labels,
        },
        options: {
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                callback: function(value:any) {if (value % 1 === 0) {return value;}},
                color: "#53535b",
              },
            },
            x: {
              beginAtZero: true,
              ticks: {
                maxRotation: 30,
                minRotation: 30,
                color: "#53535b",
              }
            },
          },
        },
      });

      setCurrChart(charty);
      charty.update();
    }
  }, [chartRef, data]);

  if (error) console.log(JSON.stringify(error, null, 2));

  return (
    <FadeIn delay={250}>
      <Card style={{
        backgroundColor: theme.palette.primary.dark,
        padding: theme.spacing(2),
        height: "350px",
        boxShadow: theme.shadows[4],
        borderRadius: 25,
        outlineColor: "#2c2c33",
        outlineStyle: "solid",
        outlineWidth: 1,
      }}>
        <CardHeader
          title="Interested Students Over Time"
          subheader={sub}
          action={
            <>
              <IconButton
                ref={popoverRef}
                onClick={(e: any) => {
                  setOpenMenu(true);
                  setAnchor(e.currentTarget);
                }}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="post-menu"
                anchorEl={anchor}
                open={openMenu}
                slotProps={{
                  paper: {
                    sx: {
                      backgroundColor: theme.palette.primary.dark,
                      borderRadius: 1.5,
                      outlineColor: "#3c3c44",
                      outlineStyle: "solid",
                      outlineWidth: 1,
                    }
                  }
                }}
                onClose={() => setOpenMenu(false)}
              >
                {options.map((option) => (
                  <MenuItem
                    key={option.value}
                    onClick={() => handleDateChange(option)}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Menu>
            </>
          }
          titleTypographyProps={{ fontWeight: 600 }}
        />
        <CardContent style={{ height: "250px" }}>
          {error ? (
            <Typography color="error">{error.toString()}</Typography>
          ) : (
            <>
              {loading ? (
                <Loading />
              ) : (
                <>
                  {data?.interestedStudentsOverTimes?.edges.length === 0 ? (
                    <Box display='flex' justifyContent='center' mt={theme.spacing(5)}>
                      <Typography>No results.</Typography>
                    </Box>
                  ) : (
                    <canvas
                      id="interested-students"
                      ref={chartRef}
                    ></canvas>
                  )}
                </>
              )}
            </>
          )}
        </CardContent>
      </Card>
    </FadeIn>
  );
}
