import React, { useState } from "react";
import Parse from "parse";
import {
  Button,
  Grid,
  Typography,
  Card,
  Avatar,
  Fade,
  Modal,
  Backdrop,
  IconButton,
  Box
} from "@mui/material";
import { useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useAuth } from "../../../hooks/useAuth";
import { display } from "@mui/system";

const useStyles = makeStyles((theme: any) => ({
  avatar: {
    top: -50,
    borderRadius: 40,
    position: "absolute",
    "& .MuiAvatar-root": {
      height: 120,
      width: 120,
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(4),
    maxWidth: "400px",
    borderRadius: 20,
    outlineColor: "#323238",
    outlineStyle: "solid",
    outlineWidth: 1,
  },
  button: {
    background: "linear-gradient(75deg, #35c669, #2ec4a9)",
    border: 0,
    borderRadius: 13,
    color: "#fff",
    padding: "0 30px",
    minHeight: "50px",
    margin: theme.spacing(1),
    minWidth: 100
  },
}));

export default function ProfileImage(props) {
  const theme = useTheme();
  const classes = useStyles(theme);

  const auth = useAuth();
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState<any>(null);
  const [tempURL, setTempURL] = useState(props.url);

  const handleChange = (event) => {
    setFile(event.target.files[0]);

    var reader = new FileReader();
    reader.onload = function (upload) {
      setFile(upload.target?.result);
    };
    reader.readAsDataURL(event.target.files[0]);

    setTempURL(URL.createObjectURL(event.target.files[0]));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (auth.collegeId) {
      const colleges = Parse.Object.extend("college");
      const query = new Parse.Query(colleges);
      query.get(auth.collegeId).then((object) => {
        object.set("img", new Parse.File("profileImage.png", { base64: file }));
        object.save().then(
          (response) => {
            console.log("img", response.get("img")._url);
          },
          (error) => {
            console.error("Error while updating college", error);
          }
        );
      });

      setOpen(false);
    }
  };

  return (
    <div>
      <IconButton className={classes.avatar} onClick={() => setOpen(true)}>
        <Avatar
          alt="Profile Picture"
          src={props.url}
          style={{ borderRadius: 35 }}>
        </Avatar>
      </IconButton>
      <Modal
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
      >
        <Fade in={open}>
          <Card style={{
            backgroundColor: theme.palette.primary.darker,
            display: "flex",
            flexDirection: "column",
            padding: theme.spacing(4),
            maxWidth: 400,
            borderRadius: 30,
            outlineColor: "#323238",
            outlineStyle: "solid",
            outlineWidth: 1,
          }}>
            <form noValidate onSubmit={handleSubmit}>
              <Grid container spacing={0} display='flex'>
                <Grid item xs={12}>
                  <Typography variant="h4" align="center" color="textPrimary" style={{ fontWeight: 600 }}>
                    Profile Picture
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Box height={20}/>
                  <Box height={250} justifyContent='center' display='flex'>
                    <img
                      alt="profile-select"
                      style={{ borderRadius: 47, height: 250, }}
                      src={tempURL ? tempURL : props.url}
                    ></img>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box height={10}/>
                  <Box height={20} justifyContent='center' display='flex'>
                    <input
                      type="file"
                      accept="image/png/jpeg"
                      onChange={handleChange}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} alignSelf='center'>
                  <Box height={20} />
                  <Box height={70} display='flex' justifyContent='center' >
                    <Button style={{
                      backgroundColor: theme.palette.error.main,
                      border: 0,
                      borderRadius: 13,
                      color: "#fff",
                      padding: "0 30px",
                      minHeight: "50px",
                      width: 140,
                      margin: theme.spacing(1),
                      minWidth: 100
                    }} onClick={() => setOpen(false)}>
                      Cancel
                    </Button>
                    <Button style={{
                      background: "linear-gradient(75deg, #35c669, #2ec4a9)",
                      border: 0,
                      borderRadius: 13,
                      color: "#fff",
                      padding: "0 30px",
                      minHeight: "50px",
                      width: 140,
                      margin: theme.spacing(1),
                      minWidth: 100
                    }} type="submit">
                      Save
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Card>
        </Fade>
      </Modal>
    </div>
  );
}
