import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Button,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { gql, useMutation } from "@apollo/client";
import { Loading, Input } from "../../../components";

const useStyles = makeStyles((theme: any) => ({
  button: {
    background: "linear-gradient(75deg, #35c669, #2ec4a9)",
    border: 0,
    borderRadius: 15,
    color: "#fff",
    padding: "0 30px",
    minHeight: "50px",
  },
}));

const SAVE = gql`
  mutation saveCollegeStats(
    $id: ID!
    $act: Float!
    $act25math: Float
    $act25english: Float
    $act25writing: Float
    $act25cumulative: Float
    $act50math: Float
    $act50english: Float
    $act50writing: Float
    $act50cumulative: Float
    $act75math: Float
    $act75english: Float
    $act75writing: Float
    $act75cumulative: Float
  ) {
    updateCollegeStat(
      input: {
        id: $id
        fields: {
          ACTavg: $act
          ACT25thPercentileMath: $act25math
          ACT25thPercentileEnglish: $act25english
          ACT25thPercentileWriting: $act25writing
          ACT25thPercentileCumulative: $act25cumulative
          ACTMidpointMath: $act50math
          ACTMidpointEnglish: $act50english
          ACTMidpointWriting: $act50writing
          ACTMidpointCumulative: $act50cumulative
          ACT75thPercentileMath: $act75math
          ACT75thPercentileEnglish: $act75english
          ACT75thPercentileWriting: $act75writing
          ACT75thPercentileCumulative: $act75cumulative
        }
      }
    ) {
      collegeStat {
        id
        objectId
        ACT25thPercentileMath
        ACT25thPercentileEnglish
        ACT25thPercentileWriting
        ACT25thPercentileCumulative
        ACTMidpointMath
        ACTMidpointEnglish
        ACTMidpointWriting
        ACTMidpointCumulative
        ACT75thPercentileMath
        ACT75thPercentileEnglish
        ACT75thPercentileWriting
        ACT75thPercentileCumulative
      }
    }
  }
`;

export default function ACTScores(props) {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [ACT_avg, set_ACT_avg] = useState("");
  const [ACT_25_math, set_ACT_25_math] = useState("");
  const [ACT_25_english, set_ACT_25_english] = useState("");
  const [ACT_25_writing, set_ACT_25_writing] = useState("");
  const [ACT_25_cumulative, set_ACT_25_cumulative] = useState("");
  const [ACT_mp_math, set_ACT_mp_math] = useState("");
  const [ACT_mp_english, set_ACT_mp_english] = useState("");
  const [ACT_mp_writing, set_ACT_mp_writing] = useState("");
  const [ACT_mp_cumulative, set_ACT_mp_cumulative] = useState("");
  const [ACT_75_math, set_ACT_75_math] = useState("");
  const [ACT_75_english, set_ACT_75_english] = useState("");
  const [ACT_75_writing, set_ACT_75_writing] = useState("");
  const [ACT_75_cumulative, set_ACT_75_cumulative] = useState("");

  const [saveCollegeStats, { loading }] = useMutation(SAVE);

  useEffect(() => {
    if (props.data) {
      set_ACT_avg(props.data?.college?.collegeStats?.ACTavg);
      set_ACT_25_math(props.data?.college?.collegeStats?.ACT25thPercentileMath);
      set_ACT_25_english(
        props.data?.college?.collegeStats?.ACT25thPercentileEnglish
      );
      set_ACT_25_writing(
        props.data?.college?.collegeStats?.ACT25thPercentileWriting
      );
      set_ACT_25_cumulative(
        props.data?.college?.collegeStats?.ACT25thPercentileCumulative
      );
      set_ACT_mp_math(props.data?.college?.collegeStats?.ACTMidpointMath);
      set_ACT_mp_english(props.data?.college?.collegeStats?.ACTMidpointEnglish);
      set_ACT_mp_writing(props.data?.college?.collegeStats?.ACTMidpointWriting);
      set_ACT_mp_cumulative(
        props.data?.college?.collegeStats?.ACTMidpointCumulative
      );
      set_ACT_75_math(props.data?.college?.collegeStats?.ACT75thPercentileMath);
      set_ACT_75_english(
        props.data?.college?.collegeStats?.ACT75thPercentileEnglish
      );
      set_ACT_75_writing(
        props.data?.college?.collegeStats?.ACT75thPercentileWriting
      );
      set_ACT_75_cumulative(
        props.data?.college?.collegeStats?.ACT75thPercentileCumulative
      );
    }
  }, [props.data]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography justify-content="left" align="left" color="textPrimary">
          Overall Average ACT Score
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Input
          fullWidth
          label="Average ACT Score"
          name="ACT_avg"
          value={ACT_avg}
          onChange={(e) => set_ACT_avg(e.target.value)}
          variant="filled"
        />
      </Grid>
      <Grid item xs={12}>
        <Typography justify-content="left" align="left" color="textPrimary">
          Average ACT Scores (25th Percentile)
        </Typography>
      </Grid>
      <Grid item md={3} xs={12}>
        <Input
          fullWidth
          label="Math"
          name="ACT_25percentile_math"
          value={ACT_25_math}
          onChange={(e) => set_ACT_25_math(e.target.value)}
          variant="filled"
        />
      </Grid>
      <Grid item md={3} xs={12}>
        <Input
          fullWidth
          label="English"
          name="ACT_25percentile_english"
          value={ACT_25_english}
          onChange={(e) => set_ACT_25_english(e.target.value)}
          variant="filled"
        />
      </Grid>
      <Grid item md={3} xs={12}>
        <Input
          fullWidth
          label="Writing"
          name="ACT_25percentile_writing"
          value={ACT_25_writing}
          onChange={(e) => set_ACT_25_writing(e.target.value)}
          variant="filled"
        />
      </Grid>
      <Grid item md={3} xs={12}>
        <Input
          fullWidth
          label="Cumulative"
          name="ACT_25percentile_cumulative"
          value={ACT_25_cumulative}
          onChange={(e) => set_ACT_25_cumulative(e.target.value)}
          variant="filled"
        />
      </Grid>
      <Grid item xs={12}>
        <Typography justify-content="left" align="left" color="textPrimary">
          Average ACT Scores (Midpoint)
        </Typography>
      </Grid>
      <Grid item md={3} xs={12}>
        <Input
          fullWidth
          label="Math"
          name="ACT_midpoint_math"
          value={ACT_mp_math}
          onChange={(e) => set_ACT_mp_math(e.target.value)}
          variant="filled"
        />
      </Grid>
      <Grid item md={3} xs={12}>
        <Input
          fullWidth
          label="English"
          name="ACT_midpoint_english"
          value={ACT_mp_english}
          onChange={(e) => set_ACT_avg(e.target.value)}
          variant="filled"
        />
      </Grid>
      <Grid item md={3} xs={12}>
        <Input
          fullWidth
          label="Writing"
          name="ACT_midpoint_writing"
          value={ACT_mp_writing}
          onChange={(e) => set_ACT_mp_writing(e.target.value)}
          variant="filled"
        />
      </Grid>
      <Grid item md={3} xs={12}>
        <Input
          fullWidth
          label="Cumulative"
          name="ACT_midpoint_cumulative"
          value={ACT_mp_cumulative}
          onChange={(e) => set_ACT_mp_cumulative(e.target.value)}
          variant="filled"
        />
      </Grid>
      <Grid item xs={12}>
        <Typography justify-content="left" align="left" color="textPrimary">
          Average ACT Scores (75th Percentile)
        </Typography>
      </Grid>
      <Grid item md={3} xs={12}>
        <Input
          fullWidth
          label="Math"
          name="ACT_75percentile_math"
          value={ACT_75_math}
          onChange={(e) => set_ACT_75_math(e.target.value)}
          variant="filled"
        />
      </Grid>
      <Grid item md={3} xs={12}>
        <Input
          fullWidth
          label="English"
          name="ACT_75percentile_english"
          value={ACT_75_english}
          onChange={(e) => set_ACT_75_english(e.target.value)}
          variant="filled"
        />
      </Grid>
      <Grid item md={3} xs={12}>
        <Input
          fullWidth
          label="Writing"
          name="ACT_75percentile_writing"
          value={ACT_75_writing}
          onChange={(e) => set_ACT_75_writing(e.target.value)}
          variant="filled"
        />
      </Grid>
      <Grid item md={3} xs={12}>
        <Input
          fullWidth
          label="Cumulative"
          name="ACT_75percentile_cumulative"
          value={ACT_75_cumulative}
          onChange={(e) => set_ACT_75_cumulative(e.target.value)}
          variant="filled"
        />
      </Grid>
      <Grid item xs={12} sx={{ alignSelf: "right" }}>
        {loading ? (
          <Loading small />
        ) : (
          <Button
            style={{
              background: "linear-gradient(75deg, #35c669, #2ec4a9)",
              border: 0,
              borderRadius: 15,
              color: "#fff",
              padding: "0 30px",
              minHeight: "50px",
            }}
            onClick={() => {
              saveCollegeStats({
                variables: {
                  id: props.data?.college?.collegeStats?.objectId,
                  act: parseFloat(ACT_avg),
                  act25math: parseFloat(ACT_25_math),
                  act25english: parseFloat(ACT_25_english),
                  act25writing: parseFloat(ACT_25_writing),
                  act25cumulative: parseFloat(ACT_25_cumulative),
                  act50math: parseFloat(ACT_mp_math),
                  act50english: parseFloat(ACT_mp_english),
                  act50writing: parseFloat(ACT_mp_writing),
                  act50cumulative: parseFloat(ACT_mp_cumulative),
                  act75math: parseFloat(ACT_75_math),
                  act75english: parseFloat(ACT_75_english),
                  act75writing: parseFloat(ACT_75_writing),
                  act75cumulative: parseFloat(ACT_75_cumulative),
                },
              });
            }}
          >
            Save ACT Scores
          </Button>
        )}
      </Grid>
    </Grid>
  );
}
