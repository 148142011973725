import React, { useEffect, useState, useRef } from "react";
import {
  Card,
  Box,
  Typography,
  ButtonBase,
  Hidden,
  Button,
  IconButton,
  Grid,
  Avatar,
  Menu,
  MenuItem,
  Tooltip,
  Modal,
  Fade,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow
} from "@mui/material";
import FadeIn from "react-fade-in";
import { useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Filters, Loading, Page, Modal as CustomModal, Button as CustomButton } from "../../../components";
import { gql, useLazyQuery } from "@apollo/client";
import { Link, useLocation } from "react-router-dom";
import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
import queryString from "query-string";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { useAuth } from "../../../hooks/useAuth";
import { parseDate } from "../../../util";
import Parse from "parse";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import Iconify from '../../../../src/routes/FrontEnd/Components/iconify';
import { calcAcademicScore } from "../../../util/util";
import { useSnackbar } from "notistack";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme: any) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  card: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(0),
    height: "720px",
    overflowY: "auto",
    borderRadius: 20,
    outlineColor: "#25252b",
    outlineStyle: "solid",
    outlineWidth: 1,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: theme.spacing(3),
  },
  tableCell: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    "& .MuiTableCell-root": {
      borderBottomColor: "#3c3c46"
    },
  },
  avatar: {
    height: 42,
    width: 42,
    top: 5.5,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
    backgroundColor: "#1d1d2b",
  },
  button: {
    background: "linear-gradient(75deg, #35c669, #2ec4a9)",
    border: 0,
    color: "#fff",
    marginLeft: "auto"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(4),
  },
  search: {
    display: "flex",
    alignItems: "center",
    height: 44,
    borderRadius: 10,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    backgroundColor: theme.palette.primary.light,
    outlineColor: "#33333a",
    outlineStyle: "solid",
    outlineWidth: 1,
  },
  searchInput: {
    flexGrow: 1,
    marginLeft: theme.spacing(1),
  },
  cancelButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.text.primary,
  },
  result: {
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(1),
    height: 70,
    display: "flex",
    "&:hover": {
      backgroundColor: theme.palette.primary.lightHover,
      cursor: "pointer"
    },
  },
  results: {
    overflowY: "auto",
  },
  cardAction: {
    width: "auto",
    height: "auto",
    display: "block",
  },
  messageBtn: {
    marginTop: theme.spacing(0.35),
    marginLeft: 'auto',
    marginRight: theme.spacing(1)
  },
  accordion: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[0],
    outlineColor: theme.palette.background.paper,
    marginTop: theme.spacing(1),
  },
  accordianDetails: {
    justifyContent: "center",
  },
}));

const designationOptions = [
  {
    label: "Set as 'No Selection'",
    value: "No Selection",
  },
  {
    label: "Set as 'Interested In Connecting'",
    value: "Interested In Connecting",
  },
];

const GET_ONBOARDED_COLLEGE = gql`
  query getOnboardedColleges($id: ID!) {
    onboardedColleges(
      where: {
        college: { have: { objectId: { equalTo: $id } } }
      }
    ) {
      edges {
        node {
          id
          objectId
          costPerLeadExport
        }
      }
    }
  }
`;

const SEARCH_HIGHSCHOOL = gql`
  query searchUsers(
    $filters: [UserStudentWhereInput!]
    $majorsFilter: MajorRelationWhereInput
    $interestsFilter: ProfileInterestRelationWhereInput
    $collegesFilter: CollegeRelationWhereInput
    $nameFilter: UserContactRelationWhereInput
    $after: String
  ) {
    userStudents(
      first: 40
      after: $after
      order: createdAt_DESC
      where: {
        AND: $filters
        gender: { exists: true }
        fromLead: { equalTo: false }
        generalInterestedMajors: $majorsFilter
        interests: $interestsFilter
        generalInterestedColleges: $collegesFilter
        userContact: $nameFilter
      }
    ) {
      count
      edges {
        cursor
        node {
          id
          objectId
          sat
          act
          psat
          adjustedUWGPA
          adjustedClassRank
          verificationStatus
          zipCodeState
          parentColleges {
            ... on Element {
              value
            }
          }
          siblingColleges {
            ... on Element {
              value
            }
          }
          friendColleges {
            ... on Element {
              value
            }
          }
          appliedColleges {
            ... on Element {
              value
            }
          }
          acceptedColleges {
            ... on Element {
              value
            }
          }
          enrolledColleges {
            ... on Element {
              value
            }
          }
          user {
            id
            objectId
            username
          }
          userContact {
            id
            objectId
            firstName
            lastName
            city
            img {
              url
            }
          }
          settings {
            id
            showPersonalInfoToColleges
            showLastNameToColleges
            showStatsToColleges
            showContactInfoToColleges
            showCollegeListToColleges
            showMajorsToColleges
          }
        }
      }
    }
  }
`;

const SEARCH_LEADS = gql`
  query searchLeads(
    $collegeId: ID!
    $filters: [UserStudentWhereInput!]
    $majorsFilter: MajorRelationWhereInput
    $interestsFilter: ProfileInterestRelationWhereInput
    $collegesFilter: CollegeRelationWhereInput
    $nameFilter: UserContactRelationWhereInput
    $after: String
  ) {
    leads(
      first: 40
      after: $after
      order: createdAt_DESC
      where: {
        student: { 
          have: { 
            AND: $filters 
            generalInterestedMajors: $majorsFilter
            interests: $interestsFilter
            generalInterestedColleges: $collegesFilter
            userContact: $nameFilter
          } 
        }
        hasCompletedProfile: { equalTo: true } 
        college: { have: { objectId: { equalTo: $collegeId } } }
      }
    ) {
      count
      edges {
        cursor
        node {
          id
          objectId
          createdAt
          student {
            id
            objectId
            sat
            act
            psat
            adjustedUWGPA
            adjustedClassRank
            verificationStatus
            zipCodeState
            parentColleges {
              ... on Element {
                value
              }
            }
            siblingColleges {
              ... on Element {
                value
              }
            }
            friendColleges {
              ... on Element {
                value
              }
            }
            appliedColleges {
              ... on Element {
                value
              }
            }
            acceptedColleges {
              ... on Element {
                value
              }
            }
            enrolledColleges {
              ... on Element {
                value
              }
            }
            user {
              id
              objectId
              username
            }
            userContact {
              id
              objectId
              firstName
              lastName
              city
              img {
                url
              }
            }
            settings {
              id
              showPersonalInfoToColleges
              showLastNameToColleges
              showStatsToColleges
              showContactInfoToColleges
              showCollegeListToColleges
              showMajorsToColleges
            }
          }
        }
      }
    }
  }
`;


const SEARCH_COLLEGEORALUM = gql`
  query searchUsers(
    $filters: [UserStudentWhereInput!]
    $majorsFilter: MajorRelationWhereInput
    $interestsFilter: ProfileInterestRelationWhereInput
    $collegesFilter: CollegeRelationWhereInput
    $nameFilter: UserContactRelationWhereInput
    $after: String
  ) {
    userStudents(
      first: 40
      after: $after
      order: createdAt_DESC
      where: {
        AND: $filters
        gender: { exists: true }
        fromLead: { equalTo: false }
        generalInterestedMajors: $majorsFilter
        interests: $interestsFilter
        college: $collegesFilter
        userContact: $nameFilter
      }
    ) {
      count
      edges {
        cursor
        node {
          id
          objectId
          sat
          act
          psat
          adjustedUWGPA
          adjustedClassRank
          verificationStatus
          zipCodeState
          parentColleges {
            ... on Element {
              value
            }
          }
          siblingColleges {
            ... on Element {
              value
            }
          }
          friendColleges {
            ... on Element {
              value
            }
          }
          appliedColleges {
            ... on Element {
              value
            }
          }
          acceptedColleges {
            ... on Element {
              value
            }
          }
          enrolledColleges {
            ... on Element {
              value
            }
          }
          user {
            id
            objectId
            username
          }
          userContact {
            id
            objectId
            firstName
            lastName
            city
            img {
              url
            }
          }
          settings {
            id
            showPersonalInfoToColleges
            showLastNameToColleges
            showStatsToColleges
            showContactInfoToColleges
            showCollegeListToColleges
            showMajorsToColleges
          }
        }
      }
    }
  }
`;

const EXPORT_SEARCH_HIGHSCHOOL = `
  query searchUsers(
    $collegeId: ID!
    $filters: [UserStudentWhereInput!]
    $majorsFilter: MajorRelationWhereInput
    $interestsFilter: ProfileInterestRelationWhereInput
    $collegesFilter: CollegeRelationWhereInput
    $nameFilter: UserContactRelationWhereInput
  ) {
    userStudents(
      first: 3000
      order: createdAt_DESC
      where: {
        AND: $filters
        gender: { exists: true }
        fromLead: { equalTo: false }
        generalInterestedMajors: $majorsFilter
        interests: $interestsFilter
        generalInterestedColleges: $collegesFilter
        userContact: $nameFilter
      }
    ) {
      count
      edges {
        cursor
        node {
          id
          objectId
          createdAt
          ethnicity
          gender
          birthday
          createdAt
          gradYear
          school
          college {
            id
            objectId
            name
          }
          type
          zipCodeState
          sat
          act
          psat
          adjustedUWGPA
          adjustedClassRank
          parentsHighestEdu
          verificationStatus
          interests {
            edges {
              node {
                objectId
                name
              }
            }
          }
          parentColleges {
            ... on Element {
              value
            }
          }
          siblingColleges {
            ... on Element {
              value
            }
          }
          friendColleges {
            ... on Element {
              value
            }
          }
          appliedColleges {
            ... on Element {
              value
            }
          }
          acceptedColleges {
            ... on Element {
              value
            }
          }
          enrolledColleges {
            ... on Element {
              value
            }
          }
          generalInterestedColleges(
            where: {
              objectId: { equalTo: $collegeId }
            }
          ) {
            count
          }
          highlyInterestedColleges(
            where: {
              objectId: { equalTo: $collegeId }
            }
          ) {
            count
          }
          moderatelyInterestedColleges(
            where: {
              objectId: { equalTo: $collegeId }
            }
          ) {
            count
          }
          mildlyInterestedColleges(
            where: {
              objectId: { equalTo: $collegeId }
            }
          ) {
            count
          }
          collegeConnects(
            where: {
              objectId: { equalTo: $collegeId }
            }
          ) {
            count
          }
          settings {
            id
            showPersonalInfoToColleges
            showLastNameToColleges
            showStatsToColleges
            showContactInfoToColleges
            showCollegeListToColleges
            showMajorsToColleges
          }
          user {
            id
            username
          }
          userContact {
            id
            objectId
            email
            phoneMobile
            firstName
            lastName
            fullName
            country
            city
            zipCode
            address1
          }
        }
      }
    }
  }
`;

const EXPORT_SEARCH_LEADS = `
  query searchLeads(
    $collegeId: ID!
    $filters: [UserStudentWhereInput!]
    $majorsFilter: MajorRelationWhereInput
    $interestsFilter: ProfileInterestRelationWhereInput
    $collegesFilter: CollegeRelationWhereInput
    $nameFilter: UserContactRelationWhereInput
  ) {
    leads(
      first: 3000
      order: createdAt_DESC
      where: {
        student: { 
          have: { 
            AND: $filters 
            generalInterestedMajors: $majorsFilter
            interests: $interestsFilter
            generalInterestedColleges: $collegesFilter
            userContact: $nameFilter
          } 
        }
        hasCompletedProfile: { equalTo: true } 
        college: { have: { objectId: { equalTo: $collegeId } } }
      }
    ) {
      count
      edges {
        cursor
        node {
          id
          objectId
          createdAt
          student {
            id
            objectId
            createdAt
            ethnicity
            gender
            birthday
            gradYear
            createdAt
            school
            college {
              id
              objectId
              name
            }
            type
            zipCodeState
            sat
            act
            psat
            adjustedUWGPA
            adjustedClassRank
            parentsHighestEdu
            verificationStatus
            interests {
              edges {
                node {
                  objectId
                  name
                }
              }
            }
            parentColleges {
              ... on Element {
                value
              }
            }
            siblingColleges {
              ... on Element {
                value
              }
            }
            friendColleges {
              ... on Element {
                value
              }
            }
            appliedColleges {
              ... on Element {
                value
              }
            }
            acceptedColleges {
              ... on Element {
                value
              }
            }
            enrolledColleges {
              ... on Element {
                value
              }
            }
            generalInterestedColleges(
              where: {
                objectId: { equalTo: $collegeId }
              }
            ) {
              count
            }
            highlyInterestedColleges(
              where: {
                objectId: { equalTo: $collegeId }
              }
            ) {
              count
            }
            moderatelyInterestedColleges(
              where: {
                objectId: { equalTo: $collegeId }
              }
            ) {
              count
            }
            mildlyInterestedColleges(
              where: {
                objectId: { equalTo: $collegeId }
              }
            ) {
              count
            }
            collegeConnects(
              where: {
                objectId: { equalTo: $collegeId }
              }
            ) {
              count
            }
            settings {
              id
              showPersonalInfoToColleges
              showLastNameToColleges
              showStatsToColleges
              showContactInfoToColleges
              showCollegeListToColleges
              showMajorsToColleges
            }
            user {
              id
              username
            }
            userContact {
              id
              objectId
              email
              phoneMobile
              firstName
              lastName
              fullName
              country
              city
              zipCode
              address1
            }
          }
        }
      }
    }
  }
`;

const EXPORT_SEARCH_COLLEGEALUM = `
  query searchUsers(
    $filters: [UserStudentWhereInput!]
    $majorsFilter: MajorRelationWhereInput
    $interestsFilter: ProfileInterestRelationWhereInput
    $collegesFilter: CollegeRelationWhereInput
    $nameFilter: UserContactRelationWhereInput
  ) {
    userStudents(
      first: 3000
      order: createdAt_DESC
      where: {
        AND: $filters
        gender: { exists: true }
        fromLead: { equalTo: false }
        generalInterestedMajors: $majorsFilter
        interests: $interestsFilter
        college: $collegesFilter
        userContact: $nameFilter
      }
    ) {
      count
      edges {
        cursor
        node {
          id
          objectId
          createdAt
          ethnicity
          gender
          birthday
          gradYear
          school
          createdAt
          college {
            id
            objectId
            name
          }
          type
          zipCodeState
          sat
          act
          psat
          adjustedUWGPA
          adjustedClassRank
          parentsHighestEdu
          verificationStatus
          interests {
            edges {
              node {
                objectId
                name
              }
            }
          }
          parentColleges {
            ... on Element {
              value
            }
          }
          siblingColleges {
            ... on Element {
              value
            }
          }
          friendColleges {
            ... on Element {
              value
            }
          }
          appliedColleges {
            ... on Element {
              value
            }
          }
          acceptedColleges {
            ... on Element {
              value
            }
          }
          enrolledColleges {
            ... on Element {
              value
            }
          }
          settings {
            id
            showPersonalInfoToColleges
            showLastNameToColleges
            showStatsToColleges
            showContactInfoToColleges
            showCollegeListToColleges
            showMajorsToColleges
          }
          user {
            id
            username
          }
          userContact {
            id
            objectId
            email
            phoneMobile
            firstName
            lastName
            fullName
            country
            city
            zipCode
            address1
          }
        }
      }
    }
  }
`;

const Person = ({ person, hasMessagingPermission, isFavorited, designatedStudents, includes }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const auth = useAuth();

  const [isFavoritedLoc, setIsFavoritedLoc] = useState(isFavorited);
  const [intentScore, setIntentScore] = useState(0);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [designation, setDesignation] = useState(designationOptions[0].value);
  const ref = useRef(null);

  const capitalize_each_word = (str) => {
    if (!str) {
      return ""
    }

    const arr = str.split(" ");

    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }

    const str2 = arr.join(" ");

    return str2
  }

  const handleOpen = (e) => {
    e.stopPropagation();

    setDropdownOpen(true);
  };

  const handleClose = (e) => {
    e.stopPropagation();

    setDropdownOpen(false);
  };

  async function changeDesignation(designation) {
    setDesignation(designation);

    const res = await Parse.Cloud.run("saveDesignation", {
      collegeId: auth.collegeId,
      studentId: person.objectId,
      designation: designation,
      userId: Parse.User.current()?.id
    });

    if (res == "added_new_designation") {
      await Parse.Cloud.run("sendIOSPush", { type: "profileViewOrInterest", id: person.user?.objectId, bodyStr: "A new school is interested in you!" });
    }

    await Parse.Cloud.run("logNewStudentProfileVisit", {
      visitorId: Parse.User.current()?.id,
      visitedId: person?.user?.objectId,
    });
  }

  async function favoriteStudent(e) {
    e.preventDefault();
    e.stopPropagation();

    setIsFavoritedLoc(!isFavoritedLoc);

    await Parse.Cloud.run("favoriteUnfavoriteStudent", {
      userUniversityId: Parse.User.current()!.get("userUniversity").id,
      studentId: person?.objectId
    });
  }

  const getIntentData = async (student) => {
    const res = await Parse.Cloud.run("deriveStudentIntent", {
      studentId: student.objectId,
      collegeId: auth.collegeId,
      includes: includes ?? [],
      studentFirstName: student.userContact.firstName,
      studentState: student.zipCodeState,
      studentCity: student.userContact.city,
      collegeState: auth.college.state,
      collegeCity: auth.college.city,
      parentColleges: student.parentColleges ? student.parentColleges.map((x) => x.value) : [],
      siblingColleges: student.siblingColleges ? student.siblingColleges.map((x) => x.value) : [],
      friendColleges: student.friendColleges ? student.friendColleges.map((x) => x.value) : [],
      appliedColleges: student.appliedColleges ? student.appliedColleges.map((x) => x.value) : [],
      acceptedColleges: student.acceptedColleges ? student.acceptedColleges.map((x) => x.value) : [],
      enrolledColleges: student.enrolledColleges ? student.enrolledColleges.map((x) => x.value) : []
    })

    setIntentScore(res.intentScore);
  }

  useEffect(() => {
    setDesignation(designatedStudents.filter((x: any) => x.get('student').id == person.objectId).length > 0 ? (designatedStudents.filter((x: any) => x.get('student').id == person.objectId)[0] as any).get("designation") : "No Selection")
  }, [])

  useEffect(() => {
    getIntentData(person);

  }, [auth.collegeId]);

  return (
    <Grid item xs={12}>
      <Link
        className={classes.cardAction}
        to={`/dashboard/profiles/${person.userContact.objectId}`}
        style={{ textDecoration: "none" }}
      >
        <Card
          sx={{
            "&:hover": {
              backgroundColor: theme.palette.primary.lighter,
              cursor: "pointer"
            },
          }}
          style={{
            backgroundColor: theme.palette.secondary.dark,
            padding: theme.spacing(1),
            borderRadius: 12,
            height: 52,
            display: "flex",
          }}>
          <Avatar
            alt={person.userContact.firstName}
            src={person.userContact.img?.url}
            className={classes.avatar}
          />
          <Box sx={{ alignSelf: "left" }} ml={1} mt={0.25}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: 'transparent',
              }}
            >
              <Typography style={{ fontWeight: 600, fontSize: 17 }} variant="body1">
                {person.settings.showLastNameToColleges == true ? capitalize_each_word(person.userContact?.firstName +
                  " " +
                  person.userContact?.lastName) : capitalize_each_word(person.userContact?.firstName + " " + person.userContact?.lastName.charAt(0))}
              </Typography>
              {person?.verificationStatus == "verified" ? (
                <img
                  height={17}
                  width={17}
                  src={"/assets/icons/ic_verify.png"}
                  style={{
                    visibility: person?.verificationStatus == "verified" ? "visible" : "hidden",
                    marginLeft: theme.spacing(0.5),
                  }}
                />
              ) : (<></>)}
              {person?.appliedColleges || person?.acceptedColleges || person?.enrolledColleges ? (
                <Tooltip
                  title={person?.appliedColleges.map((x) => x.value).includes(auth.collegeId) ? "Applied" : person?.acceptedColleges.map((x) => x.value).includes(auth.collegeId) ? "Accepted" : person?.enrolledColleges.map((x) => x.value).includes(auth.collegeId) ? "Enrolled" : "Not Applied"}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: "#46464f",
                        color: "white"
                      }
                    }
                  }}
                >
                  <img
                    height={14}
                    width={14}
                    src={person?.appliedColleges.map((x) => x.value).includes(auth.collegeId) ? "/assets/icons/ic_applied.svg" : person?.acceptedColleges.map((x) => x.value).includes(auth.collegeId) ? "/assets/icons/ic_accepted.svg" : person?.enrolledColleges.map((x) => x.value).includes(auth.collegeId) ? "/assets/icons/ic_enrolled.svg" : "/assets/icons/ic_applied.svg"}
                    style={{
                      visibility: person?.appliedColleges.map((x) => x.value).includes(auth.collegeId) || person?.acceptedColleges.map((x) => x.value).includes(auth.collegeId) || person?.enrolledColleges.map((x) => x.value).includes(auth.collegeId) ? "visible" : "hidden",
                      marginLeft: theme.spacing(0.5),
                    }}
                  />
                </Tooltip>
              ) : (<></>)}
            </div>
            <Typography variant="body2" color="textSecondary">
              {person?.user?.username?.toLowerCase()}
            </Typography>
          </Box>
          <Box
            display='flex'
            alignItems='center'
            marginLeft='auto'
            marginRight={theme.spacing(1)}
          >
            <Box
              style={{
                backgroundColor: "#1e1e26",
                height: 28,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginRight: theme.spacing(1.3),
                borderRadius: 14,
                paddingRight: theme.spacing(2),
                paddingLeft: theme.spacing(2),
              }}
            >
              <Tooltip
                title="Intent Score"
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: "#46464f",
                      color: "white"
                    }
                  }
                }}
              >
                <Typography
                  fontWeight={600}
                  fontSize={14}
                  mr={theme.spacing(1.5)}
                >
                  {intentScore > 0 ? intentScore : ""}
                </Typography>
              </Tooltip>
              <Box
                width={1.1}
                height={17}
                style={{
                  backgroundColor: "#41414b",
                }}
              />
              <Tooltip
                title="Academic Score"
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: "#46464f",
                      color: "white"
                    }
                  }
                }}
              >
                <Typography
                  textAlign={"left"}
                  ml={theme.spacing(1.5)}
                  sx={{
                    fontSize: 14,
                    fontWeight: 800,
                    backgroundcolor: "primary",
                    backgroundImage: `linear-gradient(to right, #35c669, #2ec4a9)`,
                    backgroundSize: "100%",
                    backgroundRepeat: "repeat",
                    backgroundClip: "text",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",

                  }}>
                  {calcAcademicScore(person.sat, person.act, person.psat, person.adjustedUWGPA, person.adjustedClassRank) ?? 0}
                </Typography>
              </Tooltip>
            </Box>
            <Tooltip
              title="Interest Level"
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: "#46464f",
                    color: "white"
                  }
                }
              }}
            >
              <div>
                <Box
                  display="flex"
                  alignItems="center"
                  component={ButtonBase}
                  onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();

                    handleOpen(e);
                  }}
                  ref={ref}
                  style={{
                    borderRadius: 25,
                    backgroundColor: "#14141c",
                    // padding: 1,
                    minWidth: 70,
                    paddingRight: theme.spacing(2),
                    paddingLeft: theme.spacing(2),
                    marginLeft: 1,
                    marginRight: theme.spacing(1.5),
                    paddingTop: 5,
                    paddingBottom: 5,
                    height: 28,
                    outlineWidth: 1,
                    outlineStyle: "solid",
                    outlineColor: "#3b3b42"
                  }}
                >
                  <Hidden smDown>
                    <Typography style={{ fontSize: 11, fontWeight: 600 }} color="inherit">
                      {designation}
                    </Typography>
                  </Hidden>
                </Box>
                <Menu
                  onClose={e => handleClose(e)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  keepMounted
                  slotProps={{
                    paper: {
                      sx: {
                        minWidth: 270,
                        marginLeft: theme.spacing(0),
                        marginTop: theme.spacing(1),
                        backgroundColor: theme.palette.primary.dark,
                        borderRadius: 1.5,
                        outlineColor: "#3c3c44",
                        outlineStyle: "solid",
                        outlineWidth: 1,
                      }
                    }
                  }}
                  anchorEl={ref.current}
                  open={isDropdownOpen}
                >
                  {designationOptions.map((option) => (
                    <MenuItem
                      key={option.value}
                      onClick={e => {
                        e.stopPropagation();
                        e.preventDefault();

                        setDropdownOpen(false);
                        changeDesignation(option.value);
                      }}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Menu>
              </div>
            </Tooltip>
            <Tooltip
              title="Favorite"
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: "#46464f",
                    color: "white"
                  }
                }
              }}
            >
              <IconButton
                style={{
                  marginRight: theme.spacing(1.3),
                  width: 28,
                  height: 28,
                  backgroundColor: isFavoritedLoc ? "#35c669" : "#1e1e26",
                  padding: theme.spacing(0.8)
                }}
                onClick={e => {
                  favoriteStudent(e);
                }}
              >
                <Iconify
                  icon={'ph:star-fill'}
                  width={19}
                  color="#fff"
                />
              </IconButton>
            </Tooltip>
            <Tooltip
              title="Direct Message"
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: "#46464f",
                    color: "white"
                  }
                }
              }}
            >
              {hasMessagingPermission ? (
                <Link
                  to={`/dashboard/messages/create/${person.userContact?.objectId}`}
                >
                  <IconButton
                    style={{
                      width: 28,
                      height: 28,
                      backgroundColor: "#1e1e26",
                      padding: theme.spacing(1)
                    }}>
                    <ChatBubbleIcon style={{ width: 13, height: 13 }} />
                  </IconButton>
                </Link>
              ) : (
                <span>
                  <IconButton disabled>
                    <ChatBubbleIcon />
                  </IconButton>
                </span>
              )}
            </Tooltip>
          </Box>
        </Card>
        <Box height="10px" />
      </Link>
    </Grid >
  );
};

const PastExports = (props) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [pastExports, setPastExports] = useState<any>([]);
  const auth = useAuth();

  const getPastExports = async () => {
    await Parse.Cloud.run("getPastExports", {
      collegeId: auth.collegeId,
    }).then(
      (res) => {
        setPastExports(res);
      }
    );
  }

  useEffect(() => {
    if (props.open == true) {
      getPastExports();
    }
  }, [props.open]);

  return (
    <Modal
      className={classes.modal}
      open={props.open}
      onClose={() => props.setOpen(false)}
      closeAfterTransition
    >
      <Fade in={props.open}>
        <Card style={{
          height: 400,
          overflowX: "hidden",
          backgroundColor: theme.palette.primary.dark,
          display: "flex",
          flexDirection: "column",
          padding: theme.spacing(4),
          borderRadius: 25,
          outlineColor: "#25252b",
          outlineStyle: "solid",
          outlineWidth: 1,
        }}>
          <Box width="550px">
            <Grid container sx={{ alignSelf: "left" }}>
              <Typography style={{ fontWeight: 600, fontSize: 29 }} align="left" color="textPrimary">
                Past Exports
              </Typography>
              <Box flexGrow={1} />
              <IconButton style={{ width: 40, height: 40 }} onClick={() => props.setOpen(false)}>
                <CloseIcon />
              </IconButton>
            </Grid>
            <Box width="20px" />
            <PerfectScrollbar
              style={{
                maxHeight: 370
              }}
            >
              <Table>
                <TableHead>
                  <TableRow className={classes.tableCell}>
                    <TableCell align="left">Export Date</TableCell>
                    <TableCell align="left">Status</TableCell>
                    <TableCell align="left">Size</TableCell>
                    <TableCell align="left">Cost</TableCell>
                    <TableCell align="right">Download</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pastExports?.map((obj) => (
                    <TableRow>
                      <TableCell
                        align="left"
                      >
                        {<Typography
                          style={{
                            fontWeight: 600,
                            fontSize: 14,
                            color: "#fff"
                          }}
                        >
                          {parseDate(obj.get('createdAt'))}
                        </Typography>}
                      </TableCell>
                      <TableCell align="left">{<Typography style={{ fontWeight: 400, fontSize: 14, color: obj.get('status') == "Completed" ? theme.palette.secondary.main : theme.palette.text.secondary }}>{obj.get('status')}</Typography>}</TableCell>
                      <TableCell align="left">{<Typography style={{ fontWeight: 600, fontSize: 14, color: "#fff" }}>{obj.get('numRows') ? obj.get('numRows').toLocaleString() : ""}</Typography>}</TableCell>
                      <TableCell align="left">{<Typography style={{ fontWeight: 400, fontSize: 14, color: theme.palette.text.secondary }}>{obj.get('cost') ? "$" + obj.get('cost').toFixed(2) : ""}</Typography>}</TableCell>
                      <TableCell align="right">
                        {obj.get('status') == "Completed" ? (
                          <Button
                            variant="outlined"
                            style={{
                              background: "linear-gradient(75deg, #35c669, #2ec4a9)",
                              border: 0,
                              borderRadius: 20,
                              height: 22,
                              paddingRight: 9,
                              paddingLeft: 9,
                              color: "#fff",
                              marginLeft: "auto",
                              fontSize: 11
                            }}
                            onClick={() => {
                              fetch(obj.get('file').url(), {
                                method: 'GET',
                                headers: {
                                  'Content-Type': 'text/csv',
                                },
                              })
                                .then((response) => response.blob())
                                .then((blob) => {
                                  // Create blob link to download
                                  const url = window.URL.createObjectURL(
                                    new Blob([blob]),
                                  );
                                  const link: any = document.createElement('a');
                                  link.href = url;
                                  link.setAttribute(
                                    'download',
                                    `${obj.get('createdAt').toISOString()}_export.csv`,
                                  );

                                  // Append to html link element page
                                  document.body.appendChild(link);

                                  // Start download
                                  link.click();

                                  // Clean up and remove the link
                                  link.parentNode.removeChild(link);
                                });
                            }}
                          >
                            Download
                          </Button>
                        ) : (<></>)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </PerfectScrollbar>
          </Box>
        </Card>
      </Fade>
    </Modal >
  )
};

export default function SearchUsers(props) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { enqueueSnackbar } = useSnackbar();

  const location = useLocation();
  const [query, setQuery] = useState<any>("");
  const [open, setOpen] = useState(false);
  const [openPastExports, setOpenPastExports] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [designatedStudents, setDesignatedStudents] = useState([]);
  const auth = useAuth();
  const [studentIntentIncludes, setStudentIntentIncludes] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [searchHighschool, { data: searchHighschoolData, loading: searchHighschoolLoading, error: searchHighschoolError, fetchMore: fetchHighschoolMore },] = useLazyQuery(SEARCH_HIGHSCHOOL);
  const [searchLeads, { data: searchLeadsData, loading: searchLeadsLoading, error: searchLeadsError, fetchMore: fetchLeadsMore },] = useLazyQuery(SEARCH_LEADS);
  const [searchCollege, { data: searchCollegeData, loading: searchCollegeLoading, error: searchCollegeError, fetchMore: fetchCollegeMore },] = useLazyQuery(SEARCH_COLLEGEORALUM);
  const [favoritedStudents, setFavoritedStudents] = useState([]);
  const [costPerLeadExport, setCostPerLeadExport] = useState(0);

  const [filters, setFilters] = useState<any>(undefined);
  const [filterType, setFilterType] = useState<any>("highschool_student");
  const [source, setSource] = useState<any>("Organic");

  const filtersRef = useRef<any>(null);

  const [getOnboardedCollege, { data: onboardedCollegeData }] = useLazyQuery(GET_ONBOARDED_COLLEGE, {
    variables: { id: auth.collegeId },
    fetchPolicy: "cache-first",
  });

  async function handleExport() {
    setOpen(false);

    enqueueSnackbar("Export is now processing. Click the downloads button to see progress.", {
      variant: "success",
    });

    await Parse.Cloud.run("createNewExport", {
      collegeId: auth.collegeId,
      type: filterType == "highschool_student" || filterType == "student_parent" ? source == "Organic" ? 'highschool' : 'leads' : 'college',
      queryStr: filterType == "highschool_student" || filterType == "student_parent" ? source == "Organic" ? EXPORT_SEARCH_HIGHSCHOOL : EXPORT_SEARCH_LEADS : EXPORT_SEARCH_COLLEGEALUM,
      costPerLeadExport: costPerLeadExport,
      variables: {
        filters: filters?.list?.length === 0 ? undefined : filters?.list,
        majorsFilter: filters?.majorsFilter,
        interestsFilter: filters?.interestsFilter,
        collegesFilter: filters?.collegesFilter,
        collegeId: auth.collegeId,
        nameFilter:
          query === ""
            ? undefined
            : {
              have: { fullName: { matchesRegex: query, options: "i" } },
            },
      },
    })
  };

  async function getIntentScoreFactors() {
    const includes = await Parse.Cloud.run("getIntentScoreFactors", {
      collegeId: auth.collegeId,
    });

    setStudentIntentIncludes(includes);
  }

  useEffect(() => {
    if (onboardedCollegeData) {
      setCostPerLeadExport(onboardedCollegeData?.onboardedColleges?.edges[0].node?.costPerLeadExport);
    }
  }, [onboardedCollegeData]);

  useEffect(() => {
    if (auth.collegeId) {
      getIntentScoreFactors();
    }
  }, [auth.collegeId]);

  useEffect(() => {
    const values = queryString.parse(location.search);

    if (values.query) {
      setQuery(values.query);

    } else {
      setQuery("");
    }

  }, [location]);

  useEffect(() => {
    setLoading(true);

    getData()

  }, [filters, query, auth.collegeId]);

  const getData = async () => {
    if (
      filters?.list?.length === 0 &&
      !filters.majorsFilter &&
      !filters.interestsFilter &&
      !filters.collegesFilter &&
      query === "") {
      setLoading(false);

      return;
    }

    getOnboardedCollege();

    await Parse.Cloud.run("getFavoritedStudents", {
      userUniversityId: Parse.User.current()!.get("userUniversity").id,
      limit: 1000,
      skip: 0
    }).then(
      (res) => {
        setFavoritedStudents(res);
      }
    );

    await Parse.Cloud.run("getDesignatedStudents", {
      collegeId: auth.collegeId,
    }).then(
      (res) => {
        setDesignatedStudents(res);
      },
      (err) => {
      }
    );

    if (filters) {
      var type = "";

      for (const obj of filters?.list) {
        if (obj.type?.equalTo) {
          type = obj.type?.equalTo;
        }
      }

      setFilterType(type);

      if (type == "highschool_student" || type == "student_parent") {
        if (source == "Organic") {
          await searchHighschool({
            variables: {
              filters: filters?.list?.length === 0 ? undefined : filters?.list,
              majorsFilter: filters?.majorsFilter,
              interestsFilter: filters?.interestsFilter,
              collegesFilter: filters?.collegesFilter,
              nameFilter:
                query === ""
                  ? undefined
                  : {
                    have: { fullName: { matchesRegex: query, options: "i" } },
                  },
            },
          });

        } else {
          await searchLeads({
            variables: {
              collegeId: auth.collegeId,
              filters: filters?.list?.length === 0 ? undefined : filters?.list,
              majorsFilter: filters?.majorsFilter,
              interestsFilter: filters?.interestsFilter,
              collegesFilter: filters?.collegesFilter,
              nameFilter:
                query === ""
                  ? undefined
                  : {
                    have: { fullName: { matchesRegex: query, options: "i" } },
                  },
            },
          });
        }

      } else {
        await searchCollege({
          variables: {
            filters: filters?.list?.length === 0 ? undefined : filters?.list,
            majorsFilter: filters?.majorsFilter,
            interestsFilter: filters?.interestsFilter,
            collegesFilter: filters?.collegesFilter,
            nameFilter:
              query === ""
                ? undefined
                : {
                  have: { fullName: { matchesRegex: query, options: "i" } },
                },
          },
        });
      }

    } else {
      await searchHighschool({
        variables: {
          filters: filters?.list?.length === 0 ? undefined : filters?.list,
          majorsFilter: filters?.majorsFilter,
          interestsFilter: filters?.interestsFilter,
          collegesFilter: filters?.collegesFilter,
          nameFilter:
            query === ""
              ? undefined
              : {
                have: { fullName: { matchesRegex: query, options: "i" } },
              },
        },
      });
    }

    setLoading(false);
  }

  const applyFilters = () => {
    console.log(filtersRef);
    filtersRef.current.apply(undefined, true);
  }

  const loadMore = async () => {
    if (filterType == "highschool_student" || filterType == "student_parent") {
      if (source == "Organic") {
        if (searchHighschoolData?.userStudents.count > 0) {
          try {
            if (fetchHighschoolMore) {
              fetchHighschoolMore({
                variables: {
                  after:
                    searchHighschoolData?.userStudents?.edges[
                      searchHighschoolData?.userStudents?.edges?.length - 1
                    ].cursor,
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                  if (!fetchMoreResult) return prev;
                  if (fetchMoreResult.userStudents.edges.length === 0)
                    return prev;
                  return {
                    ...prev,
                    userStudents: {
                      ...prev.userStudents,
                      edges: [
                        ...prev.userStudents.edges,
                        ...fetchMoreResult.userStudents.edges,
                      ],
                    },
                  };
                },
              });
            }

          } catch (error) { return }
        }

      } else {
        if (searchLeadsData?.leads.count > 0) {
          try {
            if (fetchLeadsMore) {
              fetchLeadsMore({
                variables: {
                  after:
                    searchLeadsData?.leads?.edges[
                      searchLeadsData?.leads?.edges?.length - 1
                    ].cursor,
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                  if (!fetchMoreResult) return prev;
                  if (fetchMoreResult.leads.edges.length === 0)
                    return prev;
                  return {
                    ...prev,
                    leads: {
                      ...prev.leads,
                      edges: [
                        ...prev.leads.edges,
                        ...fetchMoreResult.leads.edges,
                      ],
                    },
                  };
                },
              });
            }

          } catch (error) { return }
        }
      }
    } else {
      if (searchCollegeData?.userStudents.count > 0) {
        try {
          if (fetchCollegeMore) {
            fetchCollegeMore({
              variables: {
                after:
                  searchCollegeData?.userStudents?.edges[
                    searchCollegeData?.userStudents?.edges?.length - 1
                  ].cursor,
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                if (fetchMoreResult.userStudents.edges.length === 0)
                  return prev;
                return {
                  ...prev,
                  userStudents: {
                    ...prev.userStudents,
                    edges: [
                      ...prev.userStudents.edges,
                      ...fetchMoreResult.userStudents.edges,
                    ],
                  },
                };
              },
            });
          }
        } catch (error) { return }
      }
    }
  };

  if (searchHighschoolError) console.log(JSON.stringify(searchHighschoolError, null, 2));
  if (searchCollegeError) console.log(JSON.stringify(searchCollegeError, null, 2));
  if (searchLeadsError) console.log(JSON.stringify(searchLeadsError, null, 2));

  return (
    <Page title="Search" maxWidth="xl">
      <FadeIn>
        <Card style={{
          backgroundColor: theme.palette.primary.dark,
          padding: theme.spacing(3),
          paddingBottom: theme.spacing(0),
          height: "calc(100vh - 155px)",
          overflowY: "auto",
          borderRadius: 25,
          outlineColor: "#25252b",
          outlineStyle: "solid",
          outlineWidth: 1,
        }}>
          <FadeIn>
            <Box display="flex" mt={0.5} mb={1.4} alignItems="center">
              <Box display="flex" alignItems='center'>
                <Typography color="textPrimary" style={{ fontSize: 30, fontWeight: 600 }} variant="h5">Search Students</Typography>
                <Box width="20px" />
                {/* {searchHighschoolLoading || searchCollegeLoading || searchLeadsLoading ? (
              <Loading smaller />
            ) : ( */}
                {/* )} */}
              </Box>
              <Button
                variant="outlined"
                style={{
                  background: "linear-gradient(75deg, #35c669, #2ec4a9)",
                  border: 0,
                  color: "#fff",
                  marginLeft: "auto"
                }}
                onClick={applyFilters}
              >
                Apply Filters
              </Button>
              <Button
                variant="outlined"
                style={{
                  background: "linear-gradient(75deg, #35c669, #2ec4a9)",
                  border: 0,
                  color: "#fff",
                  marginLeft: theme.spacing(2),
                  opacity: auth.tier ? auth.tier == "free" || loading || (searchHighschoolData == null && searchCollegeData == null && searchLeadsData == null) ? 0.65 : filterType == "highschool_student" || filterType == "student_parent" ? source == "Organic" ? searchHighschoolData?.userStudents?.count == 0 ? 0.65 : 1 : searchLeadsData?.leads?.count == 0 ? 0.65 : 1 : searchCollegeData?.userStudents?.count == 0 ? 0.65 : 1 : 0.65
                }}
                onClick={() => setOpen(true)}
                disabled={auth.tier ? auth.tier == "free" || loading || (searchHighschoolData == null && searchCollegeData == null && searchLeadsData == null) ? true : filterType == "highschool_student" || filterType == "student_parent" ? source == "Organic" ? searchHighschoolData?.userStudents?.count == 0 ? true : false : searchLeadsData?.leads?.count == 0 ? true : false : searchCollegeData?.userStudents?.count == 0 ? true : false : true}
              >
                Export to CSV
              </Button>
              <Tooltip
                title={"Past Exports"}
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: "#46464f",
                      color: "white"
                    }
                  }
                }}
              >
                <IconButton
                  style={{
                    width: 40,
                    height: 40,
                    marginLeft: theme.spacing(1)
                  }}
                  onClick={() => setOpenPastExports(true)}
                >
                  <GetAppRoundedIcon />
                </IconButton>
              </Tooltip>
              <PastExports
                open={openPastExports}
                setOpen={setOpenPastExports}

              />
              <CustomModal
                open={open}
                setOpen={setOpen}
                cardColor={theme.palette.primary.darker}
                title={<Typography style={{ fontSize: 25, fontWeight: 600 }} color="textPrimary">
                  {"Export " + (source == "Organic" ? ((filterType == "highschool_student" || filterType == "student_parent")
                    ? searchHighschoolData?.userStudents?.count <= 3000 ? searchHighschoolData?.userStudents?.count?.toLocaleString() : "3,000" ?? 0
                    : searchCollegeData?.userStudents?.count <= 3000 ? searchCollegeData?.userStudents?.count?.toLocaleString() : "3,000" ?? 0)
                    : searchLeadsData?.leads?.count <= 3000 ? searchLeadsData?.leads?.count?.toLocaleString() : "3,000" ?? 0) + " leads"}</Typography>}
                sub={"Cost: $" + (costPerLeadExport == 0 ? "0" :
                  (costPerLeadExport * Math.min(source == "Organic" ? ((filterType == "highschool_student" || filterType == "student_parent")
                    ? searchHighschoolData?.userStudents?.count ?? 0
                    : searchCollegeData?.userStudents?.count ?? 0) : searchLeadsData?.leads?.count ?? 0, 3000) + 50).toFixed(2))}
                noScroll
              >
                <Box display="flex">
                  <Grid container spacing={2}>
                    <Grid item xs={1} />
                    <Grid item xs={5}>
                      <Button
                        style={{
                          height: 50,
                          backgroundColor: theme.palette.error.main,
                          color: theme.palette.text.primary,
                          borderRadius: 13
                        }}
                        fullWidth
                        variant="contained"
                        onClick={() => setOpen(false)}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item xs={5}>
                      {exportLoading ? (
                        <Box display="flex" ml={6}>
                          <Loading small />
                        </Box>
                      ) : (
                        <CustomButton
                          style={{ height: 50, borderRadius: 13 }}
                          variant="contained"
                          fullWidth
                          onClick={handleExport}
                        >
                          Export
                        </CustomButton>
                      )}
                    </Grid>
                    <Grid item xs={1} />
                  </Grid>
                </Box>
              </CustomModal>
            </Box>
          </FadeIn>
          <Grid container style={{ marginTop: theme.spacing(5), marginBottom: theme.spacing(0) }} height={"calc(100% - 110px)"}>
            <Grid item xs={3} height={"100%"} overflow='hidden'>
              <Box
                marginLeft={1}
                paddingLeft={1}
                maxWidth={600}
                height={"100%"}
                width={"100%"}
                overflow={"auto"}
                paddingTop={1}
                className="cardScroll"
              >
                <FadeIn delay={100}>
                  <Filters
                    ref={filtersRef}
                    setFilters={setFilters}
                    disableFromSourceOption={false}
                    noSearch
                    setSource={setSource}
                    disabled={false}
                    applyOnLoad={true}
                    isNarrow
                  />
                </FadeIn>
              </Box>
            </Grid>
            <Grid item xs={9} height={"100%"}>
              <Box marginLeft={7} marginRight={1.5} marginTop={-1.5} height={"100%"}>
                <Box height={35}>
                  <FadeIn delay={100}>
                    <Typography variant="body2" color="textSecondary">
                      {`(${source == "Organic" ? (filterType == "highschool_student" || filterType == "student_parent"
                        ? searchHighschoolData?.userStudents?.count?.toLocaleString() ?? 0
                        : searchCollegeData?.userStudents?.count?.toLocaleString() ?? 0)
                        : searchLeadsData?.leads?.count?.toLocaleString() ?? 0
                        } results)`}
                    </Typography>
                  </FadeIn>
                </Box>
                {searchHighschoolLoading || searchCollegeLoading || searchLeadsLoading || loading ? (
                  <Box display='flex' justifyContent='center' mt={theme.spacing(10)} mb={theme.spacing(10)}>
                    <Loading />
                  </Box>
                ) : (
                  <>
                    {filterType == "highschool_student" || filterType == "student_parent" ? (
                      <>
                        {
                          source == "Organic" ? (
                            <>
                              {searchHighschoolData ? (
                                <>
                                  {searchHighschoolData?.userStudents?.count === 0 ? (
                                    <>
                                      <Box height="20px" />
                                      <Box display='flex' justifyContent='center' mt={theme.spacing(10)} mb={theme.spacing(10)}>
                                        <Typography>No results.</Typography>
                                      </Box>
                                    </>
                                  ) : (
                                    <>
                                      <div style={{ height: `calc(100%)`, overflowY: "auto" }}>
                                        <PerfectScrollbar
                                          onYReachEnd={loadMore}
                                        >
                                          {searchHighschoolData?.userStudents?.edges?.map((user) => (
                                            <Person
                                              key={user.node.id}
                                              person={user.node}
                                              hasMessagingPermission={props.hasMessagingPermission}
                                              isFavorited={favoritedStudents.map((x: any) => x.get('student').id).includes(user.node.objectId)}
                                              designatedStudents={designatedStudents}
                                              includes={studentIntentIncludes}
                                            />
                                          ))}
                                        </PerfectScrollbar>
                                      </div>
                                    </>
                                  )}
                                </>
                              ) : (
                                <>
                                  <Box height="20px" />
                                  <Box display='flex' justifyContent='center' mt={theme.spacing(10)} mb={theme.spacing(10)}>
                                    <Typography>No results.</Typography>
                                  </Box>
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              {searchLeadsData ? (
                                <>
                                  {searchLeadsData?.leads.count === 0 ? (
                                    <>
                                      <Box height="20px" />
                                      <Box display='flex' justifyContent='center' mt={theme.spacing(10)} mb={theme.spacing(10)}>
                                        <Typography>No results.</Typography>
                                      </Box>
                                    </>
                                  ) : (
                                    <>
                                      <div style={{ height: `calc(100%)`, overflowY: "auto" }}>
                                        <PerfectScrollbar
                                          onYReachEnd={loadMore}
                                        >
                                          {searchLeadsData?.leads?.edges?.map((lead) => (
                                            <Person
                                              key={lead.node.id}
                                              person={lead.node.student}
                                              hasMessagingPermission={props.hasMessagingPermission}
                                              isFavorited={favoritedStudents.map((x: any) => x.get('student').id).includes(lead.node.student.objectId)}
                                              designatedStudents={designatedStudents}
                                              includes={studentIntentIncludes}
                                            />
                                          ))}
                                        </PerfectScrollbar>
                                      </div>
                                    </>
                                  )}
                                </>
                              ) : (
                                <>
                                  <Box height="20px" />
                                  <Box display='flex' justifyContent='center' mt={theme.spacing(10)} mb={theme.spacing(10)}>
                                    <Typography>No results.</Typography>
                                  </Box>
                                </>
                              )}
                            </>
                          )}
                      </>
                    ) : (
                      <>
                        {searchCollegeData ? (
                          <>
                            {searchCollegeData?.userStudents.count === 0 ? (
                              <>
                                <Box height="20px" />
                                <Box display='flex' justifyContent='center' mt={theme.spacing(10)} mb={theme.spacing(10)}>
                                  <Typography>No results.</Typography>
                                </Box>
                              </>
                            ) : (
                              <>
                                <div style={{ height: `calc(100%)`, overflowY: "auto" }}>
                                  <PerfectScrollbar
                                    onYReachEnd={loadMore}
                                  >
                                    {searchCollegeData?.userStudents?.edges?.map((user) => (
                                      <Person
                                        key={user.node.id}
                                        person={user.node}
                                        hasMessagingPermission={props.hasMessagingPermission}
                                        isFavorited={favoritedStudents.map((x: any) => x.get('student').id).includes(user.node.objectId)}
                                        designatedStudents={designatedStudents}
                                        includes={studentIntentIncludes}
                                      />
                                    ))}
                                  </PerfectScrollbar>
                                </div>
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            <Box height="20px" />
                            <Box display='flex' justifyContent='center' mt={theme.spacing(10)} mb={theme.spacing(10)}>
                              <Typography>No results.</Typography>
                            </Box>
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        </Card>
      </FadeIn>
    </Page >
  );
}
