import React, { useState, useEffect, useRef } from "react";
import Parse from "parse";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Card,
  Grid,
  Avatar,
  Box,
  Checkbox,
  Table,
  CardHeader,
  TableBody,
  ButtonBase,
  Hidden,
  Container,
  TableCell,
  TableHead,
  Modal,
  Menu,
  MenuItem,
  Fade,
  TableRow,
  Typography,
  IconButton,
  Tooltip,
  Button as RealButton,
} from "@mui/material";
import FadeIn from "react-fade-in";
import { useTheme } from '@mui/material';
import CustomModal from "../../../../components/Modal";
import { makeStyles } from '@mui/styles';
import SettingsIcon from "@material-ui/icons/Settings";
import TuneIcon from '@material-ui/icons/Tune';
import { gql, useQuery, useLazyQuery, useMutation } from "@apollo/client";
import { Loading, Filters, Button } from "../../../../components";
import { Link, useNavigate } from "react-router-dom";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CloseIcon from "@material-ui/icons/Close";
import { useSnackbar } from "notistack";
import { useAuth } from "../../../../hooks/useAuth";
import Iconify from '../../../../../src/routes/FrontEnd/Components/iconify';
import { getFiltersFromFilterQuery, calcAcademicScore } from "../../../../util/util";
import "../../../../styles.css"

const useStyles = makeStyles((theme: any) => ({
  card: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
    boxShadow: theme.shadows[4],
    borderRadius: 15,
    outlineColor: "#2c2c33",
    outlineStyle: "solid",
    outlineWidth: 1,
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1),
    backgroundColor: "#22222b"
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: theme.spacing(3),
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(4),
    borderRadius: 20,
    outlineColor: "#2c2c33",
    outlineStyle: "solid",
    outlineWidth: 1,
  },
  results: {
    height: 500,
  },
  titleFont: {
    fontWeight: 500
  },
  tableCell: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    "& .MuiTableCell-root": {
      borderBottomColor: "#3c3c46"
    },
  },
}));

const designationOptions = [
  {
    label: "Set as 'No Selection'",
    value: "No Selection",
  },
  {
    label: "Set as 'Interested In Connecting'",
    value: "Interested In Connecting",
  },
];

const SEARCH_HIGHSCHOOL = gql`
  query searchUsers(
    $filters: [UserStudentWhereInput!]
    $majorsFilter: MajorRelationWhereInput
    $interestsFilter: ProfileInterestRelationWhereInput
    $collegesFilter: CollegeRelationWhereInput
    $after: String
  ) {
    userStudents(
      where: {
        AND: $filters
        gender: { exists: true }
        fromLead: { equalTo: false }
        generalInterestedMajors: $majorsFilter
        interests: $interestsFilter
        generalInterestedColleges: $collegesFilter
      }
      first: 40
      after: $after
      order: createdAt_DESC
    ) {
      count
      edges {
        cursor
        node {
          id
          objectId
          createdAt
          user {
            id
            objectId
            username
          }
          sat
          act
          adjustedUWGPA
          adjustedClassRank
          zipCodeState
          gradYear
          verificationStatus
          parentColleges {
            ... on Element {
              value
            }
          }
          siblingColleges {
            ... on Element {
              value
            }
          }
          friendColleges {
            ... on Element {
              value
            }
          }
          appliedColleges {
            ... on Element {
              value
            }
          }
          acceptedColleges {
            ... on Element {
              value
            }
          }
          enrolledColleges {
            ... on Element {
              value
            }
          }
          userContact {
            id
            objectId
            firstName
            lastName
            city
            img {
              url
            }
          }
          settings {
            id
            objectId
            showStatsToColleges
            showLastNameToColleges
          }
        }
      }
    }
  }
`;

const SEARCH_COLLEGEORALUM = gql`
  query searchUsers(
    $filters: [UserStudentWhereInput!]
    $majorsFilter: MajorRelationWhereInput
    $interestsFilter: ProfileInterestRelationWhereInput
    $collegesFilter: CollegeRelationWhereInput
    $after: String
  ) {
    userStudents(
      where: {
        AND: $filters
        gender: { exists: true }
        fromLead: { equalTo: false }
        generalInterestedMajors: $majorsFilter
        interests: $interestsFilter
        college: $collegesFilter
      }
      first: 40
      after: $after
      order: createdAt_DESC
    ) {
      count
      edges {
        cursor
        node {
          id
          objectId
          createdAt
          user {
            id
            objectId
            username
          }
          sat
          act
          adjustedUWGPA
          adjustedClassRank
          zipCodeState
          gradYear
          verificationStatus
          parentColleges {
            ... on Element {
              value
            }
          }
          siblingColleges {
            ... on Element {
              value
            }
          }
          friendColleges {
            ... on Element {
              value
            }
          }
          appliedColleges {
            ... on Element {
              value
            }
          }
          acceptedColleges {
            ... on Element {
              value
            }
          }
          enrolledColleges {
            ... on Element {
              value
            }
          }
          userContact {
            id
            objectId
            firstName
            lastName
            city
            img {
              url
            }
          }
          settings {
            id
            objectId
            showStatsToColleges
            showLastNameToColleges
          }
        }
      }
    }
  }
`;

const SAVE_COLLEGE_QUERY = gql`
  mutation updateCollegeQuery(
    $college: ID!
    $ethnicities: [Any!]
    $locations: [Any!]
    $genders: [Any!]
    $gradyears: [Any!]
    $majors: [Any!]
    $interests: [Any!]
    $type: String
    $parentsEd: String
    $SATfrom: Float
    $SATto: Float
    $ACTfrom: Float
    $ACTto: Float
    $GPAfrom: Float
    $GPAto: Float
    $RANKfrom: Float
    $RANKto: Float
    $zip: String
    $zipRange: Float
    $interestedStudentsOnly: Boolean
    $audienceSize: Float
  ) {
    updateCollege(
      input: {
        id: $college
        fields: {
          impressiveStudentsQuery: {
            createAndLink: {
              ethnicities: $ethnicities
              locations: $locations
              genders: $genders
              gradyears: $gradyears
              majors: $majors
              interests: $interests
              parentsEd: $parentsEd
              type: $type
              SATfrom: $SATfrom
              SATto: $SATto
              ACTfrom: $ACTfrom
              ACTto: $ACTto
              GPAfrom: $GPAfrom
              GPAto: $GPAto
              RANKfrom: $RANKfrom
              RANKto: $RANKto
              zipCode: $zip
              zipRange: $zipRange
              college: { link: $college }
              audienceSize: $audienceSize
              interestedStudentsOnly: $interestedStudentsOnly
            }
          }
        }
      }
    ) {
      college {
        id
        objectId
        impressiveStudentsQuery {
          id
          objectId
          college {
            objectId
          }
          ethnicities {
            ... on Element {
              value
            }
          }
          locations {
            ... on Element {
              value
            }
          }
          genders {
            ... on Element {
              value
            }
          }
          gradyears {
            ... on Element {
              value
            }
          }
          majors {
            ... on Element {
              value
            }
          }
          interests {
            ... on Element {
              value
            }
          }
          SATfrom
          SATto
          ACTfrom
          ACTto
          GPAfrom
          GPAto
          RANKfrom
          RANKto
          zipCode
          zipRange
          interestedStudentsOnly
          audienceSize
          type
          parentsEd
        }
      }
    }
  }
`;

const GET_IMPRESSIVE_QUERY = gql`
  query getImpressiveQuery($id: ID!) {
    college(id: $id) {
      id
      objectId
      impressiveStudentsQuery {
        id
        objectId
        college {
          objectId
        }
        ethnicities {
          ... on Element {
            value
          }
        }
        locations {
          ... on Element {
            value
          }
        }
        genders {
          ... on Element {
            value
          }
        }
        gradyears {
          ... on Element {
            value
          }
        }
        majors {
          ... on Element {
            value
          }
        }
        interests {
          ... on Element {
            value
          }
        }
        SATfrom
        SATto
        ACTfrom
        ACTto
        GPAfrom
        GPAto
        RANKfrom
        RANKto
        zipCode
        zipRange
        interestedStudentsOnly
        audienceSize
        type
        parentsEd
      }
    }
  }
`;

const EditImpressiveQuery = (props) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const auth = useAuth();
  const [open, setOpen] = useState(false);
  const [filters, setFilters] = useState<any>({});
  const [hasAppliedChanges, setHasAppliedChanges] = useState(false);

  const [updateCollegeQuery, { data, error }] = useMutation(SAVE_COLLEGE_QUERY);

  if (error) console.log(JSON.stringify(error, null, 2));

  useEffect(() => {
    if (open === true) {
      setHasAppliedChanges(false);
    }
  }, [open]);

  return (
    <>
      <IconButton>
        <SettingsIcon onClick={() => setOpen(true)} />
      </IconButton>
      <Modal
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: theme.spacing(3),
        }}
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
      >
        <Fade in={open}>
          <Card style={{
            backgroundColor: theme.palette.primary.dark,
            display: "flex",
            flexDirection: "column",
            padding: theme.spacing(4),
            borderRadius: 25,
            outlineColor: "#2c2c33",
            outlineStyle: "solid",
            outlineWidth: 1,
          }}>
            <Box width="600px">
              <Grid container sx={{ alignSelf: "left" }}>
                <Typography style={{ fontWeight: 600, fontSize: 29 }} align="left" color="textPrimary">
                  Edit Student Filter Criteria
                </Typography>
                <Box flexGrow={1} />
                <IconButton style={{ width: 40, height: 40 }} onClick={() => setOpen(false)}>
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Box height="20px" />
              <Typography variant="h6" color="textSecondary">
                {filters.loading ? (
                  <Box ml={theme.spacing(-3.5)} width={100}>
                    <Loading />
                  </Box>
                ) : (
                  `Filters (${filters.numStudents?.toLocaleString()} students)`
                )}
              </Typography>
              <Box height="20px" />
              <Filters
                setFilters={setFilters}
                customType='notLeads'
                filterQuery={props.filterQuery}
                disableFromSourceOption={true}
                disabled={false}
                onApply={() => setHasAppliedChanges(true)}
                applyOnLoad={true}
              />
              <Box height="0px" />
              <Box display="flex" justifyContent="right">
                <Button
                  onClick={() => {
                    updateCollegeQuery({
                      variables: {
                        college: auth.collegeId,
                        ...filters.saveData,
                      },
                    });
                    setOpen(false);
                    props.setFilters(filters);
                  }}
                  disabled={!hasAppliedChanges}
                >
                  Save
                </Button>
              </Box>
            </Box>
          </Card>
        </Fade>
      </Modal>
    </>
  );
};

const StudentRow = (props) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const ref = useRef(null);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [designation, setDesignation] = useState(designationOptions[0].value);

  const auth = useAuth();
  const [intentScore, setIntentScore] = useState(0);

  const navigate = useNavigate();

  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  const handleOpen = (e) => {
    e.stopPropagation();

    setDropdownOpen(true);
  };

  const handleClose = (e) => {
    e.stopPropagation();

    setDropdownOpen(false);
  };

  async function changeDesignation(designation) {
    setDesignation(designation);

    const res = await Parse.Cloud.run("saveDesignation", {
      collegeId: auth.collegeId,
      studentId: props.student.objectId,
      designation: designation,
      userId: Parse.User.current()?.id
    });

    if (res == "added_new_designation") {
      await Parse.Cloud.run("sendIOSPush", { type: "profileViewOrInterest", id: props.student.user?.objectId, bodyStr: "A new school is interested in you!" });
    }

    await Parse.Cloud.run("logNewStudentProfileVisit", {
      visitorId: Parse.User.current()?.id,
      visitedId: props.student.user?.objectId,
    });
  }

  const capitalize_each_word = (str) => {
    if (!str) {
      return ""
    }

    const arr = str.split(" ");

    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }

    const str2 = arr.join(" ");

    return str2
  }

  async function getIntentScore() {
    const res = await Parse.Cloud.run("deriveStudentIntent", {
      studentId: props.student.objectId,
      collegeId: auth.collegeId,
      includes: props.includes ?? [],
      studentFirstName: props.student.userContact.firstName,
      studentState: props.student.zipCodeState,
      studentCity: props.student.userContact.city,
      collegeState: auth.college.state,
      collegeCity: auth.college.city,
      parentColleges: props.student.parentColleges ? props.student.parentColleges.map((x) => x.value) : [],
      siblingColleges: props.student.siblingColleges ? props.student.siblingColleges.map((x) => x.value) : [],
      friendColleges: props.student.friendColleges ? props.student.friendColleges.map((x) => x.value) : [],
      appliedColleges: props.student.appliedColleges ? props.student.appliedColleges.map((x) => x.value) : [],
      acceptedColleges: props.student.acceptedColleges ? props.student.acceptedColleges.map((x) => x.value) : [],
      enrolledColleges: props.student.enrolledColleges ? props.student.enrolledColleges.map((x) => x.value) : []
    });
    setIntentScore(res.intentScore);
  };

  useEffect(() => {
    setDesignation(props.designatedStudents.filter((x: any) => x.get('student').id == props.student.objectId).length > 0 ? (props.designatedStudents.filter((x: any) => x.get('student').id == props.student.objectId)[0] as any).get("designation") : "No Selection")
  }, [props])

  useEffect(() => {
    getIntentScore();

  }, [auth.collegeId]);

  return (
    <TableRow
      className={classes.tableCell}
      hover
      key={props.student.id}
      onClick={() => navigate(`/dashboard/profiles/${props.student.userContact?.objectId}`)}
      style={{ textDecoration: "none", zIndex: -2 }}
    >
      <TableCell
        style={{
          position: 'sticky',
          left: 0,
          zIndex: 1,
          borderRight: "2px solid #41414b"
        }}
      >
        <Card
          style={{
            backgroundColor: theme.palette.primary.dark,
            borderRadius: 0,
            position: 'absolute',
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            boxShadow: 'none',
            zIndex: -3
          }}
        >
        </Card>
        <Box display="flex" alignItems="center">
          <Checkbox
            sx={{
              ml: theme.spacing(-1),
              mr: theme.spacing(1),
              color: theme.palette.text.secondary,
              '&.Mui-checked': {
                color: theme.palette.secondary.main,
              },
              transform: "scale(0.85)",
            }}
            disableRipple
            checked={props.selectedStudents.map((x: any) => x.objectId).includes(props.student.objectId)}
            onChange={e => {
              if (e.target.checked) {
                var newArr = [...props.selectedStudents];
                newArr.push(props.student);
                props.setSelectedStudents(newArr);

              } else {
                var newArr1 = props.selectedStudents.filter(function (e: any) { return e.objectId !== props.student.objectId });

                props.setSelectedStudents(newArr1);
              }

              props.setSelectAllIsChecked(false);
            }}
            onClick={e => {
              e.stopPropagation();
            }}
          />
          <Avatar
            className={classes.avatar}
            src={props.student.userContact?.img?.url}
            style={{
              zIndex: 1
            }}
          />
          <Box
            visibility={props.favoritedStudents.map((x: any) => x.get('student').id).includes(props.student.objectId) ? 'visible' : 'hidden'}
            display='flex'
            alignItems='center'
            justifyContent='center'
            style={{
              height: 15,
              width: 15,
              backgroundColor: "#35c669",
              borderRadius: 10,
              marginLeft: -18,
              marginTop: -25,
              zIndex: 3,
            }}
            sx={{
              boxShadow: 4
            }}
          >
            <Iconify
              icon={'ph:star-fill'}
              width={10}
              color="#fff"
            />
          </Box>
          <Box width="10px" />
          <div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: 'transparent',
              }}
            >
              <Typography
                fontSize={16}
                fontWeight={600}
                color="textPrimary"
              >
                {props.student.settings.showLastNameToColleges == true ? capitalize_each_word(props.student.userContact?.firstName +
                  " " +
                  props.student.userContact?.lastName) : capitalize_each_word(props.student.userContact?.firstName + " " + props.student.userContact?.lastName.charAt(0))}
              </Typography>
              {props?.student?.verificationStatus == "verified" ? (
                <img
                  height={17}
                  width={17}
                  src={"/assets/icons/ic_verify.png"}
                  style={{
                    visibility: props?.student?.verificationStatus == "verified" ? "visible" : "hidden",
                    marginLeft: theme.spacing(0.5),
                  }}
                />
              ) : (<></>)}
              {props?.student?.appliedColleges || props?.student?.acceptedColleges || props?.student?.enrolledColleges ? (
                <Tooltip
                  title={props?.student?.appliedColleges.map((x) => x.value).includes(auth.collegeId) ? "Applied" : props?.student?.acceptedColleges.map((x) => x.value).includes(auth.collegeId) ? "Accepted" : props?.student?.enrolledColleges.map((x) => x.value).includes(auth.collegeId) ? "Enrolled" : "Not Applied"}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: "#46464f",
                        color: "white"
                      }
                    }
                  }}
                >
                  <img
                    height={14}
                    width={14}
                    src={props?.student?.appliedColleges.map((x) => x.value).includes(auth.collegeId) ? "/assets/icons/ic_applied.svg" : props?.student?.acceptedColleges.map((x) => x.value).includes(auth.collegeId) ? "/assets/icons/ic_accepted.svg" : props?.student?.enrolledColleges.map((x) => x.value).includes(auth.collegeId) ? "/assets/icons/ic_enrolled.svg" : "/assets/icons/ic_applied.svg"}
                    style={{
                      visibility: props?.student?.appliedColleges.map((x) => x.value).includes(auth.collegeId) || props?.student?.acceptedColleges.map((x) => x.value).includes(auth.collegeId) || props?.student?.enrolledColleges.map((x) => x.value).includes(auth.collegeId) ? "visible" : "hidden",
                      marginLeft: theme.spacing(0.5),
                    }}
                  />
                </Tooltip>
              ) : (<></>)}
            </div>
            <Typography variant="body2" color="textSecondary" >
              {props.student.user?.username?.toLowerCase()}
            </Typography>
          </div>
        </Box>
      </TableCell>
      <TableCell
        align="center"
        style={{ backgroundColor: "#1f1f25" }}
      >
        <Typography
          fontWeight={500}
          color={"textSecondary"}
          fontSize={14}
        >
          {props.student.gradYear ? props.student.gradYear : ''}
        </Typography>
      </TableCell>
      <TableCell
        align="center"
        style={{ backgroundColor: "#1f1f25" }}
      >
        <Typography
          fontWeight={500}
          color={"textSecondary"}
          fontSize={14}
        >
          {props.student.zipCodeState ? props.student.zipCodeState == "none" ? "None" : props.student.zipCodeState : ''}
        </Typography>
      </TableCell>
      <TableCell
        align="center"
        style={{ backgroundColor: "#1f1f25" }}
      >
        <Typography
          fontWeight={600}
          fontSize={14}
        >
          {intentScore > 0 ? intentScore : ""}
        </Typography>
      </TableCell>
      <TableCell
        align="center"
        style={{ backgroundColor: "#1f1f25" }}
      >
        <Typography
          textAlign={"center"}
          sx={{
            fontSize: 14,
            fontWeight: 800,
            backgroundcolor: "primary",
            backgroundImage: `linear-gradient(to right, #35c669, #2ec4a9)`,
            backgroundSize: "20%",
            backgroundRepeat: "repeat",
            backgroundClip: "text",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",

          }}>
          {calcAcademicScore(props.student.sat, props.student.act, props.student.psat, props.student.adjustedUWGPA, props.student.adjustedClassRank) ?? 0}
        </Typography>
      </TableCell>
      <TableCell
        align="center"
        style={{ backgroundColor: "#1f1f25" }}
      >
        <Box
          display="flex"
          alignItems="center"
          component={ButtonBase}
          onClick={e => {
            e.stopPropagation();

            handleOpen(e);
          }}
          ref={ref}
          style={{
            borderRadius: 25,
            backgroundColor: "#0d0d14",
            // padding: 1,
            minWidth: 70,
            paddingRight: theme.spacing(2),
            paddingLeft: theme.spacing(2),
            marginLeft: 1,
            paddingTop: 5,
            paddingBottom: 5,
            outlineWidth: 1,
            outlineStyle: "solid",
            outlineColor: "#3b3b42"
          }}
        >
          <Hidden smDown>
            <Typography
              style={{
                fontSize: 11,
                fontWeight: 600
              }}
              color="inherit"
            >
              {designation}
            </Typography>
          </Hidden>
        </Box>
        <Menu
          onClose={e => handleClose(e)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          keepMounted
          slotProps={{
            paper: {
              sx: {
                minWidth: 270,
                marginLeft: theme.spacing(0),
                marginTop: theme.spacing(1),
                backgroundColor: theme.palette.primary.dark,
                borderRadius: 1.5,
                outlineColor: "#3c3c44",
                outlineStyle: "solid",
                outlineWidth: 1,
              }
            }
          }}
          anchorEl={ref.current}
          open={isDropdownOpen}
        >
          {designationOptions.map((option) => (
            <MenuItem
              key={option.value}
              onClick={e => {
                e.stopPropagation();

                setDropdownOpen(false);
                changeDesignation(option.value);
              }}
            >
              {option.label}
            </MenuItem>
          ))}
        </Menu>
      </TableCell>
      <TableCell
        align="right"
        style={{ backgroundColor: "#1f1f25" }}
      >
        <Typography
          fontWeight={500}
          fontSize={14}
        >
          {months[(new Date(props.student.createdAt)).getMonth()] + " " + (new Date(props.student.createdAt)).getDate() + ", " + (new Date(props.student.createdAt)).getFullYear()}
        </Typography>
      </TableCell>
      <TableCell
        align="right"
        style={{ backgroundColor: "#1f1f25" }}
      >
        <ChevronRightIcon />
      </TableCell>
    </TableRow>
  )
}

export default function ImpressiveStudents() {
  const theme = useTheme();
  const classes = useStyles(theme);

  const auth = useAuth();
  const popoverRef = useRef<any>();
  const [anchor, setAnchor] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [studentIntentIncludes, setStudentIntentIncludes] = useState<any>([]);
  const [designatedStudents, setDesignatedStudents] = useState([]);
  const [filters, setFilters] = useState<any>([]);
  const [favoritedStudents, setFavoritedStudents] = useState([]);
  const [searchHighschool, { data: searchHighschoolData, loading: searchHighschoolLoading, error: searchHighschoolError, fetchMore: fetchHighschoolMore },] = useLazyQuery(SEARCH_HIGHSCHOOL);
  const [searchCollege, { data: searchCollegeData, loading: searchCollegeLoading, error: searchCollegeError, fetchMore: fetchCollegeMore },] = useLazyQuery(SEARCH_COLLEGEORALUM);
  const [selectedStudents, setSelectedStudents] = useState<Array<any>>([]);
  const [selectAllIsChecked, setSelectAllIsChecked] = useState(false);
  const { data, loading } = useQuery(GET_IMPRESSIVE_QUERY, {
    variables: { id: auth.collegeId },
  });

  const loadMore = async () => {
    if (data?.college?.impressiveStudentsQuery?.type == "highschool_student"
      || data?.college?.impressiveStudentsQuery?.type == "student_parent") {
      try {
        if (fetchHighschoolMore) {
          await fetchHighschoolMore({
            variables: {
              after:
                searchHighschoolData?.userStudents?.edges[
                  searchHighschoolData?.userStudents?.edges.length - 1
                ]?.cursor,
            },
            updateQuery: (prev, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prev;
              if (fetchMoreResult.userStudents.edges.length === 0) return prev;
              return {
                ...prev,
                userStudents: {
                  ...prev.userStudents,
                  edges: [
                    ...prev.userStudents.edges,
                    ...fetchMoreResult.userStudents.edges,
                  ],
                },
              };
            },
          });
        }
      } catch (error) { return }

    } else {
      try {
        if (fetchCollegeMore) {
          await fetchCollegeMore({
            variables: {
              after:
                searchCollegeData?.userStudents?.edges[
                  searchCollegeData?.userStudents?.edges.length - 1
                ]?.cursor,
            },
            updateQuery: (prev, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prev;
              if (fetchMoreResult.userStudents.edges.length === 0) return prev;
              return {
                ...prev,
                userStudents: {
                  ...prev.userStudents,
                  edges: [
                    ...prev.userStudents.edges,
                    ...fetchMoreResult.userStudents.edges,
                  ],
                },
              };
            },
          });
        }
      } catch (error) { return }
    }
  };

  const selectAll = (checked: boolean) => {
    if (checked) {
      if (data?.college?.impressiveStudentsQuery?.type == "highschool_student"
        || data?.college?.impressiveStudentsQuery?.type == "student_parent") {
        setSelectedStudents(searchHighschoolData?.userStudents?.edges.map((x: any) => x.node));

      } else {
        setSelectedStudents(searchCollegeData?.userStudents?.edges.map((x: any) => x.node));
      }

      setSelectAllIsChecked(true);

    } else {
      setSelectedStudents([]);
      setSelectAllIsChecked(false);
    }
  }

  async function getIntentScoreFactors() {
    const includes = await Parse.Cloud.run("getIntentScoreFactors", {
      collegeId: auth.collegeId,
    });

    setStudentIntentIncludes(includes);
  }

  async function addFavorites(itemsToAdd: Array<any>) {
    await Parse.Cloud.run("addFavorites", {
      userUniversityId: Parse.User.current()!.get("userUniversity").id,
      selectedStudentIds: itemsToAdd.map((x: any) => x.objectId)
    });

    setSelectedStudents([]);
    setSelectAllIsChecked(false);

    getData();

    enqueueSnackbar("Successfully added to favorites", {
      variant: "success",
    });
  }

  useEffect(() => {
    if (data?.college?.impressiveStudentsQuery) {
      loadFilterQuery();
    }
  }, [data]);

  const loadFilterQuery = async () => {
    var lat = 0.0;
    var lng = 0.0;
    await fetch("https://maps.googleapis.com/maps/api/geocode/json?address=" + data?.college?.impressiveStudentsQuery?.zipCode + '&key=' + 'AIzaSyCaOCAZzMinsPPM-bNUQcXfFerpJPerGQI')
      .then(response => response.json())
      .then(data => {
        if (data.results[0]) {
          if (data.results[0].geometry != null) {
            lat = data.results[0].geometry.location.lat;
            lng = data.results[0].geometry.location.lng;
          }
        }
      })

    var obj = Object.assign({}, data?.college?.impressiveStudentsQuery);
    obj["lat"] = lat
    obj["lng"] = lng

    const list = getFiltersFromFilterQuery(
      obj
    );
    let majorsFilter: any = undefined;
    if (
      data?.college?.impressiveStudentsQuery?.majors?.map((m) => m.value)
        ?.length > 0
    ) {
      majorsFilter = {
        have: {
          objectId: {
            in: data?.college?.impressiveStudentsQuery?.majors?.map(
              (m) => m.value
            ),
          },
        },
      };
    }
    let interestsFilter: any = undefined;
    if (
      data?.college?.impressiveStudentsQuery?.interests?.map((m) => m.value)
        ?.length > 0
    ) {
      interestsFilter = {
        have: {
          objectId: {
            in: data?.college?.impressiveStudentsQuery?.interests?.map(
              (m) => m.value
            ),
          },
        },
      };
    }
    let collegesFilter: any = undefined;
    if (data?.college?.impressiveStudentsQuery?.interestedStudentsOnly) {
      collegesFilter = {
        have: { objectId: { equalTo: auth.collegeId } },
      };
    }
    setFilters({
      list: list.length > 0 ? list : undefined,
      majorsFilter: majorsFilter,
      interestsFilter: interestsFilter,
      collegesFilter: collegesFilter,
    });
  }

  useEffect(() => {
    getData();
  }, [filters]);

  useEffect(() => {
    if (auth.collegeId) {
      getIntentScoreFactors();
    }
  }, [auth.collegeId]);

  useEffect(() => {
    document.body.style.overflow = 'auto'

    return () => {
      document.body.style.overflow = 'auto'
    }
  }, []);

  const getData = async () => {
    await Parse.Cloud.run("getDesignatedStudents", {
      collegeId: auth.collegeId,
    }).then(
      (res) => {
        setDesignatedStudents(res);
      },
      (err) => {
      }
    );

    await Parse.Cloud.run("getFavoritedStudents", {
      userUniversityId: Parse.User.current()!.get("userUniversity").id,
      limit: 1000,
      skip: 0
    }).then(
      (res) => {
        setFavoritedStudents(res);
      }
    );

    if (data?.college?.impressiveStudentsQuery?.type == "highschool_student"
      || data?.college?.impressiveStudentsQuery?.type == "student_parent") {
      searchHighschool({
        variables: {
          filters: filters?.list,
          majorsFilter: filters?.majorsFilter,
          interestsFilter: filters?.interestsFilter,
          collegesFilter: filters?.collegesFilter,
        },
      });

    } else {
      searchCollege({
        variables: {
          filters: filters?.list,
          majorsFilter: filters?.majorsFilter,
          interestsFilter: filters?.interestsFilter,
          collegesFilter: filters?.collegesFilter,
        },
      });
    }
  }

  if (searchCollegeError) console.log(JSON.stringify(searchCollegeError, null, 2));
  if (searchHighschoolError) console.log(JSON.stringify(searchHighschoolError, null, 2));

  return (
    <FadeIn delay={400}>
      <Card style={{
        backgroundColor: theme.palette.primary.dark,
        padding: theme.spacing(3),
        boxShadow: theme.shadows[4],
        borderRadius: 25,
        outlineColor: "#2c2c33",
        outlineStyle: "solid",
        outlineWidth: 1,
      }}>
        {data?.college?.impressiveStudentsQuery?.type == "highschool_student"
          || data?.college?.impressiveStudentsQuery?.type == "student_parent" ? (
          <CardHeader
            title={
              "Student Filter" +
              (searchHighschoolData
                ? ` (${searchHighschoolData?.userStudents?.count
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} total)`
                : "")
            }
            action={
              <Box>
                <IconButton
                  ref={popoverRef}
                  onClick={(e: any) => {
                    setOpenMenu(true);
                    setAnchor(e.currentTarget);
                  }}
                >
                  <TuneIcon />
                </IconButton>
                <Menu
                  id="post-menu"
                  anchorEl={anchor}
                  open={openMenu}
                  slotProps={{
                    paper: {
                      sx: {
                        backgroundColor: theme.palette.primary.dark,
                        borderRadius: 1.5,
                        outlineColor: "#3c3c44",
                        outlineStyle: "solid",
                        outlineWidth: 1,
                      }
                    }
                  }}
                  onClose={() => setOpenMenu(false)}
                >
                  <MenuItem
                    key={"favSelected"}
                    disabled={selectedStudents.length > 0 ? false : true}
                    onClick={() => {
                      setOpenMenu(false);
                      addFavorites(selectedStudents);
                    }}
                  >
                    {"Favorite Selected (" + selectedStudents.length + ")"}
                  </MenuItem>
                  <MenuItem
                    key={"favAll"}
                    onClick={() => {
                      setOpenMenu(false);
                      setOpenConfirm(true);
                    }}
                  >
                    Favorite All
                  </MenuItem>
                </Menu>
                <CustomModal
                  open={openConfirm}
                  setOpen={setOpenConfirm}
                  sub={
                    <Typography color='textSecondary'>
                      {"Are you sure you want to add " + searchHighschoolData?.userStudents?.edges.length + " students to your favorites?"}
                    </Typography>
                  }
                  noScroll
                >
                  <Box display="flex">
                    <Grid container spacing={2}>
                      <Grid item xs={1} />
                      <Grid item xs={5}>
                        <RealButton
                          style={{
                            height: 50,
                            backgroundColor: theme.palette.error.main,
                            color: theme.palette.text.primary,
                            borderRadius: 16
                          }}
                          fullWidth
                          variant="contained"
                          onClick={() => setOpenConfirm(false)}
                        >
                          Cancel
                        </RealButton>
                      </Grid>
                      <Grid item xs={5}>
                        <Button
                          style={{ height: 50, borderRadius: 16 }}
                          variant="contained"
                          fullWidth
                          onClick={() => {
                            addFavorites(searchHighschoolData?.userStudents?.edges
                              ?.map((e) => e.node));

                            setOpenConfirm(false);
                          }}
                        >
                          Yes
                        </Button>
                      </Grid>
                      <Grid item xs={1} />
                    </Grid>
                  </Box>
                </CustomModal>

                <EditImpressiveQuery
                  setFilters={setFilters}
                  filterQuery={data?.college?.impressiveStudentsQuery}
                />
              </Box>
            }
            titleTypographyProps={{ fontWeight: 600 }}
          />
        ) : (
          <>
            <CardHeader
              title={
                "Student Filter" +
                (searchCollegeData
                  ? ` (${searchCollegeData?.userStudents?.count
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} total)`
                  : "")
              }
              action={
                <Box>
                  <IconButton
                    ref={popoverRef}
                    onClick={(e: any) => {
                      setOpenMenu(true);
                      setAnchor(e.currentTarget);
                    }}
                  >
                    <TuneIcon />
                  </IconButton>
                  <Menu
                    id="post-menu"
                    anchorEl={anchor}
                    open={openMenu}
                    slotProps={{
                      paper: {
                        sx: {
                          backgroundColor: theme.palette.primary.dark,
                          borderRadius: 1.5,
                          outlineColor: "#3c3c44",
                          outlineStyle: "solid",
                          outlineWidth: 1,
                        }
                      }
                    }}
                    onClose={() => setOpenMenu(false)}
                  >
                    <MenuItem
                      key={"favSelected"}
                      disabled={selectedStudents.length > 0 ? false : true}
                      onClick={() => {
                        setOpenMenu(false);
                        addFavorites(selectedStudents);
                      }}
                    >
                      {"Favorite Selected (" + selectedStudents.length + ")"}
                    </MenuItem>
                    <MenuItem
                      key={"favAll"}
                      onClick={() => {
                        setOpenMenu(false);
                        setOpenConfirm(true);
                      }}
                    >
                      Favorite All
                    </MenuItem>
                  </Menu>
                  <CustomModal
                    open={openConfirm}
                    setOpen={setOpenConfirm}
                    sub={
                      <Typography color='textSecondary'>
                        {"Are you sure you want to add " + searchCollegeData?.userStudents?.edges.length + " students to your favorites?"}
                      </Typography>
                    }
                    noScroll
                  >
                    <Box display="flex">
                      <Grid container spacing={2}>
                        <Grid item xs={1} />
                        <Grid item xs={5}>
                          <RealButton
                            style={{
                              height: 50,
                              backgroundColor: theme.palette.error.main,
                              color: theme.palette.text.primary,
                              borderRadius: 16
                            }}
                            fullWidth
                            variant="contained"
                            onClick={() => setOpenConfirm(false)}
                          >
                            Cancel
                          </RealButton>
                        </Grid>
                        <Grid item xs={5}>
                          <Button
                            style={{ height: 50, borderRadius: 16 }}
                            variant="contained"
                            fullWidth
                            onClick={() => {
                              addFavorites(searchCollegeData?.userStudents?.edges
                                ?.map((e) => e.node));

                              setOpenConfirm(false);
                            }}
                          >
                            Yes
                          </Button>
                        </Grid>
                        <Grid item xs={1} />
                      </Grid>
                    </Box>
                  </CustomModal>

                  <EditImpressiveQuery
                    setFilters={setFilters}
                    filterQuery={data?.college?.impressiveStudentsQuery}
                  />
                </Box>
              }
              titleTypographyProps={{ fontWeight: 600 }}
            />
          </>
        )}
        {loading ? (
          <Loading />
        ) : (
          <>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                {!data?.college?.impressiveStudentsQuery ? (
                  <>
                    <Box height="40px" />
                    <Box display='flex' justifyContent='center'>
                      <Typography>No criteria defined.</Typography>
                    </Box>
                    <Box height="20px" />
                  </>
                ) : (
                  <Container style={{ height: (data?.college?.impressiveStudentsQuery?.type == "highschool_student" || data?.college?.impressiveStudentsQuery?.type == "student_parent") ? Math.min(((searchHighschoolData?.userStudents?.count * 80) + 57), 600) : Math.min(((searchCollegeData?.userStudents?.count * 80) + 57), 600) }} maxWidth="xl">
                    <PerfectScrollbar
                      className="scrollbar"
                      onYReachEnd={() => {
                        if (data?.college?.impressiveStudentsQuery?.type == "highschool_student"
                          || data?.college?.impressiveStudentsQuery?.type == "student_parent") {
                          if (searchHighschoolData?.userStudents?.edges.length > 0) {
                            loadMore();
                          }
                        } else {
                          if (searchCollegeData?.userStudents?.edges.length > 0) {
                            loadMore();
                          }
                        }
                        document.body.style.overflow = 'auto'
                      }}
                      onScrollY={() => {
                        document.body.style.overflow = 'hidden'
                      }}
                      onMouseLeave={() => {
                        document.body.style.overflow = 'auto'
                      }}
                      onYReachStart={() => {
                        document.body.style.overflow = 'auto'
                      }}
                    >
                      {searchHighschoolLoading || searchCollegeLoading ? (
                        <Loading />
                      ) : (
                        <div style={{ minWidth: 1200 }}>
                          <Table style={{ borderCollapse: 'separate' }}>
                            <TableHead>
                              <TableRow
                                style={{
                                  position: 'sticky',
                                  top: 0,
                                  zIndex: 3,
                                }}
                              >
                                <TableCell
                                  align="left"
                                  style={{
                                    position: 'sticky',
                                    left: 0,
                                  }}
                                  sx={{
                                    "&.MuiTableCell-root": {
                                      borderBottom: 'none'
                                    }
                                  }}
                                >
                                  <Card
                                    style={{
                                      backgroundColor: theme.palette.primary.dark,
                                      borderRadius: 0,
                                      position: 'absolute',
                                      width: "100%",
                                      height: "100%",
                                      top: 0,
                                      left: 0,
                                      boxShadow: "none",
                                      zIndex: -3,
                                      borderBottom: "2px solid #41414b"
                                    }}
                                  >
                                  </Card>
                                  <Checkbox
                                    sx={{
                                      ml: theme.spacing(-1),
                                      mr: theme.spacing(1),
                                      color: theme.palette.text.secondary,
                                      '&.Mui-checked': {
                                        color: theme.palette.secondary.main,
                                      },
                                      transform: "scale(0.85)",
                                    }}
                                    disableRipple
                                    checked={selectAllIsChecked}
                                    onChange={e => selectAll(e.target.checked)}
                                    onClick={e => {
                                      e.stopPropagation();
                                    }}
                                  />
                                  Student
                                </TableCell>
                                <TableCell
                                  align="left"
                                  sx={{
                                    "&.MuiTableCell-root": {
                                      borderBottom: 'none'
                                    }
                                  }}
                                >
                                  <Card
                                    style={{
                                      backgroundColor: theme.palette.primary.dark,
                                      borderRadius: 0,
                                      position: 'absolute',
                                      width: "100%",
                                      height: "100%",
                                      top: 0,
                                      right: 0,
                                      boxShadow: "none",
                                      borderBottom: "2px solid #41414b",
                                      zIndex: -3
                                    }}
                                  />
                                  Grad Year
                                </TableCell>
                                <TableCell
                                  align="left"
                                  sx={{
                                    zIndex: 10,
                                    "&.MuiTableCell-root": {
                                      borderBottom: 'none'
                                    }
                                  }}
                                >
                                  State
                                </TableCell>
                                <TableCell
                                  align="left"
                                  sx={{
                                    "&.MuiTableCell-root": {
                                      borderBottom: 'none'
                                    }
                                  }}
                                >
                                  Intent Score
                                </TableCell>
                                <TableCell
                                  align="left"
                                  sx={{
                                    "&.MuiTableCell-root": {
                                      borderBottom: 'none'
                                    }
                                  }}
                                >
                                  Academic Score
                                </TableCell>
                                <TableCell
                                  align="left"
                                  sx={{
                                    "&.MuiTableCell-root": {
                                      borderBottom: 'none'
                                    }
                                  }}
                                >
                                  Interest Level
                                </TableCell>
                                <TableCell
                                  align="right"
                                  sx={{
                                    "&.MuiTableCell-root": {
                                      borderBottom: 'none'
                                    }
                                  }}
                                >
                                  Date Joined
                                </TableCell>
                                <TableCell
                                  align="right"
                                  sx={{
                                    "&.MuiTableCell-root": {
                                      borderBottom: 'none'
                                    }
                                  }}
                                >
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {data?.college?.impressiveStudentsQuery?.type == "highschool_student"
                                || data?.college?.impressiveStudentsQuery?.type == "student_parent" ? (
                                <>
                                  {searchHighschoolData?.userStudents?.edges
                                    ?.map((e) => e.node)
                                    .map((student) => (
                                      <StudentRow
                                        student={student}
                                        setSelectedStudents={setSelectedStudents}
                                        setSelectAllIsChecked={setSelectAllIsChecked}
                                        favoritedStudents={favoritedStudents}
                                        selectedStudents={selectedStudents}
                                        designatedStudents={designatedStudents}
                                        includes={studentIntentIncludes}
                                      />
                                    ))}
                                </>
                              ) : (
                                <>
                                  {searchCollegeData?.userStudents?.edges
                                    ?.map((e) => e.node)
                                    .map((student) => (
                                      <StudentRow
                                        student={student}
                                        setSelectedStudents={setSelectedStudents}
                                        setSelectAllIsChecked={setSelectAllIsChecked}
                                        favoritedStudents={favoritedStudents}
                                        selectedStudents={selectedStudents}
                                        designatedStudents={designatedStudents}
                                        includes={studentIntentIncludes}
                                      />
                                    ))}
                                </>
                              )}
                            </TableBody>
                          </Table>
                        </div>
                      )}
                    </PerfectScrollbar>
                  </Container>
                )}
              </Grid>
            </Grid>
          </>
        )}
      </Card>
    </FadeIn>
  );
}
