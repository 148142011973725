import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import CompactLayout from '../../Layouts/compact';
import { SplashScreen } from '../../Components/loading-screen';
import AuthBackgroundLayout from '../../Layouts/auth/background';

// ----------------------------------------------------------------------

const SigninPage = lazy(() => import('../../Pages/auth/signin'));
const RegisterPage = lazy(() => import('../../Pages/auth/register'));
const ClaimAccountPage = lazy(() => import('../../Pages/auth/claim-account'));
const ForgotPasswordPage = lazy(() => import('../../Pages/auth/forgot-password'));
const VerifyPage = lazy(() => import('../../Pages/auth/verify'));
const UnsubscribePage = lazy(() => import('../../Pages/auth/unsubscribe'));

// ----------------------------------------------------------------------

export const authRoutes = [
  {
    path: '',
    element: (
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    ),
    children: [
      {
        path: 'signin',
        element: (
          <AuthBackgroundLayout type="signin">
            <SigninPage />
          </AuthBackgroundLayout>
        ),
      },
      {
        path: 'claim-school',
        element: (
          <AuthBackgroundLayout type="claim-school">
            <RegisterPage />
          </AuthBackgroundLayout>
        ),
      },
      {
        path: 'claim-account',
        element: (
          <AuthBackgroundLayout type="claim-account">
            <ClaimAccountPage />
          </AuthBackgroundLayout>
        ),
      },
      {
        element: (
          <Suspense>
            <Outlet />
          </Suspense>
        ),
        children: [
          {
            path: 'forgot-password',
            element: (
              <AuthBackgroundLayout type="forgotPassword">
                <ForgotPasswordPage />
              </AuthBackgroundLayout>
            )
          },
          {
            path: 'verify',
            element: (
              <AuthBackgroundLayout type="verify">
                <VerifyPage />
              </AuthBackgroundLayout>
            )
          },
          {
            path: 'unsubscribe',
            element: (
              <AuthBackgroundLayout type="unsubscribe">
                <UnsubscribePage />
              </AuthBackgroundLayout>
            )
          },
        ],
      },
    ],
  },
];
