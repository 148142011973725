import { useEffect, useCallback, useRef, useState } from 'react';
import { Link as RouterLinkDom } from "react-router-dom";

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Fade from '@mui/material/Fade';
import Stack from '@mui/material/Stack';
import Portal from '@mui/material/Portal';
import Grid from '@mui/material/Unstable_Grid2';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import MenuIcon from "@material-ui/icons/Menu";

import Label from '../../../../Components/label';
import Image from '../../../../Components/image';
import { usePathname } from '../../../../Routes/hooks';
import { useBoolean } from '../../../../Hooks/use-boolean';
import { useOffSetTop } from '../../../../Hooks/use-off-set-top';
import { RouterLink } from '../../../../Routes/components';
import { useActiveLink } from '../../../../Routes/hooks/use-active-link';

import { NavListProps, NavItemBaseProps } from '../types';

import { NavItem } from './nav-item';
import { StyledMenu, StyledSubheader } from './styles';

// ----------------------------------------------------------------------

export default function NavList({ item, headerOnDark }: { item: NavItemBaseProps, headerOnDark?: Boolean }) {
  const pathname = usePathname();

  const menuOpen = useBoolean();
  const [anchor, setAnchor] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [hasEnteredMenu, setHasEnteredMenu] = useState(false);

  const offset = useOffSetTop();

  const active = useActiveLink(item.path, false);

  const externalLink = item.path.includes('http');

  const mainList = item.children ? item.children.filter((list) => list.title !== 'Common') : [];

  const commonList = item.children
    ? item.children.find((list) => list.title === 'Common')
    : null;

  useEffect(() => {
    if (menuOpen.value) {
      menuOpen.onFalse();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  function handleClose() {
    setOpenMenu(false);
    setHasEnteredMenu(false);
  }

  function handleEnter() {
    setHasEnteredMenu(true);
  }

  return (
    <>
      <NavItem
        item={item}
        active={active}
        open={menuOpen.value}
        style={{ color: headerOnDark ? offset ? "#000" : "#fff" : "#000" }}
        externalLink={externalLink}
        onClick={(e: any) => {
          setOpenMenu(true);
          setAnchor(e.currentTarget);
        }}
        onMouseEnter={(e: any) => {
          setOpenMenu(true);
          setAnchor(e.currentTarget);
        }}
        onMouseLeave={async () => {
          if (!hasEnteredMenu) {
            setOpenMenu(false);
            setHasEnteredMenu(false);
          }
        }}
      />
      {mainList.length > 0 ? (
        <Menu
          id="menu-appbar"
          anchorEl={anchor}
          open={openMenu}
          style={{ zIndex: 1 }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          MenuListProps={{ onMouseLeave: handleClose, onPointerEnter: handleEnter }}
          slotProps={{
            paper: {
              sx: {
                backgroundColor: "#fff",
                borderRadius: 2,
                boxShadow: 3,
                marginTop: -0.45,
              }
            }
          }}
          onClose={() => setOpenMenu(false)}
        >
          {mainList.map((list) => (
            <MenuItem
              sx={{
                "&:hover": {
                  backgroundColor: "#f0f0f2",
                  borderRadius: 1.8,
                }
              }}
              onClick={(e: any) => {
                setOpenMenu(false);
              }}
              component={RouterLinkDom}
              to={list.path}
            >
              <Image
                style={{
                  marginLeft: 10,
                  height: 50,
                  width: 50,
                  padding: 7,
                  color: "GrayText",
                }}
                src={list.icon}>
              </Image>
              <div style={{ marginLeft: 15 }}>
                <Typography
                  textAlign='left'
                  style={{
                    fontWeight: 600,
                    fontSize: 17,
                    color: "#000",
                    marginTop: 8,
                    marginRight: 30
                  }}>
                  {list.title}
                </Typography>

                <Typography
                  textAlign='left'
                  style={{
                    fontWeight: 400,
                    fontSize: 14,
                    color: "GrayText",
                    marginBottom: 9,
                    marginRight: 30
                  }}>
                  {list.subTitle}
                </Typography>
              </div>
            </MenuItem>
          ))}
        </Menu>
      ) : (
        <></>
      )}
    </>
  );
}