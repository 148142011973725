import React, { useEffect, useState } from "react";
import { Grid, Button, Typography, MenuItem } from "@mui/material";
import NumberFormat from "react-number-format";
import { Input } from "../../../components";
import { Loading } from "../../../components/Loading";
import { gql, useMutation } from "@apollo/client";
import { useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => ({
  button: {
    background: "linear-gradient(75deg, #35c669, #2ec4a9)",
    border: 0,
    borderRadius: 15,
    color: "#fff",
    padding: "0 30px",
    minHeight: "50px",
  },
  selectStatus: {
    "& .MuiInputBase-root": {
      backgroundColor: theme.palette.primary.light,
      borderBottomRightRadius: theme.shape.borderRadius,
      borderBottomLeftRadius: theme.shape.borderRadius,
    },
  },
}));

const SAVE = gql`
  mutation saveCollegeStats(
    $id: ID!
    $collegeStatId: ID!
    $enrolled: Float!
    $admissionRate: Float!
    $gradRate: Float!
    $testingRequirements: Float!
  ) {
    updateCollege(
      input: {
        id: $id
        fields: { testingRequirements: $testingRequirements }
      }
    ) {
      college {
        id
        objectId
        testingRequirements
      }
    }
    updateCollegeStat(
      input: {
        id: $collegeStatId
        fields: {
          numStudentsEnrolled: $enrolled
          admissionRate: $admissionRate
          graduationRate8yr: $gradRate
        }
      }
    ) {
      collegeStat {
        id
        objectId
        numStudentsEnrolled
        admissionRate
        graduationRate8yr
      }
    }
  }
`;

function Field(props) {
  return <Input {...props} variant="filled" />;
}

export default function Admissions(props) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [numStudentsEnrolled, setNumStudentsEnrolled] = useState("");
  const [admissionRate, setAdmissionRate] = useState("");
  const [graduationRate, setGraduationRate] = useState("");
  const [testingRequirements, setTestingRequirements] = useState(0);

  const [saveCollegeStats, { loading }] = useMutation(SAVE);

  useEffect(() => {
    if (props.data) {
      setNumStudentsEnrolled(
        props.data?.college?.collegeStats?.numStudentsEnrolled
      );
      setAdmissionRate(
        (props.data?.college?.collegeStats?.admissionRate * 100)
          .toString()
          .substring(0, 5)
      );
      setGraduationRate(
        (props.data?.college?.collegeStats?.graduationRate8yr * 100)
          .toString()
          .substring(0, 5)
      );
      setTestingRequirements(
        props.data?.college?.testingRequirements
      );
    }
  }, [props.data]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography justify-content="left" align="left" color="textPrimary">
          General Admissions Information
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <NumberFormat
          fullWidth
          label="Number of enrolled students"
          name="numStudentsEnrolled"
          value={numStudentsEnrolled}
          onChange={(e) => setNumStudentsEnrolled(e.target.value)}
          customInput={Field}
          thousandSeparator
          isNumericString
        />
      </Grid>
      <Grid item xs={12}>
        <NumberFormat
          fullWidth
          label="Admission Rate"
          name="admissionRate"
          value={admissionRate}
          onChange={(e) => setAdmissionRate(e.target.value)}
          customInput={Field}
          suffix="%"
        />
      </Grid>
      <Grid item xs={12}>
        <NumberFormat
          fullWidth
          label="Graduation Rate (8yr)"
          name="graduationRate"
          value={graduationRate}
          onChange={(e) => setGraduationRate(e.target.value)}
          customInput={Field}
          suffix="%"
        />
      </Grid>
      <Grid item xs={12}>
        <Input
          className={classes.selectStatus}
          label="Testing Requirements"
          select
          fullWidth
          value={testingRequirements == 1 ? "Required" : testingRequirements == 2 ? "Recommended" : testingRequirements == 5 ? "Not Required" : "Required"}
          onChange={(e) => {
            setTestingRequirements(e.target.value == "Required" ? 1 : e.target.value == "Recommended" ? 2 : e.target.value == "Not Required" ? 5 : 1);
          }}
        >
          <MenuItem value="Required">Required</MenuItem>
          <MenuItem value="Recommended">Recommended</MenuItem>
          <MenuItem value="Not Required">Not Required</MenuItem>
        </Input>
      </Grid>
      <Grid item xs={12} sx={{ alignSelf: "right" }}>
        {loading ? (
          <Loading small />
        ) : (
          <Button
            style={{
              background: "linear-gradient(75deg, #35c669, #2ec4a9)",
              border: 0,
              borderRadius: 15,
              color: "#fff",
              padding: "0 30px",
              minHeight: "50px",
            }}
            onClick={() => {
              saveCollegeStats({
                variables: {
                  id: props.data?.college?.objectId,
                  collegeStatId: props?.data?.college?.collegeStats?.objectId,
                  enrolled: typeof numStudentsEnrolled == "number" ? numStudentsEnrolled : parseFloat(numStudentsEnrolled.replace(",", "")),
                  admissionRate: parseFloat(admissionRate) / 100,
                  gradRate: parseFloat(graduationRate) / 100,
                  testingRequirements: testingRequirements
                },
              });
            }}
          >
            Save Admissions Info
          </Button>
        )}
      </Grid>
    </Grid>
  );
}
