import React, { useEffect, useState } from "react";
import { States as states, Loading, Input } from "../../../components";
import {
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Button,
} from "@mui/material";
import { useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { gql, useMutation } from "@apollo/client";

const useStyles = makeStyles((theme: any) => ({
  root: {},
  button: {
    background: "linear-gradient(75deg, #35c669, #2ec4a9)",
    border: 0,
    borderRadius: 15,
    color: "#fff",
    padding: "0 30px",
    minHeight: "50px",
  },
}));

const SAVE = gql`
  mutation saveCollege(
    $id: ID!
    $name: String!
    $city: String!
    $bio: String!
    $state: String!
    $isPublic: Boolean!
  ) {
    updateCollege(
      input: {
        id: $id
        fields: { name: $name, city: $city, bio: $bio, state: $state, isPublic: $isPublic }
      }
    ) {
      college {
        id
        objectId
        name
        city
        bio
        state
        isPublic
      }
    }
  }
`;

export default function GeneralInfo(props) {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [officialName, setOfficialName] = useState();
  const [city, setCity] = useState();
  const [funFact, setFunFact] = useState();
  const [state, setState] = useState<any>();
  const [isPublic, setIsPublic] = useState(false);

  const [saveCollege, { loading }] = useMutation(SAVE);

  useEffect(() => {
    if (props.data) {
      setOfficialName(props.data?.college?.name);
      setCity(props.data?.college?.city);
      setState(props.data?.college?.state);
      setFunFact(props.data?.college?.bio);
      setIsPublic(props.data?.college?.isPublic);
    }
  }, [props]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography justify-content="left" align="left" color="textPrimary">
          General Profile Information
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Input
          fullWidth
          variant="filled"
          label="Official Name"
          name="officialName"
          value={officialName}
          onChange={(e) => setOfficialName(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <Input
          fullWidth
          variant="filled"
          label="City"
          name="city"
          value={city}
          onChange={(e) => setCity(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <Input
          variant="filled"
          fullWidth
          select
          label={"State"}
          name="state"
          value={state ?? ""}
        >
          {states.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              onClick={() => setState(option.value)}
            >
              {option.label}
            </MenuItem>
          ))}
        </Input>
      </Grid>
      <Grid item xs={12}>
        <Input
          fullWidth
          variant="filled"
          label="Fun Fact"
          name="fun fact"
          value={funFact}
          onChange={(e) => setFunFact(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
            name="is_public_institution"
            sx={{
              color: theme.palette.text.secondary,
              '&.Mui-checked': {
                color: theme.palette.secondary.main,
              },
            }}
            checked={isPublic}
            onChange={() => setIsPublic(!isPublic)}
            />
          }
          label="Public Instution"
        />
      </Grid>
      <Grid item xs={12} sx={{ alignSelf: "right" }}>
        {loading ? (
          <Loading small />
        ) : (
          <Button
            style={{
              background: "linear-gradient(75deg, #35c669, #2ec4a9)",
              border: 0,
              borderRadius: 15,
              color: "#fff",
              padding: "0 30px",
              minHeight: "50px",
            }}
            onClick={() =>
              saveCollege({
                variables: {
                  id: props.data?.college?.objectId,
                  name: officialName,
                  city: city,
                  bio: funFact,
                  state: state,
                  isPublic: isPublic,
                },
              })
            }
          >
            Save General Info
          </Button>
        )}
      </Grid>
    </Grid>
  );
}
