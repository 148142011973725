import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import CompactLayout from '../../Layouts/compact';
import { SplashScreen } from '../../Components/loading-screen';

// ----------------------------------------------------------------------

const Page404 = lazy(() => import('../../Pages/404'));

// ----------------------------------------------------------------------

export const errorRoutes = [
  {
    element: (
      <CompactLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </CompactLayout>
    ),
    children: [
      { path: '404', element: <Page404 /> },
    ],
  },
];
