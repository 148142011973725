import { lazy } from 'react';

// ----------------------------------------------------------------------

const ContactPage = lazy(() => import('../../Pages/_company/contact'));

// ----------------------------------------------------------------------

export const contactRoutes = [
  {
    path: 'contact',
    children: [
      { element: <ContactPage />, index: true },
    ],
  },
];
