import React, { useState, useRef } from "react";
import Parse from "parse";
import {
  ListItemText,
  SvgIcon,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Avatar,
  Box,
  Typography,
  Button,
} from "@mui/material";
import { useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AvatarGroup } from "@material-ui/lab";
import { MoreVertical as MoreIcon } from "react-feather";
import { useParams, Navigate } from "react-router-dom";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useAuth } from "../../../../hooks/useAuth";

const useStyles = makeStyles((theme: any) => ({
  root: {
    alignItems: "center",
    backgroundColor: theme.palette.background.dark,
    borderBottom: `1px solid ${theme.palette.divider}`,
    display: "flex",
    flexShrink: 0,
    minHeight: 64,
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  smallAvatar: {
    height: 30,
    width: 30,
    "&:first-child": {
      marginTop: 10,
    },
  },
  popover: {
    width: 200,
    backgroundColor: theme.palette.secondary.dark,
    outlineColor: "#2a2a30",
    outlineStyle: "solid",
    outlineWidth: 1,
  },
  avatar: {
    height: 42,
    width: 42,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    backgroundColor: "#1d1d2b",
  },
  title: {
    height: 42,
    marginTop: theme.spacing(0),
  },
}));

const DELETE_THREAD = gql`
  mutation deleteThread($id: ID!) {
    deleteCollegeThread(input: { id: $id }) {
      collegeThread {
        id
      }
    }
  }
`;

const DetailHeader = ({ student, title }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const auth = useAuth();

  const moreRef = useRef(null);
  const [openMenu, setOpenMenu] = useState(false);
  const { id } = useParams();
  const [deleteThread, { data }] = useMutation(DELETE_THREAD);
  const [back, setBack] = useState(false);

  const handleMenuOpen = () => {
    setOpenMenu(true);
  };

  const handleMenuClose = () => {
    setOpenMenu(false);
  };

  const handleDelete = async () => {
    deleteThread({
      variables: { id },
    });
  };

  const capitalize_each_word = (str) => {
    if (!str) {
      return ""
    }

    const arr = str.split(" ");

    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }

    const str2 = arr.join(" ");

    return str2
  }

  if (data || back) {
    return <Navigate to="/dashboard/messages" />;
  }

  return (
    <div style={{
      alignItems: "center",
      backgroundColor: theme.palette.primary.dark,
      borderBottom: `1px solid ${theme.palette.divider}`,
      display: "flex",
      flexShrink: 0,
      minHeight: 64,
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    }}>
      <Box alignItems="left" display="flex">
        <Tooltip
          title="Back to threads"
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: "#46464f",
                color: "white"
              }
            }
          }}
        >
          <IconButton onClick={() => setBack(true)}>
            <ArrowBackIcon />
          </IconButton>
        </Tooltip>
        <Avatar
          alt="Student"
          key={student.id}
          src={student.img?.url}
          className={classes.avatar}
        />
        <Box width="10px" />
        <Link to={`/dashboard/profiles/${student.objectId}`} style={{ textDecoration: 'none' }}>
          <Typography className={classes.title} color="textPrimary" style={{ fontSize: 27, fontWeight: 600, textDecoration: 'underline' }} >
            {capitalize_each_word(title)}
          </Typography>
        </Link>
        {student?.userStudent?.verificationStatus == "verified" ? (
          <img
            height={22}
            width={22}
            src={"/assets/icons/ic_verify.png"}
            style={{
              visibility: student?.userStudent?.verificationStatus == "verified" ? "visible" : "hidden",
              marginLeft: theme.spacing(1),
              marginTop: theme.spacing(1),
            }}
          />
        ) : (<></>)}
        {student?.userStudent?.appliedColleges || student?.userStudent?.acceptedColleges || student?.userStudent?.enrolledColleges ? (
          <Tooltip
            title={student?.userStudent?.appliedColleges.map((x) => x.value).includes(auth.collegeId) ? "Applied" : student?.userStudent?.acceptedColleges.map((x) => x.value).includes(auth.collegeId) ? "Accepted" : student?.userStudent?.enrolledColleges.map((x) => x.value).includes(auth.collegeId) ? "Enrolled" : "Not Applied"}
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: "#46464f",
                  color: "white"
                }
              }
            }}
          >
            <img
              height={18}
              width={18}
              src={student?.userStudent?.appliedColleges.map((x) => x.value).includes(auth.collegeId) ? "/assets/icons/ic_applied.svg" : student?.userStudent?.acceptedColleges.map((x) => x.value).includes(auth.collegeId) ? "/assets/icons/ic_accepted.svg" : student?.userStudent?.enrolledColleges.map((x) => x.value).includes(auth.collegeId) ? "/assets/icons/ic_enrolled.svg" : "/assets/icons/ic_applied.svg"}
              style={{
                visibility: student?.userStudent?.appliedColleges.map((x) => x.value).includes(auth.collegeId) || student?.userStudent?.acceptedColleges.map((x) => x.value).includes(auth.collegeId) || student?.userStudent?.enrolledColleges.map((x) => x.value).includes(auth.collegeId) ? "visible" : "hidden",
                marginLeft: theme.spacing(0.7),
                marginTop: theme.spacing(1.3),
              }}
            />
          </Tooltip>
        ) : (<></>)}
      </Box>
      <Box flexGrow={1} />
      <Tooltip
        title="More options"
        componentsProps={{
          tooltip: {
            sx: {
              bgcolor: "#46464f",
              color: "white"
            }
          }
        }}
      >
        <IconButton onClick={handleMenuOpen} ref={moreRef}>
          <SvgIcon fontSize="small">
            <MoreIcon />
          </SvgIcon>
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={moreRef.current}
        keepMounted
        elevation={1}
        onClose={handleMenuClose}
        open={openMenu}
        slotProps={{
          paper: {
            sx: {
              width: 200,
              backgroundColor: theme.palette.primary.dark,
              borderRadius: 1.5,
              outlineColor: "#3c3c44",
              outlineStyle: "solid",
              outlineWidth: 1,
            }
          }
        }}
      >
        <MenuItem onClick={() => handleDelete()}>
          <ListItemText primary="Delete thread" />
        </MenuItem>
      </Menu>
    </div>
  );
};

export default DetailHeader;
