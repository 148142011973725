import React, { useEffect, useState } from "react";
import Parse from "parse";
import { Link } from "react-router-dom";
import { Lightbox } from "react-modal-image";
import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  CardHeader,
  Grid,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Chip,
  TextField,
} from "@mui/material";
import { useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import ChatIcon from "@material-ui/icons/Chat";
import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import ShareIcon from "@material-ui/icons/Share";
import CommentIcon from "@material-ui/icons/Comment";
import AssessmentIcon from "@material-ui/icons/Assessment";
import { ThumbsDown, ThumbsUp } from "react-feather";
import { gql, useMutation } from "@apollo/client";
import { Input, Loading, Modal as CustomModal, Button } from "../../../../components";
import SendIcon from "@material-ui/icons/Send";
import { useAuth } from "../../../../hooks/useAuth";
import AddReply from "./AddReply";
import Reply from "./Reply";
import { parseDate } from "../../../../util";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme: any) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 20,
    outlineColor: "#29292d",
    outlineStyle: "solid",
    outlineWidth: 1,
  },
  date: {
    marginLeft: 6,
  },
  media: {
    height: 500,
  },
  reactions: {
    display: "flex",
    alignItems: "center",
  },
  tag: {
    marginLeft: theme.spacing(1),
  },
  reply: {
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(3),
  },
  input: {
    "& .MuiInputBase-root": {
      backgroundColor: theme.palette.primary.light,
      borderBottomRightRadius: theme.shape.borderRadius,
      borderBottomLeftRadius: theme.shape.borderRadius,
    },
  },
  errorButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.text.primary,
  },
}));

const REPORT_FORUM = gql`
  mutation reportForum($id: ID!, $myID: ID!, $comment: String) {
    createReportedForum(
      input: { fields: { forum: { link: $id }, from: { link: $myID } , comment: $comment} }
    ) {
      reportedForum {
        id
        objectId
      }
    }
  }
`;

const ForumCard = ({ data, ...props }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [openMenu, setOpenMenu] = useState(false);
  const [openReportBox, setOpenReportBox] = useState(false)
  const [anchor, setAnchor] = useState(null);
  const [reportForum, { data: reportData, error }] = useMutation(REPORT_FORUM);
  const { enqueueSnackbar } = useSnackbar();
  const [reportDescription, setReportDescription] = useState("");
  const [reportReturn, setReportReturn] = useState<any>(null)

  const auth = useAuth();

  const getContrastColor = (hex) => {
    if (hex.indexOf("#") === 0) {
      hex = hex.slice(1);
    }
    if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
      throw new Error("Invalid HEX color.");
    }
    var r = parseInt(hex.slice(0, 2), 16),
      g = parseInt(hex.slice(2, 4), 16),
      b = parseInt(hex.slice(4, 6), 16);
    return r * 0.299 + g * 0.587 + b * 0.114 > 160 ? "#000000" : "#FFFFFF";
  };

  const handleChange = (event) => {
    event.persist();
    setReportDescription(event.target.value);
  };

  const handleReport = async (e) => {
    e.preventDefault();
    // reportForum({
    //   variables: {
    //     id: data?.objectId,
    //     myID: Parse.User.current().get("userContact").id,
    //     comment: reportDescription
    //   },
    // });
    if (Parse.User.current()) {
      await setReportReturn(Parse.Cloud.run("reportForum", {
        forumID: data?.objectId,
        comment: reportDescription,
        user: Parse.User.current()?.id,
      }));
    }

    setOpenMenu(false);
    setOpenReportBox(false);
  };

  useEffect(() => {
    if (reportReturn === 1) {
      enqueueSnackbar("Error reported forum.", {
        variant: "error"
      })
    }
    else if (reportReturn) {
      enqueueSnackbar("Successfully reported forum.", {
        variant: "success"
      })
    }

  }, [reportReturn])

  return (
    <>
      <Card style={{
        backgroundColor: theme.palette.primary.dark,
        borderRadius: 25,
        padding: theme.spacing(0.5),
        outlineColor: "#29292d",
        outlineStyle: "solid",
        outlineWidth: 1,
      }}>
        <CardHeader
          avatar={
            <Avatar
              alt="Person"
              component={Link}
              to={`/dashboard/profiles/${data?.author?.objectId}`}
              src={data?.author?.img?.url ?? data?.collegeAuthor?.img?.url}
              style={{
                textDecoration: "none",
                backgroundColor: "#1d1d2b",
              }}
            />
          }
          disableTypography
          subheader={
            <Box display="flex" alignItems="center">
              <Typography variant="caption" color="textSecondary" style={{ fontSize: 13 }}>
                {parseDate(data?.createdAt)}
              </Typography>
            </Box>
          }
          title={
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: 'transparent',
              }}
            >
              <Typography
                color="textPrimary"
                component={Link}
                to={`/dashboard/profiles/${data?.author?.objectId}`}
                variant="h6"
                style={{ textDecoration: "none" }}
                sx={{ ":hover": { color: theme.palette.secondary.main } }}
              >
                {data?.author
                  ? data?.author?.firstName + " " + data?.author?.lastName
                  : data?.collegeAuthor?.name}
              </Typography>
              {data?.author?.userStudent?.verificationStatus == "verified" ? (
                <img
                  height={17}
                  width={17}
                  src={"/assets/icons/ic_verify.png"}
                  style={{
                    visibility: data?.author?.userStudent?.verificationStatus == "verified" ? "visible" : "hidden",
                    marginLeft: theme.spacing(0.5),
                  }}
                />
              ) : (<></>)}
              {data?.author?.userStudent?.appliedColleges || data?.author?.userStudent?.acceptedColleges || data?.author?.userStudent?.enrolledColleges ? (
                <Tooltip
                  title={data?.author?.userStudent?.appliedColleges.map((x) => x.value).includes(auth.collegeId) ? "Applied" : data?.author?.userStudent?.acceptedColleges.map((x) => x.value).includes(auth.collegeId) ? "Accepted" : data?.author?.userStudent?.enrolledColleges.map((x) => x.value).includes(auth.collegeId) ? "Enrolled" : "Not Applied"}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: "#46464f",
                        color: "white"
                      }
                    }
                  }}
                >
                  <img
                    height={14}
                    width={14}
                    src={data?.author?.userStudent?.appliedColleges.map((x) => x.value).includes(auth.collegeId) ? "/assets/icons/ic_applied.svg" : data?.author?.userStudent?.acceptedColleges.map((x) => x.value).includes(auth.collegeId) ? "/assets/icons/ic_accepted.svg" : data?.author?.userStudent?.enrolledColleges.map((x) => x.value).includes(auth.collegeId) ? "/assets/icons/ic_enrolled.svg" : "/assets/icons/ic_applied.svg"}
                    style={{
                      visibility: data?.author?.userStudent?.appliedColleges.map((x) => x.value).includes(auth.collegeId) || data?.author?.userStudent?.acceptedColleges.map((x) => x.value).includes(auth.collegeId) || data?.author?.userStudent?.enrolledColleges.map((x) => x.value).includes(auth.collegeId) ? "visible" : "hidden",
                      marginLeft: theme.spacing(0.5),
                    }}
                  />
                </Tooltip>
              ) : (<></>)}
            </div>
          }
          action={
            <>
              <IconButton
                aria-controls="ad-menu"
                onClick={(e: any) => {
                  setOpenMenu(true);
                  setAnchor(e.currentTarget);
                }}
              >
                <MoreHorizIcon />
              </IconButton>
              <Menu
                id="ad-menu"
                anchorEl={anchor}
                open={openMenu}
                slotProps={{
                  paper: {
                    sx: {
                      backgroundColor: theme.palette.primary.dark,
                      borderRadius: 1.5,
                      outlineColor: "#3c3c44",
                      outlineStyle: "solid",
                      outlineWidth: 1,
                    }
                  }
                }}
                onClose={() => setOpenMenu(false)}
              >
                <MenuItem onClick={() => setOpenReportBox(true)}>
                  Report
                </MenuItem>
                <CustomModal
                  open={openReportBox}
                  setOpen={setOpenReportBox}
                  sub={<Typography color='white' fontSize={20} fontWeight={600} >Give a reason for your report.</Typography>}
                  noScroll
                >
                  <form onSubmit={handleReport} noValidate>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-around"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid item xs={9}>
                        <Input
                          className={classes.input}
                          fullWidth
                          onChange={handleChange}
                          value={reportDescription}
                          label={"Reason for report"}
                          variant="filled"
                          InputProps={{ disableUnderline: true }}
                        />
                      </Grid>

                      <Grid item xs={3}>
                        <Button
                          style={{ height: 50, borderRadius: 13 }}
                          variant="contained"
                          fullWidth
                          type="submit"
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </CustomModal>
              </Menu>
            </>
          }
        />
        <Box px={3} pb={2}>
          {props.showReplies ? (
            <Typography variant="h5" color="textPrimary">
              {data?.title}
            </Typography>
          ) : (
            <Typography
              component={Link}
              to={`/dashboard/forums/${data?.objectId}`}
              style={{ textDecoration: "none" }}
              variant="h5"
              color="textPrimary"
            >
              {data?.title}
            </Typography>
          )}
          <Box height="8px" />
          <Typography variant="body1" color="textSecondary">
            {data?.content}
          </Typography>
          <Box mt={2}>
            <div className={classes.reactions}>
              <IconButton disabled>
                <ThumbsUp fontSize="small" />
              </IconButton>
              <Typography color="textSecondary" style={{ fontSize: 18, fontWeight: 600 }}>
                {data?.numUpVotes ?? 0}
              </Typography>
              <Box width={10} />
              <IconButton disabled>
                <ThumbsDown fontSize="small" />
              </IconButton>
              <Typography color="textSecondary" style={{ fontSize: 18, fontWeight: 600 }}>
                {data?.numDownVotes ?? 0}
              </Typography>
              <Box width="15px" />
              <Tooltip
                title="Comments"
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: "#46464f",
                      color: "white"
                    }
                  }
                }}
              >
                {props.showReplies ? (
                  <IconButton disabled>
                    <CommentIcon fontSize="small" />
                  </IconButton>
                ) : (
                  <IconButton
                    component={Link}
                    to={`/dashboard/forums/${data?.objectId}`}
                    style={{ textDecoration: "none" }}
                  >
                    <CommentIcon fontSize="small" />
                  </IconButton>
                )}
              </Tooltip>
              <Typography color="textSecondary" style={{ fontSize: 18, fontWeight: 600 }}>
                {data?.replies?.count}
              </Typography>
              <Box flexGrow={1} />
              {data?.tags?.edges?.map((e) => (
                <Chip
                  key={e.node.id}
                  size="small"
                  label={e.node.tag}
                  style={{
                    backgroundColor: e.node.color,
                    color: getContrastColor(e.node.color),
                  }}
                  className={classes.tag}
                />
              ))}
            </div>
          </Box>
        </Box>
      </Card>
      {props.showReplies && (
        <>
          <AddReply postId={data?.objectId} numReplies={data?.replies?.count} />
          {data?.replies?.edges
            ?.map((e) => e.node)
            ?.map((reply) => (
              <Reply reply={reply} refreshReplies={props.refreshReplies} />
            ))}
        </>
      )}
    </>
  );
};

export default ForumCard;
