import React, { useEffect, useState } from "react";
import Parse from "parse";
import {
  Card,
  CardHeader,
  CardContent,
  Tooltip,
  Box,
} from "@mui/material";
import FadeIn from "react-fade-in";
import { useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { geoCentroid } from "d3-geo";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  Annotation,
} from "react-simple-maps";
import allStates from "./allStates.json";
import { Loading } from "../../../../components";

const useStyles = makeStyles((theme: any) => ({
  card: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    height: 380,
    borderRadius: 15,
    outlineColor: "#2c2c33",
    outlineStyle: "solid",
    outlineWidth: 1,
    boxShadow: theme.shadows[4],
  },
  boxShadow: theme.shadows[4],
  grid: {
    flexGrow: 1,
  },
  map: {
    maxWidth: 350,
    alignItems: "center",
  },
  titleFont: {
    fontWeight: 500
  },
}));

const offsets = {
  VT: [50, -8],
  NH: [34, 2],
  MA: [30, -1],
  RI: [28, 2],
  CT: [35, 10],
  NJ: [34, 1],
  DE: [33, 0],
  MD: [47, 10],
  DC: [49, 21],
};

const MapChart = ({ setTooltipContent, data }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <Box display='flex' style={{ justifyContent: "center" }}>
      <ComposableMap
        data-tip=""
        projection="geoAlbersUsa"
        alignSelf={'center'}
        style={{ alignSelf: 'center', maxWidth: 350 }}
        className={classes.map}
      >
        {data && (
          <Geographies geography={data}>
            {({ geographies }) => (
              <>
                {geographies.map((geo) => (
                  <Geography
                    key={geo.rsmKey}
                    stroke="#373741"
                    geography={geo}
                    fill={geo.properties.color}
                    onMouseEnter={() => {
                      setTooltipContent(
                        `${geo.properties.name} - ${geo.properties.value}`
                      );
                    }}
                    onMouseLeave={() => {
                      setTooltipContent("");
                    }}
                    style={{
                      hover: {
                        fill: "#35c669",
                        outline: "none",
                      },
                      pressed: {
                        fill: "#35c669",
                        outline: "none",
                      },
                    }}
                  />
                ))}
                {geographies.map((geo) => {
                  const centroid = geoCentroid(geo);
                  var cur: any = allStates.find((s) => s.val === geo.id);
                  cur = "";
                  return (
                    <g key={geo.rsmKey + "-name"}>
                      {cur &&
                        centroid[0] > -160 &&
                        centroid[0] < -67 &&
                        (Object.keys(offsets).indexOf(cur.id) === -1 ? (
                          <Marker coordinates={centroid}>
                            <text y="2" fontSize={14} textAnchor="middle">
                              {cur.id}
                            </text>
                          </Marker>
                        ) : (
                          <Annotation
                            subject={centroid}
                            dx={offsets[cur.id][0]}
                            dy={offsets[cur.id][1]}
                          >
                            <text
                              x={4}
                              fontSize={14}
                              alignmentBaseline="middle"
                            >
                              {cur.id}
                            </text>
                          </Annotation>
                        ))}
                    </g>
                  );
                })}
              </>
            )}
          </Geographies>
        )}
      </ComposableMap>
    </Box>
  );
};

export default function StudentsMap() {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [content, setContent] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      await Parse.Cloud.run("dashboardMap").then((response) => {
        setData(response);
        setLoading(false);
      });
    }
    fetchData();
  }, []);

  return (
    <FadeIn delay={350}>
      <Card style={{
        backgroundColor: theme.palette.primary.dark,
        padding: theme.spacing(2),
        height: 350,
        borderRadius: 25,
        outlineColor: "#2c2c33",
        outlineStyle: "solid",
        outlineWidth: 1,
        boxShadow: theme.shadows[4],
      }}>
        <CardHeader
          align="center"
          title="Map of Interested Students"
          titleTypographyProps={{ fontWeight: 600 }} />
        <CardContent style={{ alignContent: 'center' }}>
          {loading ? (
            <Loading />
          ) : (
            <Tooltip
              title={content}
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: "#46464f",
                    color: "white"
                  }
                }
              }}
            >
              <div>
                <MapChart setTooltipContent={setContent} data={data} />
              </div>
            </Tooltip>
          )}
        </CardContent>
      </Card>
    </FadeIn>
  );
}
