import React from "react";
import Parse from "parse";
import { Box, Typography } from "@mui/material";
import { useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => ({
  root: {
    marginBottom: theme.spacing(0),
    display: "flex",
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(2),
  },
  avatar: {
    height: 32,
    width: 32,
  },
  image: {
    cursor: "pointer",
    height: "auto",
    maxWidth: "100%",
    width: 380,
  },
}));

const MessageItem = ({ message, isLast }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const senderDetails =
    message.from?.userStudent != null
      ? {
          avatar: "",
          name: "Sender",
          type: "contact",
        }
      : {
          avatar: "",
          name: "Me",
          type: "user",
        };

  const parseDate = (date) => {
    let d = new Date(date);
    const mediumTime = new Intl.DateTimeFormat("en", {
      timeStyle: "short",
      dateStyle: "short",
    });
    return mediumTime.format(d);
  };

  return (
    <div className={classes.root}>
      <Box
        display="flex"
        maxWidth={600}
        ml={senderDetails.type === "user" ? "auto" : 0}
      >
        {/* <Avatar className={classes.avatar} src={senderDetails.avatar} /> */}
        <Box ml={2}>
          <Box
            bgcolor={
              senderDetails.type === "user"
                ? "secondary.main"
                : "secondary.dark"
            }
            style={
              senderDetails.type === "user"
                ? { background: "linear-gradient(130deg, #2ec4a9, 25%, #f4dfab)" }
                : {}
            }
            borderRadius={1.5}
            boxShadow={1}
            color={
              senderDetails.type === "user"
                ? "text.contrastText"
                : "text.primary"
            }
            px={2}
            py={1}
          >
            <Box>
              <Typography color="inherit" variant="body1">
                {message.content}
              </Typography>
            </Box>
          </Box>
          <Box mt={1} display="flex" justifyContent={senderDetails.type === "user" ? "right" : "left"}>
            <Typography noWrap color="textSecondary" style={{ fontSize: 12 }}>
              {senderDetails.type === "user" && isLast ? message?.readByRecipient == true ? parseDate(message?.createdAt) + " - Opened" : parseDate(message?.createdAt) + " - Sent" : parseDate(message?.createdAt)}
            </Typography>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default MessageItem;
