import React from "react";
import { NavLink as RouterLink } from "react-router-dom";
import { Button, ListItem, useTheme } from "@mui/material";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => ({
  item: {
    display: "flex",
  },
  button: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: "flex-start",
    letterSpacing: 0,
    textTransform: "none",
  },
  icon: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  title: {
    marginRight: "auto",
    color: theme.palette.text.secondary
  },
  selectedTitle: {
    marginRight: "auto",
    color: theme.palette.secondary.main
  },
  active: {
    color: theme.palette.primary.main,
    "& $title": {
      fontWeight: theme.typography.fontWeightMedium,
    },
    "& $icon": {
      color: theme.palette.primary.main,
    },
  },
}));

const NavItem = ({
  href,
  icon: Icon,
  title,
  selected,
  ...props
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <ListItem
      className={classes.item}
      style={{
        marginTop: 10,
        height: 45
      }}
      disableGutters
      {...props}
    >
      <Button
        className={classes.button}
        component={RouterLink}
        disabled={props.disabled}
        to={href}
        style={{ 
          marginLeft: props.ml, 
          width: props.width, 
          height: 45, 
          fontSize: 13,
          fontWeight: props.fontWeight
        }}
      >
        {Icon && <Icon className={classes.icon} size="20" />}
        {selected ? (
          <span className={classes.selectedTitle}>{title}</span>
        ) : (
          <span className={classes.title}>{title}</span>
        )}
      </Button>
    </ListItem>
  );
};

export default NavItem;
