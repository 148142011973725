import React from "react";
import { Typography, Link } from "@mui/material";
import { VERSION } from "../env";

export default function Copyright() {
  return (
    <>
      <Typography variant="body2" color="textSecondary" align="center" style={{ fontSize: 13 }}>
        {"Copyright © "}
        <Link color="inherit">OptN</Link> {new Date().getFullYear()}
        {". " + VERSION}
      </Typography>
      <Typography variant="body2" color="textSecondary" align="center" style={{ fontSize: 11 }}>
        <Link href="/terms" color="inherit">
          Terms of Service
        </Link>
        {" | "}
        <Link href="/privacy" color="inherit" style={{ fontSize: 12 }}>
          Privacy Policy
        </Link>
      </Typography>
    </>
  );
}
