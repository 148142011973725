import React, { useState, useEffect, useRef } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Card,
  Grid,
  Box,
  Menu,
  MenuItem,
  Typography,
  Hidden,
  Button as MuiButton,
  CardHeader,
  Paper,
  ButtonBase,
  CardContent,
} from "@mui/material";
import FadeIn from "react-fade-in";
import CustomModal from "../../../components/Modal";
import Button from "../../../components/Button";
import { Loading } from "../../../components/Loading";
import Page from "../../../components/Page";
import { CreditCard } from "react-feather";
import UpgradePayment from "./UpgradePayment";
import ChangePaymentMethod from "./ChangePaymentMethod";
import { useAuth } from "../../../hooks/useAuth";
import Parse from "parse";
import { gql, useQuery } from "@apollo/client";
import { useSnackbar } from "notistack";
import { useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { parseDate } from '../../../util'

const useStyles = makeStyles((theme: any) => ({
  root: {},
  card: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
    borderRadius: 20,
    outlineColor: "#2c2c33",
    outlineStyle: "solid",
    outlineWidth: 1,
  },
  title: {
    fontSize: 30,
    fontWeight: 600,
    color: "#fff"
  },
  subHeader: {
    fontSize: 13,
    fontWeight: 400,
    color: "#c6c9d3"
  },
  bulletList: {
    marginLeft: 12,
    listStyleType: "disc"
  },
  button: {
    background: "linear-gradient(75deg, #35c669, #2ec4a9)",
    border: 0,
    color: "#fff",
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2)
  },
  cancelButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.text.primary,
  },
  errorButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.text.primary,
  },
}));

const FETCH_SETTINGS = gql`
  query fetchSettings($id: ID!) {
    user(id: $id) {
      id
      objectId
      userContact {
        id
        objectId
        email
      }
    }
  }
`;

export default function Billing(props) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const ref = useRef(null);

  const [tier, setTier] = useState('free');
  const [subscriptionStatus, setSubscriptionStatus] = useState('');
  const [subscriptionExpirationDate, setSubscriptionExpirationDate] = useState();
  const [subscriptionMonthlyCost, setSubscriptionMonthlyCost] = useState(0);
  const [subscriptionYearlyCost, setSubscriptionYearlyCost] = useState(0);
  const [monthlyUserFee, setMonthlyUserFee] = useState(0);
  const [numUsers, setNumUsers] = useState(0);
  const [paymentMethodLast4, setPaymentMethodLast4] = useState("");
  const [paymentMethodType = "loading...", setPaymentMethodType] = useState<any>("");
  const { enqueueSnackbar } = useSnackbar();
  const auth = useAuth();
  const id = Parse.User.current()?.id;
  const [refreshing, setRefreshing] = useState(false);
  const [paymentFrequency, setPaymentFrequency] = useState("monthly");
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState("");
  const [openConfirm, setOpenConfirm] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const { data, error } = useQuery(FETCH_SETTINGS, {
    variables: { id },
    fetchPolicy: "network-only",
  });

  const paymentFrequencyOptions = [
    {
      label: "Monthly",
      value: "monthly",
    },
    {
      label: "Yearly",
      value: "yearly",
    },
  ];

  const handleOpen = (e) => {
    e.stopPropagation();

    setDropdownOpen(true);
  };

  const handleClose = (e) => {
    e.stopPropagation();

    setDropdownOpen(false);
  };

  async function fetchBillingInfo() {
    const result = await Parse.Cloud.run("getBillingInfo", { collegeId: auth.collegeId })

    setLoading(false);

    setNumUsers(result.numUsers);
    setSubscriptionMonthlyCost(result.subscriptionCostMonthly ?? 0);
    setSubscriptionYearlyCost(result.subscriptionCostYearly ?? 0);
    setPaymentFrequency(result.billingFrequency ?? "yearly");
    setMonthlyUserFee(result.costPerUser ?? 0);
    setSubscriptionStatus(result.subscriptionStatus ?? '');
    setSubscriptionExpirationDate(result.subscriptionExpirationDate ?? null);

    setTier(result.tier);
    auth.setTier(result.tier);

    if (result.paymentMethod == "none") {
      setPaymentMethodType("No Card or Bank");
      setPaymentMethodLast4("");

    } else {
      console.log(result.paymentMethod);
      if (result.paymentMethod.card) {
        setPaymentMethodType(result.paymentMethod.card?.brand);
        setPaymentMethodLast4(result.paymentMethod.card?.last4);

      } else {
        setPaymentMethodType(result.paymentMethod.us_bank_account?.bank_name);
        setPaymentMethodLast4(result.paymentMethod.us_bank_account?.last4);
      }
    }
  }

  async function handleConfirm() {
    setOpenConfirm(false)
    setRefreshing(true);

    console.log(numUsers);

    if (tier == "free") {
      await Parse.Cloud.run("upgradeToPremiumTier", { collegeId: auth.collegeId, billingFrequency: paymentFrequency })

    } else {
      if (numUsers > 3) {
        setRefreshing(false);

        enqueueSnackbar("Before you can downgrade you need to deactivate at least " + -(3 - numUsers) + " users", {
          variant: "error",
        });

      } else {
        await Parse.Cloud.run("downgradeToFreeTier", { collegeId: auth.collegeId })
      }
    }

    await fetchBillingInfo();

    if (tier == "free") {
      enqueueSnackbar("Successfully upgraded to Premium", {
        variant: "success",
      });

    } else if (numUsers <= 3) {
      enqueueSnackbar("Successfully downgraded to free tier", {
        variant: "success",
      });
    }
  }

  async function refresh() {
    setRefreshing(true);
    await fetchBillingInfo().then(() => setRefreshing(false));
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  useEffect(() => {
    setEmail(data?.user?.userContact?.email);
  }, [data]);

  useEffect(() => {
    if (auth.tier != null) {
      props.setTierHook();
    }
  }, [tier]);

  useEffect(() => {
    refresh();

    document.body.style.backgroundColor = "#0e0e14";
  }, []);

  if (loading) {
    return <Loading lower />;
  }
  if (error) return <Typography color="error">{error.toString()}</Typography>;

  return (
    <>
      <Page title="Billing" maxWidth="xl">
        <FadeIn>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Card style={{
                backgroundColor: theme.palette.primary.dark,
                padding: theme.spacing(2),
                borderRadius: 25,
                outlineColor: "#2c2c33",
                outlineStyle: "solid",
                outlineWidth: 1,
              }}>
                <CardHeader
                  title={<Typography style={{
                    fontSize: 30,
                    fontWeight: 600,
                    color: "#fff"
                  }}>Free Tier</Typography>}
                  subheader={<Typography align="left" style={{
                    fontSize: 13,
                    fontWeight: 400,
                    color: "#c6c9d3",
                  }}><ul style={{ padding: 0, marginTop: 0 }}><li className={classes.bulletList}>Limited to 3 free users</li><li className={classes.bulletList}>Limited access to Dashboard, Account Management, and College Profile pages</li></ul></Typography>}
                />
                <CardContent sx={{ alignSelf: "center" }}>
                  {tier == "free" ? (
                    <Box display='flex' justifyContent='center'>
                      <Button disabled style={{
                        background: "linear-gradient(75deg, #35c669, #2ec4a9)",
                        border: 0,
                        color: "rgba(250, 250, 250, 0.4)",
                        paddingRight: theme.spacing(2),
                        paddingLeft: theme.spacing(2),
                        marginBottom: theme.spacing(-2)
                      }}>Current Plan</Button>
                    </Box>
                  ) : (
                    <>
                      {subscriptionStatus == "canceled" ? (
                        <Box display='flex' justifyContent='center'>
                          <Button disabled style={{
                            background: "linear-gradient(75deg, #35c669, #2ec4a9)",
                            border: 0,
                            color: "rgba(250, 250, 250, 0.4)",
                            paddingRight: theme.spacing(2),
                            paddingLeft: theme.spacing(2),
                            marginBottom: theme.spacing(-2)
                          }} onClick={() => setOpenConfirm(true)}>Downgraded</Button>
                        </Box>
                      ) : (
                        <Box display='flex' justifyContent='center'>
                          <Button style={{
                            background: "linear-gradient(75deg, #35c669, #2ec4a9)",
                            border: 0,
                            color: "#fff",
                            paddingRight: theme.spacing(2),
                            paddingLeft: theme.spacing(2),
                            marginBottom: theme.spacing(-2)
                          }} onClick={() => setOpenConfirm(true)}>Downgrade Plan</Button>
                        </Box>
                      )}
                    </>
                  )}
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={4.5}>
              <Card style={{
                backgroundColor: theme.palette.primary.dark,
                padding: theme.spacing(2),
                borderRadius: 25,
                outlineColor: "#2c2c33",
                outlineStyle: "solid",
                outlineWidth: 1,
              }}>
                <CardHeader
                  title={
                    <>
                      {paymentFrequency == "monthly" ?
                        <><span style={{ fontSize: 30, fontWeight: 600 }}>Premium</span><span style={{ fontSize: 19, fontWeight: 600 }}>{" ($"}{(Math.round((subscriptionMonthlyCost) * 100) / 100).toLocaleString()}{"/mo.)"}</span></> :
                        <><span style={{ fontSize: 30, fontWeight: 600 }}>Premium</span><span style={{ fontSize: 19, fontWeight: 600 }}>{" ($"}{(Math.round((subscriptionYearlyCost / 12) * 100) / 100).toLocaleString()}{"/mo.)"}</span></>}
                    </>
                  }
                  subheader={<Typography style={{
                    fontSize: 13,
                    fontWeight: 400,
                    color: "#c6c9d3"
                  }}><ul style={{ padding: 0, marginTop: 0 }}><li className={classes.bulletList}>3 free users &#40;${monthlyUserFee}/mo. user fees apply for additional users&#41;</li><li className={classes.bulletList}>Full access to all app pages and functionality</li><li className={classes.bulletList}>Campaign, Advertisement, and Leads Export use &#40;service fees apply&#41;</li></ul></Typography>}
                />
                <CardContent sx={{ alignSelf: "center" }}>
                  {tier == "premium" ? (
                    <div style={{ display: 'flex', flexDirection: "column", alignItems: 'center' }}>
                      <Box
                        disabled
                        display="flex"
                        alignItems="center"
                        component={ButtonBase}
                        ref={ref}
                        style={{
                          borderRadius: 25,
                          backgroundColor: "#0d0d14",
                          // padding: 1,
                          minWidth: 70,
                          paddingRight: theme.spacing(3),
                          paddingLeft: theme.spacing(3),
                          marginLeft: 1,
                          paddingTop: 5,
                          paddingBottom: 5,
                          color: "rgba(250, 250, 250, 0.4)",
                          marginBottom: theme.spacing(2),
                          outlineWidth: 1,
                          outlineStyle: "solid",
                          outlineColor: "#3b3b42"
                        }}
                      >
                        <Hidden smDown>
                          <Typography
                            style={{
                              fontSize: 11,
                              fontWeight: 600
                            }}
                            color="inherit"
                          >
                            {paymentFrequency == "monthly" ? "MONTHLY" :
                              <>
                                <span
                                  style={{
                                    fontSize: 11,
                                    fontWeight: 600
                                  }}
                                >
                                  {"YEARLY "}
                                </span>
                                <span
                                  style={{
                                    fontSize: 9,
                                    fontWeight: 600,
                                    opacity: 0.5,
                                    backgroundImage: `linear-gradient(to right, #35c669, #2ec4a9)`,
                                    backgroundSize: "100%",
                                    backgroundRepeat: "repeat",
                                    backgroundClip: "text",
                                    WebkitBackgroundClip: "text",
                                    WebkitTextFillColor: "transparent"
                                  }}
                                >
                                  {"(SAVE " + Math.round(((subscriptionMonthlyCost - (subscriptionYearlyCost / 12)) / subscriptionMonthlyCost) * 100) + "%)"}
                                </span>
                              </>
                            }
                          </Typography>
                        </Hidden>
                      </Box>
                      <Button disabled style={{
                        background: "linear-gradient(75deg, #35c669, #2ec4a9)",
                        border: 0,
                        color: "rgba(250, 250, 250, 0.4)",
                        paddingRight: theme.spacing(2),
                        paddingLeft: theme.spacing(2),
                        marginBottom: theme.spacing(-2),
                      }}>{subscriptionStatus == "canceled" ? "Expiring " + parseDate(subscriptionExpirationDate) : "Current Plan"}</Button>
                    </div>
                  ) : (
                    <>
                      {paymentMethodLast4 == "" ? (
                        <div style={{ display: 'flex', flexDirection: "column", alignItems: 'center' }}>
                          <Box
                            display="flex"
                            alignItems="center"
                            component={ButtonBase}
                            onClick={e => {
                              e.stopPropagation();

                              handleOpen(e);
                            }}
                            ref={ref}
                            style={{
                              borderRadius: 25,
                              backgroundColor: "#0d0d14",
                              // padding: 1,
                              minWidth: 70,
                              paddingRight: theme.spacing(3),
                              paddingLeft: theme.spacing(3),
                              marginLeft: 1,
                              paddingTop: 5,
                              paddingBottom: 5,
                              marginBottom: theme.spacing(2),
                              outlineWidth: 1,
                              outlineStyle: "solid",
                              outlineColor: "#3b3b42"
                            }}
                          >
                            <Hidden smDown>
                              <Typography
                                style={{
                                  fontSize: 11,
                                  fontWeight: 600
                                }}
                                color="inherit"
                              >
                                {paymentFrequency == "monthly" ? "MONTHLY" :
                                  <>
                                    <span
                                      style={{
                                        fontSize: 11,
                                        fontWeight: 600
                                      }}
                                    >
                                      {"YEARLY "}
                                    </span>
                                    <span
                                      style={{
                                        fontSize: 9,
                                        fontWeight: 600,
                                        backgroundImage: `linear-gradient(to right, #35c669, #2ec4a9)`,
                                        backgroundSize: "100%",
                                        backgroundRepeat: "repeat",
                                        backgroundClip: "text",
                                        WebkitBackgroundClip: "text",
                                        WebkitTextFillColor: "transparent"
                                      }}
                                    >
                                      {"(SAVE " + Math.round(((subscriptionMonthlyCost - (subscriptionYearlyCost / 12)) / subscriptionMonthlyCost) * 100) + "%)"}
                                    </span>
                                  </>
                                }
                              </Typography>
                            </Hidden>
                          </Box>
                          <Menu
                            onClose={e => handleClose(e)}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}
                            keepMounted
                            slotProps={{
                              paper: {
                                sx: {
                                  minWidth: 130,
                                  marginLeft: theme.spacing(0),
                                  marginTop: theme.spacing(1),
                                  backgroundColor: theme.palette.primary.dark,
                                  borderRadius: 1.5,
                                  outlineColor: "#3c3c44",
                                  outlineStyle: "solid",
                                  outlineWidth: 1,
                                }
                              }
                            }}
                            anchorEl={ref.current}
                            open={isDropdownOpen}
                          >
                            {paymentFrequencyOptions.map((option) => (
                              <MenuItem
                                key={option.value}
                                onClick={e => {
                                  e.stopPropagation();

                                  setDropdownOpen(false);
                                  setPaymentFrequency(option.value);
                                }}
                              >
                                {option.label}
                              </MenuItem>
                            ))}
                          </Menu>
                          <UpgradePayment
                            refresh={refresh}
                            email={email}
                            subscriptionCostMonthly={subscriptionMonthlyCost}
                            subscriptionCostYearly={subscriptionYearlyCost}
                            paymentFrequency={paymentFrequency}
                            monthlyUserFee={monthlyUserFee}
                            setPaymentFrequency={setPaymentFrequency}
                          />
                        </div>
                      ) : (
                        <>
                          <div style={{ display: 'flex', flexDirection: "column", alignItems: 'center' }}>
                            <Box
                              display="flex"
                              alignItems="center"
                              component={ButtonBase}
                              onClick={e => {
                                e.stopPropagation();

                                handleOpen(e);
                              }}
                              ref={ref}
                              style={{
                                borderRadius: 25,
                                backgroundColor: "#0d0d14",
                                // padding: 1,
                                minWidth: 70,
                                paddingRight: theme.spacing(3),
                                paddingLeft: theme.spacing(3),
                                marginLeft: 1,
                                paddingTop: 5,
                                paddingBottom: 5,
                                marginBottom: theme.spacing(2),
                                outlineWidth: 1,
                                outlineStyle: "solid",
                                outlineColor: "#3b3b42"
                              }}
                            >
                              <Hidden smDown>
                                <Typography
                                  style={{
                                    fontSize: 11,
                                    fontWeight: 600
                                  }}
                                  color="inherit"
                                >
                                  {paymentFrequency == "monthly" ? "MONTHLY" :
                                    <>
                                      <span
                                        style={{
                                          fontSize: 11,
                                          fontWeight: 600
                                        }}
                                      >
                                        {"YEARLY "}
                                      </span>
                                      <span
                                        style={{
                                          fontSize: 9,
                                          fontWeight: 600,
                                          backgroundImage: `linear-gradient(to right, #35c669, #2ec4a9)`,
                                          backgroundSize: "100%",
                                          backgroundRepeat: "repeat",
                                          backgroundClip: "text",
                                          WebkitBackgroundClip: "text",
                                          WebkitTextFillColor: "transparent"
                                        }}
                                      >
                                        {"(SAVE " + Math.round(((subscriptionMonthlyCost - (subscriptionYearlyCost / 12)) / subscriptionMonthlyCost) * 100) + "%)"}
                                      </span>
                                    </>
                                  }
                                </Typography>
                              </Hidden>
                            </Box>
                            <Menu
                              onClose={e => handleClose(e)}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}
                              keepMounted
                              slotProps={{
                                paper: {
                                  sx: {
                                    minWidth: 130,
                                    marginLeft: theme.spacing(0),
                                    marginTop: theme.spacing(1),
                                    backgroundColor: theme.palette.primary.dark,
                                    borderRadius: 1.5,
                                    outlineColor: "#3c3c44",
                                    outlineStyle: "solid",
                                    outlineWidth: 1,
                                  }
                                }
                              }}
                              anchorEl={ref.current}
                              open={isDropdownOpen}
                            >
                              {paymentFrequencyOptions.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  onClick={e => {
                                    e.stopPropagation();

                                    setDropdownOpen(false);
                                    setPaymentFrequency(option.value);
                                  }}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Menu>
                            <Button style={{
                              background: "linear-gradient(75deg, #35c669, #2ec4a9)",
                              border: 0,
                              color: "#fff",
                              paddingRight: theme.spacing(2),
                              paddingLeft: theme.spacing(2),
                              marginBottom: theme.spacing(-2)
                            }} onClick={() => setOpenConfirm(true)}>Upgrade Plan</Button>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </CardContent>
              </Card>
            </Grid>
            <CustomModal
              open={openConfirm}
              setOpen={setOpenConfirm}
              sub={tier == "free" ? <Typography color='textSecondary'>{"Are you sure you want to upgrade to Premium?"}</Typography> : <div><Typography color='textSecondary'>{"Are you sure you want to downgrade to free tier?"}</Typography></div>}
              noScroll
            >
              <Box display="flex">
                <Grid container spacing={2}>
                  <Grid item xs={1} />
                  <Grid item xs={5}>
                    <MuiButton
                      style={{
                        height: 50,
                        backgroundColor: theme.palette.error.main,
                        color: theme.palette.text.primary,
                        borderRadius: 16
                      }}
                      fullWidth
                      variant="contained"
                      onClick={() => setOpenConfirm(false)}
                    >
                      Cancel
                    </MuiButton>
                  </Grid>
                  <Grid item xs={5}>
                    <Button
                      style={{ height: 50, borderRadius: 16 }}
                      variant="contained"
                      fullWidth
                      onClick={handleConfirm}
                    >
                      {tier == "free" ? paymentFrequency == "monthly" ? "Pay $" + (Math.round(subscriptionMonthlyCost * 100)/100).toLocaleString() : "Pay $" + (Math.round(subscriptionYearlyCost * 100)/100).toLocaleString() : "Downgrade"}
                    </Button>
                  </Grid>
                  <Grid item xs={1} />
                </Grid>
              </Box>
            </CustomModal>
          </Grid>
          <Box height="20px" />
          <Paper style={{
            backgroundColor: theme.palette.primary.dark,
            padding: theme.spacing(3),
            borderRadius: 25,
            outlineColor: "#2c2c33",
            outlineStyle: "solid",
            outlineWidth: 1,
          }}>
            <Grid container spacing={0}>
              <Grid item xs={1} sx={{ alignSelf: "left" }}>
                <CreditCard width="60px" height="60px" />
              </Grid>
              <Grid item xs={9} sx={{ alignSelf: "left" }}>
                <Box>
                  <Typography style={{ fontWeight: 600, fontSize: 25 }} color="textPrimary">
                    Payment Method
                  </Typography>
                  <Typography variant="body1" color="textSecondary">
                    {capitalizeFirstLetter(paymentMethodType) + " " + paymentMethodLast4}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box height={10} />
                {paymentMethodLast4 == "" ? (
                  <Box display='flex' justifyContent='right'>
                    <Button style={{
                      background: "linear-gradient(75deg, #35c669, #2ec4a9)",
                      border: 0,
                      color: "rgba(250, 250, 250, 0.4)",
                      paddingRight: theme.spacing(2),
                      paddingLeft: theme.spacing(2)
                    }} disabled>Change</Button>
                  </Box>
                ) : (
                  <>
                    <ChangePaymentMethod refresh={refresh} email={email} />
                  </>
                )}
              </Grid>
            </Grid>
          </Paper>
          <Box height="20px" />
          {/* <Paper className={classes.card}>
        <Typography color="textPrimary" variant="h6">
          Payment History
        </Typography>
        <Typography color="textSecondary" variant="body2">
          Current plan: Plan 1
        </Typography>
        <PerfectScrollbar>
          <Box>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Amount</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell align="right">Payment Method</TableCell>
                </TableRow>
              </TableHead>
              <TableBody></TableBody>
            </Table>
            <Box height="20px" />
            <Typography variant="body1" color="textSecondary">
              No payment history.
            </Typography>
          </Box>
        </PerfectScrollbar>
      </Paper> */}
          <Box height="20px" />
          <Typography color="textSecondary">
            *Premium plan includes pay-as-you-go usage and user fees, as outlined{" "}
            <a href="/terms">in the terms and service agreement</a>
          </Typography>
        </FadeIn>
      </Page>
      {/* {refreshing && (
      <Modal
        open={refreshing}
        closeAfterTransition
      >
        <Fade in={refreshing}>
          <Loading lower/>
        </Fade>
      </Modal>
      )} */}
    </>
  );
}
