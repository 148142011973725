import React, { Fragment, useState, useEffect } from "react";
import Parse from "parse";
import {
  useTheme,
  Card,
  Grid,
  Box,
  CardHeader,
  IconButton,
  Accordion,
  AccordionDetails,
  Typography,
  TextField,
  Modal,
  Backdrop,
  Fade,
  FormControlLabel,
  Checkbox,
  AccordionSummary,
  MenuItem,
} from "@mui/material";
import { makeStyles } from '@mui/styles';
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import { Filters, Input, Button } from "../../../components";
import { Loading } from "../../../components/Loading";
import gql from "graphql-tag";
import { selectHttpOptionsAndBody, useMutation } from "@apollo/client";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useAuth } from "../../../hooks/useAuth";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme: any) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  card: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
    boxShadow: theme.shadows[4],
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: theme.spacing(3),
  },
  paper: {
    maxHeight: 720,
    overflow: "auto",
    backgroundColor: "#101016",
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(4),
    borderRadius: 25,
    outlineColor: "#25252b",
    outlineStyle: "solid",
    outlineWidth: 1,
  },
  deactivate: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.text.primary,
  },
  accordion: {
    background: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    '&:before': {
      display: 'none',
    }
  },
  accordianDetails: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  scheduleDate: {
    marginTop: theme.spacing(-1),
    marginRight: theme.spacing(2),
    width: "220px",
  },
  selectStatus: {
    "& .MuiInputBase-root": {
      backgroundColor: theme.palette.primary.light,
      borderBottomRightRadius: theme.shape.borderRadius,
      borderBottomLeftRadius: theme.shape.borderRadius,
    },
  },
}));

const CREATE_CAMPAIGN = gql`
  mutation addCampaign(
    $collegeID: ID!
    $authorID: ID!
    $name: String!
    $message: String!
    $allowResponses: Boolean!
    $launchDate: Date
    $desc: String
    $status: String
    $ethnicities: [Any!]
    $locations: [Any!]
    $genders: [Any!]
    $gradyears: [Any!]
    $majors: [Any!]
    $interests: [Any!]
    $SATfrom: Float
    $SATto: Float
    $ACTfrom: Float
    $ACTto: Float
    $GPAfrom: Float
    $GPAto: Float
    $RANKfrom: Float
    $RANKto: Float
    $zip: String
    $zipRange: Float
    $audienceSize: Float
    $parentsEd: String
    $type: String
    $fromSource: String
    $interestedStudentsOnly: Boolean
  ) {
    createCampaign(
      input: {
        fields: {
          college: { link: $collegeID }
          author: { link: $authorID }
          campaignName: $name
          updatedBy: { link: $authorID }
          allowResponses: $allowResponses
          message: $message
          launchDate: $launchDate
          status: $status
          campaignDescription: $desc
          audienceSize: $audienceSize
          active: false
          hasCompleted: false
          filterQuery: {
            createAndLink: {
              ethnicities: $ethnicities
              locations: $locations
              genders: $genders
              gradyears: $gradyears
              majors: $majors
              interests: $interests
              SATfrom: $SATfrom
              SATto: $SATto
              ACTfrom: $ACTfrom
              ACTto: $ACTto
              GPAfrom: $GPAfrom
              GPAto: $GPAto
              RANKfrom: $RANKfrom
              RANKto: $RANKto
              zipCode: $zip
              zipRange: $zipRange
              parentsEd: $parentsEd
              type: $type
              fromSource: $fromSource
              college: { link: $collegeID }
              audienceSize: $audienceSize
              interestedStudentsOnly: $interestedStudentsOnly
            }
          }
        }
      }
    ) {
      campaign {
        id
        objectId
        createdAt
        author {
          firstName
          lastName
        }
        message
        active
        allowResponses
        launchDate
        campaignName
        status
        campaignDescription
        hasCompleted
        filterQuery {
          audienceSize
        }
      }
    }
  }
`;

export default function NewCampaign(props) {
  const theme = useTheme();
  const classes = useStyles(theme);

  var coeff = 1000 * 60 * 10;
  var today = new Date();

  const auth = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const [date, setDate] = useState<any>(new Date(Math.round(today.getTime() / coeff) * coeff));
  const [status, setStatus] = useState("Draft");
  const [allowResponses, setAllowResponses] = useState(true);
  const [filters, setFilters] = useState<any>(undefined);
  const [audienceSize, setAudienceSize] = useState(undefined);

  const [createCampaign, { error, loading, data }] =
    useMutation(CREATE_CAMPAIGN);

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setOpen(false);
    setName("");
    setDesc("");
    setMessage("");

    await createCampaign({
      variables: {
        collegeID: auth.collegeId,
        authorID: Parse.User.current()?.get("userContact").id,
        allowResponses: allowResponses,
        name: name,
        desc: desc,
        message: message,
        status: status,
        launchDate: status === "Scheduled" ? date.toISOString() : undefined,
        ...filters.saveData,
      },
    });
    await sleep(300);

    await props.refetch();
  };

  useEffect(() => {
    document.body.style.backgroundColor = "#0e0e14";

    if (error) {
      enqueueSnackbar("Error creating new campaign.", {
        variant: "error",
      });
    }
  }, [data, error]);

  if (loading) return <Loading small />;

  if (error) console.log(JSON.stringify(error, null, 2));

  return (
    <>
      <Button
        icon
        size="large"
        onClick={() => setOpen(true)}
      >
        <AddIcon />
      </Button>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Modal
          className={classes.modal}
          open={open}
          onClose={() => setOpen(false)}
          closeAfterTransition
        >
          <Fade in={open}>
            <Card
              style={{
                maxHeight: 720,
                overflow: "auto",
                backgroundColor: "#0e0e14",
                display: "flex",
                flexDirection: "column",
                padding: theme.spacing(4),
                borderRadius: 25,
                outlineColor: "#25252b",
                outlineStyle: "solid",
                outlineWidth: 1,
              }}
              className="cardScroll"
            >
              <Box width="600px">
                <form onSubmit={handleSubmit}>
                  <Grid container sx={{ alignSelf: "left" }}>
                    <Typography style={{ fontWeight: 600, fontSize: 30 }} color="textPrimary" align="left">
                      Create New Campaign
                    </Typography>
                    <Box flexGrow={1} />
                    <IconButton style={{ width: 40, height: 40 }} onClick={() => setOpen(false)}>
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                  <Grid container sx={{ alignSelf: "left" }}>
                    <Typography variant="body2" align="left" color="textSecondary">
                      Estimated Cost: {props.costPerCampaignMessage == 0 ? "$0" : audienceSize != null ? "$" + (Math.round(((audienceSize * props.costPerCampaignMessage) * 100) / 100) + 50).toLocaleString() : "$" + (Math.round(((props.totalStudents * props.costPerCampaignMessage) * 100) / 100) + 50).toLocaleString()}
                    </Typography>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Box height="10px" />
                      <Typography variant="h6" color="textSecondary">
                        Details
                      </Typography>
                      <Box height="10px" />
                      <Input
                        label="Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        fullWidth
                        required
                      />
                      <Box height="10px" />
                      <Input
                        label="Description (optional)"
                        value={desc}
                        onChange={(e) => setDesc(e.target.value)}
                        fullWidth
                      />
                      <Box height="10px" />
                      <Input
                        label="Message"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        fullWidth
                        multiline
                        required
                        rows={5}
                      />
                      <Box height="10px" />
                      <Input
                        className={classes.selectStatus}
                        label="Status"
                        select
                        fullWidth
                        value={status}
                        onChange={(e) => {
                          setStatus(e.target.value);
                          if (e.target.value === "Draft") {
                            setDate(undefined);
                          }
                        }}
                      >
                        <MenuItem value="Draft">Draft</MenuItem>
                        <MenuItem value="Scheduled">Scheduled</MenuItem>
                      </Input>
                    </Grid>
                  </Grid>
                  <Box height="10px" />
                  <Accordion sx={{
                    background: theme.palette.secondary.dark,
                    borderRadius: 1,
                    '&:before': {
                      display: 'none',
                    }
                  }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <CardHeader
                        title={
                          filters?.loading ? (
                            <Box width={150}>
                              <Loading small />
                            </Box>
                          ) : (
                            `Filters (${filters?.numStudents?.toLocaleString() ?? "All"
                            } students)`
                          )
                        }
                        titleTypographyProps={{ fontWeight: 600, fontSize: 20 }}
                      />
                    </AccordionSummary>
                    <AccordionDetails
                      style={{
                        paddingLeft: theme.spacing(3),
                        paddingRight: theme.spacing(3)
                      }}
                    >
                      <Filters
                        setFilters={setFilters}
                        disableFromSourceOption={false}
                        disabled={false}
                        setAudienceSize={setAudienceSize}
                        applyOnLoad={true}
                      />
                    </AccordionDetails>
                  </Accordion>
                  <Box height="10px" />
                  <Grid container sx={{ alignSelf: "left" }}>
                    <Typography variant="body2" align="left" color="textSecondary">
                      *Cost=${props.costPerCampaignMessage.toFixed(2)}/message {props.costPerCampaignMessage > 0 ? "+ $50 service fee" : "+ $0 service fee"}
                    </Typography>
                  </Grid>
                  <Box height="10px" />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="allowResponses"
                        sx={{
                          color: theme.palette.text.secondary,
                          '&.Mui-checked': {
                            color: theme.palette.secondary.main,
                          },
                        }}
                        checked={allowResponses}
                        onChange={(e) => setAllowResponses(e.target.checked)}
                      />
                    }
                    label="Allow Student Responses"
                  />
                  <Box height="20px" />
                  <Box display="flex" justifyContent="right">
                    {status === "Scheduled" && (
                      <>
                        <DateTimePicker
                          label="Scheduled Date and Time"
                          value={dayjs(date)}
                          minutesStep={10}
                          onChange={(d) => {
                            if (!d) return;
                            console.log(d);
                            setDate(d);
                          }}
                          disablePast={true}
                          sx={{ marginTop: theme.spacing(-1), width: 250 }}
                          slotProps={{
                            textField: {
                              placeholder: "fsdf",
                              color: "primary",
                              required: true,
                              onKeyDown: (e) => e.preventDefault()
                            },
                          }}
                        />
                      </>
                    )}
                    <Box width="10px" />
                    <Button type="submit" style={{ height: 40, color: "#fff" }}>
                      Save
                    </Button>
                  </Box>
                </form>
              </Box>
            </Card>
          </Fade>
        </Modal>{" "}
      </LocalizationProvider>
    </>
  );
}
