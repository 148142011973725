import React, { useState, useRef } from "react";
import {
  Badge,
  Box,
  IconButton,
  useTheme,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  SvgIcon,
  Popover,
  Avatar,
  Divider,
} from "@mui/material";
import NotificationsIcon from "@material-ui/icons/NotificationsOutlined";
import { makeStyles } from '@mui/styles';
import { paper } from "src/routes/FrontEnd/Theme/overrides/components/paper";

const useStyles = makeStyles((theme: any) => ({
  popover: {
    backgroundColor: theme.palette.secondary.dark,
    width: 320,
    outlineColor: "#2c2c33",
    outlineStyle: "solid",
    outlineWidth: 1,
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    background: "linear-gradient(75deg, #35c669, #2ec4a9)"
  },
}));

export default function Notifications(props) {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [open, setOpen] = useState(false);
  const ref = useRef(null);

  return (
    <>
      <IconButton color="inherit" ref={ref} onClick={() => setOpen(true)}>
        <Badge badgeContent={props.data?.length} color="error">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          horizontal: "left",
          vertical: "top",
        }}
        slotProps={{
          paper: {
            sx: {
              width: 320,
              backgroundColor: theme.palette.primary.dark,
              borderRadius: 1.5,
              outlineColor: "#3c3c44",
              outlineStyle: "solid",
              outlineWidth: 1,
            }
          }
        }}
        anchorEl={ref.current}
        onClose={() => setOpen(false)}
        open={open}
      >
        <Box p={2}>
          <Typography style={{ fontWeight: 600, fontSize: 20 }} color="textPrimary">
            Notifications
          </Typography>
        </Box>
        <Divider />
        {props.data?.length === 0 ? (
          <Box p={2}>
            <Typography variant="body1" color="textSecondary">
              No new notifications
            </Typography>
          </Box>
        ) : (
          <>
            <List disablePadding>
              {props.data?.map((notification) => {
                return (
                  <ListItem
                    key={notification.id}
                    divider
                    component="a"
                    href={notification.link}
                  >
                    <ListItemAvatar>
                      <Avatar className={classes.icon}>
                        <SvgIcon fontSize="small">
                          <NotificationsIcon />
                        </SvgIcon>
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={notification.primary}
                      primaryTypographyProps={{
                        variant: "subtitle2",
                        color: "textPrimary",
                        fontWeight: 400
                      }}
                      secondary={notification.secondary}
                    />
                  </ListItem>
                );
              })}
            </List>
          </>
        )}
      </Popover>
    </>
  );
}
