import { lazy, Suspense } from 'react';
import { Outlet, Navigate, useRoutes } from 'react-router-dom';

import MainLayout from '../../Layouts/main';
import { SplashScreen } from '../../Components/loading-screen';

import { authRoutes } from './auth';
import { errorRoutes } from './error';
import { careerRoutes } from './careers';
import { contactRoutes } from './contact';
import { ecosystemRoutes } from './ecosystem';
import { studentAppRoutes } from './student-app';
import { universityWebappRoutes } from './university-webapp';
import { aboutRoutes } from './about';
import { privacypRoutes } from './privacy';
import { termsRoutes } from './terms';
import { blogRoutes } from './blog';
import { trackingRoutes } from './tracking';

// ----------------------------------------------------------------------

const IndexPage = lazy(() => import('../../Pages/home'));
const SupportPage = lazy(() => import('../../Pages/support'));

export default function Router() {
  return useRoutes([
    {
      element: (
        <MainLayout>
          <Suspense fallback={<SplashScreen />}>
            <Outlet />
          </Suspense>
        </MainLayout>
      ),
      children: [
        { element: <IndexPage />, index: true },
        { path: 'support', element: <SupportPage /> },

        ...careerRoutes,

        ...aboutRoutes,

        ...contactRoutes,

        ...ecosystemRoutes,

        ...studentAppRoutes,

        ...universityWebappRoutes,

        ...privacypRoutes,

        ...termsRoutes,

        ...blogRoutes,
      ],
    },

    ...authRoutes,

    ...errorRoutes,

    ...trackingRoutes

    // { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
