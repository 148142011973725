import React, { useState } from "react";
import Parse from "parse";
import {
  Card,
  CardHeader,
  CardContent,
  Menu,
  IconButton,
  MenuItem,
  Box,
  Typography
} from "@mui/material";
import FadeIn from "react-fade-in";
import { useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useRef, useEffect } from "react";
import { Chart, registerables } from "chart.js";
import MenuIcon from "@material-ui/icons/Menu";
import { Loading } from "../../../../components";
import { useAuth } from "../../../../hooks/useAuth";

const useStyles = makeStyles((theme: any) => ({
  card: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    boxShadow: theme.shadows[4],
    borderRadius: 15,
    outlineColor: "#2c2c33",
    outlineStyle: "solid",
    outlineWidth: 1,
  },
  grid: {
    flexGrow: 1,
  },
  popover: {
    backgroundColor: theme.palette.secondary.dark,
    borderRadius: 11,
    outlineColor: "#2c2c33",
    outlineStyle: "solid",
    outlineWidth: 1,
  },
  titleFont: {
    fontWeight: 500
  },
}));

export default function MajorsHistogram() {
  const theme = useTheme();
  const classes = useStyles(theme);

  const auth = useAuth();
  const chartRef = useRef<any>();
  const popoverRef = useRef<any>();
  const [anchor, setAnchor] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [sub, setSub] = useState("All Majors");
  const [currChart, setCurrChart] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [histData, setHistData] = useState([]);

  useEffect(() => {
    async function fetchMajors() {
      await Parse.Cloud.run("dashboardLeadsMajors").then((response) => {
        setHistData(response);
        setLoading(false);
      });
    }
    if (auth.collegeId) {
      fetchMajors();
    }
  }, [auth.collegeId]);

  useEffect(() => {
    if (histData && chartRef.current && histData.length > 1) {
      const myChartRef = chartRef.current.getContext("2d");

      var gradientBackground = myChartRef.createLinearGradient(0, 0, 0, 400);
      gradientBackground.addColorStop(0, "#35c669");
      gradientBackground.addColorStop(1, "#2ec4a9");

      var allLabels = histData.map((item: any) => {
        // const MAX_LENGTH = 7;
        // if (item.category.length > MAX_LENGTH) {
        //   return item.category.substring(0, MAX_LENGTH) + "...";
        // } else {
        //   return item.category;
        // }
        return item.category;
      });
      var allData = histData.map((item: any) => {
        let sum = 0;
        item.majors.forEach((major) => {
          sum = sum + major.interested;
        });
        return sum;
      });

      const data = {
        "All Majors": allData,
      };
      const labels = {
        "All Majors": allLabels,
      };
      histData.forEach((item: any) => {
        data[item.category] = item.majors.map((x) => x.interested);
        labels[item.category] = item.majors.map((x) => x.name);
      });

      if (currChart !== null) {
        currChart.destroy();
      }

      const charty = new Chart(myChartRef, {
        type: "bar",
        data: {
          datasets: [
            {
              backgroundColor: gradientBackground,
              data: data[sub],
              borderRadius: 10,
            },
          ],
          labels: labels[sub],
        },
        options: {
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            y: {
              ticks: {
                // precision: 0,
                // min: 0,
                color: "#53535b",
              },
            },
            x: {
              ticks: {
                maxRotation: 40,
                minRotation: 40,
                color: "#53535b",
                callback: function (value) {
                  if (typeof labels[sub][value] === "string" && labels[sub][value].length > 10) {
                    return labels[sub][value].substring(0, 10) + "...";
                  }
                  return labels[sub][value];
                },
              },
            },
          },
        },
      });

      setCurrChart(charty);
      charty.update();
    }
  }, [chartRef, histData, sub]);

  useEffect(() => {
    Chart.register(...registerables);
  }, []);

  return (
    <FadeIn delay={250}>
      <Card style={{
        backgroundColor: theme.palette.primary.dark,
        padding: theme.spacing(2),
        boxShadow: theme.shadows[4],
        borderRadius: 25,
        outlineColor: "#2c2c33",
        outlineStyle: "solid",
        outlineWidth: 1,
      }}>
        <CardHeader
          title="Student Leads Majors"
          titleTypographyProps={{ fontWeight: 600 }}
          subheader={sub}
          action={
            <>
              <IconButton
                ref={popoverRef}
                onClick={(e: any) => {
                  setOpenMenu(true);
                  setAnchor(e.currentTarget);
                }}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="post-menu"
                anchorEl={anchor}
                open={openMenu}
                slotProps={{
                  paper: {
                    sx: {
                      backgroundColor: theme.palette.primary.dark,
                      borderRadius: 1.5,
                      outlineColor: "#3c3c44",
                      outlineStyle: "solid",
                      outlineWidth: 1,
                    }
                  }
                }}
                onClose={() => setOpenMenu(false)}
              >
                <MenuItem
                  onClick={() => {
                    setOpenMenu(false);
                    setSub("All Majors");
                  }}
                >
                  All Majors
                </MenuItem>
                {histData
                  ?.map((item: any) => item.category)
                  ?.map((option) => (
                    <MenuItem
                      key={option}
                      onClick={() => {
                        setOpenMenu(false);
                        setSub(option);
                      }}
                    >
                      {option}
                    </MenuItem>
                  ))}
              </Menu>
            </>
          }
        />
        <CardContent style={{ height: "280px" }}>
          {loading ? (
            <Loading />
          ) : (
            <>
              {histData?.length == 0 ? (
                <>
                  <Box height="60px" />
                  <Box display='flex' justifyContent='center'>
                    <Typography>No data.</Typography>
                  </Box>
                </>
              ) : (<></>)}
            </>
          )}
          <canvas
            id="interested-students"
            height="100%"
            ref={chartRef}
          ></canvas>
        </CardContent>
      </Card>
    </FadeIn>
  );
}
