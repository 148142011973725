import React from "react";
import { Container, Grid, CircularProgress, useTheme } from "@mui/material";
import { makeStyles } from '@mui/styles';

export const Loading = (props) => {
  const useStyles = makeStyles((theme: any) => ({
    custom: {
      marginTop: theme.spacing(20),
    },
  }));

  const theme = useTheme();
  const classes = useStyles(theme);

  if (props.small) {
    return <CircularProgress color="secondary"/>;
  }

  if (props.smaller) {
    return <CircularProgress thickness={5} color="secondary" style={{ height: 20, width: 20 }}/>;
  }

  if (props.lower) {
    return (
      <Container className={classes.custom}>
        <Grid container sx={{justifyContent: "center"}}>
          <CircularProgress color="secondary" />
        </Grid>
      </Container>
    )
  }

  return (
    <Container {...props}>
      <Grid container sx={{justifyContent: "center"}}>
        <CircularProgress color="secondary" />
      </Grid>
    </Container>
  );
};

export default Loading;