import React, { useEffect, useState, useRef } from "react";
import { Page } from "../../../components";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Card,
  Grid,
  Avatar,
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  Container,
  TableRow,
  Modal,
  Fade,
  ButtonBase,
  Hidden,
  Menu,
  IconButton,
  MenuItem,
  Tooltip,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  IconButton as ICButton,
  TextField
} from "@mui/material";
import NumberFormat from "react-number-format";
import LoadingButton from "@mui/lab/LoadingButton";
import FadeIn from "react-fade-in";
import { calcAcademicScore } from "../../../util/util";
import Carousel, { useCarousel, CarouselDots, CarouselArrows } from '../../FrontEnd/Components/carousel';
import { CircleProgress } from 'react-gradient-progress'
import { useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  Loading,
  Input as StyledInput
} from "../../../components";
import { Link } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import { useSnackbar } from "notistack";
import Parse from "parse";
import Iconify from '../../../../src/routes/FrontEnd/Components/iconify';
import CloseIcon from "@material-ui/icons/Close";
import InfoIcon from "@material-ui/icons/Info";
import SettingsIcon from "@material-ui/icons/SettingsRounded";

const useStyles = makeStyles((theme: any) => ({
  root: {},
  card: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4),
    borderRadius: 20,
    outlineColor: "#25252b",
    outlineStyle: "solid",
    outlineWidth: 1,
  },
  personal_card: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4),
    borderRadius: 20,
    outlineColor: "#25252b",
    outlineStyle: "solid",
    outlineWidth: 1,
  },
  button: {
    background: "linear-gradient(75deg, #35c669, #2ec4a9)",
    color: "#fff",
    borderRadius: 17,
    width: 160,
    height: "60px",
  },
  avatar: {
    width: 150,
    height: 150,
    backgroundColor: "#1d1d2b",
  },
  avatarCollege: {
    width: 40,
    height: 40,
    backgroundColor: "#1d1d2b",
  },
  avatarMajor: {
    width: 12,
    height: 12,
    borderRadius: 4,
    marginTop: theme.spacing(2)
  },
  usernameCard: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'transparent'
  },
  resultsMajor: {
    height: 300,
  },
  resultsCollege: {
    height: 400,
  },
  resultsActivity: {
    height: 400,
  },
  tableCell: {
    marginLeft: theme.spacing(0),
    paddingLeft: theme.spacing(0),
  },
  table: {
    marginRight: theme.spacing(0),
    paddingRight: theme.spacing(0)
  },
  title: {
    marginLeft: theme.spacing(2.8),
    marginDown: theme.spacing(2),
  },
  name: {
    marginTop: -20,
    fontSize: 40,
    fontWeight: 700,
  },
  submit: {
    margin: theme.spacing(3),
    marginBottom: theme.spacing(20),
  },
  stat: {
    backgroundColor: theme.palette.primary.light,
    height: 250,
    borderRadius: 30,
    padding: theme.spacing(1),
    outlineColor: "#37373d",
    outlineStyle: "solid",
    outlineWidth: 1,
  },
  headerInfoCard: {
    backgroundColor: theme.palette.secondary.dark,
    height: 50,
    borderRadius: 30,
    padding: theme.spacing(1),
    outlineColor: "#64646e",
    outlineStyle: "solid",
    outlineWidth: 0.5,
  },
  headerInfoTitle: {
    fontSize: 15,
    fontWeight: 500,
    height: 34,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  headerInfoCardSmall: {
    backgroundColor: "#32323c",
    borderRadius: 30,
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  headerInfoTitleSmall: {
    fontSize: 14,
    fontWeight: 500,
    height: 34,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  headerInfoTitleTiny: {
    fontSize: 10,
    fontWeight: 500,
    height: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  contact: {
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(1),
    borderRadius: 20,
    outlineColor: "#37373d",
    outlineStyle: "solid",
    outlineWidth: 1,
  },
  caps: {
    fontSize: 12,
    fontWeight: 600,
    paddingTop: "6px"
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: theme.spacing(3),
  },
}));

const designationOptions = [
  {
    label: "Set as 'No Selection'",
    value: "No Selection",
  },
  {
    label: "Set as 'Interested In Connecting'",
    value: "Interested In Connecting",
  },
];

const Info = (props) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Modal
      className={classes.modal}
      open={props.open}
      onClose={() => props.setOpen(false)}
      closeAfterTransition
    >
      <Fade in={props.open}>
        <Card style={{
          maxHeight: 690,
          overflowY: "auto",
          overflowX: "hidden",
          backgroundColor: theme.palette.primary.dark,
          display: "flex",
          flexDirection: "column",
          padding: theme.spacing(4),
          paddingBottom: theme.spacing(2),
          borderRadius: 25,
          outlineColor: "#2c2c33",
          outlineStyle: "solid",
          outlineWidth: 1,
        }}>
          <Box width={500}>
            <Grid container sx={{ alignSelf: "left" }}>
              <Typography style={{ fontWeight: 600, fontSize: 29 }} align="left" color="textPrimary">
                {props.title}
              </Typography>
              <Box flexGrow={1} />
              <IconButton style={{ width: 40, height: 40 }} onClick={() => props.setOpen(false)}>
                <CloseIcon />
              </IconButton>
            </Grid>
            {props.content}
          </Box>
        </Card>
      </Fade>
    </Modal >
  );
};

const IntentNumberFormatCustomInput = (props) => {
  const theme = useTheme();

  return (
    <TextField
      sx={{
        "& .MuiInputBase-root": {
          backgroundColor: "#0e0e11",
          height: 28,
          width: 43,
          borderBottomRightRadius: props.borderRadius ? props.borderRadius : theme.shape.borderRadius,
          borderBottomLeftRadius: props.borderRadius ? props.borderRadius : theme.shape.borderRadius,
          borderTopRightRadius: props.borderRadius ? props.borderRadius : theme.shape.borderRadius,
          borderTopLeftRadius: props.borderRadius ? props.borderRadius : theme.shape.borderRadius,
          outlineColor: "#35353d",
          outlineStyle: "solid",
          outlineWidth: 1,
        },
        "& .MuiInputBase-root.Mui-disabled": {
          backgroundColor: theme.palette.primary.light
        },
        "&.MuiInputBase-root.Mui-hover": {
          backgroundColor: "#FFF"
        }
      }}
      inputProps={{
        style: {
          color: theme.palette.secondary.main,
          padding: 7,
          fontSize: 12,
          fontWeight: 600,
          textAlign: 'center'
        }
      }}
      InputProps={{ disableUnderline: true }}
      variant="outlined"
      {...props}
    />
  )
}

const IntentSettings = (props) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [selectedActionsAndAttributes, setSelectedActionsAndAttributes] = useState<any>([]);
  const [isSaving, setIsSaving] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const INTENT_DEFAULTS = [
    {
      name: "Follows school",
      weight: 17
    },
    {
      name: "Interest level",
      weight: 12
    },
    {
      name: "Connection status",
      weight: 21
    },
    {
      name: "Profile views",
      weight: 10
    },
    {
      name: "Parent attended",
      weight: 5
    },
    {
      name: "Sibling attended",
      weight: 4
    },
    {
      name: "Friend attends",
      weight: 6
    },
    {
      name: "Application status",
      weight: 9
    },
    {
      name: "In-state student",
      weight: 10
    },
    {
      name: "Same city",
      weight: 6
    },
  ]

  const scaleToOneHundred = () => {
    const arr = selectedActionsAndAttributes.filter((x) => x.weight > 0);
    const sum = arr.map((x) => x.weight).reduce((partialSum, a) => partialSum + a, 0);

    var newVals: any = [];

    for (var i = 0; i < arr.length; i++) {
      newVals.push({ name: arr[i].name, weight: Math.round((arr[i].weight / sum) * 100) });
    }

    const zerosArr = selectedActionsAndAttributes.filter((x) => x.weight == 0);

    for (var i = 0; i < zerosArr.length; i++) {
      newVals.push({ name: zerosArr[i].name, weight: zerosArr[i].weight });
    }

    setSelectedActionsAndAttributes(newVals.sort(((a, b) => a.name.localeCompare(b.name))));
  }

  const recalculateWeights = (newObj) => {
    if (newObj.weight == '') {
      newObj.weight = '0'
    }

    var arr = selectedActionsAndAttributes.filter((x) => x.name != newObj.name);

    const newWeight = parseFloat(newObj.weight.split('%')[0]);

    var newVals: any = [];

    for (var i = 0; i < arr.length; i++) {
      newVals.push({ name: arr[i].name, weight: arr[i].weight });
    }

    newVals.push({ name: newObj.name, weight: newWeight });

    setSelectedActionsAndAttributes(newVals.sort(((a, b) => a.name.localeCompare(b.name))));
  }

  async function getIntentScoreFactors() {
    const res = await Parse.Cloud.run("getIntentScoreFactors", { collegeId: props.collegeId })

    if (res) {
      setSelectedActionsAndAttributes(res.sort(((a, b) => a.name.localeCompare(b.name))));

    } else {
      setSelectedActionsAndAttributes(INTENT_DEFAULTS.sort(((a, b) => a.name.localeCompare(b.name))));
    }
  }

  const handleSave = async () => {
    if (selectedActionsAndAttributes.map((x) => x.weight).reduce((partialSum, a) => partialSum + a, 0) != 100) {
      enqueueSnackbar("Oops! Please make sure your intent factor weights add up to 100%.", { variant: "error" })

    } else {
      setIsSaving(true);

      await Parse.Cloud.run("saveIntentScoreFactors", { collegeId: props.collegeId, includes: selectedActionsAndAttributes })

      setIsSaving(false);

      props.setOpen(false);
      props.reload();
    }
  }

  useEffect(() => {
    if (props.open == true) {
      getIntentScoreFactors();
    }
  }, [props.open]);

  return (
    <Modal
      className={classes.modal}
      open={props.open}
      onClose={() => props.setOpen(false)}
      closeAfterTransition
    >
      <Fade in={props.open}>
        <Card
          className="cardScroll"
          style={{
            maxHeight: 700,
            overflowY: "auto",
            overflowX: "hidden",
            backgroundColor: theme.palette.primary.dark,
            display: "flex",
            flexDirection: "column",
            padding: theme.spacing(4),
            paddingBottom: theme.spacing(2),
            borderRadius: 25,
            outlineColor: "#2c2c33",
            outlineStyle: "solid",
            outlineWidth: 1,
          }}>
          <Box width={470}>
            <Grid container sx={{ alignSelf: "left" }}>
              <div>
                <Typography style={{ fontWeight: 600, fontSize: 29 }} align="left" color="textPrimary">
                  {props.title}
                </Typography>
                <Typography style={{ fontWeight: 400, fontSize: 15 }} align="left" color="textSecondary">
                  {"Total: " + Math.round(selectedActionsAndAttributes.map((x) => x.weight).reduce((partialSum, a) => partialSum + a, 0)) + "%"}
                </Typography>
              </div>
              <Box flexGrow={1} />
              <IconButton style={{ width: 40, height: 40 }} onClick={() => props.setOpen(false)}>
                <CloseIcon />
              </IconButton>
            </Grid>
            <div>
              <Box height="10px" />
              {selectedActionsAndAttributes.map((p) => (
                <FormControlLabel
                  style={{
                    marginRight: theme.spacing(5),
                    marginTop: theme.spacing(1.5),
                    marginLeft: theme.spacing(0.5)
                  }}
                  control={
                    <NumberFormat
                      variant="filled"
                      onChange={(e) => recalculateWeights({ name: p.name, weight: e.target.value })}
                      customInput={IntentNumberFormatCustomInput}
                      style={{
                        marginRight: theme.spacing(1),
                        width: 47,
                        height: 28,
                      }}
                      suffix="%"
                      defaultValue={
                        p.weight
                      }
                      value={p.weight}
                    />
                  }
                  label={p.name}
                />
              ))}
              <Box height="30px" />
              <Box display="flex" justifyContent="right">
                <Button
                  style={{
                    backgroundColor: "#0e0e11",
                    border: 0,
                    color: "#fff",
                    marginRight: theme.spacing(1.3),
                    paddingRight: theme.spacing(2),
                    paddingLeft: theme.spacing(2),
                    fontSize: 10,
                    height: 33,
                    outlineColor: "#35353d",
                    outlineStyle: "solid",
                    outlineWidth: 1,
                  }}
                  onClick={scaleToOneHundred}
                >
                  RE-SCALE TO 100
                </Button>
                <LoadingButton
                  variant="outlined"
                  loading={isSaving}
                  style={{
                    background: "linear-gradient(75deg, #35c669, #2ec4a9)",
                    border: 0,
                    color: "#fff",
                    fontSize: 10,
                    height: 33,
                  }}
                  loadingIndicator={
                    <CircularProgress thickness={4} size={15} sx={{ color: "#fff" }} />
                  }
                  onClick={handleSave}
                >
                  {!isSaving ? "SAVE" : ""}
                </LoadingButton>
              </Box>
              <Box height="10px" />
            </div>
          </Box>
        </Card>
      </Fade>
    </Modal >
  );
};

const Interests = (props) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Modal
      className={classes.modal}
      open={props.open}
      onClose={() => props.setOpen(false)}
      closeAfterTransition
    >
      <Fade in={props.open}>
        <Card style={{
          maxHeight: 690,
          overflowY: "auto",
          overflowX: "hidden",
          backgroundColor: theme.palette.primary.dark,
          display: "flex",
          flexDirection: "column",
          padding: theme.spacing(4),
          paddingBottom: theme.spacing(2),
          borderRadius: 25,
          outlineColor: "#2c2c33",
          outlineStyle: "solid",
          outlineWidth: 1,
        }}>
          <Box width={500}>
            <Grid container sx={{ alignSelf: "left" }}>
              <Typography style={{ fontWeight: 600, fontSize: 29, marginTop: theme.spacing(-1) }} align="left" color="textPrimary">
                {props.title}
              </Typography>
              <Box flexGrow={1} />
              <IconButton style={{ width: 40, height: 40, marginTop: theme.spacing(-1) }} onClick={() => props.setOpen(false)}>
                <CloseIcon />
              </IconButton>
            </Grid>
            {props.interests?.edges?.length > 0 ? (
              <Grid container spacing={1} marginTop={theme.spacing(1.7)} marginBottom={theme.spacing(2)}>
                {props.interests?.edges?.map((interest: any) => (
                  <Grid item xs={interest?.node?.name / 3}
                    display='flex'
                    alignItems='center'
                    justifyContent="left"
                  >
                    <Typography
                      fontSize={13.5}
                      fontWeight={600}
                      paddingLeft={theme.spacing(3)}
                      paddingRight={theme.spacing(3)}
                      color="#fff"
                      bgcolor="#35353d"
                      display='flex'
                      alignItems='center'
                      justifyContent='center'
                      borderRadius={12}
                      height={32}
                    >
                      {interest?.node?.name}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <div>
                <Typography
                  fontSize={15}
                  fontWeight={400}
                  marginTop={theme.spacing(3)}
                  marginBottom={theme.spacing(1)}
                  color="#fff"
                  display='flex'
                  alignItems='center'
                  justifyContent='center'
                  height={32}
                >
                  No interests.
                </Typography>
              </div>
            )}
          </Box>
        </Card>
      </Fade>
    </Modal >
  );
};

const MajorRow = ({ name, category, color, id }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  useEffect(() => {
    document.body.style.backgroundColor = "#0e0e14";
  }, []);

  return (
    <>
      <TableRow
        key={id}
      >
        <TableCell className={classes.tableCell}>
          <Box display="flex" sx={{ alignSelf: "left" }}>
            <Box
              style={{
                backgroundColor: color,
                width: 12,
                height: 12,
                borderRadius: 4,
                marginTop: theme.spacing(2)
              }}
            />
            <Box width="15px" />
            <div>
              <Typography fontSize={16} fontWeight={600} color="textPrimary">
                {name}
              </Typography>
              <Typography fontSize={14} color="textSecondary">
                {category}
              </Typography>
            </div>
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
};

const CollegeRow = ({ name, imgUrl, interestLevel, id, appliedColleges, acceptedColleges, enrolledColleges }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  useEffect(() => {
    document.body.style.backgroundColor = "#0e0e14";
  }, []);

  return (
    <>
      <TableRow
        key={id}
      >
        <TableCell className={classes.tableCell}>
          <Box display="flex" sx={{ alignSelf: "left" }} alignItems="left">
            <Avatar
              className={classes.avatarCollege}
              src={imgUrl?._url}
            />
            <Box width="15px" />
            <div>
              <Typography fontSize={16} fontWeight={600} color="textPrimary">
                {name}
              </Typography>
              <div style={{ display: 'flex' }}>
                <Typography fontSize={14} color="textSecondary">
                  {interestLevel}
                </Typography>
                <Tooltip
                  title={appliedColleges.includes(id) ? "Applied" : acceptedColleges.includes(id) ? "Accepted" : enrolledColleges.includes(id) ? "Enrolled" : "Not Applied"}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: "#46464f",
                        color: "white"
                      }
                    }
                  }}
                >
                  <img
                    height={13}
                    width={13}
                    src={appliedColleges.includes(id) ? "/assets/icons/ic_applied.svg" : acceptedColleges.includes(id) ? "/assets/icons/ic_accepted.svg" : enrolledColleges.includes(id) ? "/assets/icons/ic_enrolled.svg" : "/assets/icons/ic_applied.svg"}
                    style={{
                      visibility: appliedColleges.includes(id) || acceptedColleges.includes(id) || enrolledColleges.includes(id) ? "visible" : "hidden",
                      marginLeft: theme.spacing(0.8),
                      marginTop: theme.spacing(0.5)
                    }}
                  />
                </Tooltip>
              </div>
            </div>
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
};

const ActivityRow = ({ name, organization, desc, id, fromDate, toDate }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  useEffect(() => {
    document.body.style.backgroundColor = "#0e0e14";
  }, []);

  return (
    <>
      <TableRow
        key={id}
      >
        <TableCell className={classes.tableCell}>
          <Box display="flex" sx={{ alignSelf: "left" }} alignItems="left">
            <div>
              <Typography fontSize={16} fontWeight={600} color="textPrimary">
                {name}
              </Typography>
              <Typography fontSize={14} color="textSecondary">
                {organization}
              </Typography>
              <Typography fontSize={14} color="textSecondary">
                {fromDate} - {toDate}
              </Typography>
              <Typography fontSize={14} color="textPrimary">
                {desc}
              </Typography>
            </div>
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
};

const AwardRow = ({ name, organization, desc, id, dateAwarded }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  useEffect(() => {
    document.body.style.backgroundColor = "#0e0e14";
  }, []);

  return (
    <>
      <TableRow
        key={id}
      >
        <TableCell className={classes.tableCell}>
          <Box display="flex" sx={{ alignSelf: "left" }} alignItems="left">
            <div>
              <Typography fontSize={16} fontWeight={600} color="textPrimary">
                {name}
              </Typography>
              <Typography fontSize={14} color="textSecondary">
                {organization}
              </Typography>
              <Typography fontSize={14} color="textSecondary">
                {dateAwarded}
              </Typography>
              <Typography fontSize={14} color="textPrimary">
                {desc}
              </Typography>
            </div>
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
};

const SchoolActivityRow = ({ type, actionDate, userUniversity, content }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const parseDate = (date) => {
    let d = new Date(date);
    const mediumTime = new Intl.DateTimeFormat("en", {
      timeStyle: "short",
      dateStyle: "medium",
    });
    return mediumTime.format(d);
  };

  useEffect(() => {
    document.body.style.backgroundColor = "#0e0e14";
  }, []);

  return (
    <>
      <TableRow
        key={actionDate}
      >
        <TableCell className={classes.tableCell}>
          <Box display="flex">
            <Box
              style={{
                backgroundColor: type == "dm" ? "#2b95f2" :
                  type == "favorite" ? "#e04166" :
                    type == "designation" ? "#21c485" :
                      type == "campaign" ? "#a126e2" : "#fff",
                width: 12,
                height: 12,
                borderRadius: 4,
                marginTop: theme.spacing(2)
              }}
            />
            <Box width="15px" />
            <div style={{ width: "70%" }}>
              <Typography fontSize={16} fontWeight={600} color="textPrimary">
                {type == "dm" ? "Unique Direct Message Sent" :
                  type == "favorite" ? "Favorited" :
                    type == "designation" ? "Indicated Interest" :
                      type == "campaign" ? "Included in Campaign" : ""}
              </Typography>
              <Typography fontSize={14} color="textSecondary">
                {type == "dm" ? "sent by: " + content :
                  type == "favorite" ? "by: " + userUniversity.get("userContact").get("fullName") :
                    type == "designation" ? "by: " + userUniversity.get("userContact").get("fullName") :
                      type == "campaign" ? "Campaign: " + content : ""}
              </Typography>
            </div>
            <div style={{ display: 'flex', justifyContent: "right", width: "30%", alignItems: 'center' }}>
              <Typography fontSize={14} color="textSecondary" textAlign='right'>
                {parseDate(actionDate)}
              </Typography>
            </div>
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
};

export default function StudentProfile({ data, ...props }) {
  const theme = useTheme();
  const classes = useStyles(theme);

  const CARD_WIDTH = 4;
  const auth = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [generalColleges, setGeneralColleges] = useState([]);
  const [highColleges, setHighCollege] = useState([]);
  const [moderateColleges, setModerateColleges] = useState([]);
  const [lowColleges, setLowColleges] = useState([]);
  const [isFavorited, setIsFavorited] = useState(false);
  const [majors, setMajors] = useState([]);
  const [loadingMajors, setLoadingMajors] = useState(false);
  const [loadingColleges, setLoadingColleges] = useState(false);
  const [savingNotes, setSavingNotes] = useState(false);
  const [currentPage, setCurrentPage] = useState("academic_stats");
  const [isLead, setIsLead] = useState(false);
  const [intentScore, setIntentScore] = useState(0);
  const [intentEvents, setIntentEvents] = useState([]);
  const [note, setNote] = useState("");
  const [activities, setActivities] = useState([]);
  const [awards, setAwards] = useState([]);
  const [loadingActivities, setLoadingActivities] = useState(false);
  const [loadingAwards, setLoadingAwards] = useState(false);
  const [loadingActivity, setLoadingActivity] = useState(false);
  const ref = useRef(null);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [designation, setDesignation] = useState(designationOptions[0].value);
  const [openInfo, setOpenInfo] = useState(false);
  const [schoolActivities, setSchoolActivities] = useState([]);
  const [openInterests, setOpenInterests] = useState(false);
  const [openIntentSettings, setOpenIntentSettings] = useState(false);

  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  const carousel = useCarousel({
    dots: true,
    slidesToShow: 2,
    slidesToScroll: 2,
    slidesPerRow: 1,
    rows: 1,
    arrows: false,
    focusOnSelect: true,
    infinite: false,
    variableWidth: true,
    ...CarouselDots({
      sx: {
        mt: 8,
      },
    }),
    responsive: [],
  });

  const handleOpen = () => {
    setDropdownOpen(true);
  };

  const handleClose = () => {
    setDropdownOpen(false);
  };

  async function fetchStudentColleges() {
    setLoadingColleges(true);

    const result = await Parse.Cloud.run("getCollegesForStudentProfile", { userId: data?.userContact?.user?.objectId })

    setGeneralColleges(result[0].generalColleges);

    if (result[0].doesRank) {
      setHighCollege(result[0].highColleges);
      setModerateColleges(result[0].moderateColleges);
      setLowColleges(result[0].lowColleges);
    }
    setLoadingColleges(false);
  }

  async function fetchStudentMajors() {
    setLoadingMajors(true);

    const majorsResult = await Parse.Cloud.run("getMajorsForStudentProfile", { userId: data?.userContact?.user?.objectId })

    setMajors(majorsResult);

    setLoadingMajors(false);
  }

  async function fetchStudentActivities() {
    setLoadingActivities(true);

    const result = await Parse.Cloud.run("getActivitiesForStudentProfile", { userId: data?.userContact?.user?.objectId })

    setActivities(result);

    setLoadingActivities(false);
  }

  async function fetchStudentAwards() {
    setLoadingAwards(true);

    const result = await Parse.Cloud.run("getAwardsForStudentProfile", { userId: data?.userContact?.user?.objectId })

    setAwards(result);

    setLoadingAwards(false);
  }

  async function favoriteStudent() {
    setIsFavorited(!isFavorited);

    await Parse.Cloud.run("favoriteUnfavoriteStudent", {
      userUniversityId: Parse.User.current()!.get("userUniversity").id,
      studentId: data?.userContact?.userStudent?.objectId
    });
  }

  async function checkIsLead() {
    const res = await Parse.Cloud.run("checkIsLead", {
      collegeId: auth.collegeId,
      studentId: data?.userContact?.userStudent?.objectId
    })

    setIsLead(res);
  }

  async function changeDesignation(designation) {
    setDesignation(designation);

    const res = await Parse.Cloud.run("saveDesignation", {
      collegeId: auth.collegeId,
      studentId: data?.userContact?.userStudent?.objectId,
      designation: designation,
      userId: Parse.User.current()?.id
    })

    if (res == "added_new_designation") {
      await Parse.Cloud.run("sendIOSPush", { type: "profileViewOrInterest", id: data?.userContact?.user?.objectId, bodyStr: "A new school is interested in you!" });
    }
  }

  async function getDesignation() {
    const res = await Parse.Cloud.run("getDesignation", {
      collegeId: auth.collegeId,
      studentId: data?.userContact?.userStudent?.objectId
    })

    if (res) {
      setDesignation(res);

    } else {
      setDesignation(designationOptions[0].value);
    }
  }

  const handleSaveNote = async () => {
    setSavingNotes(true);

    await Parse.Cloud.run("saveStudentNote", {
      collegeId: auth.collegeId,
      studentId: data?.userContact?.userStudent?.objectId,
      userUniversityId: Parse.User.current()?.get("userUniversity").id,
      note: note
    })

    setSavingNotes(false);

    enqueueSnackbar("Success! Student note saved", { variant: "success" })
  }

  const getNote = async () => {
    const res = await Parse.Cloud.run("getStudentNote", {
      collegeId: auth.collegeId,
      studentId: data?.userContact?.userStudent?.objectId,
    })

    setNote(res);
  }

  const logNewProfileVisit = async () => {
    const res = await Parse.Cloud.run("logNewStudentProfileVisit", {
      visitorId: Parse.User.current()?.id,
      visitedId: data?.userContact?.user?.objectId,
    })

    if (res[0].status == "added new visit") {
      await Parse.Cloud.run("sendIOSPush", { type: "profileViewOrInterest", id: data?.userContact?.user?.objectId, bodyStr: "A new school has viewed your profile!" });
    }
  }

  async function deriveStudentIntent() {
    const includes = await Parse.Cloud.run("getIntentScoreFactors", {
      collegeId: auth.collegeId,
    });

    const res = await Parse.Cloud.run("deriveStudentIntent", {
      studentId: data?.userContact?.userStudent?.objectId,
      collegeId: auth.collegeId,
      includes: includes ?? [],
      studentFirstName: data?.userContact?.firstName,
      studentState: data?.userContact?.userStudent?.zipCodeState,
      studentCity: data?.userContact?.city,
      collegeState: auth.college.state,
      collegeCity: auth.college.city,
      parentColleges: data?.userContact?.userStudent?.parentColleges ? data?.userContact?.userStudent?.parentColleges.map((x) => x.value) : [],
      siblingColleges: data?.userContact?.userStudent?.siblingColleges ? data?.userContact?.userStudent?.siblingColleges.map((x) => x.value) : [],
      friendColleges: data?.userContact?.userStudent?.friendColleges ? data?.userContact?.userStudent?.friendColleges.map((x) => x.value) : [],
      appliedColleges: data?.userContact?.userStudent?.appliedColleges ? data?.userContact?.userStudent?.appliedColleges.map((x) => x.value) : [],
      acceptedColleges: data?.userContact?.userStudent?.acceptedColleges ? data?.userContact?.userStudent?.acceptedColleges.map((x) => x.value) : [],
      enrolledColleges: data?.userContact?.userStudent?.enrolledColleges ? data?.userContact?.userStudent?.enrolledColleges.map((x) => x.value) : []
    })

    setIntentEvents(res.itemsOfInterest);
    setIntentScore(res.intentScore);
  }

  async function checkIsFavorited() {
    const favoriteRes = await Parse.Cloud.run("checkStudentFavorited", {
      userUniversityId: Parse.User.current()!.get("userUniversity").id,
      studentId: data?.userContact?.userStudent?.objectId
    })

    setIsFavorited(favoriteRes);
  }

  async function getSchoolActivities() {
    setLoadingActivity(true);

    const res = await Parse.Cloud.run("getSchoolActivities", {
      studentId: data?.userContact?.userStudent?.objectId,
      collegeId: auth.collegeId
    })

    console.log(res);

    setSchoolActivities(res);

    setLoadingActivity(false);
  }

  useEffect(() => {
    checkIsFavorited();

    logNewProfileVisit();
  }, []);

  useEffect(() => {
    if (auth.collegeId) {
      checkIsLead();
      getDesignation();
      deriveStudentIntent();
      getSchoolActivities();
      getNote();
    }

  }, [auth.collegeId]);

  const ordinal_suffix_of = (i) => {
    if (i < 1) {
      return "Top " + (i * 100) + "%";

    } else {
      var j = i % 10,
        k = i % 100;
      if (typeof i === "string") {
        return i;
      }
      if (j == 1 && k != 11) {
        return i + "st";
      }
      if (j == 2 && k != 12) {
        return i + "nd";
      }
      if (j == 3 && k != 13) {
        return i + "rd";
      }
      return i + "th";
    }
  };

  const truncate_gpa = (gpa) => {
    if (typeof gpa === "string") {
      return gpa
    }
    return gpa?.toString().substring(0, 4) ? gpa?.toFixed(1) : "None"
  }

  const display_address = (address1, address2, city, state, zipCode) => {
    if (!address1) {
      if (!state) {
        return (
          <Grid item xs={2}>
            <Card style={{
              backgroundColor: theme.palette.secondary.dark,
              height: 36,
              borderRadius: 30,
              padding: theme.spacing(1),
              outlineColor: "#64646e",
              outlineStyle: "solid",
              outlineWidth: 0.5,
            }}>
              <Typography style={{
                fontSize: 13,
                fontWeight: 600,
                height: 34,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }} color="textPrimary">
                No Address
              </Typography>
            </Card>
          </Grid>
        )

      } else {
        if (state == "none") {
          return <></>
        } else {
          return (
            <Grid item xs={city ? 2.5 : 1.75}>
              <Card style={{
                backgroundColor: theme.palette.secondary.dark,
                height: 36,
                borderRadius: 30,
                padding: theme.spacing(1),
                outlineColor: "#64646e",
                outlineStyle: "solid",
                outlineWidth: 0.5,
              }}>
                <Typography style={{
                  fontSize: 13,
                  fontWeight: 600,
                  height: 34,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }} color="textPrimary">
                  {city ? city + ", " + state + ", " + zipCode : state + ", " + zipCode}
                </Typography>
              </Card>
            </Grid>
          )
        }
      }
    }

    if (address1 === "Hidden") {
      return (
        <Grid item xs={3}>
          <Card style={{
            backgroundColor: theme.palette.secondary.dark,
            height: 36,
            borderRadius: 30,
            padding: theme.spacing(1),
            outlineColor: "#64646e",
            outlineStyle: "solid",
            outlineWidth: 0.5,
          }}>
            <Typography style={{
              fontSize: 13,
              fontWeight: 600,
              height: 34,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }} color="textPrimary">
              {"Address Hidden by Student"}
            </Typography>
          </Card>
        </Grid>
      );
    }

    return (
      <Grid item xs={4.3}>
        <Card style={{
          backgroundColor: theme.palette.secondary.dark,
          height: 36,
          borderRadius: 30,
          padding: theme.spacing(1),
          outlineColor: "#64646e",
          outlineStyle: "solid",
          outlineWidth: 0.5,
        }}>
          <Typography style={{
            fontSize: 14,
            fontWeight: 600,
            height: 34,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            {capitalize_each_word(address1.trim()) + "" + (address2 ?? "") + (city ? ", " + capitalize_each_word(city).trim() + " " + state + ", " + zipCode : state + ", " + zipCode)}
          </Typography>
        </Card>
      </Grid>
    )
  }

  const format_birthday = (date) => {
    if (!date) {
      return "No Birthday"
    }

    if (date === "Hidden") {
      return date
    }

    let dateObj = new Date(date)
    return dateObj.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  }

  const capitalize = (str) => {
    if (!str) {
      return "None"
    }

    if (str.length <= 3) {
      return str.toUpperCase()
    }
    const lower = str.toLowerCase();
    return str.charAt(0).toUpperCase() + lower.slice(1);
  }

  const capitalize_each_word = (str) => {
    if (!str) {
      return ""
    }

    const arr = str.split(" ");

    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }

    const str2 = arr.join(" ");

    return str2
  }

  const format_phone = (str) => {
    //Filter only numbers from the input
    if (!str) {
      return
    }

    if (str === "Hidden") {
      return str
    }
    var cleaned = ("" + str).replace(/\D/g, "");

    //Check if the input is of correct
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      //Remove the matched extension code
      //Change this to format for any country code.
      var intlCode = match[1] ? "+1 " : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }

    return null;
  }

  const act_breakdown = (math, english, reading, science, total) => {
    if (total === "Hidden") {
      return ""
    }

    let retVal = (math ? math.toString() + ' math, ' : "") +
      (english ? english.toString() + ' english, ' : "") +
      (reading ? reading.toString() + ' reading, ' : "") +
      (science ? science.toString() + ' science, ' : "")

    // remove last comma and space
    if (retVal.length > 0) {
      retVal = '(' + retVal.substring(0, retVal.length - 2) + ')'
    }

    return retVal
  }
  const sat_breakdown = (math, readingWriting, total) => {
    if (total === "Hidden") {
      return ""
    }

    let retVal = (math ? math.toString() + " math, " : "") +
      (readingWriting ? readingWriting.toString() + " reading/writing, " : "")

    // remove last comma and space
    if (retVal.length > 0) {
      retVal = "(" + retVal.substring(0, retVal.length - 2) + ")";
    }

    return retVal;
  }

  useEffect(() => {
    fetchStudentColleges();
    fetchStudentMajors();
    fetchStudentActivities()
    fetchStudentAwards();

    document.body.style.backgroundColor = "#0e0e14";
  }, []);

  useEffect(() => {
    console.log(generalColleges);
  }, [generalColleges]);

  return (
    <Page title="User Profile" maxWidth="xl">
      <FadeIn>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card style={{
              backgroundColor: theme.palette.primary.dark,
              padding: theme.spacing(4),
              borderRadius: 25,
              outlineColor: "#25252b",
              outlineStyle: "solid",
              outlineWidth: 1,
            }} sx={{ alignSelf: "center" }}>
              <Grid container spacing={3}>
                <Grid marginLeft={theme.spacing(1.2)} item xs={2}>
                  <Box marginTop={theme.spacing(-3.5)}>
                    <Tooltip
                      title="Favorite Student"
                      componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: "#46464f",
                            color: "white"
                          }
                        }
                      }}
                    >
                      <ICButton
                        onClick={favoriteStudent}
                        style={{
                          backgroundColor: isFavorited ? "#35c669" : "#3a3a43",
                          borderRadius: 35,
                          height: 35,
                          width: 35,
                          top: theme.spacing(5),
                          right: theme.spacing(-14),
                          zIndex: 3,
                          outlineColor: "#1a1a21",
                          outlineStyle: "solid",
                          outlineWidth: 4.5
                        }}
                        sx={{
                          // boxShadow: 4
                        }}
                      >
                        <Iconify
                          icon={'ph:star-fill'}
                          width={20}
                          color="#fff"
                        />
                      </ICButton>
                    </Tooltip>
                    <Avatar
                      style={{
                        width: 150,
                        height: 150,
                        backgroundColor: "#22222b",
                        zIndex: 1,
                        outlineColor: "#3e3e46",
                        outlineStyle: "solid",
                        outlineWidth: 1
                      }}
                      alt="U"
                      src={data?.userContact?.img?.url}
                    />
                  </Box>
                </Grid>
                <Grid item xs={5}>
                  <Box height="35px" />
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      backgroundColor: 'transparent',
                    }}
                  >
                    <Typography style={{
                      marginTop: -20,
                      marginLeft: theme.spacing(-1),
                      fontSize: 40,
                      fontWeight: 700,
                    }} color="textPrimary">
                      {
                        capitalize_each_word(data?.userContact?.firstName +
                          " " +
                          (data?.userContact?.userStudent?.settings?.showLastNameToColleges ? data?.userContact?.lastName : data?.userContact?.lastName.charAt(0) + "."))}
                    </Typography>
                    {data?.userContact?.userStudent?.verificationStatus == "verified" ? (
                      <Tooltip
                        title="Verified Profile"
                        componentsProps={{
                          tooltip: {
                            sx: {
                              bgcolor: "#46464f",
                              color: "white"
                            }
                          }
                        }}
                      >
                        <img
                          height={26}
                          width={26}
                          src={"/assets/icons/ic_verify.png"}
                          style={{
                            visibility: data?.userContact?.userStudent?.verificationStatus == "verified" ? "visible" : "hidden",
                            marginLeft: theme.spacing(1),
                            marginTop: theme.spacing(-2)
                          }}
                        />
                      </Tooltip>
                    ) : (<></>)}
                    {data?.userContact?.userStudent?.appliedColleges || data?.userContact?.userStudent?.acceptedColleges || data?.userContact?.userStudent?.enrolledColleges ? (
                      <Tooltip
                        title={data?.userContact?.userStudent?.appliedColleges.map((x) => x.value).includes(auth.collegeId) ? "Applied" : data?.userContact?.userStudent?.acceptedColleges.map((x) => x.value).includes(auth.collegeId) ? "Accepted" : data?.userContact?.userStudent?.enrolledColleges.map((x) => x.value).includes(auth.collegeId) ? "Enrolled" : "Not Applied"}
                        componentsProps={{
                          tooltip: {
                            sx: {
                              bgcolor: "#46464f",
                              color: "white"
                            }
                          }
                        }}
                      >
                        <img
                          height={22}
                          width={22}
                          src={data?.userContact?.userStudent?.appliedColleges.map((x) => x.value).includes(auth.collegeId) ? "/assets/icons/ic_applied.svg" : data?.userContact?.userStudent?.acceptedColleges.map((x) => x.value).includes(auth.collegeId) ? "/assets/icons/ic_accepted.svg" : data?.userContact?.userStudent?.enrolledColleges.map((x) => x.value).includes(auth.collegeId) ? "/assets/icons/ic_enrolled.svg" : "/assets/icons/ic_applied.svg"}
                          style={{
                            visibility: data?.userContact?.userStudent?.appliedColleges.map((x) => x.value).includes(auth.collegeId) || data?.userContact?.userStudent?.acceptedColleges.map((x) => x.value).includes(auth.collegeId) || data?.userContact?.userStudent?.enrolledColleges.map((x) => x.value).includes(auth.collegeId) ? "visible" : "hidden",
                            marginLeft: theme.spacing(1),
                            marginTop: theme.spacing(-2)
                          }}
                        />
                      </Tooltip>
                    ) : (<></>)}
                  </div>
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: 'transparent',
                    marginLeft: theme.spacing(-1),
                  }}>
                    <Typography variant="body1" color="textSecondary">
                      {"@" + data?.userContact?.user?.username?.toLowerCase() + " • " + (new Date(data?.userContact?.userStudent?.graduationDate) < (new Date()) ? "Graduated " + months[(new Date(data?.userContact?.userStudent?.graduationDate).getMonth())] + ", " + new Date(data?.userContact?.userStudent?.graduationDate).getFullYear() : "Graduating " + months[(new Date(data?.userContact?.userStudent?.graduationDate).getMonth())] + ", " + new Date(data?.userContact?.userStudent?.graduationDate).getFullYear())}
                    </Typography>
                    <Box width="10px" />
                    <Card style={(isLead) ? {
                      background: "linear-gradient(75deg, #35c669, #2ec4a9)",
                      borderRadius: 30,
                      paddingTop: theme.spacing(0),
                      paddingBottom: theme.spacing(0),
                      paddingLeft: theme.spacing(1),
                      paddingRight: theme.spacing(1),
                    } : {
                      backgroundColor: "#32323c",
                      borderRadius: 30,
                      paddingTop: theme.spacing(0),
                      paddingBottom: theme.spacing(0),
                      paddingLeft: theme.spacing(1),
                      paddingRight: theme.spacing(1),
                    }}>
                      <Typography style={{
                        fontSize: 9.5,
                        fontWeight: 600,
                        height: 20,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }} color="textPrimary">
                        {isLead ? "OUR LEAD" : "ORGANIC USER"}
                      </Typography>
                    </Card>
                  </div>
                  <Typography style={{
                    fontSize: 12,
                    marginLeft: theme.spacing(-1),
                    fontWeight: 600,
                    paddingTop: "6px"
                  }} color="textSecondary">
                    {props.typeAndSchool}
                  </Typography>
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: 'transparent',
                    marginLeft: theme.spacing(-1),
                  }}>
                    <Tooltip
                      title="Interest Level"
                      componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: "#46464f",
                            color: "white"
                          }
                        }
                      }}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        component={ButtonBase}
                        onClick={handleOpen}
                        ref={ref}
                        style={{
                          borderRadius: 25,
                          backgroundColor: "#0d0d14",
                          // padding: 1,
                          minWidth: (props.typeAndSchool).length * 6.7,
                          paddingRight: theme.spacing(3),
                          paddingLeft: theme.spacing(3),
                          marginLeft: 1,
                          paddingTop: 4,
                          paddingBottom: 4,
                          outlineWidth: 1,
                          outlineStyle: "solid",
                          outlineColor: "#3b3b42"
                        }}
                      >
                        {/* <Avatar alt="User" className={classes.avatar} src={src} sx={{ width: 32, height: 32, backgroundColor: theme.palette.primary.light }} /> */}
                        <Hidden smDown>
                          <Typography style={{ fontSize: 12, fontWeight: 600 }} color="inherit">
                            {designation}
                          </Typography>
                        </Hidden>
                      </Box>
                    </Tooltip>
                    <Menu
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      keepMounted
                      slotProps={{
                        paper: {
                          sx: {
                            minWidth: 270,
                            marginLeft: theme.spacing(0),
                            marginTop: theme.spacing(1),
                            backgroundColor: theme.palette.primary.dark,
                            borderRadius: 1.5,
                            outlineColor: "#3c3c44",
                            outlineStyle: "solid",
                            outlineWidth: 1,
                          }
                        }
                      }}
                      anchorEl={ref.current}
                      open={isDropdownOpen}
                    >
                      {designationOptions.map((option) => (
                        <MenuItem
                          key={option.value}
                          onClick={() => {
                            setDropdownOpen(false);
                            changeDesignation(option.value);
                          }}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </Menu>
                    <IconButton onClick={() => setOpenInfo(true)}>
                      <InfoIcon style={{ color: "#9696a0" }} />
                    </IconButton>
                    <Info
                      setOpen={setOpenInfo}
                      open={openInfo}
                      title="Show Interest in Students"
                      content={<Typography style={{ fontWeight: 400, fontSize: 16, color: "#aaaab4", marginTop: theme.spacing(3), paddingBottom: theme.spacing(1) }} align="left">
                        Click the 'Student Interest' button to the left to designate the student as either 'No Selection' or as someone that
                        the school is 'Interested in Connecting' with.
                        <br />
                        <br />
                        These level of interest designations both keep your department organized on which students are of
                        most interest and can also indicate your school's interest directly to students. With the purchase of the
                        Premium Subscription, students can see that your school is interested in connecting with them and can
                        take steps to further connect with you based on that. <b>If you do not want a student
                          to be able to see this, simply select the 'No Selection' option.
                        </b>
                      </Typography>}
                    />
                  </div>
                </Grid>
                <Grid item xs={4.8} style={{ display: 'flex', justifyContent: 'right' }}>
                  {auth.tier == "free" ? (
                    <Button
                      style={{
                        background: "linear-gradient(75deg, #35c669, #2ec4a9)",
                        color: "#fff",
                        borderRadius: 18,
                        width: 170,
                        height: 60,
                        top: theme.spacing(7)
                      }}
                      variant="contained"
                      size="large"
                      onClick={() => enqueueSnackbar("Oops! You must upgrade to Premium before messaging students", { variant: "error" })}
                    >
                      Direct Message
                    </Button>
                  ) : (
                    <>
                      {props.hasMessagingPermission ? (
                        <Link
                          to={`/dashboard/messages/create/${data?.userContact?.objectId}`}
                        >
                          <Button
                            style={{
                              background: "linear-gradient(75deg, #35c669, #2ec4a9)",
                              color: "#fff",
                              borderRadius: 18,
                              width: 170,
                              height: 60,
                              top: theme.spacing(7)
                            }}
                            variant="contained"
                            size="large"
                          >
                            Direct Message
                          </Button>
                        </Link>
                      ) : (
                        <>
                          <Button
                            style={{
                              background: "linear-gradient(75deg, #35c669, #2ec4a9)",
                              color: "#fff",
                              borderRadius: 18,
                              width: 170,
                              height: 60,
                              top: theme.spacing(7)
                            }} variant="contained"
                            size="large"
                            disabled
                          >
                            Direct Message
                          </Button>
                        </>
                      )}
                    </>
                  )}
                  <Tooltip
                    title="Student Interests"
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: "#46464f",
                          color: "white"
                        }
                      }
                    }}
                  >
                    <ICButton
                      onClick={() => { setOpenInterests(true) }}
                      style={{
                        backgroundColor: "#131318",
                        borderRadius: 35,
                        height: 40,
                        width: 40,
                        top: theme.spacing(8),
                        left: theme.spacing(1.5),
                        marginRight: theme.spacing(1.5),
                        outlineColor: "#64646e",
                        outlineStyle: "solid",
                        outlineWidth: 0.5
                      }}
                    >
                      <Iconify
                        icon={'heroicons-solid:lightning-bolt'}
                        width={20}
                        color="#fff"
                      />
                    </ICButton>
                  </Tooltip>
                  <Interests
                    setOpen={setOpenInterests}
                    open={openInterests}
                    title="Student Interests"
                    interests={data?.userContact?.userStudent?.interests}
                  />
                </Grid>
              </Grid>
              <Box height="20px" />
              <Box height="20px" />
              <Grid container spacing={2}>
                {data?.userContact?.userStudent?.gender && props.showPersonalInfoToColleges ? (
                  <Grid item xs={data?.userContact?.userStudent?.gender == "Other" ? 2.1 : 1.7}>
                    <Card style={{
                      backgroundColor: theme.palette.secondary.dark,
                      height: 36,
                      borderRadius: 30,
                      padding: theme.spacing(1),
                      outlineColor: "#64646e",
                      outlineStyle: "solid",
                      outlineWidth: 0.5,
                    }}>
                      <Typography style={{
                        fontSize: 14,
                        fontWeight: 600,
                        height: 34,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }} color="textPrimary">
                        {data?.userContact?.userStudent?.gender == "Other" ? "No Gender Listed" : data?.userContact?.userStudent?.gender}
                      </Typography>
                    </Card>
                  </Grid>
                ) : (
                  <></>
                )}
                {data?.userContact?.userStudent?.ethnicity && props.showPersonalInfoToColleges ? (
                  <Grid item xs={data?.userContact?.userStudent?.ethnicity.length > 11 ? 2.8 : 2}>
                    <Card style={{
                      backgroundColor: theme.palette.secondary.dark,
                      height: 36,
                      borderRadius: 30,
                      padding: theme.spacing(1),
                      outlineColor: "#64646e",
                      outlineStyle: "solid",
                      outlineWidth: 0.5,
                    }}>
                      <Typography style={{
                        fontSize: 14,
                        fontWeight: 600,
                        height: 34,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }} color="textPrimary">
                        {data?.userContact?.userStudent?.ethnicity == "None" ? "No Ethnicity" : data?.userContact?.userStudent?.ethnicity == "Other" ? "Other Ethnicity" : data?.userContact?.userStudent?.ethnicity}
                      </Typography>
                    </Card>
                  </Grid>
                ) : (
                  <></>
                )}
                {data?.userContact?.userStudent?.birthday && props.showPersonalInfoToColleges ? (
                  <Grid item xs={2.4}>
                    <Card style={{
                      backgroundColor: theme.palette.secondary.dark,
                      height: 36,
                      borderRadius: 30,
                      padding: theme.spacing(1),
                      outlineColor: "#64646e",
                      outlineStyle: "solid",
                      outlineWidth: 0.5,
                    }}>
                      <Typography style={{
                        fontSize: 14,
                        fontWeight: 600,
                        height: 34,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }} color="textPrimary">
                        {"Born " + format_birthday(data?.userContact?.userStudent?.birthday)}
                      </Typography>
                    </Card>
                  </Grid>
                ) : (
                  <></>
                )}
                {(data?.userContact?.userStudent?.settings?.showPersonalInfoToColleges ? display_address(
                  data?.userContact?.address1,
                  data?.userContact?.address2,
                  data?.userContact?.city,
                  data?.userContact?.userStudent?.zipCodeState,
                  data?.userContact?.zipCode
                ) : display_address(
                  "Hidden",
                  "",
                  "",
                  "",
                  ""))}
                {data?.userContact?.userStudent?.parentsHighestEdu && props.showPersonalInfoToColleges ? (
                  <Grid item xs={3.2}>
                    <Card style={{
                      backgroundColor: theme.palette.secondary.dark,
                      height: 36,
                      borderRadius: 30,
                      padding: theme.spacing(1),
                      outlineColor: "#64646e",
                      outlineStyle: "solid",
                      outlineWidth: 0.5,
                    }}>
                      <Typography style={{
                        fontSize: 14,
                        fontWeight: 600,
                        height: 34,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }} color="textPrimary">
                        {"Parents' Highest Edu - " + capitalize(data?.userContact?.userStudent?.parentsHighestEdu)}
                      </Typography>
                    </Card>
                  </Grid>
                ) : (
                  <></>
                )}
                <Grid item xs={2.5}>
                  <Card style={{
                    backgroundColor: theme.palette.secondary.dark,
                    height: 36,
                    borderRadius: 30,
                    padding: theme.spacing(1),
                    outlineColor: "#64646e",
                    outlineStyle: "solid",
                    outlineWidth: 0.5,
                  }}>
                    <Typography style={{
                      fontSize: 14,
                      fontWeight: 600,
                      height: 34,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }} color="textPrimary">
                      {isLead ? "Joined " + format_birthday(data?.userContact?.createdAt) : "Joined " + format_birthday(data?.userContact?.createdAt)}
                    </Typography>
                  </Card>
                </Grid>
              </Grid>
              <Box height="50px" />
              <Grid container spacing={2} maxWidth={1200}>
                <Grid item xs={1.6}>
                  <Button
                    style={{
                      fontSize: 14,
                      fontWeight: 600,
                      height: 34,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      color: currentPage == "academic_stats" ? "#35c669" : "#fff",
                      minWidth: 140,
                    }}
                    sx={{
                      backgroundColor: currentPage == "academic_stats" ? "rgba(105, 105, 110, 0.1)" : "transparent",
                      '&:hover': {
                        backgroundColor: "rgba(105, 105, 110, 0.1)",
                      },
                    }}
                    onClick={() => setCurrentPage("academic_stats")}
                  >
                    Academic Stats
                  </Button>
                </Grid>
                <Grid item xs={1.57}>
                  <Button
                    style={{
                      fontSize: 14,
                      fontWeight: 600,
                      height: 34,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      color: currentPage == "intent_analysis" ? "#35c669" : "#fff",
                      minWidth: 138,
                    }}
                    sx={{
                      backgroundColor: currentPage == "intent_analysis" ? "rgba(105, 105, 110, 0.1)" : "transparent",
                      '&:hover': {
                        backgroundColor: "rgba(105, 105, 110, 0.1)",
                      },
                    }}
                    onClick={() => setCurrentPage("intent_analysis")}
                  >
                    Intent Analysis
                  </Button>
                </Grid>
                <Grid item xs={1.81}>
                  <Button
                    style={{
                      fontSize: 14,
                      fontWeight: 600,
                      height: 34,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      color: currentPage == "colleges_majors" ? "#35c669" : "#fff",
                      minWidth: 160,
                    }}
                    sx={{
                      backgroundColor: currentPage == "colleges_majors" ? "rgba(105, 105, 110, 0.1)" : "transparent",
                      '&:hover': {
                        backgroundColor: "rgba(105, 105, 110, 0.1)",
                      },
                    }}
                    onClick={() => setCurrentPage("colleges_majors")}
                  >
                    Colleges & Majors
                  </Button>
                </Grid>
                <Grid item xs={1.91}>
                  <Button
                    style={{
                      fontSize: 14,
                      fontWeight: 600,
                      height: 34,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      color: currentPage == "activities_awards" ? "#35c669" : "#fff",
                      minWidth: 170,
                    }}
                    sx={{
                      backgroundColor: currentPage == "activities_awards" ? "rgba(105, 105, 110, 0.1)" : "transparent",
                      '&:hover': {
                        backgroundColor: "rgba(105, 105, 110, 0.1)",
                      },
                    }}
                    onClick={() => setCurrentPage("activities_awards")}
                  >
                    Activities & Awards
                  </Button>
                </Grid>
                <Grid item xs={2.03}>
                  <Button
                    style={{
                      fontSize: 14,
                      fontWeight: 600,
                      height: 34,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      color: currentPage == "activity" ? "#35c669" : "#fff",
                      minWidth: 180,
                    }}
                    sx={{
                      backgroundColor: currentPage == "activity" ? "rgba(105, 105, 110, 0.1)" : "transparent",
                      '&:hover': {
                        backgroundColor: "rgba(105, 105, 110, 0.1)",
                      },
                    }}
                    onClick={() => setCurrentPage("activity")}
                  >
                    Engagement Activity
                  </Button>
                </Grid>
                <Grid item xs={0.95}>
                  <Button
                    style={{
                      fontSize: 14,
                      fontWeight: 600,
                      height: 34,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      color: currentPage == "notes" ? "#35c669" : "#fff",
                      minWidth: 72,
                    }}
                    sx={{
                      backgroundColor: currentPage == "notes" ? "rgba(105, 105, 110, 0.1)" : "transparent",
                      '&:hover': {
                        backgroundColor: "rgba(105, 105, 110, 0.1)",
                      },
                    }}
                    onClick={() => setCurrentPage("notes")}
                  >
                    Notes
                  </Button>
                </Grid>
              </Grid>
              <Box height="30px" />
              <Box
                visibility={currentPage == "academic_stats" ? "visible" : "hidden"}
                maxHeight={currentPage == "academic_stats" ? 1000 : 0}
                style={{
                  opacity: currentPage == "academic_stats" ? 1 : 0,
                  transition: "opacity .3s ease",
                }}
              >
                <FadeIn>
                  <Grid marginBottom={theme.spacing(-6)} container>
                    <Grid item xs={3}>
                      <Box height={"65%"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                        <Box
                          display="block"
                          width={180}
                          height={180}
                          style={{
                            backgroundColor: theme.palette.secondary.dark,
                            borderRadius: 100,
                            outlineColor: "#37373d",
                            outlineStyle: "solid",
                            outlineWidth: 1,
                            marginTop: theme.spacing(3),
                          }}
                        >
                          <Typography
                            textAlign={"center"}
                            sx={{
                              fontSize: 70,
                              fontWeight: 800,
                              backgroundcolor: "primary",
                              backgroundImage: `linear-gradient(to right, #35c669 30%, #2ec4a9 70%)`,
                              backgroundSize: "100%",
                              backgroundRepeat: "repeat",
                              backgroundClip: "text",
                              WebkitBackgroundClip: "text",
                              WebkitTextFillColor: "transparent",
                              mt: theme.spacing(2)
                            }}>
                            {calcAcademicScore(data?.userContact?.userStudent?.sat, data?.userContact?.userStudent?.act, data?.userContact?.userStudent?.psat, data?.userContact?.userStudent?.adjustedUWGPA, data?.userContact?.userStudent?.adjustedClassRank) ?? 0}
                          </Typography>
                          <Typography
                            textAlign={"center"}
                            fontSize={13}
                            fontWeight={800}
                            mt={theme.spacing(-1)}
                            paddingRight={theme.spacing(4)}
                            paddingLeft={theme.spacing(4)}
                          >
                            ACADEMIC SCORE
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={9}>
                      <Carousel
                        ref={carousel.carouselRef}
                        {...carousel.carouselSettings}
                      >
                        <Box>
                          <Card style={{
                            margin: 15,
                            backgroundColor: theme.palette.secondary.dark,
                            height: 250,
                            width: 350,
                            borderRadius: 30,
                            padding: theme.spacing(1),
                            outlineColor: "#37373d",
                            outlineStyle: "solid",
                            outlineWidth: 1,
                          }}>
                            <Box height="70px" pt={4} justifyContent='center' display='flex'>
                              <Typography variant="h2" color="textPrimary" style={{ fontWeight: 600 }}>
                                {(props.showStatsToColleges ? truncate_gpa(data?.userContact?.userStudent?.adjustedUWGPA) : "Hidden")}
                              </Typography>
                            </Box>
                            <Box height="140px" p={2} mt={2} px={5} justifyContent='center'>
                              <Typography style={{ fontWeight: 600, fontSize: 21 }} color="textPrimary" align='center'>GPA (unweighted)</Typography>
                              <Typography variant="body1" color="textSecondary" align='center'>
                                {`Cumulative grade point average calculated on a 4.0 scale`}
                              </Typography>
                            </Box>
                          </Card>
                        </Box>
                        <Box>
                          <Card style={{
                            margin: 15,
                            backgroundColor: theme.palette.secondary.dark,
                            height: 250,
                            width: 350,
                            borderRadius: 30,
                            padding: theme.spacing(1),
                            outlineColor: "#37373d",
                            outlineStyle: "solid",
                            outlineWidth: 1,
                          }}>
                            <Box height="70px" pt={4} justifyContent='center' display='flex'>
                              <Typography variant="h2" color="textPrimary" style={{ fontWeight: 600 }}>
                                {(props.showStatsToColleges ? truncate_gpa(data?.userContact?.userStudent?.gpaW) : "Hidden")}
                              </Typography>
                            </Box>
                            <Box height="140px" p={2} mt={2} px={5} justifyContent='center'>
                              <Typography style={{ fontWeight: 600, fontSize: 21 }} color="textPrimary" align='center'>GPA (weighted)</Typography>
                              <Typography variant="body1" color="textSecondary" align='center'>
                                {`Cumulative grade point average calculated on ${data?.userContact?.userStudent?.gpaScale ? "a " + data?.userContact?.userStudent?.gpaScale.toFixed(1) : "an unknown"} scale`}
                              </Typography>
                            </Box>
                          </Card>
                        </Box>
                        <Box>
                          <Card style={{
                            margin: 15,
                            backgroundColor: theme.palette.secondary.dark,
                            height: 250,
                            width: 350,
                            borderRadius: 30,
                            padding: theme.spacing(1),
                            outlineColor: "#37373d",
                            outlineStyle: "solid",
                            outlineWidth: 1,
                          }}>
                            <Box height="70px" pt={4} justifyContent='center' display='flex'>
                              <Typography variant="h2" color="textPrimary" style={{ fontWeight: 600 }}>
                                {(props.showStatsToColleges ? data?.userContact?.userStudent?.sat ?? "None" : "Hidden")}
                              </Typography>
                            </Box>
                            <Box height="140px" p={2} mt={2} px={5} justifyContent='center'>
                              <Typography
                                style={{
                                  fontWeight: 600,
                                  fontSize: 21
                                }}
                                color="textPrimary"
                                align='center'
                              >
                                {data?.userContact?.userStudent?.satHasTaken == false ? "SAT Score (Not Taken)" : "SAT Score"}
                              </Typography>
                              <Typography variant="body1" color="textSecondary" align='center'>
                                {`Total SAT score ${(props.showStatsToColleges ? sat_breakdown(
                                  data?.userContact?.userStudent?.mathSATScore,
                                  data?.userContact?.userStudent?.readingWritingSATScore,
                                  data?.userContact?.userStudent?.sat
                                ) : sat_breakdown(
                                  data?.userContact?.userStudent?.mathSATScore,
                                  data?.userContact?.userStudent?.readingWritingSATScore,
                                  "Hidden"))} on the standard 1600 point scale`}
                              </Typography>
                            </Box>
                          </Card>
                        </Box>
                        <Box>
                          <Card style={{
                            margin: 15,
                            backgroundColor: theme.palette.secondary.dark,
                            height: 250,
                            width: 350,
                            borderRadius: 30,
                            padding: theme.spacing(1),
                            outlineColor: "#37373d",
                            outlineStyle: "solid",
                            outlineWidth: 1,
                          }}>
                            <Box height="70px" pt={4} justifyContent='center' display='flex'>
                              <Typography variant="h2" color="textPrimary" style={{ fontWeight: 600 }}>
                                {(props.showStatsToColleges ? data?.userContact?.userStudent?.act ?? "None" : "Hidden")}
                              </Typography>
                            </Box>
                            <Box height="140px" p={2} mt={2} px={5} justifyContent='center'>
                              <Typography
                                style={{
                                  fontWeight: 600,
                                  fontSize: 21
                                }}
                                color="textPrimary"
                                align='center'
                              >
                                {data?.userContact?.userStudent?.actHasTaken == false ? "ACT Score (Not Taken)" : "ACT Score"}
                              </Typography>
                              <Typography variant="body1" color="textSecondary" align='center'>
                                {`Total ACT score ${(props.showStatsToColleges ? act_breakdown(
                                  data?.userContact?.userStudent?.mathACTScore,
                                  data?.userContact?.userStudent?.englishACTScore,
                                  data?.userContact?.userStudent?.readingACTScore,
                                  data?.userContact?.userStudent?.scienceACTScore,
                                  data?.userContact?.userStudent?.act
                                ) :
                                  act_breakdown(
                                    data?.userContact?.userStudent?.mathACTScore,
                                    data?.userContact?.userStudent?.englishACTScore,
                                    data?.userContact?.userStudent?.readingACTScore,
                                    data?.userContact?.userStudent?.scienceACTScore,
                                    "Hidden"
                                  ))} on the standard 36 point scale`}
                              </Typography>
                            </Box>
                          </Card>
                        </Box>
                        <Box>
                          <Card style={{
                            margin: 15,
                            backgroundColor: theme.palette.secondary.dark,
                            height: 250,
                            width: 350,
                            borderRadius: 30,
                            padding: theme.spacing(1),
                            outlineColor: "#37373d",
                            outlineStyle: "solid",
                            outlineWidth: 1,
                          }}>
                            <Box height="70px" pt={4} justifyContent='center' display='flex'>
                              <Typography variant="h2" color="textPrimary" style={{ fontWeight: 600 }}>
                                {(props.showStatsToColleges ? data?.userContact?.userStudent?.psat ?? "None" : "Hidden")}
                              </Typography>
                            </Box>
                            <Box height="140px" p={2} mt={2} px={5} justifyContent='center'>
                              <Typography
                                style={{
                                  fontWeight: 600,
                                  fontSize: 21
                                }}
                                color="textPrimary"
                                align='center'
                              >
                                {data?.userContact?.userStudent?.psatHasTaken == false ? "PSAT Score (Not Taken)" : "PSAT Score"}
                              </Typography>
                              <Typography variant="body1" color="textSecondary" align='center'>
                                {`Total PSAT score ${(props.showStatsToColleges ? sat_breakdown(
                                  data?.userContact?.userStudent?.mathPSATScore,
                                  data?.userContact?.userStudent?.readingWritingPSATScore,
                                  data?.userContact?.userStudent?.psat
                                ) : sat_breakdown(
                                  data?.userContact?.userStudent?.mathPSATScore,
                                  data?.userContact?.userStudent?.readingWritingPSATScore,
                                  "Hidden"
                                ))} on the standard 1520 point scale`}
                              </Typography>
                            </Box>
                          </Card>
                        </Box>
                        <Box>
                          <Card style={{
                            margin: 15,
                            backgroundColor: theme.palette.secondary.dark,
                            height: 250,
                            width: 350,
                            borderRadius: 30,
                            padding: theme.spacing(1),
                            outlineColor: "#37373d",
                            outlineStyle: "solid",
                            outlineWidth: 1,
                          }}>
                            <Box height="70px" pt={4} justifyContent='center' display='flex'>
                              <Typography variant="h2" color="textPrimary" style={{ fontWeight: 600 }}>
                                {(props.showStatsToColleges ? ordinal_suffix_of(
                                  data?.userContact?.userStudent?.classRank ?? "None"
                                ) : "Hidden")}
                              </Typography>
                            </Box>
                            <Box height="140px" p={2} mt={2} px={5} justifyContent='center'>
                              <Typography style={{ fontWeight: 600, fontSize: 21 }} color="textPrimary" align='center'>Class Rank</Typography>
                              <Typography variant="body1" color="textSecondary" align='center'>
                                {`Current class ranking out of ${data?.userContact?.userStudent?.classSize ?? "no"
                                  } students`}
                              </Typography>
                            </Box>
                          </Card>
                        </Box>
                      </Carousel>
                      <CarouselArrows
                        width={"100%"}
                        justifyContent={"space-between"}
                        onNext={carousel.onNext}
                        onPrev={carousel.onPrev}
                        leftButtonProps={{
                          color: "primary",
                          sx: { color: "#35c669", opacity: 1 },
                        }}
                        rightButtonProps={{
                          color: "primary",
                          sx: { color: "#35c669", opacity: 1 },
                        }}
                        sx={{ mt: -10 }}
                      />
                    </Grid>
                  </Grid>
                </FadeIn>
              </Box>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={3}
                  visibility={currentPage == "intent_analysis" ? "visible" : "hidden"}
                  maxHeight={currentPage == "intent_analysis" ? 1000 : 0}
                  style={{
                    opacity: currentPage == "intent_analysis" ? 1 : 0,
                    transition: "opacity .3s ease"
                  }}
                >
                  <Box sx={{ position: 'relative', display: 'inline-flex', marginLeft: theme.spacing(3) }}>
                    <CircleProgress
                      percentage={intentScore}
                      strokeWidth={13}
                      primaryColor={['#35c669', '#2ec4a9']}
                      secondaryColor={theme.palette.secondary.dark}
                      fontSize={35}
                      hidePercentageText
                      fontWeight={800}
                    />
                    <Box
                      sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center'
                      }}
                    >
                      <Box marginTop={theme.spacing(-2)}>
                        <Typography
                          fontSize={45}
                          fontWeight={800}
                          color="#fff"
                        >
                          {`${Math.round(intentScore)}`}
                        </Typography>
                        <Typography
                          fontSize={10}
                          fontWeight={800}
                          color="#fff"
                          marginTop={theme.spacing(-1)}
                        >
                          INTENT SCORE
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={9}
                  visibility={currentPage == "intent_analysis" ? "visible" : "hidden"}
                  maxHeight={currentPage == "intent_analysis" ? 1000 : 0}
                  style={{
                    opacity: currentPage == "intent_analysis" ? 1 : 0,
                    transition: "opacity .3s ease"
                  }}
                >
                  <Box marginTop={theme.spacing(2)}>
                    <div style={{ display: 'flex', justifyContent: 'left' }}>
                      <Typography
                        fontSize={22}
                        fontWeight={800}
                        color="#fff"
                        marginTop={theme.spacing(-1)}
                      >
                        Actions & Attributes
                      </Typography>
                      <IconButton
                        style={{
                          marginTop: theme.spacing(-1)
                        }}
                        onClick={() => setOpenIntentSettings(true)}>
                        <SettingsIcon
                          style={{
                            color: "#9696a0",
                            height: 18,
                            width: 18
                          }}
                        />
                      </IconButton>
                    </div>
                    <IntentSettings
                      setOpen={setOpenIntentSettings}
                      open={openIntentSettings}
                      title="Intent Score Factors"
                      collegeId={auth.collegeId}
                      reload={deriveStudentIntent}
                    />
                    <Grid
                      container
                      width={intentEvents.length < 4 ? 520 : 680}
                      minHeight={intentEvents.length < 7 ? 130 : 160}
                      spacing={1.5}
                      marginLeft={theme.spacing(0)}
                      marginTop={theme.spacing(1)}
                      style={{
                        backgroundColor: theme.palette.primary.light,
                        padding: theme.spacing(2),
                        borderRadius: 25,
                        outlineColor: "#3d3d44",
                        outlineStyle: "solid",
                        outlineWidth: 1,
                      }}
                    >
                      {intentEvents.length == 0 ? (
                        <>
                          <Box height="20px" />
                          <Box display='flex' width="100%" justifyContent='center' alignItems={'center'}>
                            <Typography color="textPrimary">No Results.</Typography>
                          </Box>
                        </>
                      ) : (
                        <>
                          {intentEvents.map((event: any) => (
                            <Grid
                              item
                              xs={
                                intentEvents.length < 4 ?
                                  (event == "Followed school" ? 4.35 :
                                    event == "High level of interest" ? 5.05 :
                                      event == "Connected with school" ? 5.4 :
                                        event == "Viewed profile" ? 4.1 :
                                          event == "In-state student" ? 4.2 :
                                            event == "Low level of interest" ? 5.1 :
                                              event == "Moderate level of interest" ? 5.75 :
                                                event == "Parent attended" ? 4.35 :
                                                  event == "Sibling attended" ? 4.4 :
                                                    event == "Friend attends" ? 4 :
                                                      event == "Has applied" ? 3.54 :
                                                        event == "Has been accepted" ? 4.75 :
                                                          event == "Is enrolled" ? 3.43 :
                                                            5) :
                                  (event == "Followed school" ? 3.3 :
                                    event == "High level of interest" ? 3.83 :
                                      event == "Connected with school" ? 4.05 :
                                        event == "Viewed profile" ? 3 :
                                          event == "In-state student" ? 3.2 :
                                            event == "Low level of interest" ? 3.8 :
                                              event == "Moderate level of interest" ? 4.38 :
                                                event == "Parent attended" ? 3.2 :
                                                  event == "Sibling attended" ? 3.25 :
                                                    event == "Friend attends" ? 3.05 :
                                                      event == "Has applied" ? 2.75 :
                                                        event == "Has been accepted" ? 3.63 :
                                                          event == "Is enrolled" ? 2.61 :
                                                            4)
                              }
                              display={'flex'}
                              alignItems={'center'}
                              justifyContent={"left"}
                            >
                              <Typography
                                fontSize={13.5}
                                fontWeight={600}
                                marginTop={theme.spacing(-1.4)}
                                paddingLeft={theme.spacing(3)}
                                paddingRight={theme.spacing(3)}
                                color="#fff"
                                bgcolor="#41414b"
                                display={'flex'}
                                alignItems={'center'}
                                justifyContent={'center'}
                                borderRadius={12}
                                height={32}
                              >
                                {event}
                              </Typography>
                            </Grid>
                          ))}
                        </>
                      )}
                    </Grid>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={6}
                  visibility={currentPage == "colleges_majors" ? "visible" : "hidden"}
                  maxHeight={currentPage == "colleges_majors" ? 1000 : 0}
                  style={{
                    opacity: currentPage == "colleges_majors" ? 1 : 0,
                    transition: "opacity .3s ease"
                  }}
                >
                  <Card style={{
                    backgroundColor: theme.palette.secondary.dark,
                    padding: theme.spacing(1),
                    borderRadius: 25,
                    outlineColor: "#37373d",
                    outlineStyle: "solid",
                    outlineWidth: 1,
                  }}>
                    <Box p={0} pt={2} pl={0} pb={2}>
                      <Typography style={{
                        marginLeft: theme.spacing(2.8),
                        marginBottom: theme.spacing(1),
                      }} align="left" variant="h6" color="textPrimary">
                        {data?.userContact?.userStudent?.type == "college_alumni" ? "Followed Colleges" : "Interested Colleges"}
                      </Typography>
                      {loadingColleges ? (
                        <Loading />
                      ) : (
                        <>
                          {props.showCollegeListToColleges ? (
                            <>
                              <Box height="20px" />
                              <Box display='flex' justifyContent='center'>
                                <Typography style={{ fontWeight: 400 }} color="textSecondary">Hidden by Student</Typography>
                              </Box>
                            </>
                          ) : (
                            <>
                              {generalColleges?.length === 0 ? (
                                <>
                                  <Box height="20px" />
                                  <Box display='flex' justifyContent='center'>
                                    <Typography color="textSecondary">No Results.</Typography>
                                  </Box>
                                </>
                              ) : (
                                <Container className={classes.resultsCollege}>
                                  <PerfectScrollbar>
                                    <Table>
                                      <TableBody className={classes.table}>
                                        {generalColleges.map((college: any) => (
                                          <CollegeRow
                                            id={college.id}
                                            name={college.get('name')}
                                            imgUrl={college.get('img')}
                                            interestLevel={highColleges.some((e: any) => e.id === college.id) ? "Highly Interested" : moderateColleges.some((e: any) => e.id === college.id) ? "Moderately Interested" : lowColleges.some((e: any) => e.id === college.id) ? "Mildly Interested" : "General Interest"}
                                            appliedColleges={data?.userContact?.userStudent?.appliedColleges ? data?.userContact?.userStudent?.appliedColleges.map((x) => x.value) : []}
                                            acceptedColleges={data?.userContact?.userStudent?.acceptedColleges ? data?.userContact?.userStudent?.acceptedColleges.map((x) => x.value) : []}
                                            enrolledColleges={data?.userContact?.userStudent?.enrolledColleges ? data?.userContact?.userStudent?.enrolledColleges.map((x) => x.value) : []}
                                          />
                                        ))}
                                      </TableBody>
                                    </Table>
                                  </PerfectScrollbar>
                                </Container>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </Box>
                  </Card>
                </Grid>
                <Grid
                  item
                  xs={6}
                  visibility={currentPage == "colleges_majors" ? "visible" : "hidden"}
                  maxHeight={currentPage == "colleges_majors" ? 1000 : 0}
                  style={{
                    opacity: currentPage == "colleges_majors" ? 1 : 0,
                    transition: "opacity .3s ease"
                  }}
                >
                  <Card style={{
                    backgroundColor: theme.palette.secondary.dark,
                    padding: theme.spacing(1),
                    borderRadius: 25,
                    outlineColor: "#37373d",
                    outlineStyle: "solid",
                    outlineWidth: 1,
                  }}>
                    <Box p={0} pt={2} pl={0} pb={2}>
                      <Typography style={{
                        marginLeft: theme.spacing(2.8),
                        marginBottom: theme.spacing(1),
                      }} align="left" variant="h6" color="textPrimary">
                        {data?.userContact?.userStudent?.type == "college_alumni" ? "Their Major" : "Interested Majors"}
                      </Typography>
                      {loadingMajors ? (
                        <Loading />
                      ) : (
                        <>
                          {props.showMajorsToColleges ? (
                            <>
                              <Box height="20px" />
                              <Box display='flex' justifyContent='center'>
                                <Typography style={{ fontWeight: 400 }} color="textSecondary">Hidden by Student</Typography>
                              </Box>
                            </>
                          ) : (
                            <>
                              {majors?.length === 0 ? (
                                <>
                                  <Box height="20px" />
                                  <Box display='flex' justifyContent='center'>
                                    <Typography color="textSecondary">No Results.</Typography>
                                  </Box>
                                </>
                              ) : (
                                <Container className={classes.resultsMajor}>
                                  <PerfectScrollbar>
                                    <Table>
                                      <TableBody className={classes.table}>
                                        {majors.map((major: any) => (
                                          <MajorRow
                                            id={major.obj.id}
                                            name={major.obj.get('name')}
                                            category={major.obj.get('category')}
                                            color={major.obj.get('color')}
                                          />
                                        ))}
                                      </TableBody>
                                    </Table>
                                  </PerfectScrollbar>
                                </Container>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </Box>
                  </Card>
                </Grid>
                <Grid
                  item
                  xs={6}
                  visibility={currentPage == "activities_awards" ? "visible" : "hidden"}
                  maxHeight={currentPage == "activities_awards" ? 1000 : 0}
                  style={{
                    opacity: currentPage == "activities_awards" ? 1 : 0,
                    transition: "opacity .3s ease",
                    marginTop: theme.spacing(-2)
                  }}
                >
                  <Card style={{
                    backgroundColor: theme.palette.secondary.dark,
                    padding: theme.spacing(1),
                    borderRadius: 25,
                    outlineColor: "#37373d",
                    outlineStyle: "solid",
                    outlineWidth: 1,
                  }}>
                    <Box p={0} pt={2} pl={0} pb={2}>
                      <Typography style={{
                        marginLeft: theme.spacing(2.8),
                        marginBottom: theme.spacing(1),
                      }} align="left" variant="h6" color="textPrimary">
                        Extra Curriculars
                      </Typography>
                      {loadingActivities ? (
                        <Loading />
                      ) : (
                        <>
                          {props.showActivitiesAndAwardsToColleges ? (
                            <>
                              <Box height="20px" />
                              <Box display='flex' justifyContent='center'>
                                <Typography style={{ fontWeight: 400 }} color="textSecondary">Hidden by Student</Typography>
                              </Box>
                            </>
                          ) : (
                            <>
                              {activities?.length === 0 ? (
                                <>
                                  <Box height="20px" />
                                  <Box display='flex' justifyContent='center'>
                                    <Typography color="textSecondary">No Results.</Typography>
                                  </Box>
                                  <Box height="20px" />
                                </>
                              ) : (
                                <Container className={classes.resultsActivity}>
                                  <PerfectScrollbar>
                                    <Table>
                                      <TableBody className={classes.table}>
                                        {activities.map((obj: any) => (
                                          <ActivityRow
                                            id={obj.obj.id}
                                            name={obj.obj.get('name')}
                                            organization={obj.obj.get('organization')}
                                            fromDate={format_birthday(obj.obj.get('fromDate'))}
                                            toDate={format_birthday(obj.obj.get('toDate'))}
                                            desc={obj.obj.get('desc')}
                                          />
                                        ))}
                                      </TableBody>
                                    </Table>
                                  </PerfectScrollbar>
                                </Container>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </Box>
                  </Card>
                </Grid>
                <Grid
                  item
                  xs={6}
                  visibility={currentPage == "activities_awards" ? "visible" : "hidden"}
                  maxHeight={currentPage == "activities_awards" ? 1000 : 0}
                  style={{
                    opacity: currentPage == "activities_awards" ? 1 : 0,
                    transition: "opacity .3s ease",
                    marginTop: theme.spacing(-2)
                  }}
                >
                  <Card style={{
                    backgroundColor: theme.palette.secondary.dark,
                    padding: theme.spacing(1),
                    borderRadius: 25,
                    outlineColor: "#37373d",
                    outlineStyle: "solid",
                    outlineWidth: 1,
                  }}>
                    <Box p={0} pt={2} pl={0} pb={2}>
                      <Typography style={{
                        marginLeft: theme.spacing(2.8),
                        marginBottom: theme.spacing(1),
                      }} align="left" variant="h6" color="textPrimary">
                        Awards
                      </Typography>
                      {loadingAwards ? (
                        <Loading />
                      ) : (
                        <>
                          {props.showActivitiesAndAwardsToColleges ? (
                            <>
                              <Box height="20px" />
                              <Box display='flex' justifyContent='center'>
                                <Typography style={{ fontWeight: 400 }} color="textSecondary">Hidden by Student</Typography>
                              </Box>
                            </>
                          ) : (
                            <>
                              {awards?.length === 0 ? (
                                <>
                                  <Box height="20px" />
                                  <Box display='flex' justifyContent='center'>
                                    <Typography color="textSecondary">No Results.</Typography>
                                  </Box>
                                  <Box height="20px" />
                                </>
                              ) : (
                                <Container className={classes.resultsActivity}>
                                  <PerfectScrollbar>
                                    <Table>
                                      <TableBody className={classes.table}>
                                        {awards.map((obj: any) => (
                                          <AwardRow
                                            id={obj.obj.id}
                                            name={obj.obj.get('name')}
                                            organization={obj.obj.get('organization')}
                                            dateAwarded={format_birthday(obj.obj.get('dateAwarded'))}
                                            desc={obj.obj.get('desc')}
                                          />
                                        ))}
                                      </TableBody>
                                    </Table>
                                  </PerfectScrollbar>
                                </Container>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </Box>
                  </Card>
                </Grid>
                <Grid
                  item
                  xs={9}
                  visibility={currentPage == "activity" ? "visible" : "hidden"}
                  maxHeight={currentPage == "activity" ? 1000 : 0}
                  style={{
                    opacity: currentPage == "activity" ? 1 : 0,
                    transition: "opacity .3s ease",
                    marginTop: theme.spacing(-2)
                  }}
                >
                  <Card style={{
                    backgroundColor: theme.palette.secondary.dark,
                    padding: theme.spacing(1),
                    borderRadius: 25,
                    outlineColor: "#37373d",
                    outlineStyle: "solid",
                    outlineWidth: 1,
                    marginLeft: theme.spacing(1)
                  }}>
                    <Box p={0} pt={2} pl={0} pb={2}>
                      <Typography
                        style={{
                          marginLeft: theme.spacing(2.8),
                          marginBottom: theme.spacing(1),
                        }}
                        align="left"
                        fontSize={23}
                        fontWeight={600}
                        color="textPrimary"
                      >
                        Engagement Activity
                      </Typography>
                      {loadingActivity ? (
                        <Loading />
                      ) : (
                        <>
                          {schoolActivities?.length === 0 ? (
                            <>
                              <Box height="20px" />
                              <Box display='flex' justifyContent='center'>
                                <Typography color="textSecondary">No Results.</Typography>
                              </Box>
                              <Box height="20px" />
                            </>
                          ) : (
                            <Container
                              className={classes.resultsActivity}
                              style={{
                                height: Math.min(schoolActivities.length * 78, 400)
                              }}
                            >
                              <PerfectScrollbar
                                className="scrollbar"
                              >
                                <Table>
                                  <TableBody className={classes.table}>
                                    {schoolActivities.map((obj: any) => (
                                      <SchoolActivityRow
                                        type={obj.type}
                                        actionDate={obj.actionDate}
                                        userUniversity={obj.userUniversity}
                                        content={obj.content}
                                      />
                                    ))}
                                  </TableBody>
                                </Table>
                              </PerfectScrollbar>
                            </Container>
                          )}
                        </>
                      )}
                    </Box>
                  </Card>
                </Grid>
                <Grid
                  item
                  xs={10}
                  visibility={currentPage == "notes" ? "visible" : "hidden"}
                  maxHeight={currentPage == "notes" ? 1000 : 0}
                  style={{
                    opacity: currentPage == "notes" ? 1 : 0,
                    transition: "opacity .3s ease",
                    marginTop: theme.spacing(-2)
                  }}
                >
                  <Box display='block' justifyContent="right">
                    <TextField
                      label="Student notes"
                      onChange={(e) => setNote(e.target.value)}
                      value={note}
                      sx={{
                        "& .MuiInputBase-root": {
                          backgroundColor: theme.palette.primary.light,
                          borderBottomRightRadius: 25,
                          borderBottomLeftRadius: 25,
                          borderTopRightRadius: 25,
                          borderTopLeftRadius: 25,
                          outlineColor: "#35353d",
                          outlineStyle: "solid",
                          outlineWidth: 1,
                          width: "100%"
                        },
                        "& .MuiInputBase-root.Mui-disabled": {
                          backgroundColor: theme.palette.primary.light
                        },
                        "&.MuiInputBase-root.Mui-hover": {
                          backgroundColor: "#FFF"
                        }
                      }}
                      variant="filled"
                      fullWidth
                      rows={8}
                      multiline
                      InputLabelProps={{
                        style: { marginLeft: 10 },
                      }}
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          paddingLeft: 23,
                          paddingRight: 23,
                        }
                      }}
                    />
                    {false ? (
                      <Loading small />
                    ) : (
                      <Button
                        className={classes.button}
                        variant="contained"
                        style={{
                          borderRadius: 12,
                          width: 160,
                          height: 40,
                          marginLeft: 'auto',
                          marginTop: theme.spacing(3),
                          float: "right"
                        }}
                        onClick={handleSaveNote}
                      >
                        Save
                      </Button>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Card>
          </Grid >
          < Grid item xs={12} >
            <Card style={{
              backgroundColor: theme.palette.primary.dark,
              padding: theme.spacing(4),
              borderRadius: 25,
              outlineColor: "#25252b",
              outlineStyle: "solid",
              outlineWidth: 1,
            }}>
              <Grid
                container
                spacing={2}
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <Typography align="left" style={{ fontWeight: 600, fontSize: 24 }} color="textPrimary">
                    Contact Info
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Card style={{
                    backgroundColor: theme.palette.secondary.dark,
                    padding: theme.spacing(1),
                    borderRadius: 25,
                    outlineColor: "#37373d",
                    outlineStyle: "solid",
                    outlineWidth: 1,
                  }}>
                    <Box p={1} pt={2}>
                      <Typography variant="h6" color="textPrimary" align='center'>
                        {(props.showContactInfoToColleges ? format_phone(data?.userContact?.phoneMobile) ?? "None" : "Hidden by Student")}
                      </Typography>
                    </Box>
                    <Box p={1} pb={2}>
                      <Typography variant="body1" color="textSecondary" align='center'>
                        Phone Number
                      </Typography>
                    </Box>
                  </Card>
                </Grid>

                <Grid item xs={6}>
                  <Card style={{
                    backgroundColor: theme.palette.secondary.dark,
                    padding: theme.spacing(1),
                    borderRadius: 25,
                    outlineColor: "#37373d",
                    outlineStyle: "solid",
                    outlineWidth: 1,
                  }}>
                    <Box p={1} pt={2}>
                      <Typography variant="h6" color="textPrimary" align='center'>
                        {(props.showContactInfoToColleges ? data?.userContact?.email?.toLowerCase() : "Hidden by Student")}
                      </Typography>
                    </Box>
                    <Box p={1} pb={2}>
                      <Typography variant="body1" color="textSecondary" align='center'>
                        Email
                      </Typography>
                    </Box>
                  </Card>
                </Grid>
              </Grid>
            </Card>
          </Grid >
        </Grid >
      </FadeIn>
    </Page >
  );
}
