import React, { useEffect, useState, useContext, createContext } from "react";
import Parse from "parse";
import { Routes, Route, useNavigate } from "react-router-dom";
import { Container, Typography, Box, Grid } from "@mui/material";
import { useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import NavBar from "../../components/NavBar";
import SideBar from "../../components/SideBar";
import { Loading } from "../../components/Loading";
import CollegeProfile from "./CollegeProfile/CollegeProfile";
import Settings from "./Settings/Settings";
import Billing from "./Billing/Billing";
import DashboardHome from "./DashboardHome/DashboardHome";
import FavoritedStudents from "./FavoritedStudents/FavoritedStudents";
import AccountsManagement from "./AccountsManagement/AccountsManagement";
import { Posts, Post, Ads, Ad } from "./Social";
import { Thread, Threads, CreateThread } from "./Messaging";
import { Campaigns } from "./Campaigns";
import { Search } from "./Search";
import { Profile } from "./Profile";
import { Forum, Forums } from "./Forums";
import { useAuth } from "../../hooks/useAuth";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

const extraPadding = 0;

const useStyles = makeStyles((theme: any) => ({
  root: {
    backgroundColor: "#0e0e14",
    height: "100%",
    overflow: "hidden",
  },
  wrapper: {
    paddingTop: 64,
    paddingLeft: 256 + extraPadding,
    paddingRight: extraPadding,
  },
}));

const FETCH_USER = gql`
  query fetchUser($id: ID!) {
    user(id: $id) {
      id
      objectId
      userContact {
        id
        objectId
        firstName
        lastName
        img {
          url
        }
        notifications {
          edges {
            node {
              id
              objectId
              createdAt
              primary
              secondary
              link
              read
            }
          }
        }
        followedTags {
          edges {
            node {
              id
              objectId
              tag
              color
            }
          }
        }
      }
      userUniversity {
        id
        objectId
        jobTitle
        linkedCollege {
          id
          objectId
          name
          state
          city
        }
      }
    }
    roles(where: { users: { have: { objectId: { equalTo: $id } } } }) {
      edges {
        node {
          id
          objectId
          name
        }
      }
    }
  }
`;

export default function Dashboard() {
  const theme = useTheme();
  const classes = useStyles(theme);

  const auth = useAuth();
  const id = Parse.User.current()?.id;
  const { data, loading, error } = useQuery(FETCH_USER, {
    variables: { id },
  });
  const [openSidebar, setOpenSidebar] = useState(true);
  const [roles, setRoles] = useState<any[]>([]);
  const [tier, setTier] = useState<any>('free');
  const navigate = useNavigate();

  const checkRoles = (name) => {
    if (roles.length === 0) {
      return false;
    }
    if (roles.indexOf('ADMIN') > -1) {
      return true;
    }
    if (roles.indexOf(name) > -1) {
      return true;
    }
    return false;
  };

  const setTierHook = () => {
    setTier(auth.tier);
  };

  const getTier = async (collegeId) => {
    if (collegeId) {
      Parse.Cloud.run("getTier", {
        collegeId: collegeId,
      }).then((response) => {
        setTier(response.get('tier'));
        auth.setTier(response.get('tier'));
      })
    }
  }

  useEffect(() => {
    if (data?.roles) {
      var userRoles: string[] = [];
      data?.roles?.edges.forEach((edge: any) => {
        if (edge.node.name.length > 11) {
          userRoles.push(edge.node.name.substring(11));
        }
      });
      setRoles(userRoles);
    }
    if (data?.user) {
      auth.setCollegeId(data?.user?.userUniversity?.linkedCollege?.objectId);
      auth.setCollege(data?.user?.userUniversity?.linkedCollege);

      getTier(data?.user?.userUniversity?.linkedCollege?.objectId);
    }

  }, [data]);

  useEffect(() => {
    document.body.style.backgroundColor = "#0e0e14";
  }, []);

  if (error) console.log(JSON.stringify(error, null, 2));

  if (!Parse.User.current()?.id) {
    return <>{navigate("/")}</>
  }

  return (
    <>
      <div 
        className={classes.root}
      >
        <NavBar
          avatar={data?.user?.userContact?.img?.url}
          notifications={data?.user?.userContact?.notifications?.edges?.map(
            (n) => n.node
          )}
        />
        <SideBar
          avatar={data?.user?.userContact?.img?.url}
          name={[
            data?.user?.userContact?.firstName,
            data?.user?.userContact?.lastName,
          ].join(" ")}
          title={data?.user?.userUniversity?.jobTitle}
          open={openSidebar}
          checkRoles={checkRoles}
          roles={roles}
          tier={tier}
        />
        <Grid
          container
          style={{
            marginLeft: 258,
            width: `calc(100% - ${258}px)`,
          }}
        >
          <Grid
            item
            xs={12}
            style={{
              marginTop: 73,
            }}
          >
            <Routes>
              <Route path="*" element={<DashboardHome />} />
              <Route path="/favorites" element={<FavoritedStudents />} />
              {checkRoles("ADMIN") && (
                <Route
                  path="/accounts"
                  element={<AccountsManagement />}
                />
              )}
              {checkRoles("BILLING") && (
                <Route path="/billing" element={<Billing setTierHook={setTierHook} />} />
              )}
              {checkRoles("PROFILE") && (
                <Route
                  path="/profile"
                  element={<CollegeProfile />}
                />
              )}
              {checkRoles("SOCIAL") && (
                <>
                  <Route path="/social/posts"
                    element={<Posts hasAdsPermission={checkRoles("ADS")} />} />
                  <Route
                    path="/social/posts/:id"
                    element={<Post />}
                  />
                </>
              )}
              {checkRoles("ADS") && (
                <>
                  <Route path="/social/ads"
                    element={<Ads hasSocialPermission={checkRoles("SOCIAL")} />} />
                  <Route path="/social/ads/:id" element={<Ad />} />
                </>
              )}
              {checkRoles("FORUMS") && (
                <>
                  <Route path="/forums"
                    element={<Forums tags={data?.user?.userContact?.followedTags?.edges} />} />
                  <Route path="/forums/:id" element={<Forum />} />
                </>
              )}
              {checkRoles("CAMPAIGNS") && (
                <>
                  <Route path="/campaigns" element={<Campaigns />} />
                </>
              )}
              {checkRoles("MESSAGING") && (
                <>
                  <Route path="/messages" element={<Threads />} />
                  <Route
                    path="/messages/create/:id"
                    element={<CreateThread />}
                  />
                  <Route path="/messages/:id" element={<Thread />} />
                </>
              )}
              <Route path="/settings" element={<Settings />} />
              <Route path="/search"
                element={<Search hasMessagingPermission={checkRoles("MESSAGING")} />} />
              <Route path="/profiles/:id"
                element={<Profile hasMessagingPermission={checkRoles("MESSAGING")} />} />
            </Routes>
          </Grid>
        </Grid>
      </div>
    </>
  );
}