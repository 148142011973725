import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';

import Logo from '../../Components/logo';
import { bgBlur } from '../../Theme/css';
import { paths } from '../../Routes/paths';
import { useOffSetTop } from '../../Hooks/use-off-set-top';
import { useResponsive } from '../../Hooks/use-responsive';

import { HEADER } from '../config-layout';
import HeaderShadow from '../common/header-shadow';

import NavMobile from './nav/mobile';
import NavDesktop from './nav/desktop';
import { navConfig } from './config-navigation';

// ----------------------------------------------------------------------

type Props = {
  headerOnDark: boolean;
};

export default function Header({ headerOnDark }: Props) {
  const theme = useTheme();

  const offset = useOffSetTop();

  const mdUp = useResponsive('up', 'md');

  return (
    <AppBar>
      <Toolbar
        style={{zIndex: 2}}
        disableGutters
        sx={{
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_DESKTOP,
          },
          transition: theme.transitions.create(['height', 'background-color'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          ...(headerOnDark && {
            color: 'common.white',
          }),
          ...(offset && {
            ...bgBlur({ color: theme.palette.background.default }),
            boxShadow: 4,
            color: 'common.primary',
            height: {
              md: HEADER.H_DESKTOP - 16,
            },
          }),
        }}
      >
        <Container
          sx={{ height: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
        >
          <Box sx={{ lineHeight: 0, position: 'relative' }}>
            <Logo />

            <Link href="https://zone-docs.vercel.app/changelog" target="_blank" rel="noopener">
              {/* <Label
                color="info"
                sx={{
                  ml: 0.5,
                  px: 0.5,
                  top: -14,
                  left: 60,
                  height: 20,
                  fontSize: 11,
                  cursor: 'pointer',
                  position: 'absolute',
                }}
              >
                v2.1.0
              </Label> */}
            </Link>
          </Box>

          {mdUp && <NavDesktop data={navConfig} headerOnDark={headerOnDark} />}

          <Stack spacing={2} direction="row" alignItems="center" justifyContent="flex-end">
            {/* <Stack spacing={1} direction="row" alignItems="center">
              <Searchbar />

              <SettingsButton />
            </Stack> */}

            {mdUp && (
              <>
                <Button
                  variant="contained"
                  color="inherit"
                  href={paths.signin}
                  rel="noopener"
                  style={{ color: headerOnDark ? offset ? "#000" : "#fff" : "#000" }}
                  sx={{ backgroundColor: 'transparent', height: 40, borderRadius: 12 }}
                >
                  Sign In
                </Button>

                <Button
                  variant="contained"
                  color="inherit"
                  href={paths.register}
                  rel="noopener"
                  sx={{ paddingRight: 3, paddingLeft: 3, background: `linear-gradient(to right, #35c669, #2ec4a9)`, height: 40, borderRadius: 12 }}
                  >
                  Claim Your School
                </Button>
              </>
            )}
          </Stack>

          {!mdUp && <NavMobile data={navConfig} />}
        </Container>
      </Toolbar>

      {offset && <HeaderShadow />}
    </AppBar>
  );
}
