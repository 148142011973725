import React, { useEffect, useState } from "react";
import Parse from "parse";
import {
  useTheme,
  Card,
  Grid,
  Box,
  IconButton,
  Typography,
} from "@mui/material";
import { makeStyles } from '@mui/styles';
import AddIcon from "@material-ui/icons/Add";
import { Input, Modal, Button } from "../../../components";
import { useAuth } from "../../../hooks/useAuth";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme: any) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  card: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  button: {
    background: "linear-gradient(75deg, #35c669, #2ec4a9)",
    border: 0,
    color: "#fff",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: theme.spacing(3),
  },
  title: {
    fontSize: 30,
    fontWeight: 600,
    color: "#fff"
  },
  subtitle: {
    fontSize: 13,
    fontWeight: 400,
    color: "#c6c9d3",
    textAlign: "left",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(4),
  },
  deactivate: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.text.primary,
  },
  tableCell: {
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

export default function AddUserButton(props) {
  const theme = useTheme();
  const classes = useStyles(theme);

  const auth = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [title, setTitle] = useState("");

  useEffect(() => {
    if (open == false) {
      setTimeout(function () {
        setEmail("");
        setTitle("");
      }, 200);
    }

    if (open == true && props.numUsers >= 3 && auth.tier == "free") {
      setOpen(false);

      enqueueSnackbar("Oops! Please upgrade to Premium to add more users", {
        variant: "error",
      });
    }

  }, [open]);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (props.numUsers >= 3 && auth.tier == "free") {
      enqueueSnackbar("Oops! Please upgrade to Premium to add more users", {
        variant: "error",
      });

    } else {
      Parse.Cloud.run("inviteUser", {
        email: email,
        title: title,
      }).then(
        (result) => {
          if (result === true) {
            enqueueSnackbar("Successfully invited new user.", {
              variant: "success",
            });
          }
        },
        (error) => {
          enqueueSnackbar(error.toString(), {
            variant: "error",
          });
        }
      );
    }

    setOpen(false);
  };

  return (
    <>
      <IconButton
        size="large"
        className={classes.button}
        onClick={() => setOpen(true)}
      >
        <AddIcon />
      </IconButton>
      <Modal
        open={open}
        setOpen={setOpen}
        cardColor="#090910"
        title={<Typography style={{ fontSize: 30, fontWeight: 600, color: "#fff" }}>Invite New User</Typography>}
        sub={auth.collegeName}
        noScroll
      >
        <Box maxWidth={440}>
          <form onSubmit={(e) => handleSubmit(e)}>
            <Grid container spacing={2} sx={{ alignSelf: "center" }}>
              <Grid item xs={12}>
                <Input
                  label="Email Address"
                  required
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  label="Job Title"
                  value={title}
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                  fullWidth
                />
              </Grid>
              {props.numUsers >= 3 && props.costPerUser > 0 ? (
                <Grid item xs={12}>
                  <Typography style={{
                    fontSize: 13,
                    fontWeight: 400,
                    color: "#c6c9d3",
                    textAlign: "left",
                  }} color="textSecondary">*Note: A user fee of ${props.costPerUser}/mo. will be added to the card on file for each additional user. This subscription will only start once the user has claimed their account and will be canceled upon deactivation of the account.</Typography>
                </Grid>
              ) : (
                <>
                </>
              )}
              <Grid item xs={12} pt={3}>
                <Box justifyContent='right'>
                  <Box display="flex" justifyContent="right">
                    <Button type="submit">Send</Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
    </>
  );
}
