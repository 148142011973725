import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Box,
  Menu,
  MenuItem,
  IconButton,
  SvgIcon,
  Modal,
  Fade,
  Backdrop,
  Input,
  Typography,
  Avatar,
  List,
  Tooltip
} from "@mui/material";
import FadeIn from "react-fade-in";
import { useTheme } from '@mui/material';
import Parse from "parse";
import { makeStyles } from '@mui/styles';
import { useRef, useEffect, useState } from "react";
import MenuIcon from "@material-ui/icons/Menu";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { gql, useQuery } from "@apollo/client";
import { useAuth } from "../../../../hooks/useAuth";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Loading,
  Modal as CustomModal,
} from "../../../../components";
import { Link } from "react-router-dom";
import { Search as SearchIcon } from "react-feather";
import CloseIcon from "@material-ui/icons/Close";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

const useStyles = makeStyles((theme: any) => ({
  card: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    height: 315,
    paddingBottom: theme.spacing(14),
    overflowY: "hidden",
    borderRadius: 15,
    outlineColor: "#2c2c33",
    outlineStyle: "solid",
    outlineWidth: 1,
    boxShadow: theme.shadows[4],
  },
  grid: {
    flexGrow: 1,
  },
  popover: {
    backgroundColor: theme.palette.secondary.dark,
    borderRadius: 11,
    outlineColor: "#2c2c33",
    outlineStyle: "solid",
    outlineWidth: 1,
  },
  avatar: {
    height: 42,
    width: 42,
    top: 2,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    backgroundColor: "#1d1d2b",
  },
  paper: {
    maxHeight: 690,
    overflowY: "auto",
    overflowX: "hidden",
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(4),
    borderRadius: 20,
    outlineColor: "#2c2c33",
    outlineStyle: "solid",
    outlineWidth: 1,
  },
  boxShadow: theme.shadows[4],
  itemCard: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    boxShadow: theme.shadows[4],
    display: "grid",
    wrap: 'nowrap',
    backgroundColor: theme.palette.primary.light,
    height: 150,
    width: 180,
    borderRadius: 25,
    outlineColor: "#37373f",
    outlineStyle: "solid",
    outlineWidth: 1,
  },
  // grid: {
  //   paddingRight: theme.spacing(2),
  //   paddingLeft: theme.spacing(2),
  // },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: theme.spacing(3),
  },
  statTitles: {
    display: "grid",
    fontSize: 14,
    textAlign: 'left',
    width: 100,
    marginTop: theme.spacing(0),
    marginLeft: theme.spacing(3),
    marginBottom: theme.spacing(0),
  },
  statValues: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(1),
    display: "flex",
    fontSize: 40,
    fontWeight: 500,
    align: 'left',
    width: 70,
    height: 57,
  },
  viewButtonArrowContiner: {
    marginLeft: theme.spacing(3),
    marginBottom: theme.spacing(1),
    display: "flex",
    alignItems: 'center',
    "&:hover": {
      opacity: 0.4,
      cursor: "pointer"
    },
  },
  viewButton: {
    fontSize: 11,
    fontWeight: 500,
    textAlign: 'right',
    display: "flex",
    color: "#32a852",
  },
  rightArrow: {
    display: "flex",
    color: "#32a852",
  },
  search: {
    display: "flex",
    alignItems: "center",
    height: 44,
    borderRadius: 10,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    backgroundColor: theme.palette.primary.light,
    outlineColor: "#2c2c33",
    outlineStyle: "solid",
    outlineWidth: 1,
  },
  searchInput: {
    flexGrow: 1,
    marginLeft: theme.spacing(1),
  },
  cardAction: {
    width: "auto",
    height: "auto",
    display: "block",
  },
  results: {
    overflowY: "auto",
    height: 300,
  },
  person: {
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(1),
    height: 47,
    display: "flex",
    "&:hover": {
      backgroundColor: theme.palette.primary.lightHover,
      cursor: "pointer"
    },
  },
  titleFont: {
    fontWeight: 500
  },
}));

const GET_ENGAGEMENT_STATS = gql`
  query getEngagementStats($id: ID!, $date: Date) {
    timeframe: interestedStudentsOverTimes(
      first: 400
      where: {
        college: { have: { objectId: { equalTo: $id } } }
        createdAt: { greaterThanOrEqualTo: $date }
      }
      order: createdAt_ASC
    ) {
      count
      edges {
        node {
          id
          objectId
          createdAt
          connects(
            first: 400
            where: {
              gender: { exists: true }
            }
          ) {
            edges {
              node {
                id
                objectId
                verificationStatus
                appliedColleges {
                  ... on Element {
                    value
                  }
                }
                acceptedColleges {
                  ... on Element {
                    value
                  }
                }
                enrolledColleges {
                  ... on Element {
                    value
                  }
                }
                userContact {
                  id
                  objectId
                  fullName
                  img {
                    url
                  }
                }
                user {
                  id
                  username
                }
              }
            }
          }
          studentProfileVisits(
            first: 400
            where: {
              gender: { exists: true }
            }
          ) {
            edges {
              node {
                id
                objectId
                verificationStatus
                appliedColleges {
                  ... on Element {
                    value
                  }
                }
                acceptedColleges {
                  ... on Element {
                    value
                  }
                }
                enrolledColleges {
                  ... on Element {
                    value
                  }
                }
                userContact {
                  id
                  objectId
                  fullName
                  img {
                    url
                  }
                }
                user {
                  id
                  username
                }
              }
            }
          }
          newInterestedStudents(
            first: 400
            where: {
              gender: { exists: true }
            }
          ) {
            edges {
              node {
                id
                objectId
                verificationStatus
                appliedColleges {
                  ... on Element {
                    value
                  }
                }
                acceptedColleges {
                  ... on Element {
                    value
                  }
                }
                enrolledColleges {
                  ... on Element {
                    value
                  }
                }
                userContact {
                  id
                  objectId
                  fullName
                  img {
                    url
                  }
                }
                user {
                  id
                  username
                }
              }
            }
          }
        }
      }
    }
  }
`;

const Person = ({ person }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const auth = useAuth();

  const capitalize_each_word = (str) => {
    if (!str) {
      return ""
    }

    const arr = str.split(" ");

    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }

    const str2 = arr.join(" ");

    return str2
  }

  return (
    <Grid item xs={12}>
      <Link
        className={classes.cardAction}
        to={`/dashboard/profiles/${person?.userContact?.objectId}`}
        style={{ textDecoration: "none" }}
      >
        <Card
          sx={{
            "&:hover": {
              backgroundColor: theme.palette.primary.lighter,
              cursor: "pointer"
            },
          }}
          style={{
            backgroundColor: theme.palette.secondary.darker,
            padding: theme.spacing(1),
            height: 47,
            display: "flex",
          }}>
          <Avatar
            alt={person?.userContact?.fullName}
            src={person?.userContact?.img?.url}
            className={classes.avatar}
          />
          <Box sx={{ alignSelf: "left" }} ml={1}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: 'transparent',
              }}
            >
              <Typography variant="body1" fontWeight={600}>
                {capitalize_each_word(person?.userContact?.fullName)}
              </Typography>
              {person?.verificationStatus == "verified" ? (
                <img
                  height={17}
                  width={17}
                  src={"/assets/icons/ic_verify.png"}
                  style={{
                    visibility: person?.verificationStatus == "verified" ? "visible" : "hidden",
                    marginLeft: theme.spacing(0.5),
                  }}
                />
              ) : (<></>)}
              {person?.appliedColleges || person?.acceptedColleges || person?.enrolledColleges ? (
                <Tooltip
                  title={person?.appliedColleges.map((x) => x.value).includes(auth.collegeId) ? "Applied" : person?.acceptedColleges.map((x) => x.value).includes(auth.collegeId) ? "Accepted" : person?.enrolledColleges.map((x) => x.value).includes(auth.collegeId) ? "Enrolled" : "Not Applied"}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: "#46464f",
                        color: "white"
                      }
                    }
                  }}
                >
                  <img
                    height={14}
                    width={14}
                    src={person?.appliedColleges.map((x) => x.value).includes(auth.collegeId) ? "/assets/icons/ic_applied.svg" : person?.acceptedColleges.map((x) => x.value).includes(auth.collegeId) ? "/assets/icons/ic_accepted.svg" : person?.enrolledColleges.map((x) => x.value).includes(auth.collegeId) ? "/assets/icons/ic_enrolled.svg" : "/assets/icons/ic_applied.svg"}
                    style={{
                      visibility: person?.appliedColleges.map((x) => x.value).includes(auth.collegeId) || person?.acceptedColleges.map((x) => x.value).includes(auth.collegeId) || person?.enrolledColleges.map((x) => x.value).includes(auth.collegeId) ? "visible" : "hidden",
                      marginLeft: theme.spacing(0.5),
                    }}
                  />
                </Tooltip>
              ) : (<></>)}
            </div>
            <Typography variant="body2" color="textSecondary">
              {person.user?.username}
            </Typography>
          </Box>
        </Card>
        <Box height="10px" />
      </Link>
    </Grid>
  );
};

const ViewStudents = (props) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [query, setQuery] = useState("");
  const [searchData, setSearchData] = useState([]);

  useEffect(() => {
    setSearchData(props.data?.filter((n) => n.node?.userContact?.fullName.toLowerCase().includes(query.toLowerCase())));
  }, [query]);

  return (
    <Modal
      className={classes.modal}
      open={props.open}
      onClose={() => props.setOpen(false)}
      closeAfterTransition
    >
      <Fade in={props.open}>
        <Card style={{
          maxHeight: 690,
          overflowY: "auto",
          overflowX: "hidden",
          backgroundColor: theme.palette.primary.dark,
          display: "flex",
          flexDirection: "column",
          padding: theme.spacing(4),
          borderRadius: 25,
          outlineColor: "#2c2c33",
          outlineStyle: "solid",
          outlineWidth: 1,
        }}>
          <Box width="600px">
            <Grid container sx={{ alignSelf: "left" }}>
              <Typography style={{ fontWeight: 600, fontSize: 29 }} align="left" color="textPrimary">
                {props.title}
              </Typography>
              <Box flexGrow={1} />
              <IconButton style={{ width: 40, height: 40 }} onClick={() => props.setOpen(false)}>
                <CloseIcon />
              </IconButton>
            </Grid>
            <Box width="20px" />
            {query != "" ? (
              <Typography variant="body2" align="left" color="textSecondary">
                {"(" + searchData.length + " results)"}
              </Typography>
            ) : (
              <Typography variant="body2" align="left" color="textSecondary">
                {"(" + props.data?.length + " students)"}
              </Typography>
            )}
            <Box height="20px" />
            <div className={classes.search}>
              <SvgIcon fontSize="small" color="action">
                <SearchIcon />
              </SvgIcon>
              <Input
                className={classes.searchInput}
                disableUnderline
                placeholder="Search by name"
                value={query}
                onChange={(e) => {
                  setQuery(e.target.value);
                }}
              />
            </div>
            <Box height="15px" />
            <Grid item xs={12}>
              {false ? <Loading /> : <></>}
              {props.data && props.data?.length > 0 ?
                <div className={classes.results} style={{ height: Math.min(((searchData?.length * 47) + 25), 300) }}>
                  {query != "" ? (
                    <>
                      <PerfectScrollbar>
                        <List>
                          {searchData?.map((p: any) => (
                            <Person key={p.node.id} person={p.node} />
                          ))}
                        </List>
                        {searchData?.length === 0 && (
                          <Box sx={{ alignSelf: "center" }}>
                            <Typography>No results.</Typography>
                          </Box>
                        )}
                      </PerfectScrollbar>
                    </>
                  ) : (
                    <>
                      <PerfectScrollbar>
                        <List>
                          {props.data?.map((p) => (
                            <Person key={p.node.id} person={p.node} />
                          ))}
                        </List>
                        {props.data?.length === 0 && (
                          <Box sx={{ alignSelf: "center" }}>
                            <Typography>No results.</Typography>
                          </Box>
                        )}
                      </PerfectScrollbar>
                    </>
                  )}
                </div>
                : <></>}
            </Grid>
          </Box>
        </Card>
      </Fade>
    </Modal >
  );
};

export default function Stats(props) {
  const theme = useTheme();
  const classes = useStyles(theme);

  const auth = useAuth();
  const popoverRef = useRef(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [anchor, setAnchor] = useState(null);
  const [sub, setSub] = useState("This Month");
  const [date, setDate] = useState<any>(undefined);
  const [displayData, setDisplayData] = useState<any>({});
  const [openProfileVisits, setOpenProfileVisits] = useState(false);
  const [openConnects, setOpenConnects] = useState(false);
  const [openNewStudents, setOpenNewStudents] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [connects, setConnects] = useState([]);
  const [newInterestedStudents, setNewInterestedStudents] = useState([]);
  const [studentProfileVisits, setStudentProfileVisits] = useState([]);

  const options = [
    {
      label: "Today",
      value: "today",
    },
    {
      label: "This Week",
      value: "lastWeek",
    },
    {
      label: "This Month",
      value: "lastMonth",
    },
    {
      label: "This Year",
      value: "lastYear",
    },
  ];

  const { data, loading, error } = useQuery(GET_ENGAGEMENT_STATS, {
    variables: {
      id: auth.collegeId,
      date: date,
    },
  });

  // if (error) console.log(JSON.stringify(error, null, 2));

  const handleDateChange = (option) => {
    setOpenMenu(false);
    setSub(option.label);
    let d = new Date();
    if (option.value === "today") {
      d.setDate(d.getDate() - 1);
    }
    if (option.value === "lastWeek") {
      d.setDate(d.getDate() - 7);
    }
    if (option.value === "lastMonth") {
      d.setMonth(d.getMonth() - 1);
    }
    if (option.value === "lastYear") {
      d.setFullYear(d.getFullYear() - 1);
    }
    setDate(d);
    setIsLoading(true);
  };

  useEffect(() => {
    let d = new Date();
    d.setMonth(d.getMonth() - 1);
    setDate(d);
  }, []);

  const removeDuplicates = (array, key) => {
    return array.reduce((arr, item) => {
      const removed = arr.filter(i => i.node[key] !== item.node[key]);
      return [...removed, item];
    }, []);
  };

  const showData = async () => {
    await Parse.Cloud.run("dashboardLeads", { collegeId: auth.collegeId }).then((response) => {
      const studentLeadIds = response.map((e) => e.id);

      const connects = removeDuplicates([].concat.apply([], data?.timeframe?.edges?.map((e) => e.node?.connects?.edges)), "objectId").filter((x) => studentLeadIds.includes(x.node.objectId));
      const newInterestedStudents = removeDuplicates([].concat.apply([], data?.timeframe?.edges?.map((e) => e.node?.newInterestedStudents?.edges)), "objectId").filter((x) => studentLeadIds.includes(x.node.objectId));
      const studentProfileVisits = removeDuplicates([].concat.apply([], data?.timeframe?.edges?.map((e) => e.node?.studentProfileVisits?.edges)), "objectId").filter((x) => studentLeadIds.includes(x.node.objectId));

      setConnects(connects);
      setNewInterestedStudents(newInterestedStudents);
      setStudentProfileVisits(studentProfileVisits);

      const numInterested = newInterestedStudents.length;
      const numConnects = connects.length;
      const numVisits = studentProfileVisits.length;

      var calculations = {
        newConnects: numConnects,
        newInterested: numInterested,
        newVisits: numVisits,
      };
      for (const [key, val] of Object.entries(calculations)) {
        calculations[key] = Math.abs(val);
        if (isNaN(val) || !isFinite(val)) {
          calculations[key] = 0;
        }
      }
      setDisplayData(calculations);
      setIsLoading(false);
    });
  }

  useEffect(() => {
    if (data) {
      showData();
    }
  }, [data]);

  return (
    <FadeIn delay={300}>
      <Card style={{
        backgroundColor: theme.palette.primary.dark,
        padding: theme.spacing(2),
        height: 210,
        paddingBottom: theme.spacing(14),
        overflowY: "hidden",
        borderRadius: 25,
        outlineColor: "#2c2c33",
        outlineStyle: "solid",
        outlineWidth: 1,
        boxShadow: theme.shadows[4],
      }}>
        <CardHeader
          title="Student Leads Engagement"
          titleTypographyProps={{ fontWeight: 600 }}
          subheader={sub}
          action={
            <>
              <IconButton
                ref={popoverRef}
                onClick={(e: any) => {
                  setOpenMenu(true);
                  setAnchor(e.currentTarget);
                }}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="post-menu"
                anchorEl={anchor}
                open={openMenu}
                slotProps={{
                  paper: {
                    sx: {
                      backgroundColor: theme.palette.primary.dark,
                      borderRadius: 1.5,
                      outlineColor: "#3c3c44",
                      outlineStyle: "solid",
                      outlineWidth: 1,
                    }
                  }
                }}
                onClose={() => setOpenMenu(false)}
              >
                {options.map((option) => (
                  <MenuItem onClick={() => handleDateChange(option)}>
                    {option.label}
                  </MenuItem>
                ))}
              </Menu>
            </>
          }
        />
        <CardContent>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <Box height="5px" />
              <Grid container spacing={3} className={classes.grid}>
                <Grid item md={4} xs={12} display='flex' justifyContent='center'>
                  <Card style={{
                    paddingLeft: theme.spacing(1),
                    paddingRight: theme.spacing(1),
                    boxShadow: theme.shadows[4],
                    display: "grid",
                    flexWrap: 'nowrap',
                    backgroundColor: theme.palette.secondary.dark,
                    height: 150,
                    width: 165,
                    borderRadius: 25,
                    outlineColor: "#37373f",
                    outlineStyle: "solid",
                    outlineWidth: 1,
                  }}>
                    {/* <Box display="flex" justifyContent="center">
                      {displayData?.newVisitsPct >= 0 ? (
                        <ArrowUpwardIcon />
                      ) : (
                        <ArrowDownwardIcon />
                      )}
                      <Box width="5px" />
                      <Typography
                        variant="h6"
                        align="center"
                        color="textSecondary"
                      >
                        {displayData?.newVisitsPct?.toFixed(1) + "%"}
                      </Typography>
                    </Box> */}
                    <Typography color="secondary" style={{
                      marginTop: theme.spacing(1),
                      marginBottom: theme.spacing(0),
                      marginLeft: theme.spacing(3),
                      marginRight: theme.spacing(1),
                      display: "flex",
                      fontSize: 40,
                      fontWeight: 600,
                      alignSelf: 'left',
                      width: 70,
                      height: 57,
                    }}>
                      {displayData?.newVisits
                        ?.toString()
                        ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0}
                    </Typography>
                    <Typography style={{
                      display: "grid",
                      fontSize: 14,
                      textAlign: 'left',
                      width: 100,
                      marginTop: theme.spacing(0),
                      marginLeft: theme.spacing(3),
                      marginBottom: theme.spacing(0),
                    }} color="textSecondary">
                      New Profile Visits
                    </Typography>
                    <Link
                      to={`/dashboard/`}
                      onClick={() => setOpenProfileVisits(true)}>
                      <div className={classes.viewButtonArrowContiner} >
                        <Typography style={{
                          fontSize: 11,
                          fontWeight: 800,
                          textAlign: 'right',
                          display: "flex",
                          color: "#32a852",
                        }}>
                          VIEW
                        </Typography>
                        <ChevronRightIcon className={classes.rightArrow} />
                      </div>
                    </Link>
                  </Card>
                </Grid>
                <ViewStudents
                  setOpen={setOpenProfileVisits}
                  open={openProfileVisits}
                  title="New Profile Visits"
                  data={studentProfileVisits}
                />
                <Grid item md={4} xs={12} display='flex' justifyContent='center'>
                  <Card style={{
                    paddingLeft: theme.spacing(1),
                    paddingRight: theme.spacing(1),
                    boxShadow: theme.shadows[4],
                    display: "grid",
                    flexWrap: 'nowrap',
                    backgroundColor: theme.palette.secondary.dark,
                    height: 150,
                    width: 165,
                    borderRadius: 25,
                    outlineColor: "#37373f",
                    outlineStyle: "solid",
                    outlineWidth: 1,
                  }}>
                    {/* <Box display="flex" justifyContent="center">
                      {displayData?.newInterestedPct >= 0 ? (
                        <ArrowUpwardIcon />
                      ) : (
                        <ArrowDownwardIcon />
                      )}
                      <Box width="5px" />
                      <Typography
                        variant="h6"
                        align="center"
                        color="textSecondary"
                      >
                        {displayData?.newInterestedPct?.toFixed(1) + "%"}
                      </Typography>
                    </Box> */}
                    <Typography color="secondary" style={{
                      marginTop: theme.spacing(1),
                      marginBottom: theme.spacing(0),
                      marginLeft: theme.spacing(3),
                      marginRight: theme.spacing(1),
                      display: "flex",
                      fontSize: 40,
                      fontWeight: 600,
                      alignSelf: 'left',
                      width: 70,
                      height: 57,
                    }}>
                      {displayData?.newInterested
                        ?.toString()
                        ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0}
                    </Typography>
                    <Typography style={{
                      display: "grid",
                      fontSize: 14,
                      textAlign: 'left',
                      width: 100,
                      marginTop: theme.spacing(0),
                      marginLeft: theme.spacing(3),
                      marginBottom: theme.spacing(0),
                    }} color="textSecondary">
                      New Interested Student Leads
                    </Typography>
                    <Link
                      to={`/dashboard/`}
                      onClick={() => setOpenNewStudents(true)}>
                      <div className={classes.viewButtonArrowContiner} >
                        <Typography style={{
                          fontSize: 11,
                          fontWeight: 800,
                          textAlign: 'right',
                          display: "flex",
                          color: "#32a852",
                        }}>
                          VIEW
                        </Typography>
                        <ChevronRightIcon className={classes.rightArrow} />
                      </div>
                    </Link>
                  </Card>
                </Grid>
                <ViewStudents
                  setOpen={setOpenNewStudents}
                  open={openNewStudents}
                  title="Interested Student Leads"
                  data={newInterestedStudents}
                />
                <Grid item md={4} xs={12} display='flex' justifyContent='center'>
                  <Card style={{
                    paddingLeft: theme.spacing(1),
                    paddingRight: theme.spacing(1),
                    boxShadow: theme.shadows[4],
                    display: "grid",
                    flexWrap: 'nowrap',
                    backgroundColor: theme.palette.secondary.dark,
                    height: 150,
                    width: 165,
                    borderRadius: 25,
                    outlineColor: "#37373f",
                    outlineStyle: "solid",
                    outlineWidth: 1,
                  }}>
                    {/* <Box display="flex" justifyContent="center">
                      {displayData?.newConnectsPct >= 0 ? (
                        <ArrowUpwardIcon />
                      ) : (
                        <ArrowDownwardIcon />
                      )}
                      <Box width="5px" />
                      <Typography
                        variant="h6"
                        align="center"
                        color="textSecondary"
                      >
                        {displayData?.newConnectsPct?.toFixed(1) + "%"}
                      </Typography>
                    </Box> */}
                    <Typography color="secondary" style={{
                      marginTop: theme.spacing(1),
                      marginBottom: theme.spacing(0),
                      marginLeft: theme.spacing(3),
                      marginRight: theme.spacing(1),
                      display: "flex",
                      fontSize: 40,
                      fontWeight: 600,
                      alignSelf: 'left',
                      width: 70,
                      height: 57,
                    }}>
                      {displayData?.newConnects
                        ?.toString()
                        ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0}
                    </Typography>
                    <Typography style={{
                      display: "grid",
                      fontSize: 14,
                      textAlign: 'left',
                      width: 100,
                      marginTop: theme.spacing(0),
                      marginLeft: theme.spacing(3),
                      marginBottom: theme.spacing(0),
                    }} color="textSecondary">
                      Lead Connect Button Clicks
                    </Typography>
                    <Link
                      to={`/dashboard/`}
                      onClick={() => setOpenConnects(true)}>
                      <div className={classes.viewButtonArrowContiner} >
                        <Typography style={{
                          fontSize: 11,
                          fontWeight: 800,
                          textAlign: 'right',
                          display: "flex",
                          color: "#32a852",
                        }}>
                          VIEW
                        </Typography>
                        <ChevronRightIcon className={classes.rightArrow} />
                      </div>
                    </Link>
                  </Card>
                </Grid>
                <ViewStudents
                  setOpen={setOpenConnects}
                  open={openConnects}
                  title="New Student Connects"
                  data={connects}
                />
              </Grid>
            </>
          )}
        </CardContent>
      </Card>
    </FadeIn>
  );
}
