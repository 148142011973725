import React, { useRef, useEffect, useState } from "react";
import Parse from "parse";
import {
  Card,
  CardHeader,
  IconButton,
  Menu,
  MenuItem,
  CardMedia,
  Box,
  Typography
} from "@mui/material";
import FadeIn from "react-fade-in";
import { useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Chart, registerables } from "chart.js";
import MenuIcon from "@material-ui/icons/Menu";
import { Loading } from "../../../../components";

const useStyles = makeStyles((theme: any) => ({
  card: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    height: "350px",
    // paddingBottom: theme.spacing(8),
    marginBottom: 0,
    boxShadow: theme.shadows[4],
    borderRadius: 15,
    outlineColor: "#2c2c33",
    outlineStyle: "solid",
    outlineWidth: 1,
  },
  canvas: {
    height: "350px",
  },
  grid: {
    flexGrow: 1,
  },
  popover: {
    backgroundColor: theme.palette.secondary.dark,
    borderRadius: 11,
    outlineColor: "#2c2c33",
    outlineStyle: "solid",
    outlineWidth: 1,
  },
  titleFont: {
    fontWeight: 500
  },
}));

export default function PieChart(props) {
  const theme = useTheme();
  const classes = useStyles(theme);

  const chartRef = useRef<any>();
  const popoverRef = useRef(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [anchor, setAnchor] = useState(null);
  const [sub, setSub] = useState("By Ethnicity");
  const [option, setOption] = useState("ethnicity");
  const [currChart, setCurrChart] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState([]);

  const options = [
    {
      label: "By Ethnicity",
      value: "ethnicity",
    },
    {
      label: "By Gender",
      value: "gender",
    },
    {
      label: "By State",
      value: "state",
    },
    {
      label: "By Graduation Year",
      value: "gradyear",
    },
  ];

  useEffect(() => {
    async function fetchData() {
      await Parse.Cloud.run("dashboardPieChartLeads").then((response) => {
        setLoading(false);
        setChartData(response);
      });
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (chartData && chartRef?.current) {
      Chart.register(...registerables);

      const myChartRef = chartRef.current.getContext("2d");

      const displayThreshold = Math.max(...chartData[option].data) * 0.01;
      let indices: any = [];
      chartData[option].data.forEach((x, i) => {
        if (x > displayThreshold) {
          indices.push(i);
        }
      });
      indices.sort();

      let filteredData = chartData[option].data.filter((_, i) =>
        indices.includes(i)
      );
      let filteredLabels = chartData[option].labels.filter((_, i) =>
        indices.includes(i)
      );

      const index = filteredLabels.findIndex(o => o === '');
      if (index > -1) {
        filteredLabels[index] = "Not Listed";
      }

      filteredData.push(
        chartData[option].data.reduce((a, b) => a + b, 0) -
        filteredData.reduce((a, b) => a + b, 0)
      );
      if (filteredData[filteredData.length - 1] !== 0) {
        filteredLabels.push("Other");
      }

      const data = {
        datasets: [
          {
            data: filteredData,
            backgroundColor: [
              "#35C669",
              "#2EC4A9",
              "#05967C",
              "#43FA86",
              "#2F523B",
              "#086353",
              "#5DD489",
              "#5FD4BE",
              "#00F0C4",
              "#5AE08B",
              "#35C669",
              "#2EC4A9",
              "#05967C",
              "#43FA86",
              "#2F523B",
              "#086353",
              "#5DD489",
              "#5FD4BE",
              "#00F0C4",
              "#5AE08B",
              "#35C669",
              "#2EC4A9",
              "#05967C",
              "#43FA86",
              "#2F523B",
              "#086353",
              "#5DD489",
              "#5FD4BE",
              "#00F0C4",
              "#5AE08B",
              "#35C669",
              "#2EC4A9",
              "#05967C",
              "#43FA86",
              "#2F523B",
              "#086353",
              "#5DD489",
              "#5FD4BE",
              "#00F0C4",
              "#5AE08B",
            ],
            borderColor: "#14141F",
          },
        ],
        labels: filteredLabels,
      };

      if (currChart !== null) {
        currChart.destroy();
      }

      // let splicedData = data.filter((datapoint) => datapoint > 10);

      const charty = new Chart(myChartRef, {
        type: "doughnut",
        data: data,
        options: {
          plugins: {
            legend: {
              display: true,
              position: 'right',
              labels: {
                color: "#53535b",
              },
            },
          },
          // segmentShowStroke: false,
          responsive: true,
          cutout: 45,
          aspectRatio: 3
        },
      });

      setCurrChart(charty);
      charty.update();
    }
    // eslint-disable-next-line
  }, [chartRef, chartData, sub, option]);

  return (
    <FadeIn delay={100}>
      <Card style={{
        backgroundColor: theme.palette.primary.dark,
        padding: theme.spacing(2),
        height: 320,
        // paddingBottom: theme.spacing(8),
        marginBottom: 0,
        boxShadow: theme.shadows[4],
        borderRadius: 25,
        outlineColor: "#2c2c33",
        outlineStyle: "solid",
        outlineWidth: 1,
      }}>
        <CardHeader
          title="Leads Demographics"
          titleTypographyProps={{ fontWeight: 600 }}
          subheader={sub}
          action={
            <>
              <IconButton
                ref={popoverRef}
                onClick={(e: any) => {
                  setOpenMenu(true);
                  setAnchor(e.currentTarget);
                }}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="post-menu"
                anchorEl={anchor}
                open={openMenu}
                slotProps={{
                  paper: {
                    sx: {
                      backgroundColor: theme.palette.primary.dark,
                      borderRadius: 1.5,
                      outlineColor: "#3c3c44",
                      outlineStyle: "solid",
                      outlineWidth: 1,
                    }
                  }
                }}
                onClose={() => setOpenMenu(false)}
              >
                {options.map((option) => (
                  <MenuItem
                    key={option.value}
                    onClick={() => {
                      setOpenMenu(false);
                      setOption(option.value);
                      setSub(option.label);
                    }}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Menu>
            </>
          }
        />
        <CardMedia>
          {loading ? (
            <Loading />
          ) : (
            <>
              {chartData?.length == 0 ? (
                <>
                  <Box height="60px" />
                  <Box sx={{ alignSelf: "center" }}>
                    <Typography>No data.</Typography>
                  </Box>
                </>
              ) : (<></>)}
              <canvas
                id="interested-students"
                height="100%"
                ref={chartRef}
              ></canvas>
            </>
          )}
        </CardMedia>
      </Card>
    </FadeIn>
  );
}
