import { lazy } from 'react';

// ----------------------------------------------------------------------

const StudentAppPage = lazy(() => import('../../Pages/_platform/student-app'));

// ----------------------------------------------------------------------

export const studentAppRoutes = [
  {
    path: 'student-app',
    children: [
      { element: <StudentAppPage />, index: true },
    ],
  },
];
