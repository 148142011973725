import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { gql, useMutation } from "@apollo/client";
import { Loading, Input } from "../../../components";

const useStyles = makeStyles((theme: any) => ({
  button: {
    background: "linear-gradient(75deg, #35c669, #2ec4a9)",
    border: 0,
    borderRadius: 15,
    color: "#fff",
    padding: "0 30px",
    minHeight: "50px",
  },
}));

const SAVE = gql`
  mutation saveCollegeStats(
    $id: ID!
    $sat: Float!
    $sat25math: Float
    $sat25reading: Float
    $sat25writing: Float
    $sat50math: Float
    $sat50reading: Float
    $sat50writing: Float
    $sat75math: Float
    $sat75reading: Float
    $sat75writing: Float
  ) {
    updateCollegeStat(
      input: {
        id: $id
        fields: {
          SATavg: $sat
          SAT25thPercentileMath: $sat25math
          SAT25thPercentileReading: $sat25reading
          SAT25thPercentileWriting: $sat25writing
          SATMidpointMath: $sat50math
          SATMidpointReading: $sat50reading
          SATMidpointWriting: $sat50writing
          SAT75thPercentileMath: $sat75math
          SAT75thPercentileReading: $sat75reading
          SAT75thPercentileWriting: $sat75writing
        }
      }
    ) {
      collegeStat {
        id
        objectId
        SATavg
        SAT25thPercentileMath
        SAT25thPercentileReading
        SAT25thPercentileWriting
        SATMidpointMath
        SATMidpointReading
        SATMidpointWriting
        SAT75thPercentileMath
        SAT75thPercentileReading
        SAT75thPercentileWriting
      }
    }
  }
`;

export default function SATScores(props) {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [SAT_avg, set_SAT_avg] = useState("");
  const [SAT_25_math, set_SAT_25_math] = useState("");
  const [SAT_25_reading, set_SAT_25_reading] = useState("");
  const [SAT_25_writing, set_SAT_25_writing] = useState("");
  const [SAT_mp_math, set_SAT_mp_math] = useState("");
  const [SAT_mp_reading, set_SAT_mp_reading] = useState("");
  const [SAT_mp_writing, set_SAT_mp_writing] = useState("");
  const [SAT_75_math, set_SAT_75_math] = useState("");
  const [SAT_75_reading, set_SAT_75_reading] = useState("");
  const [SAT_75_writing, set_SAT_75_writing] = useState("");

  const [saveCollegeStats, { loading }] = useMutation(SAVE);

  useEffect(() => {
    if (props.data) {
      set_SAT_avg(props.data?.college?.collegeStats?.SATavg);
      set_SAT_25_math(props.data?.college?.collegeStats?.SAT25thPercentileMath);
      set_SAT_25_reading(
        props.data?.college?.collegeStats?.SAT25thPercentileReading
      );
      set_SAT_25_writing(
        props.data?.college?.collegeStats?.SAT25thPercentileWriting
      );
      set_SAT_mp_math(props.data?.college?.collegeStats?.SATMidpointMath);
      set_SAT_mp_reading(props.data?.college?.collegeStats?.SATMidpointReading);
      set_SAT_mp_writing(props.data?.college?.collegeStats?.SATMidpointWriting);
      set_SAT_75_math(props.data?.college?.collegeStats?.SAT75thPercentileMath);
      set_SAT_75_reading(
        props.data?.college?.collegeStats?.SAT75thPercentileReading
      );
      set_SAT_75_writing(
        props.data?.college?.collegeStats?.SAT75thPercentileWriting
      );
    }
  }, [props.data]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography justify-content="left" align="left" color="textPrimary">
          Overall Average SAT Score
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Input
          fullWidth
          label="Average SAT Score"
          name="SAT_avg"
          value={SAT_avg}
          onChange={(e) => set_SAT_avg(e.target.value)}
          variant="filled"
        />
      </Grid>
      <Grid item xs={12}>
        <Typography justify-content="left" align="left" color="textPrimary">
          Average SAT Scores (25th Percentile)
        </Typography>
      </Grid>
      <Grid item md={4} xs={12}>
        <Input
          fullWidth
          label="Math"
          name="SAT_25percentile_math"
          value={SAT_25_math}
          onChange={(e) => set_SAT_25_math(e.target.value)}
          variant="filled"
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <Input
          fullWidth
          label="Critical Reading"
          name="SAT_25percentile_reading"
          value={SAT_25_reading}
          onChange={(e) => set_SAT_25_reading(e.target.value)}
          variant="filled"
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <Input
          fullWidth
          label="Writing"
          name="SAT_25percentile_writing"
          value={SAT_25_writing}
          onChange={(e) => set_SAT_25_writing(e.target.value)}
          variant="filled"
        />
      </Grid>
      <Grid item xs={12}>
        <Typography justify-content="left" align="left" color="textPrimary">
          Average SAT Scores (Midpoint)
        </Typography>
      </Grid>
      <Grid item md={4} xs={12}>
        <Input
          fullWidth
          label="Math"
          name="SAT_midpoint_math"
          value={SAT_mp_math}
          onChange={(e) => set_SAT_mp_math(e.target.value)}
          variant="filled"
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <Input
          fullWidth
          label="Critical Reading"
          name="SAT_midpoint_reading"
          value={SAT_mp_reading}
          onChange={(e) => set_SAT_mp_reading(e.target.value)}
          variant="filled"
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <Input
          fullWidth
          label="Writing"
          name="SAT_midpoint_writing"
          value={SAT_mp_writing}
          onChange={(e) => set_SAT_mp_writing(e.target.value)}
          variant="filled"
        />
      </Grid>
      <Grid item xs={12}>
        <Typography justify-content="left" align="left" color="textPrimary">
          Average SAT Scores (75th Percentile)
        </Typography>
      </Grid>
      <Grid item md={4} xs={12}>
        <Input
          fullWidth
          label="Math"
          name="SAT_75percentile_math"
          value={SAT_75_math}
          onChange={(e) => set_SAT_75_math(e.target.value)}
          variant="filled"
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <Input
          fullWidth
          label="Critical Reading"
          name="SAT_75percentile_reading"
          value={SAT_75_reading}
          onChange={(e) => set_SAT_75_reading(e.target.value)}
          variant="filled"
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <Input
          fullWidth
          label="Writing"
          name="SAT_75percentile_writing"
          value={SAT_75_writing}
          onChange={(e) => set_SAT_75_writing(e.target.value)}
          variant="filled"
        />
      </Grid>
      <Grid item xs={12} sx={{ alignSelf: "right" }}>
        {loading ? (
          <Loading small />
        ) : (
          <Button
            style={{
              background: "linear-gradient(75deg, #35c669, #2ec4a9)",
              border: 0,
              borderRadius: 15,
              color: "#fff",
              padding: "0 30px",
              minHeight: "50px",
            }}
            onClick={() => {
              saveCollegeStats({
                variables: {
                  id: props.data?.college?.collegeStats?.objectId,
                  sat: parseFloat(SAT_avg),
                  sat25math: parseFloat(SAT_25_math),
                  sat25reading: parseFloat(SAT_25_reading),
                  sat25writing: parseFloat(SAT_25_writing),
                  sat50math: parseFloat(SAT_mp_math),
                  sat50reading: parseFloat(SAT_mp_reading),
                  sat50writing: parseFloat(SAT_mp_writing),
                  sat75math: parseFloat(SAT_75_math),
                  sat75reading: parseFloat(SAT_75_reading),
                  sat75writing: parseFloat(SAT_75_writing),
                },
              });
            }}
          >
            Save SAT Scores
          </Button>
        )}
      </Grid>
    </Grid>
  );
}
