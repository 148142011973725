import React from "react";
import {
  useTheme,
  Modal as MUIModal,
  Fade,
  Card,
  CardHeader,
  Backdrop,
  IconButton,
  CardContent,
} from "@mui/material";
import CloseIcon from "@material-ui/icons/Close";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { makeStyles } from '@mui/styles';
import { backdrop } from "src/routes/FrontEnd/Theme/overrides/components/backdrop";
import { fontSize } from "@mui/system";

const useStyles = makeStyles((theme: any) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "none",
  },
  modalContent: {
    minWidth: 400,
    maxWidth: 700,
    backgroundColor: theme.palette.primary.dark,
    display: "flex",
    outlineColor: "#2c2c33",
    outlineStyle: "solid",
    outlineWidth: 1,
    flexDirection: "column",
    padding: theme.spacing(2),
    borderRadius: 20
  },
  content: {
    maxHeight: 550,
  },
}));

const CustomModal = (props) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <MUIModal
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "none",
      }}
      {...props}
      open={props.open}
      onClose={() => props.setOpen(false)}
      closeAfterTransition
      // slots={{backdrop: { Backdrop }}}
    >
      <Fade in={props.open}>
        <Card style={{
          backgroundColor: !props.cardColor ? "#0e0e14" : props.cardColor, 
          minWidth: 400,
          maxWidth: 700,
          display: "flex",
          outlineColor: "#2c2c33",
          outlineStyle: "solid",
          outlineWidth: 1,
          flexDirection: "column",
          padding: theme.spacing(2),
          borderRadius: 25
        }}>
          <CardHeader
            title={props.title}
            subheader={props.sub}
            action={
              <IconButton style={{ width: 45, height: 45, marginLeft: theme.spacing(2) }} onClick={() => props.setOpen(false)}>
                <CloseIcon />
              </IconButton>
            }
          />
          <CardContent>
            {props.noScroll ? (
              <div className={classes.content}>{props.children}</div>
            ) : (
              <PerfectScrollbar options={{ suppressScrollX: true }}>
                <div className={classes.content}>{props.children}</div>
              </PerfectScrollbar>
            )}
          </CardContent>
        </Card>
      </Fade>
    </MUIModal>
  );
};

export default CustomModal;
