import React, { useState } from "react";
import {
  MenuList,
  Paper,
  MenuItem,
  Typography,
  Button,
  Box,
  FormControlLabel,
  Checkbox,
  IconButton,
} from "@mui/material";
import FadeIn from "react-fade-in";
import { useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Page, Loading } from "../../../../components";
import AddIcon from "@material-ui/icons/Add";
import ForumCard from "./ForumCard";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/client";
import CollegeForums from "./CollegeForums";
import MyFeed from "./MyFeed";
import ManageTags from "./ManageTags";
import FilterByTag from "./FilterByTag";
import { CheckBox } from "@material-ui/icons";

const useStyles = makeStyles((theme: any) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    "& ul": {
      display: "inline-flex",
    },
    margin: theme.spacing(3),
  },
  selected: {
    color: theme.palette.secondary.main,
  },
  button: {
    background: "linear-gradient(75deg, #35c669, #2ec4a9)",
    border: 0,
    color: "#fff",
  },
}));

export default function Forums({ tags }) {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [college, setCollege] = useState(false);
  const [filteredTags, setFilteredTags] = useState<any>([]);
  const [seekingResponse, setSeekingResponse] = useState(false);

  return (
    <Page title="Forums" maxWidth="md">
      <FadeIn>
        <Box display="flex" m={3} mt={0}>
          <Box flexGrow={1} />
          <FormControlLabel
            control={
              <Checkbox
                name="Seeking College Response"
                sx={{
                  color: theme.palette.text.secondary,
                  '&.Mui-checked': {
                    color: theme.palette.secondary.main,
                  },
                }}
                checked={seekingResponse}
                onChange={() => setSeekingResponse(!seekingResponse)}
              />
            }
            label="Seeking College Response Only"
            style={{ color: "#ffffff" }}
          />
          <Box width="6px" />
          <ManageTags tags={tags} />
          <Box width="6px" />
          <FilterByTag
            tags={tags}
            filteredTags={filteredTags}
            setFilteredTags={setFilteredTags}
          />
        </Box>
        {college ? (
          <CollegeForums />
        ) : (
          <MyFeed
            noTags={tags.length === 0}
            tags={
              filteredTags.length > 0
                ? tags.filter((t) => filteredTags.includes(t.node.objectId))
                : tags
            }
            seekingCollegeResponse={seekingResponse}
          />
        )}
      </FadeIn>
    </Page>
  );
}
