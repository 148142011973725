import React, { useState } from "react";
import Parse from "parse";
import {
  MenuList,
  Paper,
  MenuItem,
  Typography,
  Modal,
  Fade,
  Card,
  Grid,
  Backdrop,
  IconButton,
  Button,
  Chip,
  Menu,
  Checkbox,
  SvgIcon,
  Input,
  Box,
} from "@mui/material";
import { useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Page, Loading } from "../../../../components";
import AddIcon from "@material-ui/icons/Add";
import ForumCard from "./ForumCard";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/client";
import CollegeForums from "./CollegeForums";
import MyFeed from "./MyFeed";
import CloseIcon from "@material-ui/icons/Close";
import { Search as SearchIcon } from "react-feather";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

const useStyles = makeStyles((theme: any) => ({
  root: {
    backgroundColor: theme.palette.secondary.dark,
    minHeight: "100%",
    borderRadius: 20,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    outlineColor: "#313135",
    outlineStyle: "solid",
    outlineWidth: 1,
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    "& ul": {
      display: "inline-flex",
    },
    marginTop: theme.spacing(0),
    margin: theme.spacing(3),
  },
  selected: {
    color: theme.palette.secondary.main,
  },
  chip: {
    paddingLeft: theme.spacing(3),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  button: {
    background: "linear-gradient(75deg, #35c669, #2ec4a9)",
    border: 0,
    color: "#fff",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: theme.spacing(3),
  },
  search: {
    display: "flex",
    alignItems: "center",
    height: 44,
    borderRadius: 10,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    backgroundColor: theme.palette.primary.light,
  },
  searchInput: {
    flexGrow: 1,
    marginLeft: theme.spacing(1),
  },
}));

const ADD_TAG = gql`
  mutation addTag($id: ID!, $tags: [ID!]) {
    updateUserContact(
      input: { id: $id, fields: { followedTags: { add: $tags } } }
    ) {
      userContact {
        id
        objectId
        followedTags {
          edges {
            node {
              id
              objectId
              tag
              color
            }
          }
        }
      }
    }
  }
`;

const REMOVE_TAG = gql`
  mutation removeTag($id: ID!, $tags: [ID!]) {
    updateUserContact(
      input: { id: $id, fields: { followedTags: { remove: $tags } } }
    ) {
      userContact {
        id
        objectId
        followedTags {
          edges {
            node {
              id
              objectId
              tag
              color
            }
          }
        }
      }
    }
  }
`;

const SEARCH_TAGS = gql`
  query searchTags($query: String!) {
    forumTags(where: { tag: { matchesRegex: $query, options: "i" } }) {
      edges {
        node {
          id
          objectId
          tag
          color
        }
      }
    }
  }
`;

export default function FilterByTag({ tags, setFilteredTags, filteredTags }) {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [open, setOpen] = useState(false);
  const [anchor, setAnchor] = useState(null);

  const getContrastColor = (hex) => {
    if (hex.indexOf("#") === 0) {
      hex = hex.slice(1);
    }
    if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
      throw new Error("Invalid HEX color.");
    }
    var r = parseInt(hex.slice(0, 2), 16),
      g = parseInt(hex.slice(2, 4), 16),
      b = parseInt(hex.slice(4, 6), 16);
    return r * 0.299 + g * 0.587 + b * 0.114 > 160 ? "#000000" : "#FFFFFF";
  };

  const handleClick = (id) => {
    if (filteredTags.indexOf(id) > -1) {
      setFilteredTags(filteredTags.filter((t) => t !== id));
    } else {
      setFilteredTags([...filteredTags, id]);
    }
  };

  return (
    <>
      <Button
        variant="outlined"
        style={{ color: "#fff" }}
        onClick={(e: any) => {
          setOpen(!open);
          setAnchor(e.currentTarget);
        }}
      >
        Filter By Tag
      </Button>
      <Menu
        id="filter-tag-menu"
        anchorEl={anchor}
        open={open}
        onClose={() => setOpen(false)}
        keepMounted
        slotProps={{
          paper: {
            sx: {
              backgroundColor: theme.palette.primary.dark,
              paddingTop: theme.spacing(1),
              paddingBottom: theme.spacing(1),
              borderRadius: 1.5,
              outlineColor: "#3c3c44",
              outlineStyle: "solid",
              outlineWidth: 1,
            }
          }
        }}
        classes={{ paper: classes.root }}
        style={{
          marginTop: 10,
        }}
      >
        {tags?.map((t) => (
          <MenuItem
            key={t.node.id}
            value={t.node.objectId}
            onClick={() => handleClick(t.node.objectId)}
          >
            <Checkbox
              sx={{
                color: theme.palette.text.secondary,
                '&.Mui-checked': {
                  color: theme.palette.secondary.main,
                },
              }}
              checked={filteredTags.indexOf(t.node.objectId) > -1}
            />
            <Chip
              size="small"
              label={t.node.tag}
              style={{
                backgroundColor: t.node.color,
                color: getContrastColor(t.node.color),
                marginLeft: theme.spacing(1),
                marginRight: theme.spacing(3),
              }}
            />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
