import React, { useEffect, useState, useRef, useMemo, forwardRef, useImperativeHandle } from "react";
import Parse from "parse";
import { Elements, CardElement, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import {
  useTheme,
  Card,
  Grid,
  Box,
  Typography,
  Fade,
  Hidden,
  Menu,
  MenuItem,
  ButtonBase
} from "@mui/material";
import { makeStyles } from '@mui/styles';
import { Input, Modal, Button } from "../../../components";
import "react-perfect-scrollbar/dist/css/styles.css";
import { useAuth } from "../../../hooks/useAuth";
import { useSnackbar } from "notistack";
import { Loading } from "../../../components";

const useStyles = makeStyles((theme: any) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  card: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  avatar: {
    height: 42,
    width: 42,
    top: 2,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    backgroundColor: "#1d1d2b",
  },
  button: {
    marginTop: theme.spacing(3)
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: theme.spacing(1),
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(4),
    marginBottom: theme.spacing(0)
  },
  deactivate: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.text.primary,
  },
  tableCell: {
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  accordion: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[0],
    outlineColor: theme.palette.background.paper,
    marginTop: theme.spacing(1),
  },
  person: {
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(1),
    height: 62,
    display: "flex",
    "&:hover": {
      backgroundColor: theme.palette.primary.lightHover,
      cursor: "pointer"
    },
  },
  title: {
    fontSize: 30,
    fontWeight: 600,
    color: "#fff",
    marginBottom: theme.spacing(1)
  },
  subHeader: {
    fontSize: 13,
    fontWeight: 400,
    color: "#c6c9d3",
  },
  bulletList: {
    marginLeft: "10px",
    listStyleType: "disc"
  },
  cardAction: {
    width: "auto",
    height: "auto",
    display: "block",
  },
  search: {
    display: "flex",
    alignItems: "center",
    height: 44,
    borderRadius: 10,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    backgroundColor: theme.palette.primary.light,
  },
  label: {
    fontSize: 18,
    fontWeight: 500,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(0)
  },
  searchInput: {
    flexGrow: 1,
    marginLeft: theme.spacing(1),
  },
  results: {
    overflowY: "auto",
    height: 300,
  },
}));

const ChildComponent = forwardRef((props: any, ref) => {
  const theme = useTheme();

  const stripe = useStripe();
  const elements = useElements();
  const auth = useAuth();

  const { enqueueSnackbar } = useSnackbar();

  useImperativeHandle(ref, () => ({
    async handleSubmit() {
      if (elements) {
        props.setLoading(true);
        const paymentElement = elements.getElement(PaymentElement);

        if (stripe && paymentElement) {
          await stripe.confirmSetup({
            elements,
            redirect: 'if_required',
            confirmParams: {
              return_url: "http://localhost:3000/dashboard/billing",
              payment_method_data: {
                billing_details: {
                  email: props.email
                }
              }
            }
          }).then(async function (result) {
            if (result.setupIntent) {
              Parse.Cloud.run("upgradeToPremiumTier", {
                collegeId: auth.collegeId,
                methodId: result.setupIntent.payment_method,
                repEmail: props.email,
                billingFrequency: props.paymentFrequency
              }).then(
                (result) => {
                  if (result === true) {
                    enqueueSnackbar("Payment successful. Your school is now a premium user", {
                      variant: "success",
                    });
                  }

                  props.refresh();

                  props.setOpen(false);

                  props.setLoading(false);
                },
                (error) => {
                  enqueueSnackbar(error.toString(), {
                    variant: "error",
                  });

                  props.setOpen(false);

                  props.setLoading(false);
                })

            } else {
              props.setLoading(false);

              if (result.error.message) {
                enqueueSnackbar(result.error.message.toString(), {
                  variant: "error",
                });
              }
            }
          });
        }
      }
    }
  }))

  return (
    <PaymentElement id="payment-element" />
  );
});

export default function UpgradePayment(props) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const ref = useRef(null);
  const childRef = useRef<any>();

  const [open, setOpen] = useState(false);
  const [clientSecret, setClientSecret] = useState("");
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const childComponent = useMemo(() => <ChildComponent ref={childRef} email={props.email} refresh={props.refresh} setOpen={setOpen} setLoading={setLoading} />, []);
  const [paymentFrequency, setPaymentFrequency] = useState(props.paymentFrequency);
  const [stripePromise, setStripePromise] = useState(() => loadStripe('pk_live_51JZHakCgmkCKcX6cUE5ymujI4Gbzv0T11l9bKCXdP5UtUMyodH5kwxjgHIJELRpZdlKabluyJq16AMHiAn7J0w7x003b1wNj5t'))

  const handleOpen = (e) => {
    e.stopPropagation();

    setDropdownOpen(true);
  };

  const handleClose = (e) => {
    e.stopPropagation();

    setDropdownOpen(false);
  };

  const paymentFrequencyOptions = [
    {
      label: "Monthly",
      value: "monthly",
    },
    {
      label: "Yearly",
      value: "yearly",
    },
  ];

  const createPaymentIntent = async () => {
    const res = await Parse.Cloud.run("createPaymentIntent", {});
    setClientSecret(res.client_secret);
  };

  useEffect(() => {
    setPaymentFrequency(props.paymentFrequency);
  }, [props]);

  useEffect(() => {
    if (clientSecret == "") {
      createPaymentIntent();
    }
  }, []);

  return (
    <>
      <Button
        style={{
          color: '#fff',
          paddingRight: 12,
          paddingLeft: 12,
          marginBottom: theme.spacing(-2)
        }}
        onClick={() => setOpen(true)}
      >
        Select This Plan
      </Button>
      <Modal
        open={open}
        setOpen={setOpen}
        title={
          <div style={{ marginBottom: 10 }}>
            {paymentFrequency == "monthly" ?
              <><span style={{ fontSize: 30, fontWeight: 600 }}>Upgrade to Premium</span><span style={{ fontSize: 19, fontWeight: 600 }}>{" ($"}{(Math.round((props.subscriptionCostMonthly) * 100) / 100).toLocaleString()}{"/mo.)"}</span></> :
              <><span style={{ fontSize: 30, fontWeight: 600 }}>Upgrade to Premium</span><span style={{ fontSize: 19, fontWeight: 600 }}>{" ($"}{(Math.round((props.subscriptionCostYearly / 12) * 100) / 100).toLocaleString()}{"/mo.)"}</span></>}
          </div>
        }
        sub={<Typography style={{
          fontSize: 13,
          fontWeight: 400,
          color: "#c6c9d3",
        }} marginLeft={theme.spacing(1)} marginBottom={theme.spacing(-2)}><ul style={{ padding: 0, marginTop: 0 }}><li className={classes.bulletList}>3 free users &#40;${props.monthlyUserFee}/mo. user fees apply for additional users&#41;</li><li className={classes.bulletList}>Full access to all app pages and functionality</li><li className={classes.bulletList}>Campaign, Advertisement, and Leads Export use &#40;service fees apply&#41;</li></ul></Typography>}
      >
        <Box
          display="flex"
          alignItems="center"
          component={ButtonBase}
          onClick={e => {
            e.stopPropagation();

            handleOpen(e);
          }}
          ref={ref}
          style={{
            borderRadius: 25,
            backgroundColor: "#0d0d14",
            // padding: 1,
            minWidth: 70,
            paddingRight: theme.spacing(6),
            paddingLeft: theme.spacing(6),
            marginLeft: 1,
            paddingTop: 5,
            paddingBottom: 5,
            marginTop: 1,
            marginBottom: theme.spacing(2),
            outlineWidth: 1,
            outlineStyle: "solid",
            outlineColor: "#3b3b42"
          }}
        >
          <Hidden smDown>
            <Typography
              style={{
                fontSize: 11,
                fontWeight: 600
              }}
              color="inherit"
            >
              {paymentFrequency == "monthly" ? "MONTHLY" :
                <>
                  <span
                    style={{
                      fontSize: 11,
                      fontWeight: 600
                    }}
                  >
                    {"YEARLY "}
                  </span>
                  <span
                    style={{
                      fontSize: 9,
                      fontWeight: 600,
                      backgroundImage: `linear-gradient(to right, #35c669, #2ec4a9)`,
                      backgroundSize: "100%",
                      backgroundRepeat: "repeat",
                      backgroundClip: "text",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent"
                    }}
                  >
                    {"(SAVE " + Math.round(((props.subscriptionCostMonthly - (props.subscriptionCostYearly / 12)) / props.subscriptionCostMonthly) * 100) + "%)"}
                  </span>
                </>
              }
            </Typography>
          </Hidden>
        </Box>
        <Menu
          onClose={e => handleClose(e)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          keepMounted
          slotProps={{
            paper: {
              sx: {
                minWidth: 130,
                marginLeft: theme.spacing(0),
                marginTop: theme.spacing(1),
                backgroundColor: theme.palette.primary.dark,
                borderRadius: 1.5,
                outlineColor: "#3c3c44",
                outlineStyle: "solid",
                outlineWidth: 1,
              }
            }
          }}
          anchorEl={ref.current}
          open={isDropdownOpen}
        >
          {paymentFrequencyOptions.map((option) => (
            <MenuItem
              key={option.value}
              onClick={e => {
                e.stopPropagation();

                setDropdownOpen(false);
                setPaymentFrequency(option.value);
                props.setPaymentFrequency(option.value);
              }}
            >
              {option.label}
            </MenuItem>
          ))}
        </Menu>
        <Fade in={open}>
          <Card
            className="cardScroll"
            style={{
              backgroundColor: theme.palette.background.paper,
              display: "flex",
              flexDirection: "column",
              padding: theme.spacing(4),
              marginBottom: theme.spacing(0),
              borderRadius: 19,
              maxHeight: 400,
              overflowY: 'scroll'
            }}
          >
            <Box width={500}>
              <Elements
                stripe={stripePromise}
                options={{
                  clientSecret: clientSecret,
                  appearance: {
                    theme: 'stripe',
                    variables: {
                      colorPrimary: '#0570de',
                      colorBackground: '#ffffff',
                      colorText: '#000',
                      colorTextSecondary: "#82828c",
                      colorDanger: '#df1b41',
                      spacingUnit: '5px',
                      borderRadius: '13px',
                    },
                    rules: {
                      ".Label": {
                        color: "#a0a0aa",
                        fontWeight: "500"
                      },
                      ".Tab--selected": {
                        backgroundColor: "#ececf6"
                      }
                    }
                  }
                }}
              >
                {childComponent}
                {loading ? (
                  <Loading style={{marginTop: theme.spacing(3)}} />
                ) : (
                  <>
                    <Box display='flex' justifyContent='right'>
                      <Button onClick={() => childRef?.current?.handleSubmit()}>
                        Pay ${paymentFrequency == "monthly" ? (Math.round(props.subscriptionCostMonthly * 100) / 100).toLocaleString() : (Math.round(props.subscriptionCostYearly * 100) / 100).toLocaleString()}
                      </Button>
                    </Box>
                  </>
                )}
              </Elements>
            </Box>
          </Card>
        </Fade>
      </Modal>
    </>
  );
}
