import React, { useState } from "react";
import Parse from "parse";
import { Link as RouterLink } from "react-router-dom";
import { Lightbox } from "react-modal-image";
import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  CardHeader,
  CardMedia,
  Link,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Chip,
} from "@mui/material";
import { useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import ChatIcon from "@material-ui/icons/Chat";
import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import ShareIcon from "@material-ui/icons/Share";
import CommentIcon from "@material-ui/icons/Comment";
import AssessmentIcon from "@material-ui/icons/Assessment";
import { ThumbsDown, ThumbsUp } from "react-feather";
import { red, orange, purple, green, blue } from "@material-ui/core/colors";
import ForumCard from "./ForumCard";
import { gql, useQuery } from "@apollo/client";
import { Loading } from "../../../../components";
import { useAuth } from "../../../../hooks/useAuth";

const useStyles = makeStyles((theme: any) => ({
  redAvatar: {
    margin: 10,
    color: "#fff",
    backgroundColor: red[500],
  },
  orangeAvatar: {
    margin: 10,
    color: "#fff",
    backgroundColor: orange[500],
  },
  purpleAvatar: {
    margin: 10,
    color: "#fff",
    backgroundColor: purple[500],
  },
  greenAvatar: {
    margin: 10,
    color: "#fff",
    backgroundColor: green[500],
  },
  blueAvatar: {
    margin: 10,
    color: "#fff",
    backgroundColor: blue[500],
  },
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  date: {
    marginLeft: 6,
  },
  media: {
    height: 500,
  },
  reactions: {
    display: "flex",
    alignItems: "center",
  },
}));

const GET_FORUM_POSTS = gql`
  query getForums($id: ID!) {
    forums(
      order: createdAt_DESC
      where: { collegeAuthor: { have: { objectId: { equalTo: $id } } } }
    ) {
      edges {
        node {
          id
          objectId
          createdAt
          collegeAuthor {
            name
            img {
              url
            }
          }
          title
          content
          tags {
            edges {
              node {
                tag
                color
              }
            }
          }
        }
      }
    }
  }
`;

const CollegeForums = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const auth = useAuth();
  const { data, loading, error } = useQuery(GET_FORUM_POSTS, {
    variables: { id: auth.collegeId },
  });

  if (loading) return <Loading />;

  if (error) console.log(error.toString());

  return (
    <>
      {data?.forums?.edges?.map((post) => {
        return (
          <Box p={3} pt={0} key={post.node?.id}>
            <ForumCard data={post.node} />
          </Box>
        );
      })}
    </>
  );
};

export default CollegeForums;
