import React, { useEffect, useState, forwardRef, ForwardedRef, useImperativeHandle } from "react";
import {
  Box,
  Input,
  MenuItem,
  Select,
  Grid,
  FormControl,
  InputLabel,
  ListItemText,
  Checkbox,
  RadioGroup,
  Typography,
  FormControlLabel,
  Slider
} from "@mui/material";
import { useTheme, TextField } from "@mui/material";
import { makeStyles } from '@mui/styles';
import states from "./States";
import { gql, useQuery, useLazyQuery } from "@apollo/client";
import NumberFormat from "react-number-format";
import { Input as StyledInput, Button } from ".";
import { useAuth } from "../hooks/useAuth";
import { getFiltersFromFilterQuery } from "../util/util";
import { ForwardRefComponent } from "framer-motion";
import { width } from "@mui/system";

const useStyles = makeStyles((theme: any) => ({
  formControl: {
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2),
    minWidth: 180,
    maxWidth: 190,
  },
  formControlRight: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(0),
    minWidth: 180,
    maxWidth: 190,
  },
  popover: {
    width: 160,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 300,
      backgroundColor: "#181821",
      borderRadius: 17,
      outlineColor: "#494951",
      outlineStyle: "solid",
      outlineWidth: 1,
    },
  },
};
const MenuPropsWide = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 550,
      backgroundColor: "#181821",
      borderRadius: 17,
      outlineColor: "#494951",
      outlineStyle: "solid",
      outlineWidth: 1,
    },
  },
};
const MenuPropsNarrow = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
      backgroundColor: "#181821",
      borderRadius: 17,
      outlineColor: "#494951",
      outlineStyle: "solid",
      outlineWidth: 1,
    },
  },
};
const ETHNICITIES = [
  "African American/Black",
  "American Indian",
  "Asian",
  "Caucasian",
  "Hispanic",
  "Indian",
  "Native Hawaiian",
  "Other",
];
const GENDERS = ["Male", "Female"];
const GRADYEARS = ["2022", "2023", "2024", "2025", "2026", "2027", "2028", "2029", "2030", "2031", "2032"];
const PARENTS_ED = [
  { label: "None", value: undefined },
  { label: "High School Diploma", value: "highschool" },
  { label: "GED", value: "ged" },
  { label: "Bachelor's Degree", value: "bachelors" },
  { label: "Master's Degree", value: "masters" },
  { label: "Doctorate Degree", value: "doctorate" },
];
const FROM_SOURCE = ["Organic", "Leads"];

const GET_ALL_MAJORS = gql`
  query getAllMajors {
    majors(order: name_ASC, first: 1000) {
      edges {
        node {
          id
          objectId
          name
          category
        }
      }
    }
  }
`;

const GET_ALL_INTERESTS = gql`
  query getAllInterests {
    profileInterests(order: priority_ASC, first: 1000) {
      edges {
        node {
          id
          objectId
          name
          category
          priority
        }
      }
    }
  }
`;

const SEARCH_HIGHSCHOOL = gql`
  query searchUsers(
    $filters: [UserStudentWhereInput!]
    $majorsFilter: MajorRelationWhereInput
    $interestsFilter: ProfileInterestRelationWhereInput
    $collegesFilter: CollegeRelationWhereInput
  ) {
    userStudents(
      where: {
        AND: $filters
        gender: { exists: true }
        fromLead: { equalTo: false }
        interests: $interestsFilter
        generalInterestedMajors: $majorsFilter
        generalInterestedColleges: $collegesFilter
      }
    ) {
      count
    }
  }
`;

const SEARCH_LEADS = gql`
  query searchLeads(
    $collegeId: ID!
    $filters: [UserStudentWhereInput!]
    $majorsFilter: MajorRelationWhereInput
    $interestsFilter: ProfileInterestRelationWhereInput
    $collegesFilter: CollegeRelationWhereInput
  ) {
    leads(
      where: {
        student: { 
          have: { 
            AND: $filters 
            interests: $interestsFilter
            generalInterestedMajors: $majorsFilter
            generalInterestedColleges: $collegesFilter
          } 
        }
        hasCompletedProfile: { equalTo: true } 
        college: { have: { objectId: { equalTo: $collegeId } } }
      }
    ) {
      count
    }
  }
`;

const SEARCH_COLLEGEORALUM = gql`
  query searchUsers(
    $filters: [UserStudentWhereInput!]
    $majorsFilter: MajorRelationWhereInput
    $interestsFilter: ProfileInterestRelationWhereInput
    $collegesFilter: CollegeRelationWhereInput
  ) {
    userStudents(
      where: {
        AND: $filters
        gender: { exists: true }
        fromLead: { equalTo: false }
        interests: $interestsFilter
        generalInterestedMajors: $majorsFilter
        college: $collegesFilter
      }
    ) {
      count
    }
  }
`;

const Filters = forwardRef((props: any, ref: ForwardedRef<unknown>) => {
  useImperativeHandle(ref, () => ({
    apply
  }));

  const theme = useTheme();
  const classes = useStyles(theme);
  const auth = useAuth();

  const { data: majorsData } = useQuery(GET_ALL_MAJORS);
  const { data: interestsData } = useQuery(GET_ALL_INTERESTS);

  const [searchHighschool, { data: searchData, loading: searchLoading }] = useLazyQuery(
    SEARCH_HIGHSCHOOL,
    { fetchPolicy: "network-only" }
  );
  const [searchLeads, { data: searchLeadsData, loading: searchLeadsLoading }] = useLazyQuery(
    SEARCH_LEADS,
    { fetchPolicy: "network-only" }
  );
  const [searchCollege, { data: searchCollegeData, loading: searchCollegeLoading }] = useLazyQuery(
    SEARCH_COLLEGEORALUM,
    { fetchPolicy: "network-only" }
  );

  const [filters, setFilters] = useState<any>({
    list: [],
    loading: false,
    numStudents: undefined,
  });

  const [majorsDict, setMajorsDict] = useState({});
  const [checkedInterested, setCheckedInterested] = useState(false);
  const [TYPES, setTYPES] = useState<any>([]);
  const [type = "highschool_student", setType] = useState<any>();
  const [ethnicities, setEthnicities] = useState<any>([]);
  const [locations, setLocations] = useState<any>([]);
  const [genders, setGenders] = useState<any>([]);
  const [gradyears, setGradyears] = useState<any>([]);
  const [studentInterestsDict, setStudentInterestsDict] = useState({});
  const [studentInterests, setStudentInterests] = useState<any>([]);
  const [majors, setMajors] = useState<any>([]);
  const [parentsEd, setParentsEd] = useState<any>(undefined);
  const [checkedSAT, setCheckedSAT] = useState(false);
  const [SATfrom, setSATfrom] = useState<any>("");
  const [SATto, setSATto] = useState<any>("");
  const [checkedACT, setCheckedACT] = useState<any>(false);
  const [ACTfrom, setACTfrom] = useState<any>(undefined);
  const [ACTto, setACTto] = useState<any>(undefined);
  const [checkedGPA, setCheckedGPA] = useState<any>(false);
  const [GPAfrom, setGPAfrom] = useState<any>(undefined);
  const [GPAto, setGPAto] = useState<any>(undefined);
  const [checkedRank, setCheckedRank] = useState(false);
  const [Rankfrom, setRankfrom] = useState<any>(undefined);
  const [Rankto, setRankto] = useState<any>(undefined);
  const [zip, setZip] = useState(undefined);
  const [zipRange, setZipRange] = useState(30);
  const [fromSource = "Organic", setFromSource] = useState<any>();

  const apply = async (filterQuery, updateSaveData) => {
    if (props.onApply) {
      props.onApply();
    }

    var obj = null;

    var lat = 0.0;
    var lng = 0.0;
    var zipStr = filterQuery ? filterQuery?.zipCode : zip;
    await fetch("https://maps.googleapis.com/maps/api/geocode/json?address=" + zipStr + '&key=' + 'AIzaSyCaOCAZzMinsPPM-bNUQcXfFerpJPerGQI')
      .then(response => response.json())
      .then(data => {
        if (data.results[0]) {
          if (data.results[0].geometry != null) {
            lat = data.results[0].geometry.location.lat;
            lng = data.results[0].geometry.location.lng;

            if (filterQuery) {
              var obj1 = Object.assign({}, filterQuery);
              obj1["lat"] = lat;
              obj1["lng"] = lng;

              obj = obj1;
            }
          }
        }
      })

    const list = getFiltersFromFilterQuery(
      obj ?? {
        type: type,
        fromSource: ((type == "highschool_student" || type == "student_parent") && props.disableFromSourceOption == false) ? fromSource : undefined,
        ethnicities: ethnicities,
        locations: locations,
        genders: genders,
        gradyears: gradyears,
        parentsEd: parentsEd,
        SATfrom: checkedSAT && SATfrom !== "" ? parseFloat(SATfrom) : undefined,
        SATto: checkedSAT && SATto !== "" ? parseFloat(SATto) : undefined,
        ACTfrom: checkedACT ? parseFloat(ACTfrom) : undefined,
        ACTto: checkedACT ? parseFloat(ACTto) : undefined,
        GPAfrom: checkedGPA ? parseFloat(GPAfrom) : undefined,
        GPAto: checkedGPA ? parseFloat(GPAto) : undefined,
        RANKfrom: checkedRank ? parseFloat(Rankfrom) / 100 : undefined,
        RANKto: checkedRank ? parseFloat(Rankto) / 100 : undefined,
        zipCode: zip,
        zipRange: zipRange,
        lat: lat,
        lng: lng,
        collegeId: ((type == "college_student" || type == "college_alumni") && checkedInterested) ? auth.collegeId : undefined
      },
      auth.collegeId
    );
    console.log(list);
    let majorsFilter: any = undefined;
    if (majors?.length > 0) {
      majorsFilter = {
        have: {
          objectId: { in: majors },
        },
      };
    }
    let interestsFilter: any = undefined;
    if (studentInterests?.length > 0) {
      interestsFilter = {
        have: {
          objectId: { in: studentInterests },
        },
      };
    }
    let collegesFilter: any = undefined;
    if (checkedInterested || filterQuery?.interestedStudentsOnly) {
      collegesFilter = {
        have: { objectId: { equalTo: auth.collegeId } },
      };
    }
    if (updateSaveData) {
      setFilters({
        ...filters,
        list: list.length > 0 ? list : undefined,
        majorsFilter: majorsFilter,
        interestsFilter: interestsFilter,
        collegesFilter: collegesFilter,
        saveData: {
          type: type,
          fromSource: ((type == "highschool_student" || type == "student_parent") && props.disableFromSourceOption == false) ? fromSource : undefined,
          ethnicities: ethnicities,
          locations: locations,
          genders: genders,
          gradyears: gradyears,
          majors: majors,
          interests: studentInterests,
          parentsEd: parentsEd,
          SATfrom:
            checkedSAT && SATfrom !== "" ? parseFloat(SATfrom) : undefined,
          SATto: checkedSAT && SATto !== "" ? parseFloat(SATto) : undefined,
          ACTfrom: checkedACT ? parseFloat(ACTfrom) : undefined,
          ACTto: checkedACT ? parseFloat(ACTto) : undefined,
          GPAfrom: checkedGPA ? parseFloat(GPAfrom) : undefined,
          GPAto: checkedGPA ? parseFloat(GPAto) : undefined,
          RANKfrom: checkedRank ? parseFloat(Rankfrom) / 100 : undefined,
          RANKto: checkedRank ? parseFloat(Rankto) / 100 : undefined,
          zip: zip,
          zipRange: zipRange,
          interestedStudentsOnly: checkedInterested,
          collegeId: ((type == "college_student" || type == "college_alumni") && checkedInterested) ? auth.collegeId : undefined
        },
      });
    } else {
      if (type == "highschool_student" || type == "student_parent") {
        setFilters({
          ...filters,
          list: list.length > 0 ? list : undefined,
          majorsFilter: majorsFilter,
          interestsFilter: interestsFilter,
          collegesFilter: collegesFilter,
        });

      } else {
        setFilters({
          ...filters,
          list: list.length > 0 ? list : undefined,
          majorsFilter: majorsFilter,
          interestsFilter: interestsFilter,
        });
      }
    }

    if (!props.noSearch) {
      if (type == "highschool_student" || type == "student_parent") {
        if (props.customType == 'leads' || fromSource == 'Leads') {
          searchLeads({
            variables: {
              filters: list.length > 0 ? list : undefined,
              collegeId: auth.collegeId,
              majorsFilter: majorsFilter,
              interestsFilter: interestsFilter,
              collegesFilter: collegesFilter,
            },
          });

        } else {
          searchHighschool({
            variables: {
              filters: list.length > 0 ? list : undefined,
              majorsFilter: majorsFilter,
              interestsFilter: interestsFilter,
              collegesFilter: collegesFilter,
            },
          });
        }

      } else if (type == "college_student" || type == "college_alumni") {
        searchCollege({
          variables: {
            filters: list.length > 0 ? list : undefined,
            majorsFilter: majorsFilter,
            interestsFilter: interestsFilter,
            collegesFilter: collegesFilter,
          },
        });
      }
    }
  };

  useEffect(() => {
    if (searchLoading) {
      setFilters((f) => {
        return {
          ...f,
          loading: true,
        };
      });
    }
  }, [searchLoading]);

  useEffect(() => {
    if (searchLeadsLoading) {
      setFilters((f) => {
        return {
          ...f,
          loading: true,
        };
      });
    }
  }, [searchLeadsLoading]);

  useEffect(() => {
    if (searchCollegeLoading) {
      setFilters((f) => {
        return {
          ...f,
          loading: true,
        };
      });
    }
  }, [searchCollegeLoading]);

  useEffect(() => {
    if (searchData) {
      setFilters((f) => {
        return {
          ...f,
          loading: false,
          numStudents: searchData?.userStudents?.count,
          saveData: {
            ...f.saveData,
            audienceSize: searchData?.userStudents?.count,
          },
        };
      });

      if (props.setAudienceSize) {
        props.setAudienceSize(searchData?.userStudents?.count);
      }
    }
  }, [searchData]);

  useEffect(() => {
    if (searchLeadsData) {
      setFilters((f) => {
        return {
          ...f,
          loading: false,
          numStudents: searchLeadsData?.leads?.count,
          saveData: {
            ...f.saveData,
            audienceSize: searchLeadsData?.leads?.count,
          },
        };
      });

      if (props.setAudienceSize) {
        props.setAudienceSize(searchLeadsData?.leads?.count);
      }
    }
  }, [searchLeadsData]);

  useEffect(() => {
    if (searchCollegeData) {
      setFilters((f) => {
        return {
          ...f,
          loading: false,
          numStudents: searchCollegeData?.userStudents?.count,
          saveData: {
            ...f.saveData,
            audienceSize: searchCollegeData?.userStudents?.count,
          },
        };
      });

      if (props.setAudienceSize) {
        props.setAudienceSize(searchCollegeData?.userStudents?.count);
      }
    }
  }, [searchCollegeData]);

  useEffect(() => {
    if (majorsData) {
      const dict = {};
      majorsData.majors.edges.forEach((e) => {
        dict[e.node.objectId] = e.node.name;
      });
      setMajorsDict(dict);
    }
  }, [majorsData]);

  useEffect(() => {
    if (interestsData) {
      const dict = {};
      interestsData.profileInterests.edges.forEach((e) => {
        dict[e.node.objectId] = e.node.name;
      });
      setStudentInterestsDict(dict);
    }
  }, [interestsData]);

  useEffect(() => {
    if (props.filterQuery) {
      // console.log("loading fq ", props.filterQuery);
      setCheckedInterested(props.filterQuery?.interestedStudentsOnly);
      setType(props.filterQuery?.type);
      setFromSource(props.filterQuery?.fromSource);
      setEthnicities(props.filterQuery?.ethnicities?.map((x) => x.value) ?? []);
      setGenders(props.filterQuery?.genders?.map((x) => x.value) ?? []);
      setLocations(props.filterQuery?.locations?.map((x) => x.value) ?? []);
      setGradyears(props.filterQuery?.gradyears?.map((x) => x.value) ?? []);
      setMajors(props.filterQuery?.majors?.map((x) => x.value) ?? []);
      setStudentInterests(props.filterQuery?.interests?.map((x) => x.value) ?? []);
      setParentsEd(props.filterQuery?.parentsEd);
      if (props.filterQuery?.SATfrom || props.filterQuery?.SATto) {
        setCheckedSAT(true);
        setSATfrom(props.filterQuery?.SATfrom);
        setSATto(props.filterQuery?.SATto);
      }
      if (props.filterQuery?.ACTfrom || props.filterQuery?.ACTto) {
        setCheckedACT(true);
        setACTfrom(props.filterQuery?.ACTfrom);
        setACTto(props.filterQuery?.ACTto);
      }
      setZip(props.filterQuery?.zipCode);
      setZipRange(props.filterQuery?.zipRange ?? 30);
      if (props.filterQuery?.GPAfrom || props.filterQuery?.GPAto) {
        setCheckedGPA(true);
        setGPAfrom(props.filterQuery?.GPAfrom);
        setGPAto(props.filterQuery?.GPAto);
      }
      if (props.filterQuery?.RANKfrom || props.filterQuery?.RANKto) {
        setCheckedRank(true);
        setRankfrom(parseFloat(props.filterQuery?.RANKfrom));
        setRankto(parseFloat(props.filterQuery?.RANKto));
      }
      if (
        props.filterQuery?.audienceSize !== null ||
        props.filterQuery?.audienceSize !== undefined
      ) {
        setFilters({
          ...filters,
          numStudents: props.filterQuery?.audienceSize,
          saveData: {
            audienceSize: props.filterQuery?.audienceSize,
            ethnicities:
              props.filterQuery?.ethnicities?.map((x) => x.value) ?? [],
            locations: props.filterQuery?.locations?.map((x) => x.value) ?? [],
            genders: props.filterQuery?.genders?.map((x) => x.value) ?? [],
            gradyears: props.filterQuery?.gradyears?.map((x) => x.value) ?? [],
            majors: props.filterQuery?.majors?.map((x) => x.value) ?? [],
            interests: props.filterQuery?.interests?.map((x) => x.value) ?? [],
            parentsEd: props.filterQuery?.parentsEd,
            type: props.filterQuery?.type,
            fromSource: props.filterQuery?.fromSource,
            SATfrom: props.filterQuery?.SATfrom,
            SATto: props.filterQuery?.SATto,
            ACTfrom: props.filterQuery?.ACTfrom,
            ACTto: props.filterQuery?.ACTto,
            GPAfrom: props.filterQuery?.GPAfrom,
            GPAto: props.filterQuery?.GPAto,
            RANKfrom: props.filterQuery?.RANKfrom,
            RANKto: props.filterQuery?.RANKto,
            zipCode: props.filterQuery?.zipCode,
            zipRange: props.filterQuery?.zipRange,
            interestedStudentsOnly: props.filterQuery?.interestedStudentsOnly,
          },
        });
        // apply(props.filterQuery, false);
      } else {
        apply(props.filterQuery, true);
      }
    }
    // eslint-ignore-next-line
  }, [props.filterQuery]);

  useEffect(() => {
    props.setFilters(filters);

    if (props.setSource) {
      props.setSource(fromSource);
    }

  }, [filters, props, fromSource]);

  useEffect(() => {
    if (props.customType == 'leads' || fromSource == 'Leads') {
      setTYPES(
        [
          { label: "Highschool Student", value: "highschool_student" },
          { label: "Parent of Student", value: "student_parent" }
        ]
      )

    } else {
      setTYPES(
        [
          { label: "Highschool Student", value: "highschool_student" },
          { label: "College Student/Highschool Grad", value: "college_student" },
          { label: "College Alumni", value: "college_alumni" },
          { label: "Parent of Student", value: "student_parent" },
        ]
      )
    }

    if ((type == "college_student" || type == "college_alumni") && fromSource == "Leads") {

    }
  }, [props, fromSource]);

  useEffect(() => {
    if ((type == "college_student" || type == "college_alumni") && fromSource == "Leads") {
      setFromSource("Organic");
    }
  }, [type]);

  useEffect(() => {
    if (props.applyOnLoad) {
      apply(props.filterQuery, true);
    }
  }, [])

  return (
    <Grid
      container
      spacing={2}
      paddingRight={props.isNarrow ? 2 : 0}
      paddingBottom={props.isNarrow ? 2 : 0}
    >
      <Grid item xs={props.isNarrow ? 7 : 8}>
        {!props.disableInterestedStudentsOption && (
          <FormControlLabel
            style={{
              marginBottom: theme.spacing(2),
              marginRight: theme.spacing(2),
            }}
            control={
              <Checkbox
                sx={{
                  color: theme.palette.text.secondary,
                  '&.Mui-checked': {
                    color: theme.palette.secondary.main,
                  },
                }}
                name="interestedStudents"
                checked={checkedInterested}
                disabled={props.disabled}
                onChange={(e) => {
                  setCheckedInterested(e.target.checked);
                }}
              />
            }
            label={type == "college_alumni" ? <Typography style={{ fontSize: 15 }}>Attended this school</Typography> : type == "college_student" ? <Typography style={{ fontSize: 15 }}>Attends this school</Typography> : <Typography style={{ fontSize: 15 }}>Interested in this school</Typography>}
          />
        )}
      </Grid>
      <Grid item xs={props.isNarrow ? 5 : 4}>
        {!props.disableFromSourceOption && (
          <FormControl
            fullWidth
            style={{
              marginBottom: theme.spacing(2),
              marginLeft: theme.spacing(0),
            }}>
            <InputLabel style={{ marginLeft: -13 }}>Source</InputLabel>
            <Select
              label="Source"
              name="source"
              value={fromSource ?? ""}
              onChange={(e: any) => setFromSource(e.target.value)}
              disabled={props.disabled || (type == 'college_student' || type == 'college_alumni')}
              input={<Input />}
              MenuProps={MenuPropsNarrow}
              placeholder={fromSource}
            >
              {FROM_SOURCE.map((g) => (
                <MenuItem key={g} value={g}>
                  {g}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Grid>
      <Grid item xs={props.isNarrow ? 12 : 4}>
        <FormControl
          fullWidth
          style={{
            marginBottom: theme.spacing(2),
            marginRight: theme.spacing(2),
          }}>
          <InputLabel style={{ marginLeft: -13, fontSize: 15 }} sx={{ "&.MuiInputLabel-shrink": { marginTop: 1 } }}>Classification</InputLabel>
          <Select
            label="Classification"
            name="type"
            value={type ?? ""}
            style={{ fontSize: 15 }}
            onChange={(e: any) => setType(e.target.value)}
            fullWidth
            disabled={props.disabled}
            input={<Input />}
            MenuProps={MenuProps}
            placeholder={type}
          >
            {TYPES.map((g: any) => (
              <MenuItem key={g.value} value={g.value}>
                {g.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl
          fullWidth
          style={{
            marginBottom: theme.spacing(2),
            marginRight: theme.spacing(2),
          }}>
          <InputLabel style={{ marginLeft: -13, fontSize: 15 }} sx={{ "&.MuiInputLabel-shrink": { marginTop: 1 } }}>Ethnicities</InputLabel>
          <Select
            variant="filled"
            label="Ethnicities"
            name="ethnicity"
            multiple
            value={ethnicities}
            style={{ fontSize: 15 }}
            onChange={(e: any) => setEthnicities(e.target.value)}
            sx={{ width: "100%" }}
            disabled={props.disabled}
            input={<Input />}
            renderValue={(selected) => selected.join(", ")}
            MenuProps={MenuProps}
          >
            {ETHNICITIES.map((eth) => (
              <MenuItem key={eth} value={eth}>
                <Checkbox sx={{
                  color: theme.palette.text.secondary,
                  '&.Mui-checked': {
                    color: theme.palette.secondary.main,
                  },
                }} checked={ethnicities.indexOf(eth) > -1} />
                <ListItemText primary={eth} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl
          fullWidth
          style={{
            marginBottom: theme.spacing(2),
            marginRight: theme.spacing(2),
          }}>
          <InputLabel style={{ marginLeft: -13, fontSize: 15 }} sx={{ "&.MuiInputLabel-shrink": { marginTop: 1 } }}>Genders</InputLabel>
          <Select
            variant="filled"
            label="Genders"
            name="genders"
            multiple
            value={genders}
            style={{ fontSize: 15 }}
            onChange={(e) => setGenders(e.target.value)}
            fullWidth
            disabled={props.disabled}
            input={<Input />}
            renderValue={(selected) => selected.join(", ")}
            MenuProps={MenuPropsNarrow}
          >
            {GENDERS.map((g) => (
              <MenuItem key={g} value={g}>
                <Checkbox sx={{
                  color: theme.palette.text.secondary,
                  '&.Mui-checked': {
                    color: theme.palette.secondary.main,
                  },
                }} checked={genders.indexOf(g) > -1} />
                <ListItemText primary={g} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl
          fullWidth
          style={{
            marginBottom: theme.spacing(2),
            marginRight: theme.spacing(2),
          }}>
          <InputLabel style={{ marginLeft: -13, fontSize: 15 }} sx={{ "&.MuiInputLabel-shrink": { marginTop: 1 } }}>States</InputLabel>
          <Select
            variant="filled"
            label="States"
            name="locations"
            multiple
            value={locations}
            style={{ fontSize: 15 }}
            onChange={(e) => setLocations(e.target.value)}
            fullWidth
            disabled={props.disabled}
            input={<Input />}
            renderValue={(selected) => selected.join(", ")}
            MenuProps={MenuProps}
          >
            {states.map((s) => (
              <MenuItem key={s.value} value={s.value}>
                <Checkbox sx={{
                  color: theme.palette.text.secondary,
                  '&.Mui-checked': {
                    color: theme.palette.secondary.main,
                  },
                }} checked={locations.indexOf(s.value) > -1} />
                <ListItemText primary={s.label} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl
          fullWidth
          style={{
            marginBottom: theme.spacing(2),
            marginRight: theme.spacing(2),
          }}>
          <InputLabel style={{ marginLeft: -13, fontSize: 15 }} sx={{ "&.MuiInputLabel-shrink": { marginTop: 1 } }}>Graduation Years</InputLabel>
          <Select
            variant="filled"
            label="Graduation Years"
            name="gradyears"
            multiple
            value={gradyears}
            style={{ fontSize: 15 }}
            onChange={(e) => setGradyears(e.target.value)}
            fullWidth
            disabled={props.disabled}
            input={<Input />}
            renderValue={(selected) => selected.join(", ")}
            MenuProps={MenuPropsNarrow}
          >
            {GRADYEARS.map((g) => (
              <MenuItem key={g} value={g}>
                <Checkbox sx={{
                  color: theme.palette.text.secondary,
                  '&.Mui-checked': {
                    color: theme.palette.secondary.main,
                  },
                }} checked={gradyears.indexOf(g) > -1} />
                <ListItemText primary={g} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl
          fullWidth
          style={{
            marginBottom: theme.spacing(2),
            marginRight: theme.spacing(2),
          }}>
          <InputLabel style={{ marginLeft: -13, fontSize: 15 }} sx={{ "&.MuiInputLabel-shrink": { marginTop: 1 } }}>Student Interests</InputLabel>
          <Select
            variant="filled"
            label="Student Interests"
            name="interests"
            multiple
            value={studentInterests}
            style={{ fontSize: 15 }}
            onChange={(e) => setStudentInterests(e.target.value)}
            fullWidth
            disabled={props.disabled}
            input={<Input />}
            renderValue={(selected) =>
              selected.map((x) => studentInterestsDict[x]).join(", ")
            }
            MenuProps={MenuProps}
          >
            {interestsData?.profileInterests?.edges
              ?.map((e) => e.node)
              ?.map((g) => (
                <MenuItem key={g.id} value={g.objectId}>
                  <Checkbox sx={{
                    color: theme.palette.text.secondary,
                    '&.Mui-checked': {
                      color: theme.palette.secondary.main,
                    },
                  }} checked={studentInterests.indexOf(g.objectId) > -1} />
                  <ListItemText primary={g.name} />
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <FormControl
          fullWidth
          style={{
            marginBottom: theme.spacing(2),
            marginRight: theme.spacing(2),
          }}>
          <InputLabel style={{ marginLeft: -13, fontSize: 15 }} sx={{ "&.MuiInputLabel-shrink": { marginTop: 1 } }}>Majors</InputLabel>
          <Select
            label="Majors"
            name="majors"
            multiple
            value={majors}
            style={{ fontSize: 15 }}
            onChange={(e) => setMajors(e.target.value)}
            fullWidth
            disabled={props.disabled}
            input={<Input />}
            renderValue={(selected) =>
              selected.map((x) => majorsDict[x]).join(", ")
            }
            MenuProps={MenuPropsWide}
          >
            {majorsData?.majors?.edges
              ?.map((e) => e.node)
              ?.map((g) => (
                <MenuItem key={g.id} value={g.objectId}>
                  <Checkbox sx={{
                    color: theme.palette.text.secondary,
                    '&.Mui-checked': {
                      color: theme.palette.secondary.main,
                    },
                  }} checked={majors.indexOf(g.objectId) > -1} />
                  <ListItemText primary={g.name} />
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <FormControl
          fullWidth
          style={{
            marginBottom: theme.spacing(2),
            marginRight: theme.spacing(2),
          }}>
          <InputLabel style={{ marginLeft: -13, fontSize: 15 }} sx={{ "&.MuiInputLabel-shrink": { marginTop: 1 } }}>Parents Highest Ed</InputLabel>
          <Select
            label="Parents Highest Ed"
            name="parentsEd"
            value={parentsEd ?? ""}
            style={{ fontSize: 15 }}
            onChange={(e) => setParentsEd(e.target.value)}
            fullWidth
            disabled={props.disabled}
            input={<Input />}
            MenuProps={MenuProps}
            placeholder={parentsEd}
          >
            {PARENTS_ED.map((g) => (
              <MenuItem key={g.value} value={g.value}>
                {g.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={props.isNarrow ? 6 : 4}>
        {/* {!props.disableFromSourceOption && (
          <Box height="65px" />
        )} */}
        <FormControlLabel
          style={{ color: theme.palette.text.secondary }}
          control={
            <Checkbox
              sx={{
                color: theme.palette.text.secondary,
                '&.Mui-checked': {
                  color: theme.palette.secondary.main,
                },
              }}
              name="satRange"
              checked={checkedSAT}
              disabled={props.disabled}
              onChange={(e) => setCheckedSAT(e.target.checked)}
            />
          }
          label={<Typography style={{ fontSize: 11, fontWeight: 600 }}>SAT RANGE</Typography>}
        />
        <StyledInput
          label="From"
          style={{
            marginRight: 1
          }}
          disabled={props.disabled ? true : !checkedSAT}
          onChange={(e) => setSATfrom(e.target.value)}
          defaultValue={
            props.filterQuery?.SATfrom ? props.filterQuery?.SATfrom : null
          }
        />
        <Box height="10px" />
        <StyledInput
          label="To"
          style={{
            marginRight: 1
          }}
          disabled={props.disabled ? true : !checkedSAT}
          onChange={(e) => setSATto(e.target.value)}
          defaultValue={
            props.filterQuery?.SATto ? props.filterQuery?.SATto : null
          }
        />
        <Box height={10} />
        <FormControlLabel
          style={{ color: theme.palette.text.secondary }}
          control={
            <Checkbox
              sx={{
                color: theme.palette.text.secondary,
                '&.Mui-checked': {
                  color: theme.palette.secondary.main,
                },
              }}
              name="actRange"
              checked={checkedACT}
              disabled={props.disabled}
              onChange={(e) => setCheckedACT(e.target.checked)}
            />
          }
          label={<Typography style={{ fontSize: 11, fontWeight: 600 }}>ACT RANGE</Typography>}
        />
        <StyledInput
          label="From"
          style={{
            marginRight: 1
          }}
          disabled={props.disabled ? true : !checkedACT}
          onChange={(e) => setACTfrom(e.target.value)}
          defaultValue={
            props.filterQuery?.ACTfrom ? props.filterQuery?.ACTfrom : null
          }
        />
        <Box height="10px" />
        <StyledInput
          label="To"
          style={{
            marginRight: 1
          }}
          disabled={props.disabled ? true : !checkedACT}
          onChange={(e) => setACTto(e.target.value)}
          defaultValue={
            props.filterQuery?.ACTto ? props.filterQuery?.ACTto : null
          }
        />
        <Box height="20px" />
        <StyledInput
          variant="filled"
          label="Zip Code"
          style={{
            marginRight: 1
          }}
          disabled={props.disabled}
          value={zip}
          onChange={(e) => setZip(e.target.value)}
        />
        <div style={{ marginTop: theme.spacing(1), display: 'block', alignItems: 'center' }}>
          <Slider
            aria-label="ZipCode"
            value={zipRange}
            disabled={props.disabled}
            size="small"
            defaultValue={30}
            min={1}
            max={100}
            valueLabelDisplay="auto"
            style={{ width: '90%' }}
            sx={{
              '& .MuiSlider-thumb': {
                color: "#35c669"
              },
              '& .MuiSlider-track': {
                color: "#35c669"
              },
              '& .MuiSlider-rail': {
                color: "#acc4e4"
              },
              '& .MuiSlider-valueLabel': {
                color: "white",
                bgcolor: "#434354",
                fontWeight: 600
              }
            }}
            onChange={(e: any) => {
              setZipRange(e.target.value);
            }}
          />
          <Typography
            width={"90%"}
            fontSize={9}
            marginTop={-0.5}
            marginBottom={1}
            fontWeight={600}
            color='textSecondary'
            style={{
              height: 11
            }}
          >
            {"WITHIN MILES (" + zipRange + ")"}
          </Typography>
        </div>
      </Grid>
      <Grid item xs={props.isNarrow ? 6 : 4} mb={4}>
        <FormControlLabel
          style={{ color: theme.palette.text.secondary }}
          control={
            <Checkbox
              sx={{
                color: theme.palette.text.secondary,
                '&.Mui-checked': {
                  color: theme.palette.secondary.main,
                },
              }}
              name="gpaRange"
              checked={checkedGPA}
              disabled={props.disabled}
              onChange={(e) => setCheckedGPA(e.target.checked)}
            />
          }
          label={<Typography style={{ fontSize: 11, fontWeight: 600 }}>GPA RANGE</Typography>}
        />
        <StyledInput
          label="From"
          style={{
            marginRight: 1
          }}
          disabled={props.disabled ? true : !checkedGPA}
          onChange={(e) => setGPAfrom(e.target.value)}
          defaultValue={
            props.filterQuery?.GPAfrom ? props.filterQuery?.GPAfrom : null
          }
        />
        <Box height="10px" />
        <StyledInput
          label="To"
          style={{
            marginRight: 1
          }}
          disabled={props.disabled ? true : !checkedGPA}
          onChange={(e) => setGPAto(e.target.value)}
          defaultValue={
            props.filterQuery?.GPAto ? props.filterQuery?.GPAto : null
          }
        />
        <Box height={10} />
        <FormControlLabel
          style={{ color: theme.palette.text.secondary }}
          control={
            <Checkbox
              sx={{
                color: theme.palette.text.secondary,
                '&.Mui-checked': {
                  color: theme.palette.secondary.main,
                },
              }}
              name="rankRange"
              checked={checkedRank}
              disabled={props.disabled}
              onChange={(e) => setCheckedRank(e.target.checked)}
            />
          }
          label={<Typography style={{ fontSize: 11, fontWeight: 600 }}>CLASS RANK</Typography>}
        />
        <NumberFormat
          variant="filled"
          label="From"
          disabled={props.disabled ? true : !checkedRank}
          onChange={(e) => setRankfrom(e.target.value)}
          customInput={StyledInput}
          style={{
            marginRight: 1
          }}
          suffix="%"
          defaultValue={
            props.filterQuery?.RANKfrom
              ? props.filterQuery?.RANKfrom * 100
              : 0
          }
        />
        <Box height="10px" />
        <NumberFormat
          variant="filled"
          label="To"
          disabled={props.disabled ? true : !checkedRank}
          onChange={(e) => setRankto(e.target.value)}
          customInput={StyledInput}
          style={{
            marginRight: 1
          }}
          suffix="%"
          defaultValue={
            props.filterQuery?.RANKto ? props.filterQuery?.RANKto * 100 : 0
          }
        />
        {!props.autoApply && !props.isNarrow && (
          <Box alignContent="center">
            <Box height="28px" />
            <Button fullWidth disabled={props.disabled} onClick={() => apply(undefined, true)}>
              Apply Filters
            </Button>
          </Box>
        )}
      </Grid>
    </Grid>
  );
});

export default Filters;
