import React, { useEffect, useState } from "react";
import Parse from "parse";
import {
  Box,
  Divider,
  IconButton,
  Button,
  Typography,
} from "@mui/material";
import { useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import DetailHeader from "./DetailHeader";
import MessageList from "./MessageList";
import MessageComposer from "./MessageComposer";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Loading, Page } from "../../../../components";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/client";
import { useAuth } from "../../../../hooks/useAuth";
import { maxWidth, minHeight } from "@mui/system";

const useStyles = makeStyles((theme: any) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
}));

const GET_THREAD = gql`
  query getThread($id: ID!) {
    collegeThread(id: $id) {
      student {
        id
        objectId
        firstName
        lastName
        userStudent {
          id
          objectId
          verificationStatus
          appliedColleges {
            ... on Element {
              value
            }
          }
          acceptedColleges {
            ... on Element {
              value
            }
          }
          enrolledColleges {
            ... on Element {
              value
            }
          }
        }
        img {
          url
        }
        user {
          id
          objectId
        }
      }
      messages(first: 400, order: createdAt_DESC) {
        edges {
          node {
            id
            objectId
            content
            from {
              id
              objectId
              userUniversity {
                id
              }
              userStudent {
                id
              }
            }
            createdAt
            readByRecipient
          }
        }
      }
    }
  }
`;

const SEND_MESSAGE = gql`
  mutation sendMessage($content: String!, $uuid: ID!, $fromID: ID!, $threadID: ID!, $collegeID: ID!) {
    updateCollegeThread(
      input: {
        id: $threadID
        fields: {
          claimedBy: { link: $uuid }
          messages: {
            createAndAdd: { content: $content, from: { link: $fromID }, college: { link: $collegeID }, readByRecipient: false}
          }
        }
      }
    ) {
      collegeThread {
        id
        objectId
        messages(first: 1) {
          edges {
            node {
              id
              objectId
              from {
                id
                objectId
              }
              content
            }
          }
        }
      }
    }
  }
`;

const Thread = (props) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const { id } = useParams();
  const ucid = Parse.User.current()?.get("userContact").id;
  const { data, loading, error } = useQuery(GET_THREAD, {
    variables: { id, ucid },
    pollInterval: 1000,
  });
  const [sendMessage, { data: sendData, error: sendError }] =
    useMutation(SEND_MESSAGE);
  const [messages, setMessages] = useState<any>([]);
  const title =
    data?.collegeThread?.student?.firstName +
    " " +
    data?.collegeThread?.student?.lastName;

  if (sendError) console.log(JSON.stringify(sendError, null, 2));

  const handleReceivedMessage = async (message) => {
    console.log("Received Message");
    // await Parse.Cloud.run("getThread", { threadID: id }).then((result) => {
    //   setMessages(result.messages);
    // });
  };

  const auth = useAuth();

  const handleSendMessage = async (content) => {
    const today = new Date();
    const fromID = Parse.User.current()?.get("userContact").id;
    const uuid = Parse.User.current()?.get("userUniversity").id;
    const threadID = id;
    const collegeID = auth.collegeId
    console.log(collegeID)
    setMessages(
      [
        ...messages,
        {
          node: {
            createdAt: today.toString(),
            content: content,
            from: {
              objectId: fromID,
            },
          },
        },
      ],
    );
    sendMessage({
      variables: {
        content: content,
        fromID: fromID,
        uuid: uuid,
        threadID: threadID,
        collegeID: collegeID
      }
    });

    await Parse.Cloud.run("sendIOSPush", { type: "dmFromCollege", id: data?.collegeThread.student?.user?.objectId, bodyStr: auth.collegeName + " just sent you a new message!" });
  };

  if (sendData) {
    console.log(
      "delivered",
      sendData?.updateCollegeThread?.collegeThread?.messages?.edges[0].node
        ?.objectId
    );
  }

  useEffect(() => {
    if (data?.collegeThread?.messages) {
      var arr = data?.collegeThread?.messages?.edges;

      const reversedArr: any = []

      for (let i = arr.length - 1; i >= 0; i--) {
        const valueAtIndex = arr[i];

        reversedArr.push(valueAtIndex);
      }

      setMessages(reversedArr);
    }
  }, [data]);

  useEffect(() => {
    document.body.style.backgroundColor = "#0e0e14";
  }, []);

  if (loading) {
    return <Loading lower />;
  }

  if (error) {
    console.log(JSON.stringify(error, null, 2));
  }

  if (data) {
    return (
      <Page title={title} maxWidth="xl">
        <Box
          style={{
            height: "calc(100vh - 135px)"
          }}
        >
          <DetailHeader
            title={title}
            student={data?.collegeThread.student}
          />
          <Box
            overflow="hidden"
            style={{
              height: "calc(100vh - 264px)"
            }}
          >
            <MessageList messages={messages?.map((m: any) => m.node)} />
          </Box>
          <div style={{ position: 'relative', bottom: -10, minHeight: 75 }}>
            <Box
              style={{
                bottom: 10,
                minHeight: 75,
                width: '100%',
                position: 'absolute',
                backgroundColor: '#0e0e14'
              }}
            >
              <Divider />
              <MessageComposer send={handleSendMessage} userId={data?.collegeThread.student.user.objectId} />
            </Box>
          </div>
        </Box>
      </Page>
    );
  }
};

export default Thread;
