import React, { useState, useRef, useEffect, ChangeEvent } from "react";
import {
  Box,
  Card,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CardHeader,
  Container,
  Typography,
  Grid,
  Fade,
  Backdrop,
  IconButton,
  Modal,
  Button as MuiButton
} from "@mui/material";
import { useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import ImageIcon from "@material-ui/icons/Image";
import { Filters, Loading, Input, Button } from "../../../../components";
import { gql, useMutation } from "@apollo/client";
import { useAuth } from "../../../../hooks/useAuth";
import PostCard from "../Posts/PostCard";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Parse from "parse";

const useStyles = makeStyles((theme: any) => ({
  root: {
    margin: theme.spacing(3),
  },
  inputContainer: {
    backgroundColor: theme.palette.background.paper,
    flexGrow: 1,
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(0.5),
  },
  divider: {
    height: 24,
    width: 1,
  },
  fileInput: {
    display: "none",
  },
  button: {
    background: "linear-gradient(75deg, #35c669, #2ec4a9)",
    border: 0,
    color: "#fff",
  },
  buttonDelete: {
    background: theme.palette.error.main,
    border: 0,
    color: "#fff",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: theme.spacing(3),
  },
  paper: {
    maxHeight: 680,
    overflow: "auto",
    backgroundColor: theme.palette.background.dark,
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(4),
    borderRadius: 20,
    outlineColor: "#27272d",
    outlineStyle: "solid",
    outlineWidth: 1,
  },
  imageCard: {
    backgroundColor: theme.palette.primary.light,
    position: "relative",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    outlineColor: "#35353d",
    outlineStyle: "solid",
    outlineWidth: 1,
    "&:before": {
      position: "absolute",
      content: '" "',
      top: 0,
      left: 0,
      height: "100%",
      width: "100%",
    },
  },
  input: {
    outlineColor: "#35353d",
    outlineStyle: "solid",
    outlineWidth: 1,
  },
  vertCenter: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  formControl: {
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2),
    minWidth: 180,
    maxWidth: 190,
  },
  accordion: {
    background: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    '&:before': {
      display: 'none',
    }
  },
  accordianDetails: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}));

const CREATE_FILE = gql`
  mutation createFile($file: Upload!) {
    createFile(input: { upload: $file }) {
      fileInfo {
        name
        url
      }
    }
  }
`;

const CREATE_AD = gql`
  mutation createAd(
    $collegeId1: ID!
    $message: String!
    $link: String
    $file: File
    $ethnicities: [Any!]
    $locations: [Any!]
    $genders: [Any!]
    $gradyears: [Any!]
    $majors: [Any!]
    $interests: [Any!]
    $SATfrom: Float
    $SATto: Float
    $ACTfrom: Float
    $ACTto: Float
    $GPAfrom: Float
    $GPAto: Float
    $RANKfrom: Float
    $RANKto: Float
    $zipCode: String
    $audienceSize: Float
    $interestedStudents: Boolean
    $type: String
    $parentsEd: String
  ) {
    createCollegePost(
      input: {
        fields: {
          isAd: true
          numLikes: 0
          numComments: 0
          finalCost: 0
          by: { link: $collegeId1 }
          message: $message
          link: $link
          img: { file: $file }
          filterQuery: {
            createAndLink: {
              ethnicities: $ethnicities
              locations: $locations
              genders: $genders
              gradyears: $gradyears
              majors: $majors
              interests: $interests
              parentsEd: $parentsEd
              type: $type
              SATfrom: $SATfrom
              SATto: $SATto
              ACTfrom: $ACTfrom
              ACTto: $ACTto
              GPAfrom: $GPAfrom
              GPAto: $GPAto
              RANKfrom: $RANKfrom
              RANKto: $RANKto
              zipCode: $zipCode
              college: { link: $collegeId1 }
              audienceSize: $audienceSize
              interestedStudentsOnly: $interestedStudents
            }
          }
        }
      }
    ) {
      collegePost {
        id
        objectId
        createdAt
        isAd
        link
        finalCost
        filterQuery {
          id
          objectId
          audienceSize
        }
        by {
          id
          objectId
          name
          img {
            url
          }
        }
        img {
          url
        }
        message
        likes {
          count
        }
        comments {
          count
        }
      }
    }
  }
`;

const CREATE_AD_WITHOUT_IMG = gql`
  mutation createAd(
    $collegeId1: ID!
    $message: String!
    $link: String
    $ethnicities: [Any!]
    $locations: [Any!]
    $genders: [Any!]
    $gradyears: [Any!]
    $majors: [Any!]
    $interests: [Any!]
    $SATfrom: Float
    $SATto: Float
    $ACTfrom: Float
    $ACTto: Float
    $GPAfrom: Float
    $GPAto: Float
    $RANKfrom: Float
    $RANKto: Float
    $zipCode: String
    $audienceSize: Float
    $interestedStudents: Boolean
    $type: String
    $parentsEd: String
  ) {
    createCollegePost(
      input: {
        fields: {
          isAd: true
          numLikes: 0
          numComments: 0
          finalCost: 0
          by: { link: $collegeId1 }
          message: $message
          link: $link
          filterQuery: {
            createAndLink: {
              ethnicities: $ethnicities
              locations: $locations
              genders: $genders
              gradyears: $gradyears
              majors: $majors
              interests: $interests
              parentsEd: $parentsEd
              type: $type
              SATfrom: $SATfrom
              SATto: $SATto
              ACTfrom: $ACTfrom
              ACTto: $ACTto
              GPAfrom: $GPAfrom
              GPAto: $GPAto
              RANKfrom: $RANKfrom
              RANKto: $RANKto
              zipCode: $zipCode
              college: { link: $collegeId1 }
              audienceSize: $audienceSize
              interestedStudentsOnly: $interestedStudents
            }
          }
        }
      }
    ) {
      collegePost {
        id
        objectId
        createdAt
        isAd
        link
        finalCost
        filterQuery {
          id
          objectId
          audienceSize
        }
        by {
          id
          objectId
          name
          img {
            url
          }
        }
        img {
          url
        }
        message
        likes {
          count
        }
        comments {
          count
        }
      }
    }
  }
`;

const AdAdd = (props) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const auth = useAuth();
  const hiddenFileInput = useRef<any>(null);
  const [open, setOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [message, setMessage] = useState("");
  const [link, setLink] = useState("");
  const [tempURL, setTempURL] = useState("");
  const [allowedSave, setAllowedSave] = useState(true);
  const [createdAds, setCreatedAds] = useState<any>([]);
  const [audienceSize, setAudienceSize] = useState(undefined);
  const [finalCost, setFinalCost] = useState(0);

  const [filters, setFilters] = useState<any>(undefined);

  const [
    uploadFile,
    { data: fileData, loading: uploading, error: errorUploading },
  ] = useMutation(CREATE_FILE);
  const [createAd, { data: adDataImg, loading: adlLoadingImg, error: adErrorImg }] = useMutation(CREATE_AD);
  const [createAdWithoutImg, { data: adData, loading: adLoading, error: adError }] = useMutation(CREATE_AD_WITHOUT_IMG);

  const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.persist();

    if (event.currentTarget.files) {
      const file = event.currentTarget.files[0];

      uploadFile({ variables: { file } });
      setAllowedSave(false);
    }
    // target: {
    //   files: [file],
    // },
  }

  useEffect(() => {
    if (fileData) {
      setTempURL(fileData?.createFile?.fileInfo?.url);
      setAllowedSave(true);
      console.log({ fileData });
    }
  }, [fileData]);

  useEffect(() => {
    if (errorUploading) {
      setAllowedSave(true);
      console.log("Error uploading image.");
      console.log(JSON.stringify(errorUploading, null, 2));
    }
  }, [errorUploading]);

  useEffect(() => {
    if (adData) {
      const setAds = async () => {
        if (adData?.createCollegePost?.collegePost?.filterQuery) {
          setCreatedAds([adData.createCollegePost?.collegePost, ...createdAds]);

          if (isSaving && props.costPerAdImpression > 0) {
            const finalCost = await Parse.Cloud.run("payAdvertisingFee", { filterId: adData?.createCollegePost?.collegePost?.filterQuery?.objectId, collegeId: auth.collegeId, adId: adData.createCollegePost?.collegePost?.objectId });

            setFinalCost(finalCost);
          }

          setIsSaving(false);
        }
      }

      setAds();

    } else if (adDataImg) {
      const setAds = async () => {
        if (adDataImg?.createCollegePost?.collegePost?.filterQuery) {
          setCreatedAds([adDataImg.createCollegePost?.collegePost, ...createdAds]);

          if (isSaving && props.costPerAdImpression > 0) {
            const finalCost = await Parse.Cloud.run("payAdvertisingFee", { filterId: adDataImg?.createCollegePost?.collegePost?.filterQuery?.objectId, collegeId: auth.collegeId, adId: adDataImg.createCollegePost?.collegePost?.objectId });

            setFinalCost(finalCost);
          }

          setIsSaving(false);
        }
      }

      setAds();
    }

    setMessage("");
    setTempURL("");
    setLink("");

  }, [adData, adDataImg]);

  const removeMedia = (event) => {
    event.preventDefault();
    setTempURL("");
  };

  const saveNewAd = async (e) => {
    e.preventDefault();
    setOpen(false);
    setIsSaving(true);

    if (tempURL === "" || tempURL === null) {
      await createAdWithoutImg({
        variables: {
          collegeId1: auth.collegeId,
          message: message,
          link: link,
          ...filters.saveData,
        },
      });

    } else {
      await createAd({
        variables: {
          collegeId1: auth.collegeId,
          message: message,
          link: link,
          file:
            tempURL === ""
              ? null
              : {
                __type: "File",
                name: fileData?.createFile?.fileInfo?.name,
                url: fileData?.createFile?.fileInfo?.url,
              },
          ...filters.saveData,
        },
      });
    }
  };

  if (adError) console.log(JSON.stringify(adError, null, 2));
  if (adErrorImg) console.log(JSON.stringify(adErrorImg, null, 2));

  return (
    <>
      <div className={classes.root}>
        <Button
          size="large"
          variant="contained"
          className={classes.button}
          onClick={() => setOpen(true)}
        >
          <AddIcon />
          <Box width="12px" />
          <Typography variant="body2" style={{ fontWeight: 600, paddingRight: theme.spacing(1) }}>Create New Advertisement</Typography>
        </Button>
      </div>
      {adLoading || adlLoadingImg && <Loading />}
      {createdAds.map((ad) => (
        <PostCard
          data={ad}
          finalCost={finalCost}
          likedPosts={[]}
        />
      ))}
      <Modal
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: theme.spacing(3),
        }}
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
      >
        <Fade in={open}>
          <Card
            style={{
              maxHeight: 680,
              overflow: "auto",
              backgroundColor: theme.palette.primary.darker,
              display: "flex",
              flexDirection: "column",
              padding: theme.spacing(4),
              borderRadius: 25,
              outlineColor: "#27272d",
              outlineStyle: "solid",
              outlineWidth: 1,
            }}
            className="cardScroll"
          >
            <Box width="600px">
              <form onSubmit={saveNewAd}>
                <Grid container sx={{ alignSelf: "left" }}>
                  <Typography style={{ fontWeight: 600, fontSize: 29 }} color="textPrimary" align="left">
                    Create New Advertisement
                  </Typography>
                  <Box flexGrow={1} />
                  <IconButton style={{ width: 40, height: 40 }} onClick={() => setOpen(false)}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
                <Grid container sx={{ alignSelf: "left" }}>
                  <Typography variant="body2" align="left" color="textSecondary">
                    Estimated Cost: {props.costPerAdImpression == 0 ? "$0" : audienceSize != null ? "$" + ((audienceSize * props.costPerAdImpression) + 50).toFixed(2) : "$" + ((props.totalStudents * props.costPerAdImpression) + 50).toFixed(2)}
                  </Typography>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <Box height="20px" />
                    <Typography variant="h6" color="textSecondary">
                      Post Details
                    </Typography>
                    <Box height="10px" />
                    <Input
                      label="Message"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      fullWidth
                      multiline
                      rows={5}
                      required
                      borderRadius={15}
                    />
                    <Box height="10px" />
                    <Input
                      label="Link"
                      value={link}
                      onChange={(e) => setLink(e.target.value)}
                      fullWidth
                      borderRadius={15}
                    />
                    <Box height="10px" />
                  </Grid>
                  <Grid item xs={4}>
                    <Box height="60px" />
                    {tempURL === "" ? (
                      <Button
                        fullWidth
                        variant="contained"
                        className={classes.button}
                        onClick={() => hiddenFileInput.current.click()}
                      >
                        Add Image
                      </Button>
                    ) : (
                      <MuiButton
                        fullWidth
                        variant="contained"
                        style={{
                          background: theme.palette.error.main,
                          border: 0,
                          color: "#fff",
                        }}
                        onClick={removeMedia}
                      >
                        Delete Image
                      </MuiButton>
                    )}
                    <input
                      type="file"
                      accept="image/png/jpg/jpeg"
                      ref={hiddenFileInput}
                      onChange={onFileChange}
                      style={{ display: "none" }}
                    />
                    <Box height="10px" />
                    <Card
                      sx={{
                        "&:before": {
                          position: "absolute",
                          content: '" "',
                          top: 0,
                          left: 0,
                          height: "100%",
                          width: "100%",
                        },
                      }}
                      style={{
                        justifyContent: "center",
                        backgroundImage: `url(${tempURL})`,
                        backgroundColor: theme.palette.primary.light,
                        position: "relative",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        objectFit: 'cover',
                        backgroundPosition: "center",
                        outlineColor: "#35353d",
                        outlineStyle: "solid",
                        outlineWidth: 1,
                        borderRadius: 15
                      }}
                    >
                      <Box height="154px" style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}>
                        {tempURL === "" ? (
                          <Container style={{ display: 'flex', justifyContent: 'center' }}>
                            {uploading ? <Loading small /> : <ImageIcon />}
                          </Container>
                        ) : (
                          <></>
                        )}
                        {errorUploading && (
                          <Typography color="error">Error uploading.</Typography>
                        )}
                      </Box>
                    </Card>
                  </Grid>
                </Grid>
                <Box height="10px" />
                <Accordion
                  style={{
                    background: theme.palette.secondary.dark,
                    borderRadius: theme.shape.borderRadius,
                  }}
                  sx={{
                    '&:before': {
                      display: 'none',
                    }
                  }}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <CardHeader
                      title={
                        filters?.loading ? (
                          <Box width={150}>
                            <Loading small />
                          </Box>
                        ) : (
                          `Filters (${filters?.numStudents?.toLocaleString() ?? "All"} students)`
                        )
                      }
                      titleTypographyProps={{ fontWeight: 600, fontSize: 20 }}
                    />
                  </AccordionSummary>
                  <AccordionDetails
                    style={{
                      paddingLeft: theme.spacing(3),
                      paddingRight: theme.spacing(3)
                    }}
                  >
                    <Filters
                      setFilters={setFilters}
                      disableInterestedStudentsOption
                      disableFromSourceOption={true}
                      disabled={false}
                      setAudienceSize={setAudienceSize}
                      applyOnLoad={true}
                    />
                  </AccordionDetails>
                </Accordion>
                <Box height="10px" />
                <Grid container sx={{ alignSelf: "left" }}>
                  <Typography variant="body2" align="left" color="textSecondary">
                    *Cost=${props.costPerAdImpression.toFixed(2)}/ad recipient {props.costPerAdImpression > 0 ? "+ $50 service fee" : "+ $0 service fee"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{ alignSelf: "right" }}>
                  <Box height="20px" />
                  <Box justifyContent='right' display='flex'>
                    <Button
                      className={classes.button}
                      disabled={!allowedSave}
                      type="submit"
                    >
                      Post
                    </Button>
                  </Box>
                </Grid>
              </form>
            </Box>
          </Card>
        </Fade>
      </Modal>{""}
    </>
  );
};

export default AdAdd;
