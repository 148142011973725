import { useEffect } from 'react';

import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';

import Logo from '../../../../Components/logo';
import Iconify from '../../../../Components/iconify';
import { usePathname } from '../../../../Routes/hooks';
import Scrollbar from '../../../../Components/scrollbar';
import { useBoolean } from '../../../../Hooks/use-boolean';
import { useOffSetTop } from '../../../../Hooks/use-off-set-top';

import { NavProps } from '../types';
import { NAV } from '../../../config-layout';

import NavList from './nav-list';
import { paths } from 'src/routes/FrontEnd/Routes/paths';

// ----------------------------------------------------------------------

export default function NavMobile({ data }: NavProps) {
  const pathname = usePathname();

  const mobileOpen = useBoolean();

  const offset = useOffSetTop();

  useEffect(() => {
    if (mobileOpen.value) {
      mobileOpen.onFalse();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <>
      <IconButton onClick={mobileOpen.onTrue} sx={{ ml: 1, color: offset ? '#000' : '#fff' }}>
        <Iconify icon="carbon:menu" />
      </IconButton>

      <Drawer
        open={mobileOpen.value}
        onClose={mobileOpen.onFalse}
        PaperProps={{
          sx: {
            pb: 5,
            width: NAV.W_VERTICAL,
          },
        }}
      >
        <Scrollbar>
          <Logo sx={{ mx: 2.5, my: 3 }} />

          <List component="nav" disablePadding>
            {data.map((link) => (
              <NavList key={link.title} item={link} />
            ))}
          </List>

          <Stack spacing={1.5} sx={{ p: 3 }}>
            {/* <Button 
              fullWidth 
              variant="contained" 
              color="inherit"
              href={paths.signin}
              sx={{ 
                height: 40, 
                borderRadius: 5
              }}
            >
              Sign In
            </Button> */}

            <Button 
              fullWidth 
              variant="contained" 
              color="inherit"
              href={paths.register}
              sx={{ 
                background: `linear-gradient(to right, #35c669, #2ec4a9)`,
                height: 40, 
                borderRadius: 5
              }}
            >
              Start For Free
            </Button>
          </Stack>
        </Scrollbar>
      </Drawer>
    </>
  );
}
