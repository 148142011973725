import React, { useState } from "react";
import Parse from "parse";
import {
  MenuList,
  Paper,
  MenuItem,
  Typography,
  Modal,
  Fade,
  Card,
  Grid,
  Backdrop,
  IconButton,
  Button,
  Chip,
  SvgIcon,
  Input,
  Box,
} from "@mui/material";
import { useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Page, Loading } from "../../../../components";
import AddIcon from "@material-ui/icons/Add";
import ForumCard from "./ForumCard";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/client";
import CollegeForums from "./CollegeForums";
import MyFeed from "./MyFeed";
import CloseIcon from "@material-ui/icons/Close";
import { Search as SearchIcon } from "react-feather";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

const useStyles = makeStyles((theme: any) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  // paper: {
  //   backgroundColor: theme.palette.background.paper,
  //   "& ul": {
  //     display: "inline-flex",
  //   },
  //   marginTop: theme.spacing(0),
  //   margin: theme.spacing(3),
  // },
  selected: {
    color: theme.palette.secondary.main,
  },
  button: {
    background: "linear-gradient(75deg, #35c669, #2ec4a9)",
    border: 0,
    color: "#fff",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: theme.spacing(3),
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(4),
    borderRadius: 20,
    outlineColor: "#2d2d30",
    outlineStyle: "solid",
    outlineWidth: 1,
  },
  search: {
    display: "flex",
    alignItems: "center",
    height: 44,
    borderRadius: 13,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    backgroundColor: theme.palette.primary.light,
    outlineColor: "#2d2d30",
    outlineStyle: "solid",
    outlineWidth: 1,
  },
  searchInput: {
    flexGrow: 1,
    marginLeft: theme.spacing(1),
  },
  chip: {
    padding: 2,
    margin: 2,
  },
}));

const ADD_TAG = gql`
  mutation addTag($id: ID!, $tags: [ID!]) {
    updateUserContact(
      input: { id: $id, fields: { followedTags: { add: $tags } } }
    ) {
      userContact {
        id
        objectId
        followedTags {
          edges {
            node {
              id
              objectId
              tag
              color
            }
          }
        }
      }
    }
  }
`;

const REMOVE_TAG = gql`
  mutation removeTag($id: ID!, $tags: [ID!]) {
    updateUserContact(
      input: { id: $id, fields: { followedTags: { remove: $tags } } }
    ) {
      userContact {
        id
        objectId
        followedTags {
          edges {
            node {
              id
              objectId
              tag
              color
            }
          }
        }
      }
    }
  }
`;

const SEARCH_TAGS = gql`
  query searchTags($query: String!) {
    forumTags(where: { tag: { matchesRegex: $query, options: "i" } }) {
      edges {
        node {
          id
          objectId
          tag
          color
        }
      }
    }
  }
`;

export default function ManageTags({ tags }) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState("");
  const { data, loading, error, fetchMore } = useQuery(SEARCH_TAGS, {
    variables: { query },
  });
  const [addTag] = useMutation(ADD_TAG);
  const [removeTag] = useMutation(REMOVE_TAG);

  return (
    <>
      <Button
        variant="outlined"
        style={{ color: "#fff" }}
        onClick={() => setOpen(!open)}>
        Manage My Tags
      </Button>
      <Modal
        className={classes.modal}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: theme.spacing(3),
        }}
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
      // BackdropComponent={Backdrop}
      >
        <Fade in={open}>
          <Card style={{
            backgroundColor: theme.palette.primary.dark,
            display: "flex",
            flexDirection: "column",
            padding: theme.spacing(4),
            borderRadius: 25,
            outlineColor: "#2d2d30",
            outlineStyle: "solid",
            outlineWidth: 1,
          }}>
            <Box width="600px">
              <Grid container spacing={2} sx={{ alignSelf: "left" }}>
                <Grid container sx={{ alignSelf: "left" }}>
                  <Typography style={{ fontWeight: 600, fontSize: 30, paddingLeft: theme.spacing(2.4), paddingTop: theme.spacing(1) }} align="left" color="textPrimary">
                    Followed Tags
                  </Typography>
                  <Box flexGrow={1} />
                  <IconButton style={{ width: 45, height: 45, marginRight: theme.spacing(-1) }} onClick={() => setOpen(false)}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
                <Grid item xs={12}>
                  {tags.length === 0 && (
                    <Typography variant="body1" color="textSecondary">
                      You are not currently following any tags.
                    </Typography>
                  )}
                  {tags.map((tag) => (
                    <Chip
                      key={tag.node.id}
                      size="small"
                      label={tag.node.tag}
                      style={{
                        padding: 2,
                        margin: 2,
                        backgroundColor: tag.node.color,
                      }}
                      onDelete={() =>
                        removeTag({
                          variables: {
                            id: Parse.User.current()?.get("userContact").id,
                            tags: tag.node.id,
                          },
                        })
                      }
                      className={classes.chip}
                    />
                  ))}
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.search}>
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                    <Input
                      className={classes.searchInput}
                      disableUnderline
                      placeholder="Search tags..."
                      onChange={(e) => setQuery(e.target.value)}
                      value={query}
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  {query !== "" && (
                    <div>
                      <PerfectScrollbar onYReachEnd={() => { }}>
                        {data?.forumTags.edges
                          ?.map((e) => e.node)
                          ?.map((tag) => (
                            <Chip
                              size="small"
                              label={tag.tag}
                              style={{
                                backgroundColor: tag.color,
                                padding: 2,
                                margin: 2,
                              }}
                              onClick={() =>
                                addTag({
                                  variables: {
                                    id: Parse.User.current()?.get("userContact")
                                      .id,
                                    tags: tag.objectId,
                                  },
                                })
                              }
                            />
                          ))}
                      </PerfectScrollbar>
                    </div>
                  )}
                  {loading && query !== "" ? <Loading /> : <></>}
                </Grid>
              </Grid>
            </Box>
          </Card>
        </Fade>
      </Modal>
    </>
  );
}
