import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  ButtonBase,
  Hidden,
  Typography,
  useTheme,
} from "@mui/material";

const AdvancedSearch = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const ref = useRef(null);

  const handleClick = () => {
    navigate('/dashboard/search');
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleClick}
        ref={ref}
        style={{
          borderRadius: 10,
          background: "linear-gradient(75deg, #35c669, #2ec4a9)",
          backgroundColor: "#0d0d14",
          padding: 5,
          paddingRight: theme.spacing(1),
          paddingLeft: theme.spacing(0.6),
          marginLeft: 10,
          marginRight: 10,
          height: 30,
        }}
      >
        <Hidden smDown>
          <Typography style={{ fontSize: 10, fontWeight: 600, marginRight: 8, marginLeft: 8 }} color="inherit">
            ADVANCED SEARCH
          </Typography>
        </Hidden>
      </Box>
    </>
  );
};

export default AdvancedSearch;
