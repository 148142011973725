import React, { useState } from "react";
import Parse from "parse";
import {
  Button,
  Grid,
  Typography,
  Card,
  Fade,
  Modal,
  Backdrop,
  IconButton,
  Box
} from "@mui/material";
import { useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CreateIcon from "@material-ui/icons/Create";
import { useAuth } from "../../../hooks/useAuth";

const useStyles = makeStyles((theme: any) => ({
  root: {},
  cover: {
    position: "relative",
    height: 460,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    borderRadius: 15,
    outlineColor: "#323238",
    outlineStyle: "solid",
    outlineWidth: 1,
    "&:before": {
      position: "absolute",
      content: '" "',
      top: 0,
      left: 0,
      height: "100%",
      width: "100%",
      backgroundImage:
        "linear-gradient(-180deg, rgba(0,0,0,0.00) 58%, rgba(0,0,0,0.32) 100%)",
    },
    "&:hover": {
      "& $changeBannerButton": {
        visibility: "visible",
      },
    },
  },
  changeBannerButton: {
    visibility: "hidden",
    position: "absolute",
    top: theme.spacing(3),
    bottom: "auto",
    right: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
    "&:hover": {
      backgroundColor: theme.palette.background.paperLight,
    },
  },
  avatar: {
    top: -50,
    position: "absolute",
    "& .MuiAvatar-root": {
      height: 120,
      width: 120,
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(4),
    maxWidth: "500px",
    borderRadius: 20,
    outlineColor: "#323238",
    outlineStyle: "solid",
    outlineWidth: 1,
  },
  button: {
    background: "linear-gradient(75deg, #35c669, #2ec4a9)",
    border: 0,
    borderRadius: 13,
    color: "#fff",
    padding: "0 30px",
    marginBottom: theme.spacing(0),
    minHeight: "50px",
    margin: theme.spacing(1),
    minWidth: 100
  },
}));

export default function BannerImage(props) {
  const theme = useTheme();
  const classes = useStyles(theme);

  const auth = useAuth();
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState<any>(null);
  const [tempURL, setTempURL] = useState<any>(null);

  const handleChange = (event) => {
    setFile(event.target.files[0]);

    var reader = new FileReader();
    reader.onload = function (upload) {
      setFile(upload.target?.result);
    };
    reader.readAsDataURL(event.target.files[0]);

    setTempURL(URL.createObjectURL(event.target.files[0]));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (auth.collegeId) {
      const colleges = Parse.Object.extend("college");
      const query = new Parse.Query(colleges);
      query.get(auth.collegeId).then((object) => {
        object.set(
          "bannerImg",
          new Parse.File("bannerImage.png", { base64: file })
        );
        object.save().then(
          (response) => {
            console.log("banner img", response.get("bannerImg")._url);
          },
          (error) => {
            console.error("Error while updating colleges", error);
          }
        );
      });

      setOpen(false);
    }
  };

  return (
    <div className={classes.root}>
      <Card
        className={classes.cover}
        style={{
          backgroundImage: `url(${props.url})`,
          position: "relative",
          height: 460,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          borderRadius: 25,
          outlineColor: "#323238",
          outlineStyle: "solid",
          outlineWidth: 1,
        }}
      >
        <IconButton
          className={classes.changeBannerButton}
          style={{
            width: 50,
            height: 50,
            position: "absolute",
            top: theme.spacing(3),
            bottom: "auto",
            right: theme.spacing(3),
            backgroundColor: "rgba(30, 30, 35, 0.8)",
          }}
          onClick={() => {
            setOpen(true);
          }}
        >
          <CreateIcon />
        </IconButton>
      </Card>
      <Modal
        className={classes.modal}
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
      >
        <Fade in={open}>
          <Card style={{
            backgroundColor: theme.palette.primary.darker,
            display: "flex",
            flexDirection: "column",
            padding: theme.spacing(4),
            maxWidth: 600,
            borderRadius: 30,
            outlineColor: "#323238",
            outlineStyle: "solid",
            outlineWidth: 1,
          }}>
            <form noValidate onSubmit={handleSubmit}>
              <Grid container spacing={2} sx={{ alignSelf: "center" }}>
                <Grid item xs={12}>
                  <Typography variant="h4" align="center" color="textPrimary" style={{ fontWeight: 600 }}>
                    Banner Picture
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Box display="flex" justifyContent='center'>
                    <img
                      alt="profile-select"
                      style={{
                        borderRadius: 25,
                        height: 300,
                        maxWidth: 570,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        objectFit: 'cover',
                      }}
                      src={tempURL ? tempURL : props.url}
                    ></img>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box display='flex' justifyContent='center'>
                    <input
                      type="file"
                      accept="image/png/jpg/jpeg"
                      onChange={handleChange}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box display='flex' justifyContent='center'>
                    <Button style={{
                      backgroundColor: theme.palette.error.main,
                      border: 0,
                      borderRadius: 13,
                      color: "#fff",
                      padding: "0 30px",
                      width: 140,
                      marginBottom: theme.spacing(0),
                      minHeight: "50px",
                      margin: theme.spacing(1),
                      minWidth: 100
                    }} onClick={() => setOpen(false)}>
                      Cancel
                    </Button>
                    <Button style={{
                      background: "linear-gradient(75deg, #35c669, #2ec4a9)",
                      border: 0,
                      borderRadius: 13,
                      color: "#fff",
                      padding: "0 30px",
                      width: 140,
                      marginBottom: theme.spacing(0),
                      minHeight: "50px",
                      margin: theme.spacing(1),
                      minWidth: 100
                    }} type="submit">
                      Save
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Card>
        </Fade>
      </Modal>
    </div>
  );
}
