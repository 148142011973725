import React, { useEffect, useState } from "react";
import Parse from "parse";
import {
  Box,
  Card,
  IconButton,
  CardContent,
  Tooltip,
} from "@mui/material";
import { useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { gql, useMutation } from "@apollo/client";
import { Input, Loading } from "../../../../components";
import SendIcon from "@material-ui/icons/Send";
import { useAuth } from "../../../../hooks/useAuth";

const useStyles = makeStyles((theme: any) => ({
  addReply: {
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(3),
    paddingTop: theme.spacing(1),
    borderRadius: 15,
    outlineColor: "#29292d",
    outlineStyle: "solid",
    outlineWidth: 1,
  },
}));

const POST_REPLY = gql`
  mutation postReply(
    $postId: ID!
    $content: String!
    $collegeId: ID!
    $userId: ID!
    $ucid: ID!
  ) {
    updateForum(
      input: {
        id: $postId
        fields: {
          replies: {
            createAndAdd: {
              content: $content
              forum: { link: $postId }
              college: { link: $collegeId }
              user: { link: $userId }
              author: { link: $ucid }
              numUpVotes: 0
              numDownVotes: 0
              numReports: 0
            }
          }
        }
      }
    ) {
      forum {
        id
        objectId
        replies(order: numUpVotes_DESC) {
          count
          edges {
            node {
              id
              objectId
              content
              createdAt
              numUpVotes
              numDownVotes
              author {
                objectId
                firstName
                lastName
                img {
                  url
                }
              }
              college {
                objectId
                name
                img {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;

const AddReply = (props) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const auth = useAuth();
  const [content, setContent] = useState("");
  const [postReply, { data, loading, error }] = useMutation(POST_REPLY);

  if (error) console.log(error);

  useEffect(() => {
    document.body.style.backgroundColor = "#0e0e14";
    if (data) {
      setContent("");
    }
  }, [data]);

  return (
    <Card style={{
      backgroundColor: theme.palette.primary.dark,
      marginTop: theme.spacing(3),
      paddingTop: theme.spacing(1),
      borderRadius: 20,
      outlineColor: "#29292d",
      outlineStyle: "solid",
      outlineWidth: 1,
    }}>
      <CardContent>
        <Box display="flex" alignItems="center">
          <Box width="10px" />
          <Input
            fullWidth
            multiline
            onChange={(e) => setContent(e.target.value)}
            label={`Write a new reply...`}
            value={content}
            variant="filled"
            borderRadius={15}
            InputProps={{ disableUnderline: true }}
          />
          {loading ? (
            <Loading small />
          ) : (
            <Tooltip
              title="Post"
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: "#46464f",
                    color: "white"
                  }
                }
              }}
            >
              <IconButton
                type="submit"
                style={{ marginLeft: theme.spacing(3), marginRight: theme.spacing(1), height: 40, width: 40 }}
                onClick={() => {
                  if (content.length > 0) {
                    postReply({
                      variables: {
                        postId: props.postId,
                        content: content,
                        collegeId: auth.collegeId,
                        userId: Parse.User.current()?.id,
                        ucid: Parse.User.current()?.get("userContact").id,
                      },
                    });
                    const q = new Parse.Query(Parse.Object.extend("forum"));
                    q.get(props.postId).then((forumPost) => {
                      forumPost.increment("numReplies");
                      forumPost.save();
                    });
                  }
                }}
              >
                {content.length === 0 ? (
                  <SendIcon style={{ height: 23, width: 23 }} />
                ) : (
                  <SendIcon style={{ color: "#35c669", height: 23, width: 23 }} />
                )}
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default AddReply;
