import React, { useState } from "react";
import Parse from "parse";
import EditIcon from "@material-ui/icons/Edit";
import {
  useTheme,
  Card,
  Grid,
  Avatar,
  Box,
  Checkbox,
  IconButton,
  SvgIcon,
  TableCell,
  TableRow,
  Typography,
  Tooltip,
  FormControlLabel,
  Button
} from "@mui/material";
import { makeStyles } from '@mui/styles';
import CloseIcon from "@material-ui/icons/Close";
import { Input, Button as CustomButton, Modal } from "../../../components";
import { useSnackbar } from "notistack";
import { useAuth } from "../../../hooks/useAuth";

const useStyles = makeStyles((theme: any) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  card: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  button: {
    background: "linear-gradient(75deg, #35c669, #2ec4a9)",
    border: 0,
    color: "#fff",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: theme.spacing(3),
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(4),
  },
  title: {
    fontSize: 30,
    fontWeight: 600,
    color: "#fff"
  },
  deactivate: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.text.primary,
  },
  tableCell: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    "& .MuiTableCell-root": {
      borderBottomColor: "#3c3c46"
    }
  },
}));

export default function User(props) {
  const theme = useTheme();
  const classes = useStyles(theme);

  const { enqueueSnackbar } = useSnackbar();
  const auth = useAuth();

  const [open, setOpen] = useState(false);
  const [permissions, setPermissions] = useState(props.person.permissions);
  const [title, setTitle] = useState(props.person.jobTitle);

  const deactivate = (id) => {
    setOpen(false);
    props.startRefresh();

    Parse.Cloud.run("deactivate", { userID: id, collegeId: auth.collegeId }).then((res) => {
      if (res) {
        props.refresh();

        enqueueSnackbar("Deactivated account successfully", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("Failed to deactivate account", {
          variant: "error",
        });
      }
    });
  };

  const activate = (id) => {
    if (auth.tier == "free" && props.numUsers >= 3) {
      enqueueSnackbar("Please upgrade to Premium to add more accounts", {
        variant: "error",
      });

    } else {
      setOpen(false);
      props.startRefresh();

      Parse.Cloud.run("activate", { userID: id, collegeId: auth.collegeId }).then((res) => {
        if (res) {
          props.refresh();

          enqueueSnackbar("Activated account successfully", {
            variant: "success",
          });
        } else {
          props.refresh();

          enqueueSnackbar("Failed to activate account", {
            variant: "error",
          });
        }
      });
    }
  };

  const save = async (e) => {
    e.preventDefault();
    setOpen(false);
    props.refresh();

    if (title !== props.person.jobTitle) {
      await Parse.Cloud.run("editEnterpriseAccountTitle", {
        id: props.person.id,
        title: title,
      }).then(
        (res) => {
          enqueueSnackbar("Saved account info", {
            variant: "success",
          });
          props.refresh();
        },
        (err) => {
          enqueueSnackbar("Error saving account info", {
            variant: "error",
          });
        }
      );
    }
    await props.allRoles.forEach(async (role) => {
      if (
        permissions.map((p) => p.name).includes(role.name) &&
        !props.person.permissions.map((p) => p.name).includes(role.name)
      ) {
        await Parse.Cloud.run("addEnterpriseAccountPermission", {
          id: props.person.userID,
          permission: role.name,
        }).then(
          (res) => {
            // props.refresh();
            enqueueSnackbar("Saved account permissions", {
              variant: "success",
            });
          },
          (err) => {
            enqueueSnackbar("Error saving account permissions", {
              variant: "error",
            });
          }
        );
      }
      if (
        !permissions.map((p) => p.name).includes(role.name) &&
        props.person.permissions.map((p) => p.name).includes(role.name)
      ) {
        await Parse.Cloud.run("removeEnterpriseAccountPermission", {
          id: props.person.userID,
          permission: role.name,
        }).then(
          (res) => {
            // props.refresh();
            enqueueSnackbar("Saved account permissions", {
              variant: "success",
            });
          },
          (err) => {
            enqueueSnackbar("Error saving account permissions", {
              variant: "error",
            });
          }
        );
      }
    });
  };

  return (
    <>
      <TableRow hover key={props.person.id} className={classes.tableCell}>
        <TableCell>
          <Box display="flex" alignItems="center">
            <Avatar
              className={classes.avatar}
              src={props.person.avatar}
              style={{
                backgroundColor: "#2f2f3f",
              }}
            >
              {props.person.name[0]}
            </Avatar>
            <div>
              <Typography variant="body1" color="textPrimary" style={{ fontWeight: 600 }}>
                {props.person.name}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {props.person.email.toLowerCase()}
              </Typography>
            </div>
          </Box>
        </TableCell>
        {props.person.deactivated ? (
          <TableCell>
            <Typography style={{ fontWeight: 600, fontSize: 13 }} color="error">
              DEACTIVATED
            </Typography>
          </TableCell>
        ) : (
          <TableCell>{props.person.jobTitle}</TableCell>
        )}
        <TableCell className={classes.tableCell}>
          <Typography fontSize={14} color="textSecondary" fontStyle='italic'>
            {props.person.permissions.filter((item, idx) => idx < 3).map((item) => item.description).join(", ") + (props.person.permissions.length > 3 ? "..." : "")}
          </Typography>
        </TableCell>
        <TableCell align="right">
          {props.person.deactivated ? (
            <Button style={{ minWidth: 100, background: "linear-gradient(75deg, #35c669, #2ec4a9)" }} onClick={() => activate(props.person.userID)}>
              <Typography variant="body2" color="textPrimary">
                Re-activate
              </Typography>
            </Button>
          ) : (
            <>
              {props.person.userID === Parse.User.current()?.id ? (
                <Tooltip
                  title="Cannot edit your own account"
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: "#46464f",
                        color: "white"
                      }
                    }
                  }}
                >
                  <span>
                    <IconButton disabled>
                      <SvgIcon fontSize="small">
                        <EditIcon />
                      </SvgIcon>
                    </IconButton>
                  </span>
                </Tooltip>
              ) : (
                <Tooltip
                  title="Edit this account"
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: "#46464f",
                        color: "white"
                      }
                    }
                  }}
                >
                  <IconButton onClick={() => setOpen(true)}>
                    <SvgIcon fontSize="small">
                      <EditIcon />
                    </SvgIcon>
                  </IconButton>
                </Tooltip>
              )}
            </>
          )}
        </TableCell>
      </TableRow>
      <Modal
        open={open}
        setOpen={setOpen}
        noScroll
        cardColor="#090910"
        title={<Typography style={{ fontSize: 33, fontWeight: 600, color: "#fff" }}>Edit Account</Typography>}>
        <Box maxWidth={550}>
          <Grid container spacing={2} sx={{ alignSelf: "left", direction: "row" }}>
            <Grid item md={6} xs={12}>
              <Box height="10px" />
              <Typography variant="h6" color="textSecondary">
                Information
              </Typography>
              <Box height="10px" />
              <Input
                label="Name (uneditable)"
                value={props.person.name}
                variant="filled"
                fullWidth
                disabled
              />
              <Box height="10px" />
              <Input
                label="Email (uneditable)"
                value={props.person.email.toLowerCase()}
                variant="filled"
                fullWidth
                disabled
              />
              <Box height="10px" />
              <Input
                label="Job Title"
                variant="filled"
                fullWidth
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              <Box height="10px" />
              <Box justifyContent="left">
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: theme.palette.error.main,
                    color: theme.palette.text.primary,
                    ":hover": { backgroundColor: theme.palette.text.secondary }
                  }}
                  onClick={() => deactivate(props.person.userID)}
                >
                  Deactivate
                </Button>
              </Box>
            </Grid>
            <Grid item md={6} xs={12}>
              <Box height="10px" />
              <Typography variant="h6" color="textSecondary">
                Permissions
              </Typography>
              {props.allRoles.map((p) => (
                <Tooltip 
                  key={p.name} 
                  title={p.info}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: "#46464f",
                        color: "white"
                      }
                    }
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{
                          color: theme.palette.text.secondary,
                          '&.Mui-checked': {
                            color: theme.palette.secondary.main,
                          },
                        }}
                        checked={permissions
                          .map((x) => x.name)
                          .includes(p.name)}
                        onChange={(e) => {
                          if (e.target.checked === true) {
                            setPermissions([...permissions, p]);
                          } else {
                            setPermissions(
                              permissions.filter((x) => x.name !== p.name)
                            );
                          }
                        }}
                      />
                    }
                    label={p.description}
                  />
                </Tooltip>
              ))}
            </Grid>
            <Grid item md={12} xs={12}>
              <Box display="flex" justifyContent="right">
                <CustomButton onClick={save}>Save</CustomButton>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}
