import React, { useState } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Input,
  useTheme,
} from "@mui/material";
import { makeStyles } from '@mui/styles';
import Notifications from "./Notifications";
import Account from "./Account";
import SearchIcon from "@material-ui/icons/Search";
import { Link, useNavigate } from "react-router-dom";
import AdvancedSearch from "./AdvancedSearch";
// import BubbleChartIcon from "@material-ui/icons/BubbleChart"; // for queries button

const useStyles = makeStyles((theme: any) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    outlineColor: "#33333a",
    outlineStyle: "solid",
    outlineWidth: 1,
  },
  topSection: {
    backgroundSize: "cover",
    width: 256,
    backgroundRepeat: "no-repeat",
  },
  search: {
    display: "flex",
    alignItems: "center",
    height: 46,
    borderRadius: 22,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    backgroundColor: theme.palette.primary.light,
    width: 670,
    outlineColor: "#33333a",
    outlineStyle: "solid",
    outlineWidth: 1,
  },
  searchInput: {
    flexGrow: 1,
    marginLeft: theme.spacing(1),
  },
}));

const NavBar = ({
  notifications,
  ...props
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  
  const [query, setQuery] = useState("");
  const navigate = useNavigate();

  return (
    <AppBar className={classes.root} color="default" elevation={0} >
      <Toolbar>
        <Box className={classes.topSection} p={1.2} pt={2} pr={3}>
          <img alt="logo" src="/logo.png" width="40px" style={{ position: 'relative', marginLeft: 80 }}></img>
        </Box>
        <div className={classes.search}>
          <Link
            to={
              query ? `/dashboard/search?query=${query}` : "/dashboard/search"
            }
          >
            <IconButton>
              <SearchIcon />
            </IconButton>
          </Link>
          <Input
            className={classes.searchInput}
            id={"search"}
            disableUnderline
            placeholder="Search students..."
            onChange={(e) => setQuery(e.target.value)}
            value={query}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                query
                  ? navigate(`/dashboard/search?query=${query}`)
                  : navigate("/dashboard/search")
              }
            }}
          />
        </div>
        <Box flexGrow={1} />
        {/* <Tooltip title="View Queries">
          <IconButton >
            <BubbleChartIcon />
          </IconButton>
        </Tooltip> */}
        <AdvancedSearch/>
        <Notifications data={notifications} />
        <Account src={props.avatar} />
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
