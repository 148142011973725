import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import ForumCard from "./ForumCard";
import { gql, useQuery } from "@apollo/client";
import { Loading, Button } from "../../../../components";

const GET_FORUM_POSTS = gql`
  query getForums($ids: [ID!], $filter: [ForumWhereInput!], $after: String) {
    forums(
      first: 10
      order: createdAt_DESC
      where: { tags: { have: { objectId: { in: $ids } } }, AND: $filter }
      after: $after
    ) {
      count
      edges {
        cursor
        node {
          id
          objectId
          createdAt
          author {
            id
            objectId
            firstName
            lastName
            userStudent {
              id
              objectId
              verificationStatus
              appliedColleges {
                ... on Element {
                  value
                }
              }
              acceptedColleges {
                ... on Element {
                  value
                }
              }
              enrolledColleges {
                ... on Element {
                  value
                }
              }
            }
            img {
              url
            }
          }
          title
          content
          numUpVotes
          numDownVotes
          replies {
            count
          }
          tags {
            edges {
              node {
                id
                objectId
                tag
                color
              }
            }
          }
        }
      }
    }
  }
`;

const MyFeed = ({ tags, noTags, seekingCollegeResponse }) => {
  const [moreForums, setMoreForums] = useState(true);
  const [paginationLoading, setPaginationLoading] = useState(false);

  const { data, loading, error, fetchMore } = useQuery(GET_FORUM_POSTS, {
    variables: {
      ids: tags.map((t) => t.node.objectId),
      filter: seekingCollegeResponse
        ? { isWantingCollegeResponse: { equalTo: true } }
        : undefined,
    },
  });

  const loadMoreForums = async () => {
    try {
      await fetchMore({
        variables: {
          after: data?.forums?.edges[data?.forums?.edges?.length - 1].cursor,
        },
        updateQuery: (prev: any, { fetchMoreResult }) => {
          setPaginationLoading(false);
          if (!fetchMoreResult) return prev;
          if (fetchMoreResult.forums.edges.length === 0) {
            setMoreForums(false);
            return prev;
          }
          return {
            ...prev,
            forums: {
              ...prev.forums,
              edges: [...prev.forums.edges, ...fetchMoreResult.forums.edges],
            },
          };
        },
      });
    } catch (error) { return }
  };

  useEffect(() => {
    document.body.style.backgroundColor = "#0e0e14";
  }, []);

  if (loading) return <Loading lower />;

  if (error) console.log(JSON.stringify(error, null, 2));

  return (
    <>
      {data?.forums?.edges?.map((post) => {
        return (
          <Box p={3} pt={0} key={post.node?.id}>
            <ForumCard data={post.node} />
          </Box>
        );
      })}
      {data?.forums?.count > 10 && moreForums ? (
        <Box display="flex" justifyContent="center" width="100%">
          {paginationLoading ? (
            <Loading smaller/>
          ) : (
            <Box display='flex' justifyContent='center'>
              <Button
                style={{ paddingRight: 10, paddingLeft: 10, marginBottom: 10, color: "#fff" }}
                size="small"
                onClick={() => {
                  setPaginationLoading(true);
                  loadMoreForums();
                }}
              >
                Load More
              </Button>
            </Box>
          )}
        </Box>
      ) : (
        <></>
      )}
      {noTags && (
        <Box display='flex' justifyContent='center'>
          <Typography variant="body1" color="textPrimary" mt={30}>
            You are not following any tags.
          </Typography>
        </Box>
      )}
      {!noTags && data?.forums.edges.length === 0 ? (
        <Box display='flex' justifyContent='center'>
          <Typography variant="body1" color="textPrimary" mt={30}>
            No results.
          </Typography>
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};

export default MyFeed;
