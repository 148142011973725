import { Loading } from "../../../components";
import { Typography } from "@mui/material";
import { gql, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import UniversityProfile from "./UniversityProfile";
import StudentProfile from "./StudentProfile";
import { useState, useRef, useEffect } from "react";

const GET_USER = gql`
  query fetchUser($id: ID!) {
    userContact(id: $id) {
      objectId
      firstName
      lastName
      address1
      createdAt
      address2
      city
      state
      zipCode
      country
      phoneMobile
      email
      img {
        url
      }
      user {
        objectId
        username
      }
      userStudent {
        objectId
        gpaUW
        gpaW
        gpaScale
        act
        englishACTScore
        readingACTScore
        mathACTScore
        scienceACTScore
        adjustedUWGPA
        adjustedClassRank
        psat
        type
        mathPSATScore
        readingWritingPSATScore
        sat
        mathSATScore
        readingWritingSATScore
        satHasTaken
        actHasTaken
        psatHasTaken
        classRank
        classSize
        zipCodeState
        ethnicity
        graduationDate
        school
        interests {
          edges {
            node {
              objectId
              name
            }
          }
        }
        parentColleges {
          ... on Element {
            value
          }
        }
        siblingColleges {
          ... on Element {
            value
          }
        }
        friendColleges {
          ... on Element {
            value
          }
        }
        appliedColleges {
          ... on Element {
            value
          }
        }
        acceptedColleges {
          ... on Element {
            value
          }
        }
        enrolledColleges {
          ... on Element {
            value
          }
        }
        college {
          name
        }
        gender
        birthday
        gradYear
        parentsHighestEdu
        verificationStatus
        settings {
          showPersonalInfoToColleges
          showLastNameToColleges
          showStatsToColleges
          showContactInfoToColleges
          showCollegeListToColleges
          showMajorsToColleges
          showActivitiesAndAwardsToColleges
        }
      }
      userUniversity {
        jobTitle
        linkedCollege {
          name
        }
      }
    }
  }
`;

export default function Profile({ hasMessagingPermission }) {
  const { id } = useParams();
  const [typeAndSchool, setTypeAndSchool] = useState("");
  const [showCollegeListToColleges, setShowCollegeListToColleges] = useState<any>("");
  const [showMajorsToColleges, setShowMajorsToColleges] = useState<any>("");
  const { data, loading, error } = useQuery(GET_USER, {
    variables: { id },
  });

  useEffect(() => {
    setShowCollegeListToColleges(!data?.userContact?.userStudent?.settings?.showCollegeListToColleges);
    setShowMajorsToColleges(!data?.userContact?.userStudent?.settings?.showMajorsToColleges);

    if (data?.userContact?.userStudent?.type == "highschool_student") {
      if (data?.userContact?.userStudent?.school?.toUpperCase()) {
        if (data?.userContact?.userStudent?.settings?.showPersonalInfoToColleges) {
          setTypeAndSchool("HIGHSCHOOL STUDENT AT " + data?.userContact?.userStudent?.school?.toUpperCase());

        } else {
          setTypeAndSchool("HIGHSCHOOL STUDENT");
        }

      } else {
        setTypeAndSchool("HIGHSCHOOL STUDENT");
      }

    } else if (data?.userContact?.userStudent?.type == "college_student") {
      if (data?.userContact?.userStudent?.college == null) {
        setTypeAndSchool("COLLEGE STUDENT/HIGHSCHOOL GRAD");

      } else {
        if (data?.userContact?.userStudent?.settings?.showPersonalInfoToColleges) {
          setTypeAndSchool("COLLEGE STUDENT AT " + data?.userContact?.userStudent?.college?.name?.toUpperCase());

        } else {
          setTypeAndSchool("COLLEGE STUDENT");
        }
      }

    } else if (data?.userContact?.userStudent?.type == "college_alumni") {
      if (data?.userContact?.userStudent?.college == null) {
        setTypeAndSchool("COLLEGE ALUMNI");

      } else {
        if (data?.userContact?.userStudent?.settings?.showPersonalInfoToColleges) {
          setTypeAndSchool("COLLEGE ALUMNI AT " + data?.userContact?.userStudent?.college?.name?.toUpperCase());

        } else {
          setTypeAndSchool("COLLEGE ALUMNI");
        }
      }

    } else if (data?.userContact?.userStudent?.type == "student_parent") {
      if (data?.userContact?.userStudent?.school?.toUpperCase()) {
        if (data?.userContact?.userStudent?.settings?.showPersonalInfoToColleges) {
          setTypeAndSchool("PARENT OF STUDENT AT " + data?.userContact?.userStudent?.school?.toUpperCase());

        } else {
          setTypeAndSchool("PARENT OF STUDENT");
        }

      } else {
        setTypeAndSchool("PARENT OF STUDENT");
      }
    }
  }, [data]);

  useEffect(() => {
    document.body.style.backgroundColor = "#0e0e14";
  }, []);

  if (loading) {
    return <Loading lower />;
  }

  if (error) {
    return <Typography color="error">{error.toString()}</Typography>;
  }

  // if (statError) {
  //   return <Typography color="error">{statError.toString()}</Typography>;
  // }

  if (data?.userContact?.userUniversity) {
    return (
      <UniversityProfile
        data={data}
      // hasMessagingPermission={hasMessagingPermission}
      />
    );
  }

  if (data?.userContact?.userStudent?.settings) {
    return (
      <StudentProfile
        data={data}
        hasMessagingPermission={hasMessagingPermission}
        typeAndSchool={typeAndSchool}
        showStatsToColleges={data?.userContact?.userStudent?.settings?.showStatsToColleges}
        showPersonalInfoToColleges={data?.userContact?.userStudent?.settings?.showPersonalInfoToColleges}
        showContactInfoToColleges={data?.userContact?.userStudent?.settings?.showContactInfoToColleges}
        showCollegeListToColleges={showCollegeListToColleges}
        showMajorsToColleges={showMajorsToColleges}
      />
    );
  }

  return <></>;
}
