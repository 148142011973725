import React, { useState, useEffect } from "react";
import Parse from "parse";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  MenuList,
  MenuItem,
  Paper,
} from "@mui/material";
import FadeIn from "react-fade-in";
import { useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Loading, Page, Button } from "../../../../components";
import AdAdd from "./AdAdd";
import PostCard from "../Posts/PostCard";
import { gql, useQuery } from "@apollo/client";
import { useAuth } from "../../../../hooks/useAuth";

const useStyles = makeStyles((theme: any) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    "& ul": {
      display: "inline-flex",
    },
    marginTop: theme.spacing(0),
    margin: theme.spacing(3),
  },
  selected: {
    color: theme.palette.secondary.main,
  },
  button: {
    background: "linear-gradient(75deg, #35c669, #2ec4a9)",
    border: 0,
    color: "#fff",
  },
}));

const GET_TOTAL_NUM_STUDENTS = gql`
  query {
    userStudents {
      count
    }
  }
`;

const GET_ONBOARDED_COLLEGE = gql`
  query getOnboardedColleges($id: ID!) {
    onboardedColleges(
      where: {
        college: { have: { objectId: { equalTo: $id } } }
      }
    ) {
      edges {
        node {
          id
          objectId
          costPerAdImpression
        }
      }
    }
  }
`;

const GET_ADS = gql`
  query getPosts($id: ID!, $after: String) {
    collegePosts(
      first: 20
      where: {
        by: { have: { objectId: { equalTo: $id } } }
        isAd: { equalTo: true }
      }
      order: createdAt_DESC
      after: $after
    ) {
      count
      edges {
        cursor
        node {
          id
          objectId
          createdAt
          isAd
          link
          numComments
          impressions {
            count
          }
          taps {
            count
          }
          filterQuery {
            id
            audienceSize
          }
          by {
            id
            objectId
            name
            img {
              url
            }
          }
          img {
            url
          }
          message
          likes {
            count
          }
          comments {
            count
          }
          finalCost
        }
      }
    }
  }
`;

const GET_LIKED_POSTS = gql`
  query getLikedPosts($id: ID!) {
    userContact(id: $id) {
      likedPosts {
        edges {
          node {
            id
            objectId
          }
        }
      }
    }
  }
`;

const Ads = (props) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const navigate = useNavigate();
  const auth = useAuth();
  const [costPerAdImpression, setCostPerAdImpression] = useState(0);

  const [morePosts, setMorePosts] = useState(true);
  const [paginationLoading, setPaginationLoading] = useState(false);

  const { data, loading, error, fetchMore } = useQuery(GET_ADS, {
    variables: { id: auth.collegeId },
    fetchPolicy: "network-only",
  });
  const { data: likedPosts } = useQuery(GET_LIKED_POSTS, {
    variables: { id: Parse.User.current()?.get("userContact").id },
    fetchPolicy: "network-only",
  });
  const { data: numStudentsData, error: numStudentsError } = useQuery(
    GET_TOTAL_NUM_STUDENTS
  );
  const { data: onboardedCollegeData, error: onboardedCollegeError } = useQuery(GET_ONBOARDED_COLLEGE, {
    variables: { id: auth.collegeId },
    fetchPolicy: "network-only",
  });

  const loadMoreAds = async () => {
    try {
      await fetchMore({
        variables: {
          after:
            data?.collegePosts?.edges[data?.collegePosts?.edges?.length - 1]
              .cursor,
        },
        updateQuery: (prev: any, { fetchMoreResult }) => {
          setPaginationLoading(false);
          if (!fetchMoreResult) return prev;
          if (fetchMoreResult.collegePosts.edges.length === 0) {
            setMorePosts(false);
            return prev;
          }
          return {
            ...prev,
            collegePosts: {
              ...prev.collegePosts,
              edges: [
                ...prev.collegePosts.edges,
                ...fetchMoreResult.collegePosts.edges,
              ],
            },
          };
        },
      });
    } catch (error) { return }
  };

  useEffect(() => {
    if (onboardedCollegeData) {
      setCostPerAdImpression(onboardedCollegeData?.onboardedColleges?.edges[0].node?.costPerAdImpression);
    }
  }, [onboardedCollegeData]);

  useEffect(() => {
    document.body.style.backgroundColor = "#0e0e14";
  }, []);

  if (loading) return <Loading lower />;

  if (error) console.log(error);

  return (
    <Page className={classes.root} title="Social Feed">
      <Container maxWidth="md">
        <FadeIn>
          <Paper
            style={{
              backgroundColor: theme.palette.primary.dark,
              margin: theme.spacing(3),
              marginTop: theme.spacing(0),
              borderRadius: 18
            }}
            sx={{
              "& ul": {
                display: "inline-flex",
              },
            }}>
            <MenuList>
              {props.hasSocialPermission && (
                <MenuItem style={{ borderRadius: 25, marginLeft: 8 }} onClick={() => navigate("/dashboard/social/posts")}>
                  Social Posts
                </MenuItem>
              )}
              <MenuItem style={{ color: theme.palette.secondary.main, borderRadius: 25 }}>Advertisements</MenuItem>
            </MenuList>
          </Paper>
        </FadeIn>
        <FadeIn delay={100}>
          <Box mt={3}>
            <AdAdd
              updateAds={() => { }}
              totalStudents={numStudentsData?.userStudents?.count}
              costPerAdImpression={costPerAdImpression}
            />
          </Box>
        </FadeIn>
        {data?.collegePosts?.edges.map((post) => {
          return (
            <FadeIn delay={150}>
              <PostCard
                key={post.node.id}
                isAd={true}
                data={post.node}
                likedPosts={likedPosts?.userContact?.likedPosts?.edges?.map(
                  (e) => e.node.objectId
                )}
              />
            </FadeIn>
          );
        })}
        {data?.collegePosts?.count > 3 && morePosts ? (
          <Box sx={{ alignSelf: "center", width: "100%" }}>
            {paginationLoading ? (
              <Loading />
            ) : (
              <Button
                size="small"
                onClick={() => {
                  setPaginationLoading(true);
                  loadMoreAds();
                }}
              >
                Load More
              </Button>
            )}
          </Box>
        ) : (
          <></>
        )}
      </Container>
    </Page>
  );
};

export default Ads;
