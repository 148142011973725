import React, { useEffect, useState } from "react";
import Parse from "parse";
import { Link } from "react-router-dom";
import { Lightbox } from "react-modal-image";
import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  CardHeader,
  CardMedia,
  Grid,
  Typography,
  IconButton,
  CardContent,
  Menu,
  MenuItem,
  Tooltip,
  Chip,
  Button as MuiButton,
  TextField,
} from "@mui/material";
import { useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import ChatIcon from "@material-ui/icons/Chat";
import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import ShareIcon from "@material-ui/icons/Share";
import CommentIcon from "@material-ui/icons/Comment";
import AssessmentIcon from "@material-ui/icons/Assessment";
import { ThumbsDown, ThumbsUp } from "react-feather";
import { gql, useMutation } from "@apollo/client";
import {
  Button,
  Input,
  Loading,
  Modal as CustomModal,
} from "../../../../components";
import SendIcon from "@material-ui/icons/Send";
import { useAuth } from "../../../../hooks/useAuth";
import { parseDate } from "../../../../util";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme: any) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  reactions: {
    display: "flex",
    alignItems: "center",
  },
  reply: {
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(3),
    borderRadius: 20,
    outlineColor: "#29292d",
    outlineStyle: "solid",
    outlineWidth: 1,
  },
  cancelButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.text.primary,
    marginRight: theme.spacing(1),
  },
  errorButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.text.primary,
  },
  input: {
    "& .MuiInputBase-root": {
      backgroundColor: theme.palette.primary.light,
      borderBottomRightRadius: theme.shape.borderRadius,
      borderBottomLeftRadius: theme.shape.borderRadius,
    },
  },
}));

const EDIT_REPLY = gql`
  mutation editReply($id: ID!, $content: String!) {
    updateForumReply(input: { id: $id, fields: { content: $content } }) {
      forumReply {
        id
        objectId
        content
      }
    }
  }
`;

const REPORT_REPLY = gql`
  mutation reportReply($id: ID!, $myID: ID!, $comment: String) {
    createReportedForumReply(
      input: { fields: { reply: { link: $id }, from: { link: $myID } , comment: $comment} }
    ) {
      reportedForumReply {
        id
        objectId
      }
    }
  }
`;

const Reply = ({ reply, ...props }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const auth = useAuth();
  const [editing, setEditing] = useState(false);
  const [openOwnReplyMenu, setOpenOwnReplyMenu] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [anchor, setAnchor] = useState(null);
  const [content, setContent] = useState(reply.content);
  const [editReply] = useMutation(EDIT_REPLY);
  const [didDelete, setDidDelete] = useState(false)
  const [reportReply, { data: reportData, error }] = useMutation(REPORT_REPLY)
  const [openReportBox, setOpenReportBox] = useState(false)
  const [reportDescription, setReportDescription] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [reportReturn, setReportReturn] = useState<any>(null);

  const handleReport = async (e) => {
    e.preventDefault();
    console.log(reply)
    // reportReply({
    //   variables: {
    //     id: reply?.objectId,
    //     myID: Parse.User.current().get("userContact").id,
    //     comment: reportDescription
    //   },
    // });
    if (Parse.User.current()) {
      await setReportReturn(Parse.Cloud.run("reportForumReply", {
        forumReplyID: reply?.objectId,
        comment: reportDescription,
        user: Parse.User.current()?.id,
      }));
    }
    setOpenMenu(false);
    setOpenReportBox(false);
  };

  const handleChange = (event) => {
    event.persist();
    setReportDescription(event.target.value);
  };

  const deleteReply = async () => {
    setOpenDeleteConfirm(false);

    await Parse.Cloud.run("deleteCollegeForumReply", {
      replyId: reply?.objectId,
    })

    setDidDelete(true);
  }

  useEffect(() => {
    if (reply) {
      setContent(reply?.content);
    }
  }, [reply]);

  useEffect(() => {
    if (reportReturn === 1) {
      enqueueSnackbar("Error reported forum.", {
        variant: "error",
      });
    } else if (reportReturn) {
      enqueueSnackbar("Successfully reported forum.", {
        variant: "success",
      });
    }
  }, [reportReturn]);

  if (didDelete) {
    return <></>;
  }

  return (
    <Card style={{
      backgroundColor: theme.palette.primary.dark,
      marginTop: theme.spacing(3),
      padding: theme.spacing(0.5),
      borderRadius: 25,
      outlineColor: "#29292d",
      outlineStyle: "solid",
      outlineWidth: 1,
    }}>
      <CardHeader
        avatar={
          <Avatar
            alt="Person"
            component={Link}
            to={
              reply?.college
                ? "/dashboard/profile"
                : `/dashboard/profiles/${reply?.author?.objectId}`
            }
            src={
              reply?.college
                ? reply?.college?.img?.url
                : reply?.author?.img?.url
            }
          />
        }
        disableTypography
        subheader={
          <Box display="flex" alignItems="center">
            <Typography variant="caption" color="textSecondary" style={{ fontSize: 13 }}>
              {parseDate(reply?.createdAt)}
            </Typography>
          </Box>
        }
        title={
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: 'transparent',
            }}
          >
            <Typography
              color="textPrimary"
              component={Link}
              to={
                reply?.college
                  ? "/dashboard/profile"
                  : `/dashboard/profiles/${reply?.author?.objectId}`
              }
              style={{ textDecoration: "none" }}
              variant="h6"
            >
              {reply?.college
                ? reply?.college?.name
                : reply?.author?.firstName + " " + reply?.author?.lastName}
            </Typography>
            {reply?.author?.userStudent?.verificationStatus == "verified" ? (
              <img
                height={17}
                width={17}
                src={"/assets/icons/ic_verify.png"}
                style={{
                  visibility: reply?.author?.userStudent?.verificationStatus == "verified" ? "visible" : "hidden",
                  marginLeft: theme.spacing(0.5),
                }}
              />
            ) : (<></>)}
            {reply?.author?.userStudent?.appliedColleges || reply?.author?.userStudent?.acceptedColleges || reply?.author?.userStudent?.enrolledColleges ? (
              <Tooltip
                title={reply?.author?.userStudent?.appliedColleges.map((x) => x.value).includes(auth.collegeId) ? "Applied" : reply?.author?.userStudent?.acceptedColleges.map((x) => x.value).includes(auth.collegeId) ? "Accepted" : reply?.author?.userStudent?.enrolledColleges.map((x) => x.value).includes(auth.collegeId) ? "Enrolled" : "Not Applied"}
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: "#46464f",
                      color: "white"
                    }
                  }
                }}
              >
                <img
                  height={14}
                  width={14}
                  src={reply?.author?.userStudent?.appliedColleges.map((x) => x.value).includes(auth.collegeId) ? "/assets/icons/ic_applied.svg" : reply?.author?.userStudent?.acceptedColleges.map((x) => x.value).includes(auth.collegeId) ? "/assets/icons/ic_accepted.svg" : reply?.author?.userStudent?.enrolledColleges.map((x) => x.value).includes(auth.collegeId) ? "/assets/icons/ic_enrolled.svg" : "/assets/icons/ic_applied.svg"}
                  style={{
                    visibility: reply?.author?.userStudent?.appliedColleges.map((x) => x.value).includes(auth.collegeId) || reply?.author?.userStudent?.acceptedColleges.map((x) => x.value).includes(auth.collegeId) || reply?.author?.userStudent?.enrolledColleges.map((x) => x.value).includes(auth.collegeId) ? "visible" : "hidden",
                    marginLeft: theme.spacing(0.5),
                  }}
                />
              </Tooltip>
            ) : (<></>)}
          </div>
        }
        action={
          reply?.college?.objectId === auth.collegeId ? (
            <>
              <IconButton
                aria-controls="ad-menu"
                onClick={(e: any) => {
                  setOpenOwnReplyMenu(true);
                  setAnchor(e.currentTarget);
                }}
              >
                <MoreHorizIcon />
              </IconButton>
              <Menu
                id="ad-menu"
                anchorEl={anchor}
                open={openOwnReplyMenu}
                slotProps={{
                  paper: {
                    sx: {
                      backgroundColor: theme.palette.primary.dark,
                      borderRadius: 1.5,
                      outlineColor: "#3c3c44",
                      outlineStyle: "solid",
                      outlineWidth: 1,
                    }
                  }
                }}
                onClose={() => setOpenOwnReplyMenu(false)}
              >
                <MenuItem
                  onClick={() => {
                    setEditing(true);
                    setOpenOwnReplyMenu(false);
                  }}
                >
                  Edit
                </MenuItem>
                <MenuItem onClick={() => setOpenDeleteConfirm(true)}>
                  Delete
                </MenuItem>
              </Menu>
              <CustomModal
                open={openDeleteConfirm}
                setOpen={setOpenDeleteConfirm}
                sub="Are you sure you want to delete this post?"
                noScroll
              >
                <Box display="flex">
                  <Grid container spacing={2}>
                    <Grid item xs={1} />
                    <Grid item xs={5}>
                      <MuiButton
                        style={{
                          height: 50,
                          backgroundColor: theme.palette.error.main,
                          color: theme.palette.text.primary,
                          borderRadius: 14
                        }}
                        fullWidth
                        variant="contained"
                        onClick={() => setOpenDeleteConfirm(false)}
                      >
                        No
                      </MuiButton>
                    </Grid>
                    <Grid item xs={5}>
                      <Button
                        style={{
                          height: 50,
                          borderRadius: 14
                        }}
                        variant="contained"
                        fullWidth
                        onClick={deleteReply}
                      >
                        Yes
                      </Button>
                    </Grid>
                    <Grid item xs={1} />
                  </Grid>
                </Box>
              </CustomModal>
            </>
          ) : (
            <>
              <IconButton
                aria-controls="ad-menu"
                onClick={(e: any) => {
                  setOpenMenu(true);
                  setAnchor(e.currentTarget);
                }}
              >
                <MoreHorizIcon />
              </IconButton>
              <Menu
                id="ad-menu"
                anchorEl={anchor}
                open={openMenu}
                slotProps={{
                  paper: {
                    sx: {
                      backgroundColor: theme.palette.primary.dark,
                      borderRadius: 1.5,
                      outlineColor: "#3c3c44",
                      outlineStyle: "solid",
                      outlineWidth: 1,
                    }
                  }
                }}
                onClose={() => setOpenMenu(false)}
              >
                <MenuItem onClick={() => setOpenReportBox(true)}>
                  Report
                </MenuItem>
                <CustomModal
                  open={openReportBox}
                  setOpen={setOpenReportBox}
                  sub="Give a reason for your report."
                  noScroll
                >
                  <form onSubmit={handleReport} noValidate>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-around"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid item xs={9}>
                        <TextField
                          className={classes.input}
                          fullWidth
                          onChange={handleChange}
                          value={reportDescription}
                          label={"Reason for report"}
                          variant="filled"
                          InputProps={{ disableUnderline: true }}
                        />
                      </Grid>

                      <Grid item xs={3}>
                        <Button
                          style={{ height: 50 }}
                          variant="contained"
                          fullWidth
                          type="submit"
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </CustomModal>
              </Menu>
            </>
          )
        }
      />
      <Box px={3} pb={2}>
        {editing ? (
          <>
            <Input
              multiline
              fullWidth
              label={`Edit reply`}
              value={content}
              variant="filled"
              InputProps={{ disableUnderline: true }}
              style={{
                height: 45
              }}
              onChange={(e) => setContent(e.target.value)}
            />
            <Box mt={3} display='flex' justifyContent='right'>
              <Button
                onClick={() => {
                  setEditing(false);
                  setContent(reply?.content);
                }}
                className={classes.cancelButton}
                style={{ marginRight: 10 }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  setOpenOwnReplyMenu(false);
                  setEditing(false);
                  editReply({
                    variables: {
                      id: reply?.objectId,
                      content: content,
                    },
                  });
                }}
              >
                Save
              </Button>
            </Box>
          </>
        ) : (
          <Typography>{reply?.content}</Typography>
        )}
        <Box mt={2}>
          <div className={classes.reactions}>
            <IconButton disabled>
              <ThumbsUp fontSize="small" />
            </IconButton>
            <Typography color="textSecondary" style={{ fontSize: 18, fontWeight: 600 }}>
              {reply.numUpVotes ?? 0}
            </Typography>
            <Box width={10} />
            <IconButton disabled>
              <ThumbsDown fontSize="small" />
            </IconButton>
            <Typography color="textSecondary" style={{ fontSize: 18, fontWeight: 600 }}>
              {reply.numDownVotes ?? 0}
            </Typography>
          </div>
        </Box>
      </Box>
    </Card>
  );
};

export default Reply;
