import { lazy } from 'react';

// ----------------------------------------------------------------------

const PrivacyPage = lazy(() => import('../../Pages/privacy'));

// ----------------------------------------------------------------------

export const privacypRoutes = [
  {
    path: 'privacy',
    children: [
      { element: <PrivacyPage />, index: true },
    ],
  },
];
