import React, { useEffect, useState, ChangeEvent } from "react";
import Parse from "parse";
import { Loading } from "../../../../components";
import {
  Box,
  Card,
  CardContent,
  Divider,
  IconButton,
  Input,
  Paper,
  Tooltip,
  Fade,
  Modal,
  Backdrop,
  Grid,
  TextField,
  Button,
  Typography,
} from "@mui/material";
import { useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import SendIcon from "@material-ui/icons/Send";
import AddPhotoIcon from "@material-ui/icons/AddPhotoAlternate";
import { gql, useMutation } from "@apollo/client";
import { useAuth } from "../../../../hooks/useAuth";
import PostCard from "./PostCard";

const useStyles = makeStyles((theme: any) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    margin: theme.spacing(3),
    borderRadius: 15,
    outlineColor: "#25252b",
    outlineStyle: "solid",
    outlineWidth: 1,
  },
  inputContainer: {
    backgroundColor: theme.palette.background.paper,
    flexGrow: 1,
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(0.5),
  },
  divider: {
    height: 24,
    width: 1,
  },
  fileInput: {
    display: "none",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(4),
    maxWidth: "400px",
    borderRadius: 20,
  },
  button: {
    background: "linear-gradient(75deg, #35c669, #2ec4a9)",
    border: 0,
    borderRadius: 12,
    color: "#fff",
    padding: "0 30px",
    minHeight: "50px",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    minWidth: 100
  },
  input: {
    "& .MuiInputBase-root": {
      backgroundColor: theme.palette.primary.light,
      borderBottomRightRadius: theme.shape.borderRadius,
      borderBottomLeftRadius: theme.shape.borderRadius,
      outlineColor: "#35353d",
      outlineStyle: "solid",
      outlineWidth: 1,
    },
  },
}));

const CREATE_FILE = gql`
  mutation createFile($file: Upload!) {
    createFile(input: { upload: $file }) {
      fileInfo {
        name
        url
      }
    }
  }
`;

const CREATE_POST = gql`
  mutation createPost($message: String!, $collegeId: ID!, $file: File) {
    createCollegePost(
      input: {
        fields: {
          message: $message
          img: { file: $file }
          by: { link: $collegeId }
          isAd: false
          numLikes: 0
          numComments: 0
        }
      }
    ) {
      collegePost {
        id
        objectId
        createdAt
        by {
          id
          objectId
          name
          img {
            url
          }
        }
        img {
          url
        }
        message
        likes {
          count
        }
        comments {
          count
        }
      }
    }
  }
`;

const CREATE_POST_WITHOUT_IMG = gql`
  mutation createPost($message: String!, $collegeId: ID!) {
    createCollegePost(
      input: {
        fields: {
          message: $message
          by: { link: $collegeId }
          isAd: false
          numLikes: 0
          numComments: 0
        }
      }
    ) {
      collegePost {
        id
        objectId
        createdAt
        by {
          id
          objectId
          name
          img {
            url
          }
        }
        message
        likes {
          count
        }
        comments {
          count
        }
      }
    }
  }
`;

const PostAdd = (props) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const auth = useAuth();
  const [value, setValue] = useState("");
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [tempURL, setTempURL] = useState(null);
  const [newPosts, setNewPosts] = useState<any>([]);
  const [createPost, { data: dataImg, loading: loadingImg, error: errorImg }] = useMutation(CREATE_POST);
  const [createPostWithoutImg, { data: data, loading: loading, error: error }] = useMutation(CREATE_POST_WITHOUT_IMG);

  const [
    uploadFile,
    { data: fileData, loading: uploading, error: errorUploading },
  ] = useMutation(CREATE_FILE);

  const handleImageSelect = (event: ChangeEvent<HTMLInputElement>) => {
    event.persist();

    if (event.currentTarget.files) {
      const file = event.currentTarget.files[0];

      console.log(file);

      uploadFile({ variables: { file } });
    }
    // target: {
    //   files: [file],
    // },
  }

  const handleChange = (event) => {
    event.persist();
    setValue(event.target.value);
  };

  const handleAttach = (event) => {
    event.preventDefault();
    setOpen(false);
  };

  const handlePost = async (event) => {
    event.preventDefault();

    if (tempURL === "" || tempURL === null) {
      createPostWithoutImg({
        variables: {
          message: value,
          collegeId: auth.collegeId,
        },
      });
    } else {
      createPost({
        variables: {
          message: value,
          collegeId: auth.collegeId,
          file:
            tempURL === "" || tempURL === null
              ? null
              : {
                __type: "File",
                name: fileData?.createFile?.fileInfo?.name,
                url: fileData?.createFile?.fileInfo?.url,
              },
        },
      });
    }

    await Parse.Cloud.run("sendIOSPush", { type: "postFromCollege", id: auth.collegeId, bodyStr: auth.collegeName + " just posted something new!" });
  };

  useEffect(() => {
    if (fileData) {
      setTempURL(fileData?.createFile?.fileInfo?.url);
    }
  }, [fileData]);

  useEffect(() => {
    if (errorUploading) {
      console.log("Error uploading image.");
      console.log(JSON.stringify(errorUploading, null, 2));
    }
  }, [errorUploading]);

  useEffect(() => {
    if (data) {
      console.log(data);
      setNewPosts((np) => [data?.createCollegePost?.collegePost, ...np]);
      setValue("");
      setFile(null);

    } else if (dataImg) {
      console.log(dataImg);
      setNewPosts((np) => [dataImg?.createCollegePost?.collegePost, ...np]);
      setValue("");
      setFile(null);
    }
  }, [data, dataImg]);

  return (
    <>
      <form onSubmit={handlePost} noValidate>
        <Card
          style={{
            backgroundColor: theme.palette.primary.dark,
            margin: theme.spacing(3),
            borderRadius: 20,
            outlineColor: "#25252b",
            outlineStyle: "solid",
            outlineWidth: 1,
          }}>
          <Box height="6px" />
          <CardContent>
            <Box display="flex" alignItems="center">
              <Box width="10px" />
              <TextField
                fullWidth
                multiline
                onChange={handleChange}
                sx={{
                  "& .MuiInputBase-root": {
                    backgroundColor: theme.palette.primary.light,
                    borderRadius: 1.7,
                    outlineColor: "#35353d",
                    outlineStyle: "solid",
                    outlineWidth: 1,
                  },
                }}
                label={`Write a new post...`}
                value={value}
                variant="filled"
                InputProps={{ disableUnderline: true }}
              />
              <Tooltip
                title="Post"
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: "#46464f",
                      color: "white"
                    }
                  }
                }}
              >
                {value.length === 0 ? (
                  <IconButton disabled>
                    <SendIcon />
                  </IconButton>
                ) : (
                  <IconButton type="submit">
                    <SendIcon style={{ color: theme.palette.secondary.main }} />
                  </IconButton>
                )}
              </Tooltip>
              <Divider className={classes.divider} />
              <Tooltip
                title={tempURL ? "Image attached" : "Attach Image"}
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: "#46464f",
                      color: "white"
                    }
                  }
                }}
              >
                <IconButton edge="end" onClick={() => setOpen(true)}>
                  {tempURL === null ? (
                    <AddPhotoIcon />
                  ) : (
                    <AddPhotoIcon style={{ color: "#35c669" }} />
                  )}
                </IconButton>
              </Tooltip>
            </Box>
          </CardContent>
        </Card>
        {loading || loadingImg && (
          <>
            <Loading />
            <Box height="20px" />
          </>
        )}
      </form>
      <Modal
        className={classes.modal}
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
      >
        <Fade in={open}>
          <Card style={{
            backgroundColor: theme.palette.primary.dark,
            display: "flex",
            flexDirection: "column",
            padding: theme.spacing(4),
            maxWidth: "400px",
            borderRadius: 20,
            outlineColor: "#323238",
            outlineStyle: "solid",
            outlineWidth: 1,
          }}>
            <form noValidate onSubmit={handleAttach}>
              <Grid container spacing={2} sx={{ alignSelf: "center" }}>
                <Grid item xs={12}>
                  <Typography variant="h4" align="center" color="textPrimary" style={{ fontWeight: 600 }}>
                    Attach Media
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Box display='flex' justifyContent='center'>
                    <img
                      alt="post-media"
                      style={{
                        borderRadius: 30,
                        width: 290,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        objectFit: 'cover',
                        backgroundPosition: "center",
                        height: 200,
                      }}
                      src={tempURL ? tempURL : ""}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box display='flex' justifyContent='center'>
                    <input
                      type="file"
                      accept="image/png/jpg/jpeg"
                      onChange={handleImageSelect}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} spacing={2}>
                  <Box display='flex' justifyContent='center'>
                    <Button
                      style={{
                        backgroundColor: theme.palette.error.main,
                        border: 0,
                        borderRadius: 14,
                        color: "#fff",
                        padding: "0 30px",
                        minHeight: "50px",
                        width: 130,
                        marginLeft: theme.spacing(1),
                        marginRight: theme.spacing(1),
                        minWidth: 100
                      }}
                      onClick={() => {
                        setOpen(false);
                        setFile(null);
                        setTempURL(null);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      style={{
                        background: "linear-gradient(75deg, #35c669, #2ec4a9)",
                        border: 0,
                        borderRadius: 14,
                        color: "#fff",
                        padding: "0 30px",
                        minHeight: "50px",
                        width: 130,
                        marginLeft: theme.spacing(1),
                        marginRight: theme.spacing(1),
                        minWidth: 100
                      }} type="submit">
                      Continue
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Card>
        </Fade>
      </Modal>
      {newPosts?.map((post) => (
        <PostCard key={post.id} data={post} />
      ))}
    </>
  );
};

export default PostAdd;
