import React, { useEffect, useState } from "react";
import Parse from "parse";
import { useParams, Navigate } from "react-router-dom";
import { Loading } from "../../../components";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Typography } from "@mui/material";
import { useAuth } from "../../../hooks/useAuth";

const FETCH_EXISTING = gql`
  query fetchExistingThread($studentID: ID!, $collegeID: ID!) {
    collegeThreads(
      where: {
        student: { have: { objectId: { equalTo: $studentID } } }
        college: { have: { objectId: { equalTo: $collegeID } } }
      }
    ) {
      count
      edges {
        node {
          objectId
        }
      }
    }
  }
`;

const CREATE_THREAD = gql`
  mutation createThread($studentID: ID!, $collegeID: ID!, $uuid: ID!) {
    createCollegeThread(
      input: {
        fields: { student: { link: $studentID }, college: { link: $collegeID }, claimedBy: { link: $uuid } }
      }
    ) {
      collegeThread {
        id
        objectId
      }
    }
  }
`;

export default function CreateThread() {
  const { id } = useParams();
  const auth = useAuth();
  const {
    data: queryData,
    loading: queryLoading,
    error: queryError,
  } = useQuery(FETCH_EXISTING, {
    variables: { studentID: id, collegeID: auth.collegeId },
  });
  const [createThread, { data, loading, error }] = useMutation(CREATE_THREAD);
  const [redirect, setRedirect] = useState(false);
  const [threadID, setThreadID] = useState("");
  const uuid = Parse.User.current()!.get("userUniversity").id;

  useEffect(() => {
    console.log(queryData);
    if (!queryLoading) {
      if (queryData?.collegeThreads.count === 0) {
        createThread({
          variables: {
            studentID: id,
            collegeID: auth.collegeId,
            uuid: uuid,
          },
        });
      } else {
        setThreadID(queryData?.collegeThreads?.edges[0].node?.objectId);
        setRedirect(true);
      }
    }
  }, [queryData]);

  useEffect(() => {
    if (data) {
      setThreadID(data?.createCollegeThread?.collegeThread?.objectId);
      setRedirect(true);
    }
  }, [data]);

  if (error) return <Typography color="error">{error.toString()}</Typography>;
  if (queryError)
    return <Typography color="error">{queryError.toString()}</Typography>;

  if (loading || queryLoading) return <Loading lower />;

  if (redirect) {
    return <Navigate to={`/dashboard/messages/${threadID}`} />;
  }

  return <></>;
}
