import React, { useState, useEffect } from "react";
import Parse from "parse";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  MenuList,
  MenuItem,
  Paper,
  Typography,
  Button,
} from "@mui/material";
import FadeIn from "react-fade-in";
import { useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Loading, Page } from "../../../../components";
import PostAdd from "./PostAdd";
import PostCard from "./PostCard";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { useAuth } from "../../../../hooks/useAuth";

const useStyles = makeStyles((theme: any) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    "& ul": {
      display: "inline-flex",
    },
    marginTop: theme.spacing(0),
    margin: theme.spacing(3),
  },
  selected: {
    color: theme.palette.secondary.main,
  },
  button: {
    background: "linear-gradient(75deg, #35c669, #2ec4a9)",
    border: 0,
    color: "#fff",
  },
}));

const GET_POSTS = gql`
  query getPosts($id: ID!, $after: String) {
    collegePosts(
      first: 3
      after: $after
      where: {
        by: { have: { objectId: { equalTo: $id } } }
        isAd: { equalTo: false }
      }
      order: createdAt_DESC
    ) {
      count
      edges {
        cursor
        node {
          id
          objectId
          createdAt
          numComments
          impressions {
            count
          }
          taps {
            count
          }
          by {
            id
            objectId
            name
            img {
              url
            }
          }
          img {
            url
          }
          message
          likes {
            count
          }
          comments {
            count
          }
          finalCost
        }
      }
    }
  }
`;

const GET_LIKED_POSTS = gql`
  query getLikedPosts($id: ID!) {
    userContact(id: $id) {
      likedPosts {
        edges {
          node {
            objectId
          }
        }
      }
    }
  }
`;

const Posts = (props) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const navigate = useNavigate();
  const auth = useAuth();
  const [morePosts, setMorePosts] = useState(true);

  const [getPosts, { data, loading, error, fetchMore }] = useLazyQuery(
    GET_POSTS,
    { fetchPolicy: "network-only" }
  );
  const { data: likedPosts } = useQuery(GET_LIKED_POSTS, {
    variables: { id: Parse.User.current()?.get("userContact").id },
  });

  const fetchPosts = () => {
    getPosts({
      variables: { id: auth.collegeId },
    });
  };

  const handleFetchMore = async (event) => {
    try {
      if (fetchMore) {
        await fetchMore({
          variables: {
            after:
              data?.collegePosts?.edges[data?.collegePosts?.edges?.length - 1]
                .cursor,
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;
            if (fetchMoreResult.collegePosts.edges.length === 0) {
              setMorePosts(false);
              return prev;
            }
            return {
              ...prev,
              collegePosts: {
                ...prev.collegePosts,
                edges: [
                  ...prev.collegePosts.edges,
                  ...fetchMoreResult.collegePosts.edges,
                ],
              },
            };
          },
        });
      }
    } catch (error) { return }
  };

  useEffect(() => {
    document.body.style.backgroundColor = "#0e0e14";

    fetchPosts();
  }, []);

  useEffect(() => {
    if (data?.collegePosts?.count <= 3) setMorePosts(false);
  }, [data]);

  if (loading) return <Loading lower />;

  if (error) return <Typography>{error.toString()}</Typography>; // TODO: Turn these into notistack error bubbles!

  console.log(props.hasAdsPermission);

  return (
    <Page style={{
      backgroundColor: theme.palette.primary.dark,
      minHeight: "100%",
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    }} title="Social Feed">
      <Container maxWidth="md">
        <FadeIn>
          <Paper
            style={{
              backgroundColor: theme.palette.primary.dark,
              margin: theme.spacing(3),
              marginTop: theme.spacing(0),
              borderRadius: 18
            }}
            sx={{
              "& ul": {
                display: "inline-flex",
              },
            }}>
            <MenuList>
              <MenuItem style={{ color: theme.palette.secondary.main, borderRadius: 25, marginLeft: 8 }}>Social Posts</MenuItem>
              {props.hasAdsPermission && (
                <MenuItem style={{ borderRadius: 25 }} onClick={() => navigate("/dashboard/social/ads")}>
                  Advertisements
                </MenuItem>
              )}
            </MenuList>
          </Paper>
        </FadeIn>
        <FadeIn delay={100}>
          <Box mt={3}>
            <PostAdd updatePosts={fetchPosts} />
          </Box>
        </FadeIn>
        {data?.collegePosts.edges?.map((post) => {
          return (
            <FadeIn delay={150}>
              <PostCard
                key={post?.node?.id}
                data={post.node}
                likedPosts={likedPosts?.userContact?.likedPosts?.edges?.map(
                  (e) => e.node.objectId
                )}
              />
            </FadeIn>
          );
        })}
        <Box display='flex' justifyContent='center'>
          {morePosts && (
            <Button
              variant="outlined"
              style={{
                background: "linear-gradient(75deg, #35c669, #2ec4a9)",
                border: 0,
                color: "#fff",
              }}
              onClick={handleFetchMore}
            >
              Load More
            </Button>
          )}
        </Box>
      </Container>
    </Page>
  );
};

export default Posts;
