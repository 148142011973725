import React, { useState, useRef, useEffect } from "react";
import Parse from "parse";
import { Page, States, Loading, Input } from "../../../components";
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Avatar,
  Button,
  Box,
  TextField,
  FormControlLabel,
  Switch,
  Typography,
  MenuItem,
} from "@mui/material";
import FadeIn from "react-fade-in";
import { useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useAuth } from "../../../hooks/useAuth";
import { gql, useMutation, useQuery } from "@apollo/client";

const useStyles = makeStyles((theme: any) => ({
  root: {},
  card: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
    borderRadius: 20,
    outlineColor: "#25252b",
    outlineStyle: "solid",
    outlineWidth: 1,
  },
  button: {
    background: "linear-gradient(75deg, #35c669, #2ec4a9)",
    border: 0,
    color: "#fff",
    marginBottom: -15,
    minWidth: 90
  },
  sentButton: {
    borderColor: "linear-gradient(75deg, #35c669, #2ec4a9)",
    border: 0,
    color: "#fff",
    marginBottom: -15,
  },
  avatar: {
    marginTop: -15,
    width: 100,
    height: 100,
  },
  submit: {
    margin: theme.spacing(3),
    marginBottom: theme.spacing(20),
  },
  titleFont: {
    fontWeight: 600
  },
  settings_card: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
    height: 366,
    borderRadius: 20,
    outlineColor: "#25252b",
    outlineStyle: "solid",
    outlineWidth: 1,
  },
  input: {
    "& .MuiInputBase-root": {
      backgroundColor: theme.palette.primary.light,
      borderBottomRightRadius: theme.shape.borderRadius,
      borderBottomLeftRadius: theme.shape.borderRadius,
    },
  },
}));

const FETCH_SETTINGS = gql`
  query fetchSettings($id: ID!) {
    user(id: $id) {
      id
      objectId
      username
      email
      emailVerified
      userContact {
        id
        objectId
        firstName
        lastName
        email
        phoneMobile
        address1
        address2
        city
        state
        zipCode
        img {
          url
        }
      }
      userUniversity {
        id
        objectId
        workPhone
      }
    }
  }
`;

const SAVE_SETTINGS = gql`
  mutation updateSettings(
    $ucid: ID!
    $uuid: ID!
    $email: String!
    $firstName: String
    $lastName: String
    $mobile: String
    $work: String
    $address1: String
    $address2: String
    $city: String
    $st: String
    $zip: String
  ) {
    updateUserContact(
      input: {
        id: $ucid
        fields: {
          email: $email
          firstName: $firstName
          lastName: $lastName
          phoneMobile: $mobile
          address1: $address1
          address2: $address2
          city: $city
          state: $st
          zipCode: $zip
        }
      }
    ) {
      userContact {
        id
        objectId
        firstName
        lastName
        email
        phoneMobile
        address1
        address2
        city
        state
        zipCode
      }
    }
    updateUserUniversity(
      input: { id: $uuid, fields: {workPhone: $work } }
    ) {
      userUniversity {
        id
        workPhone
      }
    }
  }
`;

export default function Settings() {
  const theme = useTheme();
  const classes = useStyles(theme);

  const hiddenFileInput = useRef<any>(null);
  const auth = useAuth();
  const id = Parse.User.current()?.id;
  const [profileURL, setProfileURL] = useState(null);
  const [loadingProfileImage, setLoadingProfileImage] = useState(false);
  const [sent, setSent] = useState(false);
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneMobile, setPhoneMobile] = useState("");
  const [phoneWork, setPhoneWork] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState();
  const [zipCode, setZipCode] = useState("");
  const [saveSettings, { loading: saving }] = useMutation(SAVE_SETTINGS);
  const { data, loading, error } = useQuery(FETCH_SETTINGS, {
    variables: { id },
    fetchPolicy: "network-only",
  });

  const SETTINGS_SPACING = "20px";

  const handleFileChange = async (event) => {
    setLoadingProfileImage(true);
    var file;

    var reader = new FileReader();
    reader.onload = function (upload: any) {
      file = upload.target.result;
    };
    reader.readAsDataURL(event.target.files[0]);

    await new Parse.Query(Parse.Object.extend(new Parse.User))
      .get(Parse.User.current()!.id)
      .then((user) => {
        new Parse.Query(Parse.Object.extend("userContact"))
          .get(user.get("userContact").id)
          .then((userContact) => {
            userContact.set(
              "img",
              new Parse.File("profileImage.png", { base64: file })
            );
            userContact.save().then(
              (response) => {
                console.log("img", response.get("img")._url);
                setProfileURL(response.get("img")._url);
                setLoadingProfileImage(false);
              },
              (error) => {
                console.error("Error while updating colleges", error);
                setLoadingProfileImage(false);
              }
            );
          });
      });
  };

  const handlePasswordReset = async () => {
    await auth.sendPasswordResetEmail();
    setSent(true);
  };

  const handleSave = async () => {
    if (Parse.User.current()) {
      const variables = {
        uid: Parse.User.current()?.id,
        ucid: Parse.User.current()?.get("userContact").id,
        uuid: Parse.User.current()?.get("userUniversity").id,
        username: username,
        email: email,
        firstName: firstName,
        lastName: lastName,
        mobile: phoneMobile,
        work: phoneWork,
        address1: address1,
        address2: address2,
        city: city,
        st: state,
        zip: zipCode,
      }

      saveSettings({
        variables
      });

      await Parse.Cloud.run("updateCollegeUserInfo", {
        userId: variables.uid,
        email: variables.email,
        username: variables.username
      }).then(async function (response) {
        console.log(response);
      });
    }
  };

  useEffect(() => {
    setEmail(data?.user?.email ?? data?.user?.userContact?.email);
    setUsername(data?.user?.username ?? data?.user?.userContact?.username);
    setFirstName(data?.user?.userContact?.firstName);
    setLastName(data?.user?.userContact?.lastName);
    setPhoneMobile(data?.user?.userContact?.phoneMobile);
    setPhoneWork(data?.user?.userUniversity?.workPhone);
    setAddress1(data?.user?.userContact?.address1);
    setAddress2(data?.user?.userContact?.address2);
    setCity(data?.user?.userContact?.city);
    setState(data?.user?.userContact?.state);
    setZipCode(data?.user?.userContact?.zipCode);

    console.log(data);
  }, [data]);

  useEffect(() => {
    document.body.style.backgroundColor = "#0e0e14";
  }, []);

  if (loading) return <Loading lower />;

  if (error) console.log(JSON.stringify(error, null, 2));

  return (
    <Page title="User Profile" maxWidth="xl">
      <Grid container spacing={3}>
        <Grid item xs={3.5}>
          <FadeIn>
            <Card style={{
              backgroundColor: theme.palette.primary.dark,
              padding: theme.spacing(3),
              paddingTop: 10,
              borderRadius: 25,
              outlineColor: "#25252b",
              outlineStyle: "solid",
              outlineWidth: 1,
            }}>
              <CardHeader align="center" title="Profile Image" titleTypographyProps={{ fontWeight: 600 }} />
              <CardContent>
                {loadingProfileImage ? (
                  <Box display='flex' justifyContent='center'>
                    <Loading small />
                  </Box>
                ) : (
                  <Avatar
                    alt="User"
                    src={data?.user?.userContact?.img?.url ?? profileURL}
                    style={{
                      backgroundColor: "#22222b",
                      marginTop: theme.spacing(-3),
                      width: 100,
                      height: 100,
                      margin: 'auto'
                    }}
                  />
                )}
                <Box height="20px" />
                <Box display='flex' style={{ justifyContent: 'center' }}>
                  <Button
                    style={{
                      background: "linear-gradient(75deg, #35c669, #2ec4a9)",
                      border: 0,
                      color: "#fff",
                      borderRadius: 12,
                      marginBottom: theme.spacing(-3),
                      minWidth: 100,
                    }}
                    onClick={() => hiddenFileInput.current.click()}
                  >
                    Upload
                  </Button>
                </Box>
                <input
                  type="file"
                  accept="image/png/jpg/jpeg"
                  ref={hiddenFileInput}
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                  value={undefined}
                />
              </CardContent>
            </Card>
            {/* <Box height="20px" />
          <Card className={classes.settings_card}>
            <CardHeader align="center" title="Settings" />
            <CardContent align="center">
              <FormControlLabel
                control={<Switch onChange={() => {}} name="checkedA" />}
                label="Example Setting 1"
                labelPlacement="start"
                disabled
              />
              <Box height={SETTINGS_SPACING} />
              <FormControlLabel
                control={<Switch onChange={() => {}} name="checkedA" />}
                label="Example Setting 2"
                labelPlacement="start"
                disabled
              />
              <Box height={SETTINGS_SPACING} />
              <FormControlLabel
                control={<Switch onChange={() => {}} name="checkedA" />}
                label="Example Setting 3"
                labelPlacement="start"
                disabled
              />
              <Box height={SETTINGS_SPACING} />
              <FormControlLabel
                control={<Switch onChange={() => {}} name="checkedA" />}
                label="Example Setting 4"
                labelPlacement="start"
                disabled
              />
              <Box height={SETTINGS_SPACING} />
              <FormControlLabel
                control={<Switch onChange={() => {}} name="checkedA" />}
                label="Example Setting 5"
                labelPlacement="start"
                disabled
              />
            </CardContent>
          </Card> */}
          </FadeIn>
        </Grid>
        <Grid item xs={8.5}>
          <FadeIn delay={100}>
            <Card style={{
              backgroundColor: theme.palette.primary.dark,
              padding: theme.spacing(3),
              paddingTop: 10,
              paddingBottom: 10,
              borderRadius: 25,
              outlineColor: "#25252b",
              outlineStyle: "solid",
              outlineWidth: 1,
            }}>
              <CardHeader title="Profile Information" titleTypographyProps={{ fontWeight: 600 }} />
              <CardContent>
                <Grid container spacing={1.5}>
                  <Grid item xs={12}>
                    <Typography color="textSecondary">User</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Input
                      id="username"
                      fullWidth
                      variant="filled"
                      label="Username"
                      value={username}
                      InputProps={{ disableUnderline: true }}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Input
                      id="email"
                      fullWidth
                      variant="filled"
                      label="Email"
                      value={email}
                      InputProps={{ disableUnderline: true }}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Input
                      id="firstName"
                      fullWidth
                      variant="filled"
                      label="First Name"
                      value={firstName}
                      InputProps={{ disableUnderline: true }}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Input
                      id="lastName"
                      fullWidth
                      variant="filled"
                      label="Last Name"
                      value={lastName}
                      InputProps={{ disableUnderline: true }}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={4} />
                  <Grid item xs={12}>
                    <Typography color="textSecondary">Contact</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Input
                      id="phoneMobile"
                      fullWidth
                      variant="filled"
                      label="Mobile Phone"
                      value={phoneMobile}
                      InputProps={{ disableUnderline: true }}
                      onChange={(e) => setPhoneMobile(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Input
                      id="workPhone"
                      fullWidth
                      variant="filled"
                      label="Work Phone"
                      value={phoneWork}
                      InputProps={{ disableUnderline: true }}
                      onChange={(e) => setPhoneWork(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography color="textSecondary">Address</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      id="address1"
                      variant="filled"
                      label="Address (line 1)"
                      fullWidth
                      value={address1}
                      InputProps={{ disableUnderline: true }}
                      onChange={(e) => setAddress1(e.target.value)}
                    />
                  </Grid>
                  {/* <Grid item xs={12}>
                  <TextField
                    className={classes.input}
                    id="address2"
                    variant="filled"
                    label="Address (line 2)"
                    fullWidth
                    value={address2}
                    InputProps={{ disableUnderline: true }}
                    onChange={(e) => setAddress2(e.target.value)}
                  />
                </Grid> */}
                  <Grid item xs={12}>
                    <Input
                      id="city"
                      variant="filled"
                      label="City"
                      fullWidth
                      value={city}
                      InputProps={{ disableUnderline: true }}
                      onChange={(e) => setCity(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Input
                      id="state"
                      variant="filled"
                      fullWidth
                      select
                      label="State"
                      name="state"
                      value={state ?? ""}
                      InputProps={{ disableUnderline: true }}
                      placeholder={data?.user?.userContact?.state}
                    >
                      {States.map((option: any) => (
                        <MenuItem
                          key={option.value}
                          value={option.value}
                          onClick={() => setState(option.value)}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </Input>
                  </Grid>
                  <Grid item xs={8}>
                    <Input
                      id="zipCode"
                      fullWidth
                      variant="filled"
                      label="Zip Code"
                      value={data?.user?.userContact?.zipCode}
                      onChange={(e) => setZipCode(e.target.value)}
                      InputProps={{ disableUnderline: true }}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </FadeIn>
        </Grid>
      </Grid>
      <FadeIn delay={150}>
        <Box
          display="flex"
          style={{
            margin: theme.spacing(3),
          }}
        >
          <Box flexGrow={1} />
          {sent ? (
            <Button
              className={classes.sentButton}
              variant="outlined"
              size="large"
              style={{ borderRadius: 12 }}
              disabled
            >
              Password Reset Email Sent!
            </Button>
          ) : (
            <Button
              className={classes.button}
              onClick={handlePasswordReset}
              variant="contained"
              style={{ borderRadius: 12 }}
              size="large"
            >
              Request Password Reset
            </Button>
          )}
          <Box width="20px" />
          {saving ? (
            <Loading small />
          ) : (
            <Button
              className={classes.button}
              variant="contained"
              size="large"
              style={{ borderRadius: 12 }}
              onClick={handleSave}
            >
              Save
            </Button>
          )}
        </Box>
      </FadeIn>
    </Page>
  );
}
