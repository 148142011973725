import React, { useState, useEffect, useRef } from "react";
import Parse from "parse";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Card,
  Grid,
  Avatar,
  Box,
  Checkbox,
  Button as RealButton,
  Table,
  CardHeader,
  TableBody,
  Container,
  TableCell,
  TableHead,
  ButtonBase,
  Hidden,
  Menu,
  MenuItem,
  TableRow,
  Typography,
  IconButton,
  Tooltip
} from "@mui/material";
import FadeIn from "react-fade-in";
import TuneIcon from '@material-ui/icons/Tune';
import CustomModal from "../../../components/Modal";
import { useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from "notistack";
import { Loading, Page, Button } from "../../../components";
import { Link, useNavigate } from "react-router-dom";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { calcAcademicScore } from "../../../util/util";
import { useAuth } from "../../../hooks/useAuth";
import "../../../styles.css"

const useStyles = makeStyles((theme: any) => ({
  card: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
    boxShadow: theme.shadows[4],
    borderRadius: 15,
    outlineColor: "#2c2c33",
    outlineStyle: "solid",
    outlineWidth: 1,
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1),
    backgroundColor: "#22222b"
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: theme.spacing(3),
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(4),
    borderRadius: 20,
    outlineColor: "#2c2c33",
    outlineStyle: "solid",
    outlineWidth: 1,
  },
  results: {
    height: 500,
  },
  titleFont: {
    fontWeight: 500
  },
  tableCell: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    "& .MuiTableCell-root": {
      borderBottomColor: "#3c3c46"
    },
  },
  popover: {
    backgroundColor: theme.palette.secondary.dark,
    borderRadius: 11,
    outlineColor: "#2c2c33",
    outlineStyle: "solid",
    outlineWidth: 1,
  },
}));

const designationOptions = [
  {
    label: "Set as 'No Selection'",
    value: "No Selection",
  },
  {
    label: "Set as 'Interested In Connecting'",
    value: "Interested In Connecting",
  },
];

const StudentRow = (props) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const auth = useAuth();
  const ref = useRef(null);
  const [intentScore, setIntentScore] = useState(0);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [designation, setDesignation] = useState(designationOptions[0].value);

  const navigate = useNavigate();

  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  const handleOpen = (e) => {
    e.stopPropagation();

    setDropdownOpen(true);
  };

  const handleClose = (e) => {
    e.stopPropagation();

    setDropdownOpen(false);
  };

  async function changeDesignation(designation) {
    setDesignation(designation);

    const res = await Parse.Cloud.run("saveDesignation", {
      collegeId: auth.collegeId,
      studentId: props.obj.get('student').id,
      designation: designation,
      userId: Parse.User.current()?.id
    });

    if (res == "added_new_designation") {
      await Parse.Cloud.run("sendIOSPush", { type: "profileViewOrInterest", id: props.obj.get('student').get('user').id, bodyStr: "A new school is interested in you!" });
    }

    await Parse.Cloud.run("logNewStudentProfileVisit", {
      visitorId: Parse.User.current()?.id,
      visitedId: props.obj.get('student').get('user').id,
    });
  }

  const capitalize_each_word = (str) => {
    if (!str) {
      return ""
    }

    const arr = str.split(" ");

    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }

    const str2 = arr.join(" ");

    return str2
  }

  const getIntentData = async (student) => {
    const res = await Parse.Cloud.run("deriveStudentIntent", {
      studentId: student.id,
      collegeId: auth.collegeId,
      includes: props.includes ?? [],
      studentFirstName: student.get('userContact').get('firstName'),
      studentState: student.get('zipCodeState'),
      studentCity: student.get("userContact").get("city"),
      collegeState: auth.college.state,
      collegeCity: auth.college.city,
      parentColleges: student.get("parentColleges") ? student.get("parentColleges") : [],
      siblingColleges: student.get("siblingColleges") ? student.get("siblingColleges") : [],
      friendColleges: student.get("friendColleges") ? student.get("friendColleges") : [],
      appliedColleges: student.get("appliedColleges") ? student.get("appliedColleges") : [],
      acceptedColleges: student.get("acceptedColleges") ? student.get("acceptedColleges") : [],
      enrolledColleges: student.get("enrolledColleges") ? student.get("enrolledColleges") : [],
    })

    setIntentScore(res.intentScore);
  }

  useEffect(() => {
    getIntentData(props.obj.get('student'));

    setDesignation(props.designation);

  }, [auth.collegeId]);

  return (
    <TableRow
      className={classes.tableCell}
      hover
      key={props.obj.id}
      onClick={() => navigate(`/dashboard/profiles/${props.obj?.get('student').get('userContact').id}`)}
      style={{ textDecoration: "none", zIndex: -2 }}
    >
      <TableCell
        style={{
          position: 'sticky',
          left: 0,
          zIndex: 1,
          borderRight: "2px solid #41414b"
        }}
      >
        <Card
          style={{
            backgroundColor: theme.palette.primary.dark,
            borderRadius: 0,
            position: 'absolute',
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            boxShadow: 'none',
            zIndex: -3
          }}
        >
        </Card>
        <Box display="flex" alignItems="center">
          <Checkbox
            sx={{
              ml: theme.spacing(-1),
              mr: theme.spacing(1),
              color: theme.palette.text.secondary,
              '&.Mui-checked': {
                color: theme.palette.secondary.main,
              },
              transform: "scale(0.85)",
            }}
            disableRipple
            checked={props.selectedStudents.map((x: any) => x.id).includes(props.obj.id)}
            onChange={e => {
              if (e.target.checked) {
                var newArr = [...props.selectedStudents];
                newArr.push(props.obj);
                props.setSelectedStudents(newArr);

              } else {
                var newArr1 = props.selectedStudents.filter(function (e: any) { return e.id !== props.obj.id });

                props.setSelectedStudents(newArr1);
              }

              props.setSelectAllIsChecked(false);
            }}
            onClick={e => {
              e.stopPropagation();
            }}
          />
          <Avatar
            className={classes.avatar}
            src={props.obj?.get('student').get('userContact').get('img')?._url}
          />
          <Box width="10px" />
          <div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: 'transparent',
              }}
            >
              <Typography
                style={{ fontWeight: 600, fontSize: 16 }}
                color="textPrimary"
              >
                {props.obj.get('student').get('settings').get('showLastNameToColleges') == true ? capitalize_each_word(props.obj.get('student').get('userContact').get('firstName') +
                  " " +
                  props.obj.get('student').get('userContact').get('lastName')) : capitalize_each_word(props.obj.get('student').get('userContact').get('firstName') + " " + props.obj.get('student').get('userContact').get('lastName').charAt(0))}
              </Typography>
              {props?.obj?.get('student')?.get('verificationStatus') == "verified" ? (
              <img
                height={17}
                width={17}
                src={"/assets/icons/ic_verify.png"}
                style={{
                  visibility: props?.obj?.get('student')?.get('verificationStatus') == "verified" ? "visible" : "hidden",
                  marginLeft: theme.spacing(0.5),
                }}
              />
              ) : (<></>)}
              {props?.obj?.get('student')?.get('appliedColleges') || props?.obj?.get('student')?.get('acceptedColleges') || props?.obj?.get('student')?.get('enrolledColleges') ? (
                <Tooltip
                  title={props?.obj?.get('student')?.get('appliedColleges').map((x) => x).includes(auth.collegeId) ? "Applied" : props?.obj?.get('student')?.get('acceptedColleges').map((x) => x).includes(auth.collegeId) ? "Accepted" : props?.obj?.get('student')?.get('enrolledColleges').map((x) => x).includes(auth.collegeId) ? "Enrolled" : "Not Applied"}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: "#46464f",
                        color: "white"
                      }
                    }
                  }}
                >
                  <img
                    height={14}
                    width={14}
                    src={props?.obj?.get('student')?.get('appliedColleges').map((x) => x).includes(auth.collegeId) ? "/assets/icons/ic_applied.svg" : props?.obj?.get('student')?.get('acceptedColleges').map((x) => x).includes(auth.collegeId) ? "/assets/icons/ic_accepted.svg" : props?.obj?.get('student')?.get('enrolledColleges').map((x) => x).includes(auth.collegeId) ? "/assets/icons/ic_enrolled.svg" : "/assets/icons/ic_applied.svg"}
                    style={{
                      visibility: props?.obj?.get('student')?.get('appliedColleges').map((x) => x).includes(auth.collegeId) || props?.obj?.get('student')?.get('acceptedColleges').map((x) => x).includes(auth.collegeId) || props?.obj?.get('student')?.get('enrolledColleges').map((x) => x).includes(auth.collegeId) ? "visible" : "hidden",
                      marginLeft: theme.spacing(0.5),
                    }}
                  />
                </Tooltip>
              ) : (<></>)}
            </div>
            <Typography style={{ fontWeight: 400, fontSize: 14 }} color="textSecondary" >
              {props.obj.get('student').get('user').get('username').toLowerCase()}
            </Typography>
          </div>
        </Box>
      </TableCell>
      <TableCell
        align="center"
        style={{ backgroundColor: "#1f1f25" }}
      >
        <Typography
          fontWeight={500}
          color={"textSecondary"}
          fontSize={14}
        >
          {props.obj.get('student').get('gradYear')}
        </Typography>
      </TableCell>
      <TableCell
        align="center"
        style={{ backgroundColor: "#1f1f25" }}
      >
        <Typography
          fontWeight={500}
          color={"textSecondary"}
          fontSize={14}
        >
          {props.obj.get('student').get('zipCodeState') ? props.obj.get('student').get('zipCodeState') : ''}
        </Typography>
      </TableCell>
      <TableCell
        align="center"
        style={{ backgroundColor: "#1f1f25" }}
      >
        <Typography
          fontWeight={600}
          fontSize={14}
        >
          {intentScore > 0 ? intentScore : ""}
        </Typography>
      </TableCell>
      <TableCell
        align="center"
        style={{ backgroundColor: "#1f1f25" }}
      >
        <Typography
          textAlign={"center"}
          ml={theme.spacing(0)}
          sx={{
            fontSize: 14,
            fontWeight: 800,
            backgroundcolor: "primary",
            backgroundImage: `linear-gradient(to right, #35c669, #2ec4a9)`,
            backgroundSize: "20%",
            backgroundRepeat: "repeat",
            backgroundClip: "text",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",

          }}>
          {calcAcademicScore(props.obj.get('student').get('sat'), props.obj.get('student').get('act'), props.obj.get('student').get('psat'), props.obj.get('student').get('adjustedUWGPA'), props.obj.get('student').get('adjustedClassRank')) ?? 0}
        </Typography>
      </TableCell>
      <TableCell
        align="center"
        style={{ backgroundColor: "#1f1f25" }}
      >
        <Box
          display="flex"
          alignItems="center"
          component={ButtonBase}
          onClick={e => handleOpen(e)}
          ref={ref}
          style={{
            borderRadius: 25,
            backgroundColor: "#0d0d14",
            // padding: 1,
            minWidth: 70,
            paddingRight: theme.spacing(2),
            paddingLeft: theme.spacing(2),
            marginLeft: 1,
            paddingTop: 5,
            paddingBottom: 5,
            outlineWidth: 1,
            outlineStyle: "solid",
            outlineColor: "#3b3b42"
          }}
        >
          {/* <Avatar alt="User" className={classes.avatar} src={src} sx={{ width: 32, height: 32, backgroundColor: theme.palette.primary.light }} /> */}
          <Hidden smDown>
            <Typography style={{ fontSize: 11, fontWeight: 600 }} color="inherit">
              {designation}
            </Typography>
          </Hidden>
        </Box>
        <Menu
          onClose={e => handleClose(e)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          keepMounted
          slotProps={{
            paper: {
              sx: {
                minWidth: 270,
                marginLeft: theme.spacing(0),
                marginTop: theme.spacing(1),
                backgroundColor: theme.palette.primary.dark,
                borderRadius: 1.5,
                outlineColor: "#3c3c44",
                outlineStyle: "solid",
                outlineWidth: 1,
              }
            }
          }}
          anchorEl={ref.current}
          open={isDropdownOpen}
        >
          {designationOptions.map((option) => (
            <MenuItem
              key={option.value}
              onClick={(e) => {
                e.stopPropagation();

                setDropdownOpen(false);
                changeDesignation(option.value);
              }}
            >
              {option.label}
            </MenuItem>
          ))}
        </Menu>
      </TableCell>
      <TableCell
        align="right"
        style={{ backgroundColor: "#1f1f25" }}
      >
        <Typography
          fontWeight={500}
          fontSize={14}
        >
          {months[(new Date(props.obj.createdAt)).getMonth()] + " " + (new Date(props.obj.createdAt)).getDate() + ", " + (new Date(props.obj.createdAt)).getFullYear()}
        </Typography>
      </TableCell>
      <TableCell
        align="right"
        style={{ backgroundColor: "#1f1f25" }}
      >
        <ChevronRightIcon />
      </TableCell>
    </TableRow>
  )
}

export default function FavoritedStudents(props) {
  const theme = useTheme();
  const classes = useStyles(theme);

  const popoverRef = useRef<any>();
  const [anchor, setAnchor] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [students, setStudents] = useState([]);
  const [studentIntentIncludes, setStudentIntentIncludes] = useState<any>([]);
  const [designatedStudents, setDesignatedStudents] = useState([]);
  const [skip, setSkip] = useState(0);
  const [total, setTotal] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedStudents, setSelectedStudents] = useState<Array<any>>([]);
  const [selectAllIsChecked, setSelectAllIsChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const auth = useAuth();

  const getFavoritedStudentsTotal = async () => {
    await Parse.Cloud.run("getFavoritedStudentsTotal", {
      userUniversityId: Parse.User.current()!.get("userUniversity").id,
    }).then(
      (res) => {
        setTotal(res);
      }
    );
  };

  const getFavoritedStudents = async (willReload: boolean) => {
    await Parse.Cloud.run("getFavoritedStudents", {
      userUniversityId: Parse.User.current()!.get("userUniversity").id,
      limit: 40,
      skip: willReload ? 0 : skip
    }).then(
      (res) => {
        setLoading(false);

        if (willReload) {
          setStudents(res);
          setSkip(50);

        } else {
          setStudents(students.concat(res));
          setSkip(skip + 50);
        }

      },
      (err) => {
        setLoading(false);
      }
    );
  };

  async function getIntentScoreFactors() {
    const includes = await Parse.Cloud.run("getIntentScoreFactors", {
      collegeId: auth.collegeId,
    });

    setStudentIntentIncludes(includes);
  }

  const getDesignatedStudents = async () => {
    await Parse.Cloud.run("getDesignatedStudents", {
      collegeId: auth.collegeId,
    }).then(
      (res) => {
        setDesignatedStudents(res);
      },
      (err) => {
      }
    );
  };

  const selectAll = (checked: boolean) => {
    if (checked) {
      setSelectedStudents(students);
      setSelectAllIsChecked(true);

    } else {
      setSelectedStudents([]);
      setSelectAllIsChecked(false);
    }
  }

  async function removeFavorites(itemsToRemove: Array<any>, isRemovingAll: boolean) {
    setLoading(true);

    await Parse.Cloud.run("removeFavorites", {
      userUniversityId: Parse.User.current()!.get("userUniversity").id,
      selectedStudentIds: itemsToRemove.map((x: any) => x.id),
      isRemovingAll: isRemovingAll
    });

    setSelectedStudents([]);
    setSelectAllIsChecked(false);

    getFavoritedStudents(true);
    getFavoritedStudentsTotal();

    enqueueSnackbar("Successfully removed from favorites", {
      variant: "success",
    });
  }

  useEffect(() => {
    setLoading(true);

    getDesignatedStudents();

    if (auth.collegeId) {
      getIntentScoreFactors();
    }

  }, [auth.collegeId]);

  useEffect(() => {
    if (designatedStudents && auth.collegeId) {
      getFavoritedStudents(true);
      getFavoritedStudentsTotal();
    }

  }, [designatedStudents]);

  return (
    <Page title="Favorited Students" maxWidth="xl">
      <FadeIn>
        <Card style={{
          backgroundColor: theme.palette.primary.dark,
          padding: theme.spacing(2),
          boxShadow: theme.shadows[4],
          borderRadius: 25,
          outlineColor: "#2c2c33",
          outlineStyle: "solid",
          outlineWidth: 1,
          height: "calc(100vh - 165px)"
        }}>
          <FadeIn>
            <CardHeader
              title={
                <Box display='flex' alignItems='center'>
                  <Typography style={{ fontWeight: 600, fontSize: 29 }}>Favorited Students</Typography>
                  <Typography ml={theme.spacing(2)} variant="body2" color="textSecondary">{"(" + total?.toLocaleString() + " students)"}</Typography>
                </Box>
              }
              action={
                <>
                  <IconButton
                    ref={popoverRef}
                    onClick={(e: any) => {
                      setOpenMenu(true);
                      setAnchor(e.currentTarget);
                    }}
                  >
                    <TuneIcon />
                  </IconButton>
                  <Menu
                    id="post-menu"
                    anchorEl={anchor}
                    open={openMenu}
                    classes={{ paper: classes.popover }}
                    slotProps={{
                      paper: {
                        sx: {
                          backgroundColor: theme.palette.primary.dark,
                          borderRadius: 1.5,
                          outlineColor: "#3c3c44",
                          outlineStyle: "solid",
                          outlineWidth: 1,
                        }
                      }
                    }}
                    onClose={() => setOpenMenu(false)}
                  >
                    <MenuItem
                      key={"deleteSelected"}
                      disabled={selectedStudents.length > 0 ? false : true}
                      onClick={() => {
                        setOpenMenu(false);
                        removeFavorites(selectedStudents, false);
                      }}
                    >
                      {"Remove Selected (" + selectedStudents.length + ")"}
                    </MenuItem>
                    <MenuItem
                      key={"deleteAll"}
                      onClick={() => {
                        setOpenMenu(false);
                        setOpenConfirm(true);
                      }}
                    >
                      Remove All
                    </MenuItem>
                  </Menu>
                  <CustomModal
                    open={openConfirm}
                    setOpen={setOpenConfirm}
                    sub={<Typography color='textSecondary'>{"Are you sure you want to remove all of your favorites?"}</Typography>}
                    noScroll
                  >
                    <Box display="flex">
                      <Grid container spacing={2}>
                        <Grid item xs={1} />
                        <Grid item xs={5}>
                          <RealButton
                            style={{
                              height: 50,
                              backgroundColor: theme.palette.error.main,
                              color: theme.palette.text.primary,
                              borderRadius: 16
                            }}
                            fullWidth
                            variant="contained"
                            onClick={() => setOpenConfirm(false)}
                          >
                            Cancel
                          </RealButton>
                        </Grid>
                        <Grid item xs={5}>
                          <Button
                            style={{ height: 50, borderRadius: 16 }}
                            variant="contained"
                            fullWidth
                            onClick={() => {
                              removeFavorites([], true);
                              setOpenConfirm(false);
                            }}
                          >
                            Yes
                          </Button>
                        </Grid>
                        <Grid item xs={1} />
                      </Grid>
                    </Box>
                  </CustomModal>
                </>
              }
            />
          </FadeIn>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Container style={{ height: "calc(100vh - 225px)" }} maxWidth="xl">
                {loading ? (
                  <Box height={465}>
                    <Loading sx={{ mt: theme.spacing(15) }} />
                  </Box>
                ) : (
                  <>
                    {!students?.length ? (
                      <Box>
                        <Box height="40px" />
                        <Box display='flex' justifyContent='center' mt={theme.spacing(15)}>
                          <Typography>No favorited students.</Typography>
                        </Box>
                        <Box height="20px" />
                      </Box>
                    ) : (
                      <PerfectScrollbar
                        style={{ maxWidth: "xl" }}
                        className="scrollbar"
                        onYReachEnd={() => {
                          if (students?.length > 0 && students?.length < total) {
                            setTimeout(() => {
                              getFavoritedStudents(false);
                            }, 50);
                          }
                        }}
                      >
                        <div style={{ minWidth: 1200 }}>
                          <Table style={{ borderCollapse: 'separate' }}>
                            <TableHead>
                              <TableRow
                                style={{
                                  position: 'sticky',
                                  top: 0,
                                  zIndex: 3,
                                }}
                              >
                                <TableCell
                                  align="left"
                                  style={{
                                    position: 'sticky',
                                    left: 0,
                                  }}
                                  sx={{
                                    "&.MuiTableCell-root": {
                                      borderBottom: 'none'
                                    }
                                  }}
                                >
                                  <Card
                                    style={{
                                      backgroundColor: theme.palette.primary.dark,
                                      borderRadius: 0,
                                      position: 'absolute',
                                      width: "100%",
                                      height: "100%",
                                      top: 0,
                                      left: 0,
                                      boxShadow: "none",
                                      zIndex: -3,
                                      borderBottom: "2px solid #41414b"
                                    }}
                                  >
                                  </Card>
                                  <Checkbox
                                    sx={{
                                      ml: theme.spacing(-1),
                                      mr: theme.spacing(1),
                                      color: theme.palette.text.secondary,
                                      '&.Mui-checked': {
                                        color: theme.palette.secondary.main,
                                      },
                                      transform: "scale(0.85)",
                                    }}
                                    disableRipple
                                    checked={selectAllIsChecked}
                                    onChange={e => selectAll(e.target.checked)}
                                    onClick={e => {
                                      e.stopPropagation();
                                    }}
                                  />
                                  Student
                                </TableCell>
                                <TableCell
                                  align="left"
                                  sx={{
                                    "&.MuiTableCell-root": {
                                      borderBottom: 'none'
                                    }
                                  }}
                                >
                                  <Card
                                    style={{
                                      backgroundColor: theme.palette.primary.dark,
                                      borderRadius: 0,
                                      position: 'absolute',
                                      width: "100%",
                                      height: "100%",
                                      top: 0,
                                      right: 0,
                                      boxShadow: "none",
                                      borderBottom: "2px solid #41414b",
                                      zIndex: -3
                                    }}
                                  />
                                  Grad Year
                                </TableCell>
                                <TableCell
                                  align="left"
                                  sx={{
                                    zIndex: 10,
                                    "&.MuiTableCell-root": {
                                      borderBottom: 'none'
                                    }
                                  }}
                                >
                                  State
                                </TableCell>
                                <TableCell
                                  align="left"
                                  sx={{
                                    "&.MuiTableCell-root": {
                                      borderBottom: 'none'
                                    }
                                  }}
                                >
                                  Intent Score
                                </TableCell>
                                <TableCell
                                  align="left"
                                  sx={{
                                    "&.MuiTableCell-root": {
                                      borderBottom: 'none'
                                    }
                                  }}
                                >
                                  Academic Score
                                </TableCell>
                                <TableCell
                                  align="left"
                                  sx={{
                                    "&.MuiTableCell-root": {
                                      borderBottom: 'none'
                                    }
                                  }}
                                >
                                  Interest Level
                                </TableCell>
                                <TableCell
                                  align="right"
                                  sx={{
                                    "&.MuiTableCell-root": {
                                      borderBottom: 'none'
                                    }
                                  }}
                                >
                                  Date Favorited
                                </TableCell>
                                <TableCell
                                  align="right"
                                  sx={{
                                    "&.MuiTableCell-root": {
                                      borderBottom: 'none'
                                    }
                                  }}
                                >
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {students
                                ?.map((obj: any) => (
                                  <StudentRow
                                    obj={obj}
                                    setSelectedStudents={setSelectedStudents}
                                    setSelectAllIsChecked={setSelectAllIsChecked}
                                    selectedStudents={selectedStudents}
                                    designation={designatedStudents.filter((x: any) => x.get('student').id == obj.get('student').id).length > 0 ? (designatedStudents.filter((x: any) => x.get('student').id == obj.get('student').id)[0] as any).get("designation") : "No Selection"}
                                    includes={studentIntentIncludes}
                                  />
                                ))}
                            </TableBody>
                          </Table>
                        </div>
                      </PerfectScrollbar>
                    )}
                  </>
                )}
              </Container>
            </Grid>
          </Grid>
        </Card>
      </FadeIn>
    </Page>
  )
}