import React, { useState, useRef, useEffect } from "react";
import Parse from "parse";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  useTheme,
  Card,
  Grid,
  Box,
  IconButton,
  SvgIcon,
  Table,
  TableBody,
  Modal,
  Fade,
  Input as RealInput,
  Tooltip,
  TableCell,
  Container,
  TableHead,
  TableRow,
  Typography,
  Checkbox,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CardHeader,
  Menu,
  List,
  Avatar,
  ButtonBase,
  FormControlLabel,
  MenuItem,
  Button as RealButton,
} from "@mui/material";
import FadeIn from "react-fade-in";
import { makeStyles } from '@mui/styles';
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {
  Page,
  Filters,
  Input,
  Button,
  Modal as CustomModal,
} from "../../../components";
import { Loading } from "../../../components/Loading";
import { Link as RounterLink } from "react-router-dom";
import NewCampaign from "./NewCampaign";
import FilterListIcon from "@material-ui/icons/FilterList";
import { gql, useLazyQuery, useQuery, useMutation } from "@apollo/client";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CloseIcon from "@material-ui/icons/Close";
import { useAuth } from "../../../hooks/useAuth";
import { parseDate } from "../../../util";
import { Search as SearchIcon } from "react-feather";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme: any) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  card: {
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    boxShadow: theme.shadows[4],
    borderRadius: 20,
    outlineColor: "#25252b",
    outlineStyle: "solid",
    outlineWidth: 1,
  },
  avatar: {
    height: 42,
    width: 42,
    top: 2,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    backgroundColor: "#1d1d2b",
  },
  button: {
    background: "linear-gradient(75deg, #35c669, #2ec4a9)",
    border: 0,
    color: "#fff",
    marginLeft: "auto",
    fontSize: 12
  },
  filterButton: {
    marginRight: theme.spacing(2)
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: theme.spacing(3),
  },
  paper: {
    maxHeight: 690,
    overflowY: "auto",
    overflowX: "hidden",
    backgroundColor: "#0e0e14",
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(4),
    borderRadius: 25,
    outlineColor: "#25252b",
    outlineStyle: "solid",
    outlineWidth: 1,
  },
  modalPaper: {
    overflowX: "hidden",
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(4),
    borderRadius: 15,
    outlineColor: "#25252b",
    outlineStyle: "solid",
    outlineWidth: 1,
  },
  deactivate: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.text.primary,
  },
  popover: {
    width: 160,
    backgroundColor: theme.palette.secondary.dark,
    outlineColor: "#2a2a30",
    outlineStyle: "solid",
    outlineWidth: 1,
  },
  results: {
    height: 620,
  },
  campaignRow: {
    cursor: "pointer",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "& .MuiTableCell-root": {
      borderBottomColor: "#3c3c46"
    },
  },
  accordion: {
    background: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    '&:before': {
      display: 'none',
    }
  },
  person: {
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(1),
    height: 62,
    display: "flex",
    "&:hover": {
      backgroundColor: theme.palette.primary.lightHover,
      cursor: "pointer"
    },
  },
  cardAction: {
    width: "auto",
    height: "auto",
    display: "block",
  },
  accordianDetails: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  errorButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.text.primary,
  },
  scheduleDate: {
    marginTop: theme.spacing(-1),
    marginRight: theme.spacing(2),
    width: "220px",
  },
  resultsRecipients: {
    overflowY: "auto",
    height: 300,
  },
  search: {
    display: "flex",
    alignItems: "center",
    height: 44,
    borderRadius: 10,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    backgroundColor: theme.palette.primary.light,
    outlineColor: "#33333a",
    outlineStyle: "solid",
    outlineWidth: 1,
  },
  searchInput: {
    flexGrow: 1,
    marginLeft: theme.spacing(1),
  },
  selectStatus: {
    "& .MuiInputBase-root": {
      backgroundColor: theme.palette.primary.light,
      borderBottomRightRadius: theme.shape.borderRadius,
      borderBottomLeftRadius: theme.shape.borderRadius,
    },
  },
  tableCell: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    "& .MuiTableCell-root": {
      borderBottomColor: "#3c3c46"
    },
  },
}));

const GET_TOTAL_NUM_STUDENTS = gql`
  query {
    userStudents {
      count
    }
  }
`;

const GET_ONBOARDED_COLLEGE = gql`
  query getOnboardedColleges($id: ID!) {
    onboardedColleges(
      where: {
        college: { have: { objectId: { equalTo: $id } } }
      }
    ) {
      edges {
        node {
          id
          objectId
          costPerCampaignMessage
        }
      }
    }
  }
`;

const FETCH_CAMPAIGNS = gql`
  query getCampaigns($id: ID!, $filter: CampaignWhereInput!) {
    campaigns(
      where: {
        AND: [{ college: { have: { objectId: { equalTo: $id } } } }, $filter]
      }
      order: createdAt_DESC
    ) {
      count
      edges {
        node {
          id
          objectId
          author {
            id
            firstName
            lastName
          }
          message
          active
          launchDate
          updatedAt
          campaignName
          status
          finalCost
          hasCompleted
          filterQuery {
            id
            audienceSize
          }
          opens {
            count
          }
          responses {
            count
          }
        }
      }
    }
  }
`;

const FETCH_CAMPAIGN = gql`
  query getCampaign($id: ID!, $after: String) {
    campaign(id: $id) {
      id
      objectId
      createdAt
      author {
        id
        firstName
        lastName
      }
      message
      active
      allowResponses
      launchDate
      campaignName
      campaignDescription
      hasCompleted
      status
      finalCost
      recipients(first: 50, after: $after) {
        count
        edges {
          cursor
          node {
            id
            objectId
            fullName
            userStudent {
              id
              objectId
              verificationStatus
              appliedColleges {
                ... on Element {
                  value
                }
              }
              acceptedColleges {
                ... on Element {
                  value
                }
              }
              enrolledColleges {
                ... on Element {
                  value
                }
              }
            }
            img {
              url
            }
            user {
              username
            }
          }
        }
      }
      filterQuery {
        id
        ethnicities {
          ... on Element {
            value
          }
        }
        locations {
          ... on Element {
            value
          }
        }
        gradyears {
          ... on Element {
            value
          }
        }
        genders {
          ... on Element {
            value
          }
        }
        majors {
          ... on Element {
            value
          }
        }
        interests {
          ... on Element {
            value
          }
        }
        SATto
        SATfrom
        ACTto
        ACTfrom
        GPAto
        GPAfrom
        RANKfrom
        RANKto
        zipCode
        zipRange
        audienceSize
        parentsEd
        type
        fromSource
        interestedStudentsOnly
      }
    }
  }
`;

const FETCH_CAMPAIGN_ANALYTICS = gql`
  query getCampaign($id: ID!) {
    campaign(id: $id) {
      id
      objectId
      opens(first: 5000) {
        edges {
          cursor
          node {
            id
            objectId
            fullName
            userStudent {
              id
              objectId
              verificationStatus
              appliedColleges {
                ... on Element {
                  value
                }
              }
              acceptedColleges {
                ... on Element {
                  value
                }
              }
              enrolledColleges {
                ... on Element {
                  value
                }
              }
            }
            img {
              url
            }
            user {
              username
            }
          }
        }
      }
      responses(first: 5000) {
        edges {
          cursor
          node {
            id
            objectId
            fullName
            userStudent {
              id
              objectId
              verificationStatus
              appliedColleges {
                ... on Element {
                  value
                }
              }
              acceptedColleges {
                ... on Element {
                  value
                }
              }
              enrolledColleges {
                ... on Element {
                  value
                }
              }
            }
            img {
              url
            }
            user {
              username
            }
          }
        }
      }
    }
  }
`;

const SEARCH_CAMPAIGN_RECIPIENTS = gql`
  query getCampaign($id: ID!, $query: String!) {
    campaign(id: $id) {
      id
      objectId
      recipients(first: 20, where: { fullName: { matchesRegex: $query, options: "i" } }) {
        count
        edges {
          cursor
          node {
            id
            objectId
            fullName
            userStudent {
              id
              objectId
              verificationStatus
              appliedColleges {
                ... on Element {
                  value
                }
              }
              acceptedColleges {
                ... on Element {
                  value
                }
              }
              enrolledColleges {
                ... on Element {
                  value
                }
              }
            }
            img {
              url
            }
            user {
              username
            }
          }
        }
      }
    }
  }
`;

const UPDATE_CAMPAIGN = gql`
  mutation saveCampaign(
    $id: ID!
    $collegeID: ID!
    $myID: ID
    $name: String
    $message: String
    $launchDate: Date
    $desc: String
    $status: String
    $allowResponses: Boolean
    $ethnicities: [Any!]
    $locations: [Any!]
    $genders: [Any!]
    $gradyears: [Any!]
    $majors: [Any!]
    $interests: [Any!]
    $SATfrom: Float
    $SATto: Float
    $ACTfrom: Float
    $ACTto: Float
    $GPAfrom: Float
    $GPAto: Float
    $RANKfrom: Float
    $RANKto: Float
    $zip: String
    $zipRange: Float
    $audienceSize: Float
    $parentsEd: String
    $type: String
    $fromSource: String
    $interestedStudentsOnly: Boolean
  ) {
    updateCampaign(
      input: {
        id: $id
        fields: {
          updatedBy: { link: $myID }
          campaignName: $name
          campaignDescription: $desc
          message: $message
          status: $status
          launchDate: $launchDate
          allowResponses: $allowResponses
          audienceSize: $audienceSize
          filterQuery: {
            createAndLink: {
              ethnicities: $ethnicities
              locations: $locations
              genders: $genders
              gradyears: $gradyears
              majors: $majors
              interests: $interests
              SATfrom: $SATfrom
              SATto: $SATto
              ACTfrom: $ACTfrom
              ACTto: $ACTto
              GPAfrom: $GPAfrom
              GPAto: $GPAto
              RANKfrom: $RANKfrom
              RANKto: $RANKto
              zipCode: $zip
              zipRange: $zipRange
              college: { link: $collegeID }
              audienceSize: $audienceSize
              parentsEd: $parentsEd
              type: $type
              fromSource: $fromSource
              interestedStudentsOnly: $interestedStudentsOnly
            }
          }
        }
      }
    ) {
      campaign {
        id
        objectId
        createdAt
        author {
          id
          firstName
          lastName
        }
        message
        active
        allowResponses
        launchDate
        campaignName
        status
        campaignDescription
        hasCompleted
        filterQuery {
          id
          ethnicities {
            ... on Element {
              value
            }
          }
          locations {
            ... on Element {
              value
            }
          }
          gradyears {
            ... on Element {
              value
            }
          }
          genders {
            ... on Element {
              value
            }
          }
          majors {
            ... on Element {
              value
            }
          }
          interests {
            ... on Element {
              value
            }
          }
          SATto
          SATfrom
          ACTto
          ACTfrom
          GPAto
          GPAfrom
          RANKfrom
          RANKto
          zipCode
          zipRange
          audienceSize
          parentsEd
          type
          fromSource
          interestedStudentsOnly
        }
      }
    }
  }
`;

const DELETE_CAMPAIGN = gql`
  mutation deleteCampaign($id: ID!) {
    deleteCampaign(input: { id: $id }) {
      campaign {
        id
        objectId
      }
    }
  }
`;

const Person = ({ person, hasOpened, hasResponded }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const auth = useAuth();

  const capitalize_each_word = (str) => {
    if (!str) {
      return ""
    }

    const arr = str.split(" ");

    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }

    const str2 = arr.join(" ");

    return str2
  }

  return (
    <Grid item xs={12}>
      <RounterLink
        className={classes.cardAction}
        to={`/dashboard/messages/create/${person.objectId}`}
        style={{ textDecoration: "none" }}
      >
        <Card
          sx={{
            "&:hover": {
              backgroundColor: theme.palette.primary.light,
              cursor: "pointer"
            },
          }}
          style={{
            backgroundColor: "#202028",
            padding: theme.spacing(1),
            height: 45,
            display: "flex",
          }}
        >
          <Avatar
            alt={person.fullName}
            src={person.img?.url}
            className={classes.avatar}
          />
          <Box sx={{ alignSelf: "left" }} ml={1}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: 'transparent',
              }}
            >
              <Typography variant="body1" fontWeight={600}>
                {capitalize_each_word(person.fullName)}
              </Typography>
              {person.userStudent?.verificationStatus == "verified" ? (
                <img
                  height={17}
                  width={17}
                  src={"/assets/icons/ic_verify.png"}
                  style={{
                    visibility: person.userStudent?.verificationStatus == "verified" ? "visible" : "hidden",
                    marginLeft: theme.spacing(0.5),
                  }}
                />
              ) : (<></>)}
              {person.userStudent?.appliedColleges || person.userStudent?.acceptedColleges || person.userStudent?.enrolledColleges ? (
                <Tooltip
                  title={person.userStudent?.appliedColleges.map((x) => x.value).includes(auth.collegeId) ? "Applied" : person.userStudent?.acceptedColleges.map((x) => x.value).includes(auth.collegeId) ? "Accepted" : person.userStudent?.enrolledColleges.map((x) => x.value).includes(auth.collegeId) ? "Enrolled" : "Not Applied"}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: "#46464f",
                        color: "white"
                      }
                    }
                  }}
                >
                  <img
                    height={14}
                    width={14}
                    src={person.userStudent?.appliedColleges.map((x) => x.value).includes(auth.collegeId) ? "/assets/icons/ic_applied.svg" : person.userStudent?.acceptedColleges.map((x) => x.value).includes(auth.collegeId) ? "/assets/icons/ic_accepted.svg" : person.userStudent?.enrolledColleges.map((x) => x.value).includes(auth.collegeId) ? "/assets/icons/ic_enrolled.svg" : "/assets/icons/ic_applied.svg"}
                    style={{
                      visibility: person.userStudent?.appliedColleges.map((x) => x.value).includes(auth.collegeId) || person.userStudent?.acceptedColleges.map((x) => x.value).includes(auth.collegeId) || person.userStudent?.enrolledColleges.map((x) => x.value).includes(auth.collegeId) ? "visible" : "hidden",
                      marginLeft: theme.spacing(0.5),
                    }}
                  />
                </Tooltip>
              ) : (<></>)}
            </div>
            <Typography variant="body2" color="textSecondary">
              {person.user?.username}
            </Typography>
          </Box>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: 70,
              justifyContent: "center",
              marginLeft: 'auto'
            }}
          >
            <Tooltip
              title={hasOpened ? "Has opened" : "Has not opened"}
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: "#46464f",
                    color: "white"
                  }
                }
              }}
            >
              <Box
                display='flex'
                justifyContent='center'
              >
                <Box
                  display='flex'
                  justifyContent='center'
                  alignItems='center'
                  marginLeft={0.5}
                  sx={{
                    backgroundColor: hasOpened ? "#35c669" : "#46464d",
                    borderRadius: 20,
                    width: 24,
                    height: 24
                  }}
                >
                  <img
                    alt='check'
                    src={hasOpened ? '/assets/icons/ic_envelope-open_white.svg' : '/assets/icons/ic_envelope-closed_gray.svg'}
                    style={{
                      width: 12,
                      height: 12,
                      marginTop: hasOpened ? -2 : 0
                    }}
                  />
                </Box>
              </Box>
            </Tooltip>
            <Tooltip
              title={hasResponded ? "Has responded" : "Has not responded"}
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: "#46464f",
                    color: "white"
                  }
                }
              }}
            >
              <Box
                display='flex'
                justifyContent='center'
              >
                <Box
                  display='flex'
                  justifyContent='center'
                  alignItems='center'
                  marginLeft={1}
                  marginRight={2}
                  sx={{
                    background: hasResponded ? "linear-gradient(75deg, #3396e8, #32c7e5)" : null,
                    backgroundColor: "#46464d",
                    borderRadius: 20,
                    width: 24,
                    height: 24
                  }}
                >
                  <img
                    alt='check'
                    src={hasResponded ? '/assets/icons/ic_comments_white.svg' : '/assets/icons/ic_comments_gray.svg'}
                    style={{
                      width: hasResponded ? 12 : 12,
                      height: hasResponded ? 12 : 12,
                    }}
                  />
                </Box>
              </Box>
            </Tooltip>
          </div>
        </Card>
        <Box height="10px" />
      </RounterLink>
    </Grid>
  );
};

const CostMetrics = (props) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Modal
      className={classes.modal}
      open={props.open}
      onClose={() => props.setOpen(false)}
      closeAfterTransition
    >
      <Fade in={props.open}>
        <Card style={{
          height: 160,
          overflowX: "hidden",
          backgroundColor: theme.palette.primary.dark,
          display: "flex",
          flexDirection: "column",
          padding: theme.spacing(4),
          borderRadius: 25,
          outlineColor: "#25252b",
          outlineStyle: "solid",
          outlineWidth: 1,
        }}>
          <Box width="500px">
            <Grid container sx={{ alignSelf: "left" }}>
              <Typography style={{ fontWeight: 600, fontSize: 29 }} align="left" color="textPrimary">
                Cost Metrics
              </Typography>
              <Box flexGrow={1} />
              <IconButton style={{ width: 40, height: 40 }} onClick={() => props.setOpen(false)}>
                <CloseIcon />
              </IconButton>
            </Grid>
            <Box width="20px" />
            <Grid container width={"80%"} marginTop={theme.spacing(0.4)} spacing={2}>
              <Grid item xs={6.7}>
                <Tooltip
                  title={"(Total campaign cost)"}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: "#46464f",
                        color: "white"
                      }
                    }
                  }}
                >
                  <Card style={{
                    backgroundColor: "#040405",
                    height: 12,
                    borderRadius: 30,
                    padding: theme.spacing(1),
                    outlineWidth: 1,
                    outlineStyle: "solid",
                    outlineColor: "#3b3b42"
                  }}>
                    <ButtonBase
                      disableRipple
                      disabled
                      style={{
                        display: 'block',
                        textAlign: 'center',
                        height: 26,
                        top: -7,
                        width: '100%',
                        fontSize: 12,
                        fontWeight: 600,
                        color: "textPrimary",
                      }}
                    >
                      {props.totalCost ? "$" + props.totalCost?.toFixed(2) + " Total cost" : "$0 Total cost"}
                    </ButtonBase>
                  </Card>
                </Tooltip>
              </Grid>
              <Grid item xs={5.3}>
                <Tooltip
                  title={"(Cost per open)"}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: "#46464f",
                        color: "white"
                      }
                    }
                  }}
                >
                  <Card style={{
                    backgroundColor: "#040405",
                    height: 12,
                    borderRadius: 30,
                    padding: theme.spacing(1),
                    outlineWidth: 1,
                    outlineStyle: "solid",
                    outlineColor: "#3b3b42"
                  }}>
                    <ButtonBase
                      disableRipple
                      disabled
                      style={{
                        display: 'block',
                        textAlign: 'center',
                        height: 26,
                        top: -7,
                        width: '100%',
                        fontSize: 12,
                        fontWeight: 600,
                        color: "textPrimary",
                      }}
                    >
                      {props.totalOpens > 0 ? "$" + (props.totalCost / props.totalOpens)?.toFixed(2) + " CPO" : "N/A CPO"}
                    </ButtonBase>
                  </Card>
                </Tooltip>
              </Grid>
              <Grid item xs={5.3}>
                <Tooltip
                  title={"(Cost per response)"}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: "#46464f",
                        color: "white"
                      }
                    }
                  }}
                >
                  <Card style={{
                    backgroundColor: "#040405",
                    height: 12,
                    borderRadius: 30,
                    padding: theme.spacing(1),
                    outlineWidth: 1,
                    outlineStyle: "solid",
                    outlineColor: "#3b3b42"
                  }}>
                    <ButtonBase
                      disableRipple
                      disabled
                      style={{
                        display: 'block',
                        textAlign: 'center',
                        height: 26,
                        top: -7,
                        width: '100%',
                        fontSize: 12,
                        fontWeight: 600,
                        color: "textPrimary",
                      }}
                    >
                      {props.totalResponses > 0 ? "$" + (props.totalCost / props.totalResponses)?.toFixed(2) + " CPR" : "N/A CPR"}
                    </ButtonBase>
                  </Card>
                </Tooltip>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Fade>
    </Modal >
  )
};

const CampaignRow = ({ campaign, totalStudents, costPerCampaignMessage, refetch }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const auth = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(false);
  const [openEngagement, setOpenEngagement] = useState(false);
  const [openCostMetrics, setOpenCostMetrics] = useState(false);
  const [recipientLoading, setRecipientLoading] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [message, setMessage] = useState("");
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const [filterOpens, setFilterOpens] = useState(false);
  const [filterResponses, setFilterResponses] = useState(false);
  const [date, setDate] = useState<any>(undefined);
  const [status, setStatus] = useState("Draft");
  const [audienceSize, setAudienceSize] = useState(0);
  const [checkedAllowResponses, setCheckedAllowResponses] = useState(true);
  const [filters, setFilters] = useState<any>(undefined);
  const [query, setQuery] = useState("");

  const [getCampaign, { data, loading, error: errorFetching, fetchMore }] =
    useLazyQuery(FETCH_CAMPAIGN);
  const [searchRecipients, { data: searchData, loading: searchLoading }] =
    useLazyQuery(SEARCH_CAMPAIGN_RECIPIENTS);
  const [getCampaignAnalytics, { data: analyticsData, loading: analyticsLoading, error: analyticsErrorFetching }] =
    useLazyQuery(FETCH_CAMPAIGN_ANALYTICS);
  const [updateCampaign, { data: updateData, error: errorUpdating }] =
    useMutation(UPDATE_CAMPAIGN);
  const [deleteCampaign, { data: deleteData }] = useMutation(DELETE_CAMPAIGN);

  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  const handleClick = (e) => {
    setOpen(true);
    getCampaign({
      variables: {
        id: campaign.id,
      },
    });
  };

  const viewAudience = async (e) => {
    e.stopPropagation();

    setRecipientLoading(true);

    setOpenEngagement(true);

    getCampaign({
      variables: {
        id: campaign.id,
      },
    });

    getCampaignAnalytics({
      variables: {
        id: campaign.id,
      },
    })

    setRecipientLoading(false);
  };

  useEffect(() => {
    if (filterOpens == false && filterResponses == false) {
      searchRecipients({
        variables: {
          id: campaign.id,
          query: query
        },
      });
    }
  }, [query]);

  const loadMore = async () => {
    try {
      if (fetchMore) {
        await fetchMore({
          variables: {
            after:
              data?.campaign?.recipients?.edges[data?.campaign?.recipients?.edges?.length - 1]
                ?.cursor,
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;
            if (fetchMoreResult.campaign.recipients.edges.length === 0) return prev;
            return {
              ...prev,
              campaign: {
                ...prev.campaign,
                recipients: {
                  ...prev.campaign.recipients,
                  edges: [
                    ...prev.campaign.recipients.edges,
                    ...fetchMoreResult.campaign.recipients.edges,
                  ],
                },
              },
            };
          },
        });
      }
    } catch (error) { return }
  };

  const removeCampaign = () => {
    setOpen(false);
    setOpenConfirm(false);
    deleteCampaign({
      variables: {
        id: campaign.id,
      },
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setOpen(false);
    updateCampaign({
      variables: {
        id: campaign.objectId,
        collegeID: auth.collegeId,
        myID: Parse.User.current()?.get("userContact").id,
        name: name,
        desc: desc,
        message: message,
        status: status,
        launchDate: status === "Scheduled" ? date.toISOString() : undefined,
        allowResponses: checkedAllowResponses,
        ...filters.saveData,
      },
    });
  };

  useEffect(() => {
    if (data) {
      setName(data?.campaign.campaignName);
      setDesc(data?.campaign.campaignDescription);
      setMessage(data?.campaign.message);
      setStatus(data?.campaign.status);
      setAudienceSize(data?.campaign?.filterQuery?.audienceSize);
      setCheckedAllowResponses(data?.campaign?.allowResponses);
      if (data?.campaign.status !== "Draft") {
        const scheduleDate = new Date(data?.campaign?.launchDate);
        setDate(scheduleDate);
      } else {
        setDate(null);
      }
    }
  }, [data]);

  if (errorFetching) console.log(JSON.stringify(errorFetching, null, 2));

  useEffect(() => {
    document.body.style.backgroundColor = "#0e0e14";

    if (errorUpdating) {
      enqueueSnackbar("Error updating campaign.", {
        variant: "error",
      });
    }
    if (updateData) {
      enqueueSnackbar("Successfully updated campaign.", {
        variant: "success",
      });
    }
    if (deleteData) {
      enqueueSnackbar("Successfully deleted campaign.", {
        variant: "success",
      });
    }

    refetch();
  }, [updateData, deleteData, errorUpdating]);

  return (
    <>
      <TableRow
        hover
        key={campaign.id}
        onClick={handleClick}
        className={classes.campaignRow}
      >
        <TableCell>
          <Box display="flex" alignItems="center">
            <div>
              <Typography variant="body1" style={{ fontWeight: 600 }} color="textPrimary">
                {campaign.campaignName}
              </Typography>
              <Typography variant="body2" style={{ fontWeight: 500 }} color="textSecondary">
                {"Created by: " +
                  campaign.author?.firstName +
                  " " +
                  campaign.author?.lastName}
              </Typography>
              <Typography style={{ fontSize: 13 }} color="textSecondary">
                {"Last Updated: " + months[(new Date(campaign.updatedAt)).getMonth()] + " " + (new Date(campaign.updatedAt)).getDate() + ", " + (new Date(campaign.updatedAt)).getFullYear()}
              </Typography>
            </div>
          </Box>
        </TableCell>
        <TableCell>{campaign.message.length >= 60 ? campaign.message.slice(0, 40) + "..." : campaign.message}</TableCell>
        <TableCell>
          <Typography fontSize={15} color='textSecondary'>
            {costPerCampaignMessage > 0 ? campaign.status == "Sent" ? "$" + campaign.finalCost?.toFixed(2) : campaign.filterQuery?.audienceSize != null ? "$" + ((campaign.filterQuery?.audienceSize * costPerCampaignMessage) + 50)?.toFixed(2) : "$" + ((totalStudents * costPerCampaignMessage) + 50)?.toFixed(2) : campaign.finalCost ? "$" + campaign.finalCost?.toFixed(2) : "$0.00"}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography fontSize={15} color='textSecondary'>
            {campaign.filterQuery?.audienceSize === totalStudents ||
              campaign.filterQuery?.audienceSize === null ||
              campaign.filterQuery?.audienceSize === undefined
              ? "All students"
              : campaign.filterQuery?.audienceSize
                ?.toString()
                ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography fontSize={15} color={campaign.status == "Sent" ? theme.palette.secondary.main : "#fff"}>
            {campaign.status === "Scheduled"
              ? campaign.status + " (" + parseDate(campaign.launchDate) + ")"
              : campaign.status}
          </Typography>
        </TableCell>
        <TableCell>
          {campaign.status == "Sent" ? (
            <RealButton
              variant="outlined"
              style={{
                background: "linear-gradient(75deg, #35c669, #2ec4a9)",
                border: 0,
                color: "#fff",
                marginLeft: "auto",
                fontSize: 12
              }}
              onClick={e => viewAudience(e)}
            >
              View
            </RealButton>
          ) : (<></>)}
        </TableCell>
        <TableCell>
          {campaign.status == "Sent" ? (
            <RealButton
              variant="outlined"
              style={{
                background: "linear-gradient(75deg, #35c669, #2ec4a9)",
                border: 0,
                color: "#fff",
                marginLeft: "auto",
                fontSize: 12
              }}
              onClick={(e) => {
                e.stopPropagation();

                setOpenCostMetrics(true);
              }}
            >
              View
            </RealButton>
          ) : (<></>)}
        </TableCell>
        <TableCell align="right">
          <SvgIcon fontSize="small">
            <ChevronRightIcon />
          </SvgIcon>
        </TableCell>
      </TableRow>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Modal
          className={classes.modal}
          open={open}
          onClose={() => setOpen(false)}
          closeAfterTransition
        >
          <Fade in={open}>
            <form onSubmit={handleSubmit}>
              <Card
                style={{
                  maxHeight: 690,
                  overflowY: "auto",
                  overflowX: "hidden",
                  backgroundColor: "#0e0e14",
                  display: "flex",
                  flexDirection: "column",
                  padding: theme.spacing(4),
                  borderRadius: 25,
                  outlineColor: "#25252b",
                  outlineStyle: "solid",
                  outlineWidth: 1,
                }}
                className="cardScroll"
              >
                {loading ? (
                  <Loading />
                ) : (
                  <Box width="600px">
                    <Grid container sx={{ alignSelf: "left" }}>
                      {status == "Sent" ? (
                        <Typography style={{ fontWeight: 600, fontSize: 30 }} align="left" color="textPrimary">
                          View Campaign Details
                        </Typography>
                      ) : (
                        <>
                          <Typography style={{ fontWeight: 600, fontSize: 30 }} align="left" color="textPrimary">
                            Edit Campaign
                          </Typography>
                        </>
                      )}
                      <Box flexGrow={1} />
                      <IconButton style={{ width: 40, height: 40 }} onClick={() => setOpen(false)}>
                        <CloseIcon />
                      </IconButton>
                    </Grid>
                    {status == "Sent" ? (
                      <Grid container sx={{ alignSelf: "left" }}>
                        <Typography variant="body2" align="left" color="textSecondary">
                          Final Cost: ${campaign.finalCost?.toFixed(2)}
                        </Typography>
                      </Grid>
                    ) : (
                      <>
                        <Grid container sx={{ alignSelf: "left" }}>
                          <Typography variant="body2" align="left" color="textSecondary">
                            Estimated Cost: {costPerCampaignMessage > 0 ? audienceSize != null ? "$" + ((audienceSize * costPerCampaignMessage) + 50)?.toFixed(2) : "$" + ((totalStudents * costPerCampaignMessage) + 50)?.toFixed(2) : "$0"}
                          </Typography>
                        </Grid>
                      </>
                    )}
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Box height="10px" />
                        <Typography variant="h6" color="textSecondary">
                          Details
                        </Typography>
                        <Box height="10px" />
                        <Input
                          label="Name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          fullWidth
                          disabled={status == "Sent"}
                          required
                        />
                        <Box height="10px" />
                        <Input
                          label="Description (optional)"
                          value={desc}
                          onChange={(e) => setDesc(e.target.value)}
                          disabled={status == "Sent"}
                          fullWidth
                        />
                        <Box height="10px" />
                        <Input
                          label="Message"
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                          fullWidth
                          multiline
                          disabled={status == "Sent"}
                          rows={5}
                          required
                        />
                        <Box height="10px" />
                        {status == "Sent" ? (
                          <Input
                            className={classes.selectStatus}
                            label="Status"
                            // select
                            fullWidth
                            disabled
                            value="Sent"
                          >
                          </Input>
                        ) : (
                          <>
                            <Input
                              className={classes.selectStatus}
                              label="Status"
                              select
                              fullWidth
                              value={status}
                              onChange={(e) => {
                                setStatus(e.target.value);
                                if (e.target.value === "Draft") {
                                  setDate(undefined);
                                }
                              }}
                            >
                              <MenuItem value="Draft">Draft</MenuItem>
                              <MenuItem value="Scheduled">Scheduled</MenuItem>
                            </Input>
                          </>
                        )}
                      </Grid>
                    </Grid>
                    <Box height="10px" />
                    <Accordion
                      style={{
                        background: theme.palette.secondary.dark,
                        borderRadius: theme.shape.borderRadius,
                      }}
                      sx={{
                        '&:before': {
                          display: 'none',
                        }
                      }}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <CardHeader
                          title={
                            filters?.loading ? (
                              <Box width={150}>
                                <Loading small />
                              </Box>
                            ) : (
                              `Filters (${(filters?.numStudents === totalStudents ||
                                !filters?.numStudents) &&
                                filters?.numStudents !== 0
                                ? "All"
                                : filters?.numStudents
                                  ?.toLocaleString()
                              } students)`
                            )
                          }
                          titleTypographyProps={{ fontWeight: 600, fontSize: 20 }}
                        />
                      </AccordionSummary>
                      <AccordionDetails
                        style={{
                          paddingLeft: theme.spacing(3),
                          paddingRight: theme.spacing(3)
                        }}
                      >
                        <Filters
                          setFilters={setFilters}
                          disableFromSourceOption={false}
                          filterQuery={data?.campaign?.filterQuery}
                          disabled={status == "Sent"}
                          setAudienceSize={setAudienceSize}
                        />
                      </AccordionDetails>
                    </Accordion>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="allowResponses"
                          checked={checkedAllowResponses}
                          disabled={status == "Sent"}
                          sx={{
                            color: theme.palette.text.secondary,
                            '&.Mui-checked': {
                              color: theme.palette.secondary.main,
                            },
                          }}
                          onChange={(e) =>
                            setCheckedAllowResponses(e.target.checked)
                          }
                        />
                      }
                      label={<Typography style={{ fontWeight: 400, fontSize: 14 }}>Allow Student Responses</Typography>}
                    />
                    <Box height="10px" />
                    <Box display="flex" justifyContent="right">
                      {(status === "Scheduled" || status === "Sent") && (
                        <>
                          <DateTimePicker
                            label={status == "Scheduled" ? "Scheduled Date and Time" : "Sent Date and Time"}
                            disabled={status == "Sent"}
                            value={dayjs(date)}
                            minutesStep={10}
                            onChange={(d) => {
                              if (!d) return;
                              console.log(d);
                              setDate(d);
                            }}
                            disablePast={status == "Scheduled"}
                            sx={{ marginTop: theme.spacing(-1), width: 250 }}
                            slotProps={{
                              textField: {
                                placeholder: "fsdf",
                                color: "primary",
                                required: true,
                                onKeyDown: (e) => e.preventDefault()
                              },
                            }}
                          />
                        </>
                      )}
                      <Box width="10px" />
                      {status != "Sent" ? (
                        <Button type="submit" style={{ height: 40, color: "#fff" }}>
                          Save
                        </Button>
                      ) : (<></>)}
                      <Box width="10px" />
                      <RealButton
                        style={{
                          height: 40,
                          backgroundColor: theme.palette.error.main,
                          color: theme.palette.text.primary,
                        }}
                        variant="contained"
                        onClick={() => setOpenConfirm(true)}
                      >
                        Delete
                      </RealButton>
                      <CustomModal
                        open={openConfirm}
                        setOpen={setOpenConfirm}
                        sub="Are you sure you want to delete this campaign?"
                        noScroll
                      >
                        <Box display="flex">
                          <Grid container spacing={2}>
                            <Grid item xs={1} />
                            <Grid item xs={5}>
                              <RealButton
                                style={{
                                  height: 50,
                                  backgroundColor: theme.palette.error.main,
                                  color: theme.palette.text.primary,
                                  borderRadius: 13
                                }}
                                fullWidth
                                variant="contained"
                                onClick={() => setOpenConfirm(false)}
                              >
                                Cancel
                              </RealButton>
                            </Grid>
                            <Grid item xs={5}>
                              <Button
                                style={{
                                  height: 50,
                                  borderRadius: 13
                                }}
                                variant="contained"
                                fullWidth
                                onClick={() => removeCampaign()}
                              >
                                Yes
                              </Button>
                            </Grid>
                            <Grid item xs={1} />
                          </Grid>
                        </Box>
                      </CustomModal>
                    </Box>
                  </Box>
                )}
              </Card>
            </form>
          </Fade>
        </Modal>
        <CostMetrics
          open={openCostMetrics}
          setOpen={setOpenCostMetrics}
          totalCost={campaign?.finalCost}
          totalOpens={campaign?.opens?.count}
          totalResponses={campaign?.responses?.count}
        />
      </LocalizationProvider>
      <Modal
        className={classes.modal}
        open={openEngagement}
        onClose={() => setOpenEngagement(false)}
        closeAfterTransition
      >
        <Fade in={openEngagement}>
          <Card style={{
            height: Math.min((data?.campaign?.recipients?.count * 67) + 210, 526),
            overflowX: "hidden",
            backgroundColor: theme.palette.primary.dark,
            display: "flex",
            flexDirection: "column",
            padding: theme.spacing(4),
            borderRadius: 25,
            outlineColor: "#25252b",
            outlineStyle: "solid",
            outlineWidth: 1,
          }}>
            <Box width="500px">
              <Grid container sx={{ alignSelf: "left" }}>
                <Typography style={{ fontWeight: 600, fontSize: 29 }} align="left" color="textPrimary">
                  Campaign Engagement
                </Typography>
                <Box flexGrow={1} />
                <IconButton style={{ width: 40, height: 40 }} onClick={() => setOpenEngagement(false)}>
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Box width="20px" />
              {searchLoading || loading || recipientLoading ? (
                <div style={{ marginTop: theme.spacing(1) }}>
                  <Loading smaller />
                </div>
              ) : (
                <Grid container width={"80%"} marginTop={theme.spacing(0.4)} spacing={2}>
                  <Grid item xs={5.3}>
                    <Card style={{
                      backgroundColor: "#040405",
                      height: 12,
                      borderRadius: 30,
                      padding: theme.spacing(1),
                      outlineWidth: 1,
                      outlineStyle: "solid",
                      outlineColor: "#3b3b42"
                    }}>
                      <ButtonBase
                        disableRipple
                        style={{
                          display: 'block',
                          textAlign: 'center',
                          height: 26,
                          top: -7,
                          width: '100%',
                          fontSize: 12,
                          fontWeight: 600,
                          color: "textPrimary",
                        }}
                        onClick={() => {
                          setFilterOpens(false);
                          setFilterResponses(false);
                        }}
                      >
                        {data?.campaign?.recipients?.count?.toLocaleString() + " Sent"}
                      </ButtonBase>
                    </Card>
                  </Grid>
                  <Grid item xs={6.2}>
                    <Card style={{
                      backgroundColor: filterOpens ? "#1a1a21" : "#040405",
                      height: 12,
                      borderRadius: 30,
                      padding: theme.spacing(1),
                      outlineWidth: 1,
                      outlineStyle: "solid",
                      outlineColor: "#3b3b42"
                    }}>
                      <ButtonBase
                        disableRipple
                        style={{
                          display: 'block',
                          textAlign: 'center',
                          height: 26,
                          top: -7,
                          width: '100%',
                          fontSize: 12,
                          fontWeight: 600,
                          color: "textPrimary",
                        }}
                        onClick={() => {
                          setFilterOpens(!filterOpens);
                          setFilterResponses(false);
                        }}
                      >
                        {analyticsData?.campaign?.opens?.edges?.length + " Opened"}
                      </ButtonBase>
                    </Card>
                  </Grid>
                  <Grid item xs={7}>
                    <Card style={{
                      backgroundColor: filterResponses ? "#1a1a21" : "#040405",
                      height: 12,
                      borderRadius: 30,
                      padding: theme.spacing(1),
                      outlineWidth: 1,
                      outlineStyle: "solid",
                      outlineColor: "#3b3b42"
                    }}>
                      <ButtonBase
                        disableRipple
                        style={{
                          display: 'block',
                          textAlign: 'center',
                          height: 26,
                          top: -7,
                          width: '100%',
                          fontSize: 12,
                          fontWeight: 600,
                          color: "textPrimary",
                        }}
                        onClick={() => {
                          setFilterOpens(false);
                          setFilterResponses(!filterResponses);
                        }}
                      >
                        {analyticsData?.campaign?.responses?.edges?.length + " Responded"}
                      </ButtonBase>
                    </Card>
                  </Grid>
                </Grid>
              )}
              <Box height="20px" />
              <div className={classes.search}>
                <SvgIcon fontSize="small" color="action">
                  <SearchIcon />
                </SvgIcon>
                <RealInput
                  className={classes.searchInput}
                  disableUnderline
                  placeholder="Search by name"
                  value={query}
                  onChange={(e) => {
                    setQuery(e.target.value);
                  }}
                />
              </div>
              <Box height="10px" />
              <Grid item xs={12}>
                {/* {recipientLoading ? <Loading /> : <></>} */}
                {data?.campaign?.recipients?.edges && data?.campaign?.recipients?.edges?.length > 0 ?
                  <div style={{ height: Math.min((data?.campaign?.recipients?.count * 67) + 145, 350) }}>
                    {filterOpens ? (
                      <>
                        {query != "" ? (
                          <>
                            <PerfectScrollbar>
                              <List>
                                {analyticsData?.campaign?.opens?.edges?.filter((x) => x.node.fullName.toLowerCase().includes(query.toLowerCase())).map((p) => (
                                  <Person
                                    key={p.node.id}
                                    person={p.node}
                                    hasOpened={analyticsData?.campaign?.opens?.edges?.filter((x) => x.node.objectId == p.node.objectId).length > 0}
                                    hasResponded={analyticsData?.campaign?.responses?.edges?.filter((x) => x.node.objectId == p.node.objectId).length > 0}
                                  />
                                ))}
                              </List>
                              {analyticsData?.campaign?.opens?.edges?.filter((x) => x.node.fullName.toLowerCase().includes(query.toLowerCase())).length === 0 && (
                                <Box display='flex' justifyContent='center'>
                                  <Typography>No results.</Typography>
                                </Box>
                              )}
                            </PerfectScrollbar>
                          </>
                        ) : (
                          <>
                            <PerfectScrollbar>
                              <List>
                                {analyticsData?.campaign?.opens?.edges?.map((p) => (
                                  <Person
                                    key={p.node.id}
                                    person={p.node}
                                    hasOpened={analyticsData?.campaign?.opens?.edges?.filter((x) => x.node.objectId == p.node.objectId).length > 0}
                                    hasResponded={analyticsData?.campaign?.responses?.edges?.filter((x) => x.node.objectId == p.node.objectId).length > 0}
                                  />
                                ))}
                              </List>
                              {analyticsData?.campaign?.opens?.edges?.length === 0 && (
                                <Box display='flex' justifyContent='center'>
                                  <Typography>No results.</Typography>
                                </Box>
                              )}
                            </PerfectScrollbar>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {filterResponses ? (
                          <>
                            {query != "" ? (
                              <>
                                <PerfectScrollbar>
                                  <List>
                                    {analyticsData?.campaign?.responses?.edges?.filter((x) => x.node.fullName.toLowerCase().includes(query.toLowerCase())).map((p) => (
                                      <Person
                                        key={p.node.id}
                                        person={p.node}
                                        hasOpened={analyticsData?.campaign?.opens?.edges?.filter((x) => x.node.objectId == p.node.objectId).length > 0}
                                        hasResponded={analyticsData?.campaign?.responses?.edges?.filter((x) => x.node.objectId == p.node.objectId).length > 0}
                                      />
                                    ))}
                                  </List>
                                  {analyticsData?.campaign?.responses?.edges?.filter((x) => x.node.fullName.toLowerCase().includes(query.toLowerCase())).length === 0 && (
                                    <Box display='flex' justifyContent='center'>
                                      <Typography>No results.</Typography>
                                    </Box>
                                  )}
                                </PerfectScrollbar>
                              </>
                            ) : (
                              <>
                                <PerfectScrollbar>
                                  <List>
                                    {analyticsData?.campaign?.responses?.edges?.map((p) => (
                                      <Person
                                        key={p.node.id}
                                        person={p.node}
                                        hasOpened={analyticsData?.campaign?.opens?.edges?.filter((x) => x.node.objectId == p.node.objectId).length > 0}
                                        hasResponded={analyticsData?.campaign?.responses?.edges?.filter((x) => x.node.objectId == p.node.objectId).length > 0}
                                      />
                                    ))}
                                  </List>
                                  {analyticsData?.campaign?.responses?.edges?.length === 0 && (
                                    <Box display='flex' justifyContent='center'>
                                      <Typography>No results.</Typography>
                                    </Box>
                                  )}
                                </PerfectScrollbar>
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            {query != "" ? (
                              <>
                                <PerfectScrollbar>
                                  <List>
                                    {searchData?.campaign?.recipients?.edges?.map((p) => (
                                      <Person
                                        key={p.node.id}
                                        person={p.node}
                                        hasOpened={analyticsData?.campaign?.opens?.edges?.filter((x) => x.node.objectId == p.node.objectId).length > 0}
                                        hasResponded={analyticsData?.campaign?.responses?.edges?.filter((x) => x.node.objectId == p.node.objectId).length > 0}
                                      />
                                    ))}
                                  </List>
                                  {searchData?.campaign?.recipients?.edges.length === 0 && (
                                    <Box display='flex' justifyContent='center'>
                                      <Typography>No results.</Typography>
                                    </Box>
                                  )}
                                </PerfectScrollbar>
                              </>
                            ) : (
                              <>
                                <PerfectScrollbar onYReachEnd={loadMore}>
                                  <List>
                                    {data?.campaign?.recipients?.edges?.map((p) => (
                                      <Person
                                        key={p.node.id}
                                        person={p.node}
                                        hasOpened={analyticsData?.campaign?.opens?.edges?.filter((x) => x.node.objectId == p.node.objectId).length > 0}
                                        hasResponded={analyticsData?.campaign?.responses?.edges?.filter((x) => x.node.objectId == p.node.objectId).length > 0}
                                      />
                                    ))}
                                  </List>
                                  {data?.campaign?.recipients?.edges.length === 0 && (
                                    <Box display='flex' justifyContent='center'>
                                      <Typography>No results.</Typography>
                                    </Box>
                                  )}
                                </PerfectScrollbar>
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                  : <></>}
              </Grid>
            </Box>
          </Card>
        </Fade>
      </Modal >
    </>
  );
};

export default function Campaigns() {
  const theme = useTheme();
  const classes = useStyles(theme);

  const auth = useAuth();

  const [openMenu, setOpenMenu] = useState(false);
  const [costPerCampaignMessage, setCostPerCampaignMessage] = useState(0);
  const [anchor, setAnchor] = useState<any>(null);
  const popoverRef = useRef(null);
  const options = [
    {
      label: "All",
      value: {},
    },
    {
      label: "Sent",
      value: { status: { equalTo: "Sent" } },
    },
    {
      label: "Scheduled",
      value: { status: { equalTo: "Scheduled" } },
    },
    {
      label: "Drafts",
      value: { status: { equalTo: "Draft" } },
    },
  ];
  const [filter, setFilter] = useState({});
  const [label, setLabel] = useState("All");
  const { data, loading, error, refetch } = useQuery(FETCH_CAMPAIGNS, {
    variables: { id: auth.collegeId, filter },
    fetchPolicy: "network-only",
  });
  const { data: numStudentsData, error: numStudentsError } = useQuery(
    GET_TOTAL_NUM_STUDENTS
  );
  const { data: onboardedCollegeData, error: onboardedCollegeError } = useQuery(GET_ONBOARDED_COLLEGE, {
    variables: { id: auth.collegeId },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (onboardedCollegeData) {
      setCostPerCampaignMessage(onboardedCollegeData?.onboardedColleges?.edges[0].node?.costPerCampaignMessage);
    }
  }, [onboardedCollegeData]);

  // if (error) console.log(error);

  return (
    <Page title="Campaign Management" maxWidth="xl">
      <FadeIn>
        <Card
          style={{
            backgroundColor: theme.palette.primary.dark,
            paddingTop: theme.spacing(3),
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(1),
            boxShadow: theme.shadows[4],
            borderRadius: 25,
            outlineColor: "#25252b",
            outlineStyle: "solid",
            outlineWidth: 1,
            height: "calc(100vh - 155px)"
          }}
        >
          <Grid container spacing={3} sx={{ width: "100%" }}>
            <Grid item xs={12}>
              <FadeIn>
                <Box minHeight={56} display="flex" alignItems="center">
                  <Typography color="textPrimary" style={{ marginLeft: theme.spacing(2), fontSize: 30, fontWeight: 600 }}>Campaigns</Typography>
                  <Box flexGrow={1} />
                  <Typography variant="body1" color="textSecondary">
                    Showing {`${label}`}
                  </Typography>
                  <IconButton
                    size="large"
                    className={classes.filterButton}
                    ref={popoverRef}
                    onClick={(e) => {
                      setOpenMenu(true);
                      setAnchor(e.currentTarget);
                    }}
                  >
                    <FilterListIcon />
                  </IconButton>
                  <Menu
                    id="post-menu"
                    anchorEl={anchor}
                    open={openMenu}
                    slotProps={{
                      paper: {
                        sx: {
                          width: 160,
                          backgroundColor: theme.palette.primary.dark,
                          borderRadius: 1.5,
                          outlineColor: "#3c3c44",
                          outlineStyle: "solid",
                          outlineWidth: 1,
                        }
                      }
                    }}
                    onClose={() => setOpenMenu(false)}
                  >
                    {options.map((option) => (
                      <MenuItem
                        onClick={() => {
                          setOpenMenu(false);
                          setFilter(option.value);
                          setLabel(option.label);
                        }}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </Menu>
                  <NewCampaign
                    totalStudents={numStudentsData?.userStudents?.count}
                    costPerCampaignMessage={costPerCampaignMessage}
                    refetch={refetch}
                  />
                </Box>
              </FadeIn>
              <Container className={classes.results} maxWidth={false}>
                {data?.campaigns?.count === 0 ? (
                  <>
                    <Box height="190px" />
                    <Box display='flex' justifyContent='center'>
                      <Typography>No Results.</Typography>
                    </Box>
                    <Box height="20px" />
                  </>
                ) : (
                  <PerfectScrollbar>
                    {loading ? (
                      <Loading lower />
                    ) : (
                      <Table>
                        <TableHead>
                          <TableRow className={classes.tableCell}>
                            <TableCell>Name</TableCell>
                            <TableCell>Message</TableCell>
                            <TableCell>Cost</TableCell>
                            <TableCell>Audience Size</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Engagement</TableCell>
                            <TableCell>Cost Metrics</TableCell>
                            <TableCell align="right"></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data?.campaigns?.edges.map((campaign) => (
                            <CampaignRow
                              campaign={campaign.node}
                              refetch={refetch}
                              costPerCampaignMessage={costPerCampaignMessage}
                              totalStudents={numStudentsData?.userStudents?.count}
                            />
                          ))}
                        </TableBody>
                      </Table>
                    )}
                  </PerfectScrollbar>
                )}
              </Container>
            </Grid>
          </Grid>
        </Card>
      </FadeIn>
    </Page>
  );
}
