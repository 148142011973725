import React, { useEffect, useState } from "react";
import Parse from "parse";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Card,
  Grid,
  Box,
  Button,
  IconButton,
  Typography,
  Modal,
  Backdrop,
  Fade,
  Table,
  TableHead,
  TableRow,
  Avatar,
  SvgIcon,
  Input,
  TableCell,
  TableBody,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip
} from "@mui/material";
import { useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import Search from "./Search";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Filters, Loading } from "../../../../components";
import ThreadItem from "./ThreadItem";
import { Link } from "react-router-dom";
import { gql, useQuery, useLazyQuery } from "@apollo/client";
import { Search as SearchIcon } from "react-feather";
import "react-perfect-scrollbar/dist/css/styles.css";
import { useAuth } from "../../../../hooks/useAuth";

const useStyles = makeStyles((theme: any) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  card: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  avatar: {
    height: 42,
    width: 42,
    top: 2,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    backgroundColor: "#1d1d2b",
  },
  button: {
    background: "linear-gradient(75deg, #35c669, #2ec4a9)",
    border: 0,
    color: "#fff",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: theme.spacing(3),
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    padding: theme.spacing(4),
    maxHeight: 700,
    borderRadius: 15,
    outlineColor: "#25252b",
    outlineStyle: "solid",
    outlineWidth: 1,
  },
  deactivate: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.text.primary,
  },
  tableCell: {
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  accordion: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[0],
    outlineColor: theme.palette.background.paper,
    marginTop: theme.spacing(1),
  },
  accordianDetails: {
    justifyContent: "center",
  },
  person: {
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(1),
    height: 47,
    display: "flex",
    "&:hover": {
      backgroundColor: theme.palette.primary.lightHover,
      cursor: "pointer"
    },
  },
  cardAction: {
    width: "auto",
    height: "auto",
    display: "block",
  },
  search: {
    display: "flex",
    alignItems: "center",
    height: 44,
    borderRadius: 10,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    backgroundColor: theme.palette.primary.light,
    outlineColor: "#2a2a30",
    outlineStyle: "solid",
    outlineWidth: 1,
  },
  searchInput: {
    flexGrow: 1,
    marginLeft: theme.spacing(1),
  },
  results: {
    overflowY: "auto",
    height: 300,
  },
}));

const SEARCH_HIGHSCHOOL = gql`
  query searchUsers(
    $filters: [UserStudentWhereInput!]
    $majorsFilter: MajorRelationWhereInput
    $interestsFilter: ProfileInterestRelationWhereInput
    $collegesFilter: CollegeRelationWhereInput
    $nameFilter: UserContactRelationWhereInput
    $after: String
  ) {
    userStudents(
      first: 50
      after: $after
      order: createdAt_DESC
      where: {
        AND: $filters
        gender: { exists: true }
        fromLead: { equalTo: false }
        generalInterestedMajors: $majorsFilter
        interests: $interestsFilter
        generalInterestedColleges: $collegesFilter
        userContact: $nameFilter
      }
    ) {
      count
      edges {
        cursor
        node {
          id
          objectId
          verificationStatus
          appliedColleges {
            ... on Element {
              value
            }
          }
          acceptedColleges {
            ... on Element {
              value
            }
          }
          enrolledColleges {
            ... on Element {
              value
            }
          }
          user {
            id
            objectId
            username
          }
          userContact {
            id
            objectId
            firstName
            lastName
            img {
              url
            }
          }
        }
      }
    }
  }
`;

const SEARCH_LEADS = gql`
  query searchLeads(
    $collegeId: ID!
    $filters: [UserStudentWhereInput!]
    $majorsFilter: MajorRelationWhereInput
    $interestsFilter: ProfileInterestRelationWhereInput
    $collegesFilter: CollegeRelationWhereInput
    $nameFilter: UserContactRelationWhereInput
    $after: String
  ) {
    leads(
      first: 50
      after: $after
      order: createdAt_DESC
      where: {
        student: { 
          have: { 
            AND: $filters 
            generalInterestedMajors: $majorsFilter
            interests: $interestsFilter
            generalInterestedColleges: $collegesFilter
            userContact: $nameFilter
          } 
        }
        hasCompletedProfile: { equalTo: true } 
        college: { have: { objectId: { equalTo: $collegeId } } }
      }
    ) {
      count
      edges {
        cursor
        node {
          id
          objectId
          verificationStatus
          appliedColleges {
            ... on Element {
              value
            }
          }
          acceptedColleges {
            ... on Element {
              value
            }
          }
          enrolledColleges {
            ... on Element {
              value
            }
          }
          student {
            id
            objectId
            user {
              id
              objectId
              username
            }
            userContact {
              id
              objectId
              firstName
              lastName
              img {
                url
              }
            }
          }
        }
      }
    }
  }
`;

const SEARCH_COLLEGEORALUM = gql`
  query searchUsers(
    $filters: [UserStudentWhereInput!]
    $majorsFilter: MajorRelationWhereInput
    $interestsFilter: ProfileInterestRelationWhereInput
    $collegesFilter: CollegeRelationWhereInput
    $nameFilter: UserContactRelationWhereInput
    $after: String
  ) {
    userStudents(
      first: 50
      after: $after
      order: createdAt_DESC
      where: {
        AND: $filters
        gender: { exists: true }
        fromLead: { equalTo: false }
        generalInterestedMajors: $majorsFilter
        interests: $interestsFilter
        college: $collegesFilter
        userContact: $nameFilter
      }
    ) {
      count
      edges {
        cursor
        node {
          id
          objectId
          verificationStatus
          appliedColleges {
            ... on Element {
              value
            }
          }
          acceptedColleges {
            ... on Element {
              value
            }
          }
          enrolledColleges {
            ... on Element {
              value
            }
          }
          user {
            id
            objectId
            username
          }
          userContact {
            id
            objectId
            firstName
            lastName
            img {
              url
            }
          }
        }
      }
    }
  }
`;

const Person = ({ person }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const auth = useAuth();

  return (
    <Grid item xs={12}>
      <Link
        to={`/dashboard/messages/create/${person.userContact.objectId}`}
        style={{
          width: "auto",
          height: "auto",
          display: "block",
          textDecoration: "none"
        }}
      >
        <Card
          sx={{
            "&:hover": {
              backgroundColor: theme.palette.primary.lighter,
              cursor: "pointer"
            },
          }}
          style={{
            backgroundColor: "#202028",
            padding: theme.spacing(1),
            height: 47,
            display: "flex",
          }}>
          <Avatar
            alt={person.userContact.firstName}
            src={person.userContact.img?.url}
            className={classes.avatar}
          />
          <Box sx={{ alignSelf: "left" }} ml={1}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: 'transparent',
              }}
            >
              <Typography style={{ color: "#fff", fontSize: 16, fontWeight: 600 }}>
                {person?.userContact?.firstName +
                  " " +
                  person?.userContact?.lastName}
              </Typography>
              {person?.verificationStatus == "verified" ? (
                <img
                  height={17}
                  width={17}
                  src={"/assets/icons/ic_verify.png"}
                  style={{
                    visibility: person?.verificationStatus == "verified" ? "visible" : "hidden",
                    marginLeft: theme.spacing(0.5),
                  }}
                />
              ) : (<></>)}
              {person?.appliedColleges || person?.acceptedColleges || person?.enrolledColleges ? (
                <Tooltip
                  title={person?.appliedColleges.map((x) => x.value).includes(auth.collegeId) ? "Applied" : person?.acceptedColleges.map((x) => x.value).includes(auth.collegeId) ? "Accepted" : person?.enrolledColleges.map((x) => x.value).includes(auth.collegeId) ? "Enrolled" : "Not Applied"}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: "#46464f",
                        color: "white"
                      }
                    }
                  }}
                >
                  <img
                    height={14}
                    width={14}
                    src={person?.appliedColleges.map((x) => x.value).includes(auth.collegeId) ? "/assets/icons/ic_applied.svg" : person?.acceptedColleges.map((x) => x.value).includes(auth.collegeId) ? "/assets/icons/ic_accepted.svg" : person?.enrolledColleges.map((x) => x.value).includes(auth.collegeId) ? "/assets/icons/ic_enrolled.svg" : "/assets/icons/ic_applied.svg"}
                    style={{
                      visibility: person?.appliedColleges.map((x) => x.value).includes(auth.collegeId) || person?.acceptedColleges.map((x) => x.value).includes(auth.collegeId) || person?.enrolledColleges.map((x) => x.value).includes(auth.collegeId) ? "visible" : "hidden",
                      marginLeft: theme.spacing(0.5),
                    }}
                  />
                </Tooltip>
              ) : (<></>)}
            </div>
            <Typography variant="body2" color="textSecondary">
              {person?.user?.username}
            </Typography>
          </Box>
        </Card>
        <Box height="10px" />
      </Link>
    </Grid>
  );
};

export default function NewThread() {
  const theme = useTheme();
  const classes = useStyles(theme);
  const auth = useAuth();

  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState("");
  const [filters, setFilters] = useState<any>(undefined);
  const [filterType, setFilterType] = useState("highschool_student");
  const [source, setSource] = useState<any>("Organic");

  const [searchHighschool, { data: highschoolData, loading: highschoolLoading, error: highschoolError, fetchMore: fetchMoreHighschool }] = useLazyQuery<any>(SEARCH_HIGHSCHOOL, {
    variables: { query },
  });

  const [searchLeads, { data: leadsData, loading: leadsLoading, error: leadsError, fetchMore: fetchMoreLeads }] = useLazyQuery<any>(SEARCH_LEADS, {
    variables: { query },
  });

  const [searchCollege, { data: collegeData, loading: collegeLoading, error: collegeError, fetchMore: fetchMoreCollege }] = useLazyQuery<any>(SEARCH_COLLEGEORALUM, {
    variables: { query },
  });

  const onClickAway = () => {
    // handle click away event
  };

  const handleSearchChange = async (event) => {
    setQuery(event.target.value);
  };

  useEffect(() => {
    if (
      filters?.list?.length === 0 &&
      !filters.majorsFilter &&
      !filters.interestsFilter &&
      !filters.collegesFilter &&
      query === ""
    )
      return;

    if (filters) {
      var type = "";

      for (const obj of filters?.list) {
        if (obj.type?.equalTo) {
          type = obj.type?.equalTo;

          break
        }
      }

      setFilterType(type);

      if (type == "highschool_student" || type == "student_parent") {
        if (source == "Organic") {
          searchHighschool({
            variables: {
              filters: filters?.list?.length === 0 ? undefined : filters?.list,
              majorsFilter: filters?.majorsFilter,
              interestsFilter: filters?.interestsFilter,
              collegesFilter: filters?.collegesFilter,
              nameFilter:
                query === ""
                  ? undefined
                  : {
                    have: { fullName: { matchesRegex: query, options: "i" } },
                  },
            },
          });

        } else {
          searchLeads({
            variables: {
              filters: filters?.list?.length === 0 ? undefined : filters?.list,
              majorsFilter: filters?.majorsFilter,
              interestsFilter: filters?.interestsFilter,
              collegesFilter: filters?.collegesFilter,
              collegeId: auth.collegeId,
              nameFilter:
                query === ""
                  ? undefined
                  : {
                    have: { fullName: { matchesRegex: query, options: "i" } },
                  },
            },
          });
        }

      } else {
        searchCollege({
          variables: {
            filters: filters?.list?.length === 0 ? undefined : filters?.list,
            majorsFilter: filters?.majorsFilter,
            interestsFilter: filters?.interestsFilter,
            collegesFilter: filters?.collegesFilter,
            nameFilter:
              query === ""
                ? undefined
                : {
                  have: { fullName: { matchesRegex: query, options: "i" } },
                },
          },
        });
      }

    } else {
      searchHighschool({
        variables: {
          filters: filters?.list?.length === 0 ? undefined : filters?.list,
          majorsFilter: filters?.majorsFilter,
          interestsFilter: filters?.interestsFilter,
          collegesFilter: filters?.collegesFilter,
          nameFilter:
            query === ""
              ? undefined
              : {
                have: { fullName: { matchesRegex: query, options: "i" } },
              },
        },
      });
    }
  }, [filters, query]);

  const loadMore = async () => {
    if (filterType == "highschool_student" || filterType == "student_parent") {
      if (source == "Organic") {
        if (highschoolData?.userStudents.count > 0) {
          try {
            if (fetchMoreHighschool) {
              await fetchMoreHighschool({
                variables: {
                  after:
                    highschoolData?.userStudents?.edges[
                      highschoolData?.userStudents?.edges?.length - 1
                    ].cursor,
                },
                updateQuery: (prev: any, { fetchMoreResult }) => {
                  if (!fetchMoreResult) return prev;
                  if (fetchMoreResult.userStudents.edges.length === 0)
                    return prev;
                  return {
                    ...prev,
                    userStudents: {
                      ...prev.userStudents,
                      edges: [
                        ...prev.userStudents.edges,
                        ...fetchMoreResult.userStudents.edges,
                      ],
                    },
                  };
                },
              });
            }
          } catch (error) { return }
        }

      } else {
        if (leadsData?.leads?.count > 0) {
          try {
            if (fetchMoreHighschool) {
              await fetchMoreHighschool({
                variables: {
                  after:
                    leadsData?.leads?.edges[
                      leadsData?.leads?.edges?.length - 1
                    ].cursor,
                },
                updateQuery: (prev: any, { fetchMoreResult }) => {
                  if (!fetchMoreResult) return prev;
                  if (fetchMoreResult.leads.edges.length === 0)
                    return prev;
                  return {
                    ...prev,
                    leads: {
                      ...prev.leads,
                      edges: [
                        ...prev.leads.edges,
                        ...fetchMoreResult.leads.edges,
                      ],
                    },
                  };
                },
              });
            }
          } catch (error) { return }
        }
      }
    } else {
      if (collegeData?.userStudents.count > 0) {
        try {
          if (fetchMoreCollege) {
            await fetchMoreCollege({
              variables: {
                after:
                  collegeData?.userStudents?.edges[
                    collegeData?.userStudents?.edges?.length - 1
                  ].cursor,
              },
              updateQuery: (prev: any, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                if (fetchMoreResult.userStudents.edges.length === 0)
                  return prev;
                return {
                  ...prev,
                  userStudents: {
                    ...prev.userStudents,
                    edges: [
                      ...prev.userStudents.edges,
                      ...fetchMoreResult.userStudents.edges,
                    ],
                  },
                };
              },
            });
          }
        } catch (error) { return }
      }
    }
  };

  return (
    <>
      <IconButton
        size="large"
        className={classes.button}
        onClick={() => setOpen(true)}
      >
        <AddIcon />
      </IconButton>
      <Modal
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: theme.spacing(3),
        }}
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
      >
        <Fade in={open}>
          <Card style={{
            backgroundColor: theme.palette.primary.dark,
            display: "flex",
            overflow: "auto",
            flexDirection: "column",
            padding: theme.spacing(4),
            maxHeight: 700,
            borderRadius: 25,
            outlineColor: "#25252b",
            outlineStyle: "solid",
            outlineWidth: 1,
          }}>
            <Box display='flex' width="600px">
              <Grid container spacing={2} justifyContent='center'>
                <Grid container sx={{ alignSelf: "left" }}>
                  <div>
                    <Typography style={{ fontWeight: 600, fontSize: 29 }} align="left" marginLeft={theme.spacing(2)} marginTop={theme.spacing(1)} color="textPrimary">
                      Create New Message
                    </Typography>
                    {highschoolLoading || collegeLoading ? (
                      <Box ml={1.7}>
                        <Loading smaller />
                      </Box>
                    ) : (
                      <Typography variant="body2" marginLeft={theme.spacing(2)} color="textSecondary">
                        {`(${source == "Organic" ? (filterType == "highschool_student" || filterType == "student_parent"
                          ? highschoolData?.userStudents?.count?.toLocaleString() ?? 0
                          : collegeData?.userStudents?.count?.toLocaleString() ?? 0)
                          : leadsData?.leads?.count?.toLocaleString() ?? 0
                          } results)`}</Typography>
                    )}
                  </div>
                  <Box flexGrow={1} />
                  <div>
                    <IconButton style={{ marginTop: theme.spacing(2) }} onClick={() => setOpen(false)}>
                      <CloseIcon />
                    </IconButton>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.search}>
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                    <Input
                      className={classes.searchInput}
                      disableUnderline
                      placeholder="Search by name"
                      onChange={(e) => setQuery(e.target.value)}
                      value={query}
                    />
                  </div>
                </Grid>
                <Accordion style={{
                  backgroundColor: theme.palette.primary.dark,
                  boxShadow: theme.shadows[0],
                  outlineColor: theme.palette.background.paper,
                  marginTop: theme.spacing(3),
                  marginLeft: theme.spacing(2),
                  marginRight: theme.spacing(0)
                }}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Filters</Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ justifyContent: "center" }} >
                    <Box>
                      <Filters
                        setFilters={setFilters}
                        disableFromSourceOption={false}
                        noSearch
                        setSource={setSource}
                        disabled={false}
                      />
                    </Box>
                  </AccordionDetails>
                </Accordion>
                <Grid item xs={12}>
                  {filterType == "highschool_student" || filterType == "student_parent" ? (
                    <>
                      {
                        source == "Organic" ? (
                          <>
                            {highschoolData ? (
                              <>
                                {highschoolData?.userStudents.count === 0 && (
                                  <>
                                    <Box display='flex' justifyContent='center'>
                                      <Typography>No results.</Typography>
                                    </Box>
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                <Box display='flex' justifyContent='center'>
                                  <Typography>No results.</Typography>
                                </Box>
                              </>
                            )}
                          </>

                        ) : (
                          <>
                            {leadsData ? (
                              <>
                                {leadsData?.leads.count === 0 && (
                                  <>
                                    <Box display='flex' justifyContent='center'>
                                      <Typography>No results.</Typography>
                                    </Box>
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                <Box display='flex' justifyContent='center'>
                                  <Typography>No results.</Typography>
                                </Box>
                              </>
                            )}
                          </>
                        )}
                    </>
                  ) : (
                    <>
                      {collegeData ? (
                        <>
                          {collegeData?.userStudents.count === 0 && (
                            <>
                              <Box display='flex' justifyContent='center'>
                                <Typography>No results.</Typography>
                              </Box>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <Box display='flex' justifyContent='center'>
                            <Typography>No results.</Typography>
                          </Box>
                        </>
                      )}
                    </>
                  )}
                  <div className={classes.results}>
                    <PerfectScrollbar
                      onYReachEnd={loadMore}
                    >
                      {filterType == "highschool_student" || filterType == "student_parent" ? (
                        <>
                          {
                            source == "Organic" ? (
                              <>
                                {highschoolData?.userStudents?.edges?.map((user) => (
                                  <Person key={user.node.id} person={user.node} />
                                ))}
                              </>
                            ) : (
                              <>
                                {leadsData?.leads?.edges?.map((lead) => (
                                  <Person key={lead.node.id} person={lead.node.student} />
                                ))}
                              </>

                            )}
                        </>
                      ) : (
                        <>
                          {collegeData?.userStudents?.edges?.map((user) => (
                            <Person key={user.node.id} person={user.node} />
                          ))}
                        </>
                      )}
                    </PerfectScrollbar>
                  </div>
                </Grid>
              </Grid>
            </Box>
          </Card>
        </Fade>
      </Modal>
    </>
  );
}
