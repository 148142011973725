export const inviteEmailPersonalizedContent = `<!DOCTYPE html>
<html>

<body style="background-color: #e9ecef;">

  <!-- start preheader -->
  <!-- <div class="preheader" style="display: none; max-width: 0; max-height: 0; overflow: hidden; font-size: 1px; line-height: 1px; color: #fff; opacity: 0;">
    Reset your OptN account password.
  </div> -->
  <!-- end preheader -->

  <!-- start body -->
  <table border="0" cellpadding="0" cellspacing="0" width="100%">

    <!-- start logo -->
    <tr>
      <td align="center" bgcolor="#e9ecef">
        <!--[if (gte mso 9)|(IE)]>
        <table align="center" border="0" cellpadding="0" cellspacing="0" width="600">
        <tr>
        <td align="center" valign="top" width="600">
        <![endif]-->
        <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width: 600px;">
          <tr>
            <td align="center" valign="top" style="padding: 36px 24px;">
              <img
                src="https://api.joinoptn.com/parse/files/EK4QDXyjTzcNpo0rdlRpvgez6UHaTuy2V5fhJgQv/0765c587da19a3fa50be84027b240103_Icon-76%403x.png"
                alt="Logo" border="0" width="48" style="display: block; width: 48px; max-width: 48px; min-width: 48px;">
            </td>
          </tr>
        </table>
        <!--[if (gte mso 9)|(IE)]>
        </td>
        </tr>
        </table>
        <![endif]-->
      </td>
    </tr>
    <!-- end logo -->

    <!-- start hero -->
    <tr>
      <td align="center" bgcolor="#e9ecef">
        <!--[if (gte mso 9)|(IE)]>
        <table align="center" border="0" cellpadding="0" cellspacing="0" width="600">
        <tr>
        <td align="center" valign="top" width="600">
        <![endif]-->
        <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width: 600px;">
          <tr>
            <td align="left" bgcolor="#ffffff"
              style="padding: 36px 24px 0; font-family: 'Nunito', Helvetica, Arial, sans-serif; border-top: 0px solid #d4dadf; border-radius: 30px 30px 0px 0px;">
              <h1 style="margin: 0; font-size: 32px; font-weight: 800; letter-spacing: -1px; line-height: 48px; color: #000;">
                {university name} is inviting you to join OptN!</h1>
            </td>
          </tr>
        </table>
        <!--[if (gte mso 9)|(IE)]>
        </td>
        </tr>
        </table>
        <![endif]-->
      </td>
    </tr>
    <!-- end hero -->

    <!-- start copy block -->
    <tr>
      <td align="center" bgcolor="#e9ecef">
        <!--[if (gte mso 9)|(IE)]>
        <table align="center" border="0" cellpadding="0" cellspacing="0" width="600">
        <tr>
        <td align="center" valign="top" width="600">
        <![endif]-->
        <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width: 600px;">

          <!-- start copy -->
          <tr>
            <td align="left" bgcolor="#ffffff"
              style="padding: 24px; font-family: 'Nunito', Helvetica, Arial, sans-serif; font-size: 16px; line-height: 24px; color: #000;">
              <p style="margin: 0;">Hey {firstName}, {university name} has signed up with OptN and wants to invite you to 
                join in order to connect with you. <br><br>With OptN you can chat with admission
                counselors, see college matches and admission chances, research universities, and connect with similar students.</p>

              <br><b>Scan or click below to download the app and complete your profile to get started...</b>
              </br>
            </td>
          </tr>
          <!-- end copy -->

          <!-- start button -->
          <tr>
            <td align="left" bgcolor="#ffffff">
              <table border="0" cellpadding="0" cellspacing="0" width="100%" style="margin-top: -45px;">
                <tr>
                  <td align="center" bgcolor="#ffffff" style="padding: 0px;">
                    <table border="0" cellpadding="0" cellspacing="0">
                      <tr>
                        <img src="https://api.joinoptn.com/parse/files/EK4QDXyjTzcNpo0rdlRpvgez6UHaTuy2V5fhJgQv/c89c05c823e542227a508b078e5ec165_qrcode2.png" style="height: 320px; width: 320px; border-radius: 13px;"></img>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <!-- end button -->

          <!-- start button -->
          <tr>
            <td align="left" bgcolor="#ffffff">
              <table border="0" cellpadding="0" cellspacing="0" width="100%" style="margin-top: -55px;">
                <tr>
                  <td align="center" bgcolor="#ffffff" style="padding: 12px;">
                    <table border="0" cellpadding="0" cellspacing="0">
                      <tr>
                        <td align="center" bgcolor="#35c669" style="border-radius: 30px;">
                          <a href="https://apps.apple.com/us/app/optn-your-college-connection/id1582513542"
                            target="_blank"
                            style="display: inline-block; padding: 16px 36px; font-weight: 700; font-family: 'Nunito', Helvetica, Arial, sans-serif; font-size: 10.5px; color: #ffffff; text-decoration: none; border-radius: 6px;">DOWNLOAD
                            iOS APP</a>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <!-- end button -->

          <tr>
            <td align="left" bgcolor="#ffffff"
              style="color: #92929b; padding: 24px; padding-bottom: 0px; font-family: 'Nunito', Helvetica, Arial, sans-serif; font-size: 14px; line-height: 24px;">
              <br><b>*Note: Please use this email ({email}) when you sign up so schools can discover you.</b>
              </br>
            </td>
          </tr>

          <!-- start copy -->
          <tr>
            <td align="left" bgcolor="#ffffff"
              style="padding: 24px; font-family: 'Nunito', Helvetica, Arial, sans-serif; font-size: 16px; line-height: 24px; border-bottom: 0px solid #d4dadf; border-radius: 0px 0px 30px 30px;">
              <!-- <br><b>Welcome to OptN!</b></br></br> -->
              <!-- <p style="margin: 0;">Cheers,<br> Team OptN</p> -->
            </td>
          </tr>
          <!-- end copy -->

        </table>
        <!--[if (gte mso 9)|(IE)]>
        </td>
        </tr>
        </table>
        <![endif]-->
      </td>
    </tr>
    <!-- end copy block -->

    <!-- start footer -->
    <tr>
      <td align="center" bgcolor="#e9ecef" style="padding: 24px;">
        <!--[if (gte mso 9)|(IE)]>
        <table align="center" border="0" cellpadding="0" cellspacing="0" width="600">
        <tr>
        <td align="center" valign="top" width="600">
        <![endif]-->
        <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width: 600px;">

          <!-- start permission -->
          <tr>
            <td align="center" bgcolor="#e9ecef"
              style="padding: 12px 24px; font-family: 'Nunito', Helvetica, Arial, sans-serif; font-size: 14px; line-height: 20px; color: #666;">
              <p style="margin: 0;">You received this email because {university name} has indicated
                their interest in connecting with you on the OptN platform. If you did not want to receive this email, you
                can safely <a href="https://joinoptn.com/unsubscribe" target="_blank">unsubscribe</a> or contact OptN support at <a href="mailto:support@joinoptn.com"
                  target="_blank">support@joinoptn.com</a>.</p>
            </td>
          </tr>
          <!-- end permission -->

          <!-- start unsubscribe -->
          <tr>
            <td align="center" bgcolor="#e9ecef"
              style="padding: 12px 24px; font-family: 'Nunito', Helvetica, Arial, sans-serif; font-size: 14px; line-height: 20px; color: #666;">
              <!--<p style="margin: 0;">To stop receiving these emails, you can <a href="https://sendgrid.com" target="_blank" rel="noopener noreferrer">unsubscribe</a> at any time.</p>-->
              <p style="margin: 0;">Copyright OptN Inc, 2024 | Dallas, TX</p>
            </td>
          </tr>
          <!-- end unsubscribe -->

        </table>
        <!--[if (gte mso 9)|(IE)]>
        </td>
        </tr>
        </table>
        <![endif]-->
      </td>
    </tr>
    <!-- end footer -->

  </table>
  <!-- end body -->

</body>

</html>`