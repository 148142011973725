import React, { useEffect, useState } from "react";
import { Grid, Typography, Button } from "@mui/material";
import { useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import NumberFormat from "react-number-format";
import { gql, useMutation } from "@apollo/client";
import { Loading, Input } from "../../../components";

const useStyles = makeStyles((theme: any) => ({
  root: {},
  button: {
    background: "linear-gradient(75deg, #35c669, #2ec4a9)",
    border: 0,
    borderRadius: 15,
    color: "#fff",
    padding: "0 30px",
    minHeight: "50px",
  },
}));

const SAVE = gql`
  mutation saveCollegeStats(
    $id: ID!
    $tuitionExpensesMinusAid: Float
    $tuitionExpenses: Float
    $avgTuitionInState: Float
    $avgTuitionOutOfState: Float
    $pellRate: Float
    $federalLoanRate: Float
    $avgMonthly: Float
    $medianDebt: Float
  ) {
    updateCollegeStat(
      input: {
        id: $id
        fields: {
          tuitionExpensesMinusAid: $tuitionExpensesMinusAid
          tuitionExpenses: $tuitionExpenses
          avgTuitionInState: $avgTuitionInState
          avgTuitionOutOfState: $avgTuitionOutOfState
          pellRate: $pellRate
          federalLoanRate: $federalLoanRate
          avgMonthlyPayments: $avgMonthly
          medianDebtPostgrad: $medianDebt
        }
      }
    ) {
      collegeStat {
        id
        objectId
        tuitionExpensesMinusAid
        tuitionExpenses
        avgTuitionInState
        avgTuitionOutOfState
        pellRate
        federalLoanRate
        avgMonthlyPayments
        medianDebtPostgrad
      }
    }
  }
`;

function Field(props) {
  return <Input {...props} variant="filled" />;
}

export default function CostsScholarships(props) {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [tuition_expenses_minus_aid, set_tuition_expenses_minus_aid] = useState(
    props.data.tuition_expenses_minus_aid
  );
  const [tuition_expenses, set_tuition_expenses] = useState(
    props.data.tuition_expenses
  );
  const [avgTuition_inState, set_avgTuition_inState] = useState(
    props.data.avgTuition_inState
  );
  const [avgTuition_outState, set_avgTuition_outState] = useState(
    props.data.avgTuition_outState
  );
  const [pell_rate, set_pell_rate] = useState(props.data.pell_rate);
  const [federal_loan_rate, set_federal_loan_rate] = useState(
    props.data.federal_loan_rate
  );
  const [avg_monthly_payments, set_avg_monthly_payments] = useState(
    props.data.avg_monthly_payments
  );
  const [median_debt_postgrad, set_median_debt_postgrad] = useState(
    props.data.median_debt_postgrad
  );

  const [saveCollegeStats, { loading, error }] = useMutation(SAVE);

  useEffect(() => {
    if (props.data) {
      set_tuition_expenses_minus_aid(
        props.data?.college?.collegeStats?.tuitionExpensesMinusAid
      );
      set_tuition_expenses(props.data?.college?.collegeStats?.tuitionExpenses);
      set_avgTuition_inState(
        props.data?.college?.collegeStats?.avgTuitionInState
      );
      set_avgTuition_outState(
        props.data?.college?.collegeStats?.avgTuitionOutOfState
      );
      set_pell_rate(
        (props.data?.college?.collegeStats?.pellRate * 100)
          .toString()
          .substring(0, 5)
      );
      set_federal_loan_rate(
        (props.data?.college?.collegeStats?.federalLoanRate * 100)
          .toString()
          .substring(0, 5)
      );
      set_avg_monthly_payments(
        props.data?.college?.collegeStats?.avgMonthlyPayments
      );
      set_median_debt_postgrad(
        props.data?.college?.collegeStats?.medianDebtPostgrad
      );
    }
  }, [props.data]);

  if (error) console.log(error.networkError);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography justify-content="left" align="left" color="textPrimary">
          Total Cost Per Year
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <NumberFormat
          fullWidth
          label="Tuition, Fees, Books, & Living Expenses (minus average aid)"
          name="tuition_expenses_minus_aid"
          value={tuition_expenses_minus_aid}
          onChange={(e) => set_tuition_expenses_minus_aid(e.target.value)}
          customInput={Field}
          prefix="$"
          thousandSeparator
        />
      </Grid>
      <Grid item xs={12}>
        <NumberFormat
          fullWidth
          label="Tuition, Fees, Books, & Living Expenses"
          name="tuition_expenses"
          value={tuition_expenses}
          onChange={(e) => set_tuition_expenses(e.target.value)}
          customInput={Field}
          prefix="$"
          thousandSeparator
        />
      </Grid>
      <Grid item xs={12}>
        <Typography justify-content="left" align="left" color="textPrimary">
          Cost of Tuition
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <NumberFormat
          fullWidth
          label="Avg in-state tuition cost"
          name="avgTuition_inState"
          value={avgTuition_inState}
          onChange={(e) => set_avgTuition_inState(e.target.value)}
          customInput={Field}
          prefix="$"
          thousandSeparator
        />
      </Grid>
      <Grid item xs={12}>
        <NumberFormat
          fullWidth
          label="Avg Out-of-state tuition cost"
          name="avgTuition_outState"
          value={avgTuition_outState}
          onChange={(e) => set_avgTuition_outState(e.target.value)}
          customInput={Field}
          prefix="$"
          thousandSeparator
        />
      </Grid>
      <Grid item xs={12}>
        <Typography justify-content="left" align="left" color="textPrimary">
          Aid Provided
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <NumberFormat
          fullWidth
          label="Pell Grant Rate"
          name="pell_rate"
          value={pell_rate}
          onChange={(e) => set_pell_rate(e.target.value)}
          customInput={Field}
          suffix="%"
        />
      </Grid>
      <Grid item xs={12}>
        <NumberFormat
          fullWidth
          label="Federal Loan Rate"
          name="federal_loan_rate"
          value={federal_loan_rate}
          onChange={(e) => set_federal_loan_rate(e.target.value)}
          customInput={Field}
          suffix="%"
        />
      </Grid>
      <Grid item xs={12}>
        <NumberFormat
          fullWidth
          label="Avg Monthly Payments (After Graduation)"
          name="avg_monthly_payments"
          value={avg_monthly_payments}
          onChange={(e) => set_avg_monthly_payments(e.target.value)}
          customInput={Field}
          prefix="$"
          decimalScale={2}
          thousandSeparator
        />
      </Grid>
      <Grid item xs={12}>
        <NumberFormat
          fullWidth
          label="Median Total Debt (After Graduation)"
          name="median_debt_postgrad"
          value={median_debt_postgrad}
          onChange={(e) => set_median_debt_postgrad(e.target.value)}
          customInput={Field}
          prefix="$"
          thousandSeparator
        />
      </Grid>
      <Grid item xs={12} sx={{ alignSelf: "right" }}>
        {loading ? (
          <Loading small />
        ) : (
          <Button
            style={{
              background: "linear-gradient(75deg, #35c669, #2ec4a9)",
              border: 0,
              borderRadius: 15,
              color: "#fff",
              padding: "0 30px",
              minHeight: "50px",
            }}
            onClick={() => {
              console.log({
                id: props.data?.college?.collegeStats?.objectId,
                tuitionExpensesMinusAid: parseFloat(
                  tuition_expenses_minus_aid
                    .toString()
                    .replace("$", "")
                    .replace(",", "")
                ),
                tuitionExpenses: parseFloat(
                  tuition_expenses.toString().replace("$", "").replace(",", "")
                ),
                avgTuitionInState: parseFloat(
                  avgTuition_inState
                    .toString()
                    .replace("$", "")
                    .replace(",", "")
                ),
                avgTuitionOutOfState: parseFloat(
                  avgTuition_outState
                    .toString()
                    .replace("$", "")
                    .replace(",", "")
                ),
                pellRate: parseFloat(pell_rate) / 100,
                federalLoanRate: parseFloat(federal_loan_rate) / 100,
                avgMonthly: parseFloat(
                  avg_monthly_payments
                    .toString()
                    .replace("$", "")
                    .replace(",", "")
                ),
                medianDebt: parseFloat(
                  median_debt_postgrad
                    .toString()
                    .replace("$", "")
                    .replace(",", "")
                ),
              });
              saveCollegeStats({
                variables: {
                  id: props.data?.college?.collegeStats?.objectId,
                  tuitionExpensesMinusAid: parseFloat(
                    tuition_expenses_minus_aid
                      .toString()
                      .replace("$", "")
                      .replace(",", "")
                  ),
                  tuitionExpenses: parseFloat(
                    tuition_expenses
                      .toString()
                      .replace("$", "")
                      .replace(",", "")
                  ),
                  avgTuitionInState: parseFloat(
                    avgTuition_inState
                      .toString()
                      .replace("$", "")
                      .replace(",", "")
                  ),
                  avgTuitionOutOfState: parseFloat(
                    avgTuition_outState
                      .toString()
                      .replace("$", "")
                      .replace(",", "")
                  ),
                  pellRate: parseFloat(pell_rate) / 100,
                  federalLoanRate: parseFloat(federal_loan_rate) / 100,
                  avgMonthly: parseFloat(
                    avg_monthly_payments
                      .toString()
                      .replace("$", "")
                      .replace(",", "")
                  ),
                  medianDebt: parseFloat(
                    median_debt_postgrad
                      .toString()
                      .replace("$", "")
                      .replace(",", "")
                  ),
                },
              });
            }}
          >
            Save Costs & Scholarships Info
          </Button>
        )}
      </Grid>
    </Grid>
  );
}
