import React, { useState, useRef, useEffect } from "react";
import {
  IconButton,
  Input,
  Paper,
  SvgIcon,
  Tooltip,
  Hidden,
  Typography,
  ButtonBase,
  Box,
  CircularProgress
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Send as SendIcon } from "react-feather";
import axios from 'axios';
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import Parse from "parse";
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded';

const FETCH_USER = gql`
  query fetchUser($id: ID!) {
    user(id: $id) {
      id
      objectId
      userContact {
        id
        objectId
        firstName
      }
      userUniversity {
        id
        objectId
        jobTitle
        linkedCollege {
          id
          objectId
          name
        }
      }
    }
  }
`;

const useStyles = makeStyles((theme: any) => ({
  root: {
    alignItems: "center",
    backgroundColor: theme.palette.background.dark,
    display: "flex",
    padding: theme.spacing(1.7, 2),
  },
  inputContainer: {
    flexGrow: 1,
    marginLeft: theme.spacing(2),
    padding: theme.spacing(1),
  },
  divider: {
    height: 24,
    width: 1,
  },
  fileInput: {
    display: "none",
  },
  search: {
    display: "flex",
    alignItems: "center",
    minHeight: 44,
    borderRadius: 12,
    width: theme.spacing(300),
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    backgroundColor: theme.palette.primary.light,
  },
  searchInput: {
    flexGrow: 1,
    marginLeft: theme.spacing(1),
  },
}));

const MessageComposer = (props) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [body, setBody] = useState("");
  const [isGeneratingResponse, setIsGeneratingResponse] = useState(false);
  const [hasGeneratedBefore, setHasGeneratedBefore] = useState(false);

  const id = Parse.User.current()?.id;

  const { data, loading, error } = useQuery(FETCH_USER, {
    variables: { id },
  });

  const ref = useRef(null);

  const handlePersonalizeClick = async () => {
    setIsGeneratingResponse(true);

    const profileStr = await Parse.Cloud.run("getStudentProfileString", {
      userId: props.userId
    })

    const response = await axios.post(
      'https://api.openai.com/v1/chat/completions',
      {
        model: 'gpt-3.5-turbo',
        messages: [
          { role: 'system', content: 'Im going to give you a high school student profile and I want you to craft a 3 sentence intro message from the perspective of a(n) ' + data?.user?.userUniversity?.jobTitle + ' named ' + data?.user?.userContact?.firstName + ' at ' + data?.user?.userUniversity?.linkedCollege?.name + ' trying to recruit the student in a friendly and non-agressive way. The message should be carefully tailored to the students interests, academic record, and interested majors and how ' + data?.user?.userUniversity?.linkedCollege?.name + ' could be a good fit based on real information about that school when taking all of that into account. Include the recruiters first name in the message. Try to find some sort of creative way to relate to the student without seeming too cheesy or cliche. This is not an email, there should be no subject line.' },
          { role: 'user', content: profileStr },
        ],
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer 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`,
        },
      }
    );

    setBody(response.data.choices[0].message.content);

    setIsGeneratingResponse(false);
    setHasGeneratedBefore(true);
  };

  const handleChange = (event) => {
    event.persist();
    setBody(event.target.value);
  };

  const handleKeyUp = (event) => {
    if (event.keyCode === 13) {
      handleSend();
    }
  };

  const handleSend = async () => {
    if (!body) {
      return;
    }
    await props.send(body).then(setBody(""));
  };

  return (
    <div className={classes.root}>
      <div className={classes.search}>
        <Input
          disableUnderline
          fullWidth
          multiline
          maxRows={5}
          value={body}
          onChange={handleChange}
          // onKeyUp={handleKeyUp}
          style={{
            paddingTop: 12,
            paddingBottom: 12,
          }}
          placeholder="Send a message..."
          disabled={props.disabled}
          className={classes.searchInput}
        />
      </div>
      <Tooltip
        title="Use AI to generate a personalized intro message to the student taking into account their profile and interests."
        placement="top"
        style={{
          marginLeft: theme.spacing(1),
          opacity: 0.5,
        }}
        componentsProps={{
          tooltip: {
            sx: {
              bgcolor: "#46464f",
              color: "white"
            }
          }
        }}
      >
        <LoadingButton
          loading={isGeneratingResponse}
          className="gradientBtn"
          style={{
            color: "#fff",
            marginLeft: theme.spacing(2),
            fontSize: 10,
            height: 35,
            minWidth: hasGeneratedBefore ? 113 : 161,
            maxWidth: hasGeneratedBefore ? 113 : 161,
          }}
          loadingIndicator={
            <CircularProgress thickness={4} size={15} sx={{ color: "#fff" }} />
          }
          onClick={handlePersonalizeClick}
        >
          {!isGeneratingResponse ? hasGeneratedBefore ? "REGENERATE" : "PERSONALIZE WITH AI" : ""}
          <AutoAwesomeRoundedIcon 
            style={{ 
              height: 13, 
              width: 13, 
              marginLeft: 3, 
              marginBottom: 7,
              visibility: isGeneratingResponse ? 'hidden' : 'visible' 
            }}
          />
        </LoadingButton>
      </Tooltip>
      <Tooltip
        title="Send Message"
        placement="top"
        style={{
          marginLeft: theme.spacing(1)
        }}
        componentsProps={{
          tooltip: {
            sx: {
              bgcolor: "#46464f",
              color: "white"
            }
          }
        }}
      >
        <span>
          <IconButton
            color="secondary"
            disabled={!body || props.disabled}
            onClick={handleSend}
          >
            <SvgIcon fontSize="small">
              <SendIcon />
            </SvgIcon>
          </IconButton>
        </span>
      </Tooltip>
    </div>
  );
};

MessageComposer.defaultProps = {
  disabled: false,
  onSend: () => { },
};

export default MessageComposer;
