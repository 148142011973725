import React, { useEffect, useState, useRef } from "react";
import Parse from "parse";
import { Page, Loading } from "../../../components";
import {
  Grid,
  Box,
  Card,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Fade,
  FormControlLabel,
  Checkbox
} from "@mui/material";
import { useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link } from "react-router-dom";
import FadeIn from "react-fade-in";
import PieChart from "./Organic/PieChart";
import InterestedStudentsOverTime from "./Organic/InterestedStudentsOverTime";
import StudentStats from "./Organic/StudentStats";
import StudentTotals from "./Organic/StudentTotals";
import StudentsMap from "./Organic/StudentsMap";
import ImpressiveStudents from "./Organic/ImpressiveStudents";
import MajorsHistogram from "./Organic/MajorsHistogram";
import StudentEngagement from "./Organic/StudentEngagement";
import MenuIcon from "@material-ui/icons/Menu";
import DashboardRounded from '@material-ui/icons/DashboardRounded';
import RefreshIcon from "@material-ui/icons/Refresh";
import SettingsIcon from "@material-ui/icons/SettingsRounded";
import CloseIcon from "@material-ui/icons/Close";

import ImportLeads from "./Leads/ImportLeads";
import StudentTotalsLeads from "./Leads/StudentTotals";
import StudentStatsLeads from "./Leads/StudentStats";
import PieChartLeads from "./Leads/PieChart";
import MajorsHistogramLeads from "./Leads/MajorsHistogram";
import StudentsMapLeads from "./Leads/StudentsMap";
import LeadsList from "./Leads/LeadsList";
import StudentEngagementLeads from "./Leads/StudentEngagement";

import { useAuth } from "../../../hooks/useAuth";

const useStyles = makeStyles((theme: any) => ({
  grid: {
    flexGrow: 1,
    overflow: "hidden"
  },
  promo: {
    background: "linear-gradient(75deg, #35c669, #2ec4a9)",
    height: 50,
    marginBottom: theme.spacing(2),
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  filter: {
    backgroundColor: 'rgba(52, 52, 52, 0.0)',
    height: 50,
    marginBottom: theme.spacing(2),
    paddingRight: theme.spacing(-1),
    marginTop: -2,
    alignItems: "center",
    justifyContent: "right",
    display: "flex",
  },
  filterTitle: {
    fontSize: 15,
    fontWeight: 400,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  promoTitle: {
    fontSize: 16,
    fontWeight: 400,
    paddingRight: theme.spacing(0.6),
    color: "#fff",
  },
  promoLink: {
    fontSize: 16,
    fontWeight: 500,
    color: "#fff",
    textDecoration: 'underline'
  },
  popover: {
    backgroundColor: theme.palette.secondary.dark,
    outlineColor: "#2c2c33",
    outlineStyle: "solid",
    outlineWidth: 1,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: theme.spacing(3),
  },
}));

const Settings = (props) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const auth = useAuth();

  const setCheckedDataCoOpt = async (val) => {
    const res = await Parse.Cloud.run("saveDataCoOptStatus", {
      collegeId: auth.collegeId,
      val: val
    });

    auth.setCoOptStatus(val);

    props.setDataCoOptEnabled(val);
  }

  return (
    <Modal
      className={classes.modal}
      open={props.open}
      onClose={() => props.setOpen(false)}
      closeAfterTransition
    >
      <Fade in={props.open}>
        <Card style={{
          maxHeight: 690,
          overflowY: "auto",
          overflowX: "hidden",
          backgroundColor: theme.palette.primary.dark,
          display: "flex",
          flexDirection: "column",
          padding: theme.spacing(4),
          paddingBottom: theme.spacing(2),
          borderRadius: 25,
          outlineColor: "#2c2c33",
          outlineStyle: "solid",
          outlineWidth: 1,
        }}>
          <Box width={500}>
            <Grid container sx={{ alignSelf: "left" }}>
              <Typography style={{ fontWeight: 600, fontSize: 29 }} align="left" color="textPrimary">
                {props.title}
              </Typography>
              <Box flexGrow={1} />
              <IconButton style={{ width: 40, height: 40 }} onClick={() => props.setOpen(false)}>
                <CloseIcon />
              </IconButton>
            </Grid>
            <FormControlLabel
              style={{ marginBottom: theme.spacing(1.5), marginTop: theme.spacing(1) }}
              control={
                <Checkbox
                  name="allowResponses"
                  checked={props.dataCoOptEnabled}
                  sx={{
                    color: theme.palette.text.secondary,
                    '&.Mui-checked': {
                      color: theme.palette.secondary.main,
                    },
                  }}
                  onChange={(e) =>
                    setCheckedDataCoOpt(e.target.checked)
                  }
                />
              }
              label={<Typography style={{ fontWeight: 400, fontSize: 14, }}>Data Co-opt Enabled</Typography>}
            />
          </Box>
        </Card>
      </Fade>
    </Modal >
  );
};

export default function DashboardHome(props) {
  const theme = useTheme();
  const classes = useStyles(theme);

  const auth = useAuth();
  const [showCollegeLeads, setShowCollegeLeads] = useState(false);
  const popoverRef = useRef(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [anchor, setAnchor] = useState(null);
  const [title, setTitle] = useState("Student Leads Dashboard");
  const [option, setOption] = useState("leads");
  const [organicSeed, setOrganicSeed] = useState(1);
  const [leadsSeed, setLeadsSeed] = useState(1);
  const [openSettings, setOpenSettings] = useState(false);
  const [dataCoOptEnabled, setDataCoOptEnabled] = useState(false);

  const options = [
    {
      label: "Student Leads Dashboard",
      value: "leads",
    },
    {
      label: "Organic Students Dashboard",
      value: "organic",
    },
  ];

  const reset = () => {
    if (option === 'organic') {
      setOrganicSeed(Math.random());

    } else {
      setLeadsSeed(Math.random());
    }
  }

  const getDataCoOptStatus = async () => {
    const res = await Parse.Cloud.run("getDataCoOptStatus", {
      collegeId: auth.collegeId,
    });

    auth.setCoOptStatus(res);

    setDataCoOptEnabled(res);
  }

  useEffect(() => {
    if (auth.viewingLeads === true) {
      setOption('leads');
      setTitle('Student Leads Dashboard');

    } else {
      setOption('organic');
      setTitle('Organic Students Dashboard');
    }

    window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
    document.body.style.backgroundColor = "#0e0e14";
  }, []);

  useEffect(() => {
    if (auth.collegeId) {
      getDataCoOptStatus();
    }

  }, [auth.collegeId]);

  useEffect(() => {
    auth.setViewingLeads(option === 'leads' ? true : false);
  }, [option]);

  return (
    <Page
      title="Home"
      maxWidth={"xl"}
    >
      {auth.tier === "free" ? (
        <Card style={{
          background: "linear-gradient(75deg, #35c669, #2ec4a9)",
          height: 53,
          marginBottom: theme.spacing(2),
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          borderRadius: 15
        }}>
          <Typography style={{
            fontSize: 16,
            fontWeight: 400,
            paddingRight: theme.spacing(0.6),
            color: "#fff",
          }}>
            Upgrade to Premium to take advantage of all that OptN has to offer.
          </Typography>
          <Link
            to={`/dashboard/billing`}
            style={{ textDecoration: "none" }}
          >
            <Typography style={{
              fontSize: 16,
              fontWeight: 500,
              color: "#fff",
              textDecoration: 'underline'
            }}>
              Click here
            </Typography>
          </Link>
        </Card>
      ) : (<></>)}
      {/* <FadeIn delay={100}> */}
      <Card className={classes.filter} sx={{ backgroundColor: 'rgba(52, 52, 52, 0.0)' }} elevation={0}>
        <Typography className={classes.filterTitle} color="textSecondary">
          {title}
        </Typography>
        <IconButton
          ref={popoverRef}
          onClick={(e: any) => {
            setOpenMenu(true);
            setAnchor(e.currentTarget);
          }}
        >
          <DashboardRounded />
        </IconButton>
        <Menu
          id="post-menu"
          anchorEl={anchor}
          open={openMenu}
          slotProps={{
            paper: {
              sx: {
                backgroundColor: theme.palette.primary.dark,
                borderRadius: 1.5,
                outlineColor: "#3c3c44",
                outlineStyle: "solid",
                outlineWidth: 1,
              }
            }
          }}
          onClose={() => setOpenMenu(false)}
        >
          {options.map((option) => (
            <MenuItem
              key={option.value}
              onClick={() => {
                setOpenMenu(false);
                setOption(option.value);
                setTitle(option.label);
              }}
            >
              {option.label}
            </MenuItem>
          ))}
        </Menu>
        {option == 'leads' ? (
          <IconButton
            onClick={(e) => {
              setOpenSettings(true);
            }}
          >
            <SettingsIcon />
          </IconButton>
        ) : (
          <></>
        )}
        <Settings
          setOpen={setOpenSettings}
          open={openSettings}
          title="Data Settings"
          dataCoOptEnabled={dataCoOptEnabled}
          setDataCoOptEnabled={setDataCoOptEnabled}
        />
        <IconButton
          onClick={(e) => {
            reset();
          }}
        >
          <RefreshIcon />
        </IconButton>
      </Card>
      {/* </FadeIn> */}
      {option === "organic" ? (
        <Grid style={{
          flexGrow: 1,
          overflow: "hidden",
          padding: 1,
        }} container spacing={3}>
          <Grid item lg={6} md={12} sm={12}>
            <PieChart key={organicSeed} />
          </Grid>
          <Grid item lg={3} md={6} sm={12}>
            <StudentStats key={organicSeed} />
          </Grid>
          <Grid item lg={3} md={6} sm={12}>
            <StudentTotals key={organicSeed} />
          </Grid>
          <Grid item sm={12}>
            <MajorsHistogram key={organicSeed} />
          </Grid>
          <Grid item sm={12}>
            <InterestedStudentsOverTime key={organicSeed} />
          </Grid>
          <Grid item lg={7}>
            <StudentEngagement key={organicSeed} />
          </Grid>
          <Grid item lg={5} md={12} sm={12}>
            <StudentsMap key={organicSeed} />
          </Grid>
          <Grid item sm={12}>
            <ImpressiveStudents key={organicSeed} />
          </Grid>
        </Grid>
      ) : (
        <>
          <Grid style={{
            flexGrow: 1,
            overflow: "hidden",
            padding: 1,
          }} container spacing={3}>
            <Box width="100%" />
            <Grid item lg={12} md={12} sm={12}>
              <ImportLeads key={leadsSeed} />
            </Grid>
            <Grid item lg={6} md={12} sm={12}>
              <PieChartLeads key={leadsSeed} />
            </Grid>
            <Grid item lg={3} md={6} sm={12}>
              <StudentStatsLeads key={leadsSeed} />
            </Grid>
            <Grid item lg={3} md={6} sm={12}>
              <StudentTotalsLeads key={leadsSeed} />
            </Grid>
            <Grid item sm={12}>
              <MajorsHistogramLeads key={leadsSeed} />
            </Grid>
            <Grid item lg={7}>
              <StudentEngagementLeads key={leadsSeed} />
            </Grid>
            <Grid item lg={5} md={12} sm={12}>
              <StudentsMapLeads key={leadsSeed} />
            </Grid>
            <Grid item sm={12}>
              <LeadsList key={leadsSeed} />
            </Grid>
          </Grid>
        </>
      )}
      <Box height="10px" />
    </Page>
  );
}
