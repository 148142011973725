import { lazy } from 'react';

// ----------------------------------------------------------------------

const UniversityWebappPage = lazy(() => import('../../Pages/_platform/university-webapp'));

// ----------------------------------------------------------------------

export const universityWebappRoutes = [
  {
    path: 'university-webapp',
    children: [
      { element: <UniversityWebappPage />, index: true },
    ],
  },
];
