import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import CompactLayout from '../../Layouts/compact';
import { SplashScreen } from '../../Components/loading-screen';

// ----------------------------------------------------------------------

const TrackingPage = lazy(() => import('../../Pages/tracking'));

// ----------------------------------------------------------------------

export const trackingRoutes = [
  {
    element: (
      <CompactLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </CompactLayout>
    ),
    children: [
      { path: 'redirect', element: <TrackingPage /> },
    ],
  },
];
