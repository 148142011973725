export const addCommas = (nStr) => {
  nStr += "";
  var x = nStr.split(".");
  var x1 = x[0];
  var x2 = x?.length > 1 ? "." + x[1] : "";
  var rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    // eslint-ignore-next-line
    x1 = x1.replace(rgx, "$1" + "," + "$2");
  }
  return x1 + x2;
};

/**
 * Takes filterQuery data from a query response and outputs
 * a list of filters for use in a search query.
 * NOTE: Excludes majors
 * @param {object} filterQuery  the filterQuery data object
 *
 * @returns {Array} The list of filters from filterQuery
 */
export const getFiltersFromFilterQuery = (
  filterQuery,
  collegeId: any = undefined
) => {
  if (!filterQuery) return [];
  let filters: any = [];

  if (filterQuery?.SATfrom) {
    filters.push({
      sat: {
        greaterThanOrEqualTo: filterQuery?.SATfrom,
      },
    });
  }
  if (filterQuery?.SATto) {
    filters.push({
      sat: {
        lessThanOrEqualTo: filterQuery?.SATto,
      },
    });
  }
  if (filterQuery?.ACTfrom) {
    filters.push({
      act: {
        greaterThanOrEqualTo: filterQuery?.ACTfrom,
      },
    });
  }
  if (filterQuery?.ACTto) {
    filters.push({
      act: {
        lessThanOrEqualTo: filterQuery?.ACTto,
      },
    });
  }
  if (filterQuery?.GPAfrom) {
    filters.push({
      adjustedUWGPA: {
        greaterThanOrEqualTo: filterQuery?.GPAfrom,
      },
    });
  }
  if (filterQuery?.GPAto) {
    filters.push({
      adjustedUWGPA: {
        lessThanOrEqualTo: filterQuery?.GPAto,
      },
    });
  }
  if (filterQuery?.Rankfrom) {
    filters.push({
      adjustedClassRank: {
        greaterThanOrEqualTo: filterQuery?.Rankfrom,
      },
    });
  }
  if (filterQuery?.Rankto) {
    filters.push({
      adjustedClassRank: {
        lessThanOrEqualTo: filterQuery?.Rankto,
      },
    });
  }
  if (filterQuery?.ethnicities?.length > 0) {
    if (filterQuery?.ethnicities[0].value) {
      filters.push({
        ethnicity: {
          in: filterQuery?.ethnicities.map((e) => e.value),
        },
      });
    } else {
      filters.push({
        ethnicity: {
          in: filterQuery?.ethnicities,
        },
      });
    }
  }
  if (filterQuery?.genders?.length > 0) {
    if (filterQuery?.genders[0].value) {
      filters.push({
        gender: {
          in: filterQuery?.genders.map((g) => g.value),
        },
      });
    } else {
      filters.push({
        gender: {
          in: filterQuery?.genders,
        },
      });
    }
  }
  if (filterQuery?.locations?.length > 0) {
    if (filterQuery?.locations[0].value) {
      filters.push({
        contactState: {
          in: filterQuery?.locations.map((l) => l.value),
        },
      });
    } else {
      filters.push({
        contactState: {
          in: filterQuery?.locations,
        },
      });
    }
  }
  if (filterQuery?.gradyears?.length > 0) {
    if (filterQuery?.gradyears[0].value) {
      filters.push({
        gradYear: {
          in: filterQuery?.gradyears.map((g) => g.value),
        },
      });
    } else {
      filters.push({
        gradYear: {
          in: filterQuery?.gradyears,
        },
      });
    }
  }
  if (filterQuery?.zipRange && filterQuery?.zipCode && filterQuery?.lat && filterQuery?.lng) {
    filters.push({
      location: {
        geoWithin: {
          centerSphere: {
            center: {latitude: filterQuery?.lat, longitude: filterQuery?.lng}, 
            distance: filterQuery?.zipRange / 3959.0
          }
        }
      }
    });
  }
  if (filterQuery?.parentsEd) {
    filters.push({
      parentsHighestEdu: {
        equalTo: filterQuery.parentsEd,
      },
    });
  }
  if (filterQuery?.type) {
    filters.push({
      type: {
        equalTo: filterQuery.type,
      },
    });

  } else {
    filters.push({
      type: {
        equalTo: "highschool_student",
      },
    });
  }
  if (filterQuery?.collegeId) {
    filters.push({
      college: {
        have: {
          objectId: { equalTo: filterQuery.collegeId }
        }
      },
    });
  }
  return filters;
};

/**
 * Takes a date object or string as input and converts it to
 * acceptable readable format for use in the application
 * @param {object} date The input date (Date() or String)
 *
 * @returns {String} The formatted date
 */
export const parseDate = (date) => {
  if (!date) return "";
  let d = new Date(date);
  let ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
  let mo = new Intl.DateTimeFormat("en", { month: "short" }).format(d);
  let da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
  return `${mo} ${da}, ${ye}`;
};

export const calcAcademicScore = (sat, act, psat, adjustedUWGPA, adjustedClassRank) => {
  var rawScore = 0.0;
  var categoriesCount = 0;

  if (sat) {
    rawScore = rawScore + ((4 / 1600) * sat);
    categoriesCount = categoriesCount + 1;
  }

  if (act) {
    rawScore = rawScore + ((4 / 36) * act);
    categoriesCount = categoriesCount + 1;
  }

  if (psat) {
    rawScore = rawScore + ((4 / 1520) * psat);
    categoriesCount = categoriesCount + 1;
  }

  if (adjustedUWGPA) {
    rawScore = rawScore + (adjustedUWGPA);
    categoriesCount = categoriesCount + 1;
  }

  if (adjustedClassRank) {
    rawScore = rawScore + (4 * (1 - adjustedClassRank));
    categoriesCount = categoriesCount + 1;
  }

  const finalScore = rawScore / (categoriesCount * 4) * (0.8 + ((categoriesCount / 5) * 0.2));

  return categoriesCount > 0 ?
    ((finalScore * 100) < 40 ? "D" :
      (finalScore * 100) >= 40 && (finalScore * 100) < 47 ? "D+" :
        (finalScore * 100) >= 47 && (finalScore * 100) < 55 ? "C" :
          (finalScore * 100) >= 55 && (finalScore * 100) < 65 ? "C+" :
            (finalScore * 100) >= 65 && (finalScore * 100) < 75 ? "B" :
              (finalScore * 100) >= 75 && (finalScore * 100) < 85 ? "B+" :
                (finalScore * 100) >= 85 && (finalScore * 100) < 92 ? "A" :
                  (finalScore * 100) >= 92 ? "A+" : "N/A") :
    "N/A"
};