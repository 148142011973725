import React, { useState, useEffect } from "react";
import Parse from "parse";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  useTheme,
  Card,
  Grid,
  Box,
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Modal,
  Backdrop,
  Fade,
} from "@mui/material";
import FadeIn from "react-fade-in";
import { makeStyles } from '@mui/styles';
import { Loading } from "../../../components/Loading";
import Page from "../../../components/Page";
import AddUserButton from "./AddUserButton";
import User from "./User";
import { useSnackbar } from "notistack";
import { useAuth } from "../../../hooks/useAuth";

const useStyles = makeStyles((theme: any) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  card: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
    paddingRight: theme.spacing(5),
    paddingLeft: theme.spacing(5),
    borderRadius: 20,
    outlineColor: "#2c2c33",
    outlineStyle: "solid",
    outlineWidth: 1,
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  button: {
    background: "linear-gradient(75deg, #35c669, #2ec4a9)",
    border: 0,
    color: "#fff",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: theme.spacing(3),
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(4),
  },
  deactivate: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.text.primary,
  },
  tableCell: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    "& .MuiTableCell-root": {
      borderBottomColor: "#3c3c46"
    },
  },
}));

export default function AccountsManagement(props) {
  const theme = useTheme();
  const classes = useStyles(theme);

  const { enqueueSnackbar } = useSnackbar();
  const [people, setPeople] = useState([]);
  const [allRoles, setAllRoles] = useState([]);
  const [numUsers, setNumUsers] = useState(0);
  const [costPerUser, setCostPerUser] = useState(0);
  const selected = [];
  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const auth = useAuth();
  const selectedSome = selected.length > 0 && selected.length < people.length;
  const selectedAll = selected.length === people.length;
  const enableBulkOperations = false;

  async function fetchAccounts() {
    await Parse.Cloud.run("accounts", { collegeId: auth.collegeId }).then(
      (result) => {
        setLoading(false);
        setRefreshing(false);

        setPeople(result);
      },
      (error) => {
        setLoading(false);
        setRefreshing(false);
        
        enqueueSnackbar(error.toString(), {
          variant: "error",
        });
      }
    );

    const result = await Parse.Cloud.run("getTier", { collegeId: auth.collegeId })

    setNumUsers(result.get('numUsersOnboarded'));
    setCostPerUser(result.get('costPerUser'));

    await Parse.Cloud.run("fetchAllPermissionsForCollege").then((res) => {
      setAllRoles(res);
    });
  }

  useEffect(() => {
    console.log(people);
  }, [people]);

  useEffect(() => {
    document.body.style.backgroundColor = "#0e0e14";
    fetchAccounts();
  }, []);

  async function refresh() {
    setRefreshing(true);
    await fetchAccounts();
  }

  async function startRefresh() {
    setRefreshing(true);
  }

  if (loading) {
    return <Loading lower />;
  }

  return (
    <>
      <Page title="Accounts Management" maxWidth='xl'>
        <FadeIn>
          <Card style={{
            backgroundColor: theme.palette.primary.dark,
            padding: theme.spacing(3),
            paddingRight: theme.spacing(5),
            paddingLeft: theme.spacing(5),
            borderRadius: 25,
            outlineColor: "#2c2c33",
            outlineStyle: "solid",
            outlineWidth: 1,
          }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FadeIn>
                  <Box minHeight={56} display="flex" alignItems="center">
                    <Typography color="textPrimary" style={{ fontWeight: 600, fontSize: 30 }}>Enterprise Accounts</Typography>
                    <Box flexGrow={1} />
                    <AddUserButton numUsers={numUsers} costPerUser={costPerUser} />
                  </Box>
                </FadeIn>
                {enableBulkOperations && (
                  <div>
                    <div>
                      <Checkbox
                        checked={selectedAll}
                        indeterminate={selectedSome}
                      // onChange={handleSelectAllCustomers}
                      />
                      <Button variant="outlined">
                        Delete
                      </Button>
                      <Button variant="outlined">
                        Edit
                      </Button>
                    </div>
                  </div>
                )}
                <PerfectScrollbar>
                  <Box>
                    <Table>
                      <colgroup>
                        <col style={{ width: '20%' }} />
                        <col style={{ width: '20%' }} />
                        <col style={{ width: '40%' }} />
                      </colgroup>
                      <TableHead>
                        <TableRow className={classes.tableCell}>
                          <TableCell>Name</TableCell>
                          <TableCell>Job Title</TableCell>
                          <TableCell>Permissions</TableCell>
                          <TableCell align="right"></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {people.map((person: any) => (
                          <User
                            key={person.id}
                            person={person}
                            allRoles={allRoles}
                            refresh={refresh}
                            startRefresh={startRefresh}
                            numUsers={numUsers}
                            costPerUser={costPerUser}
                          />
                        ))}
                      </TableBody>
                    </Table>
                  </Box>
                </PerfectScrollbar>
              </Grid>
            </Grid>
          </Card>
        </FadeIn>
      </Page>
      {refreshing && (
        <Modal
          className={classes.modal}
          open={refreshing}
          closeAfterTransition
        >
          <Loading />
          {/* <Fade in={refreshing}>
            <Loading />
          </Fade> */}
        </Modal>
      )}
    </>
  );
}
